import produce from 'immer';

import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
  deepMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchTenants: 'administration/configurations/tenants/fetchTenants',
  fetchTenant: 'administration/configurations/tenants/fetchTenant',
  updateTenant: 'administration/configurations/tenants/updateTenant',
  createTenant: 'administration/configurations/tenants/createTenant',
  deleteTenant: 'administration/configurations/tenants/deleteTenant',
  updateTenantConfiguration: 'administration/configurations/tenants/updateTenantConfiguration',
  fetchVideoConfigsByTenant: 'administration/configurations/tenants/fetchVideoConfigsByTenant',
  updateTenantVideoConfigs: 'administration/configurations/tenants/updateTenantVideoConfigs',
  createTenantSupportUser: 'administration/configurations/tenants/createTenantSupportUser',
};

export const tenantsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchTenants)) {
      draft.data = sortBy(payload, 'name');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchVideoConfigsByTenant,
        ACTION_TYPE.fetchTenant,
        ACTION_TYPE.fetchTenants,
        ACTION_TYPE.updateTenantVideoConfigs,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchTenant, ACTION_TYPE.fetchVideoConfigsByTenant])) {
      const { id, actionParam, service } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, actionParam, service });
    }

    if (
      actionTypeFailure(type, [
        ACTION_TYPE.fetchTenants,
        ACTION_TYPE.fetchTenant,
        ACTION_TYPE.fetchVideoConfigsByTenant,
      ])
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.createTenant, ACTION_TYPE.updateTenant])) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteTenant)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.updateTenantConfiguration)) {
      const modifyPayload = ({ appKeys, tenantKey, ...rest }) => ({
        ...state.data.find((tenant) => tenant.key === tenantKey),
        ...rest,
      });
      draft.data = shallowMergeEntities(state.data, modifyPayload(payload), 'key');
    }
  });
