import { columnDef } from '../../../commons/ControlledDataGrid';

// Note that textColumns must have enableRowGroup: false to suppress the default grouping

const asBlankWhenExpandedParent = (originalColumnDef) => ({
  ...originalColumnDef,
  cellRendererSelector: ({ data }) =>
    data.isParent && data.isExpanded ? { component: 'Blank' } : { component: undefined },
  printCellClassName: ({ data }) =>
    data.isParent && data.isExpanded ? 'ControlledDataTable__cell--hide' : '',
  dataExportProcessCellCallback: ({ data, value, ...rest }) => {
    if (data.isParent && data.isExpanded) return '';
    return originalColumnDef.dataExportProcessCellCallback
      ? originalColumnDef.dataExportProcessCellCallback({ data, value, ...rest })
      : value;
  },
});

export const columnDefs = [
  {
    headerName: 'Activity',
    children: [
      asBlankWhenExpandedParent(
        columnDef.dateColumn({
          field: 'startedAt',
          headerName: 'Started At',
        })
      ),
      columnDef.iconColumn({
        field: 'map',
        headerName: 'Map',
        width: 80,
        cellRenderer: 'Mapper',
      }),
      columnDef.textColumn({
        field: 'activityTitle',
        headerName: 'Activity',
        cellRenderer: 'Activity',
        enableRowGroup: false,
        allowBlank: true,
        printCellClassName: ({ data }) => (data.isChild ? 'ControlledDataTable__cell--indent' : ''),
      }),
      columnDef.textColumn({
        field: 'detail',
        headerName: 'Detail',
        enableRowGroup: false,
        allowBlank: true,
      }),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'locationName',
          headerName: 'Starting Location',
          enableRowGroup: false,
          allowBlank: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          colId: 'locationCoords',
          headerName: 'Starting Coordinates',
          enableRowGroup: false,
          allowBlank: true,
          valueGetter: ({ data }) => `${data.latitude}, ${data.longitude}`,
          hide: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.durationColumn({
          field: 'duration',
          headerName: 'Duration',
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.distanceColumn({
          field: 'distance',
          headerName: 'Distance',
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.distanceColumn({
          field: 'vehicleOdometer',
          headerName: 'Vehicle Odometer',
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
    ],
  },
  {
    headerName: 'Resources',
    children: [
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'vehicleName',
          headerName: 'Vehicle',
          enableRowGroup: false,
          hide: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'trailers',
          headerName: 'Trailer(s)',
          enableRowGroup: false,
          hide: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'manifests',
          headerName: 'Manifest(s)',
          enableRowGroup: false,
          hide: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'orders',
          headerName: 'Order(s)',
          enableRowGroup: false,
          hide: true,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.textColumn({
          field: 'products',
          headerName: 'Product(s)',
          enableRowGroup: false,
          hide: true,
        })
      ),
      // asBlankWhenExpandedParent(
      //   columnDef.numberColumn({
      //     field: 'weight.gross',
      //     headerName: 'Gross Weight',
      //   })
      // ),
      // asBlankWhenExpandedParent(
      //   columnDef.numberColumn({
      //     field: 'weight.tare',
      //     headerName: 'Tare Weight',
      //   })
      // ),
    ],
  },
  {
    headerName: 'Fuel',
    children: [
      asBlankWhenExpandedParent(
        columnDef.fuelColumn({
          field: `addedFuel`,
          headerName: 'Added Fuel',
          hide: true,
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.fuelColumn({
          field: `consumedFuel`,
          headerName: 'Consumed Fuel',
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
      asBlankWhenExpandedParent(
        columnDef.fuelColumn({
          field: `idlingFuel`,
          headerName: 'Idle Fuel',
          allowedAggFuncs: [],
          aggFunc: null,
        })
      ),
    ],
  },
];
