/**
 * Fallback object for an undefined location
 */
const UNDEFINED_LOCATION = { locations: [], speedLimit: {} };

/**
 * selectLocations(state) -> [locations]
 *
 * Selector for all locations
 */
export const selectLocations = (state) => state.administration.resources.locations.data;

/**
 * selectLocation(state, { locationId }) -> location
 *
 * Selector for single location by key
 */
export const selectLocation = (state, locationId) =>
  state.administration.resources.locations.data.find((item) => item.key === locationId) ||
  UNDEFINED_LOCATION;

/**
 * selectLocationsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on locations
 */
export const selectLocationsFetchRequests = (state) =>
  state.administration.resources.locations.fetches;
