import { connect } from 'react-redux';

import { VehicleSummary } from './summary';

import {
  selectSystemTenant,
  selectIsUserSuperAdmin,
  selectIsUserTenantSuperAdmin,
} from '../../../../../data/system';

import { vehicle, vehicleType } from '../../../../../data/administration/resources';
import { camera } from '../../../../../data/administration/devices';

export const VehicleSummaryContainer = connect(
  (state) => ({
    cameraDevices: camera.selectors.selectCameraDevices(state),
    isSuperAdmin: selectIsUserSuperAdmin(state),
    isTenantSuperAdmin: selectIsUserTenantSuperAdmin(state),
    tenant: selectSystemTenant(state),
    vehicles: vehicle.selectors.selectAllVehicles(state),
    vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
  }),
  {
    deleteVehicle: vehicle.actions.deleteVehicle,
    fetchCameraDevices: camera.actions.fetchCameraDevicesOptimized,
    fetchVehicles: vehicle.actions.fetchVehicles,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
  }
)(VehicleSummary);
