import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, sortBy, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { SelectInput, Chip } from 'stti-react-common';

import { SelectFilterInput, ReportControl } from '../../../commons/ReportsCommon';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';

export const InspectionResourceFilters = ({
  rows,
  group,
  filterGroup,
  myInspectionsOnly,
  setControl,
}) => {
  const getOptions = useMemo(
    () => ({
      vehicle: () =>
        sortBy(
          uniqBy(
            rows.map(({ assetKey, assetName }) => ({ label: assetName, value: assetKey })),
            'value'
          ),
          'label'
        ),
      inspectorName: () =>
        sortBy(
          uniqBy(
            rows.map(({ inspectorKey, inspectorName }) => ({
              label: inspectorName,
              value: inspectorKey,
            })),
            'value'
          ),
          'label'
        ),
      vehicleTypes: () =>
        sortBy(
          uniqBy(
            rows.map(({ assetType, assetTypeName }) => ({
              label: assetTypeName,
              value: assetType,
            })),
            'value'
          ),
          'label'
        ),
      geozones: () =>
        sortBy(
          uniqBy(
            rows.map(({ alternativeLocationName }) => ({
              label: alternativeLocationName,
              value: alternativeLocationName,
            })),
            'value'
          ),
          'label'
        ),
      minDate: () => moment.min(rows.map(({ endedAt }) => moment(endedAt))),
      maxDate: () => moment.max(rows.map(({ endedAt }) => moment(endedAt))),
    }),
    [rows]
  );

  return (
    <>
      {!myInspectionsOnly && (
        <SelectFilterInput
          name="assetNames"
          field="assetKey"
          displayValue="assetName"
          group={group}
          filterGroup={filterGroup}
          label="Vehicles"
          summaryPrefix="Vehicle: "
          itemPlural="vehicles"
          getOptions={getOptions.vehicle}
          autoGridArea
        />
      )}
      <SelectFilterInput
        name="alternativeLocationName"
        field="alternativeLocationName"
        group={group}
        filterGroup={filterGroup}
        label="Geozones"
        summaryPrefix="Geozone: "
        itemPlural="geozones"
        getOptions={getOptions.geozones}
        autoGridArea
      />

      <SelectFilterInput
        name="assetTypes"
        field="assetType"
        displayValue="assetTypeName"
        group={group}
        filterGroup={filterGroup}
        label="Vehicle Types"
        summaryPrefix="Vehicle Type: "
        itemPlural="vehicle types"
        getOptions={getOptions.vehicleTypes}
        autoGridArea
      />
      {!myInspectionsOnly && (
        <SelectFilterInput
          name="inspectorNames"
          field="inspectorKey"
          displayValue="inspectorName"
          group={group}
          filterGroup={filterGroup}
          label="Inspectors"
          summaryPrefix="Inspector: "
          itemPlural="inspectors"
          getOptions={getOptions.inspectorName}
          autoGridArea
        />
      )}

      <ReportControl
        render={(props) => (
          <SelectInput
            options={[
              { label: 'No', value: false },
              { label: 'Yes', value: true },
              { label: '', value: null },
            ]}
            {...props}
          />
        )}
        name="hasDefects"
        group="filters"
        label="Has Defects?"
        renderSummary={(renderProps, { value }) => {
          if (!value || value.length === 0) return null;

          return (
            <Chip
              {...renderProps}
              label="Has Defects"
              onDelete={() => {
                setControl('hasDefects', null);
              }}
            />
          );
        }}
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => {
            if (value) {
              return row.hasDefects;
            }

            return !row.hasDefects;
          },
          type: filterRowsByPredicate,
          disabled: ({ value }) => isNil(value),
        }}
        autoGridArea
      />

      <ReportControl
        render={(props) => (
          <SelectInput
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
              { label: 'All', value: null },
            ]}
            {...props}
          />
        )}
        name="hasDTCs"
        group="filters"
        label="Has DTCs?"
        renderSummary={(renderProps, { value }) => {
          if (!value || value.length === 0) return null;

          return (
            <Chip
              {...renderProps}
              label="Has DTCs"
              onDelete={() => {
                setControl('hasDTCs', null);
              }}
            />
          );
        }}
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => {
            if (value === true) {
              return row.hasDTCs === true;
            }
            if (value === false) {
              return row.hasDTCs === false || row.hasDTCs === null;
            }
            return true;
          },
          type: filterRowsByPredicate,
        }}
        autoGridArea
      />
    </>
  );
};

InspectionResourceFilters.propTypes = {
  rows: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
  myInspectionsOnly: PropTypes.bool,
  queryMode: PropTypes.oneOf([
    'latestInspectionsByVehicle',
    'latestInspectionsByInspector',
    'byDateRange',
  ]),
  queryFor: PropTypes.string.isRequired,
  setControl: PropTypes.func,
};
