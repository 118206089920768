import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Clickable,
  Form,
  Icon,
  ImageInput,
  ProgressOverlay,
  SelectInput,
  TextInput,
} from 'stti-react-common';
import { isEmpty } from 'lodash';

// COMMON LIB IMPORTS
import { CrudAdminItem } from '../commons/AdminCommon';
import { Feature } from '../commons/Feature';
import { useApiError } from '../../helpers/hooks';
import { ErrorSummary } from '../commons/ErrorSummary';
import { validateName } from '../../helpers/form/validations';

const unitSystem = ['Metric', 'Imperial'];

export const Profile = ({ mode: initialMode = 'view', tenant, updateCurrentUser, user }) => {
  const [visibility, setVisibility] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [loading, setLoading] = useState(true);

  const { apiError, setApiError } = useApiError();

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...user,
      key: user?.key,
      driverLicenseNumber: user?.driverLicenseNumber || '',
      password: undefined, // password is not returned by BE, if the user wishes to changed it, it must be provided
      fullName: `${user?.firstName} ${user?.lastName}`,
    },
  });

  const { controls: formControls, resetControls } = form;

  const { isDriver } = formControls;

  useEffect(() => {
    if (!isEmpty(user)) {
      setLoading(false);
      resetControls(user);
    }
  }, [user]);

  if (loading) {
    return (
      <div className="UserDetail">
        <ProgressOverlay isOpen={loading} />
      </div>
    );
  }

  const formatData = (data) => ({ ...data, fullName: `${data.firstName} ${data.lastName}` });

  return (
    <Feature.Wrapper className="UserDetail">
      <CrudAdminItem
        createItem={null}
        data={formatData(formControls)}
        deleteItem={null}
        form={form}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        itemTitle={`${formControls.firstName} ${formControls.lastName}`}
        mode={mode}
        onApiError={setApiError}
        onClose={() => setMode('view')}
        resetControls={resetControls}
        restoreItem={null}
        setMode={setMode}
        title="Profile"
        updateItem={updateCurrentUser}
      >
        <Feature.Body>
          <div className="UserForm__Title">
            <ErrorSummary errorMsg={apiError} />
            <h4>User Information</h4>
          </div>

          <div className="UserForm__Container">
            <Control
              Component={TextInput}
              name="email"
              label="Email"
              readOnly
              error={({ value }) => {
                const validEmailPattern = /\S+@\S+\.\S+/;

                if (!value || !value.trim()) {
                  return true;
                }

                if (!validEmailPattern.test(value.toLowerCase())) {
                  return 'Please enter a valid email';
                }
                return false;
              }}
            />

            <Control
              Component={TextInput}
              name="username"
              label="Username"
              readOnly
              endAdornment={
                <Button
                  onClick={() => {}}
                  tooltip="If Google Sign-in service is used, the username must match the email address on Google's linked account."
                  icon="help"
                />
              }
            />

            {(mode === 'edit' || mode === 'create') && (
              <Control
                Component={TextInput}
                name="password"
                defaultValue=""
                type={visibility ? 'text' : 'password'}
                label="Password"
                error={({ value }) => (!value || !value.trim()) && mode === 'create'}
                endAdornment={
                  <Clickable onClick={() => setVisibility(!visibility)}>
                    <Icon icon={visibility ? 'visibility' : 'visibility_off'} />
                  </Clickable>
                }
              />
            )}
            <Control
              Component={TextInput}
              name="firstName"
              label="First Name"
              readOnly={isDriver}
              error={({ value }) => validateName('First', value)}
            />
            <Control
              Component={TextInput}
              name="lastName"
              label="Last Name"
              readOnly={isDriver}
              error={({ value }) => validateName('Last', value)}
            />
            <Control
              Component={SelectInput}
              name="unitSystem"
              label="Units"
              defaultValue={tenant?.unitSystem}
              options={unitSystem}
              error={({ value }) => !value}
            />
            <Control
              Component={ImageInput}
              name="signature"
              label={
                formControls.signature && mode === 'edit'
                  ? 'Signature: Drop image below to upload'
                  : 'Signature'
              }
              dropHint="Drop image here to upload"
              maxKb={10}
            />
          </div>
        </Feature.Body>
      </CrudAdminItem>
    </Feature.Wrapper>
  );
};

Profile.propTypes = {
  mode: PropTypes.string,
  tenant: PropTypes.object,
  updateCurrentUser: PropTypes.func,
  user: PropTypes.object,
};
