import moment from 'moment-timezone';
import { columnDef } from '../../../../../commons/ControlledDataGrid';
import {
  originStringFormatter,
  eventToELDString,
  treatStartOfDayEvent,
  formatDistanceSinceLastGps,
} from '../../../../../../helpers/formats';

export const cycleEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date & time',
    valueGetter: ({ data }) => {
      const { date, startOfDayRecord } = data;
      if (startOfDayRecord) return moment(date).format('YYYY-MM-DD');
      return moment(date).format('HH:mm:ss');
    },
    width: 70,
  }),
  columnDef.textColumn({
    field: 'eventType',
    headerName: 'Event',
    valueFormatter: ({ data }) =>
      eventToELDString(data, treatStartOfDayEvent(data, data.eventType)),
  }),
  columnDef.textColumn({
    field: 'geoLocation',
    headerName: 'Geo-Location',
    valueFormatter: ({ data }) => {
      const { geoLocation } = data;
      if (geoLocation) {
        return treatStartOfDayEvent(data, data.geoLocation);
      }
      return '';
    },
  }),
  columnDef.textColumn({
    field: 'latitude',
    headerName: 'Latitude',
    valueFormatter: ({ data }) => {
      if (!data.location || !data.location.latitude) return '—';
      return treatStartOfDayEvent(data, `${data.location && data.location.latitude.toFixed(2)}`);
    },
  }),
  columnDef.textColumn({
    field: 'longitude',
    headerName: 'Longitude',
    valueFormatter: ({ data }) => {
      if (!data.location || !data.location.longitude) return '—';
      return treatStartOfDayEvent(data, `${data.location && data.location.longitude.toFixed(2)}`);
    },
  }),
  columnDef.distanceColumn({
    field: 'distanceSinceLastGps',
    headerName: 'Distance last val. coord.',
    valueFormatter: ({ data }) => {
      const { distanceSinceLastGps, startOfDayRecord } = data;
      if (startOfDayRecord) {
        return '';
      }
      if (distanceSinceLastGps) {
        return formatDistanceSinceLastGps(treatStartOfDayEvent(data, distanceSinceLastGps));
      }
      return '0 km / 0 mi';
    },
  }),
  columnDef.textColumn({
    field: 'asset',
    headerName: 'CMV',
    valueFormatter: ({ data }) => {
      const { asset } = data;
      if (asset) {
        return treatStartOfDayEvent(data, data.asset);
      }
      return '';
    },
  }),
  columnDef.textColumn({
    field: 'recordStatus',
    headerName: 'Record Status',
    valueFormatter: ({ data }) => {
      const { recordStatus } = data;
      if (recordStatus) {
        return treatStartOfDayEvent(data, data.recordStatus);
      }
      return '';
    },
  }),
  columnDef.textColumn({
    field: 'origin',
    headerName: 'Record Origin',
    valueFormatter: ({ data }) => {
      const { origin, recordOrigin } = data;
      if (origin) {
        return originStringFormatter(treatStartOfDayEvent(data, data.origin));
      }
      if (recordOrigin) {
        return originStringFormatter(treatStartOfDayEvent(data, data.recordOrigin));
      }
      return '';
    },
  }),
  columnDef.textColumn({
    field: 'eldSequence',
    headerName: 'Seq. ID',
    valueFormatter: ({ data }) =>
      treatStartOfDayEvent(
        data,
        `0000${Number(data.eldSequence).toString(16)}`.slice(-4).toUpperCase()
      ),
  }),
];
