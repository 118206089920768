import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Clickable, useBooleanState } from 'stti-react-common';
import { useHistory } from 'react-router-dom';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { Feature } from '../../../../commons/Feature';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel } from '../../../../commons/AdminCommon/AdminSearchPanel';
import { DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import { columnDefs } from './columnDefs';
import { ToggleChip } from '../../../../commons/ToggleChip';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { FEATURES } from '../../../../../features';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const InspectionTemplateSummary = ({
  inspectionTemplates,
  fetchInspectionTemplates,
  deleteInspectionTemplate,
  openInspectionTemplateDetail,
  openInspectionTemplateCreate,
  openVehicleTypeDetail,
  openOrganizationDetail,
  openInspectionTasks,
  route,
}) => {
  useEffect(() => {
    fetchInspectionTemplates();
  }, []);

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const history = useHistory();

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) =>
      !data.isSystem && (
        <>
          <Button
            onClick={() => openInspectionTemplateDetail({ templateId: data.key, mode: 'edit' })}
            icon="edit"
          />
          <Button
            onClick={() => {
              setTargetRow(data);
              openDeleteDialog();
            }}
            icon="delete"
          />
        </>
      ),
    Name: ({ data, value }) => (
      <Clickable
        onClick={() => openInspectionTemplateDetail({ templateId: data.key, mode: 'view' })}
      >
        {value}
      </Clickable>
    ),
    Description: ({ value }) => value || '—',
    AppliesTo: ({ data, value }) =>
      value ? (
        <Clickable onClick={() => openOrganizationDetail({ organizationId: data.ou.key })}>
          {value}
        </Clickable>
      ) : (
        '—'
      ),
    ActiveChip: ({ value }) => <ToggleChip value={`${value}`} />,
    AssetType: ({ value }) =>
      /* eslint-disable  react/jsx-indent */
      value && value.length > 0
        ? value.map((type) => (
            <li key={type.key}>
              <Clickable onClick={() => openVehicleTypeDetail({ assetTypeId: type.key })}>
                {type.name}
              </Clickable>
            </li>
          ))
        : '—',
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });
  // RENDER
  return (
    <Feature.Wrapper className="InspectionTemplateSummary">
      <Feature.Header
        onClose={route.close}
        title="Inspection Templates"
        service="inspectionsTemplates"
      >
        {FEATURES.admin.hideInspectionTasksFromMainMenu && (
          <Button
            icon="task"
            variant="contained"
            label="Inspection Tasks"
            onClick={() =>
              openInspectionTasks({ ...route.matched.values }, { history, root: 'administration' })
            }
          />
        )}
        <Button
          icon="add"
          variant="contained"
          label="Create New Inspection Template"
          onClick={() => openInspectionTemplateCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search By"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={inspectionTemplates}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
          autoHeightColumns
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Inspection Template"
        itemTitle={targetRow.name}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteInspectionTemplate}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

InspectionTemplateSummary.propTypes = {
  inspectionTemplates: customPropTypes.inspectionTemplates.isRequired,
  fetchInspectionTemplates: PropTypes.func.isRequired,
  deleteInspectionTemplate: PropTypes.func.isRequired,
  openInspectionTemplateDetail: PropTypes.func.isRequired,
  openInspectionTemplateCreate: PropTypes.func.isRequired,
  openOrganizationDetail: PropTypes.func.isRequired,
  openVehicleTypeDetail: PropTypes.func.isRequired,
  openInspectionTasks: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
