import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'stti-react-common';

import { DriverLogDataTransferDialog } from './DriverLogDataTransferDialog';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const DriverLogDataTransferMenu = ({
  className = '',
  logs,
  disabled,
  user,
  ...restProps
}) => {
  const [dialogState, setDialogState] = useState({ isOpen: false });

  return (
    <>
      <Menu
        className={`Report__DriverLogDataTransferDialog ${className}`}
        label="Data Transfer"
        icon="cloud_upload"
        tooltip="Data Transfer"
        disabled={disabled}
        items={[
          {
            label: 'General Purpose',
            onClick: () => setDialogState({ isOpen: true }),
          },
          {
            label: 'US Audit',
            onClick: () => setDialogState({ isOpen: true, mode: { agency: 'FMCSA' } }),
          },
          {
            label: 'Canada Audit',
            onClick: () => setDialogState({ isOpen: true, mode: { agency: 'CVSE' } }),
          },
        ]}
        {...restProps}
      />
      {!disabled && dialogState.isOpen && (
        <DriverLogDataTransferDialog
          isOpen={dialogState.isOpen}
          mode={dialogState.mode}
          onClose={() => setDialogState({ isOpen: false })}
          logs={logs}
          user={user}
        />
      )}
    </>
  );
};

DriverLogDataTransferMenu.propTypes = {
  className: PropTypes.string,
  logs: customPropTypes.driverLogs.isRequired,
  disabled: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};
