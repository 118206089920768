import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'stti-react-common';
import { RequestsProgressIndicator } from '../RequestsProgressIndicator';

import './Feature.scss';

// note that FeatureHeader root element cannot be <header> to due to navigation.less

export const Header = ({
  title,
  subtitle,
  className = '',
  children,
  onClose,
  service,
  ...restProps
}) => (
  <div className={`Feature__Header ${className}`} {...restProps}>
    {onClose && (
      <Button className="Feature__Header__closeButton" icon="arrow_back" onClick={onClose} />
    )}
    <h1 className="Feature__Header__title">{title}</h1>
    <h2 className="Feature__Header__subtitle">{subtitle}</h2>
    <div className="Feature__Header__children">
      {service && <RequestsProgressIndicator service={service} />}
      {children}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  service: PropTypes.string,
};
