import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, TextInput, Form } from 'stti-react-common';

const { useFormController, Control } = Form;

export const EditExceptionAnnotationDialog = ({
  comment,
  isOpen,
  onClose,
  onConfirm,
  ...restProps
}) => {
  const form = useFormController();

  const { resetControls, controls, hasErrors } = form;

  return (
    <BasicDialog
      title={comment ? 'Edit Review' : 'Add Review'}
      className="ExceptionDetailReport__EditExceptionAnnotationDialog"
      maxWidth="xs"
      fullWidth
      isOpen={isOpen}
      onOpen={resetControls}
      onClose={onClose}
      buttons={[
        { label: 'Cancel', onClick: onClose },
        {
          label: 'Save',
          onClick: () => {
            onConfirm(controls);
            onClose();
          },
          disabled: hasErrors,
        },
      ]}
      {...restProps}
    >
      <Form form={form}>
        <Control
          name="comment"
          Component={TextInput}
          label="Comment"
          error={({ value }) => !value.trim()}
          defaultValue={comment || ''}
          multiline
          autoFocus
        />
      </Form>
    </BasicDialog>
  );
};

EditExceptionAnnotationDialog.propTypes = {
  comment: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
