import { connect } from 'react-redux';

import { InspectionsSummaryReport } from './InspectionsSummaryReport';
import {
  selectInspections,
  fetchInspectionsOptimized,
  fetchMyInspectionsOptimized,
  fetchInspectorsLatestInspectionsOptimized,
  fetchVehiclesLatestInspectionsOptimized,
  createLocalReportView,
} from '../../../../data/reports';
import {
  selectSystemActiveOu,
  selectSystemUser,
  setPrintMode,
  selectSystemOus,
} from '../../../../data/system';

export const InspectionsSummaryReportContainer = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    inspections: selectInspections(state),
    user: selectSystemUser(state),
    limits: { forAllOus: 7, forOu: 30 },
    ous: selectSystemOus(state),
  }),
  {
    fetchInspections: fetchInspectionsOptimized,
    fetchMyInspections: fetchMyInspectionsOptimized,
    fetchInspectorsLatestInspections: fetchInspectorsLatestInspectionsOptimized,
    fetchVehiclesLatestInspections: fetchVehiclesLatestInspectionsOptimized,
    createLocalReportView,
    setPrintMode,
  }
)(InspectionsSummaryReport);
