import _, { filter, find, clone, last } from 'lodash';
import dayjs from 'dayjs';

const notificationList = [];

export const addMessageNotification = (notification) => {
  if (
    notificationList &&
    find(notificationList, function findUser(o) {
      if (!o || !o.recipient) return false;
      return o.recipient.reference === notification.recipient.reference;
    }) === undefined
  ) {
    notificationList.push(notification);
  }
};

/**
 * Extract the sent time for a message and make it more convenient to access
 * @param {Object} message
 */
export const setMessageSent = (message) => {
  const cloneMsg = clone(message);
  cloneMsg.sent = find(message.events, { event: 'Sent' });
  return cloneMsg;
};

/**
 * Sets the message status (Seen/Acknowledged) to be shown under a message.
 * @param message
 * @returns {Object}
 */
export const setMessageStatus = (message) => {
  const cloneMsg = clone(message);
  const recipient = cloneMsg.viewRecipient.reference;
  const recipientEvents = filter(cloneMsg.events, { participant: recipient });

  const acknowledged = find(recipientEvents, { event: 'Acknowledged' });
  if (acknowledged) {
    cloneMsg.status = `✔ Acknowledged ${dayjs(acknowledged.date).format('MMM D, YYYY')}`;
    return cloneMsg;
  }

  const seen = find(recipientEvents, { event: 'Read' });
  if (seen) {
    cloneMsg.status = `✔ Seen ${dayjs(seen.date).format('MMM D, YYYY')}`;
  }

  return cloneMsg;
};

/**
 * Creates a user-readable sent event summary for the message
 *
 * @param {Object} message
 * @return {Object}
 */
export const setMessageEventSummary = (message) => {
  const cloneMsg = clone(message);
  let events;
  const output = [];
  const recipient = cloneMsg.viewRecipient;

  output.push(`Sent by ${cloneMsg.authorName} ${dayjs(cloneMsg.date).format('MMM D, YYYY')}`);

  if (recipient._type !== 'Organization') {
    events = _.filter(cloneMsg.events, { participant: recipient.reference });

    _.each(events, function eachEvent(event) {
      if (event.event === 'Read' || event.event === 'Received') {
        output.push(
          `${event.event} by ${recipient.name} ${dayjs(event.date).format('MMM D, YYYY')}`
        );
      }
    });
  }

  cloneMsg.eventSummary = output.join('\n');
  return cloneMsg;
};

/**
 * Enhance the message objects received by the server in order to not need to
 * constantly re-calculate values on the client or for easier accessibility.
 *
 * @param {Array<Object>} messages Messages to enhance
 * @returns {Array<Object>} Enhanced messages
 */
export const enhanceMessages = (messages) =>
  _(messages).map(setMessageSent).map(setMessageStatus).map(setMessageEventSummary).valueOf();

export const getUnreadMessages = (msgs, driverKey) => {
  const enhancedMsgs = enhanceMessages(msgs);

  const recentMessages = last(enhancedMsgs.filter((row) => row.author === driverKey));

  const isUnread =
    recentMessages?.events.every((row) => row.event !== 'Read') &&
    recentMessages?.author === driverKey;

  return isUnread;
};
