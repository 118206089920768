import { isFinite, flatten } from 'lodash';
import {
  getTimelessDate,
  getDatelessTime,
  toHourMinute,
  toMoment,
  getNumberFromDateTime,
} from '../../../../../helpers/moment';

const getFormattedDay = (date, timeZone) => getTimelessDate(toMoment(date, timeZone).format());
const getFormattedHour = (date, timeZone) => getDatelessTime(date, timeZone, 'HH:mm:ss');

export const getSelectedDateTime = (found, value) => {
  const targetDate = `${toMoment(
    `${getTimelessDate(found.local.startDate)}T${toHourMinute({
      value,
      format: 'HH:mm:ss',
    })}`
  )
    .format()
    .slice(0, -6)}`;

  return targetDate;
};

export const inRange = (value, col) => {
  const [first, last] = col;
  return value >= first && value <= last;
};

export const getFormDates = (selectedInterval, formControls, timeZone) => {
  // Surfsight treats time field in our form as the middle of the video, between the seconds before and seconds after
  const dateTime = `${getTimelessDate(selectedInterval.selectedDate)}T${formControls.time}`;
  const isoStartDate = toMoment(dateTime, timeZone).toISOString();

  return { isoStartDate, duration: formControls.duration };
};

export const getCompareDate = (timelessDate, value) =>
  `${toMoment(
    `${getTimelessDate(timelessDate)}T${toHourMinute({
      value,
      format: 'HH:mm:ss',
    })}`
  )
    .format()
    .slice(0, -6)}`;

export const getNumberFromHourMinute = (timelessDate, time, timeZone) =>
  getNumberFromDateTime(`${getTimelessDate(timelessDate)}T${time}`, timeZone, 'seconds') / 60;

export const getPointerValues = (startDate, endDate, timeZone) => {
  if (!startDate || !endDate) return [0, 0];

  const startDay = getFormattedDay(startDate, timeZone);
  const startHour = getFormattedHour(startDate, timeZone);

  const endDay = getFormattedDay(endDate, timeZone);
  const endHour = getFormattedHour(endDate, timeZone);

  const isSameDay = toMoment(startDay).isSame(endDay, 'day');

  const startPointer = getNumberFromHourMinute(startDay, startHour, timeZone);
  const endPointer = getNumberFromHourMinute(endDay, endHour, timeZone);

  return isSameDay
    ? [startPointer, endPointer]
    : [startPointer, getNumberFromHourMinute(startDay, '23:59:59', timeZone)];
};

export const getLocalDates = (startDate, endDate, timeZone) => {
  if (!startDate || !endDate) return [0, 0];

  const startDay = getFormattedDay(startDate, timeZone);

  const endDay = getFormattedDay(endDate, timeZone);

  const isSameDay = toMoment(startDay).isSame(endDay, 'day');

  return isSameDay
    ? {
        startDate: toMoment(startDate, timeZone).format(),
        endDate: toMoment(endDate, timeZone).format(),
      }
    : {
        startDate: toMoment(startDate, timeZone).format(),
        endDate: toMoment(startDate, timeZone).endOf('Day').format(),
      };
};

export const getCompassPoint = (heading) => {
  if (!isFinite) return '';
  const compassPoints = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return compassPoints[(heading / (360 / compassPoints.length)).toFixed() % compassPoints.length];
};

export const getDateDuration = ({ eventAt, duration, timeZone, timeline }) => {
  const numDuration = parseFloat(duration) / 2;
  const noSecondsBefore = !!timeline.find(({ local }) => local.startDate === eventAt);
  if (noSecondsBefore) {
    return toMoment(eventAt, timeZone).format('HH:mm:ss');
  }
  return toMoment(eventAt, timeZone).subtract(numDuration, 'seconds').format('HH:mm:ss');
};

export const getSurfSightDuration = ({ eventAt, duration, timeline }) => {
  const durationNumber = parseFloat(duration);
  const noSecondsBefore = !!timeline.find(({ local }) => local.startDate === eventAt);
  return noSecondsBefore ? durationNumber / 2 : durationNumber;
};

export const remapIntervals = (intervals, timeZone) =>
  // [{startDate: '2023-03-30T00:26:37.000Z', endDate: '2023-03-30T08:26:25.000Z'}]

  flatten(
    intervals.map((row) => {
      const startDay = getFormattedDay(row.startDate, timeZone);
      const endDay = getFormattedDay(row.endDate, timeZone);
      const isSameDay = toMoment(startDay).isSame(endDay, 'day');

      const isDST = toMoment(startDay, timeZone).isDST();

      return isSameDay
        ? {
            startDate: row.startDate,
            endDate: row.endDate,
            isSameDay: true,
          }
        : [
            {
              startDate: row.startDate,
              endDate: toMoment(row.startDate, timeZone)
                .endOf('day')
                .add(isDST ? 1 : 0, 'hour')
                .toISOString(),
              isSameDay: false,
            },
            {
              startDate: toMoment(row.endDate, timeZone)
                .startOf('day')
                .add(isDST ? 1 : 0, 'hour')
                .toISOString(),
              endDate: row.endDate,
              isSameDay: false,
            },
          ];
    })
  );
