import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'stti-react-common';
import moment from 'moment-timezone';
import { includes } from 'lodash';

import { deriveDateRangeFromMode } from '../../../../helpers/reports/reportUtils';
import { filterRowsByDateRange } from '../../../../helpers/reports/standardFilters';
import { ReportControl } from '../ReportControl';
import {
  dateRangeModeOptions,
  dateRangeForOptions,
  renderDateRangeSummary,
} from '../DateRangeModeSupport';

const filterDateRangeOptions = (options, filters) => {
  if (filters.length === 0) {
    return options.filter(
      ({ value }) =>
        value !== '1q' &&
        value !== '2q' &&
        value !== '3q' &&
        value !== '4q' &&
        value !== 'january' &&
        value !== 'february' &&
        value !== 'march' &&
        value !== 'april' &&
        value !== 'may' &&
        value !== 'june' &&
        value !== 'july' &&
        value !== 'august' &&
        value !== 'september' &&
        value !== 'october' &&
        value !== 'november' &&
        value !== 'december'
    );
  }

  const filteredOptions = options
    .map((option) => {
      const mapOption = { ...option };
      return includes(filters, mapOption.value) ? mapOption : false;
    })
    .filter(Boolean);

  return filteredOptions;
};

export const DateRangeModeInput = ({
  queryFor,
  defaultDateRangeFor,
  canSelectDateRangeFor,
  filterDateRange,
  disableDateRange,
}) => (
  <>
    <ReportControl
      name="dateRangeFor"
      group="query"
      label="Based On"
      defaultValue={defaultDateRangeFor}
      render={(renderProps) => (
        <SelectInput {...renderProps} options={dateRangeForOptions(queryFor)} />
      )}
      printConfig={({ value }) => SelectInput.labelForValue(dateRangeForOptions(queryFor), value)}
      disabled={!canSelectDateRangeFor}
      autoGridArea
    />
    <ReportControl
      name="dateRangeMode"
      group="query"
      label="Date Range"
      defaultValue="today"
      render={(renderProps) => (
        <SelectInput
          {...renderProps}
          options={filterDateRangeOptions(dateRangeModeOptions, filterDateRange)}
        />
      )}
      renderSummary={(renderProps, renderContext) =>
        renderDateRangeSummary(renderProps, renderContext, queryFor)
      }
      printConfig={({ value, controls, formatUnit }) => {
        const showEndDate = controls.startDate !== controls.endDate;
        return (
          value &&
          `${SelectInput.labelForValue(dateRangeModeOptions, value)} (${formatUnit(
            'timelessDate',
            moment(controls.startDate)
          )}${showEndDate ? ` to ${formatUnit('timelessDate', moment(controls.endDate))}` : ''})`
        );
      }}
      reducerCallback={(draft, value) => {
        [draft.controls.startDate, draft.controls.endDate] = deriveDateRangeFromMode(
          value,
          draft.controls.startDate,
          draft.controls.endDate,
          draft.controls.selectedYear
        );
      }}
      reducerCallbackOnRestore
      filterConfig={{
        type: filterRowsByDateRange,
        group: 'query',
        getValues: ({ controls, formats }) => ({
          startDate: controls.startDate,
          endDate: controls.endDate,
          timeZone: formats.timeZone,
          field: controls.dateRangeFor || 'endedAt', // must have default value for filter
        }),
      }}
      disabled={disableDateRange}
      autoGridArea
    />
  </>
);

DateRangeModeInput.propTypes = {
  queryFor: PropTypes.string.isRequired,
  defaultDateRangeFor: PropTypes.oneOf(['startedAt', 'endedAt']),
  canSelectDateRangeFor: PropTypes.bool,
  filterDateRange: PropTypes.array,
  disableDateRange: PropTypes.bool,
};
