import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel } from 'stti-react-common';

import { GridPanelMenu } from '../GridPanelMenu';
import { GridPanelSummary } from '../GridPanelSummary';
import { SearchTextInput } from '../SearchTextInput';
import { useReport } from '../useReport';
import { GridAutoExpandGroups } from '../GridAutoExpandGroups';
import { ControlledDataGrid } from '../../ControlledDataGrid';

export const DataGridPanel = ({
  dataGridComponents,
  title = 'Data Table',
  children,
  ...restProps
}) => {
  const {
    setControl,
    dataGridController,
    filteredRows,
    setDataGridFilteredRows,
    debouncedControls,
  } = useReport();

  const handleExpandOrCollapseAll = useCallback(
    ({ source }) => setControl('dataGridAutoExpandGroups', source === 'expandAll'),
    []
  );

  return (
    <>
      <ExpansionPanel
        title={title}
        isLocked
        summary={
          <GridPanelSummary>
            <SearchTextInput />
          </GridPanelSummary>
        }
        summaryEnd={
          <>
            {children}
            <GridPanelMenu />
          </>
        }
      />
      <ControlledDataGrid
        theme="balham"
        controller={dataGridController}
        components={dataGridComponents}
        rows={filteredRows.output}
        onRowsFiltered={setDataGridFilteredRows}
        searchText={debouncedControls.searchText}
        onExpandOrCollapseAll={handleExpandOrCollapseAll}
        {...restProps}
      />
      <GridAutoExpandGroups />
    </>
  );
};

DataGridPanel.propTypes = {
  dataGridComponents: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
};
