import { useState, useCallback } from 'react';
import { isFunction } from 'lodash';

export const useControlsState = (initialStateOrInitializer = {}) => {
  const baseState = isFunction(initialStateOrInitializer)
    ? initialStateOrInitializer()
    : initialStateOrInitializer;
  const [controls, setControls] = useState(baseState);
  const resetControls = () => setControls(baseState);
  const setControl = useCallback((name, value) => setControls({ ...controls, [name]: value }));
  return [controls, setControls, setControl, resetControls];
};
