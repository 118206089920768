import { connect } from 'react-redux';

import { EditGeozoneDialog } from './EditGeozoneDialog';
import { selectSystemTenant } from '../../../../data/system';

import { locationType } from '../../../../data/administration/resources';

export const EditGeozoneDialogContainer = connect(
  (state) => ({
    geozoneTypes: locationType.selectors.selectLocationTypes(state),
    tenant: selectSystemTenant(state),
  }),
  {}
)(EditGeozoneDialog);
