import { connect } from 'react-redux';

import { ApiKeyDetail } from './detail';
import { selectSystemUser } from '../../../../../data/system';

import { apiKey } from '../../../../../data/administration/configurations';

export const ApiKeyDetailContainer = connect(
  (state) => ({
    apiKeys: apiKey.selectors.selectApiKeys(state),
    userName: selectSystemUser(state).fullName,
  }),
  {
    createApiKey: apiKey.actions.createApiKey,
  }
)(ApiKeyDetail);
