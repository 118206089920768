import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import './ErrorSummary.scss';

export const ErrorSummary = ({ errorMsg }) => {
  if (!errorMsg) return null;
  return (
    <Card className="ErrorSummary__Card" fullWidth>
      <h4>Error Summary</h4>
      <ul>
        <li>{errorMsg}</li>
      </ul>
    </Card>
  );
};

ErrorSummary.propTypes = {
  errorMsg: PropTypes.string,
};
