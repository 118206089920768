import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Form } from 'stti-react-common';

import { valueIsNull } from './common';

const { Control } = Form;

export const LoadedStatus = ({ defaultValues, error = valueIsNull }) => (
  <div className="Report__EditExceptionRuleDialog__RuleComponent">
    While&nbsp;
    <Control
      Component={SelectInput}
      name="loadedStatus"
      defaultValue={defaultValues.loadedStatus}
      error={error}
      options={[
        {
          label: 'loaded or unloaded',
          value: 'any',
        },
        {
          label: 'loaded',
          value: 'loaded',
        },
        {
          label: 'unloaded',
          value: 'unloaded',
        },
      ]}
    />
  </div>
);

LoadedStatus.propTypes = {
  defaultValues: PropTypes.shape({
    loadedStatus: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
