import { connect } from 'react-redux';

import { NaviMap } from './NaviMap';
import { selectPrintMode, selectIsUserSuperAdmin } from '../../../data/system';
import { locationType } from '../../../data/administration/resources';

export const NaviMapContainer = connect((state, { printLayoutSuppressMap }) => ({
  geozoneTypes: locationType.selectors.selectLocationTypes(state),
  printLayoutSuppressMapMode: selectPrintMode(state).suppressMap,
  printLayoutSuppressMapProp: printLayoutSuppressMap,
  canEditGeozones: selectIsUserSuperAdmin(state),
}))(NaviMap);
