import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, BasicDialog } from 'stti-react-common';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../commons/ControlledDataGrid';
import { Feature } from '../../commons/Feature';
import { EditReportMetaDialog } from './EditReportMetaDialog';
import { editingColumnDefs as columnDefs } from './columnDefs';

import './ManageReports.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

const ConfirmDeleteDialog = ({ isOpen, onClose, onDelete, reportView }) => (
  <BasicDialog
    isOpen={isOpen}
    onClose={onClose}
    title="Confirm Delete Report"
    buttons={[
      {
        label: 'Cancel',
        onClick: onClose,
      },
      {
        label: 'Delete',
        onClick: () => {
          onDelete(reportView);
          onClose();
        },
      },
    ]}
  >
    <span>Are you sure you want to delete {reportView.title}?</span>
  </BasicDialog>
);

ConfirmDeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  reportView: PropTypes.object.isRequired,
};

export const ManageReports = ({
  reportViews,
  reportType,
  fetchReportViewsByReportType,
  updateReportView,
  deleteReportView,
  activeOu,
}) => {
  useEffect(() => {
    if (reportType) {
      fetchReportViewsByReportType(reportType);
    }
    return () => null;
  }, []);

  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetReport, setTargetReport] = useState({});

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          onClick={() => {
            setTargetReport(data);
            setCurrentDialog('edit');
          }}
          disabled={!data.permissions.canEdit}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetReport(data);
            setCurrentDialog('delete');
          }}
          disabled={!data.permissions.canDelete}
          icon="delete"
        />
      </>
    ),
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  return (
    <>
      <div className="ManageReports">
        <Feature.Header title="Manage Reports" service="reportViews" />
        <ControlledDataGrid
          controller={dataGridController}
          components={dataGridComponents}
          rows={reportViews}
          sizeColumnsToFit
        />
      </div>
      <ConfirmDeleteDialog
        isOpen={currentDialog === 'delete'}
        onClose={() => setCurrentDialog(null)}
        onDelete={deleteReportView}
        reportView={targetReport}
      />
      <EditReportMetaDialog
        isOpen={currentDialog === 'edit'}
        onClose={() => setCurrentDialog(null)}
        onSave={updateReportView}
        getReportView={() => targetReport}
        activeOu={activeOu}
      />
    </>
  );
};

ManageReports.propTypes = {
  reportViews: PropTypes.array.isRequired,
  reportType: PropTypes.string.isRequired,
  fetchReportViewsByReportType: PropTypes.func.isRequired,
  updateReportView: PropTypes.func.isRequired,
  deleteReportView: PropTypes.func.isRequired,
  activeOu: PropTypes.object.isRequired,
};
