import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Information',
    children: [
      columnDef.textColumn({
        field: 'taskName',
        headerName: 'Task',
      }),
      columnDef.textColumn({
        field: 'typeName',
        headerName: 'Defect Type',
      }),
      columnDef.enabledDisabledColumn({
        field: 'isMinor',
        headerName: 'Severity',
        cellRenderer: 'SeverityToggleChip',
        trueLabelText: 'Minor',
        falseLabelText: 'Major',
      }),
      columnDef.enabledDisabledColumn({
        field: 'isRepaired',
        headerName: 'Status',
        cellRenderer: 'RepairedToggleChip',
        trueLabelText: 'Repaired',
        falseLabelText: 'Not Repaired',
      }),
      columnDef.textColumn({
        field: 'position',
        headerName: 'Position',
      }),
    ],
  },
  {
    headerName: '',
    children: [
      columnDef.actionButtonsColumn({
        cellRenderer: 'Actions',
      }),
    ],
  },
];
