import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, ProgressOverlay } from 'stti-react-common';

import { uniqueId, includes } from 'lodash';
import {
  ManageReportsDialog,
  EditReportMetaDialog,
  OpenReportDialog,
  SendReportDialog,
} from '../../../reports/ManageReports';

import { useReport } from '../useReport';

import { Location } from '../../../../helpers/navigation/Location';
import { FEATURES } from '../../../../features';

export const ReportMenu = ({
  reportViews,
  fetchReportViewsByReportType,
  createReportView,
  updateReportView,
  createOrUpdateDefaultReportView,
  createReportSchedule,
  createReportOnDemand,
  activeOu,
  tenantKey,
  userKey,
  canIncludeDetailInSendReport,
  limitIncludeDetailInSendReport,
  hideExportOptionsInSendReport,
}) => {
  const [currentDialog, setCurrentDialog] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  const {
    meta,
    resetState,
    revertState,
    snapshotReport,
    reportWasSaved,
    reportType,
    reportTypeName,
    route,
    reportViewIdType,
    controls,
    filteredRows,
  } = useReport();

  const includeDetailInScheduleReportLimitExceededNotice = !!canIncludeDetailInSendReport &&
    !!limitIncludeDetailInSendReport &&
    controls.startDate !== controls.endDate && (
      <div>
        Include Detail Reports is not available when scheduling this report with a date range
        greater than a single day.
      </div>
    );

  const includeDetailInEmailReportLimitExceededNotice = !!canIncludeDetailInSendReport &&
    !!limitIncludeDetailInSendReport &&
    filteredRows.dataGrid.length > 150 && (
      <div>
        Include Detail Reports is not available when emailing this report with more than 150 detail
        reports.
      </div>
    );

  const handleCloseDialog = () => setCurrentDialog(null);

  const handleOpenReportView = ({ id }) => {
    if (id === meta.id) {
      // report is currently open, just reset all
      revertState();
    } else {
      // navigate to selected reportView
      route.open({ ...route.matched.values, reportViewId: id }, { replace: meta.defaultView });
    }
  };

  const handleCreateReportView = async (editedReportView) => {
    setIsWorking(true);
    try {
      const newReport = await createReportView({ ...snapshotReport(), ...editedReportView }); // always get latest snapshot
      route.open(
        { ...route.matched.values, reportViewId: newReport.id },
        { replace: meta.defaultView }
      );
    } catch {
      // do nothing; failed action triggers notification
    } finally {
      setIsWorking(false);
    }
  };

  const handleSaveDefaultReportView = async (isHidden) => {
    if (!meta.defaultView) setIsWorking(true);
    try {
      await createOrUpdateDefaultReportView({ ...snapshotReport(), hidden: isHidden });
      reportWasSaved();
      if (!meta.defaultView) route.open({ reportViewId: 'default' });
    } catch {
      // do nothing; failed action triggers notification
    } finally {
      if (!meta.defaultView) setIsWorking(false);
    }
  };

  const handleSaveCurrentReportView = async (isHidden) => {
    try {
      await updateReportView({ ...snapshotReport(), hidden: isHidden });
      reportWasSaved();
    } catch {
      // do nothing; failed action triggers notification
    }
  };

  const handleCreateReportSchedule = async ({
    toEmails,
    hour,
    endDate,
    attach,
    includeDetail,
    orientation,
  }) => {
    const { title } = snapshotReport();
    if (meta.isDirty) {
      await (meta.defaultView ? handleSaveDefaultReportView() : handleSaveCurrentReportView());
    }

    const location = new Location();
    location.pathname = route.matched.path;
    createReportSchedule({
      title: `${reportTypeName}: ${reportViewIdType === 'default' ? 'Default' : title}`,
      toEmails,
      hour,
      endDate,
      userKey,
      tenantKey,
      ouKey: activeOu.key,
      attach,
      timeZone: activeOu.timeZone,
      location,
      includeDetail,
      orientation,
    });
  };

  const handleCreateReportOnDemand = async ({ toEmails, attach, includeDetail, orientation }) => {
    setIsWorking(true);
    const editedReportView = { hidden: true, title: uniqueId('temporary-') };
    try {
      const newReport = await createReportView({ ...snapshotReport(), ...editedReportView }); // always get latest snapshot
      route.open({ ...route.matched.values, reportViewId: 'default' }, { replace: true }); // we will always return to default view since we will not show these temporary views

      const location = new Location();
      location.pathname = route.matched.path;

      createReportOnDemand({
        id: newReport.id,
        title: `${reportTypeName}: ${newReport.id}`,
        toEmails,
        userKey,
        ouKey: activeOu.key,
        attach,
        location,
        includeDetail,
        orientation,
        timeZone: activeOu.timeZone,
      });
    } catch {
      // do nothing; failed action triggers notification
    } finally {
      setIsWorking(false);
    }
  };

  const enableScheduleReport = useMemo(() => {
    if (
      controls.modeSelector &&
      includes(
        ['latestInspectionsByVehicle', 'latestInspectionsByInspector'],
        controls.modeSelector
      )
    ) {
      return true;
    }
    return (
      (controls.dateRangeMode && controls.dateRangeMode !== 'custom') || route.isAllowedToSchedule
    );
  }, [controls, route]);

  return (
    <>
      <Menu
        className="Report__ReportMenu"
        label="Report"
        icon="folder_open"
        tooltip="Report Menu"
        items={[
          {
            label: 'New',
            onClick: () => {
              meta.defaultView
                ? revertState()
                : route.open({ ...route.matched.values, reportViewId: 'default' });
            },
          },
          {
            label: 'Open...',
            onClick: () => {
              fetchReportViewsByReportType(meta.reportType);
              setCurrentDialog('open');
            },
          },
          {
            label: 'Save Current Report',
            disabled: !meta.canSave,
            onClick: () => {
              handleSaveCurrentReportView();
            },
          },
          {
            label: 'Save As...',
            onClick: () => {
              setCurrentDialog('saveAs');
            },
          },
          {
            label: 'Save As Default',
            disabled: !meta.isDirty,
            onClick: () => {
              handleSaveDefaultReportView();
            },
          },
          {
            label: `Revert Report To ${meta.defaultView ? 'Default' : 'Saved'}`,
            disabled: !meta.isDirty,
            onClick: () => {
              revertState();
            },
          },
          {
            label: 'Reset Report',
            onClick: () => {
              resetState();
            },
          },
          <hr />,
          {
            label: 'Email Report...',
            onClick: () => {
              setCurrentDialog('email');
            },
          },
          {
            label: 'Schedule Report...',
            onClick: () => {
              fetchReportViewsByReportType(meta.reportType);
              setCurrentDialog('schedule');
            },
            disabled: FEATURES.reports.disableDateRangeAndDetailReportsOnSchedule
              ? !enableScheduleReport
              : false,
          },
          {
            label: 'Manage Reports...',
            onClick: () => {
              setCurrentDialog('manage');
            },
          },
        ]}
      />
      <OpenReportDialog
        isOpen={currentDialog === 'open'}
        onClose={handleCloseDialog}
        onItemClick={handleOpenReportView}
        items={reportViews}
      />
      <EditReportMetaDialog
        isOpen={currentDialog === 'saveAs'}
        getReportView={snapshotReport} // must refresh this on create
        onClose={handleCloseDialog}
        onSave={handleCreateReportView}
        activeOu={activeOu}
        title="Save New Report"
        reportViews={reportViews}
      />
      <ManageReportsDialog
        isOpen={currentDialog === 'manage'}
        onClose={handleCloseDialog}
        reportType={reportType}
      />
      <SendReportDialog
        mode="schedule"
        isOpen={currentDialog === 'schedule'}
        onClose={handleCloseDialog}
        onConfirm={handleCreateReportSchedule}
        onReportSave={handleCreateReportView}
        isReportDirty={meta.isDirty}
        isReportPersisted={reportViewIdType !== 'local'}
        canIncludeDetail={canIncludeDetailInSendReport}
        includeDetailLimitExceededNotice={includeDetailInScheduleReportLimitExceededNotice}
        hideExportOptions={hideExportOptionsInSendReport}
        reportViews={reportViews}
        snapshotReport={snapshotReport}
        activeOu={activeOu}
      />
      <SendReportDialog
        mode="email"
        isOpen={currentDialog === 'email'}
        onClose={handleCloseDialog}
        onConfirm={handleCreateReportOnDemand}
        isReportDirty={meta.isDirty}
        isReportPersisted={reportViewIdType !== 'local'}
        canIncludeDetail={canIncludeDetailInSendReport}
        includeDetailLimitExceededNotice={includeDetailInEmailReportLimitExceededNotice}
        hideExportOptions={hideExportOptionsInSendReport}
      />
      <ProgressOverlay isOpen={isWorking} />
    </>
  );
};

ReportMenu.propTypes = {
  reportViews: PropTypes.array.isRequired,
  fetchReportViewsByReportType: PropTypes.func.isRequired,
  createReportView: PropTypes.func.isRequired,
  updateReportView: PropTypes.func.isRequired,
  createOrUpdateDefaultReportView: PropTypes.func.isRequired,
  createReportSchedule: PropTypes.func.isRequired,
  createReportOnDemand: PropTypes.func.isRequired,
  activeOu: PropTypes.object.isRequired,
  tenantKey: PropTypes.any, // potentially unavailable when first loading
  userKey: PropTypes.string, // potentially unavailable when first loading
  canIncludeDetailInSendReport: PropTypes.bool,
  limitIncludeDetailInSendReport: PropTypes.bool,
  hideExportOptionsInSendReport: PropTypes.bool,
};
