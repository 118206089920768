import { connect } from 'react-redux';

import { InspectionTemplateDetail } from './detail';

import { selectSystemOus } from '../../../../../data/system';

import {
  inspectionTemplate,
  inspectionTask,
} from '../../../../../data/administration/configurations';

import { vehicleType } from '../../../../../data/administration/resources';

export const InspectionTemplateDetailContainer = connect(
  (state, props) => ({
    inspectionTemplates: inspectionTemplate.selectors.selectInspectionTemplates(state),
    inspectionTemplate: inspectionTemplate.selectors.selectInspectionTemplate(state, props),
    inspectionTasks: inspectionTask.selectors.selectInspectionTasks(state),
    vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
    ous: selectSystemOus(state),
  }),
  {
    fetchInspectionTemplate: inspectionTemplate.actions.fetchInspectionTemplateOptimized,
    fetchInspectionTemplates: inspectionTemplate.actions.fetchInspectionTemplatesOptimized,
    createInspectionTemplate: inspectionTemplate.actions.createInspectionTemplate,
    updateInspectionTemplate: inspectionTemplate.actions.updateInspectionTemplate,
    deleteInspectionTemplate: inspectionTemplate.actions.deleteInspectionTemplate,
    fetchInspectionTasks: inspectionTask.actions.fetchInspectionTasksOptimized,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
  }
)(InspectionTemplateDetail);
