import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intersection, isNil } from 'lodash';
import { SelectInput, Form } from 'stti-react-common';

import {
  selectSystemActiveOu,
  setFilteredOus,
  setFilteredOuMode,
  selectSystemOus,
} from '../../../../data/system';

const OPTIONS = [
  { label: 'Selected organization', value: 'default' },
  {
    label: 'All organizations',
    value: 'all',
  },
  {
    label: 'Custom selection',
    value: 'custom',
  },
];

export const OusSelector = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    ous: selectSystemOus(state),
  }),
  { setFilteredOus, setFilteredOuMode }
)(({ activeOu, ous, setFilteredOus: selectedOus, setFilteredOuMode: selectedOuMode }) => {
  const customOuOptions = ous.map((ou) => ({ label: ou.name, value: ou.key }));

  const { useFormController, Control } = Form;
  const form = useFormController();
  const {
    controls: { ouMode, ousSelected },
    resetControls,
  } = form;

  useEffect(() => {
    resetControls(); // reset values at load page
  }, []);

  const setFilters = (currentOuKeys, currentOuMode) => {
    selectedOus(currentOuKeys);
    selectedOuMode(currentOuMode);
  };

  useEffect(() => {
    let ouKeys;
    switch (ouMode) {
      case 'default':
        ouKeys = [activeOu.key];
        break;
      case 'all':
        ouKeys = ous.map((ou) => ou.key);
        break;
      case 'custom': {
        ouKeys = intersection(
          ousSelected,
          ous.map(({ key }) => key)
        );
        break;
      }
      default: {
        ouKeys = [];
      }
    }
    setFilters(ouKeys, ouMode);

    return () => {
      setFilters([], 'default');
    };
  }, [activeOu, ous, ouMode, ousSelected]);

  return (
    <Form form={form}>
      <Control
        Component={SelectInput}
        name="ouMode"
        label="Organization"
        error={({ value }) => isNil(value)}
        options={OPTIONS}
        defaultValue="default"
        autoGridArea
      />
      {ouMode === 'custom' && (
        <Control
          Component={SelectInput}
          name="ousSelected"
          label="Selected Organizations"
          defaultValue={[]}
          error={({ controls, value }) =>
            controls.ouMode === 'custom' &&
            value.length === 0 &&
            'At least one organization is required.'
          }
          options={customOuOptions}
          multiple
          multipleCollapseAt={2}
          multipleCollapseText="organizations"
          autoGridArea
        />
      )}
    </Form>
  );
});

OusSelector.propTypes = {
  activeOu: PropTypes.shape({ key: PropTypes.string.isRequired }),
  hideAllOrganizations: PropTypes.bool,
  onlyAllOrganizations: PropTypes.bool,
  ous: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ),
};
