import React from 'react';
import PropTypes from 'prop-types';

import './AdminColorDisplay.scss';

export const AdminColorDisplay = ({ color }) => (
  <div className="AdminColorDisplay">
    <div className="AdminColorDisplay__colorSwatch" style={{ backgroundColor: color }} />
    &nbsp;{color}
  </div>
);

AdminColorDisplay.propTypes = {
  color: PropTypes.string.isRequired,
};
