import BetterPropTypes from 'better-prop-types';

export const geozoneSummary = BetterPropTypes.shape({
  distanceDriven: BetterPropTypes.number,
  driveDuration: BetterPropTypes.number,
  duration: BetterPropTypes.number,
  fuelConsumed: BetterPropTypes.number,
  geozone: BetterPropTypes.string,
  geozoneName: BetterPropTypes.string,
  geozoneType: BetterPropTypes.string,
  idleDuration: BetterPropTypes.number,
  key: BetterPropTypes.string.isOptionalButNotNull,
  month: BetterPropTypes.number,
  recordCount: BetterPropTypes.number,
  stopDuration: BetterPropTypes.number,
  systemDefault: BetterPropTypes.bool,
  vehicleOu: BetterPropTypes.string,
  year: BetterPropTypes.number,
});

export const geozoneSummaries = BetterPropTypes.arrayOf(geozoneSummary);

export const geozoneActivity = BetterPropTypes.shape({
  dateEntered: BetterPropTypes.string,
  dateExited: BetterPropTypes.string,
  distanceDriven: BetterPropTypes.number,
  driveDuration: BetterPropTypes.number,
  driver: BetterPropTypes.string,
  driverName: BetterPropTypes.string,
  duration: BetterPropTypes.number,
  fuelConsumed: BetterPropTypes.number,
  geozone: BetterPropTypes.string,
  geozoneName: BetterPropTypes.string,
  geozoneType: BetterPropTypes.string,
  idleDuration: BetterPropTypes.number,
  key: BetterPropTypes.string.isOptionalButNotNull,
  stopDuration: BetterPropTypes.number,
  vehicle: BetterPropTypes.string,
  vehicleId: BetterPropTypes.string,
  vehicleOu: BetterPropTypes.string,
});

export const geozoneActivities = BetterPropTypes.arrayOf(geozoneActivity);
