import React from 'react';
import PropTypes from 'prop-types';
import { uniqBy, sumBy } from 'lodash';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => (
      <div {...props}>
        {rows.length} trips, {uniqBy(rows, (row) => row && row.vehicle && row.vehicle.name).length}{' '}
        vehicles, {sumBy(rows, (row) => row && row.count && row.count.loads)} loads
      </div>
    )}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
