import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { isEmpty, some } from 'lodash';

import { customPropTypes } from '../../../helpers/customPropTypes';

export const Interval = ({ user, fetchMessages }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const hasUserMemberships = !isEmpty(user?.memberships);
    const hasGuestRole = some(user?.memberships, (membership) =>
      membership?.role?.includes('S-guest')
    );

    if (hasUserMemberships && !hasGuestRole) {
      setIsMounted(true);
    } else {
      setIsMounted(false);
    }
  }, [user?.memberships]);

  useEffect(() => {
    let intervalId;
    if (isMounted) {
      intervalId = setInterval(() => {
        fetchMessages({
          start: dayjs(dayjs().subtract(30, 'seconds')).toISOString(),
          end: dayjs().toISOString(),
        });
      }, 30000); // 30 seconds
    }

    if (!isMounted) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isMounted]);

  return <div />;
};

Interval.propTypes = {
  user: customPropTypes.user,
  fetchMessages: PropTypes.func.isRequired,
};
