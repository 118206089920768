import { connect } from 'react-redux';

import { FuellingsSummaryReport } from './FuellingsSummaryReport';
import { selectFuellings, fetchFuellingsOptimized } from '../../../../data/reports';
import { selectSearchLimits } from '../../../../data/system';

export const FuellingsSummaryReportContainer = connect(
  (state) => ({
    fuellings: selectFuellings(state),
    limits: selectSearchLimits(state, 'fuellings'),
  }),
  { fetchFuellings: fetchFuellingsOptimized }
)(FuellingsSummaryReport);
