import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Chip } from 'stti-react-common';

import { SectionPanel, InfoField } from '../../../commons/ReportsCommon';
import { getExceptionType } from '../../../../helpers/reports/exceptionTypes';
import { getExceptionSpecificsText } from '../../../../helpers/reports/exceptionSpecifics';
import { useFormats } from '../../../commons/Formats';

export const DetailPanel = ({ exceptionType, description, exception }) => {
  const { formatUnit } = useFormats();

  const { endedAt, timeZone, duration, distance, driversNames, vehicle } = exception || {};

  const exceptionTitle = exceptionType ? getExceptionType(exceptionType).title : '';
  const specifics = getExceptionSpecificsText({ exception, formatUnit });

  const formattedEndedAt = endedAt && formatUnit('date', moment(endedAt).tz(timeZone));

  let formattedDuration = formatUnit('duration', duration);
  if (duration && duration < 120)
    formattedDuration = `${formattedDuration} (${Math.round(duration)} seconds)`;

  const formattedDistance = formatUnit('distance', distance);

  return (
    <SectionPanel
      name="detailPanel"
      title="Detail"
      renderSummaries={() => (
        <>
          {description && <Chip label={description} />}
          {exception && (
            <>
              <Chip label={formattedEndedAt} />
              <Chip label={`Vehicle: ${vehicle && vehicle.name}`} />
              <Chip
                label={
                  driversNames && driversNames.length > 1
                    ? `Driver: ${driversNames.length} drivers`
                    : `Driver: ${driversNames[0]}`
                }
              />
            </>
          )}
        </>
      )}
    >
      <InfoField name="type" label="Type" value={exceptionTitle} />
      <InfoField name="description" label="Description" value={description} />
      <InfoField name="specifics" label="Specifics" value={specifics} />
      <InfoField name="endedAt" label="Ended At" value={formattedEndedAt} />
      <InfoField name="duration" label="Duration" value={formattedDuration} />
      <InfoField name="distance" label="Distance" value={formattedDistance} />
      <InfoField name="vehicle" label="Vehicle" value={vehicle && vehicle.name} />
      <InfoField name="drivers" label="Driver(s)" value={driversNames} />
    </SectionPanel>
  );
};

DetailPanel.propTypes = {
  exceptionType: PropTypes.string,
  description: PropTypes.string,
  exception: PropTypes.shape({
    endedAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    duration: PropTypes.number,
    distance: PropTypes.number,
    driversNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    vehicle: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
