import produce from 'immer';

import { actionTypeProgressOrSuccess } from '../../../apiReducerSupport';

const initialState = {
  data: [],
};

export const ACTION_TYPE = {
  fetchSpeedLimits: 'administration/configurations/speedLimits/fetchSpeedLimits',
  overrideSpeedLimit: 'administration/configurations/speedLimits/overrideSpeedLimit',
  clearSpeedLimit: 'administration/configurations/speedLimits/clearSpeedLimit',
};

export const speedLimitsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchSpeedLimits)) {
      draft.data = payload.length ? payload[0].speedLimits : [];
    }
  });
