import { connect } from 'react-redux';

import { PrintSupport } from './PrintSupport';
import { selectPrintMode, setPrintMode } from '../../../data/system';

export const PrintSupportContainer = connect(
  (state) => ({
    printMode: selectPrintMode(state),
  }),
  {
    setPrintMode,
  }
)(PrintSupport);
