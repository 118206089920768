import { find } from 'lodash';

import { columnDef } from '../../../commons/ControlledDataGrid';

const getRuleGroupValues = ({ incidents }, ruleGroup, element) =>
  (find(incidents, { ruleGroup }) || { [element]: 0 })[element];

export const columnDefs = [
  {
    headerName: 'Information',
    children: [
      columnDef.textColumn({
        colId: 'PerformanceDetail',
        field: 'shift.driverName',
        headerName: 'Driver',
        cellRenderer: 'OpenPerformanceDetail',
        headerComponent: 'OpenDetailHeader',
        width: 40,
      }),
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Date',
        width: 140,
        variant: 'timelessDate',
        enableRowGroup: true,
        rowGroupIndex: 0,
      }),
      columnDef.textColumn({
        field: 'ouName',
        headerName: 'Organization',
        width: 140,
      }),
      columnDef.textColumn({
        field: 'shift.driverName',
        headerName: 'Driver',
        cellRenderer: 'Driver',
        width: 140,
      }),
      columnDef.textColumn({
        field: 'vehicleName',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        width: 140,
      }),
      columnDef.textColumn({
        field: 'shiftNumber',
        headerName: 'Shift Number',
        width: 140,
        hide: true,
      }),
      columnDef.durationColumn({
        field: 'shift.duration',
        headerName: 'Shift Duration',
        width: 150,
        aggFunc: 'sum',
      }),
    ],
  },
  {
    headerName: 'Engine',
    children: [
      columnDef.durationColumn({
        field: 'engineTime',
        headerName: 'Hours',
        width: 130,
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'idleTime',
        headerName: 'Idling',
        width: 120,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'distanceDriven',
        headerName: 'Distance',
        width: 130,
        aggFunc: 'sum',
      }),
    ],
  },
  {
    headerName: 'Excessive Speed',
    children: [
      columnDef.numberColumn({
        colId: 'ExcessiveSpeedCount',
        field: 'incidents',
        lookup: 'totalCount',
        filter: { ruleGroup: 'ExcessiveSpeed' },
        headerName: 'Count',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveSpeed', 'totalCount'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        colId: 'ExcessiveSpeedDistance',
        field: 'incidents',
        headerName: 'Distance',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveSpeed', 'totalDistance'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        colId: 'ExcessiveSpeedDuration',
        field: 'incidents',
        lookup: 'totalDuration',
        filter: { ruleGroup: 'ExcessiveSpeed' },
        headerName: 'Duration',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveSpeed', 'totalDuration'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        colId: 'ExcessiveSpeedPoints',
        field: 'incidents',
        lookup: 'totalPoints',
        filter: { ruleGroup: 'ExcessiveSpeed' },
        headerName: 'Points',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveSpeed', 'totalPoints'),
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(2) : 0),
        width: 140,
        aggFunc: 'sum',
      }),
    ],
  },
  {
    headerName: 'Excessive RPM',
    children: [
      columnDef.numberColumn({
        colId: 'ExcessiveRPMCount',
        field: 'incidents',
        lookup: 'totalCount',
        filter: { ruleGroup: 'ExcessiveRPM' },
        headerName: 'Count',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveRPM', 'totalCount'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        colId: 'ExcessiveRPMDistance',
        field: 'incidents',
        headerName: 'Distance',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveRPM', 'totalDistance'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        colId: 'ExcessiveRPMDuration',
        field: 'incidents',
        lookup: 'totalDuration',
        filter: { ruleGroup: 'ExcessiveRPM' },
        headerName: 'Duration',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveRPM', 'totalDuration'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        colId: 'ExcessiveRPMPoints',
        field: 'incidents',
        lookup: 'totalPoints',
        filter: { ruleGroup: 'ExcessiveRPM' },
        headerName: 'Points',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'ExcessiveRPM', 'totalPoints'),
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(2) : 0),
        width: 140,
        aggFunc: 'avg',
      }),
    ],
  },
  {
    headerName: 'Harsh Braking',
    children: [
      columnDef.numberColumn({
        colId: 'HarshBrakingCount',
        field: 'incidents',
        lookup: 'totalCount',
        filter: { ruleGroup: 'HarshBraking' },
        headerName: 'Count',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'HarshBraking', 'totalCount'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        colId: 'HarshBrakingDistance',
        field: 'incidents',
        headerName: 'Distance',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'HarshBraking', 'totalDistance'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        colId: 'HarshBrakingDuration',
        field: 'incidents',
        lookup: 'totalDuration',
        filter: { ruleGroup: 'HarshBraking' },
        headerName: 'Duration',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'HarshBraking', 'totalDuration'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        colId: 'HarshBrakingPoints',
        field: 'incidents',
        lookup: 'totalPoints',
        filter: { ruleGroup: 'HarshBraking' },
        headerName: 'Points',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'HarshBraking', 'totalPoints'),
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(2) : 0),
        width: 140,
        aggFunc: 'avg',
      }),
    ],
  },
  {
    headerName: 'Idling',
    children: [
      columnDef.numberColumn({
        colId: 'IdlingCount',
        field: 'incidents',
        lookup: 'totalCount',
        filter: { ruleGroup: 'Idling' },
        headerName: 'Count',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'Idling', 'totalCount'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        colId: 'IdlingDistance',
        field: 'incidents',
        headerName: 'Distance',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'Idling', 'totalDistance'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        colId: 'IdlingDuration',
        field: 'incidents',
        lookup: 'totalDuration',
        filter: { ruleGroup: 'Idling' },
        headerName: 'Duration',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'Idling', 'totalDuration'),
        width: 140,
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        colId: 'IdlingPoints',
        field: 'incidents',
        lookup: 'totalPoints',
        filter: { ruleGroup: 'Idling' },
        headerName: 'Points',
        valueGetter: ({ data }) => getRuleGroupValues(data, 'Idling', 'totalPoints'),
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(2) : 0),
        width: 140,
        aggFunc: 'avg',
      }),
    ],
  },
  {
    headerName: 'Points',
    children: [
      columnDef.numberColumn({
        field: 'score',
        headerName: 'Per Hour',
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(2) : 0),
        width: 140,
        aggFunc: 'avg',
        sort: 'desc',
      }),
    ],
  },
];
