import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, lowerFirst, difference, orderBy, startCase, find } from 'lodash';

import {
  BasicDialog,
  TextInput,
  ColorInput,
  NumberInput,
  SelectInput,
  Form,
} from 'stti-react-common';

import { useFormats } from '../../Formats';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './EditGeozoneDialog.scss';

const TIRE_PRESSURE_LOOKUPS = [
  'steerLoaded',
  'steerUnloaded',
  'driveLoaded',
  'driveUnloaded',
  'trailerLoaded',
  'trailerUnloaded',
];

const { Control, useFormController } = Form;

export const EditGeozoneDialog = ({ geozoneTypes, isOpen, onSave, onClose, geozone, tenant }) => {
  const { unitText, convertUnit, unconvertUnit } = useFormats();
  const { enableCti } = tenant;

  const tirePressureConfigControls = () => {
    if (enableCti && geozone) {
      const { tirePressureConfig } = geozone;
      const tirePressureConfigForm = tirePressureConfig || { graceDistance: null, limits: [] };
      const tirePressureValueByGroup = (groupId, loaded) => {
        const limit = find(tirePressureConfigForm.limits, { groupId, loaded });
        return limit && limit.maximumLimit
          ? Math.round(convertUnit('pressure', limit.maximumLimit))
          : null;
      };
      return {
        graceDistance:
          tirePressureConfig && convertUnit('distance', tirePressureConfig.graceDistance),
        steerUnloaded: tirePressureValueByGroup('Steer', false),
        driveUnloaded: tirePressureValueByGroup('Drive', false),
        trailerUnloaded: tirePressureValueByGroup('Trailer', false),
        steerLoaded: tirePressureValueByGroup('Steer', true),
        driveLoaded: tirePressureValueByGroup('Drive', true),
        trailerLoaded: tirePressureValueByGroup('Trailer', true),
      };
    }
    return {};
  };

  const remapLimits = (limits, controls) => {
    const lookupByLimit = (limit) =>
      `${lowerFirst(limit.groupId)}${limit.loaded ? 'Loaded' : 'Unloaded'}`;
    // Get original structure and assign form values to it
    const formLimits = limits.map((limit) => {
      const newLimit = { ...limit };
      const queryWord = lookupByLimit(limit);
      newLimit.maximumLimit = Number(unconvertUnit('pressure', controls[queryWord]).toFixed(6));
      return newLimit;
    });
    // Create an array of queries that exist on formLimits
    const queryLimits = formLimits.map(lookupByLimit);
    // Iterate on remaining array and push new structure if form value exists and it is valid
    difference(TIRE_PRESSURE_LOOKUPS, queryLimits).map((item) =>
      controls[item]
        ? formLimits.push({
            loaded: item.split(/(?=[A-Z])/)[1] === 'Loaded',
            groupId: startCase(item.split(/(?=[A-Z])/)[0]),
            minimumLimit: null,
            maximumLimit: Number(unconvertUnit('pressure', controls[item]).toFixed(6)),
          })
        : false
    );
    // return the filtered and ordered formLimits with values
    // server returns error 500 if limits is a empty array, so we set it to null
    return orderBy(
      formLimits.filter((limit) => limit.maximumLimit > 0),
      [(limit) => TIRE_PRESSURE_LOOKUPS.indexOf(lookupByLimit(limit))],
      ['asc']
    );
  };

  const tirePressureConfigFormValues = ({ graceDistance }) => {
    const { tirePressureConfig } = geozone;
    const limits = (tirePressureConfig && tirePressureConfig.limits) || [];
    if (!enableCti) return null;
    const formLimits = remapLimits(limits, controls);
    if (formLimits.length > 0 && graceDistance) {
      return {
        graceDistance: Number(unconvertUnit('distance', graceDistance).toFixed(6)),
        limits: formLimits,
      };
    }
    return null;
  };

  useEffect(() => {
    if (!isOpen) return;
    const { name, color, type, speedLimit } = geozone;
    resetControls({
      name,
      color,
      type,
      limit: speedLimit && Math.round(convertUnit('speed', speedLimit.limit)),
      gracePeriod: speedLimit && speedLimit.gracePeriod,
      ...tirePressureConfigControls(),
    });
  }, [isOpen]);

  const { speedLimit, key } = geozone || {};

  const form = useFormController({
    controls: {
      ...geozone,
      limit: speedLimit && Math.round(convertUnit('speed', speedLimit.limit)),
      gracePeriod: speedLimit && speedLimit.gracePeriod,
      ...tirePressureConfigControls(),
    },
  });
  const { controls, resetControls, isDirty, hasErrors } = form;

  const handleSave = () => {
    const { name, color, type, limit, gracePeriod } = controls;
    onSave({
      ...geozone,
      name,
      color,
      type,
      typeName: SelectInput.labelForValue(typeOptions, type), // ignored by server but used in eager store update
      speedLimit: limit
        ? {
            limit: Number(unconvertUnit('speed', limit).toFixed(6)), // server appears to use 6 digits of precision
            gracePeriod,
          }
        : null,
      tirePressureConfig: tirePressureConfigFormValues(controls),
    });
    onClose();
  };

  const typeOptions = geozoneTypes.map(({ name: label, key: value }) => ({ label, value }));

  return (
    <BasicDialog
      title={key ? 'Edit Geozone' : 'Add Geozone'}
      className="Map__EditGeozoneDialog"
      isOpen={isOpen}
      onClose={noop}
      buttons={[
        { label: 'Cancel', onClick: onClose },
        { label: 'Save', onClick: handleSave, disabled: hasErrors || !isDirty },
      ]}
    >
      <Form form={form}>
        <div className="Map__EditGeozoneDialog__Form">
          <Control
            name="name"
            label="Name"
            Component={TextInput}
            error={({ value }) => !value || !value.trim()}
            autoGridArea
          />
          <Control
            name="type"
            label="Location Type"
            Component={SelectInput}
            options={typeOptions}
            error={({ value }) => !value}
            autoGridArea
          />
          <Control
            name="color"
            label="Color"
            Component={ColorInput}
            error={({ value }) => !/^#[0-9a-f]{6}$/.test(value) && 'Select or enter a valid color.'}
            autoGridArea
          />
          <h5>Speed Limits</h5>
          <Control
            name="limit"
            label="Speed Limit"
            Component={NumberInput}
            endAdornment={unitText('speed')}
            error={({ value }) => {
              if (isNil(value)) return false;
              if (value <= 0) return 'Speed Limit must be positive.';
              if (Math.round(value) !== value) return 'Speed Limit must be a whole number.';
              return false;
            }}
            autoGridArea
          />
          <Control
            name="gracePeriod"
            label="Grace Period"
            Component={NumberInput}
            endAdornment="seconds"
            error={({ value }) => {
              if (controls.limit && isNil(value))
                return 'Grace Period is required with a Speed Limit.';
              if (!controls.limit && value)
                return 'Grace Period must be blank without a Speed Limit.';
              return false;
            }}
            autoGridArea
          />
        </div>
        {enableCti && (
          <div className="Map__EditGeozoneDialog__Form--tirePressureConfig">
            <h5>Tire Pressure Limits</h5>
            <Control
              name="graceDistance"
              label="Grace Distance"
              Component={NumberInput}
              endAdornment={unitText('distance')}
              error={({ value }) => {
                if (
                  isNil(value) &&
                  TIRE_PRESSURE_LOOKUPS.map((item) => controls[item]).some(Boolean)
                )
                  return 'Grace Distance is required';
                if (
                  isNil(value) &&
                  TIRE_PRESSURE_LOOKUPS.map((item) => controls[item]).every(isNil)
                )
                  return false;
                if (value <= 0) return 'Grace Distance must be positive.';
                if (value && TIRE_PRESSURE_LOOKUPS.map((item) => controls[item]).every(isNil)) {
                  return 'Grace Distance cannot be added without at least one Tire Pressure Maximum';
                }
                return false;
              }}
              autoGridArea
            />
            {TIRE_PRESSURE_LOOKUPS.map((lookUp) => (
              <Control
                key={lookUp}
                name={lookUp}
                label={`${startCase(lookUp.split(/(?=[A-Z])/)[0])} Axle ${
                  lookUp.split(/(?=[A-Z])/)[1]
                }`}
                Component={NumberInput}
                endAdornment={unitText('pressure')}
                error={({ value }) => {
                  if (isNil(value)) return false;
                  if (value <= 0) return 'This value must be positive.';
                  return false;
                }}
                autoGridArea
              />
            ))}
          </div>
        )}
      </Form>
    </BasicDialog>
  );
};

EditGeozoneDialog.propTypes = {
  geozoneTypes: PropTypes.arrayOf(customPropTypes.locationType),
  geozone: PropTypes.object,
  tenant: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
