import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  meanOfField,
  countOf,
  countUniqueOfField,
  sumOfField,
} from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();
  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Trips',
            value: countOf(rows),
          },
          {
            header: 'Vehicles',
            value: countUniqueOfField(rows, 'vehicle.name'),
          },
          {
            header: 'Loads',
            value: sumOfField(rows, 'count.loads'),
          },
        ]}
      />
      <AggregatesCard
        title="Distance (avg)"
        name="distance"
        fields={[
          {
            header: 'Full Cycle',
            value: formatUnit('distance', meanOfField(rows, 'fullCycle.distance')),
          },
          {
            header: 'Empty',
            value: formatUnit('distance', meanOfField(rows, 'empty.distance')),
          },
          {
            header: 'Loaded',
            value: formatUnit('distance', meanOfField(rows, 'loaded.distance')),
          },
        ]}
      />
      <AggregatesCard
        title="Duration (avg)"
        name="duration"
        fields={[
          {
            header: 'Full Cycle',
            value: formatUnit('duration', meanOfField(rows, 'fullCycle.duration')),
          },
          {
            header: 'Loading',
            value: formatUnit('duration', meanOfField(rows, 'loading.duration')),
          },
          {
            header: 'Unloading',
            value: formatUnit('duration', meanOfField(rows, 'unloading.duration')),
          },
        ]}
      />
      <AggregatesCard
        title="Fuel (avg)"
        name="fuel"
        fields={[
          {
            header: 'Full Cycle',
            value: formatUnit('volume', meanOfField(rows, 'fullCycle.consumedFuel')),
          },
          {
            header: 'Idling',
            value: formatUnit('volume', meanOfField(rows, 'fullCycle.idlingFuel')),
          },
          {
            header: 'Mileage',
            value: formatUnit('mileage', meanOfField(rows, 'fullCycle.mileage')),
          },
        ]}
      />
      <AggregatesCard
        title="Performance (avg)"
        name="performance"
        fields={[
          {
            header: 'Violations',
            value: formatUnit('number', meanOfField(rows, 'count.totalViolations')),
          },
          {
            header: 'Idling',
            value: formatUnit('duration', meanOfField(rows, 'fullCycle.idlingDuration')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
