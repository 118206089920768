import React from 'react';
import PropTypes from 'prop-types';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { countTruthyOfField, countValueOfField } from '../../../../helpers/reports/aggregation';
import { UNREVIEWED_VIOLATION_STATUS } from '../../../../data/reports';
import { AggregatesContent } from './AggregatesContent';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => (
      <div {...props}>
        {rows.length} logs, {countTruthyOfField(rows, 'hasActivity')} with activity,{' '}
        {countValueOfField(rows, 'violationStatus', UNREVIEWED_VIOLATION_STATUS)} with unreviewed
        violations, {countTruthyOfField(rows, 'diagnosticIndication')} with diagnostic indication,{' '}
        {countTruthyOfField(rows, 'malfunctionIndication')} with malfunction indication{' '}
      </div>
    )}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
