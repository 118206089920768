import { filter, isEqual, get, uniqueId } from 'lodash';

import moment from 'moment';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../apiActionSupport';

import { ACTION_TYPE } from './reducer';
import { selectFuelTaxesFetchRequests } from './selectors';
import { fuelConsumptionRates, getCalculatedValues, sanitizeData } from './utils';
import { formatFuelType } from '../../../components/reports/FuelTracking/FuelTax/utils';

const transformForStore = (responseData, { fuelTaxId }) => {
  const { data, taxRates = [] } = responseData;

  const remapedData = sanitizeData(data)
    .map((row) => {
      const {
        ou,
        fuelType: fuelTypeData,
        jurisdictionCountry,
        jurisdictionState,
        json: { edits },
      } = row;

      const ouKey = ou && ou.key;
      const timeZone = ou && ou.timeZone ? ou.timeZone : moment.tz.guess();
      const fuelTypeName = formatFuelType(fuelTypeData);

      const lastEditedBy = edits && edits[0] && get(edits[0], 'userName');

      // FE calculated and/or added properties
      const { calcAddedFuel, calcOnHwyDist, calcOffHwyDist } = getCalculatedValues(
        sanitizeData(data),
        row
      );

      const taxRateFound =
        taxRates &&
        taxRates.find(
          ({ fuelType, quarter, jurisdictionState: state, jurisdictionCountry: country }) =>
            row.fuelType === fuelType &&
            row.quarter === quarter &&
            row.jurisdictionState === state &&
            row.jurisdictionCountry === country
        );

      let taxRate = 0;

      if (taxRateFound) {
        if (row.jurisdictionCountry === 'CA') {
          taxRate = taxRateFound.caRate;
        } else {
          taxRate = taxRateFound.usRate;
        }
      }

      return {
        ...row,
        ouKey,
        lastEditedBy,
        taxRate,
        timeZone,
        addedFuel: calcAddedFuel,
        onHighwayDistance: calcOnHwyDist,
        offHighwayDistance: calcOffHwyDist,
        fuelType: fuelTypeName,
        jurisdictionCountry,
        jurisdictionState,
        parentUUID: row.fuelTaxId === fuelTaxId ? null : fuelTaxId, // ephemeral way to link extra segments into original detail screen
      };
    })
    .map((row, index, array) => {
      const { addedFuel, fuelType, onHighwayDistance, offHighwayDistance, taxRate } = row;

      const { kpl } = fuelConsumptionRates(array)[fuelType];

      // if we have json edits, we need to use it
      const totalDistance = onHighwayDistance + offHighwayDistance;
      const taxQtConsumed = onHighwayDistance / kpl; // as stated by MBL-3385
      const netTaxableQty = taxQtConsumed - addedFuel;
      const taxDue = netTaxableQty * taxRate;
      const averagePerf = totalDistance / addedFuel;

      return {
        ...row,
        totalDistance,
        taxQtConsumed,
        netTaxableQty,
        taxDue,
        averagePerf,
      };
    });

  const remappedTaxRates = sanitizeData(taxRates).map(({ quarter, ...rest }) => ({
    id: uniqueId(quarter),
    quarter,
    ...rest,
  }));

  return {
    data: remapedData,
    taxRates: remappedTaxRates,
  };
};

const fetchFuelTaxes = makeRestApiAction({
  service: 'fuelTaxes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuelTaxes,
  transformInput: ({ quarter, month, year, ouKey }) => ({
    ouKey,
    ...(quarter && { quarter }),
    ...(month && { month, year }),
  }),
  transformOutput: transformForStore,
  notificationsItemDescriptor: 'fuelTax',
});

export const fetchFuelTaxesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectFuelTaxesFetchRequests,
  filterFetches: (fetches, actionParam) =>
    filter(fetches, ({ actionParam: fetchActionParam }) => isEqual(fetchActionParam, actionParam)),
  fetchAction: fetchFuelTaxes,
  baseActionType: ACTION_TYPE.fetchFuelTaxes,
});

const fetchFuelTax = makeRestApiAction({
  service: 'fuelTaxDetails',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuelTax,
  transformInput: ({ fuelTaxId }) => ({
    fuelTaxId,
  }),
  transformOutput: (responseData, actionParams) => transformForStore(responseData, actionParams),
  notificationsItemDescriptor: 'fuelTax',
});

export const fetchFuelTaxOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectFuelTaxesFetchRequests,
  filterFetches: (fetches, actionParam) =>
    filter(fetches, ({ actionParam: fetchActionParam }) => isEqual(fetchActionParam, actionParam)),
  fetchAction: fetchFuelTax,
  baseActionType: ACTION_TYPE.fetchFuelTax,
});

export const fetchFuelTaxExtraSegments = makeRestApiAction({
  service: 'fuelTaxDetails',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuelTaxExtraSegments,
  transformInput: ({ vehicleKey, quarter }) => ({
    vehicleKey,
    quarter,
  }),
  transformOutput: (responseData, actionParams) => transformForStore(responseData, actionParams),
  notificationsItemDescriptor: 'fuelTax',
});

export const postFuelTaxEdits = makeRestApiAction({
  service: 'reportEdits',
  method: 'post',
  baseActionType: ACTION_TYPE.postFuelTaxEdit,
  notifications: {
    success: 'Fuel Tax Segment updated.',
    failure: (actionParam, apiError) => `Couldn't update Fuel Tax Segment: ${apiError.message}`,
  },
});
