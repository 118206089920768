import PropTypes from 'prop-types';
import { filter, last, startCase, isNumber } from 'lodash';
import moment from 'moment-timezone';

import { sumOfField } from './aggregation';
import { formatUnit } from '../formats';
import { sliceBreadcrumbs } from '../maps/breadcrumbUtils';

const mergeActivities = (activities, excludeFirstRowFromAggregation = false) => {
  const summedActivityProperties = [
    'distance',
    'duration',
    'waitDuration',
    'addedFuel',
    'consumedFuel',
    'idlingFuel',
  ];

  const [firstActivity, ...restActivities] = activities;
  const mergedActivity = { ...firstActivity };

  const sumActivities = excludeFirstRowFromAggregation ? restActivities : activities;
  summedActivityProperties.forEach((property) => {
    mergedActivity[property] = sumOfField(sumActivities, property);
  });

  mergedActivity.endedAt = last(activities).endedAt;
  mergedActivity.endEvent = last(activities).endEvent;

  return mergedActivity;
};

export const groupActivityRows = ({ rows }) => {
  /*
    ADDS and MUTATES rows
    properties
      requires: activityType, originalParent
      mutates:  id, parentId, (mergeActivities)
      adds:     isSolo, isParent, isChild, isFirstChild, newParent
      removes:  -
  */

  const resultRows = [];
  let activeGroupingFamily;
  let parentId;

  rows.forEach((row, index) => {
    const followingRow = rows[index + 1];

    const currentRowGroupingFamily = row.topActivityType;

    if (currentRowGroupingFamily !== activeGroupingFamily) {
      const isParent = followingRow && followingRow.topActivityType === currentRowGroupingFamily;

      parentId = `${row.id}_parent`;

      if (isParent) {
        const parent = {
          // parent row
          ...row,
          id: parentId,
          isParent: true,
          activityType: currentRowGroupingFamily,
        };
        const child = {
          // include as first child row
          ...row,
          id: `${row.id}_child`,
          parentId,
          newParent: parent,
          isFirstChild: true,
          isChild: true,
        };
        resultRows.push(parent, child);
      } else {
        // solo row
        resultRows.push({ ...row, isSolo: true });
      }

      activeGroupingFamily = currentRowGroupingFamily;
    } else {
      // child row
      resultRows.push({ ...row, parentId, isChild: true });
    }
  });

  // fill parent row data
  const resultRowsWithAggregates = resultRows.map((row) => {
    if (!row.isParent) return row;
    const children = filter(resultRows, { parentId: row.id }); // may require optimization

    return mergeActivities([row, ...children], true);
  });

  return resultRowsWithAggregates;
};
groupActivityRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

export const mergeSameActivityRows = ({ rows }) => {
  /*
    REMOVES rows
    properties
      requires: activityType
      mutates:  (mergeActivities)
      adds:     -
  */

  const processedRows = [];

  rows.forEach((row) => {
    const lastRowIndex = processedRows.length - 1;
    const lastRow = processedRows[lastRowIndex] || {};
    if (
      row.activityType === lastRow.activityType && // same activityType
      row.topActivityType === lastRow.topActivityType // both solo rows or siblings
      // Disabling below for now; need more sophisticated no-merge detection
      // && Object.keys(row.other).length === 0 && Object.keys(lastRow.other).length === 0
    ) {
      processedRows[lastRowIndex] = mergeActivities([lastRow, row]);
    } else {
      processedRows.push(row);
    }
  });
  return processedRows;
};
mergeSameActivityRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

export const mergeActivityRows = ({ rows, test, value }) => {
  /*
    REMOVES rows
    properties
      requires: activityType
      mutates:  [mergeActivities()]
      adds:     -
  */

  const processedRows = [];

  rows.forEach((row) => {
    const previousRowIndex = processedRows.length - 1;
    const previousRow = processedRows[previousRowIndex] || {};
    if (test({ row, previousRow, value })) {
      processedRows[previousRowIndex] = mergeActivities([previousRow, row]);
    } else {
      processedRows.push(row);
    }
  });
  return processedRows;
};
mergeActivityRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

const getDetail = ({ activity, formats, isChild }) => {
  const {
    activityType,
    // startEventType,
    endEventType,
    notes, // any
    cancelled, // Fuelling, HookingTrailers, Inspection, Loading, ScaleIn, ScaleOut, UnhookingTrailers, Unloading
    trailers, // HookingTrailers, UnhookingTrailers
    orders = [], // Loading, Unloading
    manifests = [], // Loading, Unloading
    // products = [], // Loading, Unloading
    vehicleName, // ShiftStarted, ShiftResumed
    // isFirstChild, // (added by groupActivityRows filter)
    other: {
      // endedAt, // any
      // incomplete, // any
      speedLimit, // SpeedViolation
      maximumSpeed, // SpeedViolation
      reason, // Break, UnhookingTrailers? (might be error)
      quantity, // Fuelling
      defQuantity, // Fuelling (only present when enabled on tenant)
      fuelType, // Fuelling
      vendor, // Fuelling
      inspected = [], // Inspection
      inspectionType, // Inspection
      delayType, // OtherDelay
      stateEntered, // StateEntered
      networkType, // NetworkConnectionAcquired
    },
  } = activity;
  const detail = [];
  const UNKNOWN = '(unknown)';

  // initial title
  let activityTitle = startCase(activityType);
  if (!isChild && cancelled) activityTitle = `${activityTitle} (Cancelled)`;

  if (!isChild) {
    // Parents and Solo only
    switch (activityType) {
      case 'ShiftStarted':
      case 'ShiftResumed':
        detail.push(`Vehicle ${vehicleName}`);
        break;
      case 'Fuelling':
        {
          const types = [];
          if (isNumber(quantity))
            types.push(
              `${formatUnit('volume', quantity, formats.volume)} - ${fuelType || UNKNOWN}`
            );
          if (isNumber(defQuantity))
            types.push(`${formatUnit('volume', defQuantity, formats.volume)} - DEF`);
          if (types.length === 0) types.push(UNKNOWN);
          detail.push(`Adding ${types.join(', ')} @ ${vendor || UNKNOWN}`);
        }
        break;
      case 'Inspection': {
        const prefix = {
          'PRE TRIP': 'Pre-Trip ',
          'POST TRIP': 'Post-Trip ',
          OTHER: 'Other ',
          UNKNOWN: 'Unknown ',
        }[inspectionType];
        activityTitle = `${prefix || ''}Inspection`;
        detail.push(`Inspecting ${inspected.length > 0 ? inspected.join(', ') : UNKNOWN}`);
        break;
      }
      case 'HookingTrailers':
      case 'UnhookingTrailers': {
        const verb = { HookingTrailers: 'Hooking', UnhookingTrailers: 'Unhooking' }[activityType];
        detail.push(`${verb} ${trailers && trailers.length > 0 ? trailers.join(', ') : UNKNOWN}`);
        break;
      }
      case 'Loading':
      case 'Unloading': {
        if (cancelled) break;
        const subDetail = [];
        if (manifests.length > 0) subDetail.push(`Manifest ${manifests.join(', ')}`);
        if (orders.length > 0) subDetail.push(`Order ${orders.join(', ')}`);
        detail.push(
          subDetail.length > 0
            ? `${activityType} ${subDetail.join(' / ')}`
            : `${activityType} ${UNKNOWN}`
        );
        break;
      }
      case 'Break':
        detail.push(`Break: ${reason || UNKNOWN}`); // string one of OffDutyReasons
        break;
      case 'OtherDelay':
        detail.push(delayType || UNKNOWN); // not known to be enumerated
        break;
      default:
    }
  } else {
    // Children only
    switch (activityType) {
      case 'SpeedViolation':
        if (speedLimit && maximumSpeed) {
          detail.push(
            `Speed Limit: ${formatUnit('speed', speedLimit, formats.speed)}, Maximum: ${formatUnit(
              'speed',
              maximumSpeed,
              formats.speed
            )}`
          );
        } else {
          detail.push('(continued)');
        }
        break;
      case 'NetworkConnectionAcquired':
        detail.push(`Network: ${networkType || UNKNOWN}`); // enumerated?
        break;
      case 'StateEntered':
        detail.push(`Entered ${stateEntered || UNKNOWN}`);
        break;
      case 'GpsAcquired':
      case 'GpsLost':
        activityTitle = activityTitle.replace(/Gps/, 'GPS');
        break;
      case 'DriverSelectedVehicle':
        detail.push(`Vehicle ${vehicleName}`);
        break;
      case 'Waiting':
      case 'Loading':
      case 'Unloading': {
        if (endEventType === 'BreakStarted') detail.push(`Break: ${reason || UNKNOWN}`);
        break;
      }
      default:
    }
  }

  // add notes
  if (notes && notes.trim()) detail.push(`"${notes}"`);

  return {
    activityTitle,
    detail: detail.join('; '),
  };
};

export const interpretActivityRows = ({
  rows,
  breadcrumbsByVehicleKey,
  // preventDisable,
  formats,
}) => {
  const processedRows = [];
  let lastRow = {};

  let underlyingLine;

  rows.forEach((row) => {
    const { activityType, vehicleKey, startedAt, endedAt, isChild } = row;

    let line;
    let icon;

    if (!isChild) {
      // parents and solo rows
      switch (activityType) {
        case 'Driving':
        case 'UnknownStop':
          line = 'drivingLine';
          break;
        case 'BrakeCheck':
        case 'Inspection':
        case 'LoadInspection':
          icon = 'inspectionIcon';
          line = 'inspectionLine';
          break;
        case 'Loading':
        case 'HookingTrailers':
          icon = 'loadingIcon';
          line = 'loadingLine';
          break;
        case 'UnhookingTrailers':
        case 'Unloading':
          icon = 'unloadingIcon';
          line = 'unloadingLine';
          break;
        case 'ShiftStarted':
        case 'ShiftResumed':
          icon = 'shiftStartedIcon';
          line = 'drivingLine';
          break;
        case 'ShiftEnded':
          icon = 'shiftEndedIcon';
          line = undefined;
          break;
        case 'UserLoggedOut':
          icon = 'loggedOutIcon';
          line = undefined;
          break;
        case 'UserLoggedIn':
          icon = 'loggedInIcon';
          line = 'drivingLine';
          break;
        case 'Fuelling':
          icon = 'fuellingIcon';
          line = 'fuellingLine';
          break;
        case 'Break':
        case 'FerryDelay':
        case 'OtherDelay':
          icon = 'delayIcon';
          line = 'delayLine';
          break;
        case 'OtherActivity':
          icon = 'unknownIcon';
          line = 'unknownLine';
          break;
        case 'PersonalUse':
          // ?
          break;
        case 'ScaleIn':
        case 'ScaleOut':
          // ?
          break;
        default:
      }

      underlyingLine = line;
    } else {
      // child rows
      switch (activityType) {
        case 'SpeedViolation':
          line = 'speedViolationLine';
          break;
        case 'Driving':
        case 'BrakeCheck':
        case 'Inspection':
        case 'LoadInspection':
        case 'Loading':
        case 'HookingTrailers':
        case 'UnhookingTrailers':
        case 'Unloading':
        case 'Fuelling':
        case 'Break':
        case 'FerryDelay':
        case 'OtherDelay':
        case 'OtherActivity':
        case 'PersonalUse':
        case 'ScaleIn':
        case 'ScaleOut':
        case 'Waiting':
        case 'Moving':
          line = 'clearLine';
          break;
        case 'GpsAcquired':
        case 'GpsLost':
        case 'BrakeCheckExited':
        case 'BrakeCheckEntered':
        case 'CustomGeozoneEntered':
        case 'CustomGeozoneExited':
        case 'FuellingStationEntered':
        case 'FuellingStationExited':
        case 'LoadLocationEntered':
        case 'LoadLocationExited':
        case 'OffHighwayEntered':
        case 'OffHighwayExited':
        case 'OfficeEntered':
        case 'OfficeExited':
        case 'RestaurantEntered':
        case 'RestaurantExited':
        case 'ScaleEntered':
        case 'ScaleExited':
        case 'ShopEntered':
        case 'ShopExited':
        case 'UnloadLocationEntered':
        case 'UnloadLocationExited':
        case 'NetworkConnectionAcquired':
        case 'NetworkConnectionLost':
        case 'StateEntered':
        case 'Stopped':
        default:
      }
    }

    const path = line && sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startedAt, endedAt);

    const gridLine = line && line !== 'clearLine' ? line : underlyingLine;

    const { activityTitle, detail } = getDetail({ activity: row, formats, isChild });

    const thisRow = {
      ...row,
      activityTitle,
      detail,
      topLine: icon ? lastRow.bottomLine : gridLine, // grid cell upper section
      bottomLine: gridLine, // grid cell lower section
      line, // map line (drawn on path)
      icon, // grid cell and map icon
      path, // path for line
    };

    processedRows.push(thisRow);
    lastRow = thisRow;
  });

  return processedRows;
};
interpretActivityRows.propTypes = {
  rows: PropTypes.array.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object.isRequired,
};

export const insertLoggedOutRows = ({ rows }) => {
  const filteredRows = [...rows];

  let index = 0;
  while (index < filteredRows.length) {
    if (filteredRows[index].activityType === 'UserLoggedOut') {
      const userLoggedOut = filteredRows[index];
      const userLoggedIn = filteredRows[index + 1];
      filteredRows.splice(index + 1, 0, {
        driverShiftId: userLoggedOut.driverShiftId,
        id: `${userLoggedOut.id}.LoggedOut`,
        topActivityType: 'LoggedOut',
        activityType: 'LoggedOut',
        activityTitle: 'Logged Out',
        startedAt: userLoggedOut.endedAt,
        endedAt: userLoggedIn.startedAt,
        startEventType: 'UserLoggedOut',
        endEventType: 'UserLoggedIn',
        childCount: 0,
        other: {},
        duration: moment
          .duration(moment(userLoggedIn.startedAt).diff(moment(userLoggedOut.endedAt)))
          .asSeconds(),
      });
      index += 1; // advance extra to skip inserted row
    }
    index += 1;
  }

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
