import produce from 'immer';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchVideoEventTypes: 'reports/videoEvents/fetchVideoEventTypes',
  fetchVideoEventBehaviors: 'reports/videoEvents/fetchVideoEventBehaviors',
  fetchVideoEventFileLocation: 'reports/videoEvents/fetchVideoEventFileLocation',
};

const initialState = {
  typesFetches: [],
  types: [],
  behaviorsFetches: [],
  behaviors: [],
  fileLocationsFetches: [],
  fileLocationsById: {},
};

export const videoEventsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    // types
    if (actionTypeRequest(type, ACTION_TYPE.fetchVideoEventTypes)) {
      const { id } = request;
      draft.typesFetches = shallowMergeEntities(state.typesFetches, {
        id,
      });
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventTypes)) {
      draft.types = shallowMergeEntities(state.types, payload, 'key');
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchVideoEventTypes)) {
      draft.typesFetches = removeEntity(state.typesFetches, request);
    }

    // behaviors
    if (actionTypeRequest(type, ACTION_TYPE.fetchVideoEventBehaviors)) {
      const { id } = request;
      draft.behaviorsFetches = shallowMergeEntities(state.behaviorsFetches, {
        id,
      });
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventBehaviors)) {
      draft.behaviors = payload.sort();
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchVideoEventBehaviors)) {
      draft.behaviorsFetches = removeEntity(state.behaviorsFetches, request);
    }

    // locations
    if (actionTypeRequest(type, ACTION_TYPE.fetchVideoEventFileLocation)) {
      const { id, actionParam } = request;
      draft.fileLocationsFetches = shallowMergeEntities(state.fileLocationsFetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventFileLocation)) {
      draft.fileLocationsById[payload.id] = payload;
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchVideoEventFileLocation)) {
      draft.fileLocationsFetches = removeEntity(state.fileLocationsFetches, request);
    }
  });
