import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchVehiclesLatestInspections: 'reports/inspections/fetchVehiclesLatestInspections',
  fetchInspectorsLatestInspections: 'reports/inspections/fetchInspectorsLatestInspections',
  fetchInspections: 'reports/inspections/fetchInspections',
  fetchMyInspections: 'reports/inspections/fetchMyInspections',
  fetchInspection: 'reports/inspections/fetchInspection',
  updateInspection: 'reports/inspections/updateInspection',
  updateInspectionDefectAndNote: 'reports/inspections/updateInspectionDefectAndNote',
};

const initialState = {
  data: [],
  fetches: [],
};

export const inspectionsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchInspections,
        ACTION_TYPE.fetchMyInspections,
        ACTION_TYPE.fetchInspection,
        ACTION_TYPE.updateInspection,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchVehiclesLatestInspections,
        ACTION_TYPE.fetchInspectorsLatestInspections,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.updateInspectionDefectAndNote])) {
      const { data } = state;
      const newData = data.filter(({ id }) => id !== payload.id);
      newData.push(payload);
      draft.data = newData;
    }

    if (
      actionTypeRequest(type, [
        ACTION_TYPE.fetchInspections,
        ACTION_TYPE.fetchInspection,
        ACTION_TYPE.fetchMyInspections,
      ])
    ) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, actionParam });
    }

    if (
      actionTypeRequest(type, [
        ACTION_TYPE.fetchVehiclesLatestInspections,
        ACTION_TYPE.fetchInspectorsLatestInspections,
      ])
    ) {
      const { id, actionParam, service } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, actionParam, service });
    }

    if (
      actionTypeFailure(type, [
        ACTION_TYPE.fetchVehiclesLatestInspections,
        ACTION_TYPE.fetchInspectorsLatestInspections,
        ACTION_TYPE.fetchInspections,
        ACTION_TYPE.fetchInspection,
        ACTION_TYPE.fetchMyInspections,
      ])
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
