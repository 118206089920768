import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, useBooleanState } from 'stti-react-common';
import { noop } from 'lodash';

import { Feature } from '../../Feature';
import { DeleteAdminItemDialog } from './DeleteAdminItemDialog';

import './CrudAdminItem.scss';

export const CrudAdminItem = ({
  additionalDeletePrompt,
  children,
  copyItem,
  createItem,
  data,
  dataIdProperty = 'key',
  deleteItem,
  form,
  hasError,
  isDirty,
  itemTitle,
  mode,
  onApiError,
  onClose,
  preventCreateClose,
  reloadPageAfterCreate,
  reloadUrl,
  resetControls,
  restoreItem,
  setMode,
  title,
  updateItem,
  dynamicDeletePrompt,
}) => {
  if (preventCreateClose && reloadPageAfterCreate) {
    throw new Error(
      'You cannot have preventCreateClose and reloadPageAfterCreate on the same CrudAdminItem'
    );
  }

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});

  // useMemo here so the title of the item will remain static even after editing
  const itemLabel = useMemo(() => itemTitle, [data[dataIdProperty]]);

  const getTitle = () => {
    if (data) {
      switch (mode) {
        case 'view':
          return `View ${title}: ${itemLabel}`;
        case 'create':
          return `Create ${title}`;
        case 'edit':
          return `Update ${title}: ${itemLabel}`;
        case 'restore':
          return `Restore ${title}: ${itemLabel}`;
        default:
          return title;
      }
    } else {
      return title;
    }
  };

  const setFormSuccessState = () => {
    onApiError?.(null);
    if (preventCreateClose && !reloadPageAfterCreate) {
      return preventCreateClose ? noop : onClose();
    }

    if (!preventCreateClose && reloadPageAfterCreate) {
      return setTimeout(() => {
        window.location.replace(reloadUrl);
        window.location.reload();
      }, 100);
    }

    return onClose();
  };

  const setFormErrorState = (errorMsg) => {
    const formMessage = errorMsg?.message || 'Unexpected error sending form data';
    onApiError?.(formMessage);
  };

  const defineButtons = () => {
    const DELETE_BUTTON = {
      label: `Delete ${title}`,
      icon: 'delete',
      onClick: () => {
        openDeleteDialog();
        setTargetRow(data);
      },
      isHidden: !deleteItem,
    };

    const COPY_BUTTON = {
      label: `Copy ${title}`,
      icon: 'filter_none',
      onClick: () => {
        copyItem();
      },
    };

    const EDIT_BUTTON = {
      label: `Edit ${title}`,
      icon: 'create',
      onClick: () => {
        setMode('edit');
      },
      isHidden: !updateItem,
    };

    const CREATE_BUTTON = {
      label: `Create ${title}`,
      icon: 'add',
      onClick: () => {
        createItem(data).then(setFormSuccessState).catch(setFormErrorState);
      },
      disabled: hasError || !isDirty,
    };

    const CANCEL_BUTTON = {
      label: 'Cancel',
      icon: 'block',
      onClick: () => {
        onClose();
      },
    };

    const CANCEL_EDIT_BUTTON = {
      label: 'Cancel Edit',
      icon: 'block',
      onClick: () => {
        resetControls();
        onApiError?.(null);
        setMode('view');
      },
    };

    const RESTORE_BUTTON = {
      label: `Restore ${title}`,
      icon: 'restore_from_trash',
      onClick: () => {
        restoreItem(data).then(setFormSuccessState).catch(setFormErrorState);
      },
    };

    const SAVE_BUTTON = {
      label: `Save ${title}`,
      icon: 'save',
      onClick: () => {
        updateItem(data).then(setFormSuccessState).catch(setFormErrorState);
      },
      disabled: hasError || !isDirty,
    };

    const CLOSE_BUTTON = {
      label: `Save ${title}`,
      icon: 'save',
      onClick: () => {
        updateItem(data).then(setFormSuccessState).catch(setFormErrorState);
      },
      disabled: hasError || !isDirty,
    };

    switch (mode) {
      case 'view':
        return copyItem ? [DELETE_BUTTON, COPY_BUTTON, EDIT_BUTTON] : [DELETE_BUTTON, EDIT_BUTTON];
      case 'create':
        return [CANCEL_BUTTON, CREATE_BUTTON];
      case 'edit':
        return copyItem
          ? [CANCEL_EDIT_BUTTON, COPY_BUTTON, SAVE_BUTTON]
          : [CANCEL_EDIT_BUTTON, SAVE_BUTTON];
      case 'restore':
        return [RESTORE_BUTTON];
      default:
        return [CLOSE_BUTTON];
    }
  };

  return (
    <div className="CrudAdminItem">
      <Feature.Header title={getTitle()} onClose={onClose}>
        {defineButtons().map(
          (button) =>
            !button.isHidden && (
              <Button
                key={button.label}
                icon={button.icon}
                variant="contained"
                label={button.label}
                onClick={button.onClick}
                disabled={button.disabled}
              />
            )
        )}
      </Feature.Header>
      <DeleteAdminItemDialog
        dialogTitle={title}
        dynamicElement={dynamicDeletePrompt}
        isOpen={isDeleteDialogOpen}
        itemTitle={itemTitle}
        onClose={closeDeleteDialog}
        onConfirm={() => {
          onClose();
          deleteItem(targetRow);
        }}
        rowData={targetRow}
      >
        {additionalDeletePrompt}
      </DeleteAdminItemDialog>
      <Form
        form={form}
        className="CrudAdminItem__form"
        readOnly={mode === 'view' || mode === 'restore'}
      >
        {children}
      </Form>
    </div>
  );
};

CrudAdminItem.propTypes = {
  additionalDeletePrompt: PropTypes.node,
  children: PropTypes.node,
  copyItem: PropTypes.func,
  createItem: PropTypes.func,
  data: PropTypes.object.isRequired,
  dataIdProperty: PropTypes.string,
  deleteItem: PropTypes.func,
  dynamicDeletePrompt: PropTypes.node,
  form: PropTypes.object.isRequired,
  hasError: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  itemTitle: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'update', 'restore', 'create']).isRequired,
  onApiError: PropTypes.func,
  onClose: PropTypes.func,
  preventCreateClose: PropTypes.bool,
  reloadPageAfterCreate: PropTypes.bool,
  reloadUrl: PropTypes.string,
  resetControls: PropTypes.func.isRequired,
  restoreItem: PropTypes.func,
  setMode: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateItem: PropTypes.func,
};
