import React from 'react';

import { ActivationCodesSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { FEATURES } from '../../../../features';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const activationCodesSummary = configureRoute({
  name: 'Activation Codes',
  group: administrationGroups.devices,
  order: 1,
  path: '/activationCodes',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <ActivationCodesSummaryContainer {...props} />,
  hidden: FEATURES.admin.hideActivationCodesFromMainMenu,
});
