import { connect } from 'react-redux';

import { FuelTaxSummaryReport } from './summary';
import { fuelTax } from '../../../../../../data/reports';

export const FuelTaxSummaryContainer = connect(
  (state) => ({
    fuelTaxes: fuelTax.selectors.selectFuelTaxes(state),
    taxRates: fuelTax.selectors.selectFuelTaxRates(state),
  }),
  { fetchFuelTaxes: fuelTax.actions.fetchFuelTaxesOptimized }
)(FuelTaxSummaryReport);
