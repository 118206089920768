/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { ProgressOverlay } from 'stti-react-common';

import { useReport } from '../useReport';
import { LoadFailedDialog } from '../../LoadOrFail';
import { debugMode } from '../../../../helpers/debug';

export const ReportLoader = ({
  reportViews,
  fetchReportView,
  fetchLocalReportView,
  fetchDefaultReportViewsByReportType,
  createTemporaryDefaultReportView,
}) => {
  const { restoreReport, defaultState, route, reportType, reportViewId, reportViewIdType } =
    useReport();

  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState();

  useEffect(() => {
    let ignoreError = false;

    const getDefaultReportView = async () => {
      const storeDefaultReportView = find(reportViews, { reportType, defaultView: true });

      if (debugMode) {
        console.groupCollapsed(
          'WEB VIEW INFO - [REPORT LOADER] - getDefaultReportView -> storeDefaultReportView'
        );
        storeDefaultReportView
          ? console.info('storeDefaultReportView', storeDefaultReportView)
          : console.info(
              'storeDefaultReportView is null so we will fetchDefaultReportViewsByReportType from BACKEND'
            );
        console.groupEnd();
      }

      if (storeDefaultReportView) return storeDefaultReportView;

      const defaultReportViews = await fetchDefaultReportViewsByReportType(reportType);

      if (debugMode) {
        console.groupCollapsed(
          'WEB VIEW INFO - [REPORT LOADER] - getDefaultReportView -> defaultReportViews'
        );
        defaultReportViews
          ? console.info('defaultReportViews', defaultReportViews)
          : console.info(
              'defaultReportViews is null or empty so we will createTemporaryDefaultReportView from REDUX DATA'
            );
        console.groupEnd();
      }

      if (defaultReportViews.length > 0) return defaultReportViews[0];

      const reportView = {
        id: `${reportType}/temporaryDefaultView`,
        title: '',
        description: '',
        defaultView: true,
        state: defaultState,
        reportType,
      };

      createTemporaryDefaultReportView(reportView);
      return reportView;
    };

    const getRemoteReportView = async () => {
      // attempt to fetch remote report by ID
      const storeReportView = find(reportViews, { id: reportViewId, reportType });

      if (debugMode) {
        console.groupCollapsed(
          'WEB VIEW INFO - [REPORT LOADER] - getRemoteReportView -> storeReportView'
        );
        storeReportView
          ? console.info('storeReportView', storeReportView)
          : console.info('storeReportView is null so we will fetchReportView from BACKEND');
        console.groupEnd();
      }

      if (storeReportView) return storeReportView;

      return fetchReportView(reportViewId);
    };

    const getLocalReportView = async () => {
      // attempt to fetch local report by ID
      const localReportView = await fetchLocalReportView(reportViewId);

      if (debugMode) {
        console.groupCollapsed(
          'WEB VIEW INFO - [REPORT LOADER] - getLocalReportView -> localReportView'
        );
        localReportView
          ? console.info('localReportView', localReportView)
          : console.info('localReportView is null so an error will be thrown.');
        console.groupEnd();
      }

      if (!localReportView) throw new Error('Report not found.'); // TODO: streamline error handling; exception will have already been thrown

      const defaultReportView = getDefaultReportView();

      return {
        ...defaultReportView,
        title: '',
        description: '',
        defaultView: false,
        ...localReportView,
        state: { ...defaultReportView.state, ...localReportView.state },
      };
    };

    const loadReport = async () => {
      try {
        if (isLoaded !== false) {
          setIsLoaded(false);
          setLoadError(undefined);
        }

        let reportView;

        switch (reportViewIdType) {
          case 'default':
            reportView = await getDefaultReportView();
            break;
          case 'remote':
            reportView = await getRemoteReportView();
            break;
          case 'local':
            reportView = await getLocalReportView();
            break;
          default:
            // unrecognized reportViewId format
            setIsLoaded(false);
            setLoadError(new Error('Unrecognized reportViewId format.'));
        }

        if (reportView.reportType !== reportType)
          throw new Error('Retrieved report is the wrong type.');

        if (debugMode) {
          console.groupCollapsed('WEB VIEW INFO - [REPORT LOADER] - loadReport -> results');
          console.info('reportViewIdType', reportViewIdType);
          console.info('reportView', reportView);
          console.groupEnd();
        }
        // restore the reportView
        restoreReport(reportView);
        setIsLoaded(true);
        setLoadError(undefined);
      } catch (err) {
        if (ignoreError) return;
        setIsLoaded(false);
        setLoadError(err);
      }
    };
    loadReport();
    return () => {
      ignoreError = true;
    };
  }, [reportViewId]);

  if (reportViewId === 'default') {
    return (
      <>
        <ProgressOverlay isOpen={!isLoaded} />
        <LoadFailedDialog
          title="Cannot Open Report"
          isOpen={!!loadError}
          loadError={loadError}
          buttons={[
            {
              label: 'Exit Report',
              onClick: route.close,
            },
          ]}
        >
          Default report could not be retrieved.
        </LoadFailedDialog>
      </>
    );
  }

  return (
    <>
      <ProgressOverlay isOpen={!isLoaded} />
      <LoadFailedDialog
        title="Cannot Open Report"
        isOpen={!!loadError}
        loadError={loadError}
        buttons={[
          {
            label: 'Go to default',
            onClick: () =>
              route.open({ ...route.matched.values, reportViewId: 'default' }, { replace: true }),
          },
        ]}
      >
        Report may have been deleted or unshared.
      </LoadFailedDialog>
    </>
  );
};

ReportLoader.propTypes = {
  reportViews: PropTypes.array.isRequired,
  fetchReportView: PropTypes.func.isRequired,
  fetchLocalReportView: PropTypes.func.isRequired,
  fetchDefaultReportViewsByReportType: PropTypes.func.isRequired,
  createTemporaryDefaultReportView: PropTypes.func.isRequired,
};
