// ROOT ROUTE
import { administration } from '../../administration/routes';

// CONFIGURATION ROUTES
import { activitySummary } from '../../administration/configurations/Activities/routes';
import {
  apiKeySummary,
  apiKeyDetail,
  apiKeyCreate,
} from '../../administration/configurations/ApiKeys/routes';

import {
  companionAppSummary,
  companionAppCreate,
  companionAppDetail,
} from '../../administration/configurations/CompanionApps/routes';

import { delaySummary } from '../../administration/configurations/Delays/routes';

import { dispatchAbortReasonSummary } from '../../administration/configurations/DispatchAbortReasons/routes';

import {
  inspectionTasksSummary,
  inspectionTaskCreate,
  inspectionTaskDetail,
} from '../../administration/configurations/InspectionTasks/routes';

import {
  inspectionTemplateSummary,
  inspectionTemplateCreate,
  inspectionTemplateDetail,
} from '../../administration/configurations/InspectionTemplates/routes';

import {
  organizationsSummary,
  organizationCreate,
  organizationDetail,
} from '../../administration/configurations/Organizations/routes';

import {
  performanceRuleSummary,
  performanceRulesCreate,
  performanceRulesDetail,
} from '../../administration/configurations/PerformanceRules/routes';

import { speedLimitSummary } from '../../administration/configurations/SpeedLimits/routes';
import { systemSettings } from '../../administration/configurations/SystemSettings/routes';

import {
  tenantSummary,
  tenantCreate,
  tenantConfigDetail,
  tenantDetail,
} from '../../administration/configurations/Tenants/routes';

// DEVICES ROUTES
import { activationCodesSummary } from '../../administration/devices/ActivationCodes/routes';
import {
  cameraSummary,
  cameraDetail,
  cameraRecordingsDetail,
} from '../../administration/devices/Cameras/routes';
import { tabletSummary, tabletDetail } from '../../administration/devices/Tablets/routes';
import { ecmReportSummary } from '../../administration/devices/EcmReports/routes';

// RESOURCES ROUTES
import { locationSummary } from '../../administration/resources/Locations/routes';

import {
  locationTypeSummary,
  locationTypeCreate,
  locationTypeDetail,
} from '../../administration/resources/LocationTypes/routes';

import { roleSummary } from '../../administration/resources/Roles/routes';

import {
  userSummary,
  userCreateSupport,
  userCreateDriver,
  userDetail,
  userProfile,
} from '../../administration/resources/Users/routes';

import {
  vehicleSummary,
  vehicleDetail,
  vehicleCreate,
  vehicleConfig,
} from '../../administration/resources/Vehicles/routes';

import {
  vehicleTypesSummary,
  vehicleTypeCreate,
  vehicleTypeDetail,
} from '../../administration/resources/VehicleTypes/routes';

// Routes
export const administrationRoutes = [
  {
    match: (path) => path.match(/^\/administration\/profile$/),
    redirectTo: '/profile',
  },
  administration,
  activitySummary,
  apiKeySummary,
  apiKeyCreate,
  apiKeyDetail,
  companionAppSummary,
  companionAppCreate,
  companionAppDetail,
  tabletSummary,
  activationCodesSummary, // this needs to be after devicesSummary because of the routeFrame
  tabletDetail,
  ecmReportSummary,
  dispatchAbortReasonSummary,
  inspectionTemplateSummary,
  inspectionTemplateCreate,
  inspectionTemplateDetail,
  inspectionTasksSummary, // this needs to be after inspectionTemplates' route because it is a child route
  inspectionTaskCreate,
  inspectionTaskDetail,
  locationSummary,
  locationTypeSummary,
  locationTypeCreate,
  locationTypeDetail,
  delaySummary,
  vehicleSummary,
  vehicleCreate,
  vehicleConfig,
  vehicleDetail,
  cameraSummary, // this needs to be after tabletDetail because of the routeFrame
  cameraDetail,
  cameraRecordingsDetail,
  vehicleTypesSummary,
  vehicleTypeCreate,
  vehicleTypeDetail,
  organizationsSummary,
  organizationCreate,
  organizationDetail,
  performanceRuleSummary,
  performanceRulesCreate,
  performanceRulesDetail,
  tenantSummary,
  tenantCreate,
  tenantConfigDetail,
  tenantDetail,
  userSummary,
  roleSummary, // this needs to be after userSummary because of the routeFrame
  userCreateSupport,
  userCreateDriver,
  userDetail,
  userProfile,
  speedLimitSummary,
  systemSettings,
];
