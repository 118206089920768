import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchShifts: 'reports/shifts/fetchShifts',
  fetchShift: 'reports/shifts/fetchShift',
  fetchShiftActivities: 'reports/shifts/fetchShiftActivities',
};

const initialState = {
  shifts: [],
  fetches: [],
  activitiesByShiftId: {},
};

export const shiftsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchShifts, ACTION_TYPE.fetchShift]))
      draft.shifts = sortBy(shallowMergeEntities(state.shifts, payload), 'startedAt');

    if (actionTypeRequest(type, ACTION_TYPE.fetchShifts)) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchShifts)) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchShiftActivities)) {
      if (payload.length === 0) return;
      const { driverShiftId } = payload[0];
      draft.activitiesByShiftId[driverShiftId] = shallowMergeEntities(
        state.activitiesByShiftId[driverShiftId] || [],
        payload,
        'row'
      );
    }
  });
