import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, NumberInput, Form } from 'stti-react-common';

import { valueIsNull, durationUnitOptions } from './common';
import { unconvertUnit } from '../../../../../helpers/formats';

const { Control, HiddenControl } = Form;

export const Duration = ({ preText, postText, defaultValues, error = valueIsNull }) => (
  <div className="Report__EditExceptionRuleDialog__RuleComponent">
    <HiddenControl name="duration" defaultValue={defaultValues.duration} />
    {preText}&nbsp;
    <Control
      Component={NumberInput}
      name="durationValue"
      defaultValue={defaultValues.durationValue}
      onSet={({ draft }) => {
        draft.duration = unconvertUnit('duration', draft.durationValue, draft.durationUnit);
      }}
      error={error}
    />
    &nbsp;
    <Control
      Component={SelectInput}
      name="durationUnit"
      defaultValue={defaultValues.durationUnit}
      options={durationUnitOptions}
      onSet={({ draft }) => {
        draft.duration = unconvertUnit('duration', draft.durationValue, draft.durationUnit);
      }}
    />
    &nbsp;{postText}
  </div>
);

Duration.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  defaultValues: PropTypes.shape({
    duration: PropTypes.any,
    durationValue: PropTypes.number,
    durationUnit: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
