import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'stti-react-common';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { columnDefs } from './columnDefs';
import { useDebounce } from '../../../../../helpers/hooks';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { Feature } from '../../../../commons/Feature';
import { AdminSearchPanel } from '../../../../commons/AdminCommon';
import { ToggleChip } from '../../../../commons/ToggleChip';
import { TenantSupportUser } from '../SupportUser/supportUser';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const TenantSummary = ({
  closeRoute,
  createTenantSupportUser,
  fetchTenants,
  openTenantConfigDetail,
  openTenantCreate,
  openTenantDetail,
  tenant,
  tenants,
  systemUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');
  const [openSupportUserModal, setOpenSupportUserModal] = useState(false);
  const [tenantData, setTenantData] = useState(null);

  useEffect(() => {
    fetchTenants();
  }, []);

  const { key, isSystem } = tenant;

  const hideAddSupportUser = useMemo(
    () => !systemUser?.username?.includes('streamline@'),
    [systemUser]
  );

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) =>
      !data.systemDefault && (
        <>
          <Button
            icon="person_add"
            onClick={() => {
              setTenantData(data);
              setTimeout(() => setOpenSupportUserModal(true), 100);
            }}
            disabled={hideAddSupportUser && !isSystem}
          />
          <Button
            icon="edit"
            onClick={() => openTenantDetail({ tenantId: data.key, mode: 'edit' })}
          />
          <Button
            icon="build"
            onClick={() => openTenantConfigDetail({ tenantId: data.key, mode: 'edit' })}
          />
        </>
      ),
    ToggleChip: ({ value }) => <ToggleChip value={value} />,
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  if (key && !isSystem) {
    openTenantDetail({ tenantId: tenant.key }, { replace: true });
    closeRoute();
  }

  if (!key && !isSystem) return <div />;

  return (
    <Feature.Wrapper className="TenantSummary">
      <Feature.Header
        onClose={closeRoute}
        title={isSystem ? 'Tenants' : 'Tenant'}
        service="tenants"
      >
        <Button
          icon="add"
          variant="contained"
          label="Create a New Tenant"
          onClick={() => openTenantCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Tenants"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rowIdProperty="key"
          rows={tenants}
          searchText={debouncedSearchText}
          sizeColumnsToFit
        />
        <TenantSupportUser
          createUser={createTenantSupportUser}
          openModal={openSupportUserModal}
          setOpenModal={setOpenSupportUserModal}
          tenant={tenantData}
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

TenantSummary.propTypes = {
  closeRoute: PropTypes.func.isRequired,
  createTenantSupportUser: PropTypes.func.isRequired,
  fetchTenants: PropTypes.func.isRequired,
  openTenantConfigDetail: PropTypes.func.isRequired,
  openTenantCreate: PropTypes.func.isRequired,
  openTenantDetail: PropTypes.func.isRequired,
  tenant: PropTypes.oneOfType([PropTypes.object, customPropTypes.tenant]),
  tenants: customPropTypes.tenants,
  systemUser: customPropTypes.tenant,
};
