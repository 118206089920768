import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import {
  Button,
  ColorInput,
  Form,
  Icon,
  ImageInput,
  NumberInput,
  ProgressOverlay,
  SelectInput,
  TextInput,
} from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { validateName, validateEmail } from '../../../../../helpers/form/validations';
import { useApiError } from '../../../../../helpers/hooks';

import { FEATURES } from '../../../../../features';
import { TenantSupportUser } from '../SupportUser/supportUser';

import './detail.scss';

export const TenantDetail = ({
  createTenant,
  createTenantSupportUser,
  fetchCurrentTenant,
  fetchTenant,
  isSystem,
  mode: initialMode = 'view',
  route,
  tenant,
  tenantId,
  updateTenant,
  systemUser,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [openSupportUserModal, setOpenSupportUserModal] = useState(false);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      fetchTenant(tenantId)
        .then(() => resetControls())
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, tenantId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...tenant,
    },
  });

  const { controls: formControls, resetControls } = form;

  const hideField = useMemo(
    () => !systemUser?.username?.includes('streamline@') && mode !== 'create',
    [systemUser]
  );

  const hideAddSupportUser = useMemo(
    () => !systemUser?.username?.includes('streamline@'),
    [systemUser]
  );

  const forceHideOnUpdate = useMemo(() => mode === 'edit' || mode === 'view', [mode]);

  return (
    <div className="TenantDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <>
          <CrudAdminItem
            title="Tenant"
            form={form}
            itemTitle={formControls.name || ''}
            hasError={form.hasErrors}
            isDirty={form.isDirty}
            mode={mode}
            onApiError={setApiError}
            setMode={setMode}
            data={formControls}
            onClose={() => {
              route.close();
              if (!isSystem) {
                fetchCurrentTenant();
              }
            }}
            createItem={createTenant}
            updateItem={updateTenant}
            resetControls={resetControls}
          >
            <ErrorSummary errorMsg={apiError} />
            <>
              {!hideField && (
                <Control
                  Component={TextInput}
                  name="name"
                  label="Tenant Name"
                  error={({ value }) => !value || !value.trim()}
                />
              )}
              <Control
                name="brandPrimary"
                label="Branding Colour"
                defaultValue="#ea580d"
                Component={ColorInput}
                error={({ value }) =>
                  !/^#[0-9a-f]{6}$/.test(value) && 'Select or enter a valid color.'
                }
              />
              <Control
                Component={TextInput}
                name="tenantAddress"
                label="Address"
                multiline
                error={({ value }) => {
                  if (!value || !value.trim()) {
                    return true;
                  }
                  if (value.length < 30) {
                    return 'Address field needs to have more than 30 characters';
                  }
                  return false;
                }}
              />
              {!hideField && (
                <Control
                  Component={SelectInput}
                  readOnly
                  name="ecmState"
                  label="ECM feature state"
                  error={({ value }) => isNil(value)}
                  defaultValue="Off"
                  options={[
                    { label: 'Off', value: 'Off' },
                    { label: 'Read Only', value: 'ReadOnly' },
                    { label: 'Read / Write', value: 'ReadWrite' },
                  ]}
                />
              )}
              {!FEATURES.admin.hideTenantTimeZone && !hideField && !forceHideOnUpdate && (
                <Control
                  Component={SelectInput}
                  name="timeZone"
                  label="Time Zone"
                  error={({ value }) => isNil(value)}
                  options={moment.tz.names()}
                />
              )}
              {!FEATURES.admin.hidePersonalUseFromTenant && !hideField && (
                <Control
                  Component={SelectInput}
                  name="personalUseDefault"
                  label="Personal Use Default"
                  defaultValue={false}
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                />
              )}
              {!FEATURES.admin.hideYardMovesFromTenant && !hideField && (
                <Control
                  Component={SelectInput}
                  name="yardMovesDefault"
                  label="Yard Moves Default"
                  defaultValue={false}
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                />
              )}
              {!hideField && (
                <Control
                  Component={TextInput}
                  name="tenantAdminUsername"
                  label="Administrator Username"
                  error={({ value }) => {
                    if (isNil(value) || isEmpty(value)) return true;
                    if (value.length < 4) {
                      return 'Administrator Username needs to have more than 4 characters';
                    }
                    if (value.length > 60) {
                      return 'Administrator Username needs to have less than 60 characters';
                    }
                    return false;
                  }}
                />
              )}
              {!hideField && (
                <Control
                  Component={TextInput}
                  name="tenantAdmin"
                  label="Administrator Email"
                  error={({ value }) => validateEmail(value)}
                />
              )}
              {!hideField && !forceHideOnUpdate && (
                <Control
                  Component={TextInput}
                  name="adminPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  label="Administrator Password"
                  error={({ value }) => !value || !value.trim()}
                  endAdornment={
                    <Icon
                      className="TenantAdminDetail__visibilityIcon"
                      icon={passwordVisible ? 'passwordVisible' : 'visibility_off'}
                      onClick={() => setPasswordVisible((prevVisibility) => !prevVisibility)}
                    />
                  }
                />
              )}
              {!hideField && !forceHideOnUpdate && (
                <Control
                  Component={TextInput}
                  name="tenantAdminFirstName"
                  label="Administrator First Name"
                  error={({ value }) => validateName('First', value)}
                />
              )}
              {!hideField && !forceHideOnUpdate && (
                <Control
                  Component={TextInput}
                  name="tenantAdminLastName"
                  label="Administrator Last Name"
                  error={({ value }) => validateName('Last', value)}
                />
              )}
              {!hideField && (
                <Control
                  Component={NumberInput}
                  name="licenseCount"
                  label="License count"
                  error={({ value }) => !value || value < 0}
                  readOnly={!isSystem}
                />
              )}
              {!hideField && (
                <Control Component={NumberInput} name="deviceCount" label="Device count" readOnly />
              )}
              <Control Component={ImageInput} name="logo" label="Tenant Logo" />
            </>
          </CrudAdminItem>
          {!hideAddSupportUser && isSystem && (
            <Button
              variant="outlined"
              label="Add Support User"
              onClick={() => setOpenSupportUserModal(true)}
              disabled={mode === 'create'}
            />
          )}
        </>
      )}
      <TenantSupportUser
        createUser={createTenantSupportUser}
        openModal={openSupportUserModal}
        setOpenModal={setOpenSupportUserModal}
        tenant={tenant}
      />
    </div>
  );
};

TenantDetail.propTypes = {
  createTenant: PropTypes.func.isRequired,
  createTenantSupportUser: PropTypes.func.isRequired,
  fetchCurrentTenant: PropTypes.func.isRequired,
  fetchTenant: PropTypes.func.isRequired,
  isSystem: PropTypes.bool,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
  tenantId: PropTypes.string,
  updateTenant: PropTypes.func.isRequired,
  systemUser: PropTypes.object.isRequired,
};
