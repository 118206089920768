import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';

import { SectionPanel } from '../../../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import {
  countOf,
  countUniqueOfField,
  countUniqueOfArrayField,
} from '../../../../../../helpers/reports/aggregation';

export const AggregatesPanel = ({ rows, reportType }) => {
  if (!rows) return '';

  const drivers =
    reportType === 'fuelTaxSummary'
      ? flatten(rows.map((row) => (row && row.drivers ? row.drivers : [])))
      : rows.map((row) => row.driver);

  return (
    <SectionPanel
      name="aggregatesPanel"
      title="Aggregates"
      renderSummaries={(props) =>
        reportType === 'fuelTaxSummary' ? (
          <div {...props}>
            {countUniqueOfArrayField(rows, 'vehicle.name')} vehicles,{' '}
            {countUniqueOfArrayField(drivers, 'name')} drivers,{' '}
            {countUniqueOfField(rows, 'fuelType')} fuel types
          </div>
        ) : (
          <div {...props}>
            {countOf(rows)} segments, {countUniqueOfArrayField(drivers, 'name')} drivers,{' '}
            {countUniqueOfArrayField(rows, 'fuelVendors')} fuel vendors
          </div>
        )
      }
    >
      <AggregatesContent rows={rows} reportType={reportType} />
    </SectionPanel>
  );
};

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
  reportType: PropTypes.string,
};
