import React from 'react';
import moment from 'moment-timezone';
import { Chip } from 'stti-react-common';
import { isNil } from 'lodash';

import { SectionPanel, InfoField } from '../../../commons/ReportsCommon';
import { useFormats } from '../../../commons/Formats';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const DetailPanel = ({ inspection }) => {
  const { formatUnit } = useFormats();

  const {
    assetName,
    assetKey,
    assetPlateNumber,
    assetJurisdictionCountry,
    assetJurisdictionState,
    odometer,
    engineHours,
    safeToDrive,
    startedAt,
    carrier,
    principalName,
    inspectorName,
    inspectorSignature,
    inspectionType,
    complianceText,
    timeZone,
    duration,
    diagnosticTroubleCodes,
    vehicleDetailUrl,
  } = inspection || {};

  const formattedEngineHours = !isNil(engineHours) ? `${formatUnit('duration', engineHours)}` : '';
  const formattedDuration = !isNil(duration)
    ? `${formatUnit('duration', duration, 'h:mm:ss')}`
    : '';
  const formattedOdometer = odometer && `${formatUnit('distance', odometer)}`;
  const formattedEndedAt = startedAt && formatUnit('date', moment(startedAt).tz(timeZone));
  const formattedDTCs = diagnosticTroubleCodes
    ? diagnosticTroubleCodes.map((dtc) => `${dtc.code}`).join(', ')
    : 'None';

  // DTC Disclaimer Note
  const dtcDisclaimer =
    'The maximum number of diagnostic trouble codes we report is 4. There may be more codes present on the vehicle.';

  return (
    <SectionPanel
      name="detailPanel"
      title="Detail"
      renderSummaries={() =>
        inspection && (
          <>
            <Chip label={formattedEndedAt} />
            <Chip label={`Vehicle: ${assetName}`} />
            <Chip label={`Inspector: ${inspectorName}`} />
            <Chip
              color={safeToDrive ? 'primary' : 'secondary'}
              label={`Safe to Drive: ${safeToDrive ? 'Yes' : 'No'}`}
            />
          </>
        )
      }
    >
      <InfoField name="date" label="Date" value={formattedEndedAt} />

      <InfoField
        name="assetName"
        label="Vehicle ID"
        value={assetName}
        conditionalCase={
          assetKey ? (
            <a href={vehicleDetailUrl} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {assetName}
            </a>
          ) : (
            assetName
          )
        }
      />

      <InfoField
        name="assetPlateNumber"
        label="Vehicle Plate Number"
        value={assetPlateNumber && `${assetPlateNumber}`}
      />
      <InfoField
        name="jurisdiction"
        label="Jurisdiction"
        value={
          assetJurisdictionCountry &&
          assetJurisdictionState &&
          `${assetJurisdictionCountry} ${assetJurisdictionState}`
        }
      />
      <InfoField name="odometer" label="Odometer" value={formattedOdometer} />
      <InfoField name="engineHours" label="Engine Hours" value={formattedEngineHours} />
      <InfoField name="duration" label="Duration" value={formattedDuration} />
      <InfoField
        name="safeToDrive"
        label="Safe To Drive"
        value={safeToDrive}
        chipLabels={['Yes', 'No']}
      />
      <InfoField name="carrier" label="Carrier" value={carrier} />
      <InfoField name="principalName" label="Principal Name" value={principalName} />
      <InfoField name="inspectionType" label="Inspection Type" value={inspectionType} />
      <InfoField name="complianceText" label="Compliance Text" value={complianceText} />
      <InfoField name="inspectorName" label="Inspector Name" value={inspectorName} />
      <InfoField
        name="inspectorSignature"
        label="Inspector Signature"
        value={inspectorSignature}
        isImage
      />
      <InfoField name="dtc" label="DTC Codes" value={formattedDTCs} className="InfoField--dtc" />

      {diagnosticTroubleCodes && diagnosticTroubleCodes.length > 0 && (
        <div className="InspectionDetailReport__dtcDisclaimer">{dtcDisclaimer}</div>
      )}
    </SectionPanel>
  );
};

DetailPanel.propTypes = {
  inspection: customPropTypes.inspection,
};
