import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  SignalWebclientViewReady,
  FetchReportDetail,
} from '../../../commons/ReportsCommon';

// import Cards
import { AnnotationsEventsCard } from './Cards/AnnotationsEventsCard';
import { CycleEventsCard } from './Cards/CycleEventsCard';
import { DriverCard } from './Cards/DriverCard';
import { DriverEventsCard } from './Cards/DriverEventsCard';
import { EnginePowerEventsCard } from './Cards/EnginePowerEventsCard';
import { MalfunctionAndDiagnosticEventsCard } from './Cards/MalfunctionAndDiagnosticEventsCard';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { debugMode } from '../../../../helpers/debug';
import { PrintLayout } from '../../../commons/PrintLayout';
import { UNREVIEWED_VIOLATION_STATUS } from '../../../../data/reports';

import './CanadianEldLogDetailReport.scss';

const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';

export const CanadianEldLogDetailReport = ({
  driverLog,
  driverKey,
  date,
  reportViewId,
  setPrintMode,
  route,
  fetchDriverLog,
  fetchDriverLogViolation,
}) => {
  const isNotReviewed = driverLog && driverLog.violationStatus === UNREVIEWED_VIOLATION_STATUS;
  const isUnsigned =
    driverLog && driverLog.certificationStatus && !driverLog.certificationStatus.certified;

  const todayDate = moment().format(NORMAL_DATE_FORMAT);
  const isDateValid = moment(date, NORMAL_DATE_FORMAT).format(NORMAL_DATE_FORMAT) === date;
  const isDateInFuture = isDateValid && date > todayDate;
  const isDateAcceptable = isDateValid && !isDateInFuture;

  const report = useReportController({
    reportType: 'driverLogDetail',
    reportTypeName:
      driverLog && driverLog.driver
        ? `Canadian ELD Daily Log: ${driverLog.driver.name}`
        : `Canadian ELD Daily Log`,
    reportViewId,
    route,
  });

  const { reportType } = report;

  const isPartial = driverLog && !driverLog.endedAt;

  const logSummaryPrintDetails = useMemo(() => {
    const details = [];

    if (isNotReviewed) {
      details.push('Unreviewed Violations');
    }

    if (isUnsigned) {
      details.push('Unsigned Log');
    }

    if (isPartial) {
      details.push('Partial Log');
    }

    if (details.length === 0 && !isUnsigned) {
      details.push('Signed Log');
    }
    return details;
  }, [isPartial, isUnsigned, isNotReviewed]);

  const [dateInputValue, setDateInputValue] = useState(null);

  useEffect(() => {
    if (!isDateValid) return;
    setDateInputValue(date);
  }, [isDateValid, date]);

  useEffect(() => {
    if (!dateInputValue || dateInputValue === date) return;
    route.open({
      driverKey,
      date: dateInputValue,
      reportViewId,
    });
  }, [dateInputValue]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {isDateAcceptable && (
        <FetchReportDetail action={fetchDriverLog} args={{ driverKey, date, eld: 'cdn' }} />
      )}
      {driverLog && driverLog.reviewDetailsKey && (
        <FetchReportDetail action={fetchDriverLogViolation} args={driverLog.reviewDetailsKey} />
      )}
      <SignalWebclientViewReady />
      <div className="CanadianEldLogDetailReport">
        <PrintLayout mode={reportType} inline debug={debugMode}>
          <ReportFragment.Header reportType="driverLog" route={route} hideExport />
          <DriverCard
            log={driverLog}
            params={route.getAllParams()}
            logSummaryPrintDetails={logSummaryPrintDetails}
          />
          <DriverEventsCard log={driverLog} printLayoutMode={reportType} />
          <br />
          <MalfunctionAndDiagnosticEventsCard log={driverLog} printLayoutMode={reportType} />
          <br />
          <CycleEventsCard log={driverLog} printLayoutMode={reportType} />
          <br />
          <AnnotationsEventsCard log={driverLog} printLayoutMode={reportType} />
          <br />
          <EnginePowerEventsCard log={driverLog} printLayoutMode={reportType} />
          <br />
        </PrintLayout>
      </div>
    </ReportProvider>
  );
};

CanadianEldLogDetailReport.propTypes = {
  driverLog: customPropTypes.detailedDriverLog,
  setPrintMode: PropTypes.func.isRequired,
  driverKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  fetchDriverLog: PropTypes.func.isRequired,
  fetchDriverLogViolation: PropTypes.func.isRequired,
};
