import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { useInstance } from 'stti-react-common';

import { useReport } from './useReport';

export const ReportControl = ({
  autoGridArea,
  canDeleteSummary,
  defaultValue,
  error,
  filterConfig,
  group,
  instant,
  label,
  name,
  printConfig,
  reducerCallback,
  reducerCallbackOnRestore,
  render = () => null, // controls without render are therefore invisible
  renderSummary,
  reRegisterOn = [],
  unpersisted,
  ...restProps
}) => {
  const { controls, setControl, registerControl, registerFilter, formats, filteredRows } =
    useReport();

  const instance = useInstance();

  const handleChange = useCallback((newValue) => setControl(name, newValue), [name]);
  const style = useMemo(() => autoGridArea && { gridArea: name }, [autoGridArea, name]);

  useEffect(() => {
    // register control
    instance.unregisterControl = registerControl(name, {
      label,
      group,
      defaultValue,
      reducerCallback,
      reducerCallbackOnRestore,
      renderSummary,
      canDeleteSummary,
      printConfig,
      error,
      instant,
      unpersisted,
    });
    // register filter if applicable
    if (filterConfig)
      instance.unregisterFilter = registerFilter({ controlName: name, ...filterConfig });

    return () => {
      instance.unregisterControl();
      if (instance.unregisterFilter) instance.unregisterFilter();
    };
  }, reRegisterOn);
  const value = controls[name];
  const errorState = isFunction(error) ? error({ value, controls, filteredRows }) : error;
  const props = {
    label,
    value,
    error: errorState,
    onChange: handleChange,
    className: `ReportControl--${name}`,
    style,
    ...restProps,
  };
  return render(props, { value, controls, formats, filteredRows });
};

ReportControl.propTypes = {
  defaultValue: PropTypes.any,
  reducerCallback: PropTypes.func,
  reducerCallbackOnRestore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterConfig: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    group: PropTypes.string.isRequired,
    controlName: PropTypes.string,
    getValues: PropTypes.func,
    disabled: PropTypes.func,
  }),
  render: PropTypes.func,
  renderSummary: PropTypes.func,
  printConfig: PropTypes.func,
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  label: PropTypes.string,
  name: PropTypes.string,
  canDeleteSummary: PropTypes.bool,
  autoGridArea: PropTypes.bool,
  reRegisterOn: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
