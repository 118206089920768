import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import { InfoField, HidePrint, useReport } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

export const OnDutySummaryCard = ({ log, hidePrint }) => {
  const { formatUnit } = useFormats();
  const { formats } = useReport();

  const { hoursOnDutySummary } = log || {};

  const hoursOnDutySummaryFormatted = useMemo(
    () =>
      hoursOnDutySummary 
        ? hoursOnDutySummary.map(({ index, logDate, time }) => ({
            date: formatUnit(
              'date',
              moment(logDate),
              'MMM D'
            ),
            time: formatUnit('duration', time),
            index,
          }))
        : [],
    [hoursOnDutySummary, formats]
  );

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__OnDutyCard" fullWidth>
        <h4>14 Day Summary</h4>
        <div className="DriverLogDetailReport__infoFieldContainer">
          {hoursOnDutySummaryFormatted.map(({ date, time }) => (
            <InfoField
              key={`${date} + ${time}`}
              name={`${date} + ${time}`}
              label={date}
              value={time}
            />
          ))}
        </div>
      </Card>
    </HidePrint>
  );
};

OnDutySummaryCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
