import { find, sortBy, uniqueId, last, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { selectSystemUser } from '../system';
import {
  DISPATCHER_ROLE_KEY,
  MANAGER_ROLE_KEY,
  SUPER_ADMIN_ROLE_KEY,
  ADMIN_ROLE_KEY,
} from '../system/constants';
import {
  UNREVIEWED_VIOLATION_STATUS,
  REVIEWED_VIOLATION_STATUS,
  UNKNOWN_VIOLATION_STATUS,
  NO_VIOLATION_STATUS,
} from './driverLogsConstants';

export const getDriverLogs = (state) => state.reports.driverLogs.data;
export const getDriverLogsFetchRequests = (state) => state.reports.driverLogs.fetches;
export const getUsers = (state) => state.administration.resources.users.data;

const eventCodeFormatter = (code) => {
  switch (code) {
    case '1':
    case 'P':
      return 'Power';
    case '2':
    case 'E':
      return 'Engine Sync';
    case 'T':
      return 'Timing';
    case 'L':
      return 'Positioning';
    case 'R':
      return 'Data Recording';
    case '3':
      return 'Missing Data';
    case '4':
    case 'S':
      return 'Data Transfer';
    case '5':
      return 'Unid. Driver';
    case '6':
    default:
      return 'Other';
  }
};

const hasPermission = (user) =>
  user.memberships.some(
    ({ role }) =>
      role &&
      (role.includes(DISPATCHER_ROLE_KEY) ||
        role.includes(SUPER_ADMIN_ROLE_KEY) ||
        role.includes(MANAGER_ROLE_KEY) ||
        role.includes(ADMIN_ROLE_KEY))
  );

const isTwoWeeksFromToday = (logDate) => {
  const twoWeeksBeforeToday = moment().subtract(14, 'days').startOf('day');

  const currentLogDate = moment(logDate).startOf('day');

  return currentLogDate.isAfter(twoWeeksBeforeToday);
};

const makeSimpleDriverLog = (log, users) => {
  let violationStatus;
  if (log.reviewDetails) {
    if (log.reviewDetails.isReviewed) {
      violationStatus = REVIEWED_VIOLATION_STATUS;
    } else {
      violationStatus = UNREVIEWED_VIOLATION_STATUS;
    }
  } else if (log.logStanding === 'IN_VIOLATION') {
    violationStatus = UNKNOWN_VIOLATION_STATUS; // has violations, but review status unknown until log.reviewDetails is populated
  } else if (log.hasActivity) {
    violationStatus = NO_VIOLATION_STATUS;
  }

  const { driver } = log;
  const driverEnabled = (users.find((user) => user.key === driver.key) || {}).enabled;

  const extendedLog = {
    ...log,
    driver: { ...driver, enabled: driverEnabled },
    violationStatus,
  };

  return extendedLog;
};

const recordOriginCodeAppend = (origin) => {
  if (!origin) return '';
  switch (origin) {
    case 'ELD':
      return 'Eld(1)';
    case 'DRIVER':
      return 'Driver(2)';
    case 'OTHER_USER':
      return 'Other User(3)';
    case 'UNIDENTIFIED_DRIVER':
      return 'Unidentified Driver(4)';
    case 'INTERMEDIATE':
      return 'Intermediate(1)';
    default:
      return origin.toLower();
  }
};

const recordStatusCodeAppend = (status) => {
  if (!status) return '';
  switch (status) {
    case 'ACTIVE':
      return 'Active(1)';
    case 'INACTIVE_CHANGED':
      return 'Inactive Changed(2)';
    case 'INACTIVE_CHANGE_REQUESTED':
      return 'Inactive Change Requested(3)';
    case 'INACTIVE_CHANGE_REJECTED':
      return 'Inactive Change Rejected(4)';
    default:
      return 'Inactive Changed(2)';
  }
};

const cycleCodeByName = (cycle) => {
  if (!cycle) return '';
  switch (cycle) {
    case 'CAD_CYCLE_1':
      return '7';
    case 'CAD_CYCLE_2':
      return '14';
    case 'US_7_DAYS_CYCLE':
      return '7';
    case 'US_8_DAYS_CYCLE':
      return '8';
    default:
      return 'N/A';
  }
};

const findReferencedVehicleByKey = (referencedVehicles, key) => {
  if (!referencedVehicles) return null;
  return (find(referencedVehicles, { key }) || {}).assetId;
};

const canManageLogDutyStatuses = (user, logDate, certificationStatus) =>
  hasPermission(user) &&
  isTwoWeeksFromToday(logDate) &&
  certificationStatus &&
  certificationStatus.certified;

const makeDetailedDriverLog = (log, user) => {
  const {
    dutyIntervals = [],
    annotations = [],
    effectiveRegulations = [],
    adminStatusChanges = [],
    start,
    violations = [],
    certifications = [], // re-composed into eldEvents
    health = [], // re-composed into eldEvents
    enginePower = [], // re-composed into eldEvents
    sessions = [], // re-composed into eldEvents
    specialMovements = [], // re-composed into eldEvents
    operatingZones = [], // re-composed into eldEvents
    timeDeferralEvents = [], // re-composed into eldEvents
    driverCycleEvents = [], // re-composed into eldEvents
    vehicleDetails = [],
    ...restLog
  } = log;

  const { timeZone, violationStatus, logDate, certificationStatus, movementIntervals = [] } = log;
  const { dutyStatusChanges = [] } = restLog;

  return {
    ...restLog,
    movementIntervals: movementIntervals.map((interval, index) => ({
      ...interval,
      previousDutyStatus: index === 0 ? 'Off Duty' : movementIntervals[index - 1].dutyStatus,
    })),
    vehicleDetails: uniqBy(
      vehicleDetails.map((vehicleDetail) => ({
        currentLocation: (
          last(dutyStatusChanges.filter(({ vehicleKey }) => vehicleKey === vehicleDetail.key)) || {}
        ).geoLocation,
        location: (
          last(dutyStatusChanges.filter(({ vehicleKey }) => vehicleKey === vehicleDetail.key)) || {}
        ).location,
        ...vehicleDetail,
      })),
      'assetId'
    ),
    isDetailed: true,
    canEditLogViolations: hasPermission(user) && violationStatus === UNREVIEWED_VIOLATION_STATUS,
    canManageLogDutyStatuses: canManageLogDutyStatuses(user, logDate, certificationStatus),
    driverEvents: sortBy(
      [
        ...dutyIntervals.map((dutyInterval, index) => ({
          date: dutyInterval.start, // has "start" and "end"; add "date" for table
          timeZone,
          ...dutyInterval,
          previousDutyStatus: index === 0 ? 'OFF_DUTY' : dutyIntervals[index - 1].dutyStatus,
          key: dutyInterval.key || (index === 0 ? 'gap-interval-key' : undefined), // first record may be keyless to due being a mock record to fill midnight-until-event gap
          location: (dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {}).location,
          origin: recordOriginCodeAppend(dutyInterval.origin),
          recordStatus: recordStatusCodeAppend(
            (
              dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
                recordStatus: '',
              }
            ).recordStatus
          ),
          eldSequence: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              eldSequence: '',
            }
          ).eldSequence,
          accumulatedVehicleDistance: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              accumulatedVehicleDistance: 0,
            }
          ).accumulatedVehicleDistance,
          totalVehicleDistance: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              totalVehicleDistance: 0,
            }
          ).totalVehicleDistance,
          elapsedEngineHours: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              elapsedEngineHours: 0,
            }
          ).elapsedEngineHours,
          asset: findReferencedVehicleByKey(restLog.referencedVehicles, dutyInterval.vehicleKey),
        })),
        ...annotations
          .filter(({ type }) => type !== 'LogComment')
          .map(({ date, text: annotation, type }, index) => ({
            key: `annotation-${index}`,
            date,
            timeZone,
            annotation,
            type,
          })),
      ],
      'date'
    ),
    effectiveRegulations: sortBy(
      effectiveRegulations.map(({ value, description, regulation, appliesTo, ...rest }) => ({
        key: `${value}${description}${regulation}${appliesTo}${uniqueId()}`,
        value,
        description,
        regulation,
        appliesTo,
        ...rest,
      })),
      'regulation'
    ),
    adminStatusChanges: sortBy(
      adminStatusChanges.map((statusChange) => ({
        timeZone,
        origin: recordOriginCodeAppend(
          (dutyStatusChanges.find(({ key }) => key === statusChange.key) || {}).origin
        ),
        ...statusChange,
        status: recordStatusCodeAppend(statusChange.status),
      })),
      'date'
    ),
    eldEvents: sortBy(
      [
        ...certifications.map((event) => ({ ...event, eventType: `SIGNATURE` })),
        ...health.map(({ code, ...event }) => ({
          ...event,
          code,
          eventCodeDefinition: eventCodeFormatter(code),
          eventType: `DIAGNOSTIC_${event.orientation}`,
        })),
        ...enginePower.map((event) => ({ ...event, eventType: `ENGINE_${event.engineState}` })),
        ...sessions.map((event) => ({ ...event, eventType: `SESSION_${event.orientation}` })),
        ...specialMovements.map((event) => ({
          ...event,
          eventType: `${event.movementType}_${event.orientation}`,
        })),
        ...operatingZones.map((event) => ({ ...event, eventType: `ZONE` })),
        ...timeDeferralEvents.map((event) => ({
          ...event,
          key: event.key || uniqueId(),
          eventType: `DEFERRAL_${event.deferralStatus}`,
        })),
        ...driverCycleEvents.map((event) => ({
          ...event,
          code: cycleCodeByName(event.cycle),
          eventType: `CYCLE_${event.cycle}`,
        })),
        ...dutyStatusChanges.map((event) => ({
          ...event,
          key: event.key || uniqueId(),
          eventType: `DUTY_STATUS_${event.dutyStatus}`,
        })),
      ].map((event) => ({
        timeZone,
        ...event,
        recordOrigin: recordOriginCodeAppend(event.recordOrigin),
        recordStatus: recordStatusCodeAppend(event.recordStatus),
        asset: findReferencedVehicleByKey(restLog.referencedVehicles, event.vehicleKey),
      })),
      'date'
    ),
    violations,
    logAnnotations: sortBy(
      [
        ...annotations
          .filter(({ type }) => type === 'LogComment')
          .map(({ text: annotation, ...rest }) => ({
            annotation,
            timeZone,
            ...rest,
          })),
      ],
      'date'
    ),
  };
};

/**
 * selectDriverLogs(state) -> [driverLog]
 *
 * Selector for all driver logs
 */
export const selectDriverLogs = createSelector([getDriverLogs, getUsers], (driverLogs, users) =>
  driverLogs
    .filter((log) => log.driver) // presence of driver is heuristic for complete record (otherwise is reviewDetails only)
    .map((log) => makeSimpleDriverLog(log, users))
);

/**
 * selectDriverLog(state, { driverKey, date }) -> driverLog
 *
 * Selector for single detailed driverLog by driver key and date
 */
const extractDriverLogParams = (_, { driverKey, date }) => ({ driverKey, date });
const extractDriverLogCacheKey = (_, { driverKey, date }) => `${driverKey}-${date}`;
export const selectDriverLog = createCachedSelector(
  [selectDriverLogs, selectSystemUser, extractDriverLogParams],
  (driverLogs, user, { driverKey, date }) => {
    const log = find(
      driverLogs,
      ({ driver, logDate }) => driver.key === driverKey && logDate === date
    );
    return log && makeDetailedDriverLog(log, user);
  }
)(extractDriverLogCacheKey);

/**
 * selectDriverLogsFetchRequests(state) -> [fetchRequestData]
 *
 * Selector for all summary fetches (active and successful), requestData only
 */
export const selectDriverLogsFetchRequests = createSelector(
  getDriverLogsFetchRequests,
  (fetchRequests) => fetchRequests.map((fetchRequest) => fetchRequest.requestData)
);

/**
 * selectDriverLogFetchRequests(state) -> [fetches]
 *
 * Selector for all fetches that have occurred on detailed driver logs and log violations
 */
export const selectDriverLogFetchRequests = (state) => state.reports.driverLogs.detailFetches;

/**
 * selectDriverLogsReviewDetailsFetchRequests(state) -> [fetches]
 *
 * Selector for all fetches that have occurred on driver logs' review details (OU-based)
 */
export const selectDriverLogsReviewDetailsFetchRequests = (state) =>
  state.reports.driverLogs.reviewDetailsFetches;
