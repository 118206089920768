import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, round, pick, omit } from 'lodash';

import {
  Form,
  SelectInput,
  TextInput,
  ProgressOverlay,
  ToggleInput,
  NumberInput,
} from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { useFormats } from '../../../../commons/Formats';

import { customPropTypes } from '../../../../../helpers/customPropTypes';

import {
  convertConditionValues,
  getRuleTitle,
  revertConditionValues,
  RULE_TYPES,
} from '../../../../../helpers/admin/performanceRules';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

const conditionFormFields = [
  'speedLowerBound',
  'speedUpperBound',
  'boostLowerBound',
  'boostUpperBound',
  'accelLowerBound',
  'accelUpperBound',
  'rpmLowerBound',
  'rpmUpperBound',
];

export const PerformanceRuleDetail = ({
  rule,
  ruleId,
  ruleSetId,
  fetchRule,
  deleteRule,
  createRule,
  updateRule,
  mode: initialMode = 'view',
  route,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  const { unitText, convertUnit, unconvertUnit } = useFormats();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      fetchRule({ ruleSetId, ruleId })
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, ruleId]);

  const { useFormController, Control, HiddenControl } = Form;

  const form = useFormController({
    controls: {
      ...rule,
      ...(rule && rule.pointsPerSecond && { pointsPerSecond: round(rule.pointsPerSecond * 60, 1) }),
      ...convertConditionValues(rule || {}, convertUnit),
    },
  });

  const { controls: formControls, resetControls } = form;

  const formData = (formValues) => {
    const { type, conditions, pointsPerSecond } = formValues;
    const conditionFormValues = pick(formValues, conditionFormFields);
    const payload = omit(formValues, conditionFormFields);

    return {
      ...payload,
      ...(pointsPerSecond && { pointsPerSecond: pointsPerSecond / 60 }),
      conditions: revertConditionValues({ conditions, conditionFormValues, type }, unconvertUnit),
    };
  };

  return (
    <div className="PerformanceRuleDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Performance Rule"
          form={form}
          itemTitle={getRuleTitle(formControls, convertUnit)}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formData(formControls)}
          onClose={route.close}
          createItem={createRule}
          updateItem={updateRule}
          deleteItem={(data) => deleteRule(data)}
          resetControls={resetControls}
        >
          {formControls.type && RULE_TYPES[formControls.type].title && (
            <p>{RULE_TYPES[formControls.type].title}</p>
          )}
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control
              Component={SelectInput}
              name="type"
              label="Type"
              options={Object.keys(RULE_TYPES)}
              error={({ value }) => isNil(value)}
            />
            <Control
              Component={TextInput}
              name="description"
              label="Description"
              error={({ value }) => isNil(value)}
            />
            <HiddenControl
              Component={TextInput}
              name="vehicleType"
              defaultValue={ruleSetId === 'default' ? null : ruleSetId}
            />

            {formControls.type && RULE_TYPES[formControls.type].fields.includes('speed') && (
              <div className="CrudAdminItem__nestedControls">
                <Control
                  Component={NumberInput}
                  name="speedLowerBound"
                  label="Speed"
                  startAdornment="From"
                  endAdornment={unitText('speed')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.speedUpperBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.speedUpperBound) &&
                      value >= formControls.speedUpperBound
                    )
                      return 'From value needs to be lower than To value';
                    if (value < 0) return 'From value needs to be 0 or above';
                    return false;
                  }}
                />
                <Control
                  Component={NumberInput}
                  name="speedUpperBound"
                  label="Speed"
                  startAdornment="to"
                  endAdornment={unitText('speed')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.speedLowerBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.speedLowerBound) &&
                      !isNil(value) &&
                      value <= formControls.speedLowerBound
                    )
                      return 'To value needs to be higher than From value';
                    if (value < 0) return 'To value needs to be 0 or above';
                    return false;
                  }}
                />
              </div>
            )}
            {formControls.type && RULE_TYPES[formControls.type].fields.includes('boost') && (
              <div className="CrudAdminItem__nestedControls">
                <Control
                  Component={NumberInput}
                  name="boostLowerBound"
                  label="Boost"
                  startAdornment="From"
                  endAdornment={unitText('pressure')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.boostUpperBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.boostUpperBound) &&
                      value >= formControls.boostUpperBound
                    )
                      return 'From value needs to be lower than To value';
                    if (value < 0) return 'From value needs to be 0 or above';
                    return false;
                  }}
                />
                <Control
                  Component={NumberInput}
                  name="boostUpperBound"
                  label="Boost"
                  startAdornment="to"
                  endAdornment={unitText('pressure')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.boostLowerBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.boostLowerBound) &&
                      !isNil(value) &&
                      value <= formControls.boostLowerBound
                    )
                      return 'To value needs to be higher than From value';
                    if (value < 0) return 'To value needs to be 0 or above';
                    return false;
                  }}
                />
              </div>
            )}
            {formControls.type && RULE_TYPES[formControls.type].fields.includes('accel') && (
              <div className="CrudAdminItem__nestedControls">
                <Control
                  Component={NumberInput}
                  name="accelLowerBound"
                  label="Deceleration"
                  startAdornment="From"
                  endAdornment={unitText('speed')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.accelUpperBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.accelUpperBound) &&
                      value >= formControls.accelUpperBound
                    )
                      return 'From value needs to be lower than To value';
                    if (value < 0) return 'From value needs to be 0 or above';
                    return false;
                  }}
                />
                <Control
                  Component={NumberInput}
                  name="accelUpperBound"
                  label="Deceleration"
                  startAdornment="to"
                  endAdornment={unitText('speed')}
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.accelLowerBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.accelLowerBound) &&
                      !isNil(value) &&
                      value <= formControls.accelLowerBound
                    )
                      return 'To value needs to be higher than From value';
                    if (value < 0) return 'To value needs to be 0 or above';
                    return false;
                  }}
                />
              </div>
            )}
            {formControls.type && RULE_TYPES[formControls.type].fields.includes('rpm') && (
              <div className="CrudAdminItem__nestedControls">
                <Control
                  Component={NumberInput}
                  name="rpmLowerBound"
                  label="RPM"
                  startAdornment="From"
                  endAdornment="rpm"
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.rpmUpperBound))
                      return '(From) or (To) field needs to have a value';
                    if (!isNil(formControls.rpmUpperBound) && value >= formControls.rpmUpperBound)
                      return 'From value needs to be lower than To value';
                    if (value < 0) return 'From value needs to be 0 or above';
                    return false;
                  }}
                />
                <Control
                  Component={NumberInput}
                  name="rpmUpperBound"
                  label="RPM"
                  startAdornment="to"
                  endAdornment="rpm"
                  error={({ value }) => {
                    if (isNil(value) && isNil(formControls.rpmLowerBound))
                      return '(From) or (To) field needs to have a value';
                    if (
                      !isNil(formControls.rpmLowerBound) &&
                      !isNil(value) &&
                      value <= formControls.rpmLowerBound
                    )
                      return 'To value needs to be higher than From value';
                    if (value < 0) return 'To value needs to be 0 or above';
                    return false;
                  }}
                />
              </div>
            )}
            <Control
              Component={NumberInput}
              name="pointsPerSecond"
              label="Points Per Minute"
              error={({ value }) => {
                if (isNil(value)) return 'Points Per Minute is required';
                if (value < -9999 || value > 9999)
                  return 'Points Per Minute must be between -9999 and 9999';
                return false;
              }}
            />
            <Control
              Component={NumberInput}
              name="gracePeriod"
              label="Grace Period"
              error={({ value }) => {
                if (isNil(value)) return 'Grace Period is required';
                if (value < 0 || value > 999) return 'Grace Period must be between 0 and 999';
                return false;
              }}
            />
            {formControls.type && RULE_TYPES[formControls.type].fields.includes('harsh') && (
              <Control
                Component={ToggleInput}
                defaultValue={false}
                name="harsh"
                label={RULE_TYPES[formControls.type].label}
              />
            )}
          </div>
        </CrudAdminItem>
      )}
    </div>
  );
};

PerformanceRuleDetail.propTypes = {
  rule: customPropTypes.performanceRule,
  ruleId: PropTypes.string,
  ruleSetId: PropTypes.string,
  fetchRule: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
};
