import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, Form } from 'stti-react-common';

import { valueIsNull } from './common';

const { Control } = Form;
export const NumberComponent = ({ preText, postText, defaultValues, error = valueIsNull }) => (
  <div className="Report__EditExceptionRuleDialog__RuleComponent">
    {preText}&nbsp;
    <Control
      Component={NumberInput}
      name="number"
      defaultValue={defaultValues.number}
      error={error}
    />
    &nbsp;{postText}
  </div>
);

NumberComponent.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  defaultValues: PropTypes.shape({
    number: PropTypes.number,
  }),
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
