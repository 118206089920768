import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, BasicDialog, ProgressOverlay } from 'stti-react-common';
import { intersection } from 'lodash';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { getTimelessDate, daysBetweenDates } from '../../../../helpers/moment';
import { PrintLayout } from '../../../commons/PrintLayout';

import {
  useReportController,
  ReportProvider,
  ReportFragment,
  ReportLoader,
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  fetchReportDataOnDemand,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  ControlsTablePrint,
  LaunchReport,
  DetailReportPrintingLinks,
} from '../../../commons/ReportsCommon';

import './PerformancesSummaryReport.scss';

import { columnDefs } from './columnDefs';
import { FiltersPanel } from './FiltersPanel';
import { NA_VALUE } from '../../../../helpers/formats';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = (myPerformancesOnly) => ({
  controls: myPerformancesOnly ? { dateRangeMode: 'thisMonth' } : { dateRangeMode: 'yesterday' },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['performances'];

export const PerformancesSummaryReport = ({
  fetchPerformances,
  fetchMyPerformances,
  performances,
  reportViewId,
  performanceDetailReportRoute,
  route,
  myPerformancesOnly,
  limits,
  user,
  users,
  fetchUsers,
  ous,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'driverPerformancesSummary',
    reportTypeName: myPerformancesOnly
      ? 'My Performance Summary Report'
      : 'Performance Summary Report',
    reportViewId,
    route,
    defaultState: defaultState(myPerformancesOnly),
    filtersConfig: {
      input: performances,
      chain: ['query', 'filters'],
    },
  });

  const { filteredRows, dataGridController, reportType, setControl, debouncedControls } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Driver: ({ data, value }) => (
      <Clickable onClick={() => setControl('driverName', [data.driver])}>{value}</Clickable>
    ),
    Vehicle: ({ data, value }) =>
      value ? (
        <Clickable onClick={() => setControl('vehicleName', [data.vehicleKey])}>{value}</Clickable>
      ) : (
        NA_VALUE
      ),
    OpenPerformanceDetail: ({ data }) =>
      data.incidents.length > 0 && (
        <LaunchReport
          onClick={async () => {
            performanceDetailReportRoute.open({
              date: getTimelessDate(data.startedAt),
              driverKey: data.driver,
              orgKey: data.ouKey,
              shiftId: data.id,
              reportViewId: 'default',
            });
          }}
        />
      ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
  });

  // RENDER

  const { ouMode, startDate, endDate, ouKeys } = debouncedControls;

  const drivers = useMemo(
    () =>
      users
        .filter(
          ({ memberships }) =>
            intersection(
              memberships.map(({ ou }) => ou),
              ouKeys
            ).length > 0
        )
        .filter(({ isDriver }) => isDriver === true),
    [users, ouKeys]
  );

  useEffect(() => {
    dataGridController.methods.autoSizeColumns();
  }, [ouMode]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, [filteredRows]);

  useEffect(() => {
    const count = drivers.length * daysBetweenDates(startDate, endDate);

    if (myPerformancesOnly || (count >= 0 && count <= 15000)) {
      setIsLoading(true);
      fetchReportDataOnDemand({
        fetchAction: myPerformancesOnly ? fetchMyPerformances : fetchPerformances,
        report,
        isOptimized: true,
        limits,
        ous,
        ...(myPerformancesOnly && { args: { myPerformancesOnly, driverKey: user.key } }),
      });
    }
    if (count > 15000) {
      setIsOpen(true);
    }
  }, [drivers, ouMode, startDate, endDate]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <ProgressOverlay isOpen={isLoading} />
      <div className="PerformancesSummaryReport">
        <ReportFragment.Header
          reportType={reportType}
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
        />
        <QueryPanel
          queryFor="shift"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2018-01-01"
          disableFuture
          onlySelectedOrganization={myPerformancesOnly}
        />
        {!myPerformancesOnly && (
          <SectionPanel
            name="filtersPanel"
            title="Performance Filters"
            renderSummaries={() => <ControlGroupSummary group="filters" />}
            summaryEnd={<SectionPanelMenu group="filters" />}
          >
            <FiltersPanel rows={filteredRows.query} group="filters" filterGroup="filters" />
          </SectionPanel>
        )}
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
        <PrintLayout mode={reportType}>
          <ReportFragment.Header />
          <ControlsTablePrint />
          <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
        </PrintLayout>
        <DetailReportPrintingLinks
          getDetailReportPath={(data) =>
            data.incidents.length > 0 &&
            performanceDetailReportRoute.makeTargetPath({
              date: getTimelessDate(data.startedAt),
              driverKey: data.driver,
              orgKey: data.ouKey,
              shiftId: data.id,
              reportViewId: 'default',
            })
          }
        />
      </div>
      <BasicDialog
        title="Maximum number of records reached"
        maxWidth="lg"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div>
          <p>
            {Math.ceil(drivers.length * daysBetweenDates(startDate, endDate))} records were
            requested of a maximum of 15000 records.
          </p>
          <p>Please refine your query parameters.</p>
        </div>
      </BasicDialog>
    </ReportProvider>
  );
};

PerformancesSummaryReport.propTypes = {
  fetchPerformances: PropTypes.func.isRequired,
  fetchMyPerformances: PropTypes.func.isRequired,
  performances: customPropTypes.performances.isRequired,
  reportViewId: PropTypes.string.isRequired,
  performanceDetailReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  myPerformancesOnly: PropTypes.bool,
  limits: PropTypes.object,
  user: customPropTypes.user.isRequired,
  users: customPropTypes.users.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  ous: customPropTypes.organizations,
};
