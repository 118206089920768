import React from 'react';

import { reject } from 'lodash';
import { FEATURES } from '../../../features';
import { configureRoute } from '../../../config/configureRoute';
import { base64tuple, hyphenDelimitedTuple } from './encoding';
import { BasicRouteFrame, FullOverlayRouteFrame, PopupDialogRouteFrame } from './RouteFrame';
import { reportGroups } from '../../../helpers/navigation/constants';

import { TripsSummaryReport } from '../../reports/CycleTime/TripsSummaryReport';
import { CyclesSummaryReport } from '../../reports/CycleTime/CyclesSummaryReport';
import { TripRouteReport } from '../../reports/CycleTime/TripRouteReport';
import { ManageReports } from '../../reports/ManageReports';
import { CycleDetailReport } from '../../reports/CycleTime/CycleDetailReport';
import { ExceptionsSummaryReport } from '../../reports/Exceptions/ExceptionsSummaryReport';
import { ExceptionDetailReport } from '../../reports/Exceptions/ExceptionDetailReport';
import { ShiftsSummaryReport } from '../../reports/DriverActivities/ShiftsSummaryReport';
import { ShiftDetailReport } from '../../reports/DriverActivities/ShiftDetailReport';
import { SupportInvestigationReport } from '../../reports/SupportInvestigationReport';
import { PerformancesSummaryReport } from '../../reports/DriverActivities/PerformancesSummaryReport';
import { PerformanceDetailReport } from '../../reports/DriverActivities/PerformanceDetailReport';
import { GeozonesSummaryReport } from '../../reports/Geozones/GeozonesSummaryReport';
import { GeozoneActivitiesReport } from '../../reports/Geozones/GeozoneActivitiesReport';
import { DriverLogsSummaryReport } from '../../reports/DriverLogs/DriverLogsSummaryReport';
import { DriverLogsDetailReport } from '../../reports/DriverLogs/DriverLogDetailReport';
import { FuelConsumptionsSummaryReport } from '../../reports/FuelTracking/FuelConsumptionsSummaryReport';
import { FuelConsumptionLocationReport } from '../../reports/FuelTracking/FuelConsumptionLocationReport';
import {
  FuelTaxSummaryReport,
  FuelTaxDetailReport,
  FuelTaxMapReport,
} from '../../reports/FuelTracking/FuelTax';
import { FuelTaxMileageSummaryReport } from '../../reports/FuelTracking/FuelTaxMileageSummaryReport';
import { FuelTaxMileageLocationReport } from '../../reports/FuelTracking/FuelTaxMileageLocationReport';
import { FuellingsSummaryReport } from '../../reports/FuelTracking/FuellingsSummaryReport';
import { Navigation } from '../Navigation';
import { SpeedViolationsSummaryReport } from '../../reports/SpeedTracking/SpeedViolationsSummaryReport';
import { SpeedViolationLocationReport } from '../../reports/SpeedTracking/SpeedViolationLocationReport';
import { ReportSchedulesAdmin } from '../../reports/ReportSchedulesAdmin';
import { InspectionDetailReport } from '../../reports/Inspections/InspectionDetailReport';
import { InspectionsSummaryReport } from '../../reports/Inspections/InspectionsSummaryReport';
import { InspectionLocationReport } from '../../reports/Inspections/InspectionLocationReport';
import { UnidentifiedLogsSummaryReport } from '../../reports/DriverLogs/UnidentifiedLogsSummaryReport';
import { UnidentifiedLogDetailReport } from '../../reports/DriverLogs/UnidentifiedLogDetailReport';
import { UnidentifiedEldLogDetailReport } from '../../reports/DriverLogs/UnidentifiedEldLogDetailReport';

import {
  ADMIN_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  DRIVER_ROLE_KEY,
  MANAGER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
} from '../../../data/system/constants';

const tripsSummaryReport = configureRoute({
  name: 'Trip Summary',
  group: reportGroups.cycleTime,
  order: 2,
  path: '/tripSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => <TripsSummaryReport {...props} tripRouteReportRoute={tripRouteReport} />,
});

const cyclesSummaryReport = configureRoute({
  name: 'Cycle Summary',
  group: reportGroups.cycleTime,
  order: 1,
  path: '/cycleSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => <CyclesSummaryReport {...props} cycleDetailReportRoute={cycleDetailReport} />,
});

const tripRouteReport = configureRoute({
  path: '/tripMap/:tripId',
  RouteFrame: FullOverlayRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => <TripRouteReport {...props} />,
});

const cycleDetailReport = configureRoute({
  path: '/cycleDetail/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  isAllowedToSchedule: true,
  render: (props) => <CycleDetailReport {...props} tripRouteReportRoute={tripRouteReport} />,
});

const shiftsSummaryReport = configureRoute({
  name: 'Shift Summary',
  group: reportGroups.driverActivities,
  order: 2,
  path: '/driverShiftSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => <ShiftsSummaryReport {...props} shiftDetailReportRoute={shiftDetailReport} />,
});

const shiftDetailReport = configureRoute({
  path: '/driverShiftDetail/:shiftId/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY, DRIVER_ROLE_KEY],
  render: (props) => <ShiftDetailReport {...props} />,
});

const myShiftsSummaryReport = configureRoute({
  name: 'My Shift Summary',
  group: reportGroups.driverActivities,
  driverOnly: true,
  path: '/myShiftSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => (
    <ShiftsSummaryReport {...props} shiftDetailReportRoute={shiftDetailReport} myShiftsOnly />
  ),
});

const manageReports = configureRoute({
  path: '/manageReports/:reportType',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => <ManageReports {...props} />,
});

const exceptionSummaryReport = configureRoute({
  name: 'Exception Summary',
  group: reportGroups.exceptions,
  path: '/exceptionSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => (
    <ExceptionsSummaryReport {...props} exceptionDetailReportRoute={exceptionDetailReport} />
  ),
});

const exceptionDetailReport = configureRoute({
  path: '/exceptionDetail/:exception/:reportViewId',
  encoding: { exception: base64tuple(['exceptionType', 'tripId', 'exceptionId', 'description']) },
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => <ExceptionDetailReport {...props} />,
});

const supportInvestigationReport = configureRoute({
  path: '/supportInvestigation',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <SupportInvestigationReport {...props} closeRoute={supportInvestigationReport.close} />
  ),
});

const performancesSummaryReport = configureRoute({
  name: 'Performance Summary',
  group: reportGroups.driverActivities,
  order: 1,
  path: '/performanceSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => (
    <PerformancesSummaryReport {...props} performanceDetailReportRoute={performanceDetailReport} />
  ),
});

const myPerformancesSummaryReport = configureRoute({
  name: 'My Performance Summary',
  group: reportGroups.driverActivities,
  path: '/myPerformances/:reportViewId',
  driverOnly: true,
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => (
    <PerformancesSummaryReport
      {...props}
      performanceDetailReportRoute={performanceDetailReport}
      myPerformancesOnly
    />
  ),
});

const performanceDetailReport = configureRoute({
  path: '/performanceDetails/:detail/:reportViewId',
  encoding: { detail: base64tuple(['driverKey', 'orgKey', 'shiftId', 'date']) },
  permissions: [MANAGER_ROLE_KEY, DRIVER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => <PerformanceDetailReport {...props} />,
});

const geozonesSummaryReport = configureRoute({
  name: 'Geozone Summary',
  group: reportGroups.geozones,
  path: '/geozonesSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => (
    <GeozonesSummaryReport {...props} geozoneActivitiesReportRoute={geozoneActivitiesReport} />
  ),
});

const geozoneActivitiesReport = configureRoute({
  path: '/geozoneActivities/:activity/:reportViewId',
  encoding: {
    activity: hyphenDelimitedTuple(['geozoneKey', 'orgKey', 'startDate', 'endDate'], {
      compactDates: true,
    }),
  },
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => <GeozoneActivitiesReport {...props} />,
});

const inspectionLocationReport = configureRoute({
  path: '/inspectionMap/:inspectionId',
  RouteFrame: PopupDialogRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY, MECHANIC_ROLE_KEY],
  render: (props) => (
    <InspectionLocationReport {...props} closeRoute={inspectionLocationReport.close} />
  ),
});

const inspectionsSummaryReport = configureRoute({
  name: 'Inspections Summary',
  group: reportGroups.inspections,
  path: '/inspectionsSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY, MECHANIC_ROLE_KEY],
  render: (props) => (
    <InspectionsSummaryReport
      {...props}
      openInspectionLocationReportRoute={inspectionLocationReport.open}
      inspectionDetailReportRoute={inspectionDetailReport}
      closeRoute={inspectionsSummaryReport.close}
    />
  ),
});

const myInspectionsSummaryReport = configureRoute({
  name: 'My Inspections Summary',
  group: reportGroups.inspections,
  path: '/myInspections/:reportViewId',
  driverOnly: true,
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => (
    <InspectionsSummaryReport
      {...props}
      openInspectionLocationReportRoute={inspectionLocationReport.open}
      inspectionDetailReportRoute={inspectionDetailReport}
      closeRoute={inspectionsSummaryReport.close}
      myInspectionsOnly
    />
  ),
});

const inspectionDetailReport = configureRoute({
  path: '/inspectionDetail/:inspectionId/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY, MECHANIC_ROLE_KEY, DRIVER_ROLE_KEY],
  render: (props) => <InspectionDetailReport {...props} />,
});

const driverLogsSummaryReport = configureRoute({
  name: 'Hours of Service Summary',
  group: reportGroups.driverLogs,
  path: '/driverLogSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => (
    <DriverLogsSummaryReport driverLogDetailReportRoute={driverLogDetailReport} {...props} />
  ),
});

const myDriverLogsSummaryReport = configureRoute({
  name: 'My Daily Logs',
  group: reportGroups.driverLogs,
  driverOnly: true,
  path: '/myDailyLogs/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => (
    <DriverLogsSummaryReport
      {...props}
      driverLogDetailReportRoute={driverLogDetailReport}
      myLogsOnly
    />
  ),
});

const driverLogDetailReport = configureRoute({
  path: '/driverLogs/:driverLog/:reportViewId',
  encoding: {
    driverLog: hyphenDelimitedTuple(['driverKey', 'date'], { compactDates: true }),
  },
  permissions: [MANAGER_ROLE_KEY, DRIVER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => <DriverLogsDetailReport {...props} />,
});

const unidentifiedLogsSummaryReport = configureRoute({
  name: 'Unidentified Driving Summary',
  group: reportGroups.driverLogs,
  path: '/unidentifiedLogSummary/:reportViewId',
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <UnidentifiedLogsSummaryReport
      {...props}
      unidentifiedLogDetailReportRoute={unidentifiedLogDetailReport}
    />
  ),
});

const unidentifiedLogDetailReport = configureRoute({
  path: '/unidentifiedLog/:unidentifiedLog/:reportViewId',
  encoding: {
    unidentifiedLog: hyphenDelimitedTuple(['vehicleKey', 'date'], { compactDates: true }),
  },
  RouteFrame: FullOverlayRouteFrame,
  render: (props) => <UnidentifiedLogDetailReport {...props} />,
});

const unidentifiedEldLogDetailReport = configureRoute({
  path: '/unidentifiedEldLogs/:driverLog/:reportViewId',
  encoding: {
    driverLog: hyphenDelimitedTuple(['vehicleKey', 'date'], { compactDates: true }),
  },
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => <UnidentifiedEldLogDetailReport {...props} />,
});

const fuelConsumptionsSummaryReport = configureRoute({
  name: 'Consumption Summary',
  group: reportGroups.fuelTracking,
  path: '/fuelConsumptionSummary/:reportViewId',
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <FuelConsumptionsSummaryReport
      {...props}
      openFuelConsumptionLocationReportRoute={fuelConsumptionLocationReport.open}
    />
  ),
});

const fuelConsumptionLocationReport = configureRoute({
  path: '/fuelConsumptionMap/:fuelConsumptionId/:ou',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <FuelConsumptionLocationReport {...props} closeRoute={fuelConsumptionLocationReport.close} />
  ),
});

const fuellingsSummaryReport = configureRoute({
  name: 'Fuelling Summary',
  group: reportGroups.fuelTracking,
  path: '/fuellingSummary/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => <FuellingsSummaryReport {...props} />,
});

const reportNavigation = configureRoute({
  path: '/reports',
  RouteFrame: BasicRouteFrame,
  render: (props) => <Navigation menuCategory="reports" {...props} />,
  permissions: [
    DRIVER_ROLE_KEY,
    MANAGER_ROLE_KEY,
    ADMIN_ROLE_KEY,
    MECHANIC_ROLE_KEY,
    DISPATCHER_ROLE_KEY,
  ],
});

const speedViolationsSummaryReport = configureRoute({
  name: 'Speed Violations',
  path: '/speedViolations/:reportViewId',
  RouteFrame: BasicRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  group: reportGroups.speedTracking,
  render: (props) => (
    <SpeedViolationsSummaryReport
      speedViolationLocationReportRoute={speedViolationLocationReport}
      {...props}
    />
  ),
});

const speedViolationLocationReport = configureRoute({
  path: '/speedViolationMap/:speedViolationKey',
  RouteFrame: PopupDialogRouteFrame,
  permissions: [MANAGER_ROLE_KEY],
  render: (props) => (
    <SpeedViolationLocationReport {...props} closeRoute={speedViolationLocationReport.close} />
  ),
});

const reportSchedulesAdmin = configureRoute({
  path: '/reportScheduling',
  RouteFrame: BasicRouteFrame,
  group: reportGroups.scheduling,
  name: 'Schedule',
  permissions: [MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY, MECHANIC_ROLE_KEY, DRIVER_ROLE_KEY],
  render: (props) => <ReportSchedulesAdmin {...props} closeRoute={reportSchedulesAdmin.close} />,
});

const fuelTaxSummaryReport = configureRoute({
  name: 'Fuel Tax Summary',
  group: reportGroups.fuelTracking,
  order: 1,
  path: '/fuelTaxes/:reportViewId',
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <FuelTaxSummaryReport openFuelTaxDetailReportRoute={fuelTaxDetailReport} {...props} />
  ),
  hide: FEATURES.reports.hideTaxReport,
});

const fuelTaxDetailReport = configureRoute({
  path: '/fuelTax/:fuelTaxDetail/:reportViewId',
  encoding: {
    fuelTaxDetail: base64tuple(['fuelTaxId', 'quarter', 'vehicleKey', 'jurisdictionState']),
  },
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  isAllowedToSchedule: true,
  render: (props) => (
    <FuelTaxDetailReport
      openFuelTaxMapReportRoute={fuelTaxMapReport}
      shiftDetailReportRoute={shiftDetailReport}
      {...props}
    />
  ),
  hide: FEATURES.reports.hideTaxReport,
});

const fuelTaxMapReport = configureRoute({
  path: '/fuelTaxMap/:fuelTaxMapDetail/:reportViewId',
  encoding: {
    fuelTaxMapDetail: base64tuple(['fuelTaxId', 'fuelTaxDetailId']),
  },
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => <FuelTaxMapReport {...props} />,
  hide: FEATURES.reports.hideTaxReport,
});

const fuelTaxMileageSummaryReport = configureRoute({
  path: '/fuelTaxMileage/:reportViewId',
  permissions: [MANAGER_ROLE_KEY],
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <FuelTaxMileageSummaryReport
      openFuelTaxMileageLocationReportRoute={fuelTaxMileageLocationReport.open}
      {...props}
    />
  ),
  hide: FEATURES.reports.hideTaxMileageReport,
});

const fuelTaxMileageLocationReport = configureRoute({
  path: '/fuelTaxMileageMap/:fuelTaxMileageKey',
  RouteFrame: PopupDialogRouteFrame,
  render: (props) => (
    <FuelTaxMileageLocationReport {...props} closeRoute={fuelTaxMileageLocationReport.close} />
  ),
  hide: FEATURES.reports.hideTaxMileageReport,
});

export const reportRoutes = reject(
  [
    {
      match: (path) => path.match(/^\/tripSummary$/),
      redirectTo: '/tripSummary/default',
    },
    {
      match: (path) => path.match(/^\/cycleSummary$/),
      redirectTo: '/cycleSummary/default',
    },
    {
      match: (path) => path.match(/^\/exceptionSummary$/),
      redirectTo: '/exceptionSummary/default',
    },
    {
      match: (path) => path.match(/^\/driverActivities$/),
      redirectTo: '/driverShiftSummary/default',
    },
    {
      match: (path) => path.match(/^\/myDriverActivities$/),
      redirectTo: '/myShiftSummary/default',
    },
    {
      match: (path) => path.match(/^\/driverPerformance$/),
      redirectTo: '/performanceSummary/default',
    },
    {
      match: (path) => path.match(/^\/geozones$/),
      redirectTo: '/geozonesSummary/default',
    },
    {
      match: (path) => path.match(/^\/(hosSummary|hosViolations|summaries)$/),
      redirectTo: '/driverLogSummary/default',
    },
    {
      match: (path) => path.match(/^\/driverLogs\/\d+\/view/),
      redirectTo: '/driverLogSummary/default',
    },
    {
      match: (path) => path.match(/^\/fuelConsumptionSummary$/),
      redirectTo: '/fuelConsumptionSummary/default',
    },
    {
      match: (path) => path.match(/^\/fuellingSummary$/),
      redirectTo: '/fuellingSummary/default',
    },
    {
      match: (path) => path.match(/^\/assetInspectionHistory$/),
      redirectTo: '/inspectionsSummary/default',
    },
    {
      match: (path) => path.match(/^\/inspectionsSummary$/),
      redirectTo: '/inspectionsSummary/default',
    },
    {
      match: (path) => path.match(/^\/inspectorInspectionHistory$/),
      redirectTo: '/inspectionsSummary/default',
    },
    {
      match: (path) => path.match(/^\/myInspections$/),
      redirectTo: '/myInspections/default',
    },
    {
      match: (path) => path.match(/^\/myDailyLogs$/),
      redirectTo: '/myDailyLogs/default',
    },
    {
      match: (path) => path.match(/^\/myPerformances$/),
      redirectTo: '/myPerformances/default',
    },
    {
      match: (path) => path.match(/^\/speedViolations$/),
      redirectTo: '/speedViolations/default',
    },
    {
      match: (path) => path.match(/^\/fuelTaxMileageReport\/summary$/),
      redirectTo: '/fuelTaxMileage/default',
    },
    {
      match: (path) => path.match(/^\/unidentifiedLogSummary$/),
      redirectTo: '/unidentifiedLogSummary/default',
    },
    reportNavigation,
    tripsSummaryReport,
    shiftsSummaryReport,
    myShiftsSummaryReport,
    fuelTaxSummaryReport,
    fuelTaxDetailReport,
    fuelTaxMapReport,
    shiftDetailReport,
    cyclesSummaryReport,
    cycleDetailReport,
    tripRouteReport,
    exceptionSummaryReport,
    exceptionDetailReport,
    manageReports,
    supportInvestigationReport,
    performancesSummaryReport,
    myPerformancesSummaryReport,
    performanceDetailReport,
    geozonesSummaryReport,
    geozoneActivitiesReport,
    driverLogsSummaryReport,
    myDriverLogsSummaryReport,
    driverLogDetailReport,
    fuelConsumptionsSummaryReport,
    fuelConsumptionLocationReport,
    fuellingsSummaryReport,
    reportSchedulesAdmin,
    inspectionsSummaryReport,
    myInspectionsSummaryReport,
    inspectionDetailReport,
    inspectionLocationReport,
    speedViolationsSummaryReport,
    speedViolationLocationReport,
    unidentifiedEldLogDetailReport,
    unidentifiedLogsSummaryReport,
    unidentifiedLogDetailReport,
    fuelTaxMileageSummaryReport,
    fuelTaxMileageLocationReport,
  ],
  ['hide', true]
);
