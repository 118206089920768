import produce from 'immer';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchFuelConsumptions: 'reports/fuelConsumptions/fetchFuelConsumptions',
  fetchFuelConsumption: 'reports/fuelConsumptions/fetchFuelConsumption',
};

const initialState = {
  fuelConsumptions: [],
  fetches: [],
};

export const fuelConsumptionsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchFuelConsumptions,
        ACTION_TYPE.fetchFuelConsumption,
      ])
    ) {
      draft.fuelConsumptions = shallowMergeEntities(state.fuelConsumptions, payload, 'id');
    }

    if (
      actionTypeRequest(type, ACTION_TYPE.fetchFuelConsumptions, ACTION_TYPE.fetchFuelConsumption)
    ) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (
      actionTypeFailure(type, ACTION_TYPE.fetchFuelConsumptions, ACTION_TYPE.fetchFuelConsumption)
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
