import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchReportSchedulesByUserKey: 'admin/reportSchedules/fetchReportSchedulesByUserKey',
  deleteReportSchedule: 'admin/reportSchedules/deleteReportSchedule',
  createReportSchedule: 'admin/reportSchedules/createReportSchedule',
  createReportOnDemand: 'admin/reportSchedules/createReportOnDemand',
};

export const reportSchedulesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchReportSchedulesByUserKey,
        ACTION_TYPE.createReportSchedule,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteReportSchedule)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchReportSchedulesByUserKey])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchReportSchedulesByUserKey])) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    return draft;
  });
