import { isNil } from 'lodash';

const isRequiredDecorator =
  (validatorFn) =>
  (props, propName, componentName, ...restArgs) => {
    const propValue = props[propName];
    if (isNil(propValue)) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${String(
          propValue
        )}\`. `
      );
    }
    return validatorFn(props, propName, componentName, ...restArgs);
  };

export const date = (props, propName, componentName) => {
  if (!/\d{4}-\d{2}-\d{2}/.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`, expected string in format of "YYYY-MM-DD".`
    );
  }
  return undefined;
};
date.isRequired = isRequiredDecorator(date);
