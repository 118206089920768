import produce from 'immer';

import {
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
  actionTypeRequest,
  actionTypeFailure,
  deepMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchVehicles: 'administration/resources/vehicles/fetchVehicles',
  fetchVehicle: 'administration/resources/vehicles/fetchVehicle',
  updateVehicle: 'administration/resources/vehicles/updateVehicle',
  createVehicle: 'administration/resources/vehicles/createVehicle',
  deleteVehicle: 'administration/resources/vehicles/deleteVehicle',
  fetchVehicleRemovePairing: 'administration/resources/vehicles/fetchVehicleRemovePairing',
};

export const vehiclesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVehicles)) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchVehicle,
        ACTION_TYPE.createVehicle,
        ACTION_TYPE.updateVehicle,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteVehicle)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeRequest(type, [
        ACTION_TYPE.fetchVehicles,
        ACTION_TYPE.fetchVehicle,
        ACTION_TYPE.fetchVehicleRemovePairing,
      ])
    ) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (
      actionTypeFailure(type, [
        ACTION_TYPE.fetchVehicle,
        ACTION_TYPE.fetchVehicle,
        ACTION_TYPE.fetchVehicleRemovePairing,
      ])
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
