import { connect } from 'react-redux';

import { LocationSummary } from './summary';

import { location } from '../../../../../data/administration/resources/locations';
import { locationType } from '../../../../../data/administration/resources/locationTypes';

import {
  selectIsUserSuperAdmin,
  selectIsUserAdmin,
  selectSystemTenant,
} from '../../../../../data/system';

export const LocationSummaryContainer = connect(
  (state) => ({
    tenant: selectSystemTenant(state),
    locations: location.selectors.selectLocations(state),
    isUserSuperAdmin: selectIsUserSuperAdmin(state),
    isUserAdmin: selectIsUserAdmin(state),
  }),
  {
    fetchLocations: location.actions.fetchLocationsOptimized,
    fetchLocationTypes: locationType.actions.fetchLocationTypesOptimized,
  }
)(LocationSummary);
