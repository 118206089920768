import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Open',
    children: [
      columnDef.iconColumn({
        colId: 'openInspectionDetailReport',
        headerName: 'Open Detail',
        cellRenderer: 'OpenVehicleDetail',
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
    ],
  },
  {
    headerName: 'Inspection',
    children: [
      columnDef.textColumn({
        field: 'inspectorName',
        headerName: 'Inspector',
        cellRenderer: 'Inspector',
      }),
      columnDef.textColumn({
        field: 'managedByName',
        headerName: 'Organization',
      }),
      columnDef.textColumn({
        field: 'inspectionType',
        headerName: 'Type',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'Date',
        sort: 'asc',
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
        enableRowGroup: false,
        overrideFormat: 'h:mm:ss',
      }),
      columnDef.iconColumn({
        colId: 'routeMap',
        cellRenderer: 'InspectionMap',
        headerName: 'Inspection Map',
        headerComponent: 'InspectionMapHeader',
      }),
      columnDef.textColumn({
        field: 'locationName',
        headerName: 'Location',
      }),
      columnDef.textColumn({
        field: 'alternativeLocationName',
        headerName: 'Geozone',
      }),
      columnDef.textColumn({
        field: 'hasNotes',
        headerName: 'Has Notes',
        valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
      }),
    ],
  },
  {
    headerName: 'Vehicle',
    children: [
      columnDef.textColumn({
        field: 'assetName',
        headerName: 'ID',
        cellRenderer: 'Vehicle',
      }),
      columnDef.textColumn({
        field: 'assetTypeName',
        headerName: 'Type',
        cellRenderer: 'VehicleType',
      }),
      columnDef.distanceColumn({
        field: 'odometer',
        headerName: 'Odometer',
        aggFunc: null,
      }),
      columnDef.durationColumn({
        field: 'engineHours',
        headerName: 'Engine Hours',
      }),
    ],
  },
  {
    headerName: 'Defects',
    children: [
      columnDef.numberColumn({
        field: 'minorDefects',
        headerName: 'Minor',
      }),
      columnDef.numberColumn({
        field: 'majorDefects',
        headerName: 'Major',
      }),
    ],
  },
  {
    headerName: 'Repairs',
    children: [
      columnDef.numberColumn({
        field: 'minorRepairs',
        headerName: 'Minor',
      }),
      columnDef.numberColumn({
        field: 'majorRepairs',
        headerName: 'Major',
      }),
      columnDef.enabledDisabledColumn({
        field: 'safeToDrive',
        headerName: 'Safe to Drive?',
        cellRenderer: 'ToggleChip',
        trueLabelText: 'Yes',
        falseLabelText: 'No',
      }),
    ],
  },
  {
    headerName: 'DTC',
    children: [
      columnDef.enabledDisabledColumn({
        field: 'hasDTCs',
        cellRenderer: 'DTCStatus',
        headerName: 'Code(s)',
        trueLabelText: 'Yes',
        falseLabelText: 'No',
        nilLabelText: '-',
        aggFunc: null,
      }),
    ],
  }
];
