import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import {
  BasicDialog,
  Form,
  DateInput,
  TextInput,
  SelectInput,
  ToggleInput,
} from 'stti-react-common';

import {
  startDataTransfer as startDataTransferAction,
  startGeneralPurposeTransfer as startGeneralPurposeTransferAction,
} from '../../../../data/reports';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { countOf, countUniqueOfField } from '../../../../helpers/reports/aggregation';

import './DriverLogDataTransferDialog.scss';

const { useFormController, Control } = Form;

const orientationOptions = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];

export const DriverLogDataTransferDialog = connect(undefined, {
  startDataTransfer: startDataTransferAction,
  startGeneralPurposeTransfer: startGeneralPurposeTransferAction,
})(({ isOpen, onClose, logs, mode = {}, startDataTransfer, startGeneralPurposeTransfer, user }) => {
  const form = useFormController();

  const { controls, hasErrors } = form;

  const isSingleRow = logs.length === 1; // is always the case in DLDR, and may be the case in DLSR when rows filtered to 1

  const mechanismOptions = [
    { label: 'Online (Recommended)', value: 'SOAP' },
    { label: 'Email  (Use only when online method has downtime)', value: 'EMAIL' },
  ];

  const canSelectMechanism = mode.agency === 'FMCSA';

  const handleNonEldSubmit = () => {
    const { toEmails, orientation, records } = controls;

    const canadianUrls = records.includes('Canada')
      ? logs.map(
          (log) => `/#/canadianEldLogs/${log.driver.key}-${log.logDate.replaceAll('-', '')}/default`
        )
      : [];

    const usUrls = records.includes('US')
      ? logs.map(
          (log) => `/#/usEldLogs/${log.driver.key}-${log.logDate.replaceAll('-', '')}/default`
        )
      : [];

    const urls = canadianUrls.concat(usUrls);
    const payload = {
      title: 'General Purpose Data Transfer',
      orientation,
      urls,
      toEmails,
      userKey: user.key,
    };

    startGeneralPurposeTransfer(payload);
  };

  const handleEldSubmit = () => {
    const { comment, agency, email, mechanism } = controls;

    let processedLogs = logs;

    if (isSingleRow) {
      const { logDate, timeZone } = logs[0];
      const { startDate, endDate } = controls;
      if (startDate !== logDate || endDate !== logDate) {
        processedLogs = [
          {
            ...logs[0],
            startedAt: moment.tz(startDate, timeZone).startOf('day').toISOString(),
            endedAt: moment.tz(endDate, timeZone).endOf('day').toISOString(),
          },
        ];
      }
    }
    startDataTransfer({
      logs: processedLogs,
      comment,
      jurisdiction: agency === 'FMCSA' ? 'Us' : 'Cad',
      email,
      mechanism: agency === 'FMCSA' ? mechanism : 'EMAIL',
    });
  };

  const disableSubmit = useMemo(() => {
    if (countOf(logs) > 365 || countUniqueOfField(logs, 'driver.key') > 10) {
      return true;
    }
    return false;
  }, [logs]);

  if (!isOpen) return <div />;

  return (
    <BasicDialog
      className={`Report__DriverLogDataTransferDialog ${
        mode.agency
          ? 'Report__DriverLogDataTransferDialog--agency'
          : 'Report__DriverLogDataTransferDialog--general'
      }`}
      isOpen={isOpen}
      onClose={onClose}
      title={
        isSingleRow ? `Data Transfer for ${logs[0].driver.name}` : `Data Transfer (Visible Rows)`
      }
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Submit',
          disabled: hasErrors || disableSubmit,
          onClick: () => {
            !mode.agency ? handleNonEldSubmit() : handleEldSubmit();
            onClose();
          },
        },
      ]}
    >
      {!isSingleRow && (
        <h3>
          {countOf(logs)} logs, {countUniqueOfField(logs, 'driver.key')} drivers
        </h3>
      )}
      <Form form={form}>
        {isSingleRow && (
          <>
            <Control
              Component={DateInput}
              name="startDate"
              label="Start Date"
              defaultValue={logs[0].logDate}
              error={({ value }) => {
                if (isEmpty(value)) {
                  return true;
                }

                if (controls.endDate && moment(controls.endDate).isBefore(moment(value))) {
                  return 'Start Date cannot be after End Date';
                }

                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onSet={({ draft, value }) => {
                if (!draft.enableDateRange) {
                  draft.endDate = moment(value).endOf('date').format('YYYY-MM-DD');
                }
              }}
              autoGridArea
            />
            <Control
              name="enableDateRange"
              Component={ToggleInput}
              label="Transfer for Multiple Days "
              autoFocus
              onSet={({ draft, value }) => {
                if (!value) {
                  draft.endDate = draft.startDate;
                }
              }}
              autoGridArea
            />
            <Control
              Component={DateInput}
              name="endDate"
              defaultValue={logs[0].logDate}
              label="End Date"
              disabled={!controls.enableDateRange}
              autoGridArea
            />
          </>
        )}
        {mode.agency === 'CVSE' && (
          <Control
            Component={TextInput}
            name="email"
            label="Agent’s Email"
            error={({ value }) => {
              const validEmailPattern = /\S+@\S+\.\S+/;

              if (!value || !value.trim()) {
                return true;
              }

              if (!validEmailPattern.test(value.toLowerCase())) {
                return 'Please enter a valid email';
              }
              return false;
            }}
            autoGridArea
          />
        )}
        {!mode.agency && (
          <Control
            name="toEmails"
            Component={SelectInput}
            multiple
            multiline
            addNew
            label="Deliver to"
            error={({ value }) => {
              const validEmailPattern = /\S+@\S+\.\S+/;

              if (!value || !value.length === 0) {
                return true;
              }

              if (
                value.filter((email) => !validEmailPattern.test(email.toLowerCase())).length > 0
              ) {
                return 'Invalid email entered';
              }
              return false;
            }}
            defaultValue={[user.email]}
            options={[user.email]}
            autoGridArea
          />
        )}
        {!mode.agency && (
          <Control
            name="records"
            Component={SelectInput}
            multiple
            label="Records to transfer"
            defaultValue={['US']}
            error={({ value }) => value && value.length === 0}
            options={[
              { label: 'US', value: 'US' },
              { label: 'Canada', value: 'Canada' },
            ]}
            autoGridArea
          />
        )}
        {!mode.agency && (
          <Control
            name="orientation"
            Component={SelectInput}
            label="PDF orientation"
            options={orientationOptions}
            defaultValue="landscape"
            autoGridArea
          />
        )}
        <Control
          Component={TextInput}
          name="comment"
          defaultValue=""
          label="Comment"
          error={({ value }) =>
            value && value.length > 60 && 'Comment cannot be more than 60 characters.'
          }
          autoGridArea
        />
        {mode.agency && (
          <Control
            Component={SelectInput}
            name="agency"
            disabled
            defaultValue={mode.agency}
            label="Agency"
            options={[
              { label: 'FMCSA (US)', value: 'FMCSA' },
              { label: 'CVSE (CAN)', value: 'CVSE' },
            ]}
            autoGridArea
          />
        )}
        {canSelectMechanism && (
          <Control
            Component={SelectInput}
            name="mechanism"
            defaultValue="SOAP"
            label="Submit Method"
            options={mechanismOptions}
            autoGridArea
          />
        )}
      </Form>
      {disableSubmit && (
        <div className="disabledMessage">
          You only can send up to 365 driver logs or data for 10 different drivers
        </div>
      )}
    </BasicDialog>
  );
});

DriverLogDataTransferDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logs: customPropTypes.driverLogs.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  mode: PropTypes.shape({
    agency: PropTypes.oneOf(['FMCSA', 'CVSE']),
  }),
};
