import PropTypes from 'prop-types';
import React from 'react';

import { useFormats } from '../../Formats';

import './AdminTenantDefaultValuesPanel.scss';

export const AdminTenantDefaultValuesPanel = ({ name, values }) => {
  const { formatUnit } = useFormats();
  const { defaultSpeedLimit, speedLimit, defaultGracePeriod, gracePeriod } = values || {};
  return (
    <div className="AdminTenantDefaultValuesPanel">
      <h3>Selected Ou: {name}</h3>
      <div className="AdminTenantDefaultValuesPanel__Group">
        <div className="AdminTenantDefaultValuesPanel__Item">
          <h4>Default Speed Limit</h4>
          <p>{formatUnit('speed', defaultSpeedLimit)}</p>
        </div>
        <div className="AdminTenantDefaultValuesPanel__Item">
          <h4>Custom Speed Limit</h4>
          <p style={{ color: speedLimit > defaultSpeedLimit ? 'red' : 'green' }}>
            {speedLimit !== defaultSpeedLimit ? formatUnit('speed', speedLimit) : '-'}
          </p>
        </div>
        <div className="AdminTenantDefaultValuesPanel__Item">
          <h4>Default Grace Period</h4>
          <p>{formatUnit('duration', defaultGracePeriod, 'h:mm:ss')}</p>
        </div>
        <div className="AdminTenantDefaultValuesPanel__Item">
          <h4>Custom Grace Period</h4>
          <p style={{ color: gracePeriod > defaultGracePeriod ? 'red' : 'green' }}>
            {gracePeriod !== defaultGracePeriod
              ? formatUnit('duration', gracePeriod, 'h:mm:ss')
              : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

AdminTenantDefaultValuesPanel.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.shape({
    defaultSpeedLimit: PropTypes.number,
    speedLimit: PropTypes.number,
    defaultGracePeriod: PropTypes.number,
    gracePeriod: PropTypes.number,
  }),
};
