import React from 'react';
import { ScrollBar } from 'stti-react-common';
import PropTypes from 'prop-types';

export const OrderSummary = ({ selectedData }) => (
  <div className="tile">
    <h4>Order Summary </h4>
    <div className="tile__content">
      <div className="tile__content__item">
        {selectedData?.shippingDocuments?.length > 0 ? (
          <ScrollBar style={{ width: '100%', height: '100px', overflowY: 'hidden !important' }}>
            {selectedData?.shippingDocuments?.map((row) => (
              <li>{row}</li>
            ))}
          </ScrollBar>
        ) : (
          <strong>No order information</strong>
        )}
      </div>
    </div>
    <div className="tile__footer">
      <a
        href={`#/reports/driverLogSummary/default/driverLogs/${
          selectedData?.driver?.key
        }-${selectedData?.logDate?.replace(/[^a-zA-Z0-9]/g, '')}/default`}
        target="_blank"
        rel="noreferrer"
      >
        View Details
      </a>
    </div>
  </div>
);

OrderSummary.propTypes = {
  selectedData: PropTypes.any,
};
