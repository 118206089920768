import { connect } from 'react-redux';

import { UserSummary } from './summary';

import { user, role } from '../../../../../data/administration/resources';

export const UserSummaryContainer = connect(
  (state) => ({
    users: user.selectors.selectUsers(state),
  }),
  {
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchRoles: role.actions.fetchRolesOptimized,
    deleteUser: user.actions.deleteUser,
  }
)(UserSummary);
