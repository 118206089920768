import BetterPropTypes from 'better-prop-types';

export const companionApp = BetterPropTypes.shape({
  releaseChannels: BetterPropTypes.array,
  key: BetterPropTypes.string.isOptionalButNotNull,
  name: BetterPropTypes.string.isOptionalButNotNull,
  description: BetterPropTypes.string.isOptionalButNotNull,
  fileType: BetterPropTypes.string,
  available: BetterPropTypes.bool.isOptionalButNotNull,
  version: BetterPropTypes.number,
  tenantKeys: BetterPropTypes.array,
  tenants: BetterPropTypes.array,
});

export const companionApps = BetterPropTypes.arrayOf(companionApp);
