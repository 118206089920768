import qs from 'qs';

export class Location {
  constructor(href = window.location.href) {
    let queryString;
    [
      this._href,
      this.origin,
      this.pathname,
      // eslint-disable-next-line prefer-const
      queryString,
    ] = /^(https?:\/\/.+?)\/#([^?]*)\/?\??(.*)$/.exec(href);

    // strip pathname trailing slash
    this.pathname = this.pathname.replace(/\/$/, '');

    // convert search string to object
    this.query = qs.parse(queryString) || {};
  }

  get href() {
    return this._href;
  }

  toString() {
    const queryString = qs.stringify(this.query, { addQueryPrefix: true });

    return `${this.origin}/#${this.pathname ? this.pathname : '/'}${queryString}`;
  }

  deleteQueryParam(queryParamKey) {
    delete this.query[queryParamKey];
    window.location.replace(this.toString());
  }
}
