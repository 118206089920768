import { filter, last, castArray } from 'lodash';

import { ACTION_TYPE } from './reducer';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../apiActionSupport';

import { selectFleetTrackingShiftDetailFetches } from './selectors';

export const fetchFleetTrackedData = makeRestApiAction({
  service: 'fleetTracking',
  method: 'get',
  getId: () => 'trackedData',
  baseActionType: ACTION_TYPE.fetchFleetTrackedData,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'fleetTracking Data',
});

export const fetchFleetTrackingBookmark = makeRestApiAction({
  service: 'fleetTrackingBookmarks',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFleetTrackingBookmark,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'fleetTracking Bookmark',
});

export const postFleetTrackingBookmark = makeRestApiAction({
  service: 'fleetTrackingBookmarks',
  method: 'post',
  baseActionType: ACTION_TYPE.postFleetTrackingBookmark,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'update fleetTracking Bookmark',
});

export const updateFleetTrackingBookmark = makeRestApiAction({
  service: 'fleetTrackingBookmarks',
  method: 'put',
  getId: ({ key }) => key,
  baseActionType: ACTION_TYPE.updateFleetTrackingBookmark,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'update fleetTracking Bookmark',
});

export const deleteFleetTrackingBookmark = makeRestApiAction({
  service: 'fleetTrackingBookmarks',
  method: 'delete',
  getId: ({ key }) => key,
  transformInput: () => ({}),
  baseActionType: ACTION_TYPE.deleteFleetTrackingBookmark,
  notificationsItemDescriptor: 'fleetTracking Bookmark',
});

export const fetchFleetTrackingShiftDetails = makeRestApiAction({
  service: 'fleetTrackingShiftDetails',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFleetTrackingShiftDetails,
  transformOutput: (responseData) => castArray(last(responseData)).filter(Boolean),
  notificationsItemDescriptor: 'fleetTracking Shift Details',
});

export const fetchFleetTrackingShiftDetailOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectFleetTrackingShiftDetailFetches,
  filterFetches: (fetches, actionParam) => filter(fetches, { requestData: actionParam }),
  fetchAction: fetchFleetTrackingShiftDetails,
  baseActionType: ACTION_TYPE.fetchFleetTrackingShiftDetails,
});
