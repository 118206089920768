import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Name',
    cellRenderer: 'AssetType',
    enableRowGroup: false,
    sort: 'asc',
    minWidth: 130,
  }),
  columnDef.textColumn({
    field: 'typeClass',
    headerName: 'Type Class',
    minWidth: 120,
  }),
  columnDef.enabledDisabledColumn({
    field: 'isSleeperBerth',
    headerName: 'Sleeper berth?',
    cellRenderer: 'ToggleSleeperBerth',
    trueLabelText: 'Equipped',
    falseLabelText: 'Not Equipped',
    minWidth: 120,
  }),
  columnDef.textColumn({
    field: 'ou.name',
    headerName: 'Applies to',
    cellRenderer: 'AppliesTo',
    minWidth: 120,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
