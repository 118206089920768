import { createSelector } from 'reselect';

import { selectSystemActiveOu } from '../../../system';

const getApiKeys = (state) => state.administration.configurations.apiKeys.data;

/**
 * selectApiKeys(state) -> [apiKeys]
 *
 * Selector for all apiKeys, with active OU's timeZone assumed and added as property
 *
 */
export const selectApiKeys = createSelector(
  [getApiKeys, selectSystemActiveOu],
  (apiKeys, activeOu) =>
    apiKeys.map((apiKey) => ({
      ...apiKey,
      timeZone: activeOu.timeZone,
    }))
);

/**
 * selectApiKeysFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on apiKeys
 */
export const selectApiKeysFetchRequests = (state) =>
  state.administration.configurations.apiKeys.fetches;
