import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useReportController,
  ReportProvider,
  FetchReportDetail,
  FetchBreadcrumbs,
} from '../../../commons/ReportsCommon';

import { Feature } from '../../../commons/Feature';
import { TripRoute } from '../../../commons/MapsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { QaDataGrid } from '../../../commons/QaTools';
import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';

import './TripRouteReport.scss';

const requestServices = ['breadcrumbs', 'refinedBreadcrumbs'];

export const TripRouteReport = ({ trip, tripId, route, breadcrumbsByVehicleKey, fetchTrip }) => {
  const report = useReportController();

  const startDate = trip && trip.startedAt;
  const endDate = trip && trip.endedAt;
  const vehicleKey = trip && trip.vehicle.key;
  const origin = trip && trip.origin;
  const firstLoad = trip && trip.firstLoad;
  const lastUnload = trip && trip.lastUnload;

  const [focus, setFocus] = useState();

  useEffect(() => {
    setFocus(trip && [origin, firstLoad, lastUnload]);
  }, [trip, origin, firstLoad, lastUnload]);

  const breadcrumbs = useMemo(
    () => sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startDate, endDate),
    [breadcrumbsByVehicleKey[vehicleKey], startDate, endDate]
  );

  const validFocus = useMemo(
    () => focus && focus.every(({ latitude, longitude }) => latitude && longitude),
    [focus]
  );

  return (
    <ReportProvider value={report}>
      {!trip && <FetchReportDetail action={fetchTrip} args={tripId} />}
      <div className="TripRouteReport">
        <Feature.Header
          title="Trip Map"
          subtitle={trip && trip.vehicle.name}
          onClose={route.close}
          service={requestServices}
        />
        {validFocus && (
          <NaviMap focus={focus}>
            <FetchBreadcrumbs startAt={startDate} endAt={endDate} vehicleKey={vehicleKey} />
            <TripRoute trip={trip} breadcrumbs={breadcrumbs} />
          </NaviMap>
        )}
        <QaDataGrid data={breadcrumbs} onRowClick={setFocus} />
      </div>
    </ReportProvider>
  );
};

TripRouteReport.propTypes = {
  trip: PropTypes.object,
  tripId: PropTypes.string.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchTrip: PropTypes.func.isRequired,
};
