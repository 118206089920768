import { sortBy } from 'lodash';

/**
 * selectMessages(state) -> [messages]
 *
 * Selector for all messages
 */
export const selectMessages = (state) => sortBy(state.messenger.messages, 'date');

/**
 * selectOldestDate(state) -> date
 *
 * Selector for oldest date set in chat component
 */
export const selectOldestDate = (state) => state.messenger.oldestDate;
