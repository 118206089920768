import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, TextInput, ProgressOverlay } from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { useApiError } from '../../../../../helpers/hooks';

export const LocationTypeDetail = ({
  locationType,
  locationTypeId,
  fetchLocationType,
  fetchLocationTypes,
  deleteLocationType,
  createLocationType,
  updateLocationType,
  mode: initialMode = 'view',
  route,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      Promise.all([fetchLocationTypes(), fetchLocationType(locationTypeId)])
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, locationTypeId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...locationType,
    },
  });

  const { controls: formControls, resetControls } = form;
  return (
    <div className="LocationTypeDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Location Type"
          form={form}
          itemTitle={formControls.name || ''}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formControls}
          onClose={route.close}
          createItem={createLocationType}
          updateItem={updateLocationType}
          deleteItem={deleteLocationType}
          resetControls={resetControls}
        >
          <ErrorSummary errorMsg={apiError} />
          <Control
            Component={TextInput}
            name="name"
            label="Name"
            error={({ value }) => !value || !value.trim()}
          />
        </CrudAdminItem>
      )}
    </div>
  );
};

LocationTypeDetail.propTypes = {
  locationType: customPropTypes.locationType,
  locationTypeId: PropTypes.string,
  fetchLocationType: PropTypes.func.isRequired,
  fetchLocationTypes: PropTypes.func.isRequired,
  deleteLocationType: PropTypes.func.isRequired,
  createLocationType: PropTypes.func.isRequired,
  updateLocationType: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
};
