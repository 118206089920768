import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Chip, Button } from 'stti-react-common';

import { SectionPanel, InfoField } from '../../../commons/ReportsCommon';
import { useFormats } from '../../../commons/Formats';

export const AnnotationPanel = ({ exception, canEditAnnotation, onEditAnnotation }) => {
  const { formatUnit } = useFormats();

  const { annotation, timeZone } = exception || {};

  const formattedAnnotationBy = annotation && annotation.user.name;

  const formattedAnnotationAt =
    annotation && formatUnit('date', moment(annotation.updatedAt).tz(timeZone));

  return (
    <SectionPanel
      name="annotationPanel"
      title="Review"
      renderSummaries={() => {
        if (annotation)
          return (
            <Chip label={`Reviewed by ${formattedAnnotationBy} at ${formattedAnnotationAt}`} />
          );
        if (!annotation && canEditAnnotation) return <Chip label="Unreviewed" variant="outlined" />;
        return null;
      }}
    >
      <InfoField
        name="by"
        label="Reviewed By"
        value={formattedAnnotationBy}
        controlsTableDef={{
          name: 'annotation',
          label: 'Review',
          value:
            annotation &&
            `Reviewed by ${formattedAnnotationBy} at ${formattedAnnotationAt}\n${annotation.comment}`,
        }}
      />
      <InfoField
        name="at"
        label="Reviewed At"
        value={formattedAnnotationAt}
        controlsTableDef={false}
      />
      <InfoField
        name="comment"
        label="Reviewer Comment"
        value={annotation && annotation.comment}
        controlsTableDef={false}
      />
      <div className="ExceptionDetailReport__annotationButtons">
        {annotation ? (
          <Button
            icon="edit"
            label="Edit Review"
            onClick={onEditAnnotation}
            disabled={!canEditAnnotation}
          />
        ) : (
          <Button
            icon="add"
            label="Add Review"
            onClick={onEditAnnotation}
            disabled={!canEditAnnotation}
          />
        )}
      </div>
    </SectionPanel>
  );
};

AnnotationPanel.propTypes = {
  exception: PropTypes.shape({
    timeZone: PropTypes.string.isRequired,
    annotation: PropTypes.shape({
      updatedAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      comment: PropTypes.string.isRequired,
    }),
  }),
  canEditAnnotation: PropTypes.bool,
  onEditAnnotation: PropTypes.func.isRequired,
};
