import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { useFormats } from '../../../commons/Formats';
import { ControlsTablePrint } from '../../../commons/ReportsCommon';

export const UnidentifiedLogControlsTablePrint = ({ date, vehicleName }) => {
  const { formatUnit } = useFormats();

  return (
    <ControlsTablePrint
      additional={[
        {
          key: 'date',
          label: 'Date',
          value: formatUnit('timelessDate', moment(date)),
        },
        {
          key: 'vehicleName',
          label: 'Vehicle',
          value: vehicleName,
        },
      ]}
    />
  );
};

UnidentifiedLogControlsTablePrint.propTypes = {
  date: PropTypes.string.isRequired,
  vehicleName: PropTypes.string,
};
