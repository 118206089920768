import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import moment from 'moment-timezone';
import {
  BasicDialog,
  Button,
  Clickable,
  ExpansionPanel,
  Icon,
  Menu,
  ProgressOverlay,
} from 'stti-react-common';
import { useHistory } from 'react-router-dom';

import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { useDebounce } from '../../../../../helpers/hooks';
import { Feature } from '../../../../commons/Feature';
import { columnDefs } from './columnDefs';
import {
  AdminCard,
  AdminSearchPanel,
  AdminExportDataMenu,
  DeleteAdminItemDialog,
} from '../../../../commons/AdminCommon';
import { AggregatesCard } from '../../../../commons/ReportsCommon';

import { TabletDebugDialog } from '../Modals/TabletDebugDialog';
import { TabletDeletePrompt } from '../Modals/TabletDeletePrompt';

import { useFormats } from '../../../../commons/Formats';

import { FEATURES } from '../../../../../features';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const TabletSummary = ({
  tablets,
  fetchTablets,
  fetchTabletDiagnosticConfigs,
  updateTabletDiagnosticConfigs,
  deleteTablet,
  openTabletDetail,
  openAssetDetail,
  openActivationCodes,
  route,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const [dialogLoading, setDialogLoading] = useState(true);
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const { formatUnit } = useFormats();
  const history = useHistory();

  useEffect(() => {
    fetchTablets();
  }, [fetchTablets]);

  const deviceActiveLast30Days = useMemo(
    () =>
      filter(tablets, (tablet) =>
        moment(tablet.lastSignIn).isSameOrAfter(moment().subtract(30, 'days'))
      ).length,
    [tablets]
  );

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          onClick={() => {
            openTabletDetail({ tabletId: data.key, mode: 'edit' });
          }}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetRow({
              text: data.assetTag,
              lastSignIn: data.lastSignIn,
              timeZone: data.timeZone,
              key: data.key,
            });
            setCurrentDialog('delete');
          }}
          icon="delete"
        />
        <Menu
          icon="bug_report"
          tooltip="Device Action Menu"
          items={[
            {
              label: 'New Configuration',
              icon: 'add',
              onClick: () => {
                setTargetRow({
                  assetTag: data.assetTag,
                  key: data.key,
                });
                setCurrentDialog('debug');
              },
            },
            {
              label: 'Action History',
              icon: 'list',
              onClick: () => {
                setCurrentDialog('debugHistory');
                setTargetRow({});
                setDialogLoading(true);
                fetchTabletDiagnosticConfigs(data.key)
                  .then((historyData) => {
                    setTargetRow({
                      assetTag: data.assetTag,
                      key: historyData.key,
                      debugHistory: historyData.debugHistory,
                    });
                  })
                  .finally(() => setDialogLoading(false));
              },
            },
          ]}
        />
      </>
    ),
    AssetHeader: () => <Icon icon="launch" />,
    AssetTag: ({ data, value }) => (
      <Clickable
        className="TabletSummary__assetIdAction--view"
        onClick={() => {
          openTabletDetail({ tabletId: data.key, mode: 'view' });
        }}
      >
        <Icon icon="launch" />
      </Clickable>
    ),
    Vehicle: ({ data, value }) =>
      value ? (
        <Clickable
          className="TabletSummary__assetAction--view"
          onClick={() => {
            openAssetDetail({ assetId: data.asset });
          }}
        >
          {value}
        </Clickable>
      ) : (
        '—'
      ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  // RENDER
  return (
    <Feature.Wrapper className="TabletSummary">
      <Feature.Header onClose={route && route.close} title="Tablets" service="tablets">
        {FEATURES.admin.hideActivationCodesFromMainMenu && (
          <Button
            icon="web"
            variant="contained"
            label="Activation Codes"
            onClick={() =>
              openActivationCodes({ ...route.matched.values }, { history, root: 'administration' })
            }
          />
        )}
        <AdminExportDataMenu dataGridController={dataGridController} filename="Tablets" />
      </Feature.Header>
      <Feature.Body>
        {FEATURES.admin.hideTabletCardContainer && (
          <div className="TabletSummary__Aggregates">
            <ExpansionPanel
              title="Aggregates"
              onChange={() => setExpanded(!expanded)}
              value={expanded}
              summary={
                expanded ? null : (
                  <div>
                    {tablets.length} registered tablets, {deviceActiveLast30Days} active last 30
                    days
                  </div>
                )
              }
            >
              <AggregatesCard
                title="Overview"
                name="overview"
                fields={[
                  {
                    header: 'Registered tablets',
                    value: tablets.length,
                  },
                  {
                    header: 'Active Last 30 Days',
                    value: deviceActiveLast30Days,
                  },
                ]}
              />
            </ExpansionPanel>
          </div>
        )}
        {!FEATURES.admin.hideTabletCardContainer && (
          <div className="TabletSummary__cardContainer">
            <AdminCard label="Registered Tablets" value={tablets.length} icon="tablet_mac" />
            <AdminCard
              label="Active Last 30 Days"
              value={deviceActiveLast30Days}
              icon="check_circle_outline"
            />
          </div>
        )}
        <AdminSearchPanel
          searchPlaceHolder="Search By"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={tablets}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
          enableCellTextSelection
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Device"
        isOpen={currentDialog === 'delete'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={deleteTablet}
        rowData={targetRow}
        itemTitle={targetRow.text}
      >
        <TabletDeletePrompt
          lastSignIn={
            targetRow.lastSignIn
              ? formatUnit('date', moment(targetRow.lastSignIn).tz(targetRow.timeZone))
              : ' '
          }
        />
      </DeleteAdminItemDialog>
      <TabletDebugDialog
        isOpen={currentDialog === 'debug'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={updateTabletDiagnosticConfigs}
        rowData={targetRow}
      />
      <BasicDialog
        title={!dialogLoading ? `Action History for: ${targetRow.assetTag}` : ''}
        className="TabletSummary__DebugHistoryDialog"
        isOpen={currentDialog === 'debugHistory'}
        onClose={() => setCurrentDialog(null)}
      >
        <div>
          <ProgressOverlay isOpen={dialogLoading} />
          {targetRow && targetRow.debugHistory && targetRow.debugHistory.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Key/Value</th>
                </tr>
              </thead>
              <tbody>
                {targetRow.debugHistory.map((debug) => (
                  <tr key={debug.key}>
                    <td>{debug.key}</td>
                    <td>{debug.type}</td>
                    <td>{formatUnit('date', moment(debug.startAt))}</td>
                    <td>{formatUnit('date', moment(debug.endAt))}</td>
                    <td>
                      {debug.params && debug.params.length > 0 ? (
                        debug.params.map(({ paramKey, paramValue, key, value }) => (
                          <li key={key}>
                            <span>
                              <strong>Key: </strong>
                            </span>
                            <span>{paramKey || key || <i>N/A</i>}</span>
                            <span>
                              <strong> Value: </strong>
                            </span>
                            <span>{paramValue || value || <i>N/A</i>}</span>
                          </li>
                        ))
                      ) : (
                        <i>N/A</i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            !dialogLoading && <span>No Action History Found</span>
          )}
        </div>
      </BasicDialog>
    </Feature.Wrapper>
  );
};

TabletSummary.propTypes = {
  tablets: PropTypes.array.isRequired,
  fetchTablets: PropTypes.func.isRequired,
  fetchTabletDiagnosticConfigs: PropTypes.func.isRequired,
  updateTabletDiagnosticConfigs: PropTypes.func.isRequired,
  deleteTablet: PropTypes.func.isRequired,
  openTabletDetail: PropTypes.func.isRequired,
  openAssetDetail: PropTypes.func.isRequired,
  openActivationCodes: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
