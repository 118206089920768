import { createSelector } from 'reselect';
import { includes } from 'lodash';

import { selectFilteredOus } from '../../../system';

const getCameraDevices = (state) => state.administration.devices.cameras.devices.data;
const getVehicles = (state) => state.administration.resources.vehicles.data;

/**
 * selectAllVehicles(state) -> [vehicles]
 *
 * Selector for all vehicles
 */
export const selectAllVehicles = createSelector(
  [getVehicles, getCameraDevices],
  (vehicles, cameraDevices) =>
    vehicles.map((row) => ({
      cameraId: cameraDevices?.find(({ vehicleKey }) => row.key === vehicleKey)?.id,
      ...row,
    }))
);

/**
 * selectVehicles(state) -> [vehicles]
 *
 * Selector for vehicles using filteredOus
 */
export const selectVehicles = createSelector(
  [getVehicles, selectFilteredOus, getCameraDevices],
  (vehicles, filteredOus, cameraDevices) =>
    vehicles
      .filter(({ managedBy }) => includes(filteredOus, managedBy.key))
      .map((row) => ({
        cameraId: cameraDevices?.find(({ vehicleKey }) => row.key === vehicleKey)?.id,
        ...row,
      }))
);

/**
 * selectVehicle(state, { assetId }) -> vehicle
 *
 * Selector for single vehicle by key
 */
export const selectVehicle = (state, assetId) => {
  const vehicle = state.administration.resources.vehicles.data.find(
    (item) => item?.key === assetId
  );
  const cameraDevices = getCameraDevices(state);
  return {
    cameraId: cameraDevices?.find(({ vehicleKey }) => vehicle?.key === vehicleKey)?.id,
    ...vehicle,
  };
};

/**
 * selectVehiclesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on vehicles
 */
export const selectVehiclesFetchRequests = (state) =>
  state.administration.resources.vehicles.fetches;
