import BetterPropTypes from 'better-prop-types';

export const location = BetterPropTypes.shape({
  key: BetterPropTypes.string.isOptionalButNotNull,
  name: BetterPropTypes.string.isOptionalButNotNull,
  type: BetterPropTypes.string.isOptionalButNotNull,
  typeName: BetterPropTypes.string.isOptionalButNotNull,
  speedLimit: BetterPropTypes.shape({
    gracePeriod: BetterPropTypes.number.isOptionalButNotNull,
    limit: BetterPropTypes.number.isOptionalButNotNull,
  }),
  locations: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      latitude: BetterPropTypes.number.isOptionalButNotNull,
      longitude: BetterPropTypes.number.isOptionalButNotNull,
    })
  ).isOptionalButNotNull,
  color: BetterPropTypes.string.isOptionalButNotNull,
  sizeOrder: BetterPropTypes.number.isOptionalButNotNull,
});
