import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Clickable } from 'stti-react-common';

export const LaunchReport = ({ className = '', onClick, disabled }) =>
  onClick ? (
    <Clickable
      className={`Report__LaunchReport ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon icon="launch" />
    </Clickable>
  ) : (
    <Icon icon="launch" />
  );

LaunchReport.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
