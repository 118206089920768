/**
 * selectDelays(state) -> [delays]
 *
 * Selector for all delays
 */
export const selectDelays = (state) => state.administration.configurations.delays.data;

/**
 * selectDelaysFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on delays
 */
export const selectDelaysFetchRequests = (state) =>
  state.administration.configurations.delays.fetches;
