import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import { sumOfField } from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();
  return (
    <AggregatesCard
      title="Totals"
      name="totals"
      fields={[
        {
          header: 'Traversals',
          value: Number(formatUnit('number', sumOfField(rows, 'recordCount'))).toFixed(0),
        },
        {
          header: 'Driving Distance',
          value: formatUnit('distance', sumOfField(rows, 'distanceDriven')),
        },
        {
          header: 'Fuel Consumed',
          value: formatUnit('volume', sumOfField(rows, 'fuelConsumed')),
        },
      ]}
    />
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
