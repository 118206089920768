import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectRolesFetchRequests } from './selectors';

const fetchRoles = makeRestApiAction({
  service: 'roles',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRoles,
  notifications: {
    failure: (actionParam, apiError) => `Couldn't retrieve roles: ${apiError.message}`,
  },
});

export const fetchRolesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRolesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchRoles,
  baseActionType: ACTION_TYPE.fetchRoles,
});
