import { get, isFinite } from 'lodash';

export const getLegConsumption = (leg) => {
  if (!leg || (!leg.consumedFuel && !leg.distance)) {
    return 0;
  }
  const consumption = leg.distance / leg.consumedFuel;
  return isFinite(consumption) ? consumption : 0;
};

export const getLegSum = (record, field) =>
  get(record.empty, field, 0) + get(record.loaded, field, 0);
