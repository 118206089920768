import produce from 'immer';

import {
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  actionTypeRequest,
  actionTypeFailure,
  removeEntity,
  deepMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchUsers: 'administration/resources/users/fetchUsers',
  fetchInspectors: 'administration/resources/users/fetchInspectors',
  fetchUser: 'administration/resources/users/fetchUser',
  updateUser: 'administration/resources/users/updateUser',
  createUser: 'administration/resources/users/createUser',
  deleteUser: 'administration/resources/users/deleteUser',
  restoreUser: 'administration/resources/users/restoreUser',
};

export const usersReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchUsers)) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchUser,
        ACTION_TYPE.createUser,
        ACTION_TYPE.updateUser,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteUser)) {
      const payloadCopy = { ...payload };
      payloadCopy.deleted = true;
      draft.data = shallowMergeEntities(state.data, payloadCopy, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.restoreUser)) {
      const payloadCopy = { ...payload };
      payloadCopy.deleted = false;
      draft.data = shallowMergeEntities(state.data, payloadCopy, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchUsers, ACTION_TYPE.fetchUser])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchUsers, ACTION_TYPE.fetchUser])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
