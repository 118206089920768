/* eslint-disable no-console */

import packageJson from '../../../package.json';
import { FEATURES } from '../../features';

console.success = (message, table) => {
  console.groupCollapsed(message);
  console.table(table);
  console.groupEnd();
};

export const debugMode = localStorage && localStorage.getItem('Navistream.debug') === 'true';

export const version = () => {
  if (debugMode) {
    const info = [
      { key: 'Name', value: 'Webclient React' },
      { key: 'React client code version', value: packageJson.version },
      { key: 'Git branch version', value: packageJson.branch.version },
    ];

    const getEntries = (target) =>
      Object.entries(target).map((entry) => ({
        key: entry[0],
        value: entry[1],
      }));

    console.groupCollapsed('WEB CLIENT INFORMATION');
    console.success('GENERAL INFO', info);
    console.success('ADMIN FEATURES', getEntries(FEATURES.admin));
    console.success('REPORT FEATURES', getEntries(FEATURES.reports));
    console.groupEnd();
  }
};
