import { filter, isObject } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectOrganizationFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const appendOrganizationKeyProperties = ({ parent, carrier, principal, ...rest }) => ({
  ...rest,
  scope: rest._type === 'admin.OrganizationalUnit' ? 'Organization' : 'Tenant',
  parentKey: isObject(parent) ? parent.key : parent,
  carrierKey: carrier,
  principalKey: principal,
}); // drop all other properties

const reassignOrganizationKeyProperties = ({ parentKey, carrierKey, principalKey, ...rest }) => ({
  ...rest,
  parent: parentKey,
  carrier: carrierKey,
  principal: principalKey,
}); // drop all other properties

const fetchOrganizations = makeRestApiAction({
  service: 'organizations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchOrganizations,
  transformOutput: (responseData) => responseData.map(appendOrganizationKeyProperties),
  notificationsItemDescriptor: 'organization',
});

export const fetchOrganizationsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectOrganizationFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchOrganizations,
  baseActionType: ACTION_TYPE.fetchOrganizations,
});

export const fetchOrganization = makeRestApiAction({
  service: 'organizationalUnits',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchOrganization,
  getId: (key) => key,
  transformInput: () => ({
    fetch: `parent,children,members.role,members.user`,
  }),
  transformOutput: appendOrganizationKeyProperties,
  notificationsItemDescriptor: 'organizational unit',
});

export const createOrganization = makeRestApiAction({
  service: 'organizations',
  method: 'post',
  baseActionType: ACTION_TYPE.createOrganization,
  transformInput: reassignOrganizationKeyProperties,
  transformOutput: (responseData, { usDotNumber }) => ({
    usDotNumber,
    ...appendOrganizationKeyProperties(responseData),
  }),
  notificationsItemDescriptor: 'organization',
  throwApiErrors: true,
});

export const updateOrganization = makeRestApiAction({
  service: 'organizations',
  method: 'put',
  baseActionType: ACTION_TYPE.updateOrganization,
  getId: ({ key }) => key,
  transformInput: reassignOrganizationKeyProperties,
  transformOutput: (responseData, { usDotNumber }) => ({
    usDotNumber,
    ...appendOrganizationKeyProperties(responseData),
  }),
  notificationsItemDescriptor: 'organization',
  throwApiErrors: true,
});

export const deleteOrganization = makeRestApiAction({
  service: 'organizations',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteOrganization,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'organization',
});

/*
 This call will update a tenant organization via commands service and then we will append using transformOutput
 the new updated ou data into state.system.ous.data array.
*/

export const updateOrganizationPerformancePoints = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.updateOrganizationPerformancePoints,
  transformInput: ({ key, showVCPerformancePoints }) => ({
    ouKey: key,
    value: showVCPerformancePoints,
    _type: 'admin.ChangeShowVCPerformancePoints',
  }),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'organizational unit',
  throwApiErrors: true,
});
