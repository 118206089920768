import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import { Chip } from 'stti-react-common';

import { ControlGroupSummary } from './ControlGroupSummary';
import { useReport } from './useReport';

export const GridPanelSummary = ({ children }) => {
  const { state, setState } = useReport();
  const { dataGrid } = state;

  return (
    <>
      {children}
      <ControlGroupSummary group="dataGrid" />
      {Object.keys(dataGrid.filterModel).length !== 0 && (
        <Chip
          key="dataGridFiltersActive"
          label="Filters active"
          onDelete={() =>
            setState((draft) => {
              draft.dataGrid.filterModel = {};
            })
          }
        />
      )}
      {dataGrid.columnState.reduce(
        (groupingActive, column) => groupingActive || isNumber(column.rowGroupIndex),
        false
      ) && (
        <Chip
          key="dataGridGroupingActive"
          label="Grouping active"
          onDelete={() =>
            setState((draft) => {
              draft.dataGrid.columnState = draft.dataGrid.columnState.map((column) => ({
                ...column,
                rowGroupIndex: null,
              }));
            })
          }
        />
      )}
      {dataGrid.columnState.reduce(
        (pinningActive, column) => pinningActive || column.pinned,
        false
      ) && (
        <Chip
          key="dataGridPinningActive"
          label="Pinning active"
          onDelete={() =>
            setState((draft) => {
              draft.dataGrid.columnState = draft.dataGrid.columnState.map((column) => ({
                ...column,
                pinned: null,
              }));
            })
          }
        />
      )}
      {dataGrid.sortModel.length !== 0 && (
        <Chip
          key="dataGridSortingActive"
          label="Sorting active"
          onDelete={() =>
            setState((draft) => {
              draft.dataGrid.sortModel = [];
            })
          }
        />
      )}
    </>
  );
};

GridPanelSummary.propTypes = {
  children: PropTypes.node,
};
