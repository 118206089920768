import React from 'react';

import { SectionPanel, ControlGroupSummary } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';

import { customPropTypes } from '../../../../helpers/customPropTypes';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={() => <ControlGroupSummary group="aggregates" />}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: customPropTypes.performanceDetails,
};
