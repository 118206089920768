import { connect } from 'react-redux';

import { CompanionAppDetail } from './detail';

import { companionApp, tenant } from '../../../../../data/administration/configurations';

export const CompanionAppDetailContainer = connect(
  (state, props) => ({
    companionApp: companionApp.selectors.selectCompanionApp(state, props),
    tenants: tenant.selectors.selectTenants(state),
  }),
  {
    fetchCompanionApps: companionApp.actions.fetchCompanionAppsOptimized,
    fetchTenants: tenant.actions.fetchTenantsOptimized,
    updateCompanionApp: companionApp.actions.updateCompanionApp,
    createCompanionApp: companionApp.actions.createCompanionApp,
    deleteCompanionApp: companionApp.actions.deleteCompanionApp,
  }
)(CompanionAppDetail);
