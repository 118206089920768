import { connect } from 'react-redux';

import { messenger } from '../../../data/messenger';

import { selectSystemUser } from '../../../data/system/systemSelectors';

import { Interval } from './interval';

export const IntervalContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    messages: messenger.selectors.selectMessages(state),
  }),
  {
    fetchMessages: messenger.actions.fetchMessages,
  }
)(Interval);
