import produce from 'immer';
import { includes } from 'lodash';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchCountries: 'system/fetchCountries',
  fetchCurrentOrganizationUnit: 'system/fetchCurrentOrganizationUnit',
  fetchCurrentOrganizationUnits: 'system/fetchCurrentOrganizationUnits',
  fetchCurrentPermissions: 'system/fetchCurrentPermissions',
  fetchCurrentTenant: 'system/fetchCurrentTenant',
  fetchCurrentUser: 'system/fetchCurrentUser',
  fetchEnvironments: 'system/fetchEnvironments',
  fetchOrganization: 'admin/organization/fetchOrganization',
  fetchRegulations: 'system/fetchRegulations',
  fetchRoles: 'system/fetchRoles',
  fetchSearchLimits: 'system/fetchSearchLimits',
  fetchTenantVehicleConfig: 'system/fetchTenantVehicleConfig',
  fetchVideoConfigs: 'system/fetchVideoConfigs',
  setActiveOu: 'system/setActiveOu',
  setFilteredOuMode: 'system/setFilteredOuMode',
  setFilteredOus: 'system/setFilteredOus',
  setMenu: 'system/setMenu',
  setPrintMode: 'system/setPrintMode',
  setSystem: 'system/setSystem',
  signalWebclientViewError: 'system/signalWebclientViewError',
  signalWebclientViewReady: 'system/signalWebclientViewReady',
  signIn: 'system/signIn',
  updateCurrentOrganizationUnit: 'system/updateCurrentOrganizationUnit',
  updateCurrentUser: 'system/updateCurrentUser',
  updateOrganization: 'admin/organization/updateOrganization',
  updateTenantVehicleConfig: 'system/updateTenantVehicleConfig',
};

const unitSystemDefaults = {
  metric: {
    distance: 'km',
    distanceEld: 'km',
    mileage: 'km/l',
    volume: 'l',
    weight: 'kg',
    pressure: 'psi', // TODO: make 'kpa' once user preferences enhancements implemented
  },
  imperial: {
    distance: 'mi',
    distanceEld: 'mi',
    mileage: 'mpg_us',
    volume: 'gal_us',
    weight: 'lb',
    pressure: 'psi',
  },
};

const getMainPath = (url) => {
  const hashIndex = url.indexOf('#');
  if (hashIndex !== -1) {
    const path = url.substring(hashIndex + 2);
    return path.split('/')[0];
  }
  return null;
};

const initialCategory = getMainPath(window.location.href);

const initialState = () => ({
  activeOu: {
    key: null,
  },
  countries: {
    fetches: [],
    data: undefined,
  },
  environments: {
    fetches: [],
    data: undefined,
  },
  user: {},
  tenant: {},
  videoConfigs: {
    fetches: [],
    data: [],
  },
  formats: {
    unitSystem: 'metric',
    date: 'MMM D, YYYY HH:mm',
    duration: 'h:mm',
    timeZone: 'Etc/UTC',
    ...unitSystemDefaults.metric,
  },
  ous: {
    fetches: [],
    data: [],
  },
  regulations: {
    fetches: [],
    data: undefined,
  },
  roles: {
    fetches: [],
    data: undefined,
  },
  printMode: {
    print: false,
    attach: [],
    includeDetail: false,
    suppressMap: false,
    webclientViewReadyDelay: undefined,
  },
  menu: {
    category: includes(['profile', 'systemSettings'], initialCategory)
      ? 'administration'
      : initialCategory,
    option: '',
  },
  oauth: JSON.parse(window.localStorage.getItem('oauth')) || {},
  filters: {
    ous: [],
    ouMode: 'default',
  },
});

export const systemReducer = (state = initialState(), { type, request, payload }) =>
  produce(state, (draft) => {
    if (type === ACTION_TYPE.setSystem) {
      // set any properties included in the payload
      const { ous, activeOu, user, tenant } = payload;
      if (ous) {
        draft.ous.data = deepMergeEntities(state.ous.data, ous, 'key');
      }
      if (activeOu) {
        draft.activeOu = activeOu;
        draft.formats.unitSystem = activeOu.unitSystem
          ? activeOu.unitSystem.toLowerCase()
          : 'metric';
      }
      if (user) {
        draft.user = { ...user };
        draft.formats.unitSystem = user.unitSystem ? user.unitSystem.toLowerCase() : 'metric';
      }
      if (tenant && tenant.key) {
        draft.tenant = { ...tenant, key: String(tenant.key) }; // Angular-provided tenant key is number
      }
      draft.formats = {
        ...draft.formats,
        ...unitSystemDefaults[draft.formats.unitSystem],
        timeZone: draft.activeOu.timeZone,
      };
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRegulations)) {
      draft.regulations.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCountries)) {
      draft.countries.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchEnvironments)) {
      draft.environments.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRoles)) {
      draft.roles.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCurrentTenant)) {
      draft.tenant = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoConfigs)) {
      draft.videoConfigs.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCurrentOrganizationUnits)) {
      draft.ous.data = deepMergeEntities(state.ous.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteOrganization)) {
      draft.ous.data = removeEntity(state.ous.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.updateOrganization,
        ACTION_TYPE.fetchOrganization,
      ])
    ) {
      draft.ous.data = deepMergeEntities(state.ous.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchOrganization)) {
      const { id, actionParam } = request;
      draft.ous.fetches = shallowMergeEntities(state.ous.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchOrganization])) {
      draft.ous.fetches = removeEntity(state.fetches, request);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchTenantVehicleConfig,
        ACTION_TYPE.updateTenantVehicleConfig,
      ])
    ) {
      draft.tenant = { ...state.tenant, ...payload };
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchCurrentOrganizationUnit,
        ACTION_TYPE.updateCurrentOrganizationUnit,
      ])
    ) {
      draft.activeOu = { ...state.activeOu, ...payload };
    }

    if (type === ACTION_TYPE.setMenu) {
      draft.menu = { ...payload };
    }

    if (type === ACTION_TYPE.setActiveOu) {
      draft.activeOu = { ...payload };
    }

    if (type === ACTION_TYPE.setPrintMode) {
      const { print, attach = [], includeDetail, suppressMap, webclientViewReadyDelay } = payload;
      draft.printMode = { print, attach, includeDetail, suppressMap, webclientViewReadyDelay };
    }

    if (type === ACTION_TYPE.setFilteredOus) {
      draft.filters.ous = payload;
    }

    if (type === ACTION_TYPE.setFilteredOuMode) {
      draft.filters.ouMode = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchSearchLimits)) {
      draft.searchLimits = { ...payload };
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchCurrentUser,
        ACTION_TYPE.updateCurrentUser,
      ])
    ) {
      draft.user = { ...state.user, ...payload };
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchCurrentUser)) {
      window.localStorage.removeItem('oauth');
      window.localStorage.removeItem('menu');
      window.location.replace('/#/login');
    }
  });
