import produce from 'immer';
import { reject, findIndex } from 'lodash';

const initialState = {
  active: [],
};

export const requestsReducer = (state = initialState, { request }) => {
  if (!request || request.skipped) return state;

  return produce(state, (draft) => {
    if (request.active === false) {
      // remove request
      draft.active = reject(state.active, { id: request.id });
      return;
    }
    const index = findIndex(state.active, { id: request.id });
    if (index !== -1) {
      // update request
      draft.active[index] = { ...state.active[index], ...request };
    } else {
      // add request
      draft.active.push(request);
    }
  });
};
