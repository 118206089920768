import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchOrganizations: 'administration/configurations/organization/fetchOrganizations',
  fetchOrganization: 'administration/configurations/organization/fetchOrganizations',
  updateOrganization: 'administration/configurations/organization/updateOrganization',
  createOrganization: 'administration/configurations/organization/createOrganization',
  deleteOrganization: 'administration/configurations/organization/deleteOrganization',
  updateOrganizationPerformancePoints:
    'administration/configurations/organization/updateOrganizationPerformancePoints',
};

const initialState = {
  fetches: [],
  data: [],
};

export const organizationsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(
        type,
        ACTION_TYPE.fetchOrganizations,
        ACTION_TYPE.updateOrganizationPerformancePoints
      )
    ) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteOrganization)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchOrganization,
        ACTION_TYPE.createOrganization,
        ACTION_TYPE.updateOrganization,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchOrganizations, ACTION_TYPE.fetchOrganization])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchOrganizations, ACTION_TYPE.fetchOrganization])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
