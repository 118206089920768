import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { malfunctionAndDiagnosticEventsColumnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const MalfunctionAndDiagnosticEventsCard = ({ log, printLayoutMode }) => {
  const { eldEvents } = log || {};

  const malfunctionAndDiagnosticEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(
      ({ eventType }) =>
        eventType.includes('DIAGNOSTIC') ||
        eventType.includes('SESSION_START') ||
        eventType.includes('SESSION_END') ||
        eventType.includes('SIGNATURE')
    );
  }, [eldEvents]);

  const malfunctionAndDiagnosticEventsDataGridController = useDataGridController({
    columnDefs: malfunctionAndDiagnosticEventsColumnDefs,
    gridOptions,
  });

  const malfunctionAndDiagnosticEventsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  return (
    <Card fullWidth>
      <div>
        <h4>Malfunction & Diagnostic Events</h4>
      </div>
      {malfunctionAndDiagnosticEvents && (
        <ControlledDataTable
          controller={malfunctionAndDiagnosticEventsDataGridController}
          components={malfunctionAndDiagnosticEventsDataGridComponents}
          rows={malfunctionAndDiagnosticEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

MalfunctionAndDiagnosticEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  printLayoutMode: PropTypes.string,
};
