import { connect } from 'react-redux';
import {
  selectDriverLog,
  fetchDriverLogOptimized,
  fetchDriverLogViolationOptimized,
} from '../../../../data/reports';
import { CanadianEldLogDetailReport } from './CanadianEldLogDetailReport';
import { setPrintMode, selectSystemUser, selectIsUserDriver } from '../../../../data/system';

export const CanadianEldLogDetailReportContainer = connect(
  (state, { driverKey, date }) => ({
    driverLog: selectDriverLog(state, { driverKey, date }),
    user: selectSystemUser(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    setPrintMode,
    fetchDriverLog: fetchDriverLogOptimized,
    fetchDriverLogViolation: fetchDriverLogViolationOptimized,
  }
)(CanadianEldLogDetailReport);
