import BetterPropTypes from 'better-prop-types';

const distance = BetterPropTypes.shape({
  startOnHighway: BetterPropTypes.number,
  endOnHighway: BetterPropTypes.number,
  state: BetterPropTypes.string,
});

export const fuelTaxMileage = BetterPropTypes.shape({
  key: BetterPropTypes.string,
  startAt: BetterPropTypes.string,
  endedAt: BetterPropTypes.string,
  ouKey: BetterPropTypes.string,
  timeZone: BetterPropTypes.string,
  loadLocation: BetterPropTypes.shape({
    latitude: BetterPropTypes.number,
    longitude: BetterPropTypes.number,
  }),
  loadedLeg: BetterPropTypes.shape({
    locationName: BetterPropTypes.string,
    locationKey: BetterPropTypes.string,
  }),
  unloadLocation: BetterPropTypes.shape({
    latitude: BetterPropTypes.number,
    longitude: BetterPropTypes.number,
  }),
  unloadedLeg: BetterPropTypes.shape({
    locationName: BetterPropTypes.string,
    locationKey: BetterPropTypes.string,
  }),
  vehicle: BetterPropTypes.string,
  vehicleName: BetterPropTypes.string,
  driverKey: BetterPropTypes.string,
  driverName: BetterPropTypes.string,
  distances: BetterPropTypes.arrayOf(distance),
});

export const fuelTaxMileages = BetterPropTypes.arrayOf(fuelTaxMileage);
