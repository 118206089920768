import React, { memo, useMemo } from 'react';
import { filter } from 'lodash';
import { BasicDialog, SelectInput } from 'stti-react-common';

import { getFormatDefinitions } from '../../../../helpers/formats';
import { ReportControl } from '../ReportControl';

import './SettingsDialog.scss';

const formatOptions = (formatType, displayOrExport, withDefault = true) => {
  const options = filter(getFormatDefinitions(formatType), displayOrExport).map(
    ({ key, description }) => ({
      value: key,
      label: description,
    })
  );
  if (withDefault)
    options.unshift({
      label: displayOrExport === 'display' ? 'User default' : 'Same as display',
      value: 'default',
    });
  return options;
};

export const SettingsDialog = memo((props) => {
  const options = useMemo(
    () => ({
      dateFormat: formatOptions('date', 'display'),
      distanceFormat: formatOptions('distance', 'display'),
      durationFormat: formatOptions('duration', 'display'),
      mileageFormat: formatOptions('mileage', 'display'),
      volumeFormat: formatOptions('volume', 'display'),
      weightFormat: formatOptions('weight', 'display'),
      dateExportFormat: formatOptions('date', 'export', false),
      distanceExportFormat: formatOptions('distance', 'export'),
      durationExportFormat: formatOptions('duration', 'export', false),
      mileageExportFormat: formatOptions('mileage', 'export'),
      volumeExportFormat: formatOptions('volume', 'export'),
      weightExportFormat: formatOptions('weight', 'export'),
    }),
    []
  );

  return (
    <BasicDialog
      title="Report Settings"
      className="Report__SettingsDialog"
      maxWidth="sm"
      fullWidth
      scroll="body"
      keepMounted // important for registerControl
      {...props}
    >
      <h3 style={{ gridArea: 'displayHeading' }}>Display Formats</h3>
      <ReportControl
        name="dateFormat"
        label="Time"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.dateFormat} />}
      />
      <ReportControl
        name="distanceFormat"
        label="Distance, Speed"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.distanceFormat} />}
      />
      <ReportControl
        name="durationFormat"
        label="Duration"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.durationFormat} />}
      />
      <ReportControl
        name="mileageFormat"
        label="Mileage"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.mileageFormat} />}
      />
      <ReportControl
        name="volumeFormat"
        label="Volume"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.volumeFormat} />}
      />
      <ReportControl
        name="weightFormat"
        label="Weight"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => <SelectInput {...renderProps} options={options.weightFormat} />}
      />
      <h3 style={{ gridArea: 'exportHeading' }}>Export Formats</h3>
      <ReportControl
        name="dateExportFormat"
        label="Time"
        defaultValue="YYYY-MM-DDTHH:mm:ssZZ"
        autoGridArea
        disabled
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.dateExportFormat} />
        )}
      />
      <ReportControl
        name="distanceExportFormat"
        label="Distance, Speed"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.distanceExportFormat} />
        )}
      />
      <ReportControl
        name="durationExportFormat"
        label="Duration"
        defaultValue="h"
        autoGridArea
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.durationExportFormat} />
        )}
      />
      <ReportControl
        name="mileageExportFormat"
        label="Mileage"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.mileageExportFormat} />
        )}
      />
      <ReportControl
        name="volumeExportFormat"
        label="Volume"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.volumeExportFormat} />
        )}
      />
      <ReportControl
        name="weightExportFormat"
        label="Weight"
        defaultValue="default"
        autoGridArea
        render={(renderProps) => (
          <SelectInput {...renderProps} options={options.weightExportFormat} />
        )}
      />
    </BasicDialog>
  );
});
