import BetterPropTypes from 'better-prop-types';

const innerAggregatorProps = BetterPropTypes.shape({
  normal: BetterPropTypes.number,
  harsh: BetterPropTypes.number,
  total: BetterPropTypes.number,
});

const aggregatorProps = BetterPropTypes.shape({
  count: innerAggregatorProps,
  points: innerAggregatorProps,
  duration: innerAggregatorProps,
  distance: innerAggregatorProps,
});

const condition = BetterPropTypes.shape({
  upperBound: BetterPropTypes.number,
  lowerBound: BetterPropTypes.number,
  type: BetterPropTypes.string,
});

const rule = BetterPropTypes.shape({
  gracePeriod: BetterPropTypes.number,
  modifiedAt: BetterPropTypes.string,
  description: BetterPropTypes.string,
  harsh: BetterPropTypes.bool,
  type: BetterPropTypes.string,
  createdAt: BetterPropTypes.string,
  deletedAt: BetterPropTypes.string,
  pointsPerMetre: BetterPropTypes.number,
  isDeleted: BetterPropTypes.bool,
  pointsPerOccurrence: BetterPropTypes.number,
  pointsPerSecond: BetterPropTypes.number,
  conditions: BetterPropTypes.arrayOf(condition),
  key: BetterPropTypes.number,
  vehicleType: BetterPropTypes.any,
});

const incident = BetterPropTypes.shape({
  duration: BetterPropTypes.number,
  distance: BetterPropTypes.number,
  count: BetterPropTypes.number,
  rule: BetterPropTypes.number,
  key: BetterPropTypes.string,
  points: BetterPropTypes.number,
});

const shift = BetterPropTypes.shape({
  vehicleKey: BetterPropTypes.string,
  pointsAccumulated: BetterPropTypes.number,
  vehicleName: BetterPropTypes.string,
  driver: BetterPropTypes.string,
  fuelConsumed: BetterPropTypes.number,
  shift: BetterPropTypes.shape({
    driverOuKeys: BetterPropTypes.arrayOf(BetterPropTypes.number),
    driverKey: BetterPropTypes.number,
    start: BetterPropTypes.string,
    driverName: BetterPropTypes.string,
    end: BetterPropTypes.string,
    driverType: BetterPropTypes.string,
    key: BetterPropTypes.string,
    isCompleted: BetterPropTypes.bool,
  }),
  shiftNumber: BetterPropTypes.number,
  incidents: BetterPropTypes.arrayOf(incident),
});

export const performance = BetterPropTypes.shape({
  rules: BetterPropTypes.arrayOf(rule),
  shifts: BetterPropTypes.arrayOf(shift),
  key: BetterPropTypes.string.isOptionalButNotNull,
});

export const performances = BetterPropTypes.arrayOf(performance);

export const performanceDetail = BetterPropTypes.shape({
  aggregation: BetterPropTypes.shape({
    Speed: aggregatorProps,
    Boost: aggregatorProps,
    Brake: aggregatorProps,
    Holdback: aggregatorProps,
    Idling: aggregatorProps,
    RPM: aggregatorProps,
    exRPM: aggregatorProps,
  }),
  conditions: BetterPropTypes.arrayOf(condition),
  createdAt: BetterPropTypes.string,
  data: BetterPropTypes.shape({
    duration: BetterPropTypes.number,
    distance: BetterPropTypes.number,
    count: BetterPropTypes.number,
    rule: BetterPropTypes.number,
    key: BetterPropTypes.string,
    points: BetterPropTypes.number,
  }),
  deletedAt: BetterPropTypes.string,
  description: BetterPropTypes.string,
  driverName: BetterPropTypes.string,
  endedAt: BetterPropTypes.string,
  gracePeriod: BetterPropTypes.number,
  harsh: BetterPropTypes.bool,
  id: BetterPropTypes.string,
  isDeleted: BetterPropTypes.bool,
  key: BetterPropTypes.string,
  modifiedAt: BetterPropTypes.string,
  ouKey: BetterPropTypes.string,
  pointsPerMetre: BetterPropTypes.number,
  pointsPerOccurrence: BetterPropTypes.number,
  pointsPerSecond: BetterPropTypes.number,
  rangeData: BetterPropTypes.shape({
    Speed: aggregatorProps,
    Brake: aggregatorProps,
    RPM: aggregatorProps,
  }),
  startedAt: BetterPropTypes.string,
  timeZone: BetterPropTypes.string,
  totals: BetterPropTypes.shape({
    distanceDriven: BetterPropTypes.number,
    shiftDuration: BetterPropTypes.number,
    engineTime: BetterPropTypes.number,
    engineIdleTime: BetterPropTypes.number,
  }),
  type: BetterPropTypes.string,
  vehicleType: BetterPropTypes.string,
});

export const performanceDetails = BetterPropTypes.arrayOf(performanceDetail);
