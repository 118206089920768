import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { Button, useBooleanState } from 'stti-react-common';

import { SUPER_ADMIN_ROLE_KEY } from '../../../../../config';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel, AdminTenantDefaultValuesPanel } from '../../../../commons/AdminCommon';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { Feature } from '../../../../commons/Feature';
import { columnDefs } from './columnDefs';
import { SpeedLimitDialog } from './dialog';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

const setStyleColor = (value, defaultValue) => ({
  color: Number(value) > Number(defaultValue) ? 'red' : 'green',
});

export const SpeedLimitSummary = ({
  activeOu,
  clearSpeedLimit,
  defaultValues,
  fetchOrganizations,
  fetchSpeedLimits,
  ous,
  overrideSpeedLimit,
  route,
  setActiveOu,
  speedLimits,
  systemUser,
}) => {
  const [isOpen, openDialog, closeDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const topOu = useMemo(() => ous.find((org) => org.parentKey === null), [ous]);

  const isSuperAdminFromTopOu = useMemo(
    () =>
      !!systemUser?.memberships?.find(
        ({ ou, role }) => role.includes(SUPER_ADMIN_ROLE_KEY) && ou === topOu?.key?.toString()
      ),

    [systemUser, topOu]
  );

  const { name: ouName, key } = activeOu;

  const speedLimitData = useMemo(() => {
    const { defaultSpeedLimit } = defaultValues;
    const { defaultGracePeriod } = defaultValues;
    return speedLimits.map((speedLimit) => ({
      ...speedLimit,
      defaultSpeedLimit,
      defaultGracePeriod,
    }));
  }, [speedLimits, defaultValues]);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <Button
        onClick={() => {
          setTargetRow(data);
          openDialog();
        }}
        icon="edit"
      />
    ),
    SpeedLimit: ({ data: { defaultSpeedLimit }, value, valueFormatted }) => (
      <div style={setStyleColor(value, defaultSpeedLimit)}>{valueFormatted}</div>
    ),
    GracePeriod: ({ data: { defaultGracePeriod }, value, valueFormatted }) => (
      <div style={setStyleColor(value, defaultGracePeriod)}>{valueFormatted}</div>
    ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  const handleSave = (payload) => {
    overrideSpeedLimit(payload).then(fetchSpeedLimits).finally(closeDialog);
  };

  const handleDefault = (payload) => {
    clearSpeedLimit(payload).then(fetchSpeedLimits).finally(closeDialog);
  };

  useEffect(() => {
    fetchSpeedLimits();
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (!isEmpty(activeOu?.key)) {
      dataGridController.setState({ selectedRowIds: [activeOu.key] });
    }
  }, [activeOu, defaultValues]);

  return (
    <div className="SpeedLimitSummary">
      <Feature.Header
        onClose={route.close}
        title="Speed Limit Configuration"
        service="speedTrackingConfiguration"
      >
        {isSuperAdminFromTopOu && (
          <Button
            icon="edit"
            variant="contained"
            label="Defaults"
            onClick={() => {
              setTargetRow({
                name: topOu.name,
                appliesTo: key,
                operationalLevel: 'Tenant',
                speedLimit: defaultValues.defaultSpeedLimit,
                gracePeriod: defaultValues.defaultGracePeriod,
              });
              openDialog();
            }}
          />
        )}
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Speed Limit Configuration"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        {!isEmpty(activeOu) && (
          <AdminTenantDefaultValuesPanel
            name={ouName}
            values={{
              ...defaultValues,
              gracePeriod: speedLimits.find((s) => s.appliesTo === key)?.gracePeriod,
              speedLimit: speedLimits.find((s) => s.appliesTo === key)?.speedLimit,
            }}
          />
        )}
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={speedLimitData}
          searchText={debouncedSearchText}
          rowIdProperty="appliesTo"
          sizeColumnsToFit
          onRowClick={({ appliesTo }) => {
            const selectedActiveOu = ous.find((ou) => ou.key === appliesTo);
            setActiveOu(selectedActiveOu);
            dataGridController.setState({ selectedRowIds: [appliesTo] });
          }}
        />
      </Feature.Body>
      <SpeedLimitDialog
        targetRow={targetRow}
        defaultSpeedLimit={defaultValues.defaultSpeedLimit}
        defaultGracePeriod={defaultValues.defaultGracePeriod}
        closeDialog={closeDialog}
        isOpen={isOpen}
        onSave={handleSave}
        onSetDefaults={handleDefault}
        overrideSpeedLimit={overrideSpeedLimit}
        clearSpeedLimit={clearSpeedLimit}
        isSuperAdminFromTopOu={isSuperAdminFromTopOu}
      />
    </div>
  );
};

SpeedLimitSummary.propTypes = {
  activeOu: PropTypes.object,
  clearSpeedLimit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  fetchOrganizations: PropTypes.func.isRequired,
  fetchSpeedLimits: PropTypes.func.isRequired,
  ous: PropTypes.array.isRequired,
  overrideSpeedLimit: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  setActiveOu: PropTypes.func.isRequired,
  speedLimits: customPropTypes.speedLimits,
  systemUser: customPropTypes.user,
};
