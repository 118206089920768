export class ApiError extends Error {
  constructor({ status, message, error }) {
    super();
    this.status = status;
    this.message = message;
    this.error = error; // optional full error object
  }

  // eslint-disable-next-line class-methods-use-this
  static get STATUS() {
    return {
      timedOut: -2,
      networkError: -1,
      badRequest: 400,
      unauthorized: 401,
      forbidden: 403,
      notFound: 404,
      internalServerError: 500,
    };
  }

  toString() {
    return this.message;
  }
}
