import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find, sortBy } from 'lodash';

const extractKey = (_, key) => key;

const getFetchRequests = (state) => state.reports.fuelTaxMileages.fetches;

/**
 * selectFuelTaxMileages(state) -> [fuelTaxMileages]
 *
 * Selector for all tax mileages events
 */
export const selectFuelTaxMileages = (state) =>
  sortBy(state.reports.fuelTaxMileages.fuelTaxMileages, 'vehicle.name');

/**
 * selectTaxMileagesByKey(state, { key }) -> fuelTaxMileage
 *
 * Selector for single tax mileage by key
 */
export const selectTaxMileagesByKey = createCachedSelector(
  [selectFuelTaxMileages, extractKey],
  (fuelTaxMileages, key) => find(fuelTaxMileages, { key })
)(extractKey);

/**
 * selectTaxMileagesFetches(state) -> [fetchRequestData]
 *
 * Selector for all fetches (active and successful), requestData only
 */
export const selectTaxMileagesFetches = createSelector(getFetchRequests, (fetchRequests) =>
  fetchRequests.map((fetchRequest) => fetchRequest.actionParam)
);
