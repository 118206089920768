import React from 'react';
import { ProgressOverlay } from 'stti-react-common';
import PropTypes from 'prop-types';

// tiles import
import { HosSummary } from './HosSummary';
import { OrderSummary } from './OrderSummary';
import { VehicleDefectSummary } from './VehicleDefectSummary';
import { CameraSummary } from './CameraSummary';
import { CycleSummary } from './CycleSummary';
import { ShiftSummary } from './ShiftSummary';

export const TileRenderer = ({
  option,
  selectedEntity,
  selectedData,
  formatUnit,
  fetchCameras,
  fetchLiveStreamDataByCameraId,
  fetchLiveSnapshotDataByCameraId,
  fetchVideoEventFileLocation,
  loading,
  setOption,
}) => {
  if (option === 'summary') {
    return (
      <div className="title-wrapper">
        <ProgressOverlay isOpen={loading} />
        {selectedEntity?.type === 'driver' && (
          <HosSummary selectedData={selectedData} formatUnit={formatUnit} loading={loading} />
        )}
        {selectedEntity?.type === 'driver' && <OrderSummary selectedData={selectedData} />}
        <VehicleDefectSummary selectedData={selectedData} selectedEntity={selectedEntity} />
        {selectedEntity.camera && (
          <CameraSummary
            selectedEntity={selectedEntity}
            selectedData={selectedData}
            formatUnit={formatUnit}
            fetchCameras={fetchCameras}
            fetchLiveStreamDataByCameraId={fetchLiveStreamDataByCameraId}
            fetchLiveSnapshotDataByCameraId={fetchLiveSnapshotDataByCameraId}
            fetchVideoEventFileLocation={fetchVideoEventFileLocation}
            setOption={setOption}
          />
        )}
      </div>
    );
  }
  if (option === 'driverLogs') {
    return (
      <div className="title-wrapper">
        <ProgressOverlay isOpen={loading} />
        <HosSummary selectedData={selectedData} formatUnit={formatUnit} />
        <CycleSummary selectedData={selectedData} formatUnit={formatUnit} />
        <OrderSummary selectedData={selectedData} />
      </div>
    );
  }

  if (option === 'inspections') {
    return (
      <div className="title-wrapper">
        <ProgressOverlay isOpen={loading} />
        <VehicleDefectSummary selectedData={selectedData} selectedEntity={selectedEntity} />
      </div>
    );
  }

  if (option === 'currentShift') {
    return (
      <>
        <ProgressOverlay isOpen={loading} />
        <ShiftSummary selectedData={selectedData} formatUnit={formatUnit} />
      </>
    );
  }

  if (option === 'camera') {
    return (
      <>
        <ProgressOverlay isOpen={loading} />
        <CameraSummary
          selectedEntity={selectedEntity}
          selectedData={selectedData}
          formatUnit={formatUnit}
          fetchCameras={fetchCameras}
          fetchLiveStreamDataByCameraId={fetchLiveStreamDataByCameraId}
          fetchLiveSnapshotDataByCameraId={fetchLiveSnapshotDataByCameraId}
          fetchVideoEventFileLocation={fetchVideoEventFileLocation}
          setOption={setOption}
          detailMode
        />
      </>
    );
  }

  return <div>{option}</div>;
};

TileRenderer.propTypes = {
  option: PropTypes.string,
  selectedEntity: PropTypes.any,
  selectedData: PropTypes.any,
  formatUnit: PropTypes.func,
  fetchCameras: PropTypes.func,
  fetchLiveStreamDataByCameraId: PropTypes.func,
  fetchLiveSnapshotDataByCameraId: PropTypes.func,
  fetchVideoEventFileLocation: PropTypes.func,
  loading: PropTypes.bool,
  setOption: PropTypes.func,
};
