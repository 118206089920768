import React from 'react';
import PropTypes from 'prop-types';
import { ControlledDataTable as CommonControlledDataTable } from 'stti-react-common';

import { useFormats } from '../Formats';

import './ControlledDataTable.scss';

export const ControlledDataTable = ({ isPrintLayout, ...props }) => {
  const allFormatUtilities = useFormats(); // right now this is the entirety of context
  return (
    <CommonControlledDataTable
      context={allFormatUtilities}
      isPrintLayout={isPrintLayout}
      {...props}
    />
  );
};

Object.assign(ControlledDataTable, CommonControlledDataTable);

ControlledDataTable.propTypes = {
  isPrintLayout: PropTypes.bool.isRequired,
};
