import React from 'react';
import PropTypes from 'prop-types';
import { Clickable, Button } from 'stti-react-common';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { FiltersPanel } from './FiltersPanel';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  MapIcon,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';
import { FEATURES } from '../../../../features';

import './FuelTaxMileageSummaryReport.scss';

const { createState, useDataGridComponents } = ControlledDataGrid;

const defaultState = () => ({
  controls: { dateRangeMode: 'thisMonth' },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['fuelTaxMileages'];

export const FuelTaxMileageSummaryReport = ({
  fetchTaxMileages,
  fuelTaxMileages,
  reportViewId,
  route,
  openFuelTaxReportRoute,
  openFuelTaxMileageLocationReportRoute,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'fuelTaxMileagesSummary',
    reportTypeName: 'Fuel Tax Mileage Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: fuelTaxMileages,
      chain: ['query', 'filters'],
    },
  });
  const {
    debouncedControls,
    setControl,
    filteredRows,
    dataGridController,
    setDataGridFilteredRows,
    reportType,
  } = report;

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ value }) => (
      <Clickable onClick={() => setControl('vehicleNames', [value])}>{value}</Clickable>
    ),
    Driver: ({ value }) => (
      <Clickable onClick={() => setControl('driverNames', [value])}>{value}</Clickable>
    ),
    TaxFuelMileageHeader: () => <MapIcon />,
    TaxFuelMileageMap: ({ data: { key } }) => (
      <Clickable onClick={() => openFuelTaxMileageLocationReportRoute({ fuelTaxMileageKey: key })}>
        <MapIcon />
      </Clickable>
    ),
  });

  const issue =
    'Due to a currently unresolved issue that we are actively investigating, as of 11/2022 we are unable to ensure that the distances provided in this report are accurate. We recommend that you validate these values by other means.';

  // RENDER
  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData isMonthly fetchAction={fetchTaxMileages} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="FuelTaxMileageSummaryReport">
        <ReportFragment.Header
          reportType="taxMileagesSummary"
          route={route}
          services={requestServices}
        >
          {!FEATURES.reports.hideTaxMileageReport && (
            <Button
              icon="watch"
              label="(New) Fuel Tax Report"
              onClick={() => {
                window.location.href = `#/reports/fuelTaxes/default`;
              }}
            />
          )}
        </ReportFragment.Header>

        <QueryPanel
          queryFor="shift"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2018-01-01"
          disableFuture
        />
        <FiltersPanel rows={filteredRows.dataGrid} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <div className="issue-MBL-8146">{issue}</div>
        <ReportFragment.DataGridPanel
          rowIdProperty="key"
          dataGridController={dataGridController}
          dataGridComponents={dataGridComponents}
          filteredRows={filteredRows}
          setDataGridFilteredRows={setDataGridFilteredRows}
          debouncedControls={debouncedControls}
          sizeColumnsToFit
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.output} />
        </SectionPrint>
        <div className="issue-MBL-8146">{issue}</div>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

FuelTaxMileageSummaryReport.propTypes = {
  fetchTaxMileages: PropTypes.func.isRequired,
  fuelTaxMileages: customPropTypes.fuelTaxMileages.isRequired,
  reportViewId: PropTypes.string.isRequired,
  openFuelTaxReportRoute: PropTypes.func.isRequired,
  openFuelTaxMileageLocationReportRoute: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
