import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'lodash';

import { BasicDialog, Form, Icon, TextInput } from 'stti-react-common';

import { validateEmail } from '../../../../../helpers/form/validations';

import './supportUser.scss';

export const TenantSupportUser = ({ createUser, openModal, setOpenModal, tenant }) => {
  const [supportPasswordVisible, setSupportPasswordVisible] = useState(false);

  const { useFormController, Control } = Form;

  const supportUserForm = useFormController({
    controls: {
      username: null,
      email: null,
      password: null,
    },
  });

  const {
    controls: supportFormControls,
    resetControls: supportResetControls,
    hasErrors: supportHasErrors,
  } = supportUserForm;

  const blockFormPost = useMemo(() => {
    const { username, email, password } = supportFormControls;
    if (supportHasErrors) return true;
    if (!username || !email || !password) return true;
    return false;
  }, [supportFormControls, supportHasErrors]);

  const onSupportUserSave = (payload) => {
    if (tenant?.key) createUser({ ...payload, key: tenant.key });
  };

  return (
    <BasicDialog
      className="SupportUser__BasicDialog"
      title={`New Support User for ${tenant?.name}`}
      isOpen={openModal}
      onOpen={supportResetControls}
      onClose={() => setOpenModal(false)}
      buttons={[
        { label: 'Cancel', onClick: () => setOpenModal(false) },
        {
          label: 'Save',
          onClick: () => onSupportUserSave(supportFormControls),
          disabled: blockFormPost,
        },
      ]}
    >
      <Form form={supportUserForm} className="SupportUser__BasicDialog__Form">
        <Control
          Component={TextInput}
          name="username"
          label="User Name"
          error={({ value }) => {
            if (isNil(value) || isEmpty(value)) return false; // means it is optional otherwise it will validate
            if (value.length < 4) {
              return 'User name needs to have more than 4 characters';
            }
            if (value.length > 60) {
              return 'User name needs to have less than 60 characters';
            }
            return false;
          }}
        />
        <Control
          Component={TextInput}
          name="email"
          label="User Email"
          error={({ value, controls }) => {
            const usernameEmpty = isEmpty(controls.supportUserUsername);
            const emailEmpty = isNil(value) || isEmpty(value);

            if (usernameEmpty && emailEmpty) {
              return false;
            }

            return validateEmail(value);
          }}
        />
        <Control
          Component={TextInput}
          name="password"
          type={supportPasswordVisible ? 'text' : 'password'}
          label="User Password"
          error={({ value, controls }) => {
            const usernameEmpty = !controls.username || controls.username.trim() === '';
            const emailEmpty = !controls.email || controls.email.trim() === '';
            const passwordEmpty = !value || value.trim() === '';

            if (!emailEmpty && passwordEmpty) {
              return 'User Password is required if you are adding an email';
            }
            if (!emailEmpty && !passwordEmpty) {
              return false;
            }

            return usernameEmpty !== passwordEmpty;
          }}
          endAdornment={
            <Icon
              className="TenantAdminDetail__visibilityIcon"
              icon={supportPasswordVisible ? 'passwordVisible' : 'visibility_off'}
              onClick={() => setSupportPasswordVisible((prevVisibility) => !prevVisibility)}
            />
          }
        />
      </Form>
    </BasicDialog>
  );
};

TenantSupportUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  tenant: PropTypes.object,
};
