import { merge, findIndex, castArray, flatten, reject, isObject, find } from 'lodash';

const mergeEntities = (originalArray, newItemOrArray, key, mergeFn) => {
  const oldItems = [...originalArray];
  const processItems = castArray(newItemOrArray);
  const addItems = [];
  processItems.forEach((newItem) => {
    if (!isObject(newItem) || !newItem[key])
      throw new Error(
        `Entity to process is not an object or is missing key property. New item: ${JSON.stringify(
          newItem
        )}`
      );
    const index = findIndex(originalArray, { [key]: newItem[key] });
    if (index !== -1) {
      oldItems[index] = mergeFn(oldItems[index], newItem);
    } else {
      addItems.push(newItem);
    }
  });

  return oldItems.concat(addItems);
};

export const deepMergeEntities = (originalArray, newItemOrArray, key = 'id', forceNew) =>
  mergeEntities(originalArray, newItemOrArray, key, (originalItem, newItem) =>
    forceNew ? merge(newItem, { ...originalItem }) : merge({ ...originalItem }, newItem)
  );

export const shallowMergeEntities = (originalArray, newItemOrArray, key = 'id') =>
  mergeEntities(originalArray, newItemOrArray, key, (originalItem, newItem) => newItem);

export const removeEntity = (originalArray, removeItem, key = 'id') => {
  if (!isObject(removeItem) || !removeItem[key])
    throw new Error('Entity to remove not an object or is missing key property.');
  const updatedItems = [...originalArray];
  return reject(updatedItems, { [key]: removeItem[key] });
};

export const actionTypeRequest = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.request`);
  return matchTypes.includes(actionType);
};

export const actionTypeSuccess = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.success`);
  return matchTypes.includes(actionType);
};

export const actionTypeProgressOrSuccess = (actionType, matchType) => {
  const matchTypes = flatten(
    castArray(matchType).map((baseMatchType) => [
      `${baseMatchType}.progress`,
      `${baseMatchType}.success`,
    ])
  );
  return matchTypes.includes(actionType);
};

export const actionTypeFailure = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.failure`);
  return matchTypes.includes(actionType);
};

export const eagerlyMergeEntity = (originalArray, newItem, key = 'id') => {
  const foundItem = find(originalArray, { [key]: newItem[key] });
  const iterimItem = {
    ...newItem,
    __updatingFrom: foundItem,
  };
  return mergeEntities(
    originalArray,
    iterimItem,
    key,
    (originalItem, replacementItem) => replacementItem
  );
};

export const revertEagerlyMergedEntity = (originalArray, revertingItem, key = 'id') => {
  const foundItem = find(originalArray, { [key]: revertingItem[key] });
  if (!foundItem || !foundItem.__updatingFrom) return originalArray; // item not found as expected, do nothing
  return mergeEntities(
    originalArray,
    foundItem.__updatingFrom,
    key,
    (originalItem, replacementItem) => replacementItem
  );
};
