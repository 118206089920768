import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, ToggleInput, SelectInput, NumberInput } from 'stti-react-common';

import { filterRowsByIntersection, mutateRows } from '../../../../helpers/reports/standardFilters';
import { unconvertUnit } from '../../../../helpers/formats';
import { ReportControl, useReport, ReportFilter } from '../../../commons/ReportsCommon';

import './ActivitiesDisplayOptions.scss';

const GPS_ACTIVITIES = ['GpsAcquired', 'GpsLost'];
const NETWORK_ACTIVITIES = ['NetworkConnectionAcquired', 'NetworkConnectionLost'];
const ENTER_EXIT_ACTIVITIES = [
  // Geozones
  'BrakeCheckEntered',
  'BrakeCheckExited',
  'CustomGeozoneEntered',
  'CustomGeozoneExited',
  'FuellingStationEntered',
  'FuellingStationExited',
  'LoadLocationEntered',
  'LoadLocationExited',
  'OffHighwayEntered',
  'OffHighwayExited',
  'OfficeEntered',
  'OfficeExited',
  'RestaurantEntered',
  'RestaurantExited',
  'ScaleEntered',
  'ScaleExited',
  'ShopEntered',
  'ShopExited',
  // 'UnloadLocationEntered',
  // 'UnloadLocationExited',
  // State boundary (no Exited event)
  'StateEntered',
];
const verticalSplitOptions = [
  { label: 'Map below Activites', value: false },
  { label: 'Map beside Activities', value: true },
];

export const ActivitiesDisplayOptions = ({ isOpen, onClose }) => {
  const { controls } = useReport();
  return (
    <BasicDialog
      className="ShiftDetailReport__ActivitiesDisplayOptions"
      maxWidth="xs"
      fullWidth
      keepMounted // important for registerControl
      isOpen={isOpen}
      onClose={onClose}
    >
      <h4>Data Table and Map</h4>
      <ReportControl
        name="showGpsActivities"
        label="Show GPS Acquired/Lost"
        group="dataGrid"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        filterConfig={{
          type: filterRowsByIntersection,
          field: 'activityType',
          group: 'filters',
          reject: true,
          getValues: ({ value }) => ({
            value: value ? undefined : GPS_ACTIVITIES,
          }),
        }}
      />
      <ReportControl
        name="showNetworkActivities"
        label="Show Network Lost/Acquired"
        group="dataGrid"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        filterConfig={{
          type: filterRowsByIntersection,
          field: 'activityType',
          reject: true,
          group: 'filters',
          getValues: ({ value }) => ({
            value: value ? undefined : NETWORK_ACTIVITIES,
          }),
        }}
      />
      <ReportControl
        name="showLocationActivities"
        label="Show Location Entered/Exited"
        group="dataGrid"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        filterConfig={{
          type: filterRowsByIntersection,
          field: 'activityType',
          group: 'filters',
          reject: true,
          getValues: ({ value }) => ({
            value: value ? undefined : ENTER_EXIT_ACTIVITIES,
          }),
        }}
      />
      <ReportControl
        name="showSpeedViolationActivities"
        label="Show Speed Violations"
        group="dataGrid"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        filterConfig={{
          type: mutateRows,
          group: 'filters',
          disabled: ({ value }) => value,
          callback: ({ draft }) => {
            if (draft.activityType === 'SpeedViolation') draft.activityType = 'Driving';
          },
        }}
      />
      <ReportControl
        name="showStoppedInPrecedingActivity"
        label="Include Stops In Preceding Activity"
        group="dataGrid"
        defaultValue
        render={(renderProps) => <ToggleInput {...renderProps} />}
        filterConfig={{
          type: mutateRows,
          group: 'moveStops',
          disabled: ({ value }) => !value,
          callback: ({ draft, rows, index }) => {
            if (draft.topActivityType === 'Stopped') {
              let i = index - 1;
              while (rows[i].topActivityType === 'Stopped') i -= 1; // search backward until new parent found
              if (rows[i].topActivityType === 'Driving') return; // do not attach a stop to a Driving parent
              draft.topActivityType = rows[i].topActivityType;
            }
          },
        }}
      />
      <ReportFilter // change all root Stopped to UnknownStop
        filterConfig={{
          type: mutateRows,
          group: 'unknownStops',
          callback: ({ draft }) => {
            if (draft.topActivityType === 'Stopped') {
              draft.activityType = 'UnknownStop';
              draft.topActivityType = 'UnknownStop';
            }
          },
        }}
      />
      <ReportControl
        name="hideStoppedDurationLessThan"
        label="Hide Stops Shorter Than"
        group="filters"
        defaultValue
        render={(props) => (
          <ToggleInput {...props}>
            <ReportControl
              name="hideStoppedDurationLessThanMinutes"
              group="filters"
              render={(paramControlProps) => (
                <NumberInput {...paramControlProps} endAdornment="minutes" />
              )}
              reducerCallback={(draft, value) => {
                draft.controls.hideStoppedDurationLessThan = !!value;
              }}
              defaultValue={3}
              filterConfig={{
                type: mutateRows,
                group: 'hideStops',
                disabled: ({ controls: { hideStoppedDurationLessThan } }) =>
                  !hideStoppedDurationLessThan,
                callback: ({ draft, value, rows, index }) => {
                  const durationThreshold = unconvertUnit('duration', value, 'm');
                  const precedingRow = rows[index - 1];
                  if (draft.activityType === 'Stopped' && draft.duration < durationThreshold) {
                    // transform Stopped to preceding activity type
                    const { topActivityType } = precedingRow;
                    draft.activityType = topActivityType;
                    draft.topActivityType = topActivityType;
                  }
                  if (
                    draft.activityType !== 'Stopped' &&
                    draft.topActivityType === 'Stopped' &&
                    precedingRow.topActivityType !== 'Stopped'
                  ) {
                    // don't let a non-Stopped activity be reason for Stopped parent
                    draft.topActivityType = precedingRow.topActivityType;
                  }
                },
              }}
            />
          </ToggleInput>
        )}
      />
      {controls.hideStoppedDurationLessThan && (
        <div className="ShiftDetailReport__ActivitiesDisplayOptions__indent">
          <em>Hidden stops will have their time included in the preceding activity.</em>
        </div>
      )}
      <h4>Map</h4>
      <ReportControl
        name="mapPanel"
        label="Show Map"
        group="map"
        // render={renderProps => <ToggleInput {...renderProps} />}
        render={() => null} // make unavailable for now but still use control value
        defaultValue
      />
      <ReportControl
        name="showGeozones"
        label="Show Geozones"
        group="map"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        disabled={!controls.mapPanel}
        defaultValue
      />
      <ReportControl
        name="showSpeedSamples"
        label="Show Speed Samples"
        group="map"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        disabled={!controls.mapPanel}
        defaultValue
      />
      <h4>Printing</h4>
      <ReportControl
        name="printMap"
        label="Print Map"
        group="map"
        render={(renderProps) => <ToggleInput {...renderProps} />}
      />
      <h4>Layout</h4>
      <ReportControl
        name="verticalSplit"
        group="map"
        render={(renderProps) => <SelectInput {...renderProps} options={verticalSplitOptions} />}
        defaultValue
      />
    </BasicDialog>
  );
};

ActivitiesDisplayOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
