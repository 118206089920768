import { fromPairs, uniq } from 'lodash';

import { unconvertUnit } from '../formats';

export const UNDEFINED_NUMBER_PLACEHOLDER = 'X';

export const exceptionTypes = [
  {
    type: 'eventSpeedViolation',
    title: 'Speed Violation',
    getDescription: ({ durationValue, durationUnit, percent }) =>
      `Speed violation for more than ${durationValue} ${durationUnit} with average speed above ${percent}% of speed limit`,
    getDefaultValues: () => ({
      durationValue: 10,
      durationUnit: 's',
      duration: 10,
      percent: 5,
    }),
    controls: [
      {
        component: 'Duration',
        preText: 'For more than',
      },
      {
        component: 'Percent',
        preText: 'With average speed above',
        postText: 'of speed limit',
      },
    ],
  },
  {
    type: 'eventTirePressureViolation',
    title: 'Tire Pressure Violation',
    getDescription: ({ distanceValue, distanceUnit, loadedStatus, percent }) => {
      const loadedStatusText = loadedStatus === 'any' ? '' : ` while ${loadedStatus}`;
      return `Tire pressure violation for more than ${distanceValue} ${distanceUnit}${loadedStatusText} with average pressure above ${percent}% of pressure limit`;
    },
    getDefaultValues: ({ formats }) => ({
      distanceValue: 0.1,
      distanceUnit: formats.distance,
      distance: unconvertUnit('distance', 0.1, formats.distance),
      loadedStatus: 'any',
      percent: 5,
    }),
    controls: [
      {
        component: 'Distance',
        preText: 'For more than',
      },
      {
        component: 'LoadedStatus',
      },
      {
        component: 'Percent',
        preText: 'With average pressure above',
        postText: 'of pressure limit',
      },
    ],
    feature: 'cti',
  },
  // {
  //   type: 'eventEngineDiagnosticError',
  //   title: 'Engine Diagnostics Error',
  //   getDescription: () => `Engine diagnostics error reported`,
  // },
  // {
  //   type: 'eventExcessiveIdling',
  //   title: 'Excessive Idling',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Excessive idling for more than ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 30, durationUnit: 'm', duration: 30 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'eventHosViolation',
  //   title: 'HOS Violation',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Hours of Service violation for more than ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 30, durationUnit: 'm', duration: 30 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'eventLoadTimeExceeded',
  //   title: 'Load Time Exceeded',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Loading + wait time exceeds ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 10, durationUnit: 'm', duration: 10 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'eventMajorDefectsFound',
  //   title: 'Major Defects Found',
  //   getDescription: () => `Inspection has major defects`,
  // },
  // {
  //   type: 'eventminorDefectsFound',
  //   title: 'Minor Defects Found',
  //   getDescription: ({ number }) => `Inspection has more than ${number} minor defects`,
  //   controls: [
  //     {
  //       component: 'Number',
  //       values: { number: 1 },
  //       label: 'Defects',
  //     },
  //   ],
  // },
  // {
  //   type: 'eventShortInspection',
  //   title: 'Short Inspection',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Inspection completed in less than ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 5, durationUnit: 'm', duration: 5 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'eventTirePressureViolation',
  //   title: 'Tire Pressure Violation',
  //   getDescription: ({ distanceValue, distanceUnit }) =>
  //     `Tire Pressure Violation occurred for a distance > ${distanceValue} ${distanceUnit}`,
  //   controls: [
  //     {
  //       component: 'Distance',
  //       values: { distanceValue: 1, distanceUnit: 'km', distance: 1 * 1000 },
  //     },
  //   ],
  // },
  // {
  //   type: 'eventUnloadTimeExceeded',
  //   title: 'Unload Time Exceeded',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Unloading+wait time exceeds ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 10, durationUnit: 'm', duration: 10 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'tripDrivingWithoutSeatbelt',
  //   title: 'Driving Without Seatbelt',
  //   getDescription: ({ distanceValue, distanceUnit }) =>
  //     `Distance of driving without seatbelt exceeds ${distanceValue} ${distanceUnit}`,
  //   controls: [
  //     {
  //       component: 'Distance',
  //       values: { distanceValue: 1, distanceUnit: 'km', distance: 1 * 1000 },
  //     },
  //   ],
  // },
  // {
  //   type: 'tripEldMalfunction',
  //   title: 'ELD Malfunction',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `The ELD reported a malfunction for a duration over ${durationValue} ${durationUnit}`,
  //   controls: [
  //     {
  //       component: 'Duration',
  //       values: { durationValue: 10, durationUnit: 'm', duration: 10 * 60 },
  //     },
  //   ],
  // },
  // {
  //   type: 'tripEldDiagnostics',
  //   title: 'ELD Diagnostics',
  //   getDescription: ({ number }) => `The ELD reported more than ${number} diagnostics events`,
  //   controls: [
  //     {
  //       component: 'Number',
  //       values: { number: 1 },
  //       label: 'Events',
  //     },
  //   ],
  // },
  // {
  //   type: 'tripHardBraking',
  //   title: 'Hard Braking',
  //   getDescription: ({ number }) => `Hard braking for more than ${number} times in a trip`,
  //   controls: [
  //     {
  //       component: 'Number',
  //       values: { number: 3 },
  //       label: 'Times',
  //     },
  //   ],
  // },
  // {
  //   type: 'tripHardCornering',
  //   title: 'Hard Cornering',
  //   getDescription: ({ number }) => `Hard corning for more than ${number} times in a trip`,
  //   controls: [
  //     {
  //       component: 'Number',
  //       values: { number: 3 },
  //       label: 'Times',
  //     },
  //   ],
  // },
  // {
  //   type: 'tripScoring',
  //   title: 'Trip Scoring',
  //   getDescription: ({ number }) => `Trip score below ${number}`,
  //   controls: [
  //     {
  //       component: 'Number',
  //       values: { number: 0 },
  //       label: 'Score',
  //     },
  //   ],
  // },
  {
    type: 'tripFuelMileage',
    title: 'Trip Fuel Mileage',
    getDescription: ({ mileageValue, mileageUnit, distanceValue, distanceUnit }) =>
      `Trip fuel mileage worse than ${mileageValue}${mileageUnit}${
        // mileageUnit includes leading space
        distanceValue !== UNDEFINED_NUMBER_PLACEHOLDER
          ? ` and trip above ${distanceValue} ${distanceUnit}`
          : ''
      }`,
    getDefaultValues: ({ formats }) => ({
      mileageValue: null,
      mileageUnit: formats.mileage,
      distanceValue: null,
      distanceUnit: formats.distance,
    }),
    controls: [
      {
        component: 'Mileage',
        preText: 'Worse than',
      },
      {
        component: 'Distance',
        preText: 'Only trips above',
        error: false, // distance is optional
      },
    ],
  },
  {
    type: 'tripCoreCycleDuration',
    title: 'Trip Core Cycle Time',
    getDescription: ({ durationValue, durationUnit }) =>
      `Trip core cycle time above ${durationValue} ${durationUnit}`,
    getDefaultValues: () => ({
      durationValue: null,
      durationUnit: 'h',
      duration: null,
    }),
    controls: [
      {
        component: 'Duration',
        preText: 'Above',
      },
    ],
  },
  // {
  //   type: 'tripUnidentifiedDrivingDuration',
  //   title: 'Unidentified Driving Duration',
  //   getDescription: ({ durationValue, durationUnit }) =>
  //     `Unidentified driving exceeding ${durationValue} ${durationUnit}`,
  //   getDefaultValues: () => ({ durationValue: 10, durationUnit: 'm', duration: 10 * 60 }),
  //   controls: [
  //     {
  //       component: 'Duration',
  //       preText: 'Above',
  //     },
  //   ],
  // },
  {
    type: 'tripUnknownStopDuration',
    title: 'Unknown Stop Duration',
    getDescription: ({ durationValue, durationUnit }) =>
      `Trip unknown stop duration above ${durationValue} ${durationUnit}`,
    getDefaultValues: () => ({ durationValue: 30, durationUnit: 'm', duration: 30 * 60 }),
    controls: [
      {
        component: 'Duration',
        preText: 'Above',
      },
    ],
  },
  {
    type: 'eventVideoEvent',
    title: 'Camera Event',
    getDescription: ({ videoAny, videoEventTypes = [], videoEventBehaviors = [] }) => {
      if (videoAny) return 'Camera Event';
      const types = uniq([...videoEventTypes, ...videoEventBehaviors]);
      const triggerCount = videoEventTypes.length + videoEventBehaviors.length;
      return `Camera Event: ${types.length > 3 ? `${triggerCount} triggers` : types.join(' / ')}`;
    },
    getDefaultValues: () => ({
      videoAny: true,
    }),
    controls: [
      {
        component: 'VideoOptions',
      },
    ],
  },
];

const exceptionTypesDict = fromPairs(
  exceptionTypes.map(({ type, ...def }) => [type, { controls: [], ...def }])
);

export const EXCEPTION_TYPE = fromPairs(exceptionTypes.map(({ type }) => [type, type]));

export const getExceptionType = (type) => exceptionTypesDict[type];
