import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { BasicDialog, Form, SelectInput, TextInput } from 'stti-react-common';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { formatDutyStatusCodeToFriendly } from '../../../../../helpers/reports/driverLogsUtils';

const { useFormController, Control } = Form;

export const ReassignLogDutyStatusEventDialog = ({
  isOpen,
  onClose,
  onConfirm,
  date,
  driverKey,
  user,
  log,
  row = {},
}) => {
  const { coDrivers = [] } = log || {};

  const form = useFormController({
    controls: {
      key: row.key,
      logKey: `${date}-${driverKey}`,
      annotation: row.annotation,
      dutyStatus: formatDutyStatusCodeToFriendly(row.dutyStatus),
      start: row.start,
    },
  });

  const { resetControls } = form;

  useEffect(() => {
    resetControls();
  }, [isOpen]);
  const { controls } = form;

  const coDriverOptions = useMemo(
    () =>
      !log.isUnidentified
        ? coDrivers
            .map(({ firstName, lastName, key }) => ({
              label: `${firstName} ${lastName}`,
              value: `${key}`,
            }))
            .concat({
              label: `Unidentified`,
              value: 'unidentified',
            })
        : coDrivers.map(({ firstName, lastName, key }) => ({
            label: `${firstName} ${lastName}`,
            value: `${key}`,
          })),
    [coDrivers, log]
  );
  const { hasErrors, isDirty } = form;

  return (
    <BasicDialog
      className="DriverLogDetailReport__EditLogDutyStatusEventDialog"
      isOpen={isOpen}
      onClose={onClose}
      title="Reassign Duty Status Change"
      maxWidth="sm"
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Reassign Duty Status',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            onConfirm({
              ...controls,
              toDriverKey:
                controls && controls.toDriverKey === 'unidentified' ? null : controls.toDriverKey,
              annotation: `*${controls.annotation} [${user.fullName}]`,
            });
            onClose();
          },
        },
      ]}
    >
      <Form form={form}>
        <span>
          Any reassigned Duty Statuses will need to be approved by the co-driver and may be
          rejected.
        </span>
        <Control
          Component={SelectInput}
          name="toDriverKey"
          label="Reassign to"
          options={coDriverOptions}
          defaultValue={coDriverOptions && coDriverOptions.length > 0 && coDriverOptions[0].value}
          error={({ value }) => !value}
        />
        <Control
          Component={TextInput}
          name="annotation"
          label="Remarks"
          error={({ value }) => {
            if (!value) {
              return true;
            }
            if (value.length < 4) {
              return 'Remarks must be at least 4 characters';
            }

            if (value.length > 60) {
              return 'Remarks cannot be more than 60 characters';
            }
            return false;
          }}
        />
      </Form>
    </BasicDialog>
  );
};

ReassignLogDutyStatusEventDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  driverKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  row: PropTypes.shape({
    key: PropTypes.string.isRequired,
    remarks: PropTypes.string,
    geoLocation: PropTypes.string,
    end: PropTypes.string,
    dutyStatus: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }),
  log: customPropTypes.detailedDriverLog.isRequired,
};
