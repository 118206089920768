import { connect } from 'react-redux';

import { TripRouteReport } from './TripRouteReport';
import { selectTrip, selectVehiclesBreadcrumbs, fetchTrip } from '../../../../data/reports';

export const TripRouteReportContainer = connect(
  (state, { tripId }) => ({
    trip: selectTrip(state, { id: tripId }),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
  }),
  { fetchTrip }
)(TripRouteReport);
