import { omit, isNil, filter } from 'lodash';
import moment from 'moment-timezone';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectApiKeysFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchApiKeys = makeRestApiAction({
  service: 'apiKeys',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchApiKeys,
  notificationsItemDescriptor: 'API keys',
});

export const fetchApiKeysOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectApiKeysFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchApiKeys,
  baseActionType: ACTION_TYPE.fetchApiKeys,
});

export const deleteApiKey = makeRestApiAction({
  service: 'apiKeys',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteApiKey,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'API key',
});

export const createApiKey = makeRestApiAction({
  service: 'apiKeys',
  method: 'post',
  baseActionType: ACTION_TYPE.createApiKey,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => ({
    ...actionParam,
    ...responseData,
    creationDate: moment(),
  }),
  notificationsItemDescriptor: 'API key',
  throwApiErrors: true,
});
