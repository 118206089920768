import { combineReducers } from 'redux';

import { breadcrumbsReducer as breadcrumbs } from './breadcrumbsReducer';
import { driverLogsReducer as driverLogs } from './driverLogsReducer';
import { exceptionsReducer as exceptions } from './exceptionsReducer';
import { fuelTaxesReducer as fuelTaxes } from './fuel-tax/reducer';
import { fuelConsumptionsReducer as fuelConsumptions } from './fuelConsumptionsReducer';
import { fuellingsReducer as fuellings } from './fuellingsReducer';
import { fuelTaxMileagesReducer as fuelTaxMileages } from './fuelTaxMileagesReducer';
import { geozonesReducer as geozones } from './geozonesReducer';
import { inspectionsReducer as inspections } from './inspectionsReducer';
import { performancesReducer as performances } from './performancesReducer';
import { reportSchedulesReducer as reportSchedules } from './reportSchedulesReducer';
import { speedViolationsReducer as speedViolations } from './speedViolationsReducer';
import { reportViewsReducer as reportViews } from './reportViewsReducer';
import { shiftsReducer as shifts } from './shiftsReducer';
import { tripsReducer as trips } from './tripsReducer';
import { unidentifiedLogsReducer as unidentifiedLogs } from './unidentifiedLogsReducer';
import { videoEventsReducer as videoEvents } from './videoEventsReducer';

export const reportsReducer = combineReducers({
  breadcrumbs,
  driverLogs,
  exceptions,
  fuelConsumptions,
  fuellings,
  fuelTaxes,
  fuelTaxMileages,
  geozones,
  inspections,
  performances,
  reportSchedules,
  speedViolations,
  reportViews,
  shifts,
  trips,
  unidentifiedLogs,
  videoEvents,
});
