import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card } from 'stti-react-common';

import { InfoField } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { formatTimezone, timeToDecimal } from '../../../../../helpers/moment';
import {
  NA_VALUE,
  lastPartAfterSign,
  getRegulationNumber,
  formatBooleanValue,
  eldLatLonCodeFormatter,
  getDeferralStatus,
} from '../../../../../helpers/formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

export const DriverCard = ({ log = {}, params }) => {
  const { formatUnit } = useFormats();

  const {
    activeDiagnostics,
    activeMalfunctions,
    carrierDetails,
    coDrivers,
    currentInfo,
    cycleName,
    cycleOnDutyRemaining,
    cycleOnDutyTime,
    deferralStatus,
    deferralTime,
    driver,
    homeTerminalDetails,
    onDutyTotal,
    providerInfo,
    regulation,
    startedAt,
    timeZone,
    unidentifiedDriving,
    vehicleDetails,
  } = log;

  // ACTIVE DIAGNOSTICS handler ----------------------------------------------------------------------------
  const activeDiagnosticsValue =
    !activeDiagnostics || activeDiagnostics.length === 0
      ? '0'
      : `1 (Code${activeDiagnostics && activeDiagnostics.length > 1 ? 's' : ''} ${activeDiagnostics
          .map(({ code }) => code)
          .join(', ')})`;

  // ACTIVE MALFUNCTIONS handler ---------------------------------------------------------------------------
  const activeMalfunctionsValue =
    !activeMalfunctions || activeMalfunctions.length === 0
      ? '0'
      : `1 (Code${
          activeMalfunctions && activeMalfunctions.length > 1 ? 's' : ''
        } ${activeMalfunctions.map(({ code }) => code).join(', ')})`;

  // CARRIER DETAILS handler -------------------------------------------------------------------------------
  const { address: carrierAddress, name: carrierName } = carrierDetails || {};

  // CURRENT INFO handler ----------------------------------------------------------------------------------
  // We will prefer to show url params if they exist instead of what we have on currentInfo
  const {
    comment,
    lat,
    lon,
    geoLocation: paramGeo,
    latLonCode: paramLatLonCode,
    vehicleDistance: paramVehicleDistance,
    engineHours: paramEngineHours,
  } = params || {};

  const latParam = lat && !isNaN(parseFloat(lat)) && parseFloat(lat).toFixed(2);
  const lonParam = lon && !isNaN(parseFloat(lon)) && parseFloat(lon).toFixed(2);

  const {
    geoLocation: ciGeoLoc,
    location,
    latLonCode: ciLatLonCode,
    vehicleDistance,
    engineHours,
  } = currentInfo || {};

  const latLonCode = paramLatLonCode || ciLatLonCode;
  const latitude = latParam || (location && location.latitude.toFixed(2));
  const longitude = lonParam || (location && location.longitude.toFixed(2));
  const geoLocation = paramGeo || ciGeoLoc;
  const currentDistance = paramVehicleDistance || vehicleDistance;
  const totalDistance = currentDistance ? formatUnit('distanceEld', currentDistance) : NA_VALUE;
  const currentEngineHours = paramEngineHours || engineHours;
  const totalEngineHours = currentEngineHours
    ? timeToDecimal(formatUnit('duration', currentEngineHours))
    : NA_VALUE;

  const hasLocation = latitude && longitude;

  const isLocationGood = latLonCode === 'GOOD' || (latLonCode !== 'GOOD' && hasLocation);

  const latLong = hasLocation ? `\n (${latitude}, ${longitude})` : '';

  const currentGeo = geoLocation || '—';

  const currentLocation = `${currentGeo} ${
    isLocationGood ? latLong : `${geoLocation ? `\n` : ''} ${eldLatLonCodeFormatter(latLonCode)}`
  }`;

  // CYCLE NAME handler ---------------------------------------------------------------------------------
  const cycleNumber = cycleName === 'Cycle 2' ? 14 : 7;

  // CYCLE ON DUTY REMAINING handler --------------------------------------------------------------------
  const cycleOnDutyRemainingFormatted = formatUnit('duration', cycleOnDutyRemaining);

  // CYCLE ON DUTY TIME FORMATTED handler ---------------------------------------------------------------
  const cycleOnDutyTimeFormatted = formatUnit('duration', cycleOnDutyTime);

  // DEFERRAL TIME FORMATTED handler --------------------------------------------------------------------
  const deferralTimeFormatted = formatUnit('duration', deferralTime);

  // DRIVER handler -------------------------------------------------------------------------------------
  const driverLicenseState =
    driver && driver.driverLicenseIssuer ? lastPartAfterSign(driver.driverLicenseIssuer, ',') : '-';

  // HOME TERMINAL DETAILS handler ----------------------------------------------------------------------
  const { address: homeTerminalAddress } = homeTerminalDetails || {};

  // ON DUTY TOTAL handler ------------------------------------------------------------------------------
  const onDutyTotalFormatted = formatUnit('duration', onDutyTotal);

  // PROVIDER INFO handler ------------------------------------------------------------------------------
  const { identifier, provider, certificationId, authenticationValue } = providerInfo || {};

  // REGULATION INFO handler ----------------------------------------------------------------------------
  const operatingZone = getRegulationNumber(regulation);

  // VEHICLE DETAILS handler ----------------------------------------------------------------------------
  const powerUnitNumbers = vehicleDetails ? vehicleDetails.map(({ assetId }) => assetId) : [];
  const powerUnits = vehicleDetails ? vehicleDetails.map(({ vin }) => vin) : [];

  const startAndEndVehicleOdometers = vehicleDetails
    ? vehicleDetails.map(({ startVehicleOdometer, endVehicleOdometer }) => {
        if (!startVehicleOdometer && !endVehicleOdometer) {
          return '';
        }
        return `${formatUnit('distanceEld', startVehicleOdometer)} - ${formatUnit(
          'distanceEld',
          endVehicleOdometer
        )}`;
      })
    : [];

  const totalOdometers = vehicleDetails
    ? vehicleDetails.map(({ startVehicleOdometer, endVehicleOdometer }) => {
        const totalOdometer = endVehicleOdometer - startVehicleOdometer;
        if (totalOdometer < 0) return '-';
        return formatUnit('distanceEld', totalOdometer);
      })
    : [];

  const trailerNumbers = vehicleDetails
    ? vehicleDetails.map(({ trailers }) => trailers.join(', '))
    : [];

  return (
    <Card className="UsEldLogDetailReport__DriverCard" fullWidth>
      <h4>Driver&apos;s Daily Log</h4>
      <div className="UsEldLogDetailReport__infoFieldContainer">
        <InfoField
          name="rodsDate"
          label="Date of RODS (MM-DD-YY)"
          value={moment.tz(startedAt, timeZone).format('MM-DD-YY')}
          printOnly
        />
        <InfoField
          name="rodsDateTime"
          label="Day Starting Time (HH:MM:SS)"
          value={moment.tz(startedAt, timeZone).format('HH:mm:ss')}
          printOnly
        />
        <InfoField
          name="timeZone"
          label="UTC Time Zone Offset (HH:MM)"
          value={formatTimezone(moment.tz(startedAt, timeZone).format('Z'))}
          printOnly
        />
        <InfoField
          name="summary"
          label="Current Location (latitude, longitude)"
          value={currentLocation}
          printOnly
        />
        <InfoField name="comment" label="Output File Comment" value={comment} printOnly />
        <InfoField
          name="currentDateTime"
          label="Current Date and Time (MM-DD-YY HH:MM:SS)"
          value={moment().format('MM-DD-YY HH:mm:ss')}
          printOnly
        />
        <InfoField
          name="name"
          label="Driver Name (Last, First)"
          value={driver && `${driver.lastName}, ${driver.firstName}`}
          printOnly
        />
        <InfoField
          name="username"
          label="Driver ID (Username)"
          value={driver && driver.username}
          printOnly
        />
        <InfoField
          name="driverLicenseNumber"
          label="Exempt Driver Status (E: Exempt; 0: No)"
          value={formatBooleanValue(driver && driver.cdnEldExempt)}
          printOnly
        />
        <InfoField
          name="cdnEldExempt"
          label="Driver license (No and Jurisdiction)"
          value={
            driver &&
            driver.driverLicenseNumber &&
            `${driver.driverLicenseNumber} (${driverLicenseState})`
          }
          printOnly
        />
        <InfoField
          name="coDriverName"
          label="Co-Driver Name (Last, First)"
          value={
            coDrivers &&
            coDrivers.map((coDriver) => coDriver && `${coDriver.lastName}, ${coDriver.firstName}`)
          }
          printOnly
        />
        <InfoField
          name="coDriverId"
          label="Co-Driver ID (Username)"
          value={coDrivers && coDrivers.map(({ username }) => username)}
          printOnly
        />
        <InfoField
          name="powerUnitNumber"
          label="Power Unit Number (ID or License No & Jurisdiction)"
          value={powerUnitNumbers}
          joinSymbol={'\n'}
          printOnly
        />
        <InfoField
          name="vin"
          label="VIN (Power Unit)"
          value={powerUnits}
          joinSymbol={'\n'}
          printOnly
        />
        <InfoField
          name="totalVehicleDistance"
          label="Total Vehicle Distance (Start - End of the Day)"
          value={startAndEndVehicleOdometers}
          joinSymbol={'\n'}
          printOnly
        />
        <InfoField
          name="distanceToday"
          label="Distance today (km)"
          value={totalOdometers}
          joinSymbol={'\n'}
          printOnly
        />
        <InfoField
          name="totalDistance"
          label="Cur. Total Distance (km)"
          value={totalDistance}
          printOnly
        />
        <InfoField
          name="totalEngineHours"
          label="Cur. Total Engine Hours (hours)"
          value={totalEngineHours}
          printOnly
        />
        <InfoField
          name="trailerNumber"
          label="Trailer Number (ID or License No & Jurisdiction)"
          value={trailerNumbers}
          joinSymbol={'\n'}
          printOnly
        />
        <InfoField name="carrierName" label="Carrier Name" value={carrierName} printOnly />
        <InfoField
          name="homeTerminal"
          label="Home Terminal (Address)"
          value={homeTerminalAddress}
          printOnly
        />
        <InfoField
          name="principalPlaceOfBusiness"
          label="Principal Place of Business (Address)"
          value={carrierAddress}
          printOnly
        />
        <InfoField
          name="operatingZone"
          label="Operating Zone (1, 2 or 3)"
          value={operatingZone}
          printOnly
        />
        <InfoField name="cycleNumber" label="Cycle (7 or 14)" value={cycleNumber} printOnly />
        <InfoField
          name="totalHoursInWorkShift"
          label="Total Hours (in work shift)"
          value={onDutyTotalFormatted}
          printOnly
        />
        <InfoField
          name="totalHoursInCycle"
          label="Total Hours (in cycle)"
          value={cycleOnDutyTimeFormatted}
          printOnly
        />
        <InfoField
          name="remainingHoursInCycle"
          label="Remaining Hours (in cycle)"
          value={cycleOnDutyRemainingFormatted}
          printOnly
        />
        <InfoField
          name="offDutyTimeDeferral"
          label="Off-Duty Time Deferral (Status and time deferred) (0: none, 1: Day 1, 2: Day 2 - Time deferred: HH:MM)"
          value={`${getDeferralStatus(deferralStatus)} (${deferralTimeFormatted})`}
          printOnly
        />
        <InfoField
          name="dataDiagnosticStatus"
          label="Data Diagnostic Status (Status & Diagnostic Code)"
          value={activeDiagnosticsValue}
          printOnly
        />
        <InfoField
          name="unidentifiedDrivingRecord"
          label="Unidentified Driving Records (0: none, 1: active)"
          value={unidentifiedDriving ? 1 : 0}
          printOnly
        />
        <InfoField
          name="malfunctionStatus"
          label="Malfunction Status (Status & Malfunction Code)"
          value={activeMalfunctionsValue}
          printOnly
        />
        <InfoField name="eldIdentifier" label="ELD Identifier" value={identifier} printOnly />
        <InfoField name="eldProvider" label="ELD Provider" value={provider} printOnly />
        <InfoField
          name="eldCertificationId"
          label="ELD Certification ID"
          value={certificationId}
          printOnly
        />
        <InfoField
          name="eldAuthenticationValue"
          label="ELD Authentication Value"
          value={authenticationValue}
          printOnly
        />
      </div>
    </Card>
  );
};

DriverCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  params: PropTypes.object,
};
