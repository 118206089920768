import BetterPropTypes from 'better-prop-types';

const leg = BetterPropTypes.shape({
  consumedFuel: BetterPropTypes.number,
  distance: BetterPropTypes.number,
  idlingFuel: BetterPropTypes.number,
  idlingDuration: BetterPropTypes.number,
  consumption: BetterPropTypes.number,
});

export const fuelConsumption = BetterPropTypes.shape({
  id: BetterPropTypes.string.isOptionalButNotNull,
  vehicle: BetterPropTypes.shape({
    key: BetterPropTypes.string,
    name: BetterPropTypes.string,
  }).isOptionalButNotNull,
  loaded: leg,
  empty: leg,
  overall: leg,
  startedAt: BetterPropTypes.string.isOptionalButNotNull,
  endedAt: BetterPropTypes.string.isOptionalButNotNull,
  ouKey: BetterPropTypes.string.isOptionalButNotNull,
});

export const fuelConsumptions = BetterPropTypes.arrayOf(fuelConsumption);
