import { connect } from 'react-redux';

import { PerformanceRuleDetail } from './detail';

import { performanceRule } from '../../../../../data/administration/configurations';

export const PerformanceRuleDetailContainer = connect(
  (state, { ruleId }) => ({
    rule: performanceRule.selectors.selectRule(state, ruleId),
  }),
  {
    fetchRule: performanceRule.actions.fetchRuleOptimized,
    createRule: performanceRule.actions.createRule,
    updateRule: performanceRule.actions.updateRule,
    deleteRule: performanceRule.actions.deleteRule,
  }
)(PerformanceRuleDetail);
