import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    flex: 1,
    enableRowGroup: false,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
