import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, Form, TabularInput, TextInput, Button } from 'stti-react-common';
import { isEmpty, trim } from 'lodash';

import './AssignCameras.scss';

const checkArrayErrors = (arr) =>
  arr
    ?.map((item) => {
      if (
        !Object.prototype.hasOwnProperty.call(item, 'imei') ||
        isEmpty(trim(item.imei)) ||
        !Object.prototype.hasOwnProperty.call(item, 'assetTag') ||
        isEmpty(trim(item.assetTag))
      ) {
        return true;
      }
      return false;
    })
    .some((value) => value === true);

export const AssignCameras = ({ className = '', onClose, isOpen, postAddCameras, tenantKey }) => {
  const [requestResult, setRequestResult] = useState({ isOpen: false, data: null });
  const [loading, setLoading] = useState(false);
  const { useFormController, Control } = Form;
  const form = useFormController({ controls: { devices: [] } });
  const { controls, resetControls } = form;

  const hasTabularInputErrors = useMemo(
    () => checkArrayErrors(controls.devices),
    [controls.devices]
  );

  const handleKeyPress = (event) => {
    // If the user presses the "Enter" key
    if (event.key === 'Enter') {
      event.preventDefault(); // Cancel the default action
      if (!hasTabularInputErrors) {
        document.getElementById('addDeviceBtn').click(); // Trigger the button element with a click
      }
    }
  };

  useEffect(() => {
    // Add event listener to the window for key press
    window.addEventListener('keypress', handleKeyPress);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <>
      <BasicDialog
        title="Assign Devices"
        className={`AssignCamerasDialog ${className}`}
        maxWidth="lg"
        onOpen={resetControls}
        isOpen={isOpen}
        onClose={onClose}
        buttons={[
          { label: 'Cancel', onClick: () => onClose(), disabled: loading },
          {
            label: loading ? 'Loading...' : 'Assign',
            onClick: () => {
              const data = controls.devices.map((obj) =>
                Object.entries(obj).reduce(
                  (acc, [key, value]) => ({ ...acc, [key]: value.trim() }),
                  {}
                )
              );
              setLoading(true);
              postAddCameras({ data, tenantKey, provider: 'SURFSIGHT' }).then((response) => {
                setRequestResult({ isOpen: true, data: response });
                setLoading(false);
              });
            },
            disabled: hasTabularInputErrors || controls?.devices?.length === 0 || loading,
          },
        ]}
      >
        <>
          <p>Enter each device to be assigned to this tenant.</p>
          <Form form={form} className="CrudAdminItem__form">
            <div className="TabularInput__wrapper">
              <Control
                Component={TabularInput}
                renderHeader={({ addRow }) => (
                  <div className="TabularInput__title">
                    <h4>Devices</h4>
                    <Button
                      id="addDeviceBtn"
                      variant="outlined"
                      label="Add Devices"
                      onClick={() => addRow()}
                      disabled={hasTabularInputErrors || loading}
                    />
                  </div>
                )}
                renderRow={({ deleteRow }) => (
                  <>
                    <Control
                      className="TabularInput__Imei"
                      Component={TextInput}
                      name="imei"
                      label="IMEI"
                      error={({ value }) => {
                        if (isEmpty(trim(value))) {
                          return true;
                        }

                        if (trim(value).length <= 14 || trim(value).length > 15) {
                          return 'Imei need to have 15 digits';
                        }

                        return false;
                      }}
                      disabled={loading}
                    />
                    <Control
                      className="TabularInput__assetTag"
                      Component={TextInput}
                      name="assetTag"
                      label="Asset Tag"
                      error={({ value }) => isEmpty(trim(value))}
                      disabled={loading}
                    />
                    <Button
                      variant="outlined"
                      label="Delete Row"
                      icon="clear"
                      onClick={() => deleteRow()}
                      disabled={loading}
                    />
                  </>
                )}
                name="devices"
                ownErrorName="devicesTable"
              />
            </div>
          </Form>
        </>
      </BasicDialog>
      <BasicDialog
        title="Assign Devices Results"
        className={`AssignResultsDialog ${className}`}
        maxWidth="lg"
        width="900px"
        isOpen={requestResult.isOpen}
        onClose={onClose}
        buttons={[
          {
            label: 'Close',
            onClick: () => {
              setRequestResult({ isOpen: false, data: null });
              onClose();
            },
          },
        ]}
      >
        {requestResult?.data?.accepted?.length > 0 && (
          <>
            <h4>Accepted Devices</h4>
            <table className="acceptedDevices">
              <thead>
                <tr>
                  <td>IMEI</td>
                </tr>
              </thead>

              <tbody>
                {requestResult?.data?.accepted.map((item) => (
                  <tr>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {requestResult?.data?.rejected?.length > 0 && (
          <>
            <h4>Rejected Devices</h4>
            <table className="rejectedDevices">
              <thead>
                <tr>
                  <td>IMEI</td>
                  <td>Asset Tag</td>
                  <td>Reason</td>
                </tr>
              </thead>

              <tbody>
                {requestResult?.data?.rejected.map((item) => (
                  <tr>
                    <td>{item.imei}</td>
                    <td>{item.assetTag}</td>
                    <td>{item.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </BasicDialog>
    </>
  );
};

AssignCameras.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  postAddCameras: PropTypes.func.isRequired,
  tenantKey: PropTypes.string.isRequired,
};
