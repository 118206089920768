import { connect } from 'react-redux';

import {
  fetchReportView,
  fetchDefaultReportViewsByReportType,
  createTemporaryDefaultReportView,
  selectReportViews,
  fetchLocalReportView,
} from '../../../../data/reports';

import { ReportLoader } from './ReportLoader';

export const ReportLoaderContainer = connect(
  (state) => ({
    reportViews: selectReportViews(state),
  }),
  {
    fetchReportView,
    fetchLocalReportView,
    fetchDefaultReportViewsByReportType,
    createTemporaryDefaultReportView,
  }
)(ReportLoader);
