import BetterPropTypes from 'better-prop-types';

export const tablet = BetterPropTypes.shape({
  appVersion: BetterPropTypes.string.isOptionalButNotNull,
  _modifiedAt: BetterPropTypes.string,
  vgmFirmwareVersion: BetterPropTypes.string,
  lastSignIn: BetterPropTypes.string,
  ou: BetterPropTypes.string,
  apkEnvironmentName: BetterPropTypes.string,
  assetTag: BetterPropTypes.string,
  ecmCapture: BetterPropTypes.bool,
  ouName: BetterPropTypes.string,
  webviewVersion: BetterPropTypes.string,
  iccid: BetterPropTypes.string,
  logLevel: BetterPropTypes.string.isOptionalButNotNull,
  assetId: BetterPropTypes.string,
  imei: BetterPropTypes.string,
  model: BetterPropTypes.string.isOptionalButNotNull,
  asset: BetterPropTypes.string,
  key: BetterPropTypes.string.isOptionalButNotNull,
});

export const tablets = BetterPropTypes.arrayOf(tablet);
