import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  Button,
  ToggleInput,
  useBooleanState,
  Icon,
  SplitPane,
  DutyStatusChart,
} from 'stti-react-common';
import moment from 'moment-timezone';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { NaviMap } from '../../../commons/NaviMap';
import { PrintLayout } from '../../../commons/PrintLayout';

import {
  useReportController,
  ReportLoader,
  ReportProvider,
  ReportFragment,
  ReportControl,
  SectionPanel,
  GridPanelMenu,
  GridPanelSummary,
  ReportFilter,
  SignalWebclientViewReady,
  FetchBreadcrumbs,
  MapCell,
  FetchReportDetail,
  DateStepperInput,
} from '../../../commons/ReportsCommon';
import { symbols as commonSymbols } from '../../../commons/MapsCommon';
import { mutateRows } from '../../../../helpers/reports/standardFilters';
import { markRows } from '../../../../helpers/reports/displayFilters';
import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';
import { EventsDisplayOptions } from './EventsDisplayOptions';
import { AssignLogDutyStatusEventDialog } from './AssignLogDutyStatusEventDialog';
import { UnidentifiedLogControlsTablePrint } from './UnidentifiedLogControlsTablePrint';
import { UnidentifiedLogDutyStatusChart } from './UnidentifiedLogDutyStatusChart';

import { columnDefs } from './columnDefs';

import './UnidentifiedLogDetailReport.scss';

const { symbol } = GoogleMap;
const { StatusLine, SelectionArea } = DutyStatusChart;

const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';

const UNIDENTIFIED_DRIVER_COLOR = '#ff0000';
const OFF_DUTY_COLOR = '#669df6'; // matches symbols.drivingLine
// const SAME_DAY_DRIVER_COLORS = ['#00ff00', '#00ffff']; // TODO: add more colors
const symbols = {
  ...commonSymbols,
  unidentifiedDrivingLine: new symbol.Line({ color: UNIDENTIFIED_DRIVER_COLOR }),
  // sameDayDriverLines: SAME_DAY_DRIVER_COLORS.map(color => new symbol.Line({ color })),
};

const { useDataGridComponents } = ControlledDataGrid;

const { Route, RouteSection, Controls, ControlsPanel } = GoogleMap;

const defaultState = () => ({
  controls: {},
  dataGrid: ControlledDataGrid.createState({
    columnDefs,
    gridOptions: {
      defaultColDef: {
        sortable: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      },
      rowClassRules: {
        highlight: ({ data }) => data.isHighlighted,
      },
    },
  }),
});

const requestServices = [
  'unidentifiedLogs',
  'unidentifiedLogDetail',
  'driverLogDutyStatus',
  'locations',
  'locationTypes',
  'breadcrumbs',
  'refinedBreadcrumbs',
];

export const UnidentifiedLogDetailReport = ({
  unidentifiedLog,
  driverUsers,
  vehicleKey,
  date,
  route,
  reportViewId,
  breadcrumbsByVehicleKey,
  submitAssignLogDutyStatusEvents,
  user,
  fetchUnidentifiedLog,
  ...rest
}) => {
  const {
    dutyIntervals = [],
    // driverDetails = [],
    dutyStatusChanges = [],
    vehicle = {},
    timeZoneStatus,
  } = unidentifiedLog || {};
  const [isOptionsDialogOpen, openOptionsDialog, closeOptionsDialog] = useBooleanState();
  const [isAssignmentDialogOpen, openAssignmentDialog, closeAssignmentDialog] = useBooleanState();

  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'unidentifiedLogDetail',
    reportTypeName: unidentifiedLog
      ? `Unidentified Driving Log: ${vehicle.name}`
      : `Unidentified Driving Log`,
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: dutyIntervals,
      supplements: { breadcrumbsByVehicleKey },
      chain: ['filters', 'grid'],
    },
  });
  const { controls, setControl, filteredRows, dataGridController, reportType, getReport } = report;

  // SAME-DAY DRIVERS

  // const sameDayDrivers = driverDetails.map((driver, index) => ({
  //   ...driver,
  //   color: SAME_DAY_DRIVER_COLORS[index],
  // }));

  // DATE NAVIGATION

  const [dateInputValue, setDateInputValue] = useState(null);
  const todayDate = moment().format(NORMAL_DATE_FORMAT);
  const yearAgoDate = moment().subtract(12, 'months').format(NORMAL_DATE_FORMAT);
  const isDateValid = moment(date, NORMAL_DATE_FORMAT).format(NORMAL_DATE_FORMAT) === date;
  const isDateInFuture = isDateValid && date > todayDate;
  const isDateTooOld = isDateValid && date < yearAgoDate;
  const isDateAcceptable = isDateValid && !isDateInFuture && !isDateTooOld;

  useEffect(() => {
    if (!isDateValid) return;
    setDateInputValue(date);
  }, [isDateValid, date]);

  useEffect(() => {
    if (!dateInputValue || dateInputValue === date) return;
    route.open({
      vehicleKey,
      date: dateInputValue,
      reportViewId,
    });
  }, [dateInputValue]);

  // CHART INTERVALS

  const chartIntervals = useMemo(() => {
    const intervals = [];

    dutyIntervals.forEach((interval, index) => {
      const lastInterval = dutyIntervals[index - 1] || {};

      if (lastInterval.dutyStatus !== 'OFF_DUTY' && interval.dutyStatus === 'OFF_DUTY') {
        // push an extra interval (creating a zero-duration interval) but using the unidentified color so the connecting line is in that color
        intervals.push({ ...interval, color: UNIDENTIFIED_DRIVER_COLOR });
      }

      const color = interval.dutyStatus === 'OFF_DUTY' ? OFF_DUTY_COLOR : UNIDENTIFIED_DRIVER_COLOR;

      const dutyStatusRecord = (dutyStatusChanges.find(({ key }) => key === interval.key) || {});
      const geoLocation = dutyStatusRecord.location;
      const latitude = dutyStatusRecord.latitude;
      const longitude = dutyStatusRecord.longitude;

      intervals.push({ ...interval, color, geoLocation, latitude, longitude });
    });

    return intervals;
  }, [dutyIntervals]);

  // DATA GRID COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Mapper: ({ data }) => (
      <MapCell
        topLine={data.topLine && symbols[data.topLine]}
        bottomLine={data.bottomLine && symbols[data.bottomLine]}
        // icon={data.icon && symbols[data.icon]}
        onClick={(event) => handleSelect(data, event)}
        disabled={data.disabled}
      />
    ),
  });

  // MAP LINES AND SECTIONS

  const dayRoutePath = useMemo(() => {
    if (!unidentifiedLog) return [];
    return sliceBreadcrumbs(
      breadcrumbsByVehicleKey[vehicleKey],
      unidentifiedLog.startedAt,
      unidentifiedLog.endedAt
    );
  }, [unidentifiedLog && breadcrumbsByVehicleKey[vehicleKey]]);

  const sections = useMemo(() => {
    if (!unidentifiedLog) return [];
    return filteredRows.output.map((row) => {
      const { key, dutyStatus, startedAt, endedAt } = row;
      return {
        key,
        line: dutyStatus === 'OFF_DUTY' ? 'clearLine' : 'unidentifiedDrivingLine',
        path: sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startedAt, endedAt),
        startedAt,
        endedAt,
      };
    });
  }, [unidentifiedLog && breadcrumbsByVehicleKey[vehicleKey], filteredRows.output]);

  // SELECTION AND FOCUS

  // selection clear on vehicle/date change
  useEffect(() => {
    setControl('selectionStartAt', null);
    setControl('selectionEndAt', null);
  }, [date, vehicleKey]);

  // selection handler (chart, grid, and map)
  const handleSelect = useCallback((target, { shiftKey }) => {
    // target may be grid row, chart interval, or map line; may also be other map element which should be ignored
    const targetStartedAt = target.startedAt || target.fromDate;
    const targetEndedAt = target.endedAt || target.toDate;
    if (!targetStartedAt || !targetEndedAt) return;
    const isDataGridRow = !!target.topLine; // heuristic for data grid rows

    const { selectionStartAt } = getReport().controls;
    if (shiftKey && selectionStartAt && targetEndedAt > selectionStartAt) {
      setControl('selectionEndAt', targetEndedAt);
    } else {
      setControl('selectionStartAt', targetStartedAt);
      setControl('selectionEndAt', targetEndedAt);
    }
    if (!isDataGridRow) setDataGridScrollTargetId(target.key);
  }, []);

  // selectedKeys and canAssignSelected
  useEffect(() => {
    const selectedRows = filteredRows.output.filter(
      ({ startedAt, endedAt }) =>
        startedAt >= controls.selectionStartAt && endedAt <= controls.selectionEndAt
    );
    setControl(
      'selectedKeys',
      selectedRows.map(({ key }) => key)
    );
  }, [unidentifiedLog, controls.selectionStartAt, controls.selectionEndAt]); // cannot depend on filteredRows.output or creates cycle

  const canAssignSelected = useMemo(() => {
    const selectedRows = filteredRows.output.filter(({ key }) =>
      controls.selectedKeys.includes(key)
    );
    return selectedRows.length > 0 && selectedRows.every(({ canReassign }) => canReassign);
  }, [controls.selectedKeys]);

  // map highlight
  const highlightPath = useMemo(() => {
    const { selectionStartAt, selectionEndAt } = controls;

    if (!selectionStartAt || !selectionEndAt) return false;

    const targetPath = sliceBreadcrumbs(
      breadcrumbsByVehicleKey[vehicleKey],
      selectionStartAt,
      selectionEndAt
    );

    return targetPath.length > 1 && targetPath;
  }, [controls.selectionStartAt, controls.selectionEndAt, breadcrumbsByVehicleKey]);

  const [focus, setFocus] = useState();

  // initial map focus
  useEffect(() => {
    if (!dutyIntervals || dutyIntervals.length === 0) {
      setFocus(null);
      return;
    }
    const activityPoints = dutyIntervals
      .map(({ location }) => location)
      .filter((location) => location && location.latitude && location.longitude);

    if (activityPoints.length === 0) {
      const defaultPositions = [
        {
          lat: 53.726669,
          lng: -127.647621,
        },
        {
          lat: 52.621330848,
          lng: -59.684330596,
        },
      ];
      setFocus(defaultPositions);
      return;
    }
    setFocus(activityPoints);
  }, [dutyIntervals]);

  // map focus selected
  const focusSelectedElements = () => {
    if (!highlightPath || highlightPath.length === 0) return;
    setFocus(highlightPath);
  };

  // map autofocus
  useEffect(() => {
    if (controls.mapAutoFocus) focusSelectedElements();
  }, [controls.selectedKeys, controls.mapAutoFocus]);

  // chart selection area
  const chartSelection = useMemo(() => {
    const { selectionStartAt, selectionEndAt } = controls;
    if (selectionStartAt && selectionEndAt) {
      return {
        startDate: selectionStartAt,
        endDate: selectionEndAt,
      };
    }
    return undefined;
  }, [controls.selectionStartAt, controls.selectionEndAt]);

  // data grid scroll-to-row
  const [dataGridScrollTargetId, setDataGridScrollTargetId] = useState();
  useEffect(() => {
    if (!dataGridScrollTargetId) return;
    // in useEffect so as to not interrupt ag-Grid during re-render
    dataGridController.methods.scrollToRow(dataGridScrollTargetId);
  }, [dataGridScrollTargetId]);

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {isDateAcceptable && (
        <FetchReportDetail action={fetchUnidentifiedLog} args={{ vehicleKey, date }} />
      )}
      <SignalWebclientViewReady />
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <UnidentifiedLogControlsTablePrint date={date} vehicleName={vehicle.name} />
        {unidentifiedLog && (
          <UnidentifiedLogDutyStatusChart unidentifiedLog={unidentifiedLog}>
            <StatusLine intervals={chartIntervals} timeZoneStatus={timeZoneStatus} />
          </UnidentifiedLogDutyStatusChart>
        )}
        <ControlledDataTable
          controller={dataGridController}
          rows={filteredRows.output}
          rowIdProperty="key"
        />
      </PrintLayout>
      <ReportFilter // separate "off duty" from unidentified driving time, setting line
        filterConfig={{
          type: mutateRows,
          debugName: 'identifiedVsUnidentified',
          callback: ({ draft }) => {
            const line =
              draft.dutyStatus === 'OFF_DUTY' ? 'drivingLine' : 'unidentifiedDrivingLine';
            draft.topLine = line;
            draft.bottomLine = line;
          },
          group: 'filters',
        }}
      />
      <div className="UnidentifiedLogDetailReport">
        <ReportFragment.Header route={route} services={requestServices} />
        <div className="UnidentifiedLogDetailReport__staticPanel">
          <DateStepperInput
            value={dateInputValue}
            onChange={setDateInputValue}
            min={yearAgoDate}
            max={todayDate}
            error={
              (isDateInFuture && 'Cannot view log for future date') ||
              (isDateTooOld && 'Cannot view log older than one year') ||
              !dateInputValue
            }
          />
          {/* <ReportControl
            name="showSameDayDrivers"
            group="assignment"
            label={`Show Vehicle's Identified Drivers (${sameDayDrivers.length})`}
            render={renderProps => <ToggleInput {...renderProps} />}
            defaultValue
          /> */}
          <div className="UnidentifiedLogDetailReport__staticPanel__driverHints">
            <div>
              <Icon icon="stop" color={UNIDENTIFIED_DRIVER_COLOR} />
              Unidentified
            </div>
            {/* {controls.showSameDayDrivers &&
              sameDayDrivers.map(driver => (
                <div key={driver.key}>
                  <Icon icon="stop" color={driver.color} />
                  {driver.name}
                </div>
              ))} */}
          </div>
          <div className="UnidentifiedLogDetailReport__staticPanel__spacer" />
          <Button
            label="Assign Selected"
            disabled={!canAssignSelected}
            onClick={openAssignmentDialog}
          />
          <ReportControl
            name="selectionStartAt"
            unpersisted
            defaultValue=""
            label="Start"
            disabled={!unidentifiedLog}
            readOnly
            autoGridArea
          />
          <ReportControl
            name="selectionEndAt"
            unpersisted
            defaultValue=""
            disabled={!unidentifiedLog}
            readOnly
            label="End"
            autoGridArea
          />
          <ReportControl
            name="selectedKeys"
            group="dataGrid"
            defaultValue={[]}
            instant
            autoGridArea
            unpersisted
            filterConfig={{
              type: markRows,
              rowIdProperty: 'key',
              group: 'grid',
              property: 'isHighlighted',
            }}
          />
          {unidentifiedLog && (
            <AssignLogDutyStatusEventDialog
              isOpen={isAssignmentDialogOpen}
              onClose={closeAssignmentDialog}
              onConfirm={submitAssignLogDutyStatusEvents}
              driverUsers={driverUsers}
              unidentifiedLog={unidentifiedLog}
              dutyIntervalKeys={controls.selectedKeys || []}
              user={user}
            />
          )}
        </div>
        <SectionPanel name="dutyStatusPanel" title="Duty Status" renderSummaries={() => null}>
          {unidentifiedLog && (
            <UnidentifiedLogDutyStatusChart unidentifiedLog={unidentifiedLog}>
              <SelectionArea selection={chartSelection} />
              <StatusLine
                intervals={chartIntervals}
                onIntervalClick={handleSelect}
                timeZoneStatus={timeZoneStatus}
              />
            </UnidentifiedLogDutyStatusChart>
          )}
        </SectionPanel>
        <SectionPanel
          name="dataGridPanel"
          title="Log Events"
          renderSummaries={() => <GridPanelSummary />}
          summaryEnd={
            <GridPanelMenu
              panelTerm="Panel"
              extraOptionsBottom={[{ label: 'Options...', onClick: openOptionsDialog }]}
            />
          }
          defaultValue
        />
        <ReportControl
          name="panelsSplit"
          group="dataGrid"
          instant
          defaultValue={50}
          render={() => (
            <SplitPane
              split={controls.verticalSplit ? 'vertical' : 'horizontal'}
              value={controls.dataGridPanel ? controls.panelsSplit : 0}
              hideSplitter={!controls.dataGridPanel}
              onChange={(newValue) => setControl('panelsSplit', newValue)}
              firstPaneMinSize={150}
            >
              <ControlledDataGrid
                theme="balham"
                controller={dataGridController}
                components={dataGridComponents}
                rows={filteredRows.output}
                rowIdProperty="key"
                animateRows
              />
              {controls.mapPanel && (
                <>
                  {isDateAcceptable && unidentifiedLog && (
                    <FetchBreadcrumbs
                      startAt={unidentifiedLog.startedAt}
                      endAt={unidentifiedLog.endedAt}
                      vehicleKey={vehicleKey}
                    />
                  )}
                  <NaviMap
                    onSelect={handleSelect}
                    focus={focus}
                    hideGeozones={!controls.showGeozones}
                    printLayoutMode={reportType}
                    printLayoutSuppressMap={!controls.printMap}
                  >
                    <Controls controlPosition="topRight">
                      <ControlsPanel>
                        <ReportControl
                          name="mapAutoFocus"
                          group="map"
                          defaultValue
                          label="Auto-Focus"
                          render={(renderProps) => <ToggleInput {...renderProps} />}
                        />
                      </ControlsPanel>
                    </Controls>
                    <Route path={dayRoutePath} line={symbols.drivingLine} disableAutoFocus />
                    {sections.map((section) => {
                      const { key, line, path } = section;
                      return (
                        <RouteSection
                          key={key}
                          id={key}
                          path={path}
                          selection={section}
                          line={symbols[line]}
                          disableAutoFocus
                        />
                      );
                    })}
                    {highlightPath && (
                      <RouteSection
                        line={symbols.lineHighlight}
                        path={highlightPath}
                        disableAutoFocus
                      />
                    )}
                  </NaviMap>
                </>
              )}
            </SplitPane>
          )}
        />
      </div>
      <EventsDisplayOptions isOpen={isOptionsDialogOpen} onClose={closeOptionsDialog} />
    </ReportProvider>
  );
};

UnidentifiedLogDetailReport.propTypes = {
  unidentifiedLog: customPropTypes.unidentifiedLog,
  driverUsers: customPropTypes.users.isRequired,
  vehicleKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object,
  submitAssignLogDutyStatusEvents: PropTypes.func.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  fetchUnidentifiedLog: PropTypes.func.isRequired,
};
