import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectCompanionAppsFetchRequests } from './selectors';

const base64ToBinary = (url) => {
  const pos = url.indexOf(';base64,');
  const type = url.substring(5, pos);
  const base64 = url.substr(pos + 8);
  const dataContent = atob(base64);
  const buffer = new ArrayBuffer(dataContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < dataContent.length; n += 1) {
    view[n] = dataContent.charCodeAt(n);
  }

  return new Blob([view], { type });
};

function convertToFormData({ apk, ...rest }) {
  const form = new FormData();
  form.append('_type', 'ModifyCompanionApp');

  if (apk) form.append('apk', base64ToBinary(apk));

  Object.keys(rest).forEach((key) => {
    form.append(key, rest[key]);
  });

  return form;
}

export const fetchCompanionApps = makeRestApiAction({
  service: 'companionApps',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCompanionApps,
  notificationsItemDescriptor: 'companion apps',
});

export const fetchCompanionAppsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectCompanionAppsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchCompanionApps,
  baseActionType: ACTION_TYPE.fetchCompanionApps,
});

const fetchCompanionAppsByTenantId = makeRestApiAction({
  service: 'tenantCompanionApps',
  method: 'get',
  getId: (tenantKey) => tenantKey,
  baseActionType: ACTION_TYPE.fetchCompanionAppsByTenantId,
  notificationsItemDescriptor: 'companion apps',
});

export const fetchCompanionAppsByTenantIdOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectCompanionAppsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchCompanionAppsByTenantId,
  baseActionType: ACTION_TYPE.fetchCompanionAppsByTenantId,
  fetchActionConfig: {
    service: 'tenantCompanionApps',
    method: 'get',
    baseActionType: ACTION_TYPE.fetchCompanionAppsByTenantId,
    notificationsItemDescriptor: 'companion apps',
  },
});

export const updateCompanionApp = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.updateCompanionApp,
  transformOutput: (responseData, actionParam) => actionParam,
  transformInput: ({
    tenants, // drop tenants (injected by selector) and only write tenantKeys
    available, // drop available
    ...rest
  }) => convertToFormData(rest),
  notificationsItemDescriptor: 'companion app',
  throwApiErrors: true,
});

export const createCompanionApp = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.createCompanionApp,
  transformInput: convertToFormData,
  transformOutput: (responseData, actionParam) => ({
    ...actionParam,
    key: actionParam.name.replace(/ /g, '-'),
    available: true,
  }),
  notificationsItemDescriptor: 'companion app',
  throwApiErrors: true,
});

export const deleteCompanionApp = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.deleteCompanionApp,
  transformOutput: (responseData, actionParam) => actionParam,
  transformInput: (app) => ({
    _type: `RemoveCompanionApp`,
    key: app.key,
  }),
  notificationsItemDescriptor: 'companion app',
});
