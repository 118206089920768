import { connect } from 'react-redux';

import { InspectionTemplateSummary } from './summary';

import { inspectionTemplate } from '../../../../../data/administration/configurations';

export const InspectionTemplateSummaryContainer = connect(
  (state) => ({
    inspectionTemplates: inspectionTemplate.selectors.selectInspectionTemplates(state),
  }),
  {
    fetchInspectionTemplates: inspectionTemplate.actions.fetchInspectionTemplatesOptimized,
    deleteInspectionTemplate: inspectionTemplate.actions.deleteInspectionTemplate,
    createInspectionTemplate: inspectionTemplate.actions.createInspectionTemplate,
    updateInspectionTemplate: inspectionTemplate.actions.updateInspectionTemplate,
  }
)(InspectionTemplateSummary);
