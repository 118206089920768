import React, { useEffect, useState } from 'react';
import { Chip, GoogleMap } from 'stti-react-common';

import { InfoField, SectionPanel } from '../../../commons/ReportsCommon';
import { symbols } from '../../../commons/MapsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { customPropTypes } from '../../../../helpers/customPropTypes';

const { Marker } = GoogleMap;

export const LocationPanel = ({ inspection }) => {
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    if (inspection) {
      setFocus(inspection.location);
    }
  }, [inspection]);

  const { locationName, alternativeLocationName } = inspection || {};

  return (
    <SectionPanel
      className="InspectionDetailReport__LocationPanel"
      name="locationPanel"
      title="Map"
      renderSummaries={() => {
        // if we are in a geozone we prioritize alternativeLocationName over location
        if (!inspection) {
          return null;
        }
        if (alternativeLocationName) {
          return <Chip label={`Geozone: ${alternativeLocationName}`} />;
        }
        if (locationName) {
          return <Chip label={`Location: ${locationName}`} />;
        }
        return <Chip label="Location: N/A" />;
      }}
    >
      <div className="InspectionDetailReport__LocationPanel__infoContainer">
        <InfoField name="location" label="Location" value={locationName} />
        <InfoField name="geozone" label="Geozone" value={alternativeLocationName} />
      </div>
      <NaviMap focus={focus}>
        {inspection && inspection.location && (
          <Marker position={inspection.location} icon={symbols.inspectionIcon} />
        )}
      </NaviMap>
    </SectionPanel>
  );
};

LocationPanel.propTypes = {
  inspection: customPropTypes.inspection,
};
