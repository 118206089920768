import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Name',
    sort: 'asc',
  }),
  columnDef.speedColumn({
    field: 'speedLimit',
    headerName: 'Speed Limit',
    cellRenderer: 'SpeedLimit',
  }),
  columnDef.durationColumn({
    field: 'gracePeriod',
    headerName: 'Grace Period',
    cellRenderer: 'GracePeriod',
    overrideFormat: 'h:mm:ss',
  }),
  columnDef.speedColumn({
    field: 'defaultSpeedLimit',
    headerName: 'Speed Limit (Default)',
  }),
  columnDef.durationColumn({
    field: 'defaultGracePeriod',
    headerName: 'Grace Period (Default)',
    overrideFormat: 'h:mm:ss',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
