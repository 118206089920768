import { find, isString, times } from 'lodash';
import moment from 'moment-timezone';

export const objectsToSelectOptions = (list, labelProperty, valueProperty, hasBlankOption) => {
  if (!list) return undefined;

  let listCopy = [...list];

  if (hasBlankOption) {
    listCopy = listCopy.concat({});
  }

  return listCopy
    .map((item) => ({
      label: (item && item[labelProperty]) || null,
      value: (item && item[valueProperty]) || null,
    }))
    .filter(({ label, value }) => label && value);
};

export const getStatesByCountry = (countries, countryCode) => {
  if (!countries) return undefined;

  const listOfCountryStates = find(countries, { code: countryCode });

  return listOfCountryStates ? listOfCountryStates.states : [];
};

export const hasRole = (role, type) => {
  const roleToString = isString(role) ? role : role.key;
  return roleToString.includes(type);
};

export const findTenantOU = (ous, membershipOuKey) =>
  ous.find(({ key, scope }) => key === membershipOuKey && scope === 'Tenant');

export const hoursOptions = times(24, (h) => ({
  value: h,
  label: moment().startOf('day').add(h, 'hours').format('h:mm A'),
}));

export const orientationOptions = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];
