import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ToggleInput, SelectInput, Form, ProgressOverlay } from 'stti-react-common';
import { uniq } from 'lodash';

const { Control, useForm } = Form;

export const VideoOptions = ({
  defaultValues,
  videoEventTypes,
  videoEventBehaviors,
  fetchVideoEventTypes,
  fetchVideoEventBehaviors,
  provider = 'default',
}) => {
  const [loading, setLoading] = useState(true);
  const {
    controls: { videoAny },
  } = useForm();

  useEffect(() => {
    Promise.all([
      fetchVideoEventTypes('surfsight'),
      fetchVideoEventTypes('lytx'),
      fetchVideoEventTypes(), // This will be removed when BE only allows videoEvent by Provider
      fetchVideoEventBehaviors(),
    ])
      .then()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const videoEventList = useMemo(() => {
    // lets merge defaultProvider events with Lytx provider events if provider is lytx
    // this is temporary code until we don´t need tenant.enableLytx flag anymore
    if (provider === 'lytx') {
      return uniq((videoEventTypes.find(({ key }) => key !== 'surfsight') || {}).events);
    }
    return (videoEventTypes.find(({ key }) => key === provider) || {}).events;
  }, [provider, videoEventTypes]);

  return (
    <>
      <div className="Report__EditExceptionRuleDialog__RuleComponent">
        <Control
          Component={ToggleInput}
          name="videoAny"
          label="Any"
          defaultValue={defaultValues.videoAny}
          onSet={({ draft, value }) => {
            if (!value) return;
            draft.videoEventTypes = [];
            draft.videoEventBehaviors = [];
          }}
          disabled={loading}
        />
      </div>
      <div className="Report__EditExceptionRuleDialog__RuleComponent">
        <ProgressOverlay isOpen={!videoAny && videoEventTypes.length === 0} />
        <Control
          Component={SelectInput}
          name="videoEventTypes"
          label="Event Types"
          defaultValue={defaultValues.videoEventTypes || []}
          options={videoEventList}
          multiple
          multipleCollapseAt={3}
          multipleCollapseText="types"
          error={({ controls, value }) => {
            if (provider !== 'lytx' && provider !== 'default') {
              return (
                !controls.videoAny && value.length === 0 && 'Must have at least one event type'
              );
            }
            return (
              !controls.videoAny &&
              value.length === 0 &&
              controls.videoEventBehaviors &&
              controls.videoEventBehaviors.length === 0 &&
              'Must have at least one event type or behavior'
            );
          }}
          disabled={({ controls }) => controls.videoAny || videoEventTypes.length === 0}
        />
      </div>
      {(provider === 'lytx' || provider === 'default') && (
        <div className="Report__EditExceptionRuleDialog__RuleComponent">
          <ProgressOverlay isOpen={!videoAny && videoEventBehaviors.length === 0} />
          <Control
            Component={SelectInput}
            name="videoEventBehaviors"
            label="Behaviors"
            defaultValue={defaultValues.videoEventBehaviors || []}
            options={videoEventBehaviors}
            multiple
            multipleCollapseAt={3}
            multipleCollapseText="behaviors"
            error={({ controls, value }) =>
              !controls.videoAny &&
              value.length === 0 &&
              controls.videoEventTypes.length === 0 &&
              'Must have at least one event type or behavior'
            }
            disabled={({ controls }) => controls.videoAny || videoEventBehaviors.length === 0}
          />
        </div>
      )}
    </>
  );
};

VideoOptions.propTypes = {
  defaultValues: PropTypes.shape({
    videoAny: PropTypes.bool,
    videoEventTypes: PropTypes.arrayOf(PropTypes.string),
    videoEventBehaviors: PropTypes.arrayOf(PropTypes.string),
  }),
  videoEventTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  videoEventBehaviors: PropTypes.arrayOf(PropTypes.string),
  fetchVideoEventTypes: PropTypes.func.isRequired,
  fetchVideoEventBehaviors: PropTypes.func.isRequired,
  provider: PropTypes.string,
};
