import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, BasicDialog, ProgressOverlay } from 'stti-react-common';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { ScreenLayout } from '../../../commons/ScreenLayout';
import { PrintLayout } from '../../../commons/PrintLayout';
import { ColorChip } from '../../../commons/ColorChip';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { daysBetweenDates } from '../../../../helpers/moment';

import {
  UNREVIEWED_VIOLATION_STATUS,
  REVIEWED_VIOLATION_STATUS,
  NO_VIOLATION_STATUS,
} from '../../../../data/reports';

import { FiltersPanel } from './FiltersPanel';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import {
  useReportController,
  ReportProvider,
  LaunchReport,
  ReportLoader,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  fetchReportDataOnDemand,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  DetailReportPrintingLinks,
  DriverLogDataTransferMenu,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './DriverLogsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = (myLogsOnly) => ({
  controls: myLogsOnly ? { dateRangeMode: 'thisMonth' } : {},
  dataGrid: createState({ columnDefs: columnDefs(myLogsOnly) }),
});

export const DriverLogsSummaryReport = ({
  driverLogs,
  user,
  ous,
  myLogsOnly,
  reportViewId,
  route,
  driverLogDetailReportRoute,
  fetchDriverLogs,
  fetchMyDriverLogs,
  fetchDriverLogsReviewDetails,
  limits,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDriverLogsReviewDetails();
  }, []);

  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'driverLogsSummary',
    reportTypeName: myLogsOnly ? 'My Daily Logs' : 'Driver Log Summary Report',
    reportViewId,
    route,
    defaultState: defaultState(myLogsOnly),
    filtersConfig: {
      input: driverLogs,
      chain: ['query', 'filters'],
    },
  });
  const { debouncedControls, setControl, filteredRows, dataGridController, reportType } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    OpenDetail: ({
      data: {
        driver: { key: driverKey },
        logDate: date,
      },
    }) => (
      <LaunchReport
        onClick={() =>
          driverLogDetailReportRoute.open({ driverKey, date, reportViewId: 'default' })
        }
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Driver: ({ data, value }) => (
      <Clickable onClick={() => setControl('driverName', [data.driver.key])}>{value}</Clickable>
    ),
    Activity: {
      renderer: ({ value: { value, label } }) =>
        myLogsOnly ? (
          label
        ) : (
          <Clickable onClick={() => setControl('hasActivity', value)}>{label}</Clickable>
        ),
      groupRenderer: true,
    },
    ViolationStatus: {
      renderer: ({ value: { value, label, color } }) => (
        <ColorChip
          label={label}
          onClick={
            [UNREVIEWED_VIOLATION_STATUS, REVIEWED_VIOLATION_STATUS, NO_VIOLATION_STATUS].includes(
              value
            )
              ? () => {
                  setControl('violationStatus', value);
                }
              : undefined
          }
          color={color}
        />
      ),
      groupRenderer: true,
    },
    Standing: {
      renderer: ({ value: { label, color } }) => <ColorChip label={label} color={color} />,
      groupRenderer: true,
    },
    Vehicles: ({ data, value }) =>
      /* eslint-disable  react/jsx-indent */
      value && value.length > 0
        ? value.map((vehicle, index) => (
            <span key={vehicle}>
              {!!index && ', '}
              <Clickable onClick={() => setControl('vehicleNames', [data.vehicles[index].key])}>
                {vehicle}
              </Clickable>
            </span>
          ))
        : '—',
    Organizations: ({ data, value }) =>
      /* eslint-disable  react/jsx-indent */
      value && value.length > 0
        ? value.map((organization, index) => (
            <span key={organization}>
              {!!index && ', '}
              <Clickable onClick={() => setControl('organizationNames', [data.ous[index].key])}>
                {organization}
              </Clickable>
            </span>
          ))
        : '—',
    DriverActivity: {
      renderer: ({ value: { label, color } }) => <ColorChip label={label} color={color} />,
      groupRenderer: true,
    },
    Diagnostic: {
      renderer: ({ value: { value, label } }) =>
        myLogsOnly ? (
          label
        ) : (
          <Clickable onClick={() => setControl('diagnosticIndication', value)}>{label}</Clickable>
        ),
      groupRenderer: true,
    },
    Malfunction: {
      renderer: ({ value: { value, label } }) =>
        myLogsOnly ? (
          label
        ) : (
          <Clickable onClick={() => setControl('malfunctionIndication', value)}>{label}</Clickable>
        ),
      groupRenderer: true,
    },
    HealthCodes: ({ value }) =>
      value && value.length > 0 ? (
        <ul>
          {value.map((row) => (
            <li key={row}>{row}</li>
          ))}
        </ul>
      ) : (
        '—'
      ),
    Signed: {
      renderer: ({ value: { value, label } }) =>
        myLogsOnly ? (
          label
        ) : (
          <Clickable onClick={() => setControl('isSigned', value)}>{label}</Clickable>
        ),
      groupRenderer: true,
    },
  });

  // RENDER

  const { ouKeys, ouMode, startDate, endDate, ousSelected } = debouncedControls;

  useEffect(() => {
    dataGridController.methods.autoSizeColumns();
  }, [ouMode]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, [filteredRows.output]);

  useEffect(() => {
    const count = daysBetweenDates(startDate, endDate);

    if (!count || !ouMode || (ouMode === 'custom' && ousSelected.length === 0)) return;

    if (limits && limits.name && ouMode) {
      const { forAllOus } = limits;

      const shouldFetchOnDemand =
        (ouMode && ouMode === 'default') ||
        (ouMode && ouMode !== 'default' && count <= forAllOus) ||
        myLogsOnly;

      if (shouldFetchOnDemand) {
        setIsLoading(true);
        fetchReportDataOnDemand({
          fetchAction: myLogsOnly ? fetchMyDriverLogs : fetchDriverLogs,
          report,
          isOptimized: true,
          limits,
          ous,
        });
      }

      if (!shouldFetchOnDemand) {
        setIsOpen(true);
      }
    }
  }, [ouKeys, ouMode, startDate, endDate, ousSelected]);

  if (myLogsOnly && !user && !user.key) return null; // may be initial render where key not yet available

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <ProgressOverlay isOpen={isLoading} />

      <ScreenLayout customClass="DriverLogsSummaryReport">
        <ReportFragment.Header
          reportType="DriverLogsSummary"
          route={route}
          canIncludeDetailInSendReport
        >
          <DriverLogDataTransferMenu
            logs={filteredRows.dataGrid}
            disabled={filteredRows.dataGrid.length === 0}
            user={user}
          />
        </ReportFragment.Header>
        <QueryPanel
          queryFor="log"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2019-01-01"
          onlySelectedOrganization={myLogsOnly}
          disableFuture
        />
        {!myLogsOnly && <FiltersPanel rows={filteredRows.output} />}
        {!myLogsOnly && <AggregatesPanel rows={filteredRows.dataGrid} />}
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} rowIdProperty="key" />
      </ScreenLayout>

      <PrintLayout mode={reportType} inline>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable
          controller={dataGridController}
          rows={filteredRows.dataGrid}
          rowIdProperty="key"
        />
      </PrintLayout>

      <DetailReportPrintingLinks
        getDetailReportPath={({ driver: { key: driverKey }, logDate: date }) =>
          driverLogDetailReportRoute.makeTargetPath({
            driverKey,
            date,
            reportViewId: 'default',
          })
        }
      />
      <BasicDialog
        title="Query is too wide"
        maxWidth="lg"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div>
          <p>Only up to a maximum of {limits.forAllOus} days can be queried.</p>
          <p>Please refine your query parameters.</p>
        </div>
      </BasicDialog>
    </ReportProvider>
  );
};

DriverLogsSummaryReport.propTypes = {
  driverLogs: customPropTypes.driverLogs.isRequired,
  user: PropTypes.shape({
    key: PropTypes.string,
    email: PropTypes.string,
  }),
  ous: customPropTypes.organizations,
  myLogsOnly: PropTypes.bool,
  reportViewId: PropTypes.string.isRequired,
  driverLogDetailReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchDriverLogs: PropTypes.func.isRequired,
  fetchMyDriverLogs: PropTypes.func.isRequired,
  fetchDriverLogsReviewDetails: PropTypes.func.isRequired,
  limits: PropTypes.object,
};
