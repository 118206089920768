import React from 'react';

import { configureRoute } from '../../../config';
import { BasicRouteFrame } from './RouteFrame';
import { hyphenDelimitedTuple } from './encoding';
import { DRIVER_ROLE_KEY } from '../../../data/system/constants';
import { CanadianEldLogDetailReport } from '../../reports/DriverLogs/CanadianEldLogDetailReport';
import { UsEldLogDetailReport } from '../../reports/DriverLogs/UsEldLogDetailReport';

import { AppLogin } from '../AppLogin';

const loginRoute = configureRoute({
  name: 'Login',
  path: '/login',
  RouteFrame: BasicRouteFrame,
  render: (props) => <AppLogin {...props} />,
});

const backendCanadianEld = configureRoute({
  path: '/canadianEldLogs/:driverLog/:reportViewId',
  encoding: {
    driverLog: hyphenDelimitedTuple(['driverKey', 'date'], { compactDates: true }),
  },
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => <CanadianEldLogDetailReport {...props} />,
});

const backendUsEld = configureRoute({
  path: '/usEldLogs/:driverLog/:reportViewId',
  encoding: {
    driverLog: hyphenDelimitedTuple(['driverKey', 'date'], { compactDates: true }),
  },
  RouteFrame: BasicRouteFrame,
  permissions: [DRIVER_ROLE_KEY],
  render: (props) => <UsEldLogDetailReport {...props} />,
});

export const systemRoutes = [loginRoute, backendCanadianEld, backendUsEld];
