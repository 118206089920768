import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { VideoPlayer, Chip } from 'stti-react-common';

import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { selectVideoEventFileLocation } from '../../../../data/reports';
import {
  SectionPanel,
  InfoField,
  FetchReportDetail,
  useReport,
} from '../../../commons/ReportsCommon';

const syncVideos = (mediaEvents) => {
  const allVideos = mediaEvents.filter(({ fileType }) => fileType === 'video');

  if (allVideos.length < 1) return; // if length is 0 it means we don't have any video to play

  const videoEvents = [];

  allVideos.forEach((video, index) => {
    videoEvents[index] = document.getElementById(`video-${index}`);
  });

  videoEvents[0]?.addEventListener('play', () => {
    videoEvents.forEach((video, index) => {
      if (index > 0 && video) {
        video.play();
        // eslint-disable-next-line no-param-reassign
        video.volume = 0;
      }
    });
  });

  videoEvents[0]?.addEventListener('pause', () => {
    videoEvents.forEach((video, index) => {
      if (index > 0 && video) video.pause();
    });
  });

  videoEvents[0]?.addEventListener('seeking', () => {
    videoEvents.forEach((video, index) => {
      // eslint-disable-next-line no-param-reassign
      if (index > 0) video.currentTime = videoEvents[0].currentTime;
    });
  });

  videoEvents[0]?.addEventListener('seeked', () => {
    videoEvents.forEach((video, index) => {
      // eslint-disable-next-line no-param-reassign
      if (index > 0) video.currentTime = videoEvents[0].currentTime;
    });
  });
  videoEvents.forEach((video) =>
    video?.addEventListener('loadeddata', () => {
      if (videoEvents.every((item) => item.readyState >= 2)) {
        videoEvents[0].play();
      }
    })
  );
};

const renderDynamicVideoOrImageComponent = (mediaEvents, videoRef, getDaysToExpire) => (
  <div className="VideosWrapper">
    {mediaEvents.map(({ url, cameraName, fileType }, index) => (
      <div className="VideoContainer" key={index}>
        <h5>
          {getDaysToExpire() < 1 ? <span>{cameraName}</span> : <a href={url}>{cameraName}</a>}
        </h5>
        {fileType === 'video' && !isEmpty(url) && (
          <VideoPlayer id={`video-${index}`} src={url} videoRef={videoRef} maxHeight={500} />
        )}
        {fileType === 'snapshot' && !isEmpty(url) && (
          <img alt="snapshot" src={url} width="672.765625" height="378.4306640625" />
        )}
      </div>
    ))}
  </div>
);

export const VideoPanel = connect((state, { exception }) => {
  if (!exception) return { videoEventUrl: null };

  const {
    other: { fileLocations },
  } = exception;

  if (!fileLocations || isEmpty(fileLocations)) {
    return [];
  }

  const videoEventFileLocations = fileLocations
    .map(({ fileLocationId }) => selectVideoEventFileLocation(state, { id: fileLocationId }))
    .filter(Boolean);

  return { videoEventFileLocations };
}, null)(({ exception, videoEventFileLocations, fetchVideoAction }) => {
  if (!exception || !exception.other || !videoEventFileLocations) return '';

  const getDaysToExpire = () => 60 - moment().diff(moment(exception.startedAt), 'days');

  const {
    other: { provider, fileLocations },
  } = exception || {};

  const videoFileLocationIds = fileLocations.map(({ fileLocationId }) => fileLocationId);

  if (isEmpty(videoFileLocationIds)) return '';

  const {
    typeDescription,
    behaviorDescriptions = [],
    descriptions = [],
  } = (exception && exception.other) || {};

  const videoRef = useRef();

  const { controls } = useReport();

  useEffect(() => {
    if (controls.videoPanel || !videoRef.current) return;
    videoRef.current.pause();
  }, [controls.videoPanel, videoRef.current]);

  useEffect(
    () =>
      function cleanupListener() {
        window.removeEventListener('play', syncVideos);
        window.removeEventListener('pause', syncVideos);
        window.removeEventListener('seeking', syncVideos);
        window.removeEventListener('seeked', syncVideos);
        window.removeEventListener('loadeddata', syncVideos);
      },
    []
  );

  const mediaEvents = fileLocations.map(({ fileLocationId, ...rest }) => {
    const mappedEvent = {
      ...rest,
      fileType: provider.toLowerCase() === 'lytx' ? 'video' : rest.fileType,
      id: fileLocationId,
      provider: provider.toLowerCase(),
      url: (videoEventFileLocations.find(({ id }) => id === fileLocationId) || {}).url,
    };
    return mappedEvent;
  });

  const downloadUrls = mediaEvents.filter((mediaEv) => !isEmpty(mediaEv.url));

  if (downloadUrls.length > 0) {
    setTimeout(() => syncVideos(mediaEvents), 1000); // we need to give some time to videos to render
  }

  return (
    <>
      {videoFileLocationIds.map((locationId) => (
        <FetchReportDetail action={fetchVideoAction} args={locationId} />
      ))}
      <SectionPanel
        name="videoPanel"
        title="Camera Event"
        renderSummaries={() =>
          descriptions.map((description) => <Chip key={description} label={description} />)
        }
        defaultValue
      >
        <InfoField name="eventType" label="Type" value={typeDescription} />
        {provider === 'SURFSIGHT' && (
          <div className="VideoPanel__SurfSight">
            <div className="VideoPanel__Notes">
              <span>
                Note: Videos and Snapshots are stored for <b>60 days</b> from the event date.
              </span>
              {getDaysToExpire() > 0 && (
                <span>
                  There are <b>{getDaysToExpire()} days remaining to download</b>.
                </span>
              )}
            </div>
          </div>
        )}
        {provider !== 'SURFSIGHT' && (
          <InfoField name="behaviors" label="Behavior(s)" value={behaviorDescriptions} />
        )}
        {renderDynamicVideoOrImageComponent(mediaEvents, videoRef, getDaysToExpire)}
      </SectionPanel>
    </>
  );
});

VideoPanel.propTypes = {
  exception: PropTypes.shape({
    endedAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    duration: PropTypes.number,
    distance: PropTypes.number,
    driversNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    vehicle: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    other: PropTypes.shape({
      fileLocationId: PropTypes.string,
    }),
  }),
};
