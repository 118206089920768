import React from 'react';
import PropTypes from 'prop-types';

import './SectionPrint.scss';

export const SectionPrint = ({ flexRow, children }) => (
  <div className={`Report__SectionPrint ${flexRow ? 'Report__SectionPrint--flexRow' : ''}`}>
    {children}
  </div>
);

SectionPrint.propTypes = {
  flexRow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
