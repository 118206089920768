import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { GoogleMap } from 'stti-react-common';

import { useFormats } from '../Formats';

import './ActivityInfo.scss';

const { Info } = GoogleMap;

export const ActivityInfo = ({ activityTitle, detail, startedAt, endedAt, timeZone, duration }) => {
  const { formatUnit } = useFormats();

  const isInstant = startedAt === endedAt && !duration;

  return (
    <Info>
      <div className="Map__ActivityInfo">
        <div className="Map__ActivityInfo__title">{activityTitle}</div>
        <table className="Map__ActivityInfo__specs">
          <tbody>
            {isInstant ? (
              <tr>
                <th>At</th>
                <td>{formatUnit('date', moment(startedAt).tz(timeZone))}</td>
              </tr>
            ) : (
              <>
                <tr>
                  <th>From</th>
                  <td>{formatUnit('date', moment(startedAt).tz(timeZone))}</td>
                </tr>
                <tr>
                  <th>To</th>
                  <td>{formatUnit('date', moment(endedAt).tz(timeZone))}</td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td>{formatUnit('duration', duration)}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {detail && <div className="Map__ActivityInfo__detail">{detail}</div>}
      </div>
    </Info>
  );
};

ActivityInfo.propTypes = {
  activityTitle: PropTypes.string.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  duration: PropTypes.number,
  detail: PropTypes.any,
};
