import { useEffect } from 'react';
import moment from 'moment-timezone';

import { useReport } from './useReport';
import { analytics } from '../../../data/analytics';

export const DateRangeAnalytics = () => {
  const {
    controls: { dateRangeMode, startDate, endDate },
  } = useReport();

  useEffect(() => {
    const dateRangeDays = moment(endDate).diff(moment(startDate), 'days') + 1;
    if (dateRangeDays > 0)
      analytics.setValues({
        dateRangeMode,
        dateRangeDays,
      });
    return () => {
      analytics.unsetValues(['dateRangeMode', 'dateRangeDays']);
    };
  }, [dateRangeMode, startDate, endDate]);

  return null;
};
