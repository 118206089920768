import BetterPropTypes from 'better-prop-types';

export const reportSchedule = BetterPropTypes.shape({
  title: BetterPropTypes.string.isOptionalButNotNull,
  userKey: BetterPropTypes.string.isOptionalButNotNull,
  urls: BetterPropTypes.arrayOf(BetterPropTypes.string.isOptionalButNotNull).isOptionalButNotNull,
  toEmails: BetterPropTypes.arrayOf(BetterPropTypes.string.isOptionalButNotNull)
    .isOptionalButNotNull,
  includeDetail: BetterPropTypes.bool.isOptionalButNotNull,
  orientation: BetterPropTypes.oneOf(['portrait', 'landscape']),
  endAt: BetterPropTypes.string.isOptionalButNotNull,
  hour: BetterPropTypes.number.isOptionalButNotNull,
  tenantKey: BetterPropTypes.string.isOptionalButNotNull,
  timeZone: BetterPropTypes.string.isOptionalButNotNull,
  frequency: BetterPropTypes.oneOf(['daily']),
  intervalStart: BetterPropTypes.number.isOptionalButNotNull,
  emailSubject: BetterPropTypes.string, // currently only when fetched
  createdAt: BetterPropTypes.string, // only when fetched
  updatedAt: BetterPropTypes.string, // only when fetched
});

export const reportSchedules = BetterPropTypes.arrayOf(reportSchedule);
