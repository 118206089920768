import React from 'react';

import { ProgressIndicator } from 'stti-react-common';
import { uniqueId } from 'lodash';

import { SectionPanel, ControlGroupSummary } from '../../../commons/ReportsCommon';
import { customPropTypes } from '../../../../helpers/customPropTypes';

const unConvertFromMps = (value) => {
  if (!value) return '+';
  return isNaN(value) ? value : (value * 3.6).toFixed(0);
};

const unConvertUpperBoundsFromRpm = (value) => {
  if (!value) return '+';
  return value;
};

export const DrivingProfilePanel = ({ rows }) => {
  // Data is de-normalized so we get the 1st row to extract relevant aggregated data we need.
  const [first] = rows;

  // Here we check if the first row exists just to not break the SectionPanel's return.
  if (!first) return '';

  const { driverName, rangeData, vehicleName } = first;
  const types = ['Speed', 'RPM', 'Brake'];

  const showVehicleName = (name) => (name ? ` - Vehicle Name: ${name}` : '');

  return (
    <SectionPanel
      name="drivingProfilePanel"
      title={`Driving Profile - ${driverName}${showVehicleName(vehicleName)}`}
      renderSummaries={() => <ControlGroupSummary group="drivingProfile" />}
    >
      <div className="DrivingProfilePanel__panelContainer">
        {types.map((type) => (
          <table key={type}>
            <thead>
              <tr>
                <td colSpan="2">{type}</td>
                <td>Duration</td>
              </tr>
            </thead>
            <tbody>
              {rangeData[type].data.map((range) => (
                <tr key={uniqueId(range.bounds.lowerBound)}>
                  <td width={60}>
                    {type === 'RPM'
                      ? range.bounds.lowerBound
                      : unConvertFromMps(range.bounds.lowerBound)}
                  </td>
                  <td width={60}>
                    {type === 'RPM'
                      ? unConvertUpperBoundsFromRpm(range.bounds.upperBound)
                      : unConvertFromMps(range.bounds.upperBound)}
                  </td>
                  <td width={60}>
                    <span className="rangeDurationBarContainer">
                      <ProgressIndicator
                        type="bar"
                        value={(range.duration / rangeData[type].totalDuration || 0) * 100}
                      />
                    </span>
                    <span className="rangeDurationInfo">
                      {((range.duration / rangeData[type].totalDuration) * 100 || 0).toFixed(1)}%
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </SectionPanel>
  );
};

DrivingProfilePanel.propTypes = {
  rows: customPropTypes.performanceDetails,
};
