import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  Button,
  ToggleInput,
  useBooleanState,
  Icon,
  SplitPane,
} from 'stti-react-common';
import { filter, flatten, flattenDeep, find, isEmpty } from 'lodash';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { NaviMap } from '../../../commons/NaviMap';
import { PrintLayout } from '../../../commons/PrintLayout';

import {
  useReportController,
  ReportLoader,
  ReportProvider,
  ReportFragment,
  ReportControl,
  SectionPanel,
  GridPanelMenu,
  GridPanelSummary,
  GridGroupingControl,
  useGridGroupingToggleRow,
  ReportFilter,
  SignalWebclientViewReady,
  FetchReportDetail,
  FetchBreadcrumbs,
  FetchPlaceholder,
  ControlsTablePrint,
  SectionPrint,
  DateRangeAnalytics,
  ActivityCell,
  MapCell,
  ActivitiesDebug,
} from '../../../commons/ReportsCommon';
import { symbols, EventInfo, ActivityInfo } from '../../../commons/MapsCommon';
import { filterRowsByPredicate, mutateRows } from '../../../../helpers/reports/standardFilters';
import { markRows } from '../../../../helpers/reports/displayFilters';
import {
  groupActivityRows,
  mergeSameActivityRows,
  interpretActivityRows,
  insertLoggedOutRows,
} from '../../../../helpers/reports/activityFilters';
import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';
import { ActivitiesDisplayOptions } from './ActivitiesDisplayOptions';
import { AggregatesContent } from './AggregatesContent';
import { AggregatesPanel } from './AggregatesPanel';
import { DetailPanel } from './DetailPanel';

import { columnDefs } from './columnDefs';

import './ShiftDetailReport.scss';

const { useDataGridComponents } = ControlledDataGrid;

const { Route, RouteSection, Marker, Controls, ControlsPanel } = GoogleMap;

const defaultState = () => ({
  controls: {},
  dataGrid: ControlledDataGrid.createState({
    columnDefs,
    gridOptions: {
      defaultColDef: {
        sortable: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      },
      rowClassRules: {
        highlight: ({ data }) => data.isHighlighted,
      },
    },
  }),
});

const requestServices = [
  'shifts',
  'shiftActivities',
  'locations',
  'locationTypes',
  'breadcrumbs',
  'refinedBreadcrumbs',
];

export const ShiftDetailReport = ({
  activities,
  shift,
  shiftId,
  reportViewId,
  route,
  breadcrumbsByVehicleKey,
  fetchShifts,
  fetchShift,
  fetchShiftActivities,
  ou,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'driverShiftDetail',
    reportTypeName: 'Shift Detail Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: activities || [],
      supplements: { breadcrumbsByVehicleKey },
      chain: [
        'pre',
        'filters',
        'hideStops',
        'moveStops',
        'mergeSame',
        'unknownStops',
        'regroup',
        'map',
        'grid',
      ],
      disable:
        localStorage.getItem('showQaTools') && localStorage.getItem('QaTools.rawActivitiesMode'),
    },
  });
  const {
    controls,
    setControl,
    filteredRows,
    dataGridController,
    reportType,
    setDataGridFilteredRows,
  } = report;

  const [isOptionsDialogOpen, openOptionsDialog, closeOptionsDialog] = useBooleanState();

  const { timeZone } = shift || {};

  // ACTIVITIES AND TRIPS

  const trips = useMemo(() => {
    if (!shift || !activities) return [];

    return (
      shift.vehicles &&
      shift.vehicles.map(({ startedAt, endedAt, key: vehicleKey }) => {
        const tripBreadcrumbs = sliceBreadcrumbs(
          breadcrumbsByVehicleKey[vehicleKey],
          startedAt,
          endedAt
        );

        return {
          startedAt,
          endedAt,
          vehicleKey,
          path: tripBreadcrumbs,
        };
      })
    );
  }, [shift, activities, breadcrumbsByVehicleKey]);

  const speedSamples = useMemo(() => {
    const breadcrumbs = flattenDeep(
      trips &&
        trips.map((trip) =>
          filter(trip.path, (breadcrumb) => breadcrumb.eventType === 'SpeedSampled')
        )
    );
    return breadcrumbs;
  }, [trips]);

  const sections = useMemo(
    () => filter(filteredRows.map, ({ line, path }) => line && path && path.length > 1),
    [filteredRows.map]
  );
  const events = useMemo(
    () =>
      filter(filteredRows.map, ({ icon, latitude, longitude }) => icon && latitude && longitude),
    [filteredRows.map]
  );

  // GRID CELL COMPONENTS

  const toggleRowId = useGridGroupingToggleRow(report);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Mapper: ({ data }) => (
      <MapCell
        topLine={data.topLine && symbols[data.topLine]}
        bottomLine={data.bottomLine && symbols[data.bottomLine]}
        icon={data.icon && symbols[data.icon]}
        onClick={() => {
          setControl('selectedIds', [data.id]);
        }}
        disabled={data.disabled}
      />
    ),
    Activity: ({ data, value }) => {
      let type = 'solo';
      if (data.isParent && data.isExpanded) type = 'expanded';
      if (data.isParent && !data.isExpanded) type = 'collapsed';
      if (data.parentId) type = 'child';

      return <ActivityCell label={value} type={type} onToggle={() => toggleRowId(data.id)} />;
    },
    Blank: () => null,
  });

  // SELECTION AND FOCUS

  const [focus, setFocus] = useState();

  const [highlightSpots, highlightSections, showUnhighlightableWarning] = useMemo(() => {
    const { selectedIds } = controls;

    if (!selectedIds || selectedIds.length === 0) return [[], [], false];

    const selectedRows = filter(filteredRows.regroup, ({ id }) => selectedIds.includes(id));
    const targetSpots = [];
    const targetSections = [];

    selectedRows.forEach((row) => {
      const { vehicleKey, startedAt, endedAt } = row;

      targetSpots.push({
        ...row,
        path: [row, row],
      });

      if (row.path) {
        // map line exists and path already extracted
        targetSections.push(row);
      } else if (startedAt !== endedAt) {
        // no map line exists, extract one for highlight
        const path = sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startedAt, endedAt);
        if (path.length > 1) targetSections.push({ ...row, path });
      }
    });

    const targetUnhighlightable = targetSpots.length + targetSections.length === 0;

    return [targetSpots, targetSections, targetUnhighlightable];
  }, [controls.selectedIds, breadcrumbsByVehicleKey]);

  // focus handler
  const focusSelectedElements = () => {
    if (highlightSpots.length === 0 && highlightSections.length === 0) {
      // nothing to select
      return;
    }
    if (highlightSpots.length === 1 && highlightSections.length === 0) {
      // single point
      setFocus({ ...highlightSpots[0] }); // spread into new object to assure re-focus
    } else {
      // multiple points and/or paths
      const focusPoints = filter(
        [...highlightSpots, ...flatten(highlightSections.map(({ path }) => path))],
        ({ latitude, longitude }) => latitude && longitude
      );
      // if (focusPoints.length < 2) return; // cannot select
      setFocus(focusPoints);
    }
  };

  // auto-focus
  useEffect(() => {
    if (controls.mapAutoFocus) focusSelectedElements();
  }, [controls.selectedIds, controls.mapAutoFocus]);

  // set initial focus (should fire once)
  const isActivitiesComplete =
    shift &&
    activities &&
    activities.length > 0 &&
    shift.endedAt === (activities && activities[activities.length - 1].endedAt);

  useEffect(() => {
    if (isActivitiesComplete) {
      const activityPoints = filter(activities, ({ latitude, longitude }) => latitude && longitude);
      if (activityPoints.length === 0) return; // extreme case, essentially guarding for QA sparse data
      setFocus(activityPoints);
    }
  }, [isActivitiesComplete]);

  // MAP SELECTION

  const [dataGridScrollTargetId, setDataGridScrollTargetId] = useState();

  const handleMapSelect = (target) => {
    if (!target || !target.id) return; // might be a map element without selection prop or a geozone (which has no id)
    setControl('selectedIds', [target.id]);
    const visibleRow = find(filteredRows.output, { id: target.id }); // will be undefined when row not visible
    if (!visibleRow && target.parentId) toggleRowId(target.parentId); // toggle parent to make row visible
    setDataGridScrollTargetId(target.id);
  };

  useEffect(() => {
    if (!dataGridScrollTargetId) return;
    // in useEffect so as to not interrupt ag-Grid during re-render and to allow parent pre-expansion above
    dataGridController.methods.scrollToRow(dataGridScrollTargetId);
  }, [dataGridScrollTargetId]);

  useEffect(() => {
    if (activities && activities.length > 0 && isEmpty(shift) && ou && ou.key) {
      const [first, last] = activities;

      const { startedAt } = first;
      const { endedAt } = last;

      if (startedAt && endedAt) {
        const startDate = startedAt.substring(0, 10);
        const endDate = endedAt.substring(0, 10);

        if (startDate && endDate) {
          fetchShifts({
            startDate,
            endDate,
            ouKey: ou.key,
            timeZone: ou.timeZone,
            dateRangeFor: 'startedAt',
          });
        }
      }
    }
  }, [activities, shift, ou]);

  // RENDER

  if (!shiftId && isEmpty(shift)) return null; // may be initial render where key not yet available

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {shiftId && <FetchReportDetail action={fetchShift} args={shiftId} />}
      {!isActivitiesComplete && (
        <FetchReportDetail action={fetchShiftActivities} args={{ shiftId }} />
      )}
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <ReportFilter // mid-shift ShiftStarted to ShiftResumed
        filterConfig={{
          type: mutateRows,
          debugName: 'shiftStartedToShiftResumed',
          callback: ({ draft, index }) => {
            if (draft.activityType === 'ShiftStarted' && index !== 0) {
              draft.topActivityType = 'ShiftResumed';
              draft.activityType = 'ShiftResumed';
            }
          },
          group: 'pre',
        }}
      />
      <ReportFilter // insert LoggedOut after UserLoggedOut
        filterConfig={{
          type: insertLoggedOutRows,
          group: 'pre',
        }}
      />
      <ReportFilter // drop parents
        filterConfig={{
          type: filterRowsByPredicate,
          debugName: 'dropParents',
          test: ({ row }) => row.childCount === 0,
          group: 'pre',
        }}
      />
      <ReportFilter // detach former children
        filterConfig={{
          type: mutateRows,
          debugName: 'detachFormerChildren',
          callback: ({ draft }) => {
            draft.parentId = null;
          },
          group: 'pre',
        }}
      />
      <ReportFilter // intra-activity Driving to Moving
        filterConfig={{
          type: mutateRows,
          callback: ({ draft }) => {
            if (draft.activityType === 'Driving' && draft.topActivityType !== 'Driving')
              draft.activityType = 'Moving';
          },
          group: 'mergeSame',
        }}
      />
      <ReportFilter
        filterConfig={{
          type: mergeSameActivityRows,
          group: 'mergeSame',
        }}
      />
      <ReportFilter
        filterConfig={{
          type: groupActivityRows,
          group: 'regroup',
        }}
      />
      <ReportFilter
        filterConfig={{
          type: interpretActivityRows,
          group: 'regroup',
          getValues: ({ supplements, formats }) => ({
            breadcrumbsByVehicleKey: supplements.breadcrumbsByVehicleKey,
            formats,
          }),
        }}
      />
      <div className="ShiftDetailReport">
        <ReportFragment.Header
          reportType="driverShiftDetail"
          route={route}
          services={requestServices}
        />
        {!isEmpty(shift) && <DetailPanel shift={shift} />}
        <AggregatesPanel rows={filteredRows.dataGrid} shift={shift} />
        <SectionPanel
          name="dataGridPanel"
          title="Activities"
          renderSummaries={() => <GridPanelSummary />}
          summaryEnd={
            <GridPanelMenu
              panelTerm="Panel"
              extraOptionsBottom={[{ label: 'Options...', onClick: openOptionsDialog }]}
            />
          }
          defaultValue
        >
          {!isEmpty(shift) && !isEmpty(activities) && (
            <ActivitiesDebug type="shift" activitiesOf={shift} activities={activities} />
          )}
          <div className="ShiftDetailReport__dataGridControls">
            <GridGroupingControl filterGroup="grid" />
            <ReportControl
              name="selectedIds"
              group="dataGrid"
              defaultValue={[]}
              instant
              autoGridArea
              unpersisted
              filterConfig={{
                type: markRows,
                group: 'grid',
                property: 'isHighlighted',
              }}
            />
            <ActivitiesDisplayOptions isOpen={isOptionsDialogOpen} onClose={closeOptionsDialog} />
            <Button
              icon="visibility"
              onClick={openOptionsDialog}
              label="Options"
              style={{ gridArea: 'activitiesDisplayOptionsButton' }}
            />
          </div>
        </SectionPanel>
        {((trips && trips.length === 0) || !focus) && <FetchPlaceholder />}
        <ReportControl
          name="panelsSplit"
          group="dataGrid"
          instant
          defaultValue={50}
          render={() => (
            <SplitPane
              split={controls.verticalSplit ? 'vertical' : 'horizontal'}
              value={controls.dataGridPanel ? controls.panelsSplit : 0}
              hideSplitter={!controls.dataGridPanel}
              onChange={(newValue) => setControl('panelsSplit', newValue)}
              firstPaneMinSize={150}
            >
              <ReportFragment.DataGridPanel
                dataGridController={dataGridController}
                dataGridComponents={dataGridComponents}
                filteredRows={filteredRows}
                setDataGridFilteredRows={setDataGridFilteredRows}
                animateRows
              />
              {controls.mapPanel && (
                <NaviMap
                  onSelect={handleMapSelect}
                  focus={focus}
                  hideGeozones={!controls.showGeozones}
                  printLayoutMode={reportType}
                  printLayoutSuppressMap={!controls.printMap}
                >
                  {trips &&
                    trips.map(({ startedAt, endedAt, vehicleKey, path }) => (
                      <Fragment key={startedAt}>
                        <FetchBreadcrumbs
                          startAt={startedAt}
                          endAt={endedAt}
                          vehicleKey={vehicleKey}
                        />
                        <Route line={symbols.drivingLine} path={path} disableAutoFocus />
                      </Fragment>
                    ))}
                  <Controls controlPosition="topCenter">
                    <div className="ShiftDetailReport__NaviMap__unhighlightableWarning">
                      {showUnhighlightableWarning && (
                        <>
                          <Icon icon="warning" />
                          Selection cannot be shown on map due to missing location data
                        </>
                      )}
                    </div>
                  </Controls>
                  <Controls controlPosition="topRight">
                    <ControlsPanel>
                      <ReportControl
                        name="mapAutoFocus"
                        group="map"
                        defaultValue
                        label="Auto-Focus"
                        render={(renderProps) => <ToggleInput {...renderProps} />}
                      />
                    </ControlsPanel>
                  </Controls>
                  {controls.showSpeedSamples &&
                    speedSamples.map((breadcrumb) => (
                      <Marker
                        key={breadcrumb.sequence}
                        position={breadcrumb}
                        icon={symbols.speedSampleIcon}
                        rotation={breadcrumb.heading}
                        order={0}
                        minZoom
                      >
                        <EventInfo event={{ ...breadcrumb, timeZone }} />
                      </Marker>
                    ))}
                  {events.map((event) => {
                    if (event.icon === 'dotIcon')
                      return (
                        <Marker
                          key={event.id}
                          position={event}
                          selection={event}
                          icon={symbols[event.icon]}
                          disableAutoFocus
                          minZoom
                        />
                      );
                    const isSelected =
                      controls.selectedIds && controls.selectedIds.includes(event.id);
                    return (
                      <Marker
                        key={event.id}
                        position={event}
                        selection={event}
                        icon={symbols[event.icon]}
                        order={isSelected ? 10000 : event.order}
                        disableAutoFocus
                        animation={isSelected && 'bounce'}
                      >
                        <ActivityInfo {...event} />
                      </Marker>
                    );
                  })}
                  {sections.map((section) => {
                    const { id, line, path } = section;
                    return (
                      <RouteSection
                        key={id}
                        path={path}
                        selection={section}
                        line={symbols[line]}
                        disableAutoFocus
                      >
                        <ActivityInfo {...section} />
                      </RouteSection>
                    );
                  })}
                  {highlightSpots.map(({ id, path }) => (
                    <RouteSection
                      key={id}
                      line={symbols.markerHighlight}
                      path={path}
                      disableAutoFocus
                    />
                  ))}
                  {highlightSections.map(({ id, path }) => (
                    <RouteSection
                      key={id}
                      line={symbols.lineHighlight}
                      path={path}
                      disableAutoFocus
                    />
                  ))}
                </NaviMap>
              )}
            </SplitPane>
          )}
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header
          reportType="driverShiftDetail"
          route={route}
          services={requestServices}
        />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent shift={shift} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

ShiftDetailReport.propTypes = {
  shiftId: PropTypes.string.isRequired,
  shift: PropTypes.object,
  activities: PropTypes.array.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object,
  fetchShifts: PropTypes.func.isRequired,
  fetchShift: PropTypes.func.isRequired,
  fetchShiftActivities: PropTypes.func.isRequired,
  ou: PropTypes.object,
};
