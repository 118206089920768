import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import {
  BasicDialog,
  Form,
  TextInput,
  SelectInput,
  DutyStatusChart,
  Clickable,
  Icon,
} from 'stti-react-common';

import {
  getEndDateQuickOptions,
  updateIntervals,
  getChartEndDate,
  getStatusTotals,
  sanitizeDutyStatus,
} from '../../../../../helpers/reports/driverLogsUtils';

import { useFormats } from '../../../../commons/Formats';

const { useFormController, Control } = Form;
const { StatusLine } = DutyStatusChart;

const validFormat = 'YYYY-MM-DDTHH:mm';
const BREAK_REASON = 'Forgot to Enter Break';
const END_SHIFT_REASON = 'Forgot to End Shift';
const SLEEPER_BERTH_REASON = 'Forgot to Enter Sleeper Berth';
const OTHER_REASON = 'Other';

const dutyStatusOptions = ['Off Duty', 'Sleeper Berth', 'Driving', 'On Duty Not Driving'];

export const CreateLogDutyStatusEventDialog = ({
  isOpen,
  onClose,
  onConfirm,
  date,
  driverKey,
  user,
  log,
}) => {
  const { formatUnit } = useFormats();
  const form = useFormController({
    controls: {
      driverKey,
      key: `${date}-${driverKey}`,
    },
  });

  const { controls, hasErrors, isDirty, errors, setControl, resetControls } = form;
  const { logDate, timeZone, movementIntervals, timeZoneStatus } = log || {};

  const [intervals, setIntervals] = useState(movementIntervals || []);
  const [endDateOptions, setEndDateOptions] = useState([]);

  useEffect(() => {
    resetControls();
    setIntervals(movementIntervals || []);
  }, [isOpen]);

  useEffect(() => {
    if (
      controls.start &&
      controls.dutyStatus &&
      controls.reason &&
      !errors.start &&
      !errors.end &&
      !errors.dutyStatus &&
      !errors.reason
    ) {
      const updatedIntervals = updateIntervals(controls, log);

      // Now lets find the newly added interval and set property lastDutyStatus to be send in form submit
      const newIndex = updatedIntervals.findIndex((row) => !row.previousDutyStatus);
      const target = updatedIntervals[newIndex - 1];
      const targetStatus = target ? sanitizeDutyStatus(target.dutyStatus) : 'Off Duty';
      setControl('lastDutyStatus', newIndex === 0 ? 'Off Duty' : targetStatus);

      setIntervals(updatedIntervals);
    } else {
      setIntervals(movementIntervals || []);
    }
    if (controls.start) {
      setEndDateOptions(getEndDateQuickOptions(controls.start, log));
    }
  }, [controls, errors]);

  return (
    <BasicDialog
      className="DriverLogDetailReport__CreateLogDutyStatusEventDialog"
      isOpen={isOpen}
      onClose={onClose}
      title={`New Duty Status: ${date}`}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Submit Changes',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            onConfirm({
              ...controls,
              timeZone,
              reason: controls.reason === END_SHIFT_REASON ? END_SHIFT_REASON : OTHER_REASON,
              annotation: controls.remarks
                ? `*${controls.remarks} [${user.fullName}]`
                : `*${controls.reason} [${user.fullName}]`,
            });
            onClose();
          },
        },
      ]}
    >
      <Form form={form}>
        <span>
          These log edits will need to be approved by the driver before counting towards their Hours
          of Services
        </span>
        <DutyStatusChart
          startDate={moment.tz(logDate, timeZone).toISOString()}
          endDate={getChartEndDate(logDate, timeZone).toISOString()}
          intervals={intervals}
          statusTotals={{
            offDuty: formatUnit('duration', getStatusTotals(intervals, 'Off Duty')),
            sleeper: formatUnit('duration', getStatusTotals(intervals, 'Sleeper Berth')),
            driving: formatUnit('duration', getStatusTotals(intervals, 'Driving')),
            onDuty: formatUnit('duration', getStatusTotals(intervals, 'On Duty Not Driving')),
          }}
        >
          <StatusLine intervals={intervals} timeZoneStatus={timeZoneStatus} />
        </DutyStatusChart>
        <Control
          name="reason"
          Component={SelectInput}
          options={[BREAK_REASON, END_SHIFT_REASON, SLEEPER_BERTH_REASON, OTHER_REASON]}
          label="Reason"
          error={({ value }) => isDirty && !value}
          onSet={({ draft, value }) => {
            if (value === BREAK_REASON || value === END_SHIFT_REASON) {
              draft.dutyStatus = 'Off Duty';
            } else if (value === SLEEPER_BERTH_REASON) {
              draft.dutyStatus = 'Sleeper Berth';
            } else if (value === OTHER_REASON) {
              draft.dutyStatus = null;
            }
          }}
        />
        {controls.reason === OTHER_REASON && (
          <Control
            Component={TextInput}
            name="annotation"
            label="Remarks"
            error={({ value }) => {
              if (!value) {
                return true;
              }
              if (value.length < 4) {
                return 'Remarks must be at least 4 characters';
              }

              if (value.length > 60) {
                return 'Remarks cannot be more than 60 characters';
              }
              return false;
            }}
          />
        )}
        <Control
          Component={SelectInput}
          name="dutyStatus"
          readOnly={controls.reason !== OTHER_REASON}
          options={dutyStatusOptions}
          label="Status"
          error={({ value }) => controls.reason === OTHER_REASON && !value}
        />
        {date && (
          <Control
            Component={TextInput}
            name="start"
            label="Start Time"
            type="datetime-local"
            defaultValue={moment(date).startOf('day').format(validFormat)}
            error={({ value }) => {
              if (isEmpty(value)) {
                return true;
              }

              if (controls.end && moment(controls.end).isSameOrBefore(moment(value))) {
                return 'Start Time must be before End Time';
              }

              if (
                moment.tz(value, timeZone).isSameOrAfter(moment.tz(logDate, timeZone).endOf('day'))
              ) {
                return `Start Time must be same or before ${formatUnit(
                  'date',
                  moment(logDate).tz(timeZone).endOf('day')
                )}`;
              }

              if (
                moment.tz(value, timeZone).isBefore(moment.tz(logDate, timeZone).startOf('day'))
              ) {
                return `Start Time must be after ${formatUnit(
                  'date',
                  moment(logDate).tz(timeZone)
                )}`;
              }

              if (!moment(value).isValid() || value.length !== validFormat.length) {
                return `Invalid date/time entered, time must be formatted as ${validFormat}`;
              }

              return false;
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {[BREAK_REASON, SLEEPER_BERTH_REASON, OTHER_REASON].includes(controls.reason) && (
          <>
            <Control
              Component={TextInput}
              name="end"
              label="End Time (optional)"
              type="datetime-local"
              error={({ value }) => {
                if (
                  value &&
                  (moment
                    .tz(value, timeZone)
                    .isBefore(moment.tz(logDate, timeZone).startOf('day')) ||
                    moment.tz(value, timeZone).isAfter(getChartEndDate(logDate, timeZone)))
                ) {
                  return `End Time must be same or before ${formatUnit(
                    'date',
                    moment(getChartEndDate(logDate, timeZone))
                  )}`;
                }

                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="CreateLogDutyStatusEventDialog__endTimes">
              <Icon icon="query_builder" />
              {endDateOptions.map((option) => (
                <Clickable key={option.label} onClick={() => setControl('end', option.value)}>
                  {option.label}
                </Clickable>
              ))}
            </div>
          </>
        )}
        <Control hidden Component={TextInput} name="lastDutyStatus" />
      </Form>
    </BasicDialog>
  );
};

CreateLogDutyStatusEventDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  driverKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }),
  log: PropTypes.object.isRequired,
};
