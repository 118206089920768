import { connect } from 'react-redux';

import { signIn, selectSystemUser, setMenu, selectSystemOus } from '../../../data/system';

import { AppLogin } from './AppLogin';

export const AppLoginContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    ous: selectSystemOus(state),
  }),
  { signIn, setMenu }
)(AppLogin);
