import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  countOf,
  countUniqueOfField,
  sumOfField,
  calculateMileage,
} from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();

  const sums = {};
  ['dieselDistance', 'gasDistance', 'lngDistance', 'addedDiesel', 'addedGas', 'addedLng'].forEach(
    (property) => {
      sums[property] = sumOfField(rows, property);
    }
  );

  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Vehicles',
            value: countUniqueOfField(rows, 'vehicle.name'),
          },
          {
            header: 'Refuels',
            value: countOf(rows),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', sumOfField(rows, 'duration')),
          },
        ]}
      />
      <AggregatesCard
        title="Est. Distance"
        name="distance"
        fields={[
          {
            header: 'Diesel',
            value: formatUnit('distance', sums.dieselDistance),
          },
          {
            header: 'Gasoline',
            value: formatUnit('distance', sums.gasDistance),
          },
          {
            header: 'LNG',
            value: formatUnit('distance', sums.lngDistance),
          },
        ]}
      />
      <AggregatesCard
        title="Fuel Added"
        name="addedFuel"
        fields={[
          {
            header: 'Diesel',
            value: formatUnit('volume', sums.addedDiesel),
          },
          {
            header: 'Gasoline',
            value: formatUnit('volume', sums.addedGas),
          },
          {
            header: 'LNG',
            value: formatUnit('weight', sums.addedLng),
          },
        ]}
      />
      <AggregatesCard
        title="Est. Performance"
        name="mileage"
        fields={[
          {
            header: 'Diesel',
            value: formatUnit(
              'mileage',
              calculateMileage(sums.dieselDistance, sums.addedDiesel, 'Diesel')
            ),
          },
          {
            header: 'Gasoline',
            value: formatUnit('mileage', calculateMileage(sums.gasDistance, sums.addedGas, 'Gas')),
          },
          {
            header: 'LNG',
            value: formatUnit('mileage', calculateMileage(sums.lngDistance, sums.addedLng, 'LNG')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
