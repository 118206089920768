import { connect } from 'react-redux';

import { SupportInvestigationReport } from './SupportInvestigationReport';
import { selectIsUserSuperAdmin } from '../../../data/system';
import {
  selectVehiclesBreadcrumbs,
  fetchBreadcrumbsOptimized,
  fetchRefinedBreadcrumbsOptimized,
} from '../../../data/reports';
import { vehicle } from '../../../data/administration/resources';

export const SupportInvestigationReportContainer = connect(
  (state) => ({
    vehicles: vehicle.selectors.selectAllVehicles(state),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
    isFeaturePermitted: selectIsUserSuperAdmin(state),
  }),
  {
    fetchVehicles: vehicle.actions.fetchVehiclesOptimized,
    fetchBreadcrumbs: fetchBreadcrumbsOptimized,
    fetchRefinedBreadcrumbs: fetchRefinedBreadcrumbsOptimized,
  }
)(SupportInvestigationReport);
