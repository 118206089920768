import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, BasicDialog, useBooleanState, TextInput, Form } from 'stti-react-common';

import {
  ControlsTablePrint,
  FetchReportDetail,
  ReportFilter,
  ReportFragment,
  ReportLoader,
  ReportProvider,
  SignalWebclientViewReady,
  useReportController,
} from '../../../commons/ReportsCommon';
import { DetailPanel } from './DetailPanel';
import { NotesPanel } from './NotesPanel';
import { LocationPanel } from './LocationPanel';
import { RelatedVehiclesPanel } from './RelatedVehiclesPanel';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { columnDefs } from './columnDefs';
import { ToggleChip } from '../../../commons/ToggleChip';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { PrintLayout } from '../../../commons/PrintLayout';
import { MECHANIC_ROLE_KEY, SUPER_ADMIN_ROLE_KEY } from '../../../../data/system/constants';

import './InspectionDetailReport.scss';

const requestServices = ['inspections'];

const defaultState = () => ({
  controls: { detailPanel: true },
  dataGrid: ControlledDataGrid.createState({ columnDefs }),
});

const { useFormController, Control } = Form;

export const InspectionDetailReport = ({
  fetchInspection,
  updateInspectionDefectAndNote,
  addInspectionNote,
  inspection,
  inspectionId,
  user,
  route,
  reportViewId,
}) => {
  const [targetRow, setTargetRow] = useState({});

  const inspectionDefects = useMemo(() => {
    if (inspection && inspection.defects && user) {
      const canEdit =
        user.memberships &&
        user.memberships.some((membership) =>
          membership.role.some(
            (role) => role.includes(MECHANIC_ROLE_KEY) || role.includes(SUPER_ADMIN_ROLE_KEY)
          )
        );
      return inspection.defects.map((defect) => ({
        ...defect,
        isMinor: !defect.isMajor,
        canEdit,
      }));
    }

    return [];
  }, [inspection, user]);

  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] =
    useBooleanState();

  const form = useFormController();

  const { controls } = form;

  const report = useReportController({
    reportType: 'inspectionDetail',
    reportTypeName: 'Inspection Detail',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: inspectionDefects,
      chain: ['query'],
    },
  });

  const { filteredRows, dataGridController, reportType } = report;

  const dataGridComponents = ControlledDataGrid.useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        {data.isRepaired || !data.canEdit ? (
          <Button
            className="InspectionDetailReport__button--markRepaired"
            disabled
            onClick={() => {
              openConfirmationDialog();
              setTargetRow(data);
            }}
            icon="construction"
          />
        ) : (
          <Button
            className="InspectionDetailReport__button--markRepaired"
            tooltip="Mark Repaired"
            onClick={() => {
              openConfirmationDialog();
              setTargetRow(data);
            }}
            icon="construction"
          />
        )}
        {!data.isRepaired || !data.canEdit ? (
          <Button
            className="InspectionDetailReport__button--markBroken"
            disabled
            onClick={() => {
              openConfirmationDialog();
              setTargetRow(data);
            }}
            icon="error"
          />
        ) : (
          <Button
            className="InspectionDetailReport__button--markBroken"
            tooltip="Mark Broken"
            onClick={() => {
              openConfirmationDialog();
              setTargetRow(data);
            }}
            icon="error"
          />
        )}
      </>
    ),
    RepairedToggleChip: ({ value }) => (
      <ToggleChip value={value} trueLabelText="Repaired" falseLabelText="Not Repaired" />
    ),
    SeverityToggleChip: ({ value }) => (
      <ToggleChip value={value} trueLabelText="Minor" falseLabelText="Major" />
    ),
  });

  return (
    <ReportProvider value={report}>
      <ReportFilter
        filterConfig={{
          group: 'query',
          type: filterRowsByPredicate,
          test: () => true,
        }}
      />
      <ReportLoader />
      <SignalWebclientViewReady />
      <FetchReportDetail action={fetchInspection} args={inspectionId} />
      <div className="InspectionDetailReport">
        <ReportFragment.Header
          reportType="inspectionDetail"
          route={route}
          services={requestServices}
        />
        <DetailPanel inspection={inspection} route={route} />
        <RelatedVehiclesPanel inspection={inspection} route={route} />
        <LocationPanel inspection={inspection} />
        <NotesPanel inspection={inspection} user={user} onConfirm={addInspectionNote} />
        <ReportFragment.DataGridPanel
          dataGridComponents={dataGridComponents}
          rowIdProperty="key"
          title="Defects"
          sizeColumnsToFit
        />
        <BasicDialog
          title={targetRow.isRepaired ? 'Defect Broken Confirmation' : 'Defect Repair Confirmation'}
          className="InspectionDetailReport__DefectDialog"
          isOpen={isConfirmationDialogOpen}
          buttons={[
            {
              label: 'Cancel',
              onClick: closeConfirmationDialog,
            },
            {
              label: targetRow.isRepaired ? 'Mark Broken' : 'Mark Repaired',
              onClick: () => {
                updateInspectionDefectAndNote({
                  key: inspection.id,
                  defectKey: targetRow.key,
                  user,
                  inspection,
                  newNote: controls.newNote,
                }).then(closeConfirmationDialog);
              },
            },
          ]}
          onClose={() => closeConfirmationDialog()}
        >
          <Form form={form}>
            <Control
              name="newNote"
              Component={TextInput}
              label="Add Mechanic's Note"
              defaultValue=""
              multiline
              autoFocus
            />
            <p>
              Are you sure you want to mark the defect as
              <span className="InspectionDetailReport__DefectDialog__display--item">
                {targetRow.isRepaired ? ' broken' : ' repaired'}
              </span>
            </p>
          </Form>
        </BasicDialog>
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

InspectionDetailReport.propTypes = {
  fetchInspection: PropTypes.func.isRequired,
  inspection: customPropTypes.inspection,
  user: PropTypes.object,
  addInspectionNote: PropTypes.func.isRequired,
  updateInspectionDefectAndNote: PropTypes.func.isRequired,
  reportViewId: PropTypes.string.isRequired,
  inspectionId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
