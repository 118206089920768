import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchDelays: 'administration/configurations/activities/fetchDelays',
  deleteDelay: 'administration/configurations/activities/deleteDelay',
  createDelay: 'administration/configurations/activities/createDelay',
  updateDelay: 'administration/configurations/activities/updateDelay',
};

export const delaysReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchDelays)) {
      draft.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteDelay)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.createDelay, ACTION_TYPE.updateDelay])) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchDelays)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchDelays)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
