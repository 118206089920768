import { connect } from 'react-redux';

import { CycleDetailReport } from './CycleDetailReport';
import { fetchTripsOptimized, selectTrips } from '../../../../data/reports';

export const CycleDetailReportContainer = connect(
  (state) => ({
    trips: selectTrips(state),
  }),
  {
    fetchTrips: fetchTripsOptimized,
  }
)(CycleDetailReport);
