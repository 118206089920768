import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, BasicDialog, useBooleanState } from 'stti-react-common';
import { isEmpty } from 'lodash';

import { CreateAnnotationDialog } from '../Dialogs/CreateAnnotationDialog';

import { HidePrint } from '../../../../commons/ReportsCommon';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { commentsColumnDefs as columnDefs } from './columnDefs';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const CommentCard = ({ log, hidePrint, commentMethods }) => {
  const { addDriverLogComment, deleteDriverLogComment, fetchDriverLog } = commentMethods || {};
  const { logAnnotations } = log || {};

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBooleanState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [row, setRow] = useState({});
  const [deletedRow, setDeletedRow] = useState(undefined);

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <Button
        onClick={() => {
          openDeleteDialog();
          setRow(data);
        }}
        tooltip="Delete"
        icon="delete"
      />
    ),
  });

  const annotations = useMemo(() => {
    if (!deletedRow) return logAnnotations;
    return logAnnotations.filter(({ key }) => key !== deletedRow.key);
  }, [logAnnotations, deletedRow]);

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__CommentCard" fullWidth>
        <div className="DriverLogDetailReport__CommentCard__commentsHeader">
          <h4>Comments</h4>
          <Button icon="add" label="New" variant="contained" onClick={openCreateDialog} />
        </div>

        {annotations && (
          <ControlledDataTable
            controller={dataGridController}
            components={dataGridComponents}
            rows={annotations}
            rowIdProperty="key"
          />
        )}
      </Card>
      {!isEmpty(log) && (
        <CreateAnnotationDialog
          log={log}
          isOpen={isCreateDialogOpen}
          onClose={closeCreateDialog}
          onConfirm={addDriverLogComment}
          onRefresh={fetchDriverLog}
        />
      )}
      <BasicDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        title="Confirm Delete Annotation"
        buttons={[
          {
            label: 'Cancel',
            onClick: closeDeleteDialog,
          },
          {
            label: 'Delete',
            onClick: () => {
              deleteDriverLogComment(row);
              setDeletedRow(row);
              closeDeleteDialog();
            },
          },
        ]}
      >
        <span>Are you sure you want to delete this annotation?</span>
      </BasicDialog>
    </HidePrint>
  );
};

CommentCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
  commentMethods: PropTypes.object,
};
