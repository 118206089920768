import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find, filter, reject } from 'lodash';

import { selectSystemUser } from '../system';
import { organization } from '../administration/configurations';

const getReportViews = (state) => state.reports.reportViews;

const extractReportType = (_, { reportType }) => reportType;

/**
 * selectReportViews(state) -> [reportView]
 *
 * Selector for all reportViews, including permissions based on user role(s)
 */
export const selectReportViews = createSelector(
  [getReportViews, organization.selectors.selectOrganizations, selectSystemUser],
  (reportViews, ous, user) =>
    reportViews.map((reportView) => {
      const ou = find(ous, { key: reportView.ouKey }); // reportView.ouKey may be null if not shared
      const isUserOuAdmin = ou && ou.permissions.isAdmin;
      const isOwnedByUser = !reportView.ouKey; // must be owned by user
      const canModify = isOwnedByUser || isUserOuAdmin || false;
      return {
        ...reportView,
        ou,
        permissions: {
          canDelete: canModify,
          canEdit: canModify,
        },
      };
    })
);

/**
 * selectVisibleReportViews(state, { reportType }) -> [reportView]
 *
 * Selector for non-default reportViews for a given reportType (translated to/from reportCode)
 */
export const selectVisibleReportViews = createCachedSelector(
  [selectReportViews, extractReportType],
  (reportViews, reportType) =>
    reject(filter(reportViews, { reportType, defaultView: false }), { hidden: true })
)(extractReportType);
