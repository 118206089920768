import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, noop } from 'lodash';
import { BasicDialog } from 'stti-react-common';

export const LoadFailedDialog = ({ loadError, children, buttons, ...restProps }) => (
  <BasicDialog
    onClose={noop} // prevent click-away close
    buttons={buttons}
    maxWidth="xs"
    {...restProps}
  >
    {isFunction(children) ? children(loadError) : children}
  </BasicDialog>
);

LoadFailedDialog.propTypes = {
  title: PropTypes.string.isRequired,
  loadError: PropTypes.object,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
