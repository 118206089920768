import { connect } from 'react-redux';

import { DriverLogsSummaryReport } from './DriverLogsSummaryReport';
import {
  selectDriverLogs,
  fetchDriverLogsOptimized,
  fetchMyDriverLogsOptimized,
  fetchAllDriverLogsReviewDetailsOptimized,
} from '../../../../data/reports';
import { selectSystemUser, selectSystemOus } from '../../../../data/system';

export const DriverLogsSummaryReportContainer = connect(
  (state) => ({
    ous: selectSystemOus(state),
    driverLogs: selectDriverLogs(state),
    user: selectSystemUser(state),
    limits: { name: 'driverlogs', forAllOus: 14, forOu: 59 },
  }),
  {
    fetchDriverLogs: fetchDriverLogsOptimized,
    fetchMyDriverLogs: fetchMyDriverLogsOptimized,
    fetchDriverLogsReviewDetails: fetchAllDriverLogsReviewDetailsOptimized,
  }
)(DriverLogsSummaryReport);
