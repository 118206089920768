import { connect } from 'react-redux';

import { Notifier } from './Notifier';
import { dequeueNotification, selectQueuedNotifications } from '../../../data/notifications';

export const NotifierContainer = connect(
  (state) => ({
    queuedNotifications: selectQueuedNotifications(state),
  }),
  {
    dequeueNotification,
  }
)(Notifier);
