import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../commons/ControlledDataGrid';
import { RequestsProgressIndicator } from '../../commons/RequestsProgressIndicator';
import { viewingColumnDefs as columnDefs } from './columnDefs';

const { useDataGridController } = ControlledDataGrid;

export const OpenReportDialog = ({ isOpen, onClose, onItemClick, items, ...restProps }) => {
  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  return (
    <BasicDialog
      className="OpenReportDialog"
      title="Open Report"
      titleEnd={<RequestsProgressIndicator service="reportViews" />}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth
      fullHeight
      keepMounted
      {...restProps}
    >
      <ControlledDataGrid
        controller={dataGridController}
        rows={items}
        sizeColumnsToFit
        onRowClick={(row) => {
          onItemClick(row);
          onClose();
        }}
      />
    </BasicDialog>
  );
};

OpenReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
