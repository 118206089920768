export const adminGridOptions = {
  defaultColDef: {
    suppressMovable: true,
    minWidth: 120,
  },
  suppressColumnVirtualisation: true,
};

export const simpleAdminGridOptions = {
  defaultColDef: {
    suppressMovable: true,
    menuTabs: ['filterMenuTab'],
    minWidth: 120,
  },
  suppressColumnVirtualisation: true,
};

export const reportGridOptions = {};
