import React, { useMemo } from 'react';
import { ToggleInput, Chip, NumberInput } from 'stti-react-common';
import { uniqBy, sortBy } from 'lodash';

import { ReportControl, SelectFilterInput } from '../../../commons/ReportsCommon';

import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';
import { convertUnit } from '../../../../helpers/formats';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const FiltersPanel = ({ rows }) => {
  const getOptions = useMemo(
    () => ({
      driverName: () =>
        sortBy(
          uniqBy(
            rows.map(({ driver, driverName }) => ({ label: driverName, value: driver })),
            'value'
          ),
          'label'
        ),
      vehicleNames: () =>
        sortBy(
          uniqBy(
            rows.map(({ vehicleKey, vehicleName }) => ({ label: vehicleName, value: vehicleKey })),
            'value'
          ),
          'label'
        ),
    }),
    [rows]
  );

  return (
    <>
      <SelectFilterInput
        name="driverName"
        field="driver"
        displayValue="driverName"
        group="filters"
        filterGroup="filters"
        label="Drivers"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverName}
      />
      <SelectFilterInput
        name="vehicleName"
        field="vehicleKey"
        displayValue="vehicleName"
        group="filters"
        filterGroup="filters"
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
      />
      <ReportControl
        render={(props) => <ToggleInput {...props} />}
        renderSummary={(renderProps, { value }) =>
          value && <Chip {...renderProps} label="Show records with no activity" />
        }
        name="showRecordsWithoutActivities"
        defaultValue
        group="filters"
        label="Show records with no activity"
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => (!value ? row.incidents.length > 0 : row),
          type: filterRowsByPredicate,
        }}
      />
      <ReportControl
        render={(props) => <NumberInput {...props} endAdornment="hours" fullWidth />}
        renderSummary={(renderProps, { value }) =>
          value && <Chip {...renderProps} label={`Hide: Engine Hours < ${value}`} />
        }
        name="hideRelativeEngineHoursLesserThan"
        group="filters"
        label="Hide Shift Durations less than"
        filterConfig={{
          group: 'filters',
          field: 'engineTime',
          test: ({ row, value }) =>
            !value ? row : convertUnit('duration', row.engineTime, 'h') > value,
          type: filterRowsByPredicate,
        }}
      />
    </>
  );
};

FiltersPanel.propTypes = {
  rows: customPropTypes.performances.isRequired,
};
