import { omit, isNil, filter, isObject } from 'lodash';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import { selectTenantsFetchRequests } from './selectors';

import { ACTION_TYPE } from './reducer';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const transformForStore = (data, values) => {
  const tenantKey = isObject(values) ? values.tenantKey : values;
  const configs = Object.assign(
    {},
    ...data.map(({ params, provider }) =>
      Object.assign(
        {},
        ...Object.entries(params).map((param) => {
          const key = param[0];
          const value = param[1];
          return {
            [`${provider}-${key}`]: value,
          };
        })
      )
    )
  );

  return {
    key: tenantKey,
    ...configs,
  };
};

const fetchTenants = makeRestApiAction({
  service: 'tenants',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTenants,
  notificationsItemDescriptor: 'tenants',
});

export const fetchTenantsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTenantsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchTenants,
  baseActionType: ACTION_TYPE.fetchTenants,
});

const fetchTenant = makeRestApiAction({
  service: 'tenants',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTenant,
  getId: (key) => key,
  transformOutput: ({ brandPrimary, ...rest }, actionParam) => ({
    ...rest,
    brandPrimary: brandPrimary?.toLowerCase() || '',
  }),
  notificationsItemDescriptor: 'tenant',
});

export const fetchTenantOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTenantsFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { service: 'tenants', actionParam: id }),
  fetchAction: fetchTenant,
  baseActionType: ACTION_TYPE.fetchTenant,
});

export const fetchVideoConfigsByTenant = makeRestApiAction({
  service: 'tenantsVideoConfigs',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVideoConfigsByTenant,
  getId: (key) => key,
  transformOutput: transformForStore,
  notificationsItemDescriptor: 'tenant video configs',
});

export const fetchVideoConfigsByTenantOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTenantsFetchRequests,
  filterFetches: (fetches, actionParam) =>
    filter(fetches, { service: 'tenantsVideoConfigs', actionParam }),
  fetchAction: fetchVideoConfigsByTenant,
  baseActionType: ACTION_TYPE.fetchVideoConfigsByTenant,
});

export const updateTenantVideoConfigs = makeRestApiAction({
  service: 'tenantsVideoConfigs',
  method: 'post',
  baseActionType: ACTION_TYPE.updateTenantVideoConfigs,
  getId: ({ tenantKey }) => tenantKey,
  transformInput: (data) => {
    const { enabledVideoProviders } = data;
    const formattedVideoConfigurations = enabledVideoProviders.map((provider) => {
      const params = {};
      Object.entries(data).forEach((formValue) => {
        const key = formValue[0];
        const value = formValue[1];

        if (enabledVideoProviders.find((enabledProvider) => key.includes(enabledProvider))) {
          params[key.replace(`${provider}-`, '')] = value;
        }
      });

      return { provider, params };
    });

    return formattedVideoConfigurations;
  },
  transformOutput: transformForStore,
  notificationsItemDescriptor: 'tenant video configs',
  throwApiErrors: true,
});

export const createTenant = makeRestApiAction({
  service: 'tenants',
  method: 'post',
  baseActionType: ACTION_TYPE.createTenant,
  transformInput: (data) => omit(data, isNil),
  transformOutput: ({ key }, actionParam) => ({ ...actionParam, key: key.toString() }),
  notificationsItemDescriptor: 'tenant',
  throwApiErrors: true,
});

export const updateTenant = makeRestApiAction({
  service: 'tenants',
  method: 'put',
  baseActionType: ACTION_TYPE.updateTenant,
  getId: ({ key }) => key,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'tenant',
  throwApiErrors: true,
});

export const deleteTenant = makeRestApiAction({
  service: 'tenants',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteTenant,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'tenant',
});

export const updateTenantConfiguration = makeRestApiAction({
  service: 'changeTenantConfiguration',
  method: 'post',
  baseActionType: ACTION_TYPE.updateTenantConfiguration,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'tenant configuration',
  throwApiErrors: true,
});

export const createTenantSupportUser = makeRestApiAction({
  service: 'tenants',
  method: 'post',
  baseActionType: ACTION_TYPE.createTenantSupportUser,
  getId: ({ key }) => `${key}/supportUsers`,
  notifications: {
    success: 'Support User added successfully.',
    failure: (_, apiError) => `Error adding support user: (${apiError.message}).`,
  },
  throwApiErrors: true,
});
