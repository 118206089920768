import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, TextInput, ToggleInput } from 'stti-react-common';
import { useControlsState } from '../../../helpers/hooks';

import './EditReportMetaDialog.scss';

export const EditReportMetaDialog = ({
  isOpen,
  getReportView,
  onClose,
  onSave,
  title: dialogTitle = 'Edit Report',
  activeOu,
  reportViews,
  ...restProps
}) => {
  const [controls, setControls, setControl] = useControlsState();

  const reset = () => {
    const { title: reportTitle, description, ouKey } = getReportView();
    setControls({
      title: reportTitle,
      description,
      isShared: !!ouKey,
    });
  };

  const handleSave = () => {
    onClose();
    const { title, description, isShared } = controls;
    onSave({
      ...getReportView(),
      title,
      description,
      ouKey: isShared ? activeOu.key : null,
    });
  };

  const canSave = useMemo(
    () => controls.title && !reportViews?.some(({ title }) => title === controls.title),
    [controls.title, reportViews]
  );

  return (
    <BasicDialog
      isOpen={isOpen}
      onOpen={reset}
      className="EditReportMetaDialog"
      title={dialogTitle}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Save',
          onClick: handleSave,
          disabled: !canSave,
        },
      ]}
      onClose={onClose}
      {...restProps}
    >
      {!canSave && (
        <p className="errorMessage">Please enter a new and valid title in order to save.</p>
      )}
      <TextInput
        className="EditReportMetaDialog__control--name"
        autoFocus
        label="Name"
        value={controls.title}
        inputProps={{ maxLength: 200 }}
        onKeyPress={(e) => e.key === 'Enter' && canSave && handleSave()}
        onChange={(val) => setControl('title', val)}
      />
      <TextInput
        className="EditReportMetaDialog__control--description"
        label="Description"
        value={controls.description}
        inputProps={{ maxLength: 200 }}
        onKeyPress={(e) => e.key === 'Enter' && canSave && handleSave()}
        onChange={(val) => setControl('description', val)}
      />
      <ToggleInput
        className="EditReportMetaDialog__control--shared"
        label={`Shared with ${activeOu.name}`}
        value={controls.isShared}
        onChange={(val) => setControl('isShared', val)}
      />
    </BasicDialog>
  );
};

EditReportMetaDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  getReportView: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  activeOu: PropTypes.object.isRequired,
  reportViews: PropTypes.array,
};
