import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Clickable, Card, ItemManagerTree, Menu, useBooleanState } from 'stti-react-common';

import { Feature } from '../../../../commons/Feature';

import { DeleteAdminItemDialog } from '../../../../commons/AdminCommon';

import './summary.scss';

export const OrganizationSummary = ({
  organizationsTree,
  fetchOrganizations,
  openOrganizationCreate,
  openOrganizationDetail,
  deleteOrganization,
  route,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchOrganizations().then(() => setLoading(false));
  }, []);

  const restructureTree = (tree) =>
    tree.map((node) => {
      const nodeCopy = { ...node };
      nodeCopy.node = (
        <Card className="OrganizationSummary__node">
          {node && node.scope && (
            <>
              <Clickable
                onClick={() => openOrganizationDetail({ organizationId: node.key, mode: 'view' })}
              >
                {node.name}
              </Clickable>
              <Button
                variant="outlined"
                icon="edit"
                tooltip="Edit Organization"
                onClick={() => openOrganizationDetail({ organizationId: node.key, mode: 'edit' })}
              />
              <Button
                variant="outlined"
                icon="delete"
                tooltip="Delete Organization"
                onClick={() => {
                  setTargetRow(node);
                  openDeleteDialog();
                }}
              />
              <Menu
                items={[
                  {
                    label: 'Add Sub Organization',
                    onClick: () => openOrganizationCreate({ subOrganization: node }),
                  },
                ]}
                icon="more_vert"
              />
            </>
          )}
          {!node.scope && <p>{node.name}</p>}
        </Card>
      );
      nodeCopy.keywords = nodeCopy.name;
      nodeCopy.id = nodeCopy.key;

      if (nodeCopy.children.length > 0) {
        nodeCopy.items = restructureTree(nodeCopy.children);
      }

      return nodeCopy;
    });

  const formattedTree = useMemo(() => {
    const copiedTree = [...organizationsTree];
    return restructureTree(copiedTree);
  }, [organizationsTree]);

  // RENDER
  return (
    <Feature.Wrapper className="OrganizationSummary">
      <Feature.Header onClose={route.close} title="Organizations" service="organization">
        <Button
          icon="add"
          variant="contained"
          label="Create New Organization"
          onClick={() => openOrganizationCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        {!loading && <ItemManagerTree items={formattedTree} isExpanded hasSearch />}
        <DeleteAdminItemDialog
          dialogTitle="Organization"
          itemTitle={targetRow.name}
          isOpen={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          onConfirm={deleteOrganization}
          rowData={targetRow}
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

OrganizationSummary.propTypes = {
  organizationsTree: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.array.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  openOrganizationDetail: PropTypes.func.isRequired,
  openOrganizationCreate: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
