import { connect } from 'react-redux';

import { TenantDetail } from './detail';

import {
  selectSystemTenant,
  fetchCurrentTenant,
  selectSystemUser,
} from '../../../../../data/system';

import { tenant } from '../../../../../data/administration/configurations/tenants';

export const TenantDetailContainer = connect(
  (state, { tenantId }) => ({
    isSystem: selectSystemTenant(state).isSystem,
    systemUser: selectSystemUser(state),
    tenant: tenant.selectors.selectTenant(state, tenantId),
  }),
  {
    fetchTenant: tenant.actions.fetchTenantOptimized,
    createTenant: tenant.actions.createTenant,
    updateTenant: tenant.actions.updateTenant,
    fetchCurrentTenant,
    createTenantSupportUser: tenant.actions.createTenantSupportUser,
  }
)(TenantDetail);
