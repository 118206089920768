import BetterPropTypes from 'better-prop-types';

export const organization = BetterPropTypes.shape({
  address: BetterPropTypes.string,
  carrier: BetterPropTypes.string,
  carrierName: BetterPropTypes.string,
  children: BetterPropTypes.array,
  description: BetterPropTypes.string,
  key: BetterPropTypes.string.isRequiredButNullable,
  members: BetterPropTypes.array,
  name: BetterPropTypes.string.isOptionalButNotNull,
  parent: BetterPropTypes.oneOfType([BetterPropTypes.string, BetterPropTypes.object]),
  principal: BetterPropTypes.string,
  principalName: BetterPropTypes.string,
  timeZone: BetterPropTypes.string,
  unitSystem: BetterPropTypes.string.isOptionalButNotNull,
  usDotNumber: BetterPropTypes.number,
  scope: BetterPropTypes.oneOf(['Tenant', 'Organization']),
  // If there is no scope property on organizations then the currentUser doesnt have access to that organization
});

export const organizations = BetterPropTypes.arrayOf(organization);
