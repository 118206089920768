import { createSelector } from 'reselect';
import { find } from 'lodash';

import { selectSystemTenant } from '../../../system';

import { tenant } from '../tenants';

export const getCompanionApps = (state) => state.administration.configurations.companionApps.data;

const extractAppId = (_, { appId }) => appId;
/**
 * selectCompanionApps(state, companionApps, tenants, currentTenant) -> [fetchRequestData]
 *
 * Selector for all available companionApps for currentUser
 */

export const selectCompanionApps = createSelector(
  [getCompanionApps, tenant.selectors.selectTenants, selectSystemTenant],
  (companionApps, tenants, item) => {
    const availableApps = item.isSystem
      ? companionApps
      : companionApps.filter((companionApp) => companionApp.available);

    return availableApps.map((companionApp) => ({
      ...companionApp,
      canEdit: item.isSystem,
      tenants:
        tenants.length > 0 && companionApp.tenantKeys
          ? companionApp.tenantKeys.map((tenantKey) => find(tenants, { key: tenantKey }))
          : [],
    }));
  }
);

/**
 * selectCompanionApp(state, userKey) -> user
 *
 * Selector for single app by ID ("key" property)
 */
export const selectCompanionApp = createSelector(
  [selectCompanionApps, extractAppId],
  (companionApps, key) => find(companionApps, { key })
);

/**
 * selectCompanionAppsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on companionApps
 */
export const selectCompanionAppsFetchRequests = (state) =>
  state.administration.configurations.companionApps.fetches;
