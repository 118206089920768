import React from 'react';
import PropTypes from 'prop-types';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import {
  countUniqueOfField,
  countUniqueOfArrayField,
  countFalseyOfField,
} from '../../../../helpers/reports/aggregation';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => {
      const shiftsInProgressCount = countFalseyOfField(rows, 'endedAt');
      return (
        <div {...props}>
          {rows.length} shifts
          {shiftsInProgressCount ? ` (${shiftsInProgressCount} in progress), ` : ', '}
          {countUniqueOfField(rows, 'driver.name')} drivers,{' '}
          {countUniqueOfArrayField(rows, 'vehiclesNames')} vehicles,{' '}
          {countUniqueOfArrayField(rows, 'trailersNames')} trailers
        </div>
      );
    }}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
