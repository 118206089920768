import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';

import { RequestsProgressIndicator } from '../../commons/RequestsProgressIndicator';
import { ManageReportsContainer as ManageReports } from './ManageReportsContainer';

export const ManageReportsDialog = ({ isOpen, onClose, reportType, ...restProps }) => (
  <BasicDialog
    className="ManageReportsDialog"
    isOpen={isOpen}
    onClose={onClose}
    maxWidth="lg"
    fullWidth
    fullHeight
    keepMounted
    {...restProps}
    title="Manage Reports"
    titleEnd={<RequestsProgressIndicator service="reportViews" />}
  >
    <ManageReports reportType={reportType} />
  </BasicDialog>
);

ManageReportsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  reportType: PropTypes.string.isRequired,
};
