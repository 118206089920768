import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import { useFormats } from '../../../commons/Formats';
// import { sumOfField, countOf } from '../../../../helpers/reports/aggregation';

export const AggregatesContent = ({ rows, shift }) => {
  const { formatUnit } = useFormats();

  if (!shift) return null;

  const { fullCycle, empty, loaded, loading, driving, unloading, count } = shift;
  const {
    distance: fullCycleDistance,
    duration: fullCycleDuration,
    consumedFuel: fullCycleConsumedFuel,
    addedFuel: fullCycleAddedFuel,
    mileage: fullCycleMileage,
  } = fullCycle || {};
  const { distance: emptyDistance } = empty || {};
  const { distance: loadedDistance } = loaded || {};
  const { duration: drivingDuration } = driving || {};
  const { duration: loadingDuration, waitDuration: loadingWaitDuration } = loading || {};
  const { duration: unloadingDuration, waitDuration: unloadingWaitDuration } = unloading || {};

  const { loads, unloads } = count || {};

  return (
    <>
      <AggregatesCard
        title="Distance"
        name="distance"
        fields={[
          {
            header: 'Total',
            value: formatUnit('distance', fullCycleDistance),
          },
          {
            header: 'Empty',
            value: formatUnit('distance', emptyDistance),
          },
          {
            header: 'Loaded',
            value: formatUnit('distance', loadedDistance),
          },
        ]}
      />
      <AggregatesCard
        title="Duration"
        name="duration"
        fields={[
          {
            header: 'Shift',
            value: formatUnit('duration', fullCycleDuration),
          },
          {
            header: 'Driving',
            value: formatUnit('duration', drivingDuration),
          },
          {
            header: 'Activities',
            value: formatUnit('duration', fullCycleDuration - drivingDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Load Totals"
        name="loading"
        fields={[
          {
            header: 'Loads',
            value: loads,
          },
          {
            header: 'Waiting',
            value: formatUnit('duration', loadingWaitDuration),
          },
          {
            header: 'Loading',
            value: formatUnit('duration', loadingDuration - loadingWaitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Unload Totals"
        name="unloading"
        fields={[
          {
            header: 'Unloads',
            value: unloads,
          },
          {
            header: 'Waiting',
            value: formatUnit('duration', unloadingWaitDuration),
          },
          {
            header: 'Unloading',
            value: formatUnit('duration', unloadingDuration - unloadingWaitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Fuel"
        name="fuel"
        fields={[
          {
            header: 'Consumed',
            value: formatUnit('volume', fullCycleConsumedFuel),
          },
          {
            header: 'Added',
            value: formatUnit('volume', fullCycleAddedFuel),
          },
          {
            header: 'Mileage',
            value: formatUnit('mileage', fullCycleMileage),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  shift: PropTypes.object,
  rows: PropTypes.array,
};
