import { connect } from 'react-redux';

import { LocationTypeSummary } from './summary';

import { locationType } from '../../../../../data/administration/resources';

export const LocationTypeSummaryContainer = connect(
  (state) => ({
    locationTypes: locationType.selectors.selectLocationTypes(state),
  }),
  {
    fetchLocationTypes: locationType.actions.fetchLocationTypesOptimized,
    deleteLocationType: locationType.actions.deleteLocationType,
  }
)(LocationTypeSummary);
