import produce from 'immer';

import { reject } from 'lodash';
import {
  actionTypeRequest,
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
} from '../../../apiReducerSupport';

const initialState = {
  devices: { data: [], fetches: [] },
  records: [],
  events: [],
  mediaEvent: {},
  trips: [],
  breadcrumbs: { data: [], fetches: [] },
};

export const ACTION_TYPE = {
  fetchCameras: 'administration/devices/cameras/fetchCameras',
  fetchCameraDevice: 'administration/devices/cameras/fetchCameraDevice',
  fetchCameraDevices: 'administration/devices/cameras/fetchCameraDevices',
  fetchCameraRecords: 'administration/devices/cameras/fetchCameraRecords',
  fetchCameraRecordsStreaming: 'administration/devices/cameras/fetchCameraRecordsStreaming',
  fetchCameraVirtualEvents: 'administration/devices/cameras/fetchCameraVirtualEvents',
  postCameraVirtualEvent: 'administration/devices/cameras/postCameraVirtualEvent',
  fetchCameraVirtualEventsMediaDownload:
    'administration/devices/cameras/fetchCameraVirtualEventsMediaDownload',
  fetchCameraTrips: 'administration/devices/cameras/fetchCameraTrips',
  fetchCameraBreadcrumbs: 'administration/devices/cameras/fetchCameraBreadcrumbs',
  fetchCameraWakeUp: 'administration/devices/cameras/fetchCameraWakeUp',
  postAddCameras: 'administration/devices/cameras/postAddCameras',
  putCameraDevice: 'administration/devices/cameras/putCameraDevice',
  postCameraDeviceReboot: 'administration/devices/cameras/postCameraDeviceReboot',
  deleteCameraDevice: 'administration/devices/cameras/deleteCameraDevice',
  fetchLiveStreamDataByCameraId: 'administration/devices/cameras/fetchLiveStreamDataByCameraId',
  fetchLiveSnapshotDataByCameraId: 'administration/devices/cameras/fetchLiveSnapshotDataByCameraId',
};

export const cameraReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCameraRecords)) {
      draft.records = shallowMergeEntities(state.records, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCameraVirtualEvents)) {
      draft.events = shallowMergeEntities(state.events, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCameraVirtualEventsMediaDownload)) {
      draft.mediaEvent = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCameraTrips)) {
      draft.trips = shallowMergeEntities(state.trips, payload, 'id');
    }

    // start - fetchCameraBreadcrumbs general actions and reducers
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCameraBreadcrumbs)) {
      draft.breadcrumbs.data = shallowMergeEntities(state.breadcrumbs.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchCameraBreadcrumbs])) {
      const { id, actionParam } = request;
      draft.breadcrumbs.fetches = shallowMergeEntities(state.breadcrumbs.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchCameraBreadcrumbs])) {
      draft.breadcrumbs.fetches = removeEntity(state.breadcrumbs.fetches, request);
    }
    // end - fetchCameraBreadcrumbs general actions and reducers

    // start - fetchCameraDevices general actions and reducers
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchCameraDevice,
        ACTION_TYPE.fetchCameraDevices,
      ])
    ) {
      draft.devices.data = shallowMergeEntities(state.devices.data, payload, 'id');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchCameraDevices])) {
      const { id, actionParam } = request;
      draft.devices.fetches = shallowMergeEntities(state.devices.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchCameraDevices])) {
      draft.devices.fetches = removeEntity(state.devices.fetches, request);
    }
    // end - fetchCameraDevices general actions and reducers

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.putCameraDevice])) {
      draft.devices.data = shallowMergeEntities(state.devices.data, payload, 'id');
    }

    if (actionTypeFailure(type, [ACTION_TYPE.putCameraDevice])) {
      draft.devices.fetches = removeEntity(state.devices.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.deleteCameraDevice])) {
      draft.devices.data = reject(state.devices.data, { id: payload });
    }
  });
