import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil, noop } from 'lodash';

import { useReport } from '../useReport';

// important thing about args: if you want to call directly as string use <FetchReportDetail args={var} />
// otherwise you will be passing a object if you call like this <FetchReportDetail args={{var, anotherVar}} />

const FetchDataByArgs = ({ fetchAction, args, onFetch, onResolve }) => {
  const { registerFetch, fetchDependency } = useReport();

  useEffect(() => {
    const isEmptyArgs = Object.values(args).every((x) => isNil(x));
    if (isEmptyArgs) return registerFetch();
    onFetch();

    return registerFetch(
      fetchAction(typeof args === 'string' ? args : { ...args }).then(onResolve)
    );
  }, [JSON.stringify(args), fetchDependency]);

  return null;
};

FetchDataByArgs.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  args: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onFetch: PropTypes.func,
  onResolve: PropTypes.func,
};

export const FetchReportDetail = connect(
  undefined,
  {}
)(({ action, args, onFetch = noop, onResolve = noop }) => (
  <FetchDataByArgs fetchAction={action} args={args} onFetch={onFetch} onResolve={onResolve} />
));

FetchReportDetail.propTypes = {
  args: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fetchAction: PropTypes.func,
};
