import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, BasicDialog, ControlledDataGridColumnManager } from 'stti-react-common';

import { useReport } from './useReport';

const DataGridColumnSelectDialog = ({ controller, ...restProps }) => (
  <BasicDialog title="Select Columns" isOpen fullHeight fullWidth scroll="content" {...restProps}>
    <ControlledDataGridColumnManager controller={controller} />
  </BasicDialog>
);

DataGridColumnSelectDialog.propTypes = {
  controller: PropTypes.object.isRequired,
};

export const GridPanelMenu = ({ panelTerm = 'Table', extraOptionsBottom = [], ...restProps }) => {
  const { resetState, revertState, meta, dataGridController } = useReport();
  const [currentDialog, setCurrentDialog] = useState(null);
  return (
    <>
      <DataGridColumnSelectDialog
        controller={dataGridController}
        isOpen={currentDialog === 'selectColumns'}
        onClose={() => setCurrentDialog(null)}
      />
      <Menu
        icon="more_vert"
        items={[
          {
            label: 'Auto-Size Columns',
            onClick: () => {
              dataGridController.methods.autoSizeColumns();
            },
          },
          {
            label: 'Select Columns...',
            onClick: () => {
              setCurrentDialog('selectColumns');
            },
          },
          {
            label: `Revert ${panelTerm} To ${meta.defaultView ? 'Default' : 'Saved'}`,
            onClick: () => {
              revertState('dataGrid');
            },
          },
          {
            label: `Reset ${panelTerm}`,
            onClick: () => {
              resetState('dataGrid');
            },
          },
          ...extraOptionsBottom,
        ]}
        {...restProps}
      />
    </>
  );
};

GridPanelMenu.propTypes = {
  panelTerm: PropTypes.string,
  extraOptionsBottom: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};
