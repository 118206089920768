import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ManageReports } from './ManageReports';
import {
  fetchReportViewsByReportType,
  deleteReportView,
  updateReportView,
  selectVisibleReportViews,
} from '../../../data/reports';
import { selectSystemActiveOu } from '../../../data/system';

export const ManageReportsContainer = connect(
  (state, props) => ({
    activeOu: selectSystemActiveOu(state),
    reportViews: selectVisibleReportViews(state, props),
  }),
  {
    fetchReportViewsByReportType,
    deleteReportView,
    updateReportView,
  }
)(ManageReports);

ManageReportsContainer.propTypes = {
  reportType: PropTypes.string.isRequired,
};
