import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ExceptionDetailReport } from './ExceptionDetailReport';
import {
  selectUnitedException,
  selectVehiclesBreadcrumbs,
  createAnnotation,
  updateAnnotation,
  fetchTrip,
  fetchException,
  fetchAnnotations,
  fetchVideoEventFileLocationOptimized,
} from '../../../../data/reports';

export const ExceptionDetailReportContainer = connect(
  (state, { exceptionType, tripId, exceptionId }) => ({
    exception: selectUnitedException(state, { exceptionType, tripId, exceptionId }),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
  }),
  {
    createAnnotation,
    updateAnnotation,
    fetchTrip,
    fetchException,
    fetchAnnotations,
    fetchVideoEventFileLocation: fetchVideoEventFileLocationOptimized,
  }
)(ExceptionDetailReport);

ExceptionDetailReportContainer.propTypes = {
  exceptionType: PropTypes.string.isRequired,
  tripId: PropTypes.string,
  exceptionId: PropTypes.string,
};
