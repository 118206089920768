import React from 'react';
import { uniqBy } from 'lodash';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => (
      <div {...props}>
        {rows.length} violations, {uniqBy(rows, (row) => row && row.driverName).length} drivers,{' '}
      </div>
    )}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: customPropTypes.speedViolations.isRequired,
};
