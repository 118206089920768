import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { fromPairs, sortBy } from 'lodash';
import { BasicDialog, SelectInput, TextInput, ToggleInput, Form } from 'stti-react-common';

import { useFormats } from '../../Formats';
import { exceptionTypes, getExceptionType } from '../../../../helpers/reports/exceptionTypes';

import {
  Distance,
  Duration,
  LoadedStatus,
  Mileage,
  NumberComponent,
  Percent,
  VideoOptions,
  formatControlValueForDescription,
} from './editExceptionComponents';

import './EditExceptionRuleDialog.scss';

const { Control, useFormController } = Form;

const editExceptionComponents = {
  Duration,
  Distance,
  Mileage,
  Percent,
  Number: NumberComponent,
  LoadedStatus,
  VideoOptions,
};

export const EditExceptionRuleDialog = ({
  isOpen,
  onClose,
  onSaveRule,
  rule,
  features,
  ...restProps
}) => {
  const { formats } = useFormats();

  const form = useFormController({
    controls: rule,
  });

  const { controls, setControl, resetControls, hasErrors, isDirty } = form;

  useEffect(() => {
    const exceptionType = getExceptionType(controls.type);
    if (!exceptionType || controls.customDescription) return;

    // format control values
    const descriptionFormattedControls = fromPairs(
      Object.entries(controls).map(([controlName, controlValue]) => [
        controlName,
        formatControlValueForDescription(controlName, controlValue),
      ])
    );

    // set description as needed
    const description = exceptionType.getDescription(descriptionFormattedControls);
    if (controls.description !== description) setControl('description', description);
  });

  const isExistingRule = rule && rule.id;

  const canSave = !hasErrors && isDirty;

  const handleSave = () => {
    onSaveRule(controls);
    onClose();
  };

  const controlsDisabled = !controls.type;

  const exceptionType = getExceptionType(controls.type);

  const exceptionTypeOptions = sortBy(
    exceptionTypes
      .filter(({ feature }) => !feature || features[feature])
      .map(({ type, title }) => ({
        value: type,
        label: title,
      })),
    ['label']
  );

  const filteredExceptionTypeOptions = useMemo(
    () =>
      !features.lytx && !features.surfsight
        ? exceptionTypeOptions.filter(({ value }) => value !== 'eventVideoEvent')
        : exceptionTypeOptions,
    [features]
  );

  const renderExceptionTypeControls = () => {
    if (!exceptionType) return null;
    const { getDefaultValues } = exceptionType;
    const defaultValues = getDefaultValues ? getDefaultValues({ formats, controls }) : {};

    return exceptionType.controls.map(({ component, values, ...rest }) => {
      const Component = editExceptionComponents[component];
      return (
        <Component
          key={`${controls.type}/${component}`}
          values={values}
          defaultValues={defaultValues}
          provider={controls.provider}
          {...rest}
        />
      );
    });
  };

  const videoProviders = [
    { value: 'lytx', label: 'Lytx', enable: features.lytx },
    { value: 'surfsight', label: 'Navistream', enable: features.surfsight },
  ].filter(({ enable }) => enable);

  return (
    <BasicDialog
      isOpen={isOpen}
      onOpen={resetControls}
      fullWidth
      maxWidth="xs"
      className="Report__EditExceptionRuleDialog"
      title={isExistingRule ? 'Edit Rule' : 'Create Rule'}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Save',
          onClick: handleSave,
          disabled: !canSave,
        },
      ]}
      onClose={onClose}
      {...restProps}
    >
      <Form form={form} element={false}>
        <Control
          Component={SelectInput}
          name="type"
          autoFocus
          label="Type"
          options={filteredExceptionTypeOptions}
          error={({ value }) => !value}
        />
        {controls.type === 'eventVideoEvent' && videoProviders.length > 0 && (
          <Control
            Component={SelectInput}
            name="provider"
            autoFocus
            label="Provider"
            options={videoProviders}
            error={({ value }) => !value}
            defaultValue={videoProviders.length === 1 ? videoProviders[0].value : null}
            onSet={({ draft, value }) => {
              if (!value) return;
              draft.videoEventTypes = [];
              draft.videoEventBehaviors = [];
            }}
          />
        )}
        {renderExceptionTypeControls()}
        <Control
          Component={TextInput}
          name="description"
          label="Description"
          inputProps={{ maxLength: 200 }}
          disabled={controlsDisabled || !controls.customDescription}
          error={({ value, controls: { type } }) => type && !value}
          multiline
        />
        <Control
          Component={ToggleInput}
          name="customDescription"
          label="Custom Description"
          disabled={controlsDisabled}
        />
      </Form>
    </BasicDialog>
  );
};

EditExceptionRuleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveRule: PropTypes.func.isRequired,
  rule: PropTypes.object,
  features: PropTypes.shape({
    lytx: PropTypes.bool,
    surfsight: PropTypes.bool,
    cti: PropTypes.bool,
  }).isRequired,
};
