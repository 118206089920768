import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ControlsTablePrint,
  ReportFragment,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  SectionPrint,
  MapIcon,
  FetchReportData,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './SpeedViolationsSummaryReport.scss';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { FiltersPanel } from './FiltersPanel';

const { createState, useDataGridComponents } = ControlledDataGrid;

const defaultState = () => ({
  controls: {
    dateRangeFor: 'startedAt',
  },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['speedViolations'];

export const SpeedViolationsSummaryReport = ({
  fetchSpeedViolations,
  limits,
  reportViewId,
  route,
  speedViolationLocationReportRoute,
  speedViolations,
}) => {
  // INITIALIZE REPORT
  const report = useReportController({
    reportType: 'speedViolationsSummary',
    reportTypeName: 'Speed Violations Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: speedViolations,
      chain: ['query', 'filters'],
    },
  });
  const { setControl, filteredRows, dataGridController, reportType } = report;

  useEffect(() => {
    setControl('dateRangeFor', 'startedAt');
  }, [report]);

  useEffect(() => {
    dataGridController.methods.autoSizeColumns();
  }, [filteredRows]);

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    SpeedViolationHeader: () => <MapIcon />,
    SpeedViolationMap: ({ data: { key } }) => (
      <Clickable onClick={() => speedViolationLocationReportRoute.open({ speedViolationKey: key })}>
        <MapIcon />
      </Clickable>
    ),
    Driver: ({ data, value }) => (
      <Clickable onClick={() => setControl('driverNames', [data.driver])}>{value}</Clickable>
    ),
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleNames', [data.asset])}>{value}</Clickable>
    ),
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchSpeedViolations} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="SpeedViolationsSummaryReport">
        <ReportFragment.Header reportType={reportType} route={route} services={requestServices} />

        <QueryPanel
          queryFor="speed violation"
          group="query"
          defaultDateRangeFor="startedAt"
          maxRangeInDays={60}
          disableFuture
          minAllowedDate="2018-01-01"
          filterDateRange={[
            'custom',
            'today',
            'yesterday',
            'thisWeek',
            'lastWeek',
            'last7Days',
            'last14Days',
            'thisMonth',
            'lastMonth',
            'last30Days',
          ]}
        />
        <FiltersPanel rows={filteredRows.query} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel
          dataGridComponents={dataGridComponents}
          rowIdProperty="key"
          sizeColumnsToFit
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable
          controller={dataGridController}
          rows={filteredRows.dataGrid}
          rowIdProperty="key"
        />
      </PrintLayout>
    </ReportProvider>
  );
};

SpeedViolationsSummaryReport.propTypes = {
  fetchSpeedViolations: PropTypes.func.isRequired,
  limits: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  speedViolationLocationReportRoute: PropTypes.object.isRequired,
  speedViolations: customPropTypes.speedViolations.isRequired,
};
