import React from 'react';

import { CameraSummaryContainer } from './Summary/connect';
import { CameraDetailContainer } from './Detail/connect';
import { CameraRecordingsContainer } from './Recordings/connect';

import { VehicleDetailContainer as VehicleDetail } from '../../resources/Vehicles/Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { base64tuple } from '../../../commons/AppRouter/encoding';

import { administrationGroups, configureRoute, ADMIN_ROLE_KEY } from '../../../../config';

export const cameraSummary = configureRoute({
  name: 'Cameras',
  path: '/cameras',
  group: administrationGroups.devices,
  order: 2,
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <CameraSummaryContainer
      {...props}
      openCameraDetail={cameraDetail.open}
      openVehicleDetail={cameraVehicleDetail.open}
    />
  ),
});

export const cameraDetail = configureRoute({
  name: 'Cameras',
  path: '/camera/:cameraId',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <CameraDetailContainer {...props} openCameraRecords={cameraRecordingsDetail.open} />
  ),
});

export const cameraRecordingsDetail = configureRoute({
  name: 'Cameras',
  path: '/recordings/:record',
  encoding: { record: base64tuple(['ouId', 'vehicleId']) },
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <CameraRecordingsContainer {...props} />,
});

export const cameraVehicleDetail = configureRoute({
  name: 'Cameras',
  path: '/vehicles/vehicle/:assetId',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleDetail {...props} />,
});
