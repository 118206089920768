import BetterPropTypes from 'better-prop-types';

export const performanceRule = BetterPropTypes.shape({
  createdAt: BetterPropTypes.string,
  deletedAt: BetterPropTypes.string,
  description: BetterPropTypes.string,
  gracePeriod: BetterPropTypes.number,
  harsh: BetterPropTypes.bool,
  isDeleted: BetterPropTypes.bool,
  key: BetterPropTypes.string.isOptionalButNotNull,
  modifiedAt: BetterPropTypes.string,
  pointsPerMetre: BetterPropTypes.number,
  pointsPerOccurrence: BetterPropTypes.number,
  pointsPerSecond: BetterPropTypes.number,
  type: BetterPropTypes.string,
  vehicleType: BetterPropTypes.string,
});

export const performanceRules = BetterPropTypes.arrayOf(performanceRule);
