import produce from 'immer';
import { sortBy, find, findIndex } from 'lodash';

import {
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  deepMergeEntities,
  removeEntity,
  actionTypeFailure,
  actionTypeRequest,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchUnidentifiedLogs: 'reports/unidentifiedLogs/fetchUnidentifiedLogs',
  fetchUnidentifiedLog: 'reports/unidentifiedLogs/fetchUnidentifiedLog',
  submitAssignLogDutyStatusEvent: 'reports/unidentifiedLogs/submitAssignLogDutyStatusEvent',
};

const initialState = {
  data: [],
  fetches: [],
  detailFetches: [],
};

export const unidentifiedLogsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchUnidentifiedLogs,
        ACTION_TYPE.fetchUnidentifiedLog,
      ])
    ) {
      draft.data = sortBy(deepMergeEntities(state.data, payload, 'key'), [
        'logDate',
        'vehicle.name',
      ]);
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchUnidentifiedLogs])) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        requestData,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchUnidentifiedLogs])) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchUnidentifiedLog])) {
      const { id, actionParam } = request;
      draft.detailFetches = shallowMergeEntities(state.detailFetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchUnidentifiedLog])) {
      draft.detailFetches = removeEntity(state.detailFetches, request);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.submitAssignLogDutyStatusEvent])) {
      const { logKey, ...rest } = payload;
      const { key } = rest;
      const log = find(draft.data, { key: logKey });
      const existingChangeIndex = findIndex(log.adminDriverChanges, { key });
      if (existingChangeIndex === -1) {
        log.adminDriverChanges.push(rest);
      } else {
        log.adminDriverChanges[existingChangeIndex] = rest;
      }
    }
  });
