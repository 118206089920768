import React, { useCallback, useState, useEffect } from 'react';
import { BasicDialog } from 'stti-react-common';
import { connect } from 'react-redux';

import { initiatePrint as initiatePrintAction } from '../../../../data/system';
import { driverLogCards } from '../../../../helpers/reports/printUtils';

import './DriverLogPrintCardDialog.scss';

export const DriverLogPrintCardDialog = connect(undefined, {
  initiatePrint: initiatePrintAction,
})(({ initiatePrint, driverLog, mode, isOpen, onClose, onCardSelect }) => {
  const handleClick = useCallback(() => initiatePrint({ mode: true }), [mode]);
  const [selectedCards, setSelectedCards] = useState(driverLogCards.map(({ key }) => key));
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    onCardSelect(selectedCards);
  }, [selectedCards]);

  useEffect(() => {
    if (!driverLog || !driverLog.vehicleDetails || driverLog.vehicleDetails.length === 0) {
      setFilteredCards(driverLogCards.filter((card) => card.key !== 'VehicleCard'));
    } else {
      setFilteredCards(driverLogCards);
    }
  }, [driverLog]);

  return (
    <BasicDialog
      className="Report__DriverLogPrintCardDialog"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Log Print Options"
      buttons={[
        {
          label: 'Cancel',
          onClick: () => {
            onClose();
          },
        },
        {
          label: 'Clear All',
          onClick: () => {
            setSelectedCards([]);
          },
        },
        {
          label: 'Select All',
          onClick: () => {
            setSelectedCards(driverLogCards.map(({ key }) => key));
          },
        },
        {
          label: 'Print',
          onClick: () => {
            handleClick();
            onClose();
            // This needs to be put here because of the RouteFrame and how this report works
            // we will hide the previous RouteFrame and show only the current RouteFrame to print.
            // after 5 seconds we will unset this rule so we can click on back and see the previous RouteFrame
            // normally
            const element = document.querySelector('.DriverLogsSummaryReport');
            const driverSummaryElement = element.parentNode;
            driverSummaryElement.style.display = 'none';

            setTimeout(() => {
              driverSummaryElement.style.display = 'unset';
            }, 5000);
          },
          disabled: selectedCards.length === 0,
        },
      ]}
    >
      <p className="DriverLogPrintCardDialog__Title">Select items to print: </p>
      <div className="DriverLogPrintCardDialog__Container">
        {filteredCards.map(({ key, label }) => (
          <div key={key}>
            <input
              type="checkbox"
              name="card"
              onClick={(ev) =>
                ev.target.checked
                  ? setSelectedCards([...selectedCards, key])
                  : setSelectedCards([...selectedCards.filter((row) => row !== key)])
              }
              checked={!!selectedCards.find((item) => item === key)}
              defaultChecked
            />
            <span>{label}</span>
          </div>
        ))}
      </div>
    </BasicDialog>
  );
});
