import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import { HidePrint } from '../../../../commons/ReportsCommon';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { effectiveRegulationsColumnDefs as columnDefs } from './columnDefs';

const { useDataGridController } = ControlledDataGrid;

export const EffectiveRegulationsCard = ({ log, hidePrint }) => {
  const { effectiveRegulations, regulation, cycleName } = log || {};

  const dataGridController = useDataGridController({ gridOptions, columnDefs });
  const titleName = "Effective Regulations (" + regulation + " - " + cycleName + ")";

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__EffectiveRegulationsCard" fullWidth>
        <h4>{titleName}</h4>
        {effectiveRegulations && (
          <ControlledDataTable
            controller={dataGridController}
            rows={effectiveRegulations}
            rowIdProperty="key"
          />
        )}
      </Card>
    </HidePrint>
  );
};

EffectiveRegulationsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
