import React from 'react';

import { CompanionAppSummaryContainer } from './Summary/connect';
import { CompanionAppDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const companionAppSummary = configureRoute({
  name: 'Companion Apps',
  group: administrationGroups.configurations,
  order: 3,
  path: '/apps',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => (
    <CompanionAppSummaryContainer
      {...props}
      openCompanionAppDetail={companionAppDetail.open}
      openCompanionAppCreate={companionAppCreate.open}
    />
  ),
});

export const companionAppCreate = configureRoute({
  path: '/app/new',
  switchKey: 'app',
  RouteFrame: BasicRouteFrame,
  render: (props) => <CompanionAppDetailContainer {...props} mode="create" />,
});

export const companionAppDetail = configureRoute({
  path: '/app/:appId',
  switchKey: 'app',
  RouteFrame: BasicRouteFrame,
  render: (props) => <CompanionAppDetailContainer {...props} />,
});
