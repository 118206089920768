/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { debugMode } from '../../../../helpers/debug';
import { Location } from '../../../../helpers/navigation/Location';
import { useReport } from '../useReport';

import './DetailReportPrintingLinks.scss';

const DETECTED_CLASSNAME = 'subReportLink'; // https://stti.myjetbrains.com/youtrack/issue/NAV2-9302

export const DetailReportPrintingLinks = ({ printMode, getDetailReportPath }) => {
  const [hrefs, setHrefs] = useState([]);

  const { filteredRows } = useReport();

  const active = useMemo(() => printMode.print && printMode.includeDetail, [printMode]);

  useEffect(() => {
    if (!active) return;
    Promise.all(
      filteredRows.dataGrid.map(async (row) => {
        const location = new Location();
        const detailReportPath = await getDetailReportPath(row);
        if (!detailReportPath) return null; // must exclude if callback returned falsy
        location.pathname = detailReportPath;
        location.query = { print: true };
        return location.toString();
      })
    )
      .then(filter) // drop excluded
      .then(setHrefs);
  }, [active, filteredRows.dataGrid]);

  if (!active) return null;

  if (debugMode) {
    window.localStorage.setItem('DetailReportPrintingLinks', hrefs);
  }

  return (
    <div className="Report__DetailReportPrintingLinks">
      {hrefs
        .map((url) => url.replace(/\/#/, '/#/reports'))
        .map((href) => (
          <a key={href} href={href} className={DETECTED_CLASSNAME} aria-label="Link" />
        ))}
    </div>
  );
};

DetailReportPrintingLinks.propTypes = {
  printMode: PropTypes.object.isRequired,
  getDetailReportPath: PropTypes.func.isRequired, // async functions supported
};
