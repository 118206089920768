import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useBooleanState } from 'stti-react-common';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { AdminSearchPanel, DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import { Feature } from '../../../../commons/Feature';

import { useDebounce } from '../../../../../helpers/hooks';
import { columnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const ApiKeySummary = ({ apiKeys, fetchApiKeys, deleteApiKey, openApiKeyCreate, route }) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <Button
        onClick={() => {
          setTargetRow(data);
          openDeleteDialog();
        }}
        icon="delete"
      />
    ),
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  useEffect(() => {
    fetchApiKeys();
  }, []);

  // RENDER
  return (
    <Feature.Wrapper className="ApiKeysSummary">
      <Feature.Header onClose={route.close} title="API Keys" service="apiKeys">
        <Button
          icon="add"
          variant="contained"
          label="Create New API Key"
          onClick={() => openApiKeyCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search API Keys"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={apiKeys}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="API Keys"
        itemTitle={targetRow.name}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteApiKey}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

ApiKeySummary.propTypes = {
  apiKeys: customPropTypes.apiKeys.isRequired,
  fetchApiKeys: PropTypes.func.isRequired,
  deleteApiKey: PropTypes.func.isRequired,
  openApiKeyCreate: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
