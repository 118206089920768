import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';

import { GoogleMap } from 'stti-react-common';

import { symbols, EventInfo } from '../../../../commons/MapsCommon';

const { Marker } = GoogleMap;

export const MapCameraTrips = (cameraTrips, controls, timeZone) =>
  useMemo(
    () =>
      cameraTrips
        .filter(({ startedAt }) => startedAt.slice(0, 10) === controls.startDate)
        .map((row, index) => (
          <span key={uniqueId()}>
            <Marker position={row.origin} selection={row.origin} icon={symbols.go} order={0}>
              <EventInfo
                event={{
                  eventType: `Trip ${index + 1} Start`,
                  eventAt: row.startedAt,
                  timeZone,
                }}
                trip={row}
              />
            </Marker>
            <Marker position={row.lastUnload} icon={symbols.stop} order={Marker.MAX_ORDER}>
              <EventInfo
                event={{
                  eventType: `Trip #${index + 1} End`,
                  eventAt: row.endedAt,
                  timeZone,
                }}
                trip={row}
              />
            </Marker>
          </span>
        )),
    [cameraTrips]
  );
