import { connect } from 'react-redux';

import { SpeedViolationsSummaryReport } from './SpeedViolationsSummaryReport';
import { selectSpeedViolations, fetchSpeedViolationsOptimized } from '../../../../data/reports';
import { selectSystemUser, selectSearchLimits } from '../../../../data/system';

export const SpeedViolationsSummaryReportContainer = connect(
  (state) => ({
    speedViolations: selectSpeedViolations(state),
    userKey: selectSystemUser(state).key,
    limits: selectSearchLimits(state, 'SpeedViolations'),
  }),
  { fetchSpeedViolations: fetchSpeedViolationsOptimized }
)(SpeedViolationsSummaryReport);
