import { sumBy, maxBy } from 'lodash';

// values is array of objects output from mileageColumn valueGetter, properties are NOT row fields
export const mileage = (values) => {
  const distance = sumBy(values, 'distance');
  const fuel = sumBy(values, 'fuel');
  return {
    distance,
    fuel,
    toNumber: () => distance / fuel,
  };
};

// values must be array of objects with rank property; highest-ranked value is returned
export const sig = (values) => maxBy(values, 'rank');
