import BetterPropTypes from 'better-prop-types';

export const inspectionTask = BetterPropTypes.shape({
  ou: BetterPropTypes.shape({
    name: BetterPropTypes.string,
    key: BetterPropTypes.string,
  }),
  defectTypes: BetterPropTypes.array,
  name: BetterPropTypes.string.isOptionalButNotNull,
  description: BetterPropTypes.string,
  categories: BetterPropTypes.array,
  key: BetterPropTypes.string.isOptionalButNotNull,
  isSystem: BetterPropTypes.bool,
});

export const inspectionTasks = BetterPropTypes.arrayOf(inspectionTask);
