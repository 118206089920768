import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { SelectInput } from 'stti-react-common';
import './AdminToggleSelectInput.scss';

const modifier = (value) => {
  if (isNil(value)) return 'undefined';
  return value ? 'on' : 'off';
};

export const AdminToggleSelectInput = ({ value, className = '', ...restProps }) => (
  <SelectInput
    value={value}
    className={`AdminToggleSelectInput AdminToggleSelectInput--${modifier(value)} ${className}`}
    {...restProps}
  />
);

AdminToggleSelectInput.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
};
