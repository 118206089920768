import { connect } from 'react-redux';

import { CyclesSummaryReport } from './CyclesSummaryReport';
import { fetchTripsOptimized, selectTrips, createLocalReportView } from '../../../../data/reports';
import { selectSearchLimits } from '../../../../data/system';

export const CyclesSummaryReportContainer = connect(
  (state) => ({
    trips: selectTrips(state),
    limits: selectSearchLimits(state, 'cycles'),
  }),
  {
    fetchTrips: fetchTripsOptimized,
    createLocalReportView,
  }
)(CyclesSummaryReport);
