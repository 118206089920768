/**
 * selectVideoEventTypes(state) -> [string]
 *
 * Selector for all video event's possible eventTypes as strings (video events have exactly one)
 */
export const selectVideoEventTypes = (state) => state.reports.videoEvents.types;

/**
 * selectVideoEventTypesFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for video types
 */
export const selectVideoEventTypesFetchRequests = (state) => state.reports.videoEvents.typesFetches;

/**
 * selectVideoEventBehaviors(state) -> [string]
 *
 * Selector for all video event's possible behaviors as strings (video events have zero or more)
 */
export const selectVideoEventBehaviors = (state) => state.reports.videoEvents.behaviors;

/**
 * selectVideoEventBehaviorsFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for video behaviors
 */
export const selectVideoEventBehaviorsFetchRequests = (state) =>
  state.reports.videoEvents.behaviorsFetches;

/**
 * selectVideoEventFileLocation(state, { id }) -> { id: string, url: string, expiresAt: string }
 *
 * Selector for single video by id
 */
export const selectVideoEventFileLocation = (state, { id }) =>
  state.reports.videoEvents.fileLocationsById[id];

/**
 * selectVideoEventFileLocationFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for video locations
 */
export const selectVideoEventFileLocationFetchRequests = (state) =>
  state.reports.videoEvents.fileLocationsFetches;
