import { omit, isNil, filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectLocationTypesFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchLocationTypes = makeRestApiAction({
  service: 'locationTypes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchLocationTypes,
  notificationsItemDescriptor: 'location types',
});

export const fetchLocationTypesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectLocationTypesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchLocationTypes,
  baseActionType: ACTION_TYPE.fetchLocationTypes,
});

const fetchLocationType = makeRestApiAction({
  service: 'locationTypes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchLocationType,
  getId: (key) => key,
  notificationsItemDescriptor: 'location type',
});

export const fetchLocationTypeOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectLocationTypesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchLocationType,
  baseActionType: ACTION_TYPE.fetchLocationType,
});

export const createLocationType = makeRestApiAction({
  service: 'locationTypes',
  method: 'post',
  baseActionType: ACTION_TYPE.createLocationType,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => responseData,
  notificationsItemDescriptor: 'location type',
  throwApiErrors: true,
});

export const updateLocationType = makeRestApiAction({
  service: 'locationTypes',
  method: 'put',
  baseActionType: ACTION_TYPE.updateLocationType,
  getId: ({ key }) => key,
  transformInput: (data) => omit(data, isNil),
  notificationsItemDescriptor: 'location type',
  throwApiErrors: true,
});

export const deleteLocationType = makeRestApiAction({
  service: 'locationTypes',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteLocationType,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'location type',
});
