import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'stti-react-common';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { FiltersPanel } from './FiltersPanel';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  MapIcon,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './FuelConsumptionsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: { dateRangeMode: 'last7Days' },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['fuelConsumptions'];

export const FuelConsumptionsSummaryReport = ({
  fetchFuelConsumptions,
  fuelConsumptions,
  limits,
  openFuelConsumptionLocationReportRoute,
  reportViewId,
  route,
}) => {
  // INITIALIZE REPORT

  useEffect(() => {
    dataGridController.methods.expandGroups();
  }, [fuelConsumptions]);

  const report = useReportController({
    reportType: 'fuelConsumptionsSummary',
    reportTypeName: 'Fuel Consumption Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: fuelConsumptions,
      chain: ['query', 'filters'],
    },
  });
  const {
    debouncedControls,
    setControl,
    filteredRows,
    dataGridController,
    setDataGridFilteredRows,
    reportType,
  } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicles', [data?.vehicle?.key])}>{value}</Clickable>
    ),
    Driver: ({ data, value }) => (
      <Clickable onClick={() => setControl('drivers', [data?.driver?.key])}>{value}</Clickable>
    ),
    Trailers: ({ value }) =>
      value && value.length > 0 ? (
        <ul>
          {value.map((row) => (
            <li key={row}>{row}</li>
          ))}
        </ul>
      ) : (
        '—'
      ),
    Origin: ({ value }) => (
      <Clickable onClick={() => setControl('origins', [value])}>{value}</Clickable>
    ),
    FirstLoad: ({ value }) => (
      <Clickable onClick={() => setControl('firstLoads', [value])}>{value}</Clickable>
    ),
    LastUnload: ({ value }) => (
      <Clickable onClick={() => setControl('lastUnloads', [value])}>{value}</Clickable>
    ),
    RouteMapHeader: asDataGridHeaderComponent(() => <MapIcon />),
    RouteMap: ({ data: { id, ouKey } }) => (
      <Clickable
        onClick={() => openFuelConsumptionLocationReportRoute({ fuelConsumptionId: id, ou: ouKey })}
      >
        <MapIcon />
      </Clickable>
    ),
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchFuelConsumptions} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="FuelConsumptionsSummaryReport">
        <ReportFragment.Header
          reportType="fuelConsumptionsSummary"
          route={route}
          services={requestServices}
        />

        <QueryPanel queryFor="trip" group="query" minAllowedDate="2018-01-01" disableFuture />
        <FiltersPanel rows={filteredRows.query} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel
          dataGridController={dataGridController}
          dataGridComponents={dataGridComponents}
          filteredRows={filteredRows}
          setDataGridFilteredRows={setDataGridFilteredRows}
          debouncedControls={debouncedControls}
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.output} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

FuelConsumptionsSummaryReport.propTypes = {
  fetchFuelConsumptions: PropTypes.func.isRequired,
  fuelConsumptions: customPropTypes.fuelConsumptions.isRequired,
  limits: PropTypes.object,
  openFuelConsumptionLocationReportRoute: PropTypes.func.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
