import React from 'react';
import PropTypes from 'prop-types';

import { ColorChip } from '../ColorChip';

// For any labelText different from default values, we also need to add those labels as props
// inside enabledDisabledColumn at respective columnDef file.

//  Limit ToggleChip to original on/off purpose. Use of ColorChip is recommended for broader use.

export const ToggleChip = ({
  value,
  baseLabelText = '',
  trueLabelText = 'Enabled',
  falseLabelText = 'Disabled',
  undefinedLabelText = 'N/A',
  ...restProps
}) => {
  const label = `${baseLabelText}${value}`;

  let color = 'grey';
  if (value === trueLabelText) {
    color = 'green';
  } else if (value === falseLabelText) {
    color = 'red';
  }

  return <ColorChip label={label} color={color} {...restProps} />;
};

ToggleChip.propTypes = {
  value: PropTypes.string,
  baseLabelText: PropTypes.string,
  trueLabelText: PropTypes.string,
  undefinedLabelText: PropTypes.string,
  falseLabelText: PropTypes.string,
};
