import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchInspectionTemplates: 'administration/configurations/inspections/fetchInspectionTemplates',
  fetchInspectionTemplate: 'administration/configurations/inspections/fetchInspectionTemplate',
  deleteInspectionTemplate: 'administration/configurations/inspections/deleteInspectionTemplate',
  updateInspectionTemplate: 'administration/configurations/inspections/updateInspectionTemplate',
  createInspectionTemplate: 'administration/configurations/inspections/createInspectionTemplate',
};

export const inspectionTemplatesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchInspectionTemplates)) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteInspectionTemplate)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchInspectionTemplate,
        ACTION_TYPE.createInspectionTemplate,
        ACTION_TYPE.updateInspectionTemplate,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (
      actionTypeRequest(type, [
        ACTION_TYPE.fetchInspectionTemplates,
        ACTION_TYPE.fetchInspectionTemplate,
      ])
    ) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (
      actionTypeFailure(type, [
        ACTION_TYPE.fetchInspectionTemplates,
        ACTION_TYPE.fetchInspectionTemplate,
      ])
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
