import { connect } from 'react-redux';

import { TabletSummary } from './summary';
import { tablet } from '../../../../../data/administration/devices';

export const TabletSummaryContainer = connect(
  (state) => ({
    tablets: tablet.selectors.selectTablets(state),
  }),
  {
    fetchTablets: tablet.actions.fetchTabletsOptimized,
    fetchTabletDiagnosticConfigs: tablet.actions.fetchTabletDiagnosticConfigsOptimized,
    deleteTablet: tablet.actions.deleteTablet,
    updateTablet: tablet.actions.updateTablet,
    updateTabletDiagnosticConfigs: tablet.actions.updateTabletDiagnosticConfigs,
  }
)(TabletSummary);
