import { createSelector } from 'reselect';

const getBreadcrumbsFetchRequests = (state) => state.reports.breadcrumbs.fetches;
const getRefinedBreadcrumbsFetchRequests = (state) => state.reports.breadcrumbs.refinedFetches;

/**
 * selectVehiclesBreadcrumbs(state) -> [breadcrumb]
 *
 * Selector for all regular and refined breadcrumbs, keyed by vehicleKey
 */
export const selectVehiclesBreadcrumbs = (state) => state.reports.breadcrumbs.byVehicleKey;

/**
 * selectBreadcrumbsFetches(state) -> [fetchRequestData]
 *
 * Selector for all regular breadcrumbs fetches (active and successful), requestData only
 */
export const selectBreadcrumbsFetches = createSelector(
  getBreadcrumbsFetchRequests,
  (fetchRequests) => fetchRequests.map((fetchRequest) => fetchRequest.requestData)
);

/**
 * selectRefinedBreadcrumbsFetches(state) -> [fetchRequestData]
 *
 * Selector for all refinedBreadcrumbs fetches (active and successful), requestData only
 */
export const selectRefinedBreadcrumbsFetches = createSelector(
  getRefinedBreadcrumbsFetchRequests,
  (fetchRequests) => fetchRequests.map((fetchRequest) => fetchRequest.requestData)
);
