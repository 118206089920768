import { connect } from 'react-redux';

import { VehicleDetail } from './detail';

import {
  fetchTenantVehicleConfig,
  fetchCountriesOptimized,
  selectSystemTenant,
  selectCountries,
  selectIsUserSuperAdmin,
  selectSystemUser,
  selectSystemTenantOu,
  selectSystemOus,
} from '../../../../../data/system';

import { vehicle, vehicleType } from '../../../../../data/administration/resources';
import { camera } from '../../../../../data/administration/devices';

export const VehicleDetailContainer = connect(
  (state, { assetId }) => {
    const isSuperAdmin = selectIsUserSuperAdmin(state);
    return {
      cameraDevices: camera.selectors.selectCameraDevices(state),
      countriesList: selectCountries(state),
      isSuperAdmin,
      ous: selectSystemOus(state),
      tenant: selectSystemTenant(state),
      tenantOu: selectSystemTenantOu(state),
      user: selectSystemUser(state),
      vehicle: vehicle.selectors.selectVehicle(state, assetId),
      vehicles: vehicle.selectors.selectVehicles(state),
      vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
    };
  },
  {
    createVehicle: vehicle.actions.createVehicle,
    deleteVehicle: vehicle.actions.deleteVehicle,
    fetchCameraDevices: camera.actions.fetchCameraDevicesOptimized,
    fetchCountries: fetchCountriesOptimized,
    fetchTenantVehicleConfig,
    fetchVehicle: vehicle.actions.fetchVehicleOptimized,
    fetchVehicleRemovePairing: vehicle.actions.fetchVehicleRemovePairing,
    fetchVehicles: vehicle.actions.fetchVehiclesOptimized,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
    updateVehicle: vehicle.actions.updateVehicle,
  }
)(VehicleDetail);
