import { camelCase, startCase } from 'lodash';

import { columnDef } from '../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'title',
    headerName: 'Report',
  }),
  columnDef.textColumn({
    field: 'toEmails',
    headerName: 'Delivered To',
    valueFormatter: ({ value }) => value && value.join(', '),
  }),
  columnDef.textColumn({
    field: 'includeDetail',
    headerName: 'Includes Detail',
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  }),
  columnDef.textColumn({
    field: 'orientation',
    headerName: 'Orientation',
    valueFormatter: ({ value }) => value && startCase(camelCase(value)),
  }),
  columnDef.dateColumn({
    field: 'hourAt',
    headerName: 'Deliver At',
    variant: 'datelessTime',
  }),
  columnDef.dateColumn({
    field: 'endAt',
    headerName: 'Deliver Until',
    variant: 'timelessDate',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
