/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { clone, includes, isEmpty, orderBy, sortBy, uniqueId, upperFirst, some } from 'lodash';
import { Icon, ScrollBar, SelectInput, TextInput, Button } from 'stti-react-common';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import {
  getMinutes,
  fromToCalendarTime,
  dateObject,
  shortTimeFormatter,
} from '../../../../helpers/moment';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { makeIcon } from '../../MapsCommon';
import { useFormats } from '../../Formats';

import { getUnreadMessages } from '../../../../data/messenger/helpers/notifications';

import './FleetTrackingDriverVehicleSettings.scss';

function applyDriverLocationStyle(minutes, defaultValue, isLoggedIn) {
  // Scenario 01 - With Diver and from 0 to 29 minutes (Green marking)
  if (minutes >= 0 && minutes < 30) {
    return {
      age: minutes,
      class: 'age-fresh',
      color: '#0DAB0D',
    };
  }

  // Scenario 02 - With Diver and from 30 to 59 minutes (Yellow marking)
  if (minutes >= 30 && minutes < 60) {
    return {
      age: minutes,
      class: 'age-current',
      color: '#AB830D',
    };
  }

  // Scenario 03 - With Diver and from 60 to 1439 minutes (Red marking)
  if (minutes >= 60 && minutes < 1440 && isLoggedIn) {
    return {
      age: minutes,
      class: 'age-stale',
      color: '#AB0D0D',
    };
  }

  // Scenario 04 - With Diver more from than 1440 minutes (Grey marking)
  return defaultValue;
}

function getLocationStyle(location) {
  const dateString = (location && location.date) || null;

  // MBL-8991
  const minutes = getMinutes(dateString);

  const defaultValue = {
    age: minutes,
    class: 'age-old',
    color: '#777777',
  };

  const isLoggedIn = location.driver && location.vehicle;

  // DRIVERS TAB
  if (location.type === 'driver') {
    return applyDriverLocationStyle(minutes, defaultValue, isLoggedIn);
  }

  // VEHICLES TAB
  if (location.type === 'vehicle') {
    if (!location.driver) {
      // Scenario 01 - No Diver and from 0 to 29 minutes (Green marking)
      if (minutes >= 0 && minutes < 30) {
        return {
          age: minutes,
          class: 'age-fresh',
          color: '#0DAB0D',
        };
      }

      // Scenario 02 - No Diver and from 30 to 59 minutes (Yellow marking)
      if (minutes >= 30 && minutes < 60) {
        return {
          age: minutes,
          class: 'age-current',
          color: '#AB830D',
        };
      }

      // Scenario 03 - No Diver and more than 60 minutes (Grey marking)
      return defaultValue;
    }
    return applyDriverLocationStyle(minutes, defaultValue, isLoggedIn);
  }

  return defaultValue;
}

function setIcon(selected, item, mapperType) {
  if (mapperType === 'drivers') {
    if (!isEmpty(item.vehicleId) && !item.hasMessages) {
      return <Icon icon="local_shipping" />;
    }
    if (item.hasMessages && !selected) {
      return <Icon icon="mail" />;
    }
    return <Icon icon="person" />;
  }

  return <Icon icon="local_shipping" />;
}

function showTractorNameOrUnhookReason(item) {
  if (item.connectedToName) {
    return item.connectedToName;
  }

  return item.unhookReason ? upperFirst(item.unhookReason?.toLowerCase()) : 'Disconnected ';
}

export const FleetTrackingDriverVehicleSettings = ({
  clusterNearbyVehicles = false,
  currentOu,
  currentUser,
  filteredVehicleTypes,
  fleetTrackData,
  mapInstance,
  messages,
  onFocusReturn,
  openTrackingSelection,
  organizationList,
  onSelectedEntity,
  selectedEntity,
  showTrafficOverlay = false,
  showVehicleLabels = false,
}) => {
  const { formatUnit } = useFormats();

  const isGuest = some(currentUser?.memberships, (membership) =>
    membership?.role?.includes('S-guest')
  );

  const [mapperType, setMapperType] = useState(isGuest ? 'vehicles' : 'drivers');
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectOuKey, setSelectOuKey] = useState('');
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(null);
  const [showOnlyActive, setShowOnlyActive] = useState(true);

  const { MarkerClusterer, Marker, Info, TrafficLayer } = mapInstance;

  useEffect(() => {
    onSelectedEntity(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(selectedEntity);
  }, [selectedEntity]);

  const addCameraProvider = (row) => {
    const copy = clone(row);
    if (copy.camera && copy.camera.provider) {
      copy.cameraProvider =
        copy.camera.provider.toLowerCase() === 'surfsight' ? 'NAVISTREAM' : copy.camera.provider;
    } else {
      copy.cameraProvider = 'No Camera Provider.';
    }
    return copy.cameraProvider;
  };

  const filterByName = (searchName, type, name) => {
    if (type === 'drivers' || type === 'vehicles' || type === 'trailers') {
      return includes(name.toLowerCase(), searchName.toLowerCase());
    }
    return true;
  };

  const setListItem = (type, item) => {
    if (type === 'drivers') {
      return (
        <div
          className={`Entity ${item.key === selected?.key ? 'selected' : 'neutral'} ${
            item.key !== selected?.key && item.hasMessages ? 'messages' : ''
          }`}
          key={uniqueId()}
          onClick={() => (item.key !== selected?.key ? setSelected(item) : setSelected(null))}
        >
          <div className="Entity__icon">{setIcon(selected, item, type)}</div>
          <div className="Entity__info">
            <span className="Entity__info__title">{item.driverName || 'Unknown'}</span>
            <span className="Entity__info__subtitle">{item.vehicleId || 'Not Available'}</span>
          </div>
          <div className="Entity__status">
            <div className="Entity__status__icon">
              {item.camera && item.camera.provider === 'SURFSIGHT' && <Icon icon="videocam" />}
            </div>
            <div className="Entity__status__summary">{item.timeSummary}</div>
            <div className="Entity__status__dot" style={{ backgroundColor: item.style?.color }} />
          </div>
        </div>
      );
    }
    if (type === 'vehicles') {
      return (
        <div
          className={`Entity ${item.key === selected?.key ? 'selected' : 'neutral'}`}
          key={uniqueId()}
          onClick={() => (item.key !== selected?.key ? setSelected(item) : setSelected(null))}
        >
          <div className="Entity__icon">{setIcon(item, type)}</div>
          <div className="Entity__info">
            <span className="Entity__info__title">{item.vehicleId || 'Unknown'}</span>
            <span className="Entity__info__subtitle">
              {isEmpty(item.connectedTrailerNames)
                ? 'No trailers connected'
                : item.connectedTrailerNames.join(', ')}
            </span>
          </div>
          <div className="Entity__status">
            <div className="Entity__status__icon">
              {item.camera && item.camera.provider === 'SURFSIGHT' && <Icon icon="videocam" />}
            </div>
            <span className="Entity__status__summary">{item.timeSummary}</span>
            <span className="Entity__status__dot" style={{ backgroundColor: item.style?.color }} />
          </div>
        </div>
      );
    }

    return (
      <div
        className={`Entity ${item.key === selected?.key ? 'selected' : 'neutral'}`}
        key={uniqueId()}
        onClick={() => (item.key !== selected?.key ? setSelected(item) : setSelected(null))}
      >
        <div className="Entity__icon">{setIcon(item, type)}</div>
        <div className="Entity__info">
          <span className="Entity__info__title">{item.vehicleId || 'Unknown'}</span>
          <span className="Entity__info__subtitle">{showTractorNameOrUnhookReason(item)}</span>
        </div>
        <div className="Entity__status">
          <div className="Entity__status__icon">
            {item.camera && item.camera.provider === 'SURFSIGHT' && <Icon icon="videocam" />}
          </div>
          <span className="Entity__status__summary">{item.timeSummary}</span>
          <span className="Entity__status__dot" style={{ backgroundColor: item.style?.color }} />
        </div>
      </div>
    );
  };

  const setTrailerNameByType = (item) => {
    if (!item) {
      return 'No Trailers';
    }
    if (item.trailerClass) {
      return item.connectedToName || 'No Vehicle';
    }
    return item.connectedTrailerNames?.join(', ') || 'No Trailers';
  };

  // VEHICLES, DRIVERS AND TRAILERS MAPPERS
  const mappers = useMemo(() => {
    const drivers = [];
    fleetTrackData?.driverActivity
      ?.filter((item) => !item.deleted)
      .forEach((item) => {
        const row = clone(item);
        const vehicleToDriver = fleetTrackData?.vehicleLocations?.find(
          (vehicle) => vehicle.driver === row.key && vehicle.trailerClass === false
        );

        if (vehicleToDriver) {
          const date1 = new Date(item.date);
          const date2 = new Date(vehicleToDriver.date);

          row.connectedTrailerNames = vehicleToDriver.connectedTrailers.map(
            (id) =>
              fleetTrackData?.vehicleLocations?.find(({ vehicle }) => vehicle === id)?.vehicleId
          );

          if (date1 < date2) {
            row.date = vehicleToDriver.date;
          }

          row.cameraProvider = addCameraProvider(vehicleToDriver);
          row.ignored = !includes(filteredVehicleTypes, vehicleToDriver.vehicleType);
          row.loaded = vehicleToDriver.loaded;
        }

        row.uuid = uuid();
        row.type = 'driver';
        row.style = getLocationStyle({ ...vehicleToDriver, ...row });
        row.hasMessages = getUnreadMessages(messages, row.key);
        row.timeSummary = row.date ? shortTimeFormatter(row.date) : 'N/A';

        drivers.push({ ...vehicleToDriver, ...row });
      });

    const vehicles = [];
    fleetTrackData?.vehicleLocations
      ?.filter((item) => !item.deleted && item.trailerClass === false)
      ?.filter((item) => includes(filteredVehicleTypes, item.vehicleType))
      .forEach((item) => {
        const row = clone(item);

        const driverToVehicle = fleetTrackData?.driverActivity?.find(
          (driver) => driver.key === row.driver
        );
        if (driverToVehicle) {
          const date1 = new Date(row.date);
          const date2 = new Date(driverToVehicle.date);

          if (date1 < date2) {
            row.date = driverToVehicle.date;
          }
        }

        row.uuid = uuid();
        row.key = row.vehicle;
        row.type = 'vehicle';
        row.style = getLocationStyle(row);
        row.dutyStatusDescription = driverToVehicle?.dutyStatusDescription;
        row.cameraProvider = addCameraProvider(item);
        row.timeSummary = row.date ? shortTimeFormatter(row.date) : 'N/A';
        row.connectedTrailerNames = row.connectedTrailers.map(
          (id) => fleetTrackData?.vehicleLocations?.find(({ vehicle }) => vehicle === id)?.vehicleId
        );
        row.ignored = !includes(filteredVehicleTypes, item.vehicleType);
        row.connectedTractorIds = fleetTrackData?.driverActivity
          ?.filter(() => includes(row.connectedTrailers))
          .map(({ vehicleId }) => vehicleId);
        vehicles.push({ ...driverToVehicle, ...row });
      });

    const trailers = [];
    fleetTrackData?.vehicleLocations
      ?.filter((item) => !item.deleted && item.trailerClass === true)
      ?.filter((item) => includes(filteredVehicleTypes, item.vehicleType))
      .forEach((item) => {
        const row = clone(item);

        if (row.trailerClass && row.connectedTo) {
          const connectedTractor = fleetTrackData?.vehicleLocations?.find(
            ({ vehicle, trailerClass }) => vehicle === row.connectedTo && trailerClass === false
          );

          if (connectedTractor) {
            const driverToConnectedTractor = drivers.find(
              (driver) => driver.key === connectedTractor.driver
            );

            if (driverToConnectedTractor) {
              const date1 = new Date(row.date);
              const date2 = new Date(driverToConnectedTractor.date);

              if (date1 < date2) {
                row.date = driverToConnectedTractor.date;
              }
              row.driver = driverToConnectedTractor.key;
            }

            row.connectedToName = connectedTractor.vehicleId;
            row.source = connectedTractor.source;
            row.camera = connectedTractor.camera;
            row.cameraProvider = addCameraProvider(connectedTractor);
            row.dutyStatusDescription = driverToConnectedTractor?.dutyStatusDescription;
            row.ignored = !includes(filteredVehicleTypes, item.vehicleType);
            row.loaded = connectedTractor.loaded;
          }
        }
        row.uuid = uuid();
        row.key = row.vehicle;
        row.type = 'vehicle';
        row.style = getLocationStyle(row);
        row.timeSummary = row.date ? shortTimeFormatter(row.date) : 'N/A';
        trailers.push(row);
      });

    const driversWithMessages = sortBy(
      drivers.filter((row) => row.hasMessages),
      (row) => row.driverName.toLowerCase()
    );

    const driversSorted = sortBy(
      drivers.filter((row) => !row.hasMessages),
      (row) => row.driverName.toLowerCase()
    );

    return {
      drivers: [...driversWithMessages, ...driversSorted],
      trailers: sortBy(trailers, (row) => row.vehicleId.toLowerCase()),
      vehicles: sortBy(vehicles, (row) => row.vehicleId.toLowerCase()),
    };
  }, [fleetTrackData, filteredVehicleTypes]);

  const mapMarkers = useMemo(() => {
    const activeMarkers = mappers[mapperType]
      .filter((marker) => !isEmpty(marker.location))
      .filter(({ driverName, vehicleId }) =>
        filterByName(search, mapperType, driverName || vehicleId)
      )
      .filter(({ ous }) => (isEmpty(selectOuKey) ? true : includes(ous, selectOuKey)))
      .filter(({ style }) => (showOnlyActive ? style.class !== 'age-old' : true))
      .filter(({ ignored }) => !ignored)
      .map((marker, index) => (
        <Marker
          position={marker.location}
          icon={makeIcon(
            marker.style.color,
            '#fff',
            null,
            marker.key === selected?.key,
            marker.loaded
          )}
          order={index}
          key={index}
          selection={marker}
          rotation={marker.heading || 0}
          label={showVehicleLabels ? { text: marker.vehicleId, className: 'marker-title' } : null}
        >
          <Info>
            <div className="marker-info">
              <Icon icon="person" />{' '}
              <span className="marker-label">{marker.driverName || 'No Driver'}</span>
            </div>
            <div className="marker-info">
              <Icon icon="apps" />{' '}
              <span className="marker-label">
                {marker.dutyStatusDescription || 'Not Available'}
              </span>
            </div>
            <div className="marker-info">
              <Icon icon="local_shipping" />
              <span className="marker-label">
                {marker?.vehicleId || 'No Vehicle'}
                <span className="marker-label">{`(${marker.loaded ? 'Loaded' : 'Unloaded'})`}</span>
              </span>
            </div>
            <div className="marker-info">
              <Icon icon="link" />
              <span className="marker-label">{setTrailerNameByType(marker)}</span>
            </div>
            <div className="marker-info">
              <Icon icon="speed" />
              <span className="marker-label">{formatUnit('speed', marker.speed)}</span>
            </div>
            <div className="marker-info">
              <Icon icon="videocam" />
              <span className="marker-label">{marker.cameraProvider}</span>
            </div>
            <div style={{ background: marker.style.color, color: 'white', padding: 2 }}>
              {fromToCalendarTime(dateObject(marker.date), currentOu.timeZone)}
            </div>
          </Info>
        </Marker>
      ));

    let focus = activeMarkers.map(({ props: { position } }) => position);

    if (focus.length === 0) {
      // this coordinates will centralize the map into north america
      focus = [
        {
          lat: 64.835365,
          lng: -147.776749,
        },
        {
          lat: 67.010323,
          lng: -50.712353,
        },
        {
          lat: 19.451054,
          lng: -99.125519,
        },
      ];
    }

    onFocusReturn(focus);

    return activeMarkers;
  }, [mappers, mapperType, search, selectOuKey, showOnlyActive, showVehicleLabels, selected?.key]);

  const types = isGuest ? ['Vehicles', 'Trailers'] : ['Drivers', 'Vehicles', 'Trailers'];

  return (
    <div className="FleetTrackingLocator">
      <ul className="FleetTrackingLocator__navigator">
        {types.map((list) => (
          <li
            key={list}
            className={mapperType.toLowerCase() === list.toLowerCase() ? 'activeFilter' : ''}
            onClick={() => {
              setMapperType(list.toLowerCase());
              setSelected(null);
              setSearch('');
            }}
          >
            {list}
          </li>
        ))}
      </ul>
      <div className="FleetTrackingLocator__filter">
        <Icon
          icon={!isFiltered ? 'check_box_outline_blank' : 'check_box'}
          onClick={() => {
            if (isFiltered) {
              setSelectOuKey('');
            }
            setIsFiltered(!isFiltered);
          }}
        />
        <span>Filter By Organization</span>
      </div>
      <div className="FleetTrackingLocator__list">
        <SelectInput
          className="ouListSelect"
          label="Organization"
          disabled={!isFiltered}
          options={orderBy(
            organizationList.map(({ key, name }) => ({ value: key, label: name })),
            ['label']
          )}
          value={selectOuKey}
          onChange={setSelectOuKey}
        />
        <TextInput
          value={search}
          placeholder="Search"
          onChange={setSearch}
          startAdornment={<Icon icon="search" />}
          endAdornment={
            <Icon style={{ cursor: 'pointer' }} icon="close" onClick={() => setSearch('')} />
          }
        />
        <ScrollBar style={{ width: '200px', height: '60vh', overflowY: 'hidden !important' }}>
          {mappers[mapperType]
            .filter(({ driverName, vehicleId }) =>
              filterByName(search, mapperType, driverName || vehicleId)
            )
            .filter(({ ous }) => (isEmpty(selectOuKey) ? true : includes(ous, selectOuKey)))
            .filter(({ style }) => (showOnlyActive ? style.class !== 'age-old' : true))
            .map((item) => setListItem(mapperType, item))}
        </ScrollBar>
        <hr />
        <div className="FleetTrackingLocator__activeRows">
          <Icon
            icon={!showOnlyActive ? 'check_box_outline_blank' : 'check_box'}
            onClick={() => setShowOnlyActive(!showOnlyActive)}
          />
          <span>Hide Inactive</span>
          <Button
            icon="info"
            tooltip="Only vehicles with known location will be shown"
            onClick={() => {}}
          />
        </div>
        <div className="FleetTrackingLocator__trackingSelection">
          <Icon icon="tune" />
          <span onClick={() => openTrackingSelection()}>Change tracking selection</span>
        </div>
      </div>
      {clusterNearbyVehicles && <MarkerClusterer markerColor="blue" opacity={0.5} />}
      {showTrafficOverlay && <TrafficLayer />}
      {mapMarkers}
    </div>
  );
};

FleetTrackingDriverVehicleSettings.propTypes = {
  clusterNearbyVehicles: PropTypes.bool,
  currentOu: customPropTypes.organization,
  currentUser: customPropTypes.user,
  filteredVehicleTypes: PropTypes.array,
  fleetTrackData: PropTypes.any,
  mapInstance: PropTypes.any,
  messages: PropTypes.array,
  onFocusReturn: PropTypes.func,
  selectedEntity: PropTypes.object,
  onSelectedEntity: PropTypes.func,
  openTrackingSelection: PropTypes.func,
  organizationList: customPropTypes.organizations,
  showTrafficOverlay: PropTypes.bool,
  showVehicleLabels: PropTypes.bool,
};
