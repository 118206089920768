import { connect } from 'react-redux';

import { InspectionTaskSummary } from './summary';

import { inspectionTask } from '../../../../../data/administration/configurations';

export const InspectionTaskSummaryContainer = connect(
  (state) => ({
    inspectionTasks: inspectionTask.selectors.selectInspectionTasks(state),
  }),
  {
    fetchInspectionTasks: inspectionTask.actions.fetchInspectionTasksOptimized,
    deleteInspectionTask: inspectionTask.actions.deleteInspectionTask,
    createInspectionTask: inspectionTask.actions.createInspectionTask,
    updateInspectionTask: inspectionTask.actions.updateInspectionTask,
  }
)(InspectionTaskSummary);
