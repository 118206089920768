import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Clickable, useBooleanState } from 'stti-react-common';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { Feature } from '../../../../commons/Feature';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel } from '../../../../commons/AdminCommon/AdminSearchPanel';
import { DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import { columnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const InspectionTaskSummary = ({
  inspectionTasks,
  fetchInspectionTasks,
  deleteInspectionTask,
  openInspectionTaskDetail,
  openInspectionTaskCreate,
  openOrganizationDetail,
  route,
}) => {
  useEffect(() => {
    fetchInspectionTasks();
  }, []);

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) =>
      !data.isSystem && (
        <>
          <Button
            icon="edit"
            onClick={() => openInspectionTaskDetail({ taskId: data.key, mode: 'edit' })}
          />
          <Button
            icon="delete"
            onClick={() => {
              setTargetRow(data);
              openDeleteDialog();
            }}
          />
        </>
      ),
    Name: ({ data, value }) => (
      <Clickable
        onClick={() => {
          openInspectionTaskDetail({ taskId: data.key, mode: 'view' });
        }}
      >
        {value}
      </Clickable>
    ),
    Description: ({ value }) => value || '—',
    AppliesTo: ({ data, value }) =>
      value ? (
        <Clickable
          onClick={() => {
            openOrganizationDetail({ organizationId: data.ou.key });
          }}
        >
          {value}
        </Clickable>
      ) : (
        '—'
      ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  // RENDER
  return (
    <Feature.Wrapper className="InspectionTaskSummary">
      <Feature.Header onClose={route.close} title="Inspection Tasks" service="inspectionsTasks">
        <Button
          icon="add"
          variant="contained"
          label="Create New Inspection Task"
          onClick={() => openInspectionTaskCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search By"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={inspectionTasks}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Inspection Task"
        itemTitle={targetRow.name}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteInspectionTask}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

InspectionTaskSummary.propTypes = {
  inspectionTasks: customPropTypes.inspectionTasks.isRequired,
  fetchInspectionTasks: PropTypes.func.isRequired,
  deleteInspectionTask: PropTypes.func.isRequired,
  openInspectionTaskDetail: PropTypes.func.isRequired,
  openInspectionTaskCreate: PropTypes.func.isRequired,
  openOrganizationDetail: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
