import React from 'react';
import { SelectInput, Chip } from 'stti-react-common';
import moment from 'moment-timezone';

import { deriveDateRangeFromMode } from '../../../../helpers/reports/reportUtils';
import { ReportControl } from '../ReportControl';

const getYears = () => {
  const years = [2023];

  const minYear = 2023;
  const currentYear = Number(moment().format('YYYYY'));

  const iterations = currentYear - minYear;

  if (iterations > 0) {
    for (let i = -1; iterations > i; i += 1) {
      years.push(
        Number(
          moment()
            .add(i + 1, 'years')
            .format('YYYY')
        )
      );
    }
  }

  return years.filter((year) => year <= currentYear);
};

export const YearSelectorInput = () => (
  <ReportControl
    name="selectedYear"
    group="query"
    label="Year"
    defaultValue={Number(moment().format('YYYY'))}
    render={(renderProps) => <SelectInput {...renderProps} options={getYears()} />}
    renderSummary={(renderProps, { value }) => (
      <Chip {...renderProps} color="primary" label={`of ${value}`} />
    )}
    printConfig={({ value }) => SelectInput.labelForValue(getYears(), value)}
    reducerCallback={(draft, value) => {
      [draft.controls.startDate, draft.controls.endDate] = deriveDateRangeFromMode(
        draft.controls.dateRangeMode,
        draft.controls.startDate,
        draft.controls.endDate,
        value
      );
    }}
    autoGridArea
  />
);
