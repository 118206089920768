const transliteration = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  P: 7,
  R: 9,
  S: 2,
  T: 3,
  U: 4,
  V: 5,
  W: 6,
  X: 7,
  Y: 8,
  Z: 9,
};

export const validateVin = (vin) => {
  const toValidate = vin.toUpperCase();
  const vinArray = vin.split('');
  const vinFormat = /^([A-HJ-NPR-Z0-9]{8}[0-9X][A-HJ-NPR-Z0-9]{8})$/;
  const singleDigitVin = /^(\d)\1{16}$/;
  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

  // This will check if VIN does not have 17 chars, or if contains special chars and/or non-valid letters
  // and also if VIN is a repetition of the same number 17 times i.e 11111111111111111 or 22222222222222222
  if (vinArray.length !== 17 || !vinFormat.test(toValidate) || singleDigitVin.test(toValidate)) {
    return false;
  }

  const transliterated = vinArray.map((digit, index) =>
    transliteration[digit] && !undefined
      ? transliteration[digit] * weights[index]
      : digit * weights[index]
  );

  const weightedSum = transliterated.reduce((acc, val) => acc + val);
  const remainder = weightedSum % 11;

  // Keep in mind that this code, as the one in vehicle client checks for the 9th digit as it is
  // compulsory for vehicles in North America.
  if (
    remainder.toString() === toValidate.charAt(8) ||
    (toValidate.charAt(8) === 'X' && remainder === 10)
  ) {
    return true;
  }
  return false;
};
