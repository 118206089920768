import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Log',
    children: [
      columnDef.iconColumn({
        colId: 'openUnidentifiedLogDetailReport',
        headerName: 'Open Detail',
        cellRenderer: 'OpenDetail',
        headerComponent: 'OpenDetailHeader',
        enableRowGroup: true,
        rowGroupIndex: 0,
        width: 65,
      }),
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Date',
        variant: 'timelessDate',
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
      }),
    ],
  },
  {
    headerName: 'Day Summary',
    children: [
      columnDef.durationColumn({
        field: 'onDutyDrivingTotal',
        headerName: 'Driving',
        aggFunc: null,
      }),
      columnDef.durationColumn({
        field: 'onDutyTotal',
        headerName: 'On Duty',
        aggFunc: null,
      }),
    ],
  },
  {
    headerName: 'Activity',
    children: [
      columnDef.booleanColumn({
        field: 'hasActivity',
        headerName: 'Activity',
        cellRenderer: 'Activity',
        falseLabelText: 'No',
        trueLabelText: 'Yes',
        falseRank: 0,
        trueRank: 1,
        width: 190,
        enableRowGroup: false,
      }),
    ],
  },
];
