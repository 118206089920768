import { cloneDeep, intersection, find } from 'lodash';
import { createSelector } from 'reselect';

import { ADMIN_ROLE_KEYS } from '../../../system/constants';

const getOrganizations = (state) => state.administration.configurations.organizations.data;
const getSystemUser = (state) => state.system.user;

/**
 * selectOrganizations(state) -> [ou]
 *
 * Selector for all organizations, including permissions based on user role(s)
 */
export const selectOrganizations = createSelector([getOrganizations, getSystemUser], (ous, user) =>
  ous
    .map((ou) => {
      if (!user || !user.memberships) {
        return {
          ...ou,
          permissions: {
            isAdmin: false,
          },
        };
      }

      const isAdmin = user.memberships.reduce(
        (detectedAdmin, membership) =>
          detectedAdmin ||
          !!(
            intersection(ADMIN_ROLE_KEYS, membership.role).length &&
            (membership.ou === ou.key || membership.ou === ou.parent)
          ),
        false
      );
      return {
        ...ou,
        permissions: {
          isAdmin,
        },
      };
    })
    .sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()))
);

/**
 * selectOrganization(state, key ) -> ou
 *
 * Selector for single ou by key (not cached)
 */
export const selectOrganization = (state, organizationKey) =>
  find(getOrganizations(state), { key: organizationKey });

/**
 * Helper for converting organizations to formatted tree structure
 */
export const selectOrganizationsTree = createSelector(getOrganizations, (ous) => {
  const organizations = cloneDeep(ous).sort((a, b) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
  );

  const indexedNodes = {};
  const treeRoots = [];
  for (let i = 0; i < organizations.length; i += 1) {
    indexedNodes[organizations[i].key] = organizations[i];
    indexedNodes[organizations[i].key].children = [];
  }
  for (let i = 0; i < organizations.length; i += 1) {
    const parent = organizations[i].parentKey;

    const parentOu = find(organizations, { key: parent });

    if (parent === null || !parentOu) {
      treeRoots.push(organizations[i]);
    } else if (indexedNodes[parent]) {
      indexedNodes[parent].children.push(organizations[i]);
    }
  }

  return treeRoots;
});

/**
 * selectOrganizationFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on organizations
 */
export const selectOrganizationFetchRequests = (state) =>
  state.administration.configurations.organizations.fetches;
