/* eslint-disable react/destructuring-assignment */
import React from 'react';

import {
  Form,
  SelectInput,
  BasicDialog,
  TextInput,
  NumberInput,
  TimeInput,
} from 'stti-react-common';

import { flatten, isNil, isEmpty } from 'lodash';

import { toMoment, durationBetweenIntervals } from '../../../../../helpers/moment';

import { inRange, getFormDates, getNumberFromHourMinute } from '../utils';

const { Control } = Form;

export const DownloadFileDialog = (
  selectedInterval,
  isOpen,
  setIsOpen,
  formControls,
  timeZone,
  virtualEvents,
  setIsConfirmOpen,
  onConfirm,
  hasFormErrors,
  downloadForm,
  controls,
  setOptions,
  options
) => {
  if (!selectedInterval && !isOpen) return '';

  return (
    <BasicDialog
      title="Retrieve Recording Settings"
      className="DownloadFileDialog"
      maxWidth="lg"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      buttons={[
        { label: 'Close', onClick: () => setIsOpen(false) },
        {
          label: 'Retrieve Recording',
          onClick: () => {
            const { isoStartDate, duration } = getFormDates(
              selectedInterval,
              formControls,
              timeZone
            );

            const mappedEvents = flatten(virtualEvents.map(({ events }) => events));
            const found = mappedEvents.find(
              ({ date, duration: durationString }) =>
                date === isoStartDate && duration === parseInt(durationString, 10)
            );
            if (found) {
              setIsConfirmOpen(true);
            } else {
              onConfirm();
              setTimeout(() => setIsOpen(false), 1000);
            }
          },
          disabled: hasFormErrors,
        },
      ]}
    >
      <div className="DownloadFileDialogForm">
        <Form form={downloadForm}>
          <Control
            Component={TextInput}
            name="date"
            label="Date"
            error={({ value }) => isNil(value)}
            readOnly
          />
          <Control
            Component={SelectInput}
            name="cameraId"
            label="Camera"
            error={({ value }) => isNil(value)}
            options={[
              { label: 'Front Camera', value: 1 },
              { label: 'Rear Camera', value: 2 },
            ]}
            defaultValue={controls.cameraId}
            readOnly
          />
          <Control
            Component={SelectInput}
            name="quality"
            label="Quality"
            error={({ value }) => isNil(value)}
            options={[
              { label: 'Standard', value: 'standard' },
              { label: 'High', value: 'high' },
            ]}
            onSet={({ draft, value }) => {
              if (value === 'standard') {
                setOptions(['0', '5', '10', '20', '30', '45', '60', '90']);
              }
              if (value === 'high') {
                setOptions(['0', '5', '10', '20', '30', '45']);
              }
              draft.secondsBefore = '0';
              draft.secondsAfter = '0';
            }}
          />
          <Control
            Component={TimeInput}
            name="time"
            label="Time"
            onSet={({ draft, value }) => {
              if (
                value &&
                durationBetweenIntervals(
                  toMoment(selectedInterval.local.startDate, timeZone).format('HH:mm:ss'),
                  value,
                  'seconds',
                  'HH:mm:ss'
                ) <= 0
              ) {
                draft.secondsBefore = '0';
                draft.secondsAfter = '0';
              }
            }}
            error={({ value }) => {
              if (isEmpty(value)) return 'Time cannot be empty.';
              const { date } = formControls;
              const { pointers } = selectedInterval;
              const inputDateTime = toMoment(`${date}T${value}`, timeZone);
              const inputDate = inputDateTime.format('YYYY-MM-DD');
              const inputTime = inputDateTime.format('HH:mm:ss');
              const startDate = toMoment(selectedInterval.local.startDate);
              const currentValue = getNumberFromHourMinute(inputDate, inputTime, timeZone);

              if (inputDateTime.isBefore(startDate)) {
                return `Invalid time. Min is ${toMoment(
                  selectedInterval.utc.startDate,
                  timeZone
                ).format('HH:mm:ss')}`;
              }

              if (!inRange(currentValue, pointers)) return 'Invalid time interval.';
              return false;
            }}
            disableClock
            format="HH:mm:ss"
            locale="en-CA"
            maxDetail="second"
          />
          <Control
            Component={SelectInput}
            name="secondsBefore"
            label="Seconds Before"
            error={({ value }) => isNil(value)}
            options={options}
            defaultValue="0"
            disabled={
              durationBetweenIntervals(
                toMoment(selectedInterval.local.startDate, timeZone).format('HH:mm:ss'),
                formControls.time,
                'seconds',
                'HH:mm:ss'
              ) <= 0
            }
            onSet={({ draft, value }) => {
              if (value) {
                draft.duration = parseInt(value, 10) + parseInt(draft.secondsAfter, 10);
              }
            }}
          />
          <Control
            Component={SelectInput}
            name="secondsAfter"
            label="Seconds After"
            error={({ value }) => isNil(value)}
            options={options}
            defaultValue="5"
            onSet={({ draft, value }) => {
              if (value) {
                draft.duration = parseInt(value, 10) + parseInt(draft.secondsBefore, 10);
              }
            }}
          />
          <Control
            Component={NumberInput}
            name="duration"
            label="Duration (in seconds)"
            disabled
            error={({ value }) => {
              if (value < 5) return 'Duration cannot be zero';
              return false;
            }}
          />
        </Form>
      </div>
    </BasicDialog>
  );
};
