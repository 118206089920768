import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

import { useReport } from './useReport';

export const ResetButton = ({ group, ...restProps }) => {
  const { resetState } = useReport();
  return (
    <Button
      onClick={(e) => {
        resetState(group);
      }}
      variant="outlined"
      icon="clear"
      {...restProps}
    />
  );
};

ResetButton.propTypes = {
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
