class Api {
  useInstance(apiInstance) {
    this._apiInstance = apiInstance;
  }
}

const proxyHandler = {
  get: (target, prop, receiver) => {
    if (prop === 'useInstance') return target.useInstance;
    return target._apiInstance[prop];
  },
};

const apiInstance = new Api();

// eslint-disable-next-line import/no-mutable-exports
let api;
try {
  api = new Proxy(apiInstance, proxyHandler);
} catch {
  // Prevent IE crash; allow api to remain undefined
}

export { api };
