// Using PropTypes for development validation of filter function params
/* eslint-disable react/forbid-foreign-prop-types */

import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { fromPairs, findIndex, isUndefined, now } from 'lodash';

const DEBUG_FILTERS = false;

const debugMessage = (...args) => {
  // eslint-disable-next-line no-console
  if (DEBUG_FILTERS) console.info(...args);
};

const shallowCompareObjects = (a, b) => {
  for (const key in a) {
    if (a[key] !== b[key]) return false;
  }
  return true;
};

const useApplyFilter = () => {
  const cacheRef = useRef(new WeakMap());
  const cache = cacheRef.current;

  return (filterDef, filterParams) => {
    const cached = cache.get(filterDef);

    const { type: filterFn, controlName, debugName } = filterDef;
    const filterTypeName = filterFn.name;

    const filterDebugName = debugName || controlName || '(anonymous)';

    if (cached && shallowCompareObjects(cached.params, filterParams)) {
      debugMessage(
        'used cached filter',
        filterTypeName,
        filterDebugName,
        filterParams.rows.length,
        '->',
        cached.result.length
      );
      return cached.result;
    }

    const combinedFilterParams = { ...filterDef, ...filterParams };

    if (filterFn.propTypes) {
      PropTypes.checkPropTypes(
        filterFn.propTypes,
        combinedFilterParams,
        'applyFilter param',
        `<filter> (type: '${filterTypeName}', controlName: '${filterDebugName}')`
      );
    }

    const result = filterFn(combinedFilterParams);
    cache.set(filterDef, { params: filterParams, result });

    debugMessage(
      'applied filter',
      filterTypeName,
      filterDebugName,
      filterParams.rows.length,
      '->',
      result.length
    );

    return result;
  };
};

export const useReportFiltering = ({
  controls,
  input,
  chain = ['default'],
  formats,
  supplements,
  disable,
}) => {
  const timeStart = now();
  debugMessage('useReportFiltering begin');

  // state

  const instanceRef = useRef({
    previousInput: [],
    previousControls: {},
    previousSupplements: {},
    isRefilterRequired: false,
    filterDefs: fromPairs(chain.map((group) => [group, []])),
  });
  const instance = instanceRef.current;

  const filteredRowsRef = useRef(fromPairs(chain.concat('output').map((group) => [group, []])));

  const [, setReRender] = useState();
  const forceReRender = () => setReRender({});

  const [dataGridFilteredRows, setDataGridFilteredRows] = useState([]);

  // registerFilter

  const registerFilter = useCallback((filterConfig) => {
    const { group, ...filterDef } = filterConfig;

    const { type: filterFn, controlName, getValues } = filterDef;

    // validate filter
    const filterTypeName = filterFn.name;

    if (!chain.includes(group))
      throw new Error(`Unconfigured filter group "${group}" on control "${controlName}"`);

    if (isUndefined(filterFn)) throw new Error(`Undefined filter type on control "${controlName}"`);

    if (!filterFn)
      throw new Error(`Unrecognized filter type "${filterTypeName}" on control "${controlName}"`);

    if (filterFn.propTypes) {
      const testDynamicValues =
        getValues &&
        getValues({
          value: undefined,
          controls,
          formats,
          filteredRows: filteredRowsRef.current,
          supplements,
        });

      // construct test filter params
      const testFilterParams = {
        ...filterDef,
        rows: [],
        value: undefined,
        ...testDynamicValues,
      };

      PropTypes.checkPropTypes(
        filterFn.propTypes,
        testFilterParams,
        'filterConfig param',
        `registerFilter (type: '${filterTypeName}', controlName: '${controlName}')`
      );
    }

    // add or replace filter
    let addFilterIndex = instance.filterDefs[group].length;
    if (controlName) {
      const currentFilterIndex = findIndex(instance.filterDefs[group], { controlName });
      if (currentFilterIndex !== -1) {
        addFilterIndex = currentFilterIndex;
        forceReRender();
        instance.isRefilterRequired = true;
      }
    }
    instance.filterDefs[group][addFilterIndex] = { ...filterDef, active: true };

    return () => {
      // deactivate, while preserving position
      instance.filterDefs[group][addFilterIndex].active = false;
    };
  }, []);

  // applyFilter

  const applyFilter = useApplyFilter();

  // forceRefilter

  const forceRefilter = () => {
    instance.isRefilterRequired = true;
    forceReRender();
  };

  // filtering

  const isFiltersRegistered = chain.reduce(
    (result, filterGroup) => result || instance.filterDefs[filterGroup].length > 0,
    false
  );

  if (
    isFiltersRegistered &&
    (input !== instance.previousInput ||
      controls !== instance.previousControls ||
      !shallowCompareObjects(supplements, instance.previousSupplements) ||
      instance.isRefilterRequired)
  ) {
    // above condition not an optimization: necessary to avoid infinite re-render
    instance.previousInput = input;
    instance.previousControls = controls;
    instance.previousSupplements = supplements;

    const filteredRows = { input }; // will be built up with keys for each filter group in chain; include input

    let workingRows = input; // begin with input

    chain.forEach((filterGroup) => {
      if (!disable) {
        instance.filterDefs[filterGroup].forEach((filterDef) => {
          // console.info(filterDef);
          const { controlName, getValues, disabled, active } = filterDef;

          // skip if filter has been made inactive (via unregisterFilter)
          if (!active) return;

          // get control value or filterDef fixed value, as applicable
          const value = controlName ? controls[controlName] : filterDef.value;

          // apply disabled callback if exists, do not apply filter if returns true
          if (disabled && disabled({ value, controls, formats, filteredRows, supplements })) return;

          // get other dynamic values, if applicable
          const dynamicValues =
            getValues && getValues({ value, controls, formats, filteredRows, supplements });

          // construct all filter params
          const filterParams = {
            rows: workingRows,
            value,
            ...dynamicValues,
          };

          // apply filter
          workingRows = applyFilter(filterDef, filterParams);
        });
      }

      // assign working output to current filter group
      filteredRows[filterGroup] = workingRows;
    });

    // set filteredRows.output to result of final filter group in chain
    filteredRows.output = workingRows;

    // save final filteredRows
    filteredRowsRef.current = filteredRows;

    instance.isRefilterRequired = false;
  }

  // synchronize filteredRows.dataGrid to state
  filteredRowsRef.current.dataGrid = dataGridFilteredRows;

  debugMessage('useReportFiltering complete', `${now() - timeStart}ms`);

  return {
    filteredRows: filteredRowsRef.current,
    registerFilter,
    setDataGridFilteredRows,
    forceRefilter,
  };
};
