import { connect } from 'react-redux';

import { LocationTypeDetail } from './detail';
import { locationType } from '../../../../../data/administration/resources';

export const LocationTypeDetailContainer = connect(
  (state, { locationTypeId }) => ({
    locationType: locationType.selectors.selectLocationType(state, locationTypeId),
  }),
  {
    fetchLocationTypes: locationType.actions.fetchLocationTypesOptimized,
    fetchLocationType: locationType.actions.fetchLocationTypeOptimized,
    createLocationType: locationType.actions.createLocationType,
    updateLocationType: locationType.actions.fetchLocationTypeOptimized,
    deleteLocationType: locationType.actions.fetchLocationTypeOptimized,
  }
)(LocationTypeDetail);
