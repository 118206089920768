import { find } from 'lodash';

export const transformActivitiesForStore = (activities) =>
  activities.map((activity) => {
    const { activityType, parentId } = activity;
    const originalParent = parentId && find(activities, { id: parentId });

    return {
      ...activity,
      activityTitle: activityType, // activityTitle is normally set during filtering, but do this for when filters disabled in debugging
      isParent: activity.childCount > 0, // parents are normally dropped during filtering, but do this for when filters disabled in debugging
      originalParent,
      topActivityType: originalParent ? originalParent.activityType : activityType,
    };
  });
