import { useCallback } from 'react';

export const useGridGroupingToggleRow = (report) => {
  const { getReport, setControl } = report;
  const toggleRowId = useCallback(
    (id) => {
      const newToggledRows = [...getReport().state.controls.dataGridGroupingToggledRows];
      const index = newToggledRows.findIndex((expandedRowId) => expandedRowId === id);
      if (index === -1) {
        newToggledRows.push(id);
      } else {
        newToggledRows.splice(index, 1);
      }
      setControl('dataGridGroupingToggledRows', newToggledRows);
    },
    [getReport, setControl]
  );
  return toggleRowId;
};
