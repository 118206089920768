import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, TextInput } from 'stti-react-common';

import { useControlsState } from '../../../../helpers/hooks';

import './ManageAdminDialog.scss';

export const CreateAdminItemDialog = ({ dialogTitle, inputLabel, isOpen, onClose, onConfirm }) => {
  const [controls, , setControl, resetControls] = useControlsState(() => ({
    text: '',
  }));

  return (
    <BasicDialog
      title={`Create New ${dialogTitle}`}
      className="CreateAdminItemDialog"
      maxWidth="xs"
      isOpen={isOpen}
      onOpen={resetControls}
      onClose={onClose}
      buttons={[
        { label: 'Close', onClick: onClose },
        {
          label: 'Save ',
          onClick: () => {
            onConfirm(controls);
            onClose();
          },
          disabled: controls.text.length === 0,
        },
      ]}
    >
      <TextInput
        className="CreateAdminItemDialog__control--input"
        label={inputLabel}
        value={controls.text}
        onChange={(value) => setControl('text', value)}
      />
    </BasicDialog>
  );
};

CreateAdminItemDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
