import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';
import { isEmpty, startCase } from 'lodash';
import moment from 'moment';

import {
  useReportController,
  ReportProvider,
  FetchReportDetail,
  FetchBreadcrumbs,
} from '../../../../../commons/ReportsCommon';

import { Feature } from '../../../../../commons/Feature';
import { NaviMap } from '../../../../../commons/NaviMap';
import { sliceBreadcrumbs } from '../../../../../../helpers/maps/breadcrumbUtils';
import { symbols, EventInfo } from '../../../../../commons/MapsCommon';
import { replaceStringByRegex } from '../../../../../../helpers/formats';

import './map.scss';

const { Route, Marker } = GoogleMap;

export const FuelTaxMapReport = ({
  breadcrumbsByVehicleKey,
  fetchFuelTax,
  fuelTaxDetailId,
  fuelTaxDetails = [],
  fuelTaxId,
  route,
}) => {
  const report = useReportController();

  let focus = null;
  let trip = null;

  const foundTaxDetail = fuelTaxDetails.find(({ id }) => id === fuelTaxDetailId);

  if (!isEmpty(foundTaxDetail)) {
    // Destructures row object instead of accessing it directly throughout the function.
    const { startedAt, endedAt, startLocation, endLocation, vehicle, driver } = foundTaxDetail;

    // Calls sliceBreadcrumbs function by passing it parameters directly.
    const tripBreadcrumbs = sliceBreadcrumbs(
      breadcrumbsByVehicleKey[vehicle.key],
      startedAt,
      endedAt
    );

    const filteredTripBreadcrumbs = tripBreadcrumbs.filter(
      ({ driverKey }) => driverKey === driver.key
    );

    // Copies all key-value pairs of row object and adds path property to it which is assigned tripBreadcrumbs value.
    trip = { ...foundTaxDetail, path: filteredTripBreadcrumbs };

    // Extracts the getLocation function definition into a separate block.
    const getLocation = (location) => {
      if (location && location.latitude && location.longitude) {
        // Uses a different if-statement
        return { ...location };
      }
      return null; // Returns null instead of undefined.
    };

    // Uses filter function instead of checking for Boolean values.
    focus = [getLocation(startLocation), getLocation(endLocation)].map(
      ({ latitude, longitude }) => ({ latitude, longitude })
    );
  }

  return (
    <ReportProvider value={report}>
      {!foundTaxDetail && <FetchReportDetail action={fetchFuelTax} args={{ fuelTaxId }} />}
      <div className="FuelTaxMapReport">
        <Feature.Header
          title="Segment Map"
          subtitle={foundTaxDetail && foundTaxDetail.vehicle && foundTaxDetail.vehicle.name}
          onClose={route && route.close}
          service="breadcrumbs"
        />
        {!isEmpty(focus) && (
          <NaviMap focus={focus}>
            {!isEmpty(trip) && !isEmpty(trip.startLocation) && !isEmpty(trip.endLocation) && (
              <Fragment key={trip.id}>
                <Marker
                  position={trip.startLocation}
                  selection={trip.startLocation}
                  icon={symbols.go}
                  order={0}
                  minZoom
                >
                  <EventInfo
                    event={{
                      eventType: 'Segment Start',
                      eventAt: trip.startedAt,
                      timeZone: trip.timeZone || moment.tz.guess(),
                    }}
                    extraInfo={startCase(
                      replaceStringByRegex(/events\./g, trip.startEventType, '')
                    )}
                    trip={trip}
                  />
                </Marker>
                <Marker
                  position={trip.endLocation}
                  icon={symbols.stop}
                  order={Marker.MAX_ORDER}
                  minZoom
                >
                  <EventInfo
                    event={{
                      eventType: 'Segment end',
                      eventAt: trip.endedAt,
                      timeZone: trip.timeZone || moment.tz.guess(),
                    }}
                    extraInfo={
                      <p>{startCase(replaceStringByRegex(/events\./g, trip.endEventType, ''))}</p>
                    }
                    trip={trip}
                  />
                </Marker>

                <FetchBreadcrumbs
                  startAt={trip.startedAt}
                  endAt={trip.endedAt}
                  vehicleKey={trip.vehicle.key}
                  noRefinements
                />
                <Route line={symbols.drivingLine} path={trip.path} disableAutoFocus />
              </Fragment>
            )}
          </NaviMap>
        )}
      </div>
    </ReportProvider>
  );
};

FuelTaxMapReport.propTypes = {
  fuelTaxDetails: PropTypes.array,
  fuelTaxId: PropTypes.string.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchFuelTax: PropTypes.func.isRequired,
  fuelTaxDetailId: PropTypes.string.isRequired,
};
