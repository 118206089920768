import React from 'react';

import { PerformanceRuleSummaryContainer } from './Summary/connect';
import { PerformanceRuleDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const performanceRuleSummary = configureRoute({
  name: 'Performance Rules',
  group: administrationGroups.configurations,
  order: 9,
  path: '/performanceRules',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY], // we need to remove this permission when MBL-5460 is ready
  render: (props) => (
    <PerformanceRuleSummaryContainer
      {...props}
      openRuleCreate={performanceRulesCreate.open}
      openRuleDetail={performanceRulesDetail.open}
    />
  ),
});

export const performanceRulesCreate = configureRoute({
  path: '/ruleSet/:ruleSetId/rule/new',
  switchKey: 'performanceRule',
  RouteFrame: BasicRouteFrame,
  render: (props) => <PerformanceRuleDetailContainer {...props} mode="create" />,
});

export const performanceRulesDetail = configureRoute({
  path: '/ruleSet/:ruleSetId/rule/:ruleId',
  switchKey: 'performanceRule',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <PerformanceRuleDetailContainer
      {...props}
      openPerformanceRuleDetail={performanceRulesDetail.open}
    />
  ),
});
