import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import { BasicDialog, Form, TextInput } from 'stti-react-common';

import {
  signDriverLog as signDriverLogAction,
  fetchDriverLog as fetchDriverLogAction,
} from '../../../../data/reports';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './DriverLogSignDialog.scss';

const { useFormController, Control, HiddenControl } = Form;

export const DriverLogSignDialog = connect(undefined, {
  signDriverLog: signDriverLogAction,
  fetchDriverLog: fetchDriverLogAction,
})(({ signature, driverLog = {}, isOpen, onClose, signDriverLog, fetchDriverLog }) => {
  const sigCanvas = useRef();
  const [isDisabled, setIsDisabled] = useState(signature === null);

  const clear = () => {
    sigCanvas.current.clear();
    setIsDisabled(true);
  };

  const { driver, logDate, comment, key, certificationStatus } = driverLog;

  const form = useFormController({
    controls: { logDate, comment },
  });
  const { controls } = form;

  useEffect(() => {
    if (isOpen) {
      const signatureInCanvas =
        certificationStatus.signature || signature || 'data:image/png;base64,';
      setTimeout(() => {
        sigCanvas.current.fromDataURL(signatureInCanvas);
      }, 0);
    }
  }, [signature, isOpen]);

  return (
    <BasicDialog
      className="Report__DriverLogSignDialog"
      isOpen={isOpen}
      onClose={onClose}
      title="Sign Your Log"
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Clear Signature',
          onClick: clear,
        },
        {
          label: 'Sign Log',
          onClick: () => {
            signDriverLog({
              ...controls,
              key,
              signature: sigCanvas.current.getCanvas().toDataURL('image/png'),
            });

            setTimeout(
              () => fetchDriverLog({ driverKey: driver.key, date: logDate, eld: undefined }),
              2000
            );

            onClose();
          },
          disabled: isDisabled,
        },
      ]}
    >
      <Form form={form}>
        <SignaturePad
          ref={sigCanvas}
          minWidth={3}
          maxWidth={5}
          penColor="rgb(51, 51, 51)"
          canvasProps={{ className: 'sigCanvas' }}
          onEnd={() => setIsDisabled(sigCanvas.current.isEmpty())}
        />
        <HiddenControl name="logDate" defaultValue={logDate} />
        <Control name="comment" Component={TextInput} label="Comment" autoGridArea />
        <p className="term">
          I hereby certify that my data entries and my record of duty status for this 24-hour period
          are true and correct.
        </p>
      </Form>
    </BasicDialog>
  );
});

DriverLogSignDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  driverLog: customPropTypes.detailedDriverLog,
};
