import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchTablets: 'administration/devices/tablets/fetchTablets',
  fetchTablet: 'administration/devices/tablets/fetchTablet',
  deleteTablet: 'administration/devices/tablets/deleteTablet',
  updateTablet: 'administration/devices/tablets/updateTablet',
  fetchTabletDiagnosticConfigs: 'administration/devices/tablets/fetchTabletDiagnosticConfigs',
  updateTabletDiagnosticConfigs: 'administration/devices/tablets/updateTabletDiagnosticConfigs',
};

export const tabletReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchTablets)) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchTablet, ACTION_TYPE.updateTablet])) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchTabletDiagnosticConfigs,
        ACTION_TYPE.updateTabletDiagnosticConfigs,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteTablet)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeRequest(type, [
        ACTION_TYPE.fetchTablets,
        ACTION_TYPE.fetchTablet,
        ACTION_TYPE.fetchTabletDiagnosticConfigs,
      ])
    ) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchTablets, ACTION_TYPE.fetchTablet])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
