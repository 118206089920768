import moment from 'moment-timezone';
import { columnDef } from '../../../../../commons/ControlledDataGrid';
import { timeInMillisecondsToDecimal } from '../../../../../../helpers/moment';
import {
  metersToKilometersMilesConverter,
  titleCaseValueFormatter,
  originStringFormatter,
  eldLatLonCodeFormatter,
} from '../../../../../../helpers/formats';

export const driverEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date & time',
    valueGetter: ({ data: { date, startOfDayRecord } }) => {
      if (startOfDayRecord) return moment(date).format('YYYY-MM-DD');
      return moment(date).format('HH:mm:ss');
    },
    width: 70,
  }),
  columnDef.textColumn({
    field: 'eventType',
    headerName: 'Event',
    valueFormatter: ({ data: { dutyStatus, eventType, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      if (dutyStatus) {
        return titleCaseValueFormatter({ value: dutyStatus });
      }
      if (eventType) {
        return titleCaseValueFormatter({ value: eventType });
      }
      return '';
    },
  }),
  columnDef.textColumn({
    field: 'geoLocation',
    headerName: 'Geo-Location',
    valueFormatter: ({ data: { geoLocation, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return geoLocation || '';
    },
  }),
  columnDef.textColumn({
    field: 'latitude',
    headerName: 'Latitude',
    valueFormatter: ({ data: { location, startOfDayRecord, eldLatLonCode } }) => {
      const displayCode = eldLatLonCodeFormatter(eldLatLonCode);
      if (startOfDayRecord) return '';
      if (!location) return displayCode;
      const { latitude } = location;
      if (!latitude) return displayCode;
      return `${latitude.toFixed(2)}`;
    },
  }),
  columnDef.textColumn({
    field: 'longitude',
    headerName: 'Longitude',
    valueFormatter: ({ data: { location, startOfDayRecord, eldLatLonCode } }) => {
      const displayCode = eldLatLonCodeFormatter(eldLatLonCode);
      if (startOfDayRecord) return '';
      if (!location) return displayCode;
      const { longitude } = location;
      if (!longitude) return displayCode;
      return `${longitude.toFixed(2)}`;
    },
  }),
  columnDef.textColumn({
    field: 'distance',
    headerName: 'Distance last val. coord.',
    valueFormatter: ({ data }) => {
      const { distanceSinceLastGps, startOfDayRecord } = data;
      if (startOfDayRecord) return '';
      if (distanceSinceLastGps) {
        return metersToKilometersMilesConverter(distanceSinceLastGps);
      }
      return '0 km / 0 mi';
    },
  }),
  columnDef.textColumn({
    field: 'asset',
    headerName: 'CMV',
    valueFormatter: ({ data: { asset, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return asset || '';
    },
  }),
  columnDef.textColumn({
    field: 'accumulatedVehicleDistance',
    headerName: 'Distance (Accum.)',
    valueFormatter: ({ data: { accumulatedVehicleDistance, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      if (accumulatedVehicleDistance) {
        return metersToKilometersMilesConverter(accumulatedVehicleDistance);
      }
      return '0 km / 0 mi';
    },
  }),
  columnDef.durationColumn({
    field: 'elapsedEngineHours',
    headerName: 'Hours (Elapsed)',
    valueFormatter: ({ data: { elapsedEngineHours, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      // elapsedEngineHours is given by BE in seconds, so we need to convert it to milliseconds
      return timeInMillisecondsToDecimal(elapsedEngineHours * 1000);
    },
  }),
  columnDef.textColumn({
    field: 'totalVehicleDistance',
    headerName: 'Distance (Total)',
    valueFormatter: ({ data: { totalVehicleDistance, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return totalVehicleDistance ? metersToKilometersMilesConverter(totalVehicleDistance) : '';
    },
  }),
  columnDef.textColumn({
    field: 'recordStatus',
    headerName: 'Record Status',
    valueFormatter: ({ data: { recordStatus, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return recordStatus || '';
    },
  }),
  columnDef.textColumn({
    field: 'origin',
    headerName: 'Record Origin',
    valueFormatter: ({ data: { origin, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return origin ? originStringFormatter(origin) : '';
    },
  }),
  columnDef.textColumn({
    field: 'eldSequence',
    headerName: 'Seq. ID',
    valueFormatter: ({ data: { eldSequence, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return `0000${Number(eldSequence).toString(16)}`.slice(-4).toUpperCase();
    },
  }),
];
