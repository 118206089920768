import BetterPropTypes from 'better-prop-types';

export const inspection = BetterPropTypes.shape({
  alternativeLocationName: BetterPropTypes.string,
  assetName: BetterPropTypes.string.isOptionalButNotNull,
  assetJurisdictionCountry: BetterPropTypes.string.isOptionalButNotNull,
  assetJurisdictionState: BetterPropTypes.string.isOptionalButNotNull,
  assetPlateNumber: BetterPropTypes.string.isOptionalButNotNull,
  assetType: BetterPropTypes.string.isOptionalButNotNull,
  assetTypeName: BetterPropTypes.string.isOptionalButNotNull,
  carrier: BetterPropTypes.string.isOptionalButNotNull,
  complianceText: BetterPropTypes.string,
  defects: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      isMajor: BetterPropTypes.bool.isOptionalButNotNull,
      isRepaired: BetterPropTypes.bool.isOptionalButNotNull,
      position: BetterPropTypes.string.isOptionalButNotNull,
      task: BetterPropTypes.string.isOptionalButNotNull,
      typeName: BetterPropTypes.string.isOptionalButNotNull,
    })
  ),
  endedAt: BetterPropTypes.string,
  engineHours: BetterPropTypes.number.isRequiredButNullable,
  hasDefects: BetterPropTypes.bool,
  inspectionType: BetterPropTypes.string.isOptionalButNotNull,
  inspectorKey: BetterPropTypes.string.isOptionalButNotNull,
  inspectorName: BetterPropTypes.string.isOptionalButNotNull,
  inspectorSignature: BetterPropTypes.string,
  id: BetterPropTypes.string.isOptionalButNotNull,
  location: BetterPropTypes.object,
  locationName: BetterPropTypes.string,
  majorDefects: BetterPropTypes.number.isOptionalButNotNull,
  minorDefects: BetterPropTypes.number.isOptionalButNotNull,
  notes: BetterPropTypes.string,
  odometer: BetterPropTypes.number,
  ouKey: BetterPropTypes.string,
  principalKey: BetterPropTypes.string.isOptionalButNotNull,
  principalName: BetterPropTypes.string.isOptionalButNotNull,
  safeToDrive: BetterPropTypes.bool.isOptionalButNotNull,
  startedAt: BetterPropTypes.string,
  timeZone: BetterPropTypes.string,
});

export const inspections = BetterPropTypes.arrayOf(inspection);
