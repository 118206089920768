import { makeRestApiAction } from '../apiActionSupport';

import { ACTION_TYPE } from './reducer';

export const fetchMessages = makeRestApiAction({
  service: 'messages',
  method: 'get',
  transformInput: ({ end, start }) => ({
    end,
    start,
  }),
  baseActionType: ACTION_TYPE.fetchMessages,
  // notificationsItemDescriptor: 'messages', // this notification is not needed for now
});

export const postMessage = makeRestApiAction({
  service: 'messages',
  method: 'post',
  baseActionType: ACTION_TYPE.postMessage,
  // notificationsItemDescriptor: 'messages', // this notification is not needed for now
});

export const postMessageEvent = makeRestApiAction({
  service: 'messages',
  method: 'post',
  getId: ({ messageKey }) => `${messageKey}/events`,
  transformInput: ({ participant, date, event, type }) => ({
    participant,
    date,
    event,
    _type: type,
  }),
  transformOutput: ({ date, event, participant }, { messageKey }) => ({
    date,
    event,
    participant,
    messageKey,
  }),
  baseActionType: ACTION_TYPE.postMessageEvent,
  // notificationsItemDescriptor: 'message event', // this notification is not needed for now
});
