import BetterPropTypes from 'better-prop-types';

export const user = BetterPropTypes.shape({
  lastName: BetterPropTypes.string.isOptionalButNotNull,
  firstName: BetterPropTypes.string.isOptionalButNotNull,
  deleted: BetterPropTypes.bool.isOptionalButNotNull,
  enabled: BetterPropTypes.bool,
  yardMoves: BetterPropTypes.bool,
  personalUse: BetterPropTypes.bool,
  lastSignIn: BetterPropTypes.string,
  registrationDate: BetterPropTypes.string.isOptionalButNotNull,
  externalId: BetterPropTypes.string,
  memberships: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      ou: BetterPropTypes.string.isOptionalButNotNull,
      scope: BetterPropTypes.oneOf(['Tenant', 'Organization']),
      // If scope = 'Tenant' the user belongs to the tenant organization.
      role: BetterPropTypes.array,
    })
  ),
  email: BetterPropTypes.string,
  regulations: BetterPropTypes.string,
  key: BetterPropTypes.string.isOptionalButNotNull,
});

export const users = BetterPropTypes.arrayOf(user);
