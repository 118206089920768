import React from 'react';
import PropTypes from 'prop-types';

import { SectionPanel, ControlGroupSummary, ReportControl } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';

export const AggregatesPanel = ({ rows, shift }) => {
  if (!shift) return null;

  const { fullCycle, count } = shift;

  const {
    distance: fullCycleDistance,
    duration: fullCycleDuration,
    consumedFuel: fullCycleConsumedFuel,
  } = fullCycle || {};

  const { loads, unloads } = count || {};

  return (
    <>
      <ReportControl
        name="aggregatesCollapsedSummary"
        group="aggregates"
        renderSummary={(renderProps, { formatUnit }) =>
          shift && (
            <div {...renderProps}>
              <em>Distance:</em> {formatUnit('distance', fullCycleDistance)}, <em>Duration:</em>{' '}
              {formatUnit('duration', fullCycleDuration)}, <em>Loads:</em> {loads},{' '}
              <em>Unloads:</em> {unloads}, <em>Fuel:</em>{' '}
              {formatUnit('volume', fullCycleConsumedFuel)}
            </div>
          )
        }
        reRegisterOn={[shift]}
      />
      <SectionPanel
        name="aggregatesPanel"
        title="Aggregates"
        renderSummaries={() => <ControlGroupSummary group="aggregates" />}
      >
        <AggregatesContent rows={rows} shift={shift} />
      </SectionPanel>
    </>
  );
};

AggregatesPanel.propTypes = {
  shift: PropTypes.object,
  rows: PropTypes.array,
};
