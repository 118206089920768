import { isNull, isFinite } from 'lodash';
import { SelectInput } from 'stti-react-common';

import { UNDEFINED_NUMBER_PLACEHOLDER } from '../../../../../helpers/reports/exceptionTypes';
import { getFormatDefinition } from '../../../../../helpers/formats';

export const valueIsNull = ({ value }) => isNull(value);

export const distanceUnitOptions = [
  { label: 'km', value: 'km' },
  { label: 'mi', value: 'mi' },
];

export const durationUnitOptions = [
  { label: 'seconds', value: 's' },
  { label: 'minutes', value: 'm' },
  { label: 'hours', value: 'h' },
];

export const formatControlValueForDescription = (controlName, controlValue) => {
  switch (controlName) {
    case 'durationValue':
    case 'distanceValue':
    case 'mileageValue':
    case 'number':
    case 'percent':
      return isFinite(controlValue) ? controlValue : UNDEFINED_NUMBER_PLACEHOLDER;
    case 'durationUnit':
      return SelectInput.labelForValue(durationUnitOptions, controlValue);
    case 'distanceUnit':
      return SelectInput.labelForValue(distanceUnitOptions, controlValue);
    case 'mileageUnit':
      return getFormatDefinition('mileage', controlValue).suffix;
    default:
      return controlValue;
  }
};
