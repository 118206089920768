import React from 'react';
import PropTypes from 'prop-types';

import './Feature.scss';

export const Wrapper = ({ children, className = '' }) => (
  <div className={`Feature ${className}`}>{children}</div>
);

Wrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
