import moment from 'moment-timezone';

const getQuarterRange = (quarter, year) => {
  const start = moment(moment().year(year)).quarter(quarter).startOf('quarter');
  const end = moment(moment().year(year)).quarter(quarter).endOf('quarter');

  return { start, end };
};

export const deriveDateRangeFromMode = (dateRangeMode, startDate, endDate, year) => {
  let startMoment;
  let endMoment;
  switch (dateRangeMode) {
    case 'today':
      startMoment = moment();
      endMoment = moment();
      break;
    case 'yesterday':
      startMoment = moment().subtract(1, 'day');
      endMoment = moment().subtract(1, 'day');
      break;
    case 'thisWeek':
      startMoment = moment().day(0); // not using startOf('week') as it's locale-aware
      endMoment = moment().day(6);
      break;
    case 'lastWeek':
      startMoment = moment().subtract(1, 'week').day(0); // not using startOf('week') as it's locale-aware
      endMoment = moment().subtract(1, 'week').day(6);
      break;
    case 'last7Days':
      startMoment = moment().subtract(7, 'days');
      endMoment = moment();
      break;
    case 'last14Days':
      startMoment = moment().subtract(14, 'days');
      endMoment = moment();
      break;
    case 'thisMonth':
      startMoment = moment().startOf('month');
      endMoment = moment().endOf('month');
      break;
    case 'lastMonth':
      startMoment = moment().subtract(1, 'month').startOf('month');
      endMoment = moment().subtract(1, 'month').endOf('month');
      break;
    case 'last30Days':
      startMoment = moment().subtract(30, 'days');
      endMoment = moment();
      break;
    case '1q':
    case '2q':
    case '3q':
    case '4q': {
      const quarter = Number(dateRangeMode.replace('q', ''));
      const { start, end } = getQuarterRange(quarter, year);
      startMoment = start;
      endMoment = end;
      break;
    }
    case 'january':
    case 'february':
    case 'march':
    case 'april':
    case 'may':
    case 'june':
    case 'july':
    case 'august':
    case 'september':
    case 'october':
    case 'november':
    case 'december': {
      const start = moment().month(dateRangeMode).year(year).startOf('month');
      const end = moment().month(dateRangeMode).year(year).endOf('month');
      startMoment = start;
      endMoment = end;
      break;
    }

    default:
    // custom date range
  }

  if (startMoment) {
    return [startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD')];
  }

  return [startDate, endDate];
};
