import React from 'react';
import PropTypes from 'prop-types';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => <div {...props}>{rows.length} geozones</div>}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
