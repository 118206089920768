import React from 'react';
import PropTypes from 'prop-types';

import { Card, Icon } from 'stti-react-common';

import './AdminCard.scss';

export const AdminCard = ({ label, value, icon }) => (
  <Card className="AdminCard">
    <Icon icon={icon} />
    <h3>{label}</h3>
    <p>{value}</p>
  </Card>
);

AdminCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string.isRequired,
};
