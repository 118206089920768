import React from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from 'stti-react-common';

export const RequestsProgressIndicator = ({ requests, holdPlace }) => {
  if (requests.length === 0) {
    return holdPlace ? (
      <div className="RequestsProgressIndicator" style={{ width: '35px', height: '35px' }} />
    ) : null;
  }
  return <ProgressIndicator className="RequestsProgressIndicator" size={35} />;
};

RequestsProgressIndicator.propTypes = {
  requests: PropTypes.array.isRequired,
  holdPlace: PropTypes.bool,
};
