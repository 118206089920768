import BetterPropTypes from 'better-prop-types';

export const speedViolation = BetterPropTypes.shape({
  assetId: BetterPropTypes.string,
  averageSpeed: BetterPropTypes.number,
  driverName: BetterPropTypes.string,
  duration: BetterPropTypes.number,
  startLocation: BetterPropTypes.shape({
    latitude: BetterPropTypes.number,
    longitude: BetterPropTypes.number,
  }).isOptionalButNotNull,
  endLocation: BetterPropTypes.shape({
    latitude: BetterPropTypes.number,
    longitude: BetterPropTypes.number,
  }),
  key: BetterPropTypes.string.isOptionalButNotNull,
  startedAt: BetterPropTypes.string.isOptionalButNotNull,
  endedAt: BetterPropTypes.string.isOptionalButNotNull,
  timeZone: BetterPropTypes.string.isOptionalButNotNull,
  speedLimit: BetterPropTypes.number.isOptionalButNotNull,
  gracePeriod: BetterPropTypes.number.isOptionalButNotNull,
  geozoneName: BetterPropTypes.string,
});

export const speedViolations = BetterPropTypes.arrayOf(speedViolation);
