import BetterPropTypes from 'better-prop-types';

export const vehicle = BetterPropTypes.shape({
  notes: BetterPropTypes.string,
  year: BetterPropTypes.number,
  managedBy: BetterPropTypes.object,
  assetType: BetterPropTypes.object,
  assetId: BetterPropTypes.string,
  inactiveDate: BetterPropTypes.string,
  model: BetterPropTypes.string,
  vin: BetterPropTypes.string,
  make: BetterPropTypes.string,
  plateNumber: BetterPropTypes.string,
  externalId: BetterPropTypes.string,
  vinAutoDetected: BetterPropTypes.bool,
  enableCumminsEngineDiagnostics: BetterPropTypes.bool,
  engineYearBefore2000: BetterPropTypes.bool,
  sharedWith: BetterPropTypes.array,
  active: BetterPropTypes.bool,
  key: BetterPropTypes.string.isOptionalButNotNull,
});

export const vehicles = BetterPropTypes.arrayOf(vehicle);
