import React, { useCallback } from 'react';
import { Menu } from 'stti-react-common';

import { useReport } from './useReport';
import { analytics } from '../../../data/analytics';

export const ExportDataMenu = ({ ...restProps }) => {
  const { dataGridController, getReport } = useReport();

  const exportData = useCallback((format) => {
    dataGridController.methods.exportData({
      filename: getReport().getDataGridExportFilename({ format }),
      format,
    });
    analytics.sendEvent({ action: 'exportData', label: format });
  }, []);

  const handleExportXlsx = useCallback(() => exportData('xlsx'), []);
  const handleExportCsv = useCallback(() => exportData('csv'), []);

  return (
    <Menu
      className="Report__ExportDataMenu"
      label="Export"
      icon="cloud_download"
      tooltip="Export Data"
      // disabled={!gridFilteredRows.length} // not yet available in useReport
      items={[
        {
          label: 'Export Excel',
          onClick: handleExportXlsx,
        },
        {
          label: 'Export CSV',
          onClick: handleExportCsv,
        },
      ]}
      {...restProps}
    />
  );
};
