import React from 'react';
import PropTypes from 'prop-types';

import { RequestsProgressIndicator } from '../../RequestsProgressIndicator';
import { PrintButton } from '../../PrintButton';
import { Feature } from '../../Feature';

import { ReportMenu } from '../ReportMenu';
import { ExportDataMenu } from '../ExportDataMenu';
import { ReportTypeName } from '../ReportTypeName';
import { ReportTitle } from '../ReportTitle';
import { SettingsButton } from '../SettingsButton';
import { RequestError } from '../RequestError';
import { useReport } from '../useReport';

export const Header = ({
  hideExport,
  hideBack,
  hidePrint,
  services = [],
  canIncludeDetailInSendReport,
  limitIncludeDetailInSendReport,
  children,
}) => {
  const { reportType, route, fetchError, fetchRetry } = useReport();
  return (
    <Feature.Header
      title={<ReportTypeName />}
      subtitle={<ReportTitle />}
      onClose={!hideBack ? route.close : undefined}
    >
      {fetchError && <RequestError onRetry={fetchRetry} />}
      <RequestsProgressIndicator service={services} holdPlace />
      {children}
      {!hidePrint && <PrintButton mode={reportType} />}
      {!hideExport && <ExportDataMenu />}
      <SettingsButton />
      <ReportMenu
        reportType={reportType}
        canIncludeDetailInSendReport={canIncludeDetailInSendReport}
        limitIncludeDetailInSendReport={limitIncludeDetailInSendReport}
        hideExportOptionsInSendReport={hideExport}
      />
    </Feature.Header>
  );
};

Header.propTypes = {
  hideExport: PropTypes.bool,
  hideBack: PropTypes.bool,
  hidePrint: PropTypes.bool,
  services: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  canIncludeDetailInSendReport: PropTypes.bool,
  limitIncludeDetailInSendReport: PropTypes.bool,
  children: PropTypes.node,
};
