import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'stti-react-common';

export const AdminExportDataMenu = ({ dataGridController, filename, ...restProps }) => {
  const exportData = useCallback(
    (format) => {
      dataGridController.methods.exportData({
        filename,
        format,
      });
    },
    [filename]
  );

  return (
    <Menu
      variant="contained"
      label="Export"
      icon="cloud_download"
      tooltip="Export Data"
      items={[
        {
          label: 'Export Excel',
          onClick: () => exportData('xlsx'),
        },
        {
          label: 'Export CSV',
          onClick: () => exportData('csv'),
        },
      ]}
      {...restProps}
    />
  );
};

AdminExportDataMenu.propTypes = {
  dataGridController: PropTypes.object.isRequired,
  filename: PropTypes.string.isRequired,
};
