import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  countUniqueOfField,
  countTruthyOfField,
  countFalseyOfField,
  countOf,
} from '../../../../helpers/reports/aggregation';

export const AggregatesContent = ({ rows }) => (
  <>
    <AggregatesCard
      title="Overview"
      name="overview"
      fields={[
        {
          header: 'Inspections',
          value: countOf(rows),
        },
        {
          header: 'Inspectors',
          value: countUniqueOfField(rows, 'inspectorName'),
        },
        {
          header: 'Vehicles',
          value: countUniqueOfField(rows, 'assetName'),
        },
      ]}
    />
    <AggregatesCard
      title="Status"
      name="status"
      fields={[
        {
          header: 'Safe',
          value: countTruthyOfField(rows, 'safeToDrive'),
        },
        {
          header: 'Unsafe',
          value: countFalseyOfField(rows, 'safeToDrive'),
        },
        {
          header: 'Has DTC',
          value: countTruthyOfField(rows, 'hasDTCs'),
        },
      ]}
    />
  </>
);

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
