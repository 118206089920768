import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';

import { GoogleMap, Icon, Clickable } from 'stti-react-common';

import { symbols } from '../../../../commons/MapsCommon';

import { toMoment } from '../../../../../helpers/moment';

const { Marker, Info } = GoogleMap;

export const MapHighLightSpots = (
  highlightSpots,
  selectedId,
  timeZone,
  formatUnit,
  handlePlayClick
) =>
  useMemo(() => {
    highlightSpots.length > 0 &&
      highlightSpots.map(
        (row) =>
          row.latitude !== -1 &&
          row.longitude !== -1 && (
            <Marker
              key={uniqueId()}
              position={{ latitude: row.latitude, longitude: row.longitude }}
              icon={symbols.videoIcon}
              selection={row}
              order={99}
              disableAutoFocus
            >
              <Info visible forceOpen={selectedId === row.id}>
                <div className="Map__ActivityInfo">
                  <div className="Map__ActivityInfo__title">Retrieved Recording Info</div>
                  <table className="Map__ActivityInfo__specs">
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <td>{toMoment(row.date, timeZone).format('MMM DD, YYYY HH:mm:ss')}</td>
                      </tr>
                      {row.vehicle && row.vehicle.name && (
                        <tr>
                          <th>Vehicle</th>
                          <td>{formatUnit('speed', row.vehicle.name)}</td>
                        </tr>
                      )}
                      {row.driver && row.driver.name && (
                        <tr>
                          <th>Driver</th>
                          <td>{row.driver.name}</td>
                        </tr>
                      )}
                      <tr>
                        <th>Speed</th>
                        <td>{formatUnit('speed', row.speed)}</td>
                      </tr>
                      <tr>
                        <th>Duration</th>
                        <td>{row.duration} seconds</td>
                      </tr>
                      <tr>
                        <th>
                          <Icon icon="play_arrow" />
                        </th>
                        <td>
                          <Clickable onClick={() => handlePlayClick(row)}>Play Media</Clickable>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Info>
            </Marker>
          )
      );
  }, [highlightSpots]);
