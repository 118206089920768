import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { castArray } from 'lodash';

import './PrintLayout.scss';

export const PrintLayout = ({ printMode, mode: componentMode, inline, debug, children }) => {
  if (debug) return children;
  if (printMode.print !== true && !castArray(printMode.print).includes(componentMode)) return null;
  const content = <div className="PrintLayout">{children}</div>;
  return inline ? content : ReactDOM.createPortal(content, document.querySelector('body'));
};

PrintLayout.propTypes = {
  printMode: PropTypes.shape({
    print: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    attach: PropTypes.array,
    includeDetail: PropTypes.bool,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  children: PropTypes.node,
  inline: PropTypes.bool,
  debug: PropTypes.bool,
};
