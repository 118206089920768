import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  actionTypeFailure,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchLocationTypes: 'administration/resources/locations/fetchLocationTypes',
  fetchLocationType: 'administration/resources/locations/fetchLocationType',
  updateLocationType: 'administration/resources/locations/updateLocationType',
  createLocationType: 'administration/resources/locations/createLocationType',
  deleteLocationType: 'administration/resources/locations/deleteLocationType',
};

export const locationTypesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchLocationTypes)) {
      draft.data = sortBy(payload, [(locationType) => locationType.name.toLowerCase()]);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchLocationType,
        ACTION_TYPE.createLocationType,
        ACTION_TYPE.updateLocationType,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteLocationType)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchLocationType, ACTION_TYPE.fetchLocationTypes])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchLocationType, ACTION_TYPE.fetchLocationTypes])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
