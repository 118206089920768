import { createSelector } from 'reselect';
import { find, map } from 'lodash';

import { selectSystemTenant } from '../../../system';

import { organization } from '../organizations';

const LEVELS = {
  SYSTEM: 'System',
  TENANT: 'Tenant',
  OU: 'Ou',
  DRIVER: 'Driver',
  ASSET: 'Asset',
};

const getDefaultValue = (ous, tenant, limits, prop) => {
  const tenantOu = find(ous, { scope: 'Tenant' });
  const systemConfig = find(limits, { operationalLevel: LEVELS.SYSTEM });
  const tenantConfig = {
    ...(find(limits, { operationalLevel: LEVELS.TENANT }) || {
      operationalLevel: LEVELS.TENANT,
    }),
    name: tenant.name,
    ...(tenantOu && { appliesTo: tenantOu.key, operationalLevel: LEVELS.TENANT }),
  };
  if (!tenantConfig || !tenantConfig.key) return systemConfig[prop];
  return tenantConfig[prop];
};

/**
 * selectSpeedLimits(state) -> [speedLimits]
 *
 * Selector for speedLimits received from /api/speedtracking/configuration/
 */
export const selectSpeedLimits = (state) => state.administration.configurations.speedLimits.data;

/**
 * selectSpeedLimitConfiguration(state) -> {[speedLimits], defaultValues: {gracePeriod, speedLimit}}
 *
 * Selector for composite object returning speedLimits array, default speed limit values and tenantOu info.
 * Explanation: We receive from /api/speedtracking/configuration/ a object that needs to be iterated in order
 * to build our speedLimit list, we also need default speed limit and grace period values required for the
 * operations done in the speedLimitAdmin component. We use getDefaultValue to get those default values.
 */
export const selectSpeedLimitConfiguration = createSelector(
  [organization.selectors.selectOrganizations, selectSystemTenant, selectSpeedLimits],
  (ous, tenant, limits) => {
    if (limits.length === 0)
      return {
        speedLimits: [],
        defaultValues: {},
        tenantOu: {},
      };
    const speedLimits = map(ous, (ou) => {
      const filter = { appliesTo: ou.key, operationalLevel: LEVELS.OU };
      const config = find(limits, filter) || {
        ...filter,
        gracePeriod: getDefaultValue(ous, tenant, limits, 'gracePeriod'),
        speedLimit: getDefaultValue(ous, tenant, limits, 'speedLimit'),
      };
      return { ...config, name: ou.name };
    });
    return {
      speedLimits,
      defaultValues: {
        defaultGracePeriod: getDefaultValue(ous, tenant, limits, 'gracePeriod'),
        defaultSpeedLimit: getDefaultValue(ous, tenant, limits, 'speedLimit'),
      },
    };
  }
);
