import BetterPropTypes from 'better-prop-types';

export const apiKey = BetterPropTypes.shape({
  name: BetterPropTypes.string.isOptionalButNotNull,
  accessType: BetterPropTypes.string.isOptionalButNotNull,
  creationDate: BetterPropTypes.oneOfType([BetterPropTypes.string, BetterPropTypes.object])
    .isOptionalButNotNull,
  creatorName: BetterPropTypes.string.isOptionalButNotNull,
  creator: BetterPropTypes.number.isOptionalButNotNull,
  key: BetterPropTypes.oneOfType([BetterPropTypes.string, BetterPropTypes.number])
    .isOptionalButNotNull,
  tenant: BetterPropTypes.number.isOptionalButNotNull,
});

export const apiKeys = BetterPropTypes.arrayOf(apiKey);
