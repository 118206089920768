import React from 'react';

import { DispatchAbortReasonSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, DISPATCHER_ROLE_KEY } from '../../../../config';

export const dispatchAbortReasonSummary = configureRoute({
  name: 'Dispatch Abort Reasons',
  group: administrationGroups.configurations,
  order: 5,
  tenantEnabledKey: 'enableDispatch',
  path: '/dispatchAbortReasons',
  RouteFrame: BasicRouteFrame,
  permissions: [DISPATCHER_ROLE_KEY],
  render: (props) => <DispatchAbortReasonSummaryContainer {...props} />,
});
