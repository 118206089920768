import { connect } from 'react-redux';

import { RoleSummary } from './summary';

import { role } from '../../../../../data/administration/resources';

export const RoleSummaryContainer = connect(
  (state) => ({
    roles: role.selectors.selectRoles(state),
  }),
  {
    fetchRoles: role.actions.fetchRolesOptimized,
  }
)(RoleSummary);
