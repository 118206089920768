import BetterPropTypes from 'better-prop-types';

export const fuelling = BetterPropTypes.shape({
  id: BetterPropTypes.string.isOptionalButNotNull,
  startedAt: BetterPropTypes.string.isOptionalButNotNull,
  endedAt: BetterPropTypes.string.isOptionalButNotNull,
  vehicle: BetterPropTypes.shape({
    key: BetterPropTypes.string.isOptionalButNotNull,
    name: BetterPropTypes.string.isOptionalButNotNull,
    type: BetterPropTypes.string.isOptionalButNotNull,
    odometer: BetterPropTypes.number.isOptionalButNotNull,
  }).isOptionalButNotNull,
  ou: BetterPropTypes.shape({
    key: BetterPropTypes.string.isOptionalButNotNull,
    name: BetterPropTypes.string.isOptionalButNotNull,
  }).isOptionalButNotNull,
  driver: BetterPropTypes.shape({
    key: BetterPropTypes.string.isOptionalButNotNull,
    name: BetterPropTypes.string.isOptionalButNotNull,
  }).isOptionalButNotNull,
  location: BetterPropTypes.shape({
    name: BetterPropTypes.string,
  }).isOptionalButNotNull,
  addedFuel: BetterPropTypes.number.isOptionalButNotNull,
  fuelType: BetterPropTypes.oneOf(['Diesel', 'Gas', 'LNG']),
  distance: BetterPropTypes.number.isOptionalButNotNull,
  duration: BetterPropTypes.number.isOptionalButNotNull,
  vendor: BetterPropTypes.string.isOptionalButNotNull,
  jurisdiction: BetterPropTypes.string.isOptionalButNotNull,
});

export const fuellings = BetterPropTypes.arrayOf(fuelling);
