import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useReport } from './useReport';
import {
  signalWebclientViewReady as signalWebclientViewReadyAction,
  signalWebclientViewError as signalWebclientViewErrorAction,
} from '../../../data/system';

export const SignalWebclientViewReady = connect(null, {
  signalWebclientViewReady: signalWebclientViewReadyAction,
  signalWebclientViewError: signalWebclientViewErrorAction,
})(({ signalWebclientViewReady, signalWebclientViewError }) => {
  const { webclientViewReady, fetchError, getDataGridExportFilename, dataGridController } =
    useReport();

  useEffect(() => {
    if (!webclientViewReady) return;

    signalWebclientViewReady({
      getFilename: async (format) => getDataGridExportFilename({ format }),
      getContent: (format) => dataGridController.methods.getExportDataContent({ format }),
    });
  }, [webclientViewReady]);

  useEffect(() => {
    if (!fetchError) return;
    signalWebclientViewError({ code: 'fetchError', ...fetchError }); // fetchError is instance of ApiError, contains { status: string, message: string, error?: object }
  }, [fetchError]);

  return null;
});
