import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find } from 'lodash';

import { inOuBasedTime } from '../system';
import { organization } from '../administration/configurations';

const extractId = (_, { id }) => id;
const extractShiftId = (_, { shiftId }) => shiftId;

const getUsers = (state) => state.administration.resources.users.data;
const getShifts = (state) => state.reports.shifts.shifts;
const getShiftActivities = (state, { shiftId }) =>
  state.reports.shifts.activitiesByShiftId[shiftId] || [];
const getFetchRequests = (state) => state.reports.shifts.fetches;

/**
 * selectShifts(state) -> [shift]
 *
 * Selector for all shifts
 */
export const selectShifts = createSelector(
  [getShifts, organization.selectors.selectOrganizations, getUsers],
  (shifts, ous, users) =>
    shifts.map((shift) => inOuBasedTime(shift, ous, undefined, undefined, users))
);

/**
 * selectShift(state, { id }) -> shift
 *
 * Selector for single shift by id
 */
export const selectShift = createCachedSelector(
  [selectShifts, extractId],
  (shifts, id) => find(shifts, { id }) || {}
)(extractId);

/**
 * selectShiftsFetches(state) -> [fetchRequestData]
 *
 * Selector for all fetches (active and successful), requestData only
 */
export const selectShiftsFetches = createSelector(getFetchRequests, (fetchRequests) =>
  fetchRequests.map((fetchRequest) => fetchRequest.requestData)
);

/**
 * selectShiftActivities(state, { shiftId }) -> [shiftActivity]
 *
 * Selector for a shift's activities
 */
export const selectShiftActivities = createCachedSelector(
  [
    (state, { shiftId: id }) => selectShift(state, { id }),
    getShiftActivities,
    organization.selectors.selectOrganizations,
    getUsers,
  ],
  (shift, shiftActivities, ous, users) =>
    shiftActivities.map((shiftActivity) =>
      inOuBasedTime(shiftActivity, ous, shift.ouKey, undefined, users)
    )
)(extractShiftId);
