import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    cellRenderer: 'Name',
    minWidth: 180,
    sort: 'asc',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'description',
    headerName: 'Task Description',
    minWidth: 180,
    cellRenderer: 'Description',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'ou.name',
    headerName: 'Applies To',
    cellRenderer: 'AppliesTo',
    minWidth: 240,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
