import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Clickable, useBooleanState } from 'stti-react-common';

import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { useDebounce } from '../../../../../helpers/hooks';
import { Feature } from '../../../../commons/Feature';
import { AdminSearchPanel, DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import { makeColumnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController, createState } = ControlledDataGrid;

export const CompanionAppSummary = ({
  companionApps,
  fetchCompanionApps,
  fetchTenants,
  isSystem,
  openCompanionAppDetail,
  openCompanionAppCreate,
  deleteCompanionApp,
  route,
}) => {
  const [searchText, setSearchText] = useState('');
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    fetchCompanionApps();
    fetchTenants();
  }, []);

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs: makeColumnDefs(isSystem),
  });

  useEffect(() => {
    dataGridController.setState(createState({ columnDefs: makeColumnDefs(isSystem) }));
    dataGridController.methods.autoSizeColumns();
  }, [isSystem]);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          className="CompanionAppSummary__itemAction--edit"
          onClick={() => openCompanionAppDetail({ appId: data.key, mode: 'edit' })}
          icon="edit"
        />
        <Button
          className="CompanionAppSummary__itemAction--delete"
          onClick={() => {
            setTargetRow(data);
            openDeleteDialog();
          }}
          icon="delete"
        />
      </>
    ),
    Application: ({ data, value }) =>
      data.canEdit ? (
        <Clickable
          className="CompanionAppSummary__itemAction--view"
          onClick={() => openCompanionAppDetail({ appId: data.key, mode: 'view' })}
        >
          {value}
        </Clickable>
      ) : (
        value
      ),
    ReleaseChannelsList: ({ value }) =>
      /* eslint-disable  react/jsx-indent */
      value && value.length > 0
        ? value.map((item) => (
            <li className="CompanionAppSummary__releaseChannelsList" key={item}>
              {item}
            </li>
          ))
        : '—',
    TenantsList: ({ value }) =>
      /* eslint-disable  react/jsx-indent */
      value && value.length > 0
        ? value.map((item) => (
            <li className="CompanionAppSummary__tenantsList" key={item.key}>
              {item && item.name}
            </li>
          ))
        : '—',
  });

  return (
    <Feature.Wrapper className="CompanionAppSummary">
      <Feature.Header onClose={route.close} title="Companion Apps" service="companionApps">
        {isSystem && (
          <Button
            icon="add"
            variant="contained"
            label="Add New Application"
            onClick={() => openCompanionAppCreate({ mode: 'create' })}
          />
        )}
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Companion Apps"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={companionApps}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
          autoHeightColumns
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="User"
        itemTitle={targetRow.name}
        isOpen={isDeleteDialogOpen}
        onClose={() => closeDeleteDialog()}
        onConfirm={deleteCompanionApp}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

CompanionAppSummary.propTypes = {
  companionApps: customPropTypes.companionApps,
  isSystem: PropTypes.bool,
  fetchTenants: PropTypes.func.isRequired,
  fetchCompanionApps: PropTypes.func.isRequired,
  deleteCompanionApp: PropTypes.func.isRequired,
  openCompanionAppDetail: PropTypes.func.isRequired,
  openCompanionAppCreate: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
