import { DataGridPanel } from './DataGridPanel';
import { Header } from './Header';
import { LegFilters } from './LegFilters';
import { LocationFilters } from './LocationFilters';
import { TripOutlierFilters } from './TripOutlierFilters';
import { TripResourceFilters } from './TripResourceFilters';

export const ReportFragment = {
  DataGridPanel,
  Header,
  LegFilters,
  LocationFilters,
  TripOutlierFilters,
  TripResourceFilters,
};
