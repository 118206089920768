import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniq } from 'lodash';
import { Clickable, SelectInput, Chip, ToggleInput } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';

import {
  useReportController,
  ReportProvider,
  ControlGroupSummary,
  SectionPanel,
  ReportLoader,
  SectionPanelMenu,
  MapIcon,
  ReportFragment,
  ReportControl,
  ReportFilter,
  ControlsTablePrint,
  SectionPrint,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';
import { CycleDetailAggregates } from './CycleDetailAggregates';
import { countOf } from '../../../../helpers/reports/aggregation';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';
import {
  transformTripRowsToTargetCycleRow,
  transformFirstCycleRowToTripRows,
  transformCycleRowsToExactRow,
  addAggregatesToCycleRows,
} from '../../../../helpers/reports/cycleFilters';

import './CycleDetailReport.scss';

const defaultState = () => ({
  controls: {},
  dataGrid: ControlledDataGrid.createState({ columnDefs }),
});

const requestServices = ['trips'];

export const CycleDetailReport = ({
  trips,
  reportViewId,
  route,
  tripRouteReportRoute,
  fetchTrips,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'cycleDetail',
    reportTypeName: 'Cycle Detail Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: trips,
      chain: [
        'query',
        'resource',
        'outlier',
        'invertOutlier',
        'makeCycle',
        'leg',
        'finishCycle',
        'explodeCycle',
      ],
    },
  });
  const { setControl, filteredRows, dataGridController, reportType } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = ControlledDataGrid.useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data?.vehicle?.key])}>{value}</Clickable>
    ),
    Trailers: ({ data: { trailers } }) =>
      trailers.map((trailer, index) => (
        <span key={trailer?.name}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('trailersNames', [trailer?.key])}>
            {trailer?.name}
          </Clickable>
        </span>
      )),
    Drivers: ({ data: { drivers } }) =>
      drivers.map((driver, index) => (
        <span key={`${driver?.name} ${driver?.order}`}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('driversNames', [driver?.key])}>
            {driver?.name}
          </Clickable>
        </span>
      )),
    Origin: ({ value }) => (
      <Clickable onClick={() => setControl('originName', value)}>{value}</Clickable>
    ),
    FirstLoad: ({ value }) => (
      <Clickable onClick={() => setControl('firstLoadName', value)}>{value}</Clickable>
    ),
    LastUnload: ({ value }) => (
      <Clickable onClick={() => setControl('lastUnloadName', value)}>{value}</Clickable>
    ),
    RouteMapHeader: () => <MapIcon />,
    RouteMap: ({ data: { id } }) => (
      <Clickable onClick={() => tripRouteReportRoute.open({ tripId: id })}>
        <MapIcon />
      </Clickable>
    ),
    CycleLegField: ({ data, colDef, valueFormatted }) => (
      <span className={data[colDef.legType].include ? '' : 'DataGrid__cell--excludedLeg'}>
        {valueFormatted}
      </span>
    ),
  });

  // RENDER

  const getOptions = useMemo(
    () => ({
      originName: () => sortBy(uniq(filteredRows.query.map((cycle) => cycle.origin.name))),
      firstLoadName: () => sortBy(uniq(filteredRows.query.map((cycle) => cycle.firstLoad.name))),
      lastUnloadName: () => sortBy(uniq(filteredRows.query.map((cycle) => cycle.lastUnload.name))),
    }),
    [filteredRows.query]
  );

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchTrips} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="CycleDetailReport">
        <ReportFragment.Header reportType="cycleDetail" route={route} services={requestServices} />

        <QueryPanel queryFor="trip" group="query" minAllowedDate="2018-01-01" />
        <SectionPanel
          name="tripsPanel"
          title="Trip Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <ReportFragment.TripResourceFilters
            rows={filteredRows.query}
            group="filters"
            filterGroup="resource"
          />
          <ReportFragment.TripOutlierFilters baseClassName="CycleDetailReport" />
        </SectionPanel>
        <SectionPanel
          name="cyclePanel"
          title="Cycle Filters"
          renderSummaries={() => <ControlGroupSummary group="cycle" />}
          summaryEnd={<SectionPanelMenu group="cycle" />}
        >
          <ReportFilter
            filterConfig={{
              group: 'query',
              type: filterRowsByPredicate,
              test: () => false,
              disabled: ({ controls: { originName, firstLoadName, lastUnloadName } }) =>
                originName && firstLoadName && lastUnloadName,
            }}
          />
          <ReportControl
            name="originName"
            group="cycle"
            label="Origin Location"
            render={(renderProps) => (
              <SelectInput {...renderProps} getOptions={getOptions.originName} />
            )}
            renderSummary={(renderProps, { value }) =>
              value ? (
                <Chip {...renderProps} label={`Origin: ${value}`} />
              ) : (
                <Chip {...renderProps} variant="outlined" label="Select Origin Location" />
              )
            }
            error={({ value }) => !value && 'Origin Location must be specified.'}
          />
          <ReportControl
            name="firstLoadName"
            group="cycle"
            label="First Load Location"
            render={(renderProps) => (
              <SelectInput {...renderProps} getOptions={getOptions.firstLoadName} />
            )}
            renderSummary={(renderProps, { value }) =>
              value ? (
                <Chip {...renderProps} label={`Load: ${value}`} />
              ) : (
                <Chip {...renderProps} variant="outlined" label="Select First Load Location" />
              )
            }
            error={({ value }) => !value && 'First Load Location must be specified.'}
          />
          <ReportControl
            name="lastUnloadName"
            group="cycle"
            label="Last Unload Location"
            render={(renderProps) => (
              <SelectInput {...renderProps} getOptions={getOptions.lastUnloadName} />
            )}
            renderSummary={(renderProps, { value }) =>
              value ? (
                <Chip {...renderProps} label={`Unload: ${value}`} />
              ) : (
                <Chip {...renderProps} variant="outlined" label="Select Last Unload Location" />
              )
            }
            error={({ value }) => !value && 'Last Unload Location must be specified.'}
          />
          <ReportControl
            render={(props) => <ToggleInput {...props} />}
            renderSummary={(renderProps, { value }) =>
              value && <Chip {...renderProps} label="Exact trips only" />
            }
            name="showExactTripsOnly"
            defaultValue
            group="cycle"
            label="Exact trips only"
            filterConfig={{
              group: 'makeCycle',
              getValues: ({ controls: { originName, firstLoadName, lastUnloadName } }) => ({
                originName,
                firstLoadName,
                lastUnloadName,
              }),
              type: transformCycleRowsToExactRow,
            }}
          />
          <ReportFragment.LegFilters baseClassName="CyclesSummaryReport" />
          <ReportFilter
            filterConfig={{
              group: 'makeCycle',
              type: transformTripRowsToTargetCycleRow,
              getValues: ({ controls: { originName, firstLoadName, lastUnloadName } }) => ({
                originName,
                firstLoadName,
                lastUnloadName,
              }),
            }}
          />
          <ReportFilter
            filterConfig={{
              group: 'finishCycle',
              type: addAggregatesToCycleRows,
            }}
          />
          <ReportFilter
            filterConfig={{
              group: 'explodeCycle',
              type: transformFirstCycleRowToTripRows,
            }}
          />
        </SectionPanel>
        <SectionPanel
          name="aggregatesPanel"
          title="Aggregates"
          renderSummaries={() => <ControlGroupSummary group="aggregates" />}
        >
          <ReportControl
            name="aggregatesCollapsedSummary"
            group="aggregates"
            renderSummary={(props, { filteredRows: fr }) =>
              fr.finishCycle &&
              fr.finishCycle[0] && (
                <div {...props}>
                  {countOf(fr.output)} trips, {countOf(fr.finishCycle[0].vehiclesNames)} vehicles,{' '}
                  {countOf(fr.finishCycle[0].trailersNames)} trailers
                </div>
              )
            }
          />
          <CycleDetailAggregates cycle={filteredRows.finishCycle[0]} trips={filteredRows.output} />
        </SectionPanel>
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <CycleDetailAggregates cycle={filteredRows.finishCycle[0]} trips={filteredRows.output} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
    </ReportProvider>
  );
};

CycleDetailReport.propTypes = {
  trips: PropTypes.array.isRequired,
  reportViewId: PropTypes.string.isRequired,
  tripRouteReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchTrips: PropTypes.func.isRequired,
};
