import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniqBy, flatten, isNil } from 'lodash';

import { SelectFilterInput } from '../SelectFilterInput';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';

export const TripResourceFilters = ({ rows, group, filterGroup, hideTrailers }) => {
  const drivers = uniqBy(flatten(rows.map((trip) => trip.drivers)), 'key');
  const trailers = uniqBy(flatten(rows.map((trip) => trip.trailers)), 'key');

  const getOptions = useMemo(
    () => ({
      vehicleName: () =>
        sortBy(
          uniqBy(
            rows.map(({ vehicle: { key, name } }) => ({ label: name, value: key })),
            'value'
          ),
          'label'
        ),
      trailersNames: () =>
        sortBy(
          uniqBy(
            trailers.map(({ key, name }) => ({ label: name, value: key })),
            'value'
          ),
          'label'
        ),
      driversNames: () =>
        sortBy(
          uniqBy(
            drivers.map(({ key, name }) => ({ label: name, value: key })),
            'value'
          ),
          'label'
        ),
    }),
    [rows]
  );

  return (
    <>
      <SelectFilterInput
        name="vehicleName"
        field="vehicle.key"
        displayValue="vehicle.name"
        group={group}
        filterGroup={filterGroup}
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleName}
      />
      {!hideTrailers && (
        <SelectFilterInput
          name="trailersNames"
          field="trailers"
          displayValue="name"
          fieldSubMap="key" // property used for complex objects
          group={group}
          filterGroup={filterGroup}
          label="Trailers"
          summaryPrefix="Trailer: "
          itemPlural="trailers"
          autoGridArea
          getOptions={getOptions.trailersNames}
          filterConfig={{
            group: filterGroup,
            test: ({ row, value }) => row.trailers.find(({ key }) => value.includes(key)),
            type: filterRowsByPredicate,
            disabled: ({ value }) => isNil(value) || value.length === 0,
          }}
        />
      )}
      <SelectFilterInput
        name="driversNames"
        field="drivers"
        displayValue="name"
        fieldSubMap="key" // property used for complex objects
        group={group}
        label="Drivers"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driversNames}
        filterConfig={{
          group: filterGroup,
          test: ({ row, value }) => row.drivers.find(({ key }) => value.includes(key)),
          type: filterRowsByPredicate,
          disabled: ({ value }) => isNil(value) || value.length === 0,
        }}
      />
    </>
  );
};

TripResourceFilters.propTypes = {
  rows: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
  hideTrailers: PropTypes.bool,
};
