import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, useBooleanState } from 'stti-react-common';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { columnDefs } from './columnDefs';
import { useDebounce } from '../../../../../helpers/hooks';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { Feature } from '../../../../commons/Feature';

import { AdminSearchPanel, DeleteAdminItemDialog } from '../../../../commons/AdminCommon';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const LocationTypeSummary = ({
  locationTypes,
  fetchLocationTypes,
  deleteLocationType,
  openLocationTypeCreate,
  openLocationTypeDetail,
  route,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    fetchLocationTypes();
  }, []);

  const dataGridComponents = useDataGridComponents({
    Actions: ({ data }) =>
      !data.systemDefault && (
        <>
          <Button
            icon="edit"
            onClick={() => openLocationTypeDetail({ locationTypeId: data.key, mode: 'edit' })}
          />
          <Button
            icon="delete"
            onClick={() => {
              setTargetRow(data);
              openDeleteDialog();
            }}
          />
        </>
      ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  return (
    <Feature.Wrapper className="LocationTypeSummary">
      <Feature.Header onClose={route.close} title="Location Types" service="locationTypes">
        <Button
          icon="add"
          variant="contained"
          label="Create New Location Type"
          onClick={() => openLocationTypeCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Location Types"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rowIdProperty="key"
          rows={locationTypes}
          searchText={debouncedSearchText}
          sizeColumnsToFit
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Location Type"
        itemTitle={targetRow.name}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteLocationType}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

LocationTypeSummary.propTypes = {
  locationTypes: PropTypes.arrayOf(customPropTypes.locationType),
  fetchLocationTypes: PropTypes.func.isRequired,
  deleteLocationType: PropTypes.func.isRequired,
  openLocationTypeCreate: PropTypes.func.isRequired,
  openLocationTypeDetail: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
