import React from 'react';
import { GoogleMap } from 'stti-react-common';

import { ReportControl } from '../ReportsCommon';

import './MapTypeControl.scss';

const { ControlsButton } = GoogleMap;

export const MapTypeControl = () => (
  <ReportControl
    name="mapType"
    group="map"
    defaultValue="roadmap"
    render={({ value, onChange }) => (
      <div className="Map__MapTypeControl">
        <ControlsButton
          onClick={() => onChange('roadmap')}
          label="Map"
          className={`Map__MapTypeControl__ControlsButton ${
            value === 'roadmap' ? 'Map__MapTypeControl__ControlsButton--selected' : ''
          }`}
        />
        <ControlsButton
          onClick={() => onChange('hybrid')}
          label="Satellite"
          className={`Map__MapTypeControl__ControlsButton ${
            value === 'hybrid' ? 'Map__MapTypeControl__ControlsButton--selected' : ''
          }`}
        />
      </div>
    )}
  />
);
