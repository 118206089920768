import React, { useMemo } from 'react';
import { sortBy, uniq, flatten } from 'lodash';
import { ToggleInput, Chip } from 'stti-react-common';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';
import { mapStringAndNumbers } from '../../../../helpers/formats';
import {
  SelectFilterInput,
  SectionPanel,
  SectionPanelMenu,
  ControlGroupSummary,
  ReportControl,
} from '../../../commons/ReportsCommon';

export const FiltersPanel = ({ rows }) => {
  const getOptions = useMemo(
    () => ({
      vehicleNames: () => uniq(mapStringAndNumbers(rows.map((row) => row.vehicleName))),
      driverNames: () => sortBy(uniq(rows.map((row) => row.driverName))),
      jurisdictions: () => sortBy(uniq(flatten(rows.map((row) => row.jurisdictions)))),
    }),
    [rows]
  );
  return (
    <SectionPanel
      name="filtersPanel"
      title="Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="jurisdictions"
        field="jurisdictions"
        group="filters"
        filterGroup="filters"
        label="Jurisdictions"
        summaryPrefix="Jurisdiction: "
        itemPlural="jurisdictions"
        autoGridArea
        getOptions={getOptions.jurisdictions}
      />
      <SelectFilterInput
        name="vehicleNames"
        field="vehicleName"
        group="filters"
        filterGroup="filters"
        label="Vehicle"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
      />
      <SelectFilterInput
        name="driverNames"
        field="driverName"
        group="filters"
        filterGroup="filters"
        label="Driver"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverNames}
      />
      <ReportControl
        render={(props) => <ToggleInput {...props} />}
        renderSummary={(renderProps, { value }) =>
          value && <Chip {...renderProps} label="Show records with no distance" />
        }
        name="showNoDistanceRecords"
        group="filters"
        label="Show records with no distance"
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) =>
            !value ? row.distance.distance > 0 : row.distance.distance === 0,
          type: filterRowsByPredicate,
        }}
        autoGridArea
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: customPropTypes.fuelTaxMileages.isRequired,
};
