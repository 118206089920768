import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy, isFinite } from 'lodash';
import { GoogleMap } from 'stti-react-common';

import { EventInfo } from './EventInfo';
import { SectionInfo } from './SectionInfo';
import { EventMarkers } from './EventMarkers';
import { symbols } from './symbols';

import { groupPairedEvents } from '../../../helpers/maps/groupPairedEvents';

const { Route, RouteSection, Marker, Legend, LegendItem } = GoogleMap;

const standardLegendSymbols = [
  { key: 'route', label: 'Route' },
  { key: 'violation', label: 'Speed Violation' },
  { key: 'go', label: 'Trip Start' },
  { key: 'stop', label: 'Trip End' },
  { key: 'pause', label: 'Delay' },
  { key: 'blueStar', label: 'Duty Status Change' },
  { key: 'whiteStar', label: 'Loading Completed' },
  { key: 'dot', label: 'Waypoint' },
].map(({ key, label }) => ({ key, label, symbol: symbols[key] }));

export const TripRoute = ({ trip, breadcrumbs = [], children, additionalLegendSymbols = [] }) => {
  const eventsByType = useMemo(
    () =>
      groupBy(breadcrumbs, ({ eventType, speed }) => {
        if (eventType.includes('DelayOccurred')) return 'Delay'; // captures LoadDelayOccurred, UnloadDelayOccurred, ?
        if (['DutyStatusChange', 'LoadingCompleted'].includes(eventType)) return eventType;
        if (isFinite(speed)) return 'Waypoint';
        return 'rest';
      }),

    [breadcrumbs]
  );

  const legendSymbols = useMemo(
    () => [...standardLegendSymbols, ...additionalLegendSymbols],
    [additionalLegendSymbols]
  );

  const durationEventsByType = useMemo(() => groupPairedEvents(breadcrumbs), [breadcrumbs]);

  if (!trip) return null;

  const { timeZone } = trip;

  return (
    <>
      <Legend controlPosition="leftTop">
        {legendSymbols.map(({ key, symbol, label }) => (
          <LegendItem key={key} label={label} symbol={symbol} />
        ))}
      </Legend>
      <Route path={breadcrumbs} line={symbols.route}>
        <Marker position={trip.origin} icon={symbols.go} order={0}>
          <EventInfo
            event={{ eventType: 'Trip Start', eventAt: trip.startedAt, timeZone }}
            trip={trip}
          />
        </Marker>
        <Marker position={trip.lastUnload} icon={symbols.stop} order={Marker.MAX_ORDER}>
          <EventInfo
            event={{ eventType: 'Trip End', eventAt: trip.endedAt, timeZone }}
            trip={trip}
          />
        </Marker>
        <EventMarkers events={eventsByType.DutyStatusChange} icon={symbols.blueStar} trip={trip} />
        <EventMarkers events={eventsByType.LoadingCompleted} icon={symbols.whiteStar} trip={trip} />
        <EventMarkers events={eventsByType.Delay} icon={symbols.pause} trip={trip} />
        <EventMarkers events={eventsByType.Waypoint} icon={symbols.dot} trip={trip} minZoom />
        {durationEventsByType.Break &&
          durationEventsByType.Break.map((section) => (
            <Marker
              key={section.key}
              position={section.endLocation}
              icon={symbols.pause}
              order={section.endSequence}
            >
              <SectionInfo section={section} trip={trip} />
            </Marker>
          ))}
        {durationEventsByType.SpeedViolation &&
          durationEventsByType.SpeedViolation.map((section) => (
            <RouteSection key={section.key} path={section.path} line={symbols.violation}>
              <SectionInfo section={section} trip={trip} />
            </RouteSection>
          ))}
        {children}
      </Route>
    </>
  );
};

TripRoute.propTypes = {
  trip: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  additionalLegendSymbols: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      symbol: PropTypes.object.isRequired,
    })
  ),
};
