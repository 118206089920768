import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Clickable, SelectInput, Chip } from 'stti-react-common';
import { isNil, flatten, uniqBy, sortBy } from 'lodash';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  LaunchReport,
  SectionPanel,
  ControlGroupSummary,
  SectionPanelMenu,
  SelectFilterInput,
  ControlsTablePrint,
  SectionPrint,
  DetailReportPrintingLinks,
  DateRangeAnalytics,
  QueryPanel,
  ReportFilter,
  ReportControl,
} from '../../../commons/ReportsCommon';
import { PrintLayout } from '../../../commons/PrintLayout';
import { Location } from '../../../../helpers/navigation/Location';
import {
  filterRowsByIntersection,
  filterRowsByPredicate,
} from '../../../../helpers/reports/standardFilters';

import { AggregatesContent } from './AggregatesContent';
import { AggregatesPanel } from './AggregatesPanel';
import { CompletionFilter } from './CompletionFilter';

import { columnDefs } from './columnDefs';

import './ShiftsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = ({ myShiftsOnly }) => ({
  controls: myShiftsOnly ? { dateRangeMode: 'thisMonth' } : {},
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['shifts'];

export const ShiftsSummaryReport = ({
  fetchShifts,
  limits,
  myShiftsOnly,
  reportViewId,
  route,
  shiftDetailReportRoute,
  shifts,
  userKey,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'driverShiftsSummary',
    reportTypeName: myShiftsOnly ? 'My Shift Summary Report' : 'Shift Summary Report',
    reportViewId,
    route,
    defaultState: defaultState({ myShiftsOnly }),
    filtersConfig: {
      input: shifts,
      chain: ['query', 'resource'],
    },
  });

  const {
    filteredRows,
    dataGridController,
    setControl,
    reportType,
    meta: { isReady },
  } = report;

  // DEEP-LINKING STATE FOR ANGULAR LINKS

  useEffect(() => {
    if (!isReady) return;
    const location = new Location();
    const { driverName, date } = location.query;
    if (driverName) {
      location.deleteQueryParam('driverName');
      setControl('driverName', [driverName]);
    }
    if (date && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      location.deleteQueryParam('date');
      setControl('ouMode', 'default');
      setControl('dateRangeFor', 'startedAt');
      setControl('dateRangeMode', 'custom');
      setControl('startDate', date);
      setControl('endDate', date);
    }
  }, [isReady]);

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    OpenDetail: ({ data: { id, endedAt } }) =>
      endedAt ? (
        <LaunchReport
          onClick={() => shiftDetailReportRoute.open({ shiftId: id, reportViewId: 'default' })}
        />
      ) : null,
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Driver: ({ data, value }) =>
      myShiftsOnly ? (
        value
      ) : (
        <Clickable onClick={() => setControl('driverName', [data.driver.key])}>{value}</Clickable>
      ),
    Trailers: ({ data }) =>
      data.trailers.map(({ key, name }, index) => (
        <span key={key}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('trailersNames', [key])}>{name}</Clickable>
        </span>
      )),
    Vehicles: ({ data }) =>
      data.vehicles.map(({ key, name }, index) => (
        <span key={key}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('vehiclesNames', [key])}>{name}</Clickable>
        </span>
      )),
  });

  // RENDER

  const vehicles = uniqBy(flatten(filteredRows.query.map((shift) => shift.vehicles)), 'key');
  const trailers = uniqBy(flatten(filteredRows.query.map((shift) => shift.trailers)), 'key');
  const drivers = uniqBy(flatten(filteredRows.query.map((shift) => shift.driver)), 'key');

  const getOptions = useMemo(
    () => ({
      vehiclesNames: () =>
        sortBy(
          vehicles.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      trailersNames: () =>
        sortBy(
          trailers.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      driverName: () =>
        sortBy(
          drivers.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      driverEnable: () => [
        {
          label: 'Enabled',
          value: true,
        },
        {
          label: 'Disabled',
          value: false,
        },
        {
          label: undefined,
          value: null,
        },
      ],
    }),
    [filteredRows.query]
  );

  if (myShiftsOnly && !userKey) return null; // may be initial render where key not yet available

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData
        fetchAction={fetchShifts}
        isOptimized
        limits={limits}
        args={myShiftsOnly ? { driverKey: userKey } : {}}
      />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="ShiftsSummaryReport">
        <ReportFragment.Header
          reportType="driverShiftsSummary"
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
          limitIncludeDetailInSendReport
        />

        <QueryPanel
          queryFor="shift"
          group="query"
          defaultDateRangeFor="startedAt"
          canSelectDateRangeFor
          minAllowedDate="2018-01-01"
          disableFuture
        />
        <SectionPanel
          name="filtersPanel"
          title="Shift Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <CompletionFilter group="filters" filterGroup="resource" />
          {myShiftsOnly ? (
            <ReportFilter
              filterConfig={{
                type: filterRowsByIntersection,
                group: 'resource',
                field: 'driver.key',
                debugName: 'driverKey',
                value: userKey,
              }}
            />
          ) : (
            <>
              <SelectFilterInput
                name="driverName"
                field="driver.key"
                displayValue="driver.name"
                group="filters"
                filterGroup="resource"
                label="Drivers"
                summaryPrefix="Driver: "
                itemPlural="drivers"
                autoGridArea
                getOptions={getOptions.driverName}
              />
              <ReportControl
                name="isEnabled"
                group="filters"
                label="Driver Enabled"
                canDeleteSummary
                render={(renderProps) => (
                  <SelectInput {...renderProps} options={getOptions.driverEnable()} />
                )}
                renderSummary={(renderProps, { value }) => {
                  if (isNil(value)) return null;
                  return (
                    <Chip
                      {...renderProps}
                      label={`Driver ${SelectInput.labelForValue(
                        getOptions.driverEnable(),
                        value
                      )}`}
                    />
                  );
                }}
                printConfig={({ value }) =>
                  !isNil(value) && SelectInput.labelForValue(getOptions.driverEnable(), value)
                }
                filterConfig={{
                  group: 'resource',
                  type: filterRowsByIntersection,
                  field: 'driver.enabled',
                }}
              />
            </>
          )}
          <SelectFilterInput
            name="vehiclesNames"
            field="vehicles"
            displayValue="name"
            fieldSubMap="key" // property used for complex objects
            group="filters"
            label="Vehicles"
            summaryPrefix="Vehicle: "
            itemPlural="vehicles"
            autoGridArea
            getOptions={getOptions.vehiclesNames}
            filterConfig={{
              group: 'resource',
              test: ({ row, value }) => row.vehicles.find(({ key }) => value.includes(key)),
              type: filterRowsByPredicate,
              disabled: ({ value }) => isNil(value) || value.length === 0,
            }}
          />
          <SelectFilterInput
            name="trailersNames"
            field="trailers"
            displayValue="name"
            fieldSubMap="key" // property used for complex objects
            group="filters"
            label="Trailers"
            summaryPrefix="Trailer: "
            itemPlural="trailers"
            autoGridArea
            getOptions={getOptions.trailersNames}
            filterConfig={{
              group: 'resource',
              test: ({ row, value }) => row.trailers.find(({ key }) => value.includes(key)),
              type: filterRowsByPredicate,
              disabled: ({ value }) => isNil(value) || value.length === 0,
            }}
          />
        </SectionPanel>
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
      <DetailReportPrintingLinks
        getDetailReportPath={(shift) =>
          shift.endedAt &&
          shiftDetailReportRoute.makeTargetPath({
            shiftId: shift.id,
            reportViewId: 'default',
          })
        }
      />
    </ReportProvider>
  );
};

ShiftsSummaryReport.propTypes = {
  fetchShifts: PropTypes.func.isRequired,
  limits: PropTypes.object,
  myShiftsOnly: PropTypes.bool,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  setPrintMode: PropTypes.func.isRequired,
  shiftDetailReportRoute: PropTypes.object.isRequired,
  shifts: PropTypes.array.isRequired,
  userKey: PropTypes.string,
};
