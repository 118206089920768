import React from 'react';
import PropTypes from 'prop-types';
import { intersection, capitalize } from 'lodash';
import { Chip } from 'stti-react-common';

import { ReportControl } from '../ReportControl';
import { filterRowsByExceptions } from '../../../../helpers/reports/exceptionFilters';

const pluralize = (quantity, noun, pluralNoun) => {
  if (quantity === 1) return noun;
  return pluralNoun || `${noun}s`;
};

const convertProviderName = (provider) => {
  if (provider === 'surfsight') return 'Navistream';
  return capitalize(provider);
};

export const ExceptionRulesControl = ({ onChipClick, onChipDelete, ...restProps }) => (
  <ReportControl
    name="exceptionRules"
    label="Exception Rules" // not displayed, only for the benefit of printConfig
    group="exceptions"
    defaultValue={[]}
    error={({ value }) => value.length === 0 && 'No rules configured.'}
    renderSummary={(renderProps, { value }) => {
      const rulesCount = value.length;
      return [
        rulesCount > 0 ? (
          <Chip
            key="rulesCount"
            label={`${rulesCount} ${pluralize(rulesCount, 'rule')} configured`}
          />
        ) : (
          <Chip key="noRules" variant="outlined" label="No rules configured" />
        ),
      ];
    }}
    render={({ className, style }, { value }) => (
      <div className={className} style={style}>
        {value.length === 0 && <Chip variant="outlined" label="No rules configured" />}
        {value.map((rule) => (
          <Chip
            key={rule.id}
            label={
              rule.provider
                ? `${rule.description} - ${convertProviderName(rule.provider)}`
                : rule.description
            }
            onClick={() => onChipClick(rule)}
            onDelete={() => onChipDelete(rule)}
          />
        ))}
      </div>
    )}
    filterConfig={{
      type: filterRowsByExceptions,
      group: 'exceptions',
      getValues: ({ value }) => ({
        exceptionRules: value,
      }),
    }}
    printConfig={({ value, controls }) => {
      const filterRules = controls.exceptionDescription;
      const configuredRules = value.map(({ description }) => description);
      const finalRules =
        filterRules && filterRules.length > 0
          ? intersection(filterRules, configuredRules)
          : configuredRules;
      return {
        key: 'exceptionRules',
        label: 'Exception Rules',
        value: finalRules,
      };
    }}
    {...restProps}
  />
);

ExceptionRulesControl.propTypes = {
  onChipClick: PropTypes.func.isRequired,
  onChipDelete: PropTypes.func.isRequired,
};
