import { connect } from 'react-redux';

import { EcmReportSummary } from './summary';
import { ecmReport } from '../../../../../data/administration/devices';
import { selectSystemUser } from '../../../../../data/system/systemSelectors';

export const EcmReportSummaryContainer = connect(
  (state) => ({
    ecmReports: ecmReport.selectors.selectEcmReports(state),
    currentUser: selectSystemUser(state),
  }),
  {
    fetchEcmReports: ecmReport.actions.fetchEcmReports,
  }
)(EcmReportSummary);
