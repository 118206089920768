import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, TextInput } from 'stti-react-common';

import { useControlsState } from '../../../../helpers/hooks';

import './ManageAdminDialog.scss';

export const EditAdminItemDialog = ({
  dialogTitle,
  inputLabel,
  isOpen,
  onClose,
  onConfirm,
  rowData,
}) => {
  const [controls, , setControl, resetControls] = useControlsState(() => ({
    key: rowData.key || '',
    text: rowData.text || '',
  }));

  return (
    <BasicDialog
      title={`Edit ${dialogTitle}`}
      className="EditAdminItemDialog"
      maxWidth="xs"
      isOpen={isOpen}
      onOpen={resetControls}
      onClose={onClose}
      buttons={[
        { label: 'Close', onClick: onClose },
        {
          label: 'Save ',
          onClick: () => {
            onConfirm(controls);
            onClose();
          },
          disabled: controls.text.length === 0 || false,
        },
      ]}
    >
      <TextInput
        className="EditAdminItemDialog__control--input"
        label={inputLabel}
        value={controls.text}
        onChange={(value) => setControl('text', value)}
      />
    </BasicDialog>
  );
};

EditAdminItemDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};
