import { connect } from 'react-redux';

import { PerformancesSummaryReport } from './PerformancesSummaryReport';
import {
  fetchPerformancesOptimized,
  selectPerformances,
  fetchMyPerformances,
} from '../../../../data/reports';
import { selectSystemUser, selectSystemOus } from '../../../../data/system';

import { user } from '../../../../data/administration/resources';

export const PerformancesSummaryReportContainer = connect(
  (state) => ({
    performances: selectPerformances(state),
    limits: { forAllOus: 6, forOu: 30 },
    user: selectSystemUser(state),
    users: user.selectors.selectUsers(state),
    ous: selectSystemOus(state),
  }),
  {
    fetchPerformances: fetchPerformancesOptimized,
    fetchMyPerformances,
    fetchUsers: user.actions.fetchUsersOptimized,
  }
)(PerformancesSummaryReport);
