import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { DrivingProfilePanel } from './DrivingProfilePanel';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { getMinMaxDatesFromArray } from '../../../../helpers/moment';

import {
  useReportController,
  ReportProvider,
  ReportFragment,
  fetchReportDetailOnDemand,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  ControlsTablePrint,
  SectionPrint,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './PerformanceDetailReport.scss';

const getDefaultState = (date) => ({
  controls: {
    dateRangeMode: 'custom',
    aggregatesPanel: true,
    drivingProfilePanel: true,
  },
  dataGrid: createState({ columnDefs }),
});

const { createState, useDataGridComponents } = ControlledDataGrid;

export const PerformanceDetailReport = ({
  fetchPerformanceDetails,
  fetchOrganizations,
  performanceDetails,
  date,
  driverKey,
  orgKey,
  shiftId,
  reportViewId,
  route,
}) => {
  const report = useReportController({
    reportType: 'driverPerformanceDetail',
    reportTypeName: 'Performance Detail Report',
    reportViewId,
    route,
    defaultState: getDefaultState(date),
    filtersConfig: {
      input: performanceDetails,
      chain: ['query'],
    },
  });

  const { filteredRows, reportType, dataGridController, setControl } = report;

  // get values from url params

  useEffect(() => {
    fetchOrganizations();
    fetchReportDetailOnDemand({
      fetchAction: fetchPerformanceDetails,
      report,
      args: `${driverKey}/${date}?ouKey=${orgKey}&shiftId=${shiftId}`,
    });
  }, []);

  useEffect(() => {
    setControl('ouMode', 'custom');
    setControl('ouKeys', [orgKey]);
    setControl('ousSelected', [orgKey]);
  }, [orgKey, date]);

  useEffect(() => {
    if (filteredRows.input) {
      const { minStartDate, maxEndDate } = getMinMaxDatesFromArray(filteredRows.input);
      setControl('startDate', minStartDate.format('YYYY-MM-DD'));
      setControl('endDate', maxEndDate.format('YYYY-MM-DD'));
    } else {
      setControl('startDate', date);
      setControl('endDate', date);
    }
  }, [filteredRows]);

  const dataGridComponents = useDataGridComponents({});

  return (
    <ReportProvider value={report}>
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="PerformanceDetailReport">
        <ReportFragment.Header reportType={reportType} route={route} />
        <QueryPanel
          queryFor="trip"
          group="query"
          defaultDateRangeFor="startedAt"
          disableQueryPanel
          minAllowedDate="2018-01-01"
        />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <DrivingProfilePanel rows={filteredRows.output} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
        <PrintLayout mode={reportType}>
          <ReportFragment.Header />
          <ControlsTablePrint />
          <AggregatesContent rows={filteredRows.dataGrid} />
          <SectionPrint flexRow>
            <DrivingProfilePanel rows={filteredRows.output} />
          </SectionPrint>
          <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
        </PrintLayout>
      </div>
    </ReportProvider>
  );
};

PerformanceDetailReport.propTypes = {
  fetchPerformanceDetails: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  performanceDetails: customPropTypes.performances.isRequired,
  date: PropTypes.string.isRequired,
  driverKey: PropTypes.string.isRequired,
  orgKey: PropTypes.string.isRequired,
  shiftId: PropTypes.any,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
