import { filter } from 'lodash';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../helpers/moment';
import { makeRestApiAction, makeDateRangeOptimizedAction } from '../apiActionSupport';
import { ACTION_TYPE } from './fuellingsReducer';
import { selectFuellingsFetches } from './fuellingsSelectors';

const transformForStore = ({ sinceFuelledDistance: distance, ...fuelling }) => {
  const transformedFuelling = {
    distance,
    ouKey: fuelling.ou.key,
    ...fuelling,
  };

  const { fuelType, addedFuel } = fuelling;

  switch (fuelType) {
    case 'Diesel':
      transformedFuelling.addedDiesel = addedFuel;
      transformedFuelling.dieselDistance = distance;
      break;
    case 'Gas':
      transformedFuelling.addedGas = addedFuel;
      transformedFuelling.gasDistance = distance;
      break;
    case 'LNG':
      transformedFuelling.addedLng = addedFuel;
      transformedFuelling.lngDistance = distance;
      break;
    default:
  }

  return transformedFuelling;
};

// fetchFuellings({ startDate, endDate, ouKey, timeZone })
const fetchFuellings = makeRestApiAction({
  service: 'fuellings',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuellings,
  transformInput: ({ startDate, endDate, ouKey, timeZone }) => ({
    ouKey,
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
  }),
  transformOutput: (data) => data.map(transformForStore),
  notifications: {
    failure: (actionParam, apiError) => `Couldn't retrieve data: ${apiError.message}`,
  },
  enableProgress: true,
});

// fetchFuellingsOptimized({ startDate, endDate, ouKey, timeZone })
export const fetchFuellingsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectFuellingsFetches,
  filterFetches: (fetches, { ouKey, dateRangeFor }) => filter(fetches, { ouKey, dateRangeFor }),
  baseActionType: ACTION_TYPE.fetchFuellings,
  fetchAction: fetchFuellings,
  fetchActionConfig: {
    transformInput: ({ startDate, endDate, ouKey, dateRangeFor }) => ({
      startDate, // startDate is already ISO string
      endDate, // endDate is already ISO string
      ouKey,
      dateRangeFor,
    }),
  },
});
