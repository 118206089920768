import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Chip } from 'stti-react-common';
import { get, find, isArray } from 'lodash';

import { ReportControl } from './ReportControl';
import { filterRowsByIntersection } from '../../../helpers/reports/standardFilters';
import { objectsToSelectOptions } from '../../../helpers/admin/adminUtils';

export const SelectFilterInput = ({
  displayValue,
  field,
  fieldSubMap,
  filterGroup,
  itemPlural,
  label,
  name,
  summaryPrefix,
  filterConfig = {
    group: filterGroup,
    type: filterRowsByIntersection,
    disabled: ({ value }) => !value || value.length === 0,
    controlName: name,
    field: field || name, // default to name
  },
  ...restProps
}) => (
  <ReportControl
    name={name}
    group="filters"
    label={label}
    canDeleteSummary
    render={(renderProps) => (
      <SelectInput
        {...renderProps}
        getOptions={() => objectsToSelectOptions(restProps.getOptions(), 'label', 'value', true)}
        multiple
        multipleCollapseAt={2}
        multipleCollapseText={itemPlural}
      />
    )}
    renderSummary={(renderProps, { value, filteredRows }) => {
      if (!value || value.length === 0) return null;

      let presentation;

      // TODO: add a universal way to get the filtered rows without changing the targets [exceptions/input]
      const target = find(filteredRows?.exceptions || filteredRows?.input, (row) => {
        const element = get(row, field);
        if (isArray(element)) {
          return find(element, (item) => item[fieldSubMap] === value[0]);
        }
        return element === value[0];
      });

      if (target) {
        presentation = fieldSubMap
          ? find(target[field], (item) => item[fieldSubMap] === value[0])[displayValue]
          : get(target, displayValue);
      }

      const chipLabel =
        value.length > 1
          ? `${summaryPrefix}${value.length} ${itemPlural}`
          : `${summaryPrefix}${presentation || value[0]}`;
      return <Chip {...renderProps} label={chipLabel} />;
    }}
    printConfig={({ value }) => value}
    filterConfig={filterConfig}
    {...restProps}
  />
);

SelectFilterInput.propTypes = {
  displayValue: PropTypes.string,
  field: PropTypes.string.isRequired,
  fieldSubMap: PropTypes.string,
  filterConfig: PropTypes.object,
  filterGroup: PropTypes.string.isRequired,
  itemPlural: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summaryPrefix: PropTypes.string.isRequired,
};
