import { connect } from 'react-redux';

import { ApiKeySummary } from './summary';
import { apiKey } from '../../../../../data/administration/configurations';

export const ApiKeySummaryContainer = connect(
  (state) => ({
    apiKeys: apiKey.selectors.selectApiKeys(state),
  }),
  {
    fetchApiKeys: apiKey.actions.fetchApiKeysOptimized,
    deleteApiKey: apiKey.actions.deleteApiKey,
  }
)(ApiKeySummary);
