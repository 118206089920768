import { isString, isEmpty } from 'lodash';

import {
  ADMIN_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  DRIVER_ROLE_KEY,
  GUEST_ROLE_KEY,
  MANAGER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
  SUPER_ADMIN_ROLE_KEY,
} from '../../data/system/constants';

const hasRole = (role, type) => {
  const roleToString = isString(role) ? role : role.key;
  return roleToString.includes(type);
};

const findTenantOU = (ous, membershipOuKey) => {
  if (!ous) return null;
  return ous.find(({ key, scope }) => key === membershipOuKey && scope === 'Tenant');
};

export const getRolesByUserMemberships = (user, ous) => {
  if (isEmpty(user)) {
    return [];
  }
  return {
    isDriver: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, DRIVER_ROLE_KEY))
    ),
    isSystem: user.memberships.some(
      (membership) =>
        membership.role.some((role) => hasRole(role, SUPER_ADMIN_ROLE_KEY)) &&
        !isEmpty(findTenantOU(ous, membership.ou))
    ),
    isAdmin: user.memberships.some((membership) =>
      membership.role.some(
        (role) =>
          (hasRole(role, SUPER_ADMIN_ROLE_KEY) && isEmpty(findTenantOU(ous, membership.ou))) ||
          hasRole(role, ADMIN_ROLE_KEY)
      )
    ),
    isManager: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, MANAGER_ROLE_KEY))
    ),
    isMechanic: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, MECHANIC_ROLE_KEY))
    ),
    isDispatcher: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, DISPATCHER_ROLE_KEY))
    ),
    isGuest: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, GUEST_ROLE_KEY))
    ),
  };
};
