import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { BasicDialog, Form, TextInput } from 'stti-react-common';

const { useFormController, Control } = Form;

export const CreateAnnotationDialog = ({ isOpen, onClose, onConfirm, log, onRefresh }) => {
  const form = useFormController();
  const { controls, hasErrors, isDirty, resetControls } = form;
  const { logDate, driver } = log || {};

  useEffect(() => {
    resetControls();
  }, [isOpen]);

  if (!driver) return '';

  const { key: driverKey } = driver;

  return (
    <BasicDialog
      className="DriverLogDetailReport__CreateAnnotationDialog"
      isOpen={isOpen}
      onClose={onClose}
      title="Add a New Annotation"
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Submit Changes',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            onConfirm({
              driverKey,
              logDate,
              ...controls,
            });
            onClose();
            setTimeout(() => onRefresh({ driverKey, date: logDate, eld: undefined }), 2000);
          },
        },
      ]}
    >
      <Form form={form}>
        <Control
          Component={TextInput}
          name="comment"
          label="Annotation"
          error={({ value }) => !value}
        />
      </Form>
    </BasicDialog>
  );
};

CreateAnnotationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }),
  log: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
