import PropTypes from 'prop-types';
import { filter, meanBy, isNumber, fromPairs } from 'lodash';
import produce from 'immer';

import { unconvertUnit } from '../formats';
import {
  meanOfField,
  sumFields,
  uniqueOfField,
  uniqueOfArrayField,
  divideFields,
} from './aggregation';

const LEG_TYPES = ['empty', 'loading', 'loaded', 'unloading'];
const LEG_PROPERTIES = [
  'distance',
  'duration',
  'waitDuration',
  'addedFuel',
  'consumedFuel',
  'idlingFuel',
  'engineDuration',
  'idlingDuration',
  'averageSpeed',
  'mileage',
];

const cycleIncludesTripLegTypes = (cycle, trip) => {
  const matches = [];

  if (cycle.origin.name === trip.origin.name && cycle.firstLoad.name === trip.firstLoad.name)
    matches.push('empty');

  if (cycle.firstLoad.name === trip.firstLoad.name) matches.push('loading');

  if (
    cycle.firstLoad.name === trip.firstLoad.name &&
    cycle.lastUnload.name === trip.lastUnload.name
  )
    matches.push('loaded');

  if (cycle.lastUnload.name === trip.lastUnload.name) matches.push('unloading');

  return matches.length > 0 ? matches : false;
};

const meanOfIncludedTripLegs = (trips, legType, legProperty) =>
  meanOfField(filter(trips, `${legType}.include`), `${legType}.${legProperty}`);

const determineCyclesFromTrips = (trips) => {
  const cycles = [];
  trips.forEach((trip) => {
    let newCycle = true;
    cycles.forEach((cycle) => {
      if (
        cycle.origin.name === trip.origin.name &&
        cycle.firstLoad.name === trip.firstLoad.name &&
        cycle.lastUnload.name === trip.lastUnload.name
      ) {
        newCycle = false;
      }
    });
    if (newCycle) {
      const id = `${trip.origin.name || '<blank>'}/${trip.firstLoad.name || '<blank>'}/${
        trip.lastUnload.name || '<blank>'
      }`;
      cycles.push({
        id,
        origin: { name: trip.origin.name },
        firstLoad: { name: trip.firstLoad.name },
        lastUnload: { name: trip.lastUnload.name },
      });
    }
  });
  return cycles;
};

const addTripsToCycle = (cycle, trips) => {
  const cycleTrips = [];
  let exactTripsCount = 0;

  trips.forEach((trip) => {
    const legTypeMatches = cycleIncludesTripLegTypes(cycle, trip);
    if (legTypeMatches) {
      // copy trip
      const addTrip = { ...trip };

      // set include on applicable trip legs
      legTypeMatches.forEach((legType) => {
        addTrip[legType] = { ...addTrip[legType], include: true, initialInclude: true }; // set include on this leg
      });

      // special conditions when trip is exact match (i.e. all four legs included)
      if (legTypeMatches.length === LEG_TYPES.length) {
        addTrip.fullCycle = { ...addTrip.fullCycle, include: true, initialInclude: true }; // set include on fullCycle
        addTrip.coreCycle = { ...addTrip.coreCycle, include: true, initialInclude: true }; // set include on coreCycle
        exactTripsCount += 1;
      }

      // add trip to cycle
      cycleTrips.push(addTrip);
    }
  });

  const cycleProperties = {
    trips: cycleTrips,
    count: {
      exactTrips: exactTripsCount,
      contributingTrips: cycleTrips.length,
    },
    driversNames: uniqueOfArrayField(cycleTrips, 'driversNames'),
    trailersNames: uniqueOfArrayField(cycleTrips, 'trailersNames'),
    vehiclesNames: uniqueOfField(cycleTrips, 'vehicle.name'),
    ousNames: uniqueOfField(cycleTrips, 'ou.name'),
  };

  return { ...cycle, ...cycleProperties };
};

const fullCycleAggregate = (cycle, legProperty) =>
  sumFields(
    cycle,
    LEG_TYPES.map((legType) => `${legType}.${legProperty}`)
  );

const addAggregatesToCycle = (cycle) => {
  const legProperties = fromPairs(
    LEG_TYPES.map((legType) => [
      legType,
      fromPairs(
        LEG_PROPERTIES.map((legProperty) => [
          legProperty,
          meanOfIncludedTripLegs(cycle.trips, legType, legProperty),
        ])
      ),
    ])
  );

  const fullCycle = fromPairs(
    LEG_PROPERTIES.map((legProperty) => [
      legProperty,
      fullCycleAggregate(legProperties, legProperty),
    ])
  );

  fullCycle.mileage = divideFields(fullCycle, 'distance', 'consumedFuel');

  const coreCycle = {
    duration: null, // TODO: can calculate once legs have coreDuration
  };

  return { ...cycle, ...legProperties, fullCycle, coreCycle };
};

export const transformTripRowsToCycleRows = ({ rows }) => {
  const cycles = determineCyclesFromTrips(rows).map((cycle) => addTripsToCycle(cycle, rows));
  return cycles;
};
transformTripRowsToCycleRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

export const transformTripRowsToTargetCycleRow = ({
  rows,
  originName,
  firstLoadName,
  lastUnloadName,
}) => {
  const cycleSkeleton = {
    origin: { name: originName },
    firstLoad: { name: firstLoadName },
    lastUnload: { name: lastUnloadName },
  };
  const cycle = addTripsToCycle(cycleSkeleton, rows);
  return [cycle];
};
transformTripRowsToTargetCycleRow.propTypes = {
  rows: PropTypes.array.isRequired,
  originName: PropTypes.string,
  firstLoadName: PropTypes.string,
  lastUnloadName: PropTypes.string,
};

export const transformFirstCycleRowToTripRows = ({ rows }) => {
  if (rows.length === 0)
    throw new Error('No cycle row supplied to transformFirstCycleRowToTripRows.');
  if (rows.length > 1)
    throw new Error('More than one cycle row supplied to transformFirstCycleRowToTripRows.');
  return rows[0].trips;
};
transformFirstCycleRowToTripRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

export const addAggregatesToCycleRows = ({ rows }) => {
  const cycles = rows.map(addAggregatesToCycle);
  return cycles;
};
addAggregatesToCycleRows.propTypes = {
  rows: PropTypes.array.isRequired,
};

export const excludeLegsFromCyclesByUnitRange = ({
  rows,
  min,
  max,
  unitType,
  unit,
  legType,
  legField,
}) => {
  const applyMin = isNumber(min);
  const applyMax = isNumber(max);

  if (!applyMin && !applyMax) return rows;

  const siMin = applyMin && unconvertUnit(unitType, min, unit);
  const siMax = applyMax && unconvertUnit(unitType, max, unit);

  return produce(rows, (draft) => {
    draft.forEach((cycle) => {
      cycle.trips.forEach((trip) => {
        const value = trip[legType][legField];
        if ((applyMin && value < siMin) || (applyMax && value > siMax)) {
          // trip is part of Immer draft
          trip[legType].include = false; // eslint-disable-line no-param-reassign
          trip.fullCycle.include = false; // eslint-disable-line no-param-reassign
          trip.coreCycle.include = false; // eslint-disable-line no-param-reassign
        }
      });
    });
  });
};
excludeLegsFromCyclesByUnitRange.propTypes = {
  rows: PropTypes.array.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  unitType: PropTypes.string.isRequired,
  unit: PropTypes.string,
  legType: PropTypes.oneOf(LEG_TYPES),
  legField: PropTypes.string.isRequired,
};

export const excludeLegsFromCyclesByRelativeRange = ({
  rows,
  max,
  min,
  legType: legTypeParam,
  legField,
}) => {
  const applyMin = isNumber(min);
  const applyMax = isNumber(max);

  if (!applyMin && !applyMax) return rows;

  const legTypes = legTypeParam === 'any' ? LEG_TYPES : [legTypeParam];

  return produce(rows, (draft) => {
    draft.forEach((cycle) => {
      legTypes.forEach((legType) => {
        // get cycle's legType-contributing trips
        const legTrips = filter(cycle.trips, `${legType}.initialInclude`);

        // get average of contributing legs
        const average = meanBy(legTrips, `${legType}.${legField}`);

        // for each trip, exclude legType if legField out of range
        const absoluteMin = applyMin && (min / 100) * average;
        const absoluteMax = applyMax && (max / 100) * average;
        legTrips.forEach((trip) => {
          const value = trip[legType][legField];
          if ((applyMin && value < absoluteMin) || (applyMax && value > absoluteMax)) {
            // trip is part of Immer draft
            trip[legType].include = false; // eslint-disable-line no-param-reassign
            trip.fullCycle.include = false; // eslint-disable-line no-param-reassign
            trip.coreCycle.include = false; // eslint-disable-line no-param-reassign
          }
        });
      });
    });
  });
};
excludeLegsFromCyclesByRelativeRange.propTypes = {
  rows: PropTypes.array.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  legType: PropTypes.oneOf([...LEG_TYPES, 'any']),
  legField: PropTypes.string.isRequired,
};

export const transformCycleRowsToExactRow = ({
  value,
  rows,
  originName,
  firstLoadName,
  lastUnloadName,
}) => {
  const filteredRows = rows.filter(
    ({ origin, firstLoad, lastUnload }) =>
      origin.name === originName &&
      firstLoad.name === firstLoadName &&
      lastUnload.name === lastUnloadName
  );

  return value ? filteredRows : rows;
};
transformCycleRowsToExactRow.propTypes = {
  rows: PropTypes.array.isRequired,
  originName: PropTypes.string,
  firstLoadName: PropTypes.string,
  lastUnloadName: PropTypes.string,
};
