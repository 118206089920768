import { connect } from 'react-redux';

import { GeozoneActivitiesReport } from './GeozoneActivitiesReport';
import { selectGeozoneActivities, fetchGeozoneActivitiesOptimized } from '../../../../data/reports';

import { organization } from '../../../../data/administration/configurations';

export const GeozoneActivitiesReportContainer = connect(
  (state, { geozoneKey }) => ({
    activities: selectGeozoneActivities(state, geozoneKey),
  }),
  {
    fetchGeozoneActivities: fetchGeozoneActivitiesOptimized,
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
  }
)(GeozoneActivitiesReport);
