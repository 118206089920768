import React from 'react';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import { countOf, countUniqueOfField } from '../../../../helpers/reports/aggregation';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const AggregatesContent = ({ rows }) => (
  <AggregatesCard
    title="Overview"
    name="overview"
    fields={[
      {
        header: 'Violations',
        value: countOf(rows),
      },
      {
        header: 'Drivers',
        value: countUniqueOfField(rows, 'driverName'),
      },
    ]}
  />
);

AggregatesContent.propTypes = {
  rows: customPropTypes.speedViolations,
};
