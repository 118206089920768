import {
  find,
  flatten,
  get,
  isArray,
  isFinite,
  isNil,
  isObject,
  isString,
  isUndefined,
  sum,
  uniq,
} from 'lodash';
import moment from 'moment-timezone';

import {
  NA_VALUE,
  convertFuelToStandardEquivalent,
  convertUnit as nonContextConvertUnit, // required in durationColumn filterParams as context is not available there
  formatUnit as nonContextFormatUnit,
} from '../../../helpers/formats';

import { sig } from './aggFuncs';

const earliestMoment = moment('2000-01-01'); // for sort position of nil dates

const unitTypeOfFuelType = (fuelType) => (fuelType === 'LNG' ? 'weight' : 'volume');

const getValues = (data, distanceField, fuelField, fuelTypeField) => {
  const distance = get(data, distanceField);
  const fuelFieldValue = get(data, fuelField);
  const fuel =
    fuelFieldValue && convertFuelToStandardEquivalent(get(data, fuelTypeField), fuelFieldValue);
  return {
    distance,
    fuel,
    toNumber: () => distance / fuel,
  };
};

const unitColumnMethods = (unitType, field, rest = {}) => {
  const {
    precision,
    overrideFormat,
    forceInteger,
    fuelToStandardEquivalent,
    colId,
    distanceField,
    fuelField,
    fuelTypeField,
    lookup,
    filter,
  } = rest;

  if (
    unitType === 'mileage' &&
    colId &&
    field &&
    (!distanceField || !fuelField || !fuelTypeField)
  ) {
    throw new Error(
      'mileageColumn must have colId, distanceField, fuelField, fuelTypeField, and may not have field; you may want simpleMileageColumn.'
    );
  }

  return {
    filterValueGetter: ({ data, context: { convertUnit } }) => {
      switch (unitType) {
        case 'volume':
          return convertUnit(unitTypeOfFuelType(data.fuelType), get(data, field));
        case 'mileage':
          return convertUnit(
            unitType,
            getValues(data, distanceField, fuelField, fuelTypeField).toNumber()
          );
        default:
          return convertUnit(unitType, get(data, field));
      }
    },
    valueFormatter: ({
      value,
      data,
      node,
      context: { formatUnit, formats, formatConvertedUnit },
    }) => {
      const displayValue = value && value.toNumber ? value.toNumber() : value; // when grouping is enabled, value is aggregation object with toNumber() method

      switch (unitType) {
        case 'volume': {
          const conditionalValue = forceInteger ? Math.round(displayValue) : displayValue;
          const isLNG = fuelToStandardEquivalent && data && data.fuelType === 'LNG';

          if (!isFinite(displayValue)) return NA_VALUE;

          if (node.group) {
            const childrenFuelTypes = node.allLeafChildren.map(
              ({ data: { fuelType } }) => fuelType
            );
            const uniqueFuelTypes = uniq(childrenFuelTypes);

            const isGroupLNG =
              fuelToStandardEquivalent &&
              uniqueFuelTypes.length === 1 &&
              uniqueFuelTypes[0] === 'LNG';

            if (uniqueFuelTypes.length > 1) return ''; // mixed unit types, display nothing

            return isGroupLNG
              ? formatUnit(unitTypeOfFuelType(uniqueFuelTypes[0]), conditionalValue, 'l')
              : formatUnit(unitTypeOfFuelType(uniqueFuelTypes[0]), conditionalValue);
          }

          return isLNG
            ? formatUnit(unitTypeOfFuelType(data.fuelType), conditionalValue, 'l')
            : formatUnit(unitTypeOfFuelType(data.fuelType), conditionalValue);
        }
        case 'number': {
          if (isObject(displayValue)) {
            // when grouping is enabled, value is aggregation object with toNumber() method; get it and use formatConvertedUnit which constrains precision
            return formatConvertedUnit('number', displayValue.toNumber());
          }

          if (!isFinite(displayValue)) {
            return NA_VALUE;
          }
          return displayValue; // display raw value
        }
        default: {
          if (precision) {
            return isFinite(displayValue)
              ? nonContextFormatUnit(
                  unitType,
                  displayValue,
                  overrideFormat || formats[unitType],
                  precision
                )
              : NA_VALUE;
          }
          return isFinite(displayValue)
            ? formatUnit(unitType, displayValue, overrideFormat || formats[unitType])
            : NA_VALUE;
        }
      }
    },
    dataExportProcessHeaderCallback: ({ column, context: { unitText, formats } }) => {
      if (unitType === 'number') {
        return column.colDef.headerName;
      }
      return `${column.colDef.headerName} {${unitText(unitType, formats[`${unitType}Export`])}}`;
    },
    dataExportProcessCellCallback: ({ value, data, context: { convertUnit, formats } }) => {
      const displayValue = value && value.toNumber ? value.toNumber() : value;

      if (!isFinite(displayValue)) return NA_VALUE;

      switch (unitType) {
        case 'volume': {
          const unitTypeFound = unitTypeOfFuelType(data.fuelType);
          const isLNG = data && data.fuelType === 'LNG' && fuelToStandardEquivalent;
          return convertUnit(
            unitTypeFound,
            displayValue,
            isLNG ? 'l' : formats[`${unitTypeFound}Export`]
          );
        }
        case 'number': {
          const numericValue = get(data, field);

          if (isArray(numericValue)) {
            // if it is array we need to get the lookup and get the value inside the array
            // we also need to find the correct object inside the array

            const foundObj = find(numericValue, filter);
            if (!foundObj) return 0;
            return foundObj[lookup];
          }

          if (!isFinite(numericValue)) return 0;
          return numericValue;
        }

        default:
          return nonContextFormatUnit(unitType, displayValue, formats[`${unitType}Export`]);
      }
    },
  };
};

export const columnDef = {
  dateColumn: ({ field, variant = 'date', ...rest }) => ({
    field,
    enableRowGroup: true,
    filter: 'agDateColumnFilter',
    width: 150,
    sortable: true,
    resizable: true,
    filterValueGetter: ({ data }) => {
      const isoValue = get(data, field);
      if (!isoValue) return undefined;
      return moment(isoValue).startOf('day').toDate();
    },
    valueGetter: ({ data, node, context: { formatUnit } }) => {
      if (node.group) {
        return {
          momentValue: undefined,
          toString: () => '',
        };
      }
      const isoValue = get(data, field);
      if (!isoValue)
        return {
          momentValue: undefined,
          toString: () => NA_VALUE,
          dayToString: () => NA_VALUE,
        };
      const momentValue = moment(isoValue).tz(data?.timeZone || data?.ouTimeZone || 'UTC'); // Note: requires a timeZone property to be set on the object (offset is not enough to know named time zone)
      const dayToString = () => formatUnit('timelessDate', momentValue);
      const toString = () => formatUnit(variant, momentValue);

      return {
        momentValue,
        toString,
        dayToString,
      };
    },
    comparator: (a, b) => {
      const valueA = a.momentValue || earliestMoment;
      const valueB = b.momentValue || earliestMoment;
      if (valueA.isAfter(valueB)) return 1;
      if (valueA.isBefore(valueB)) return -1;
      return 0;
    },
    dataExportProcessCellCallback: ({ value, context: { formats, formatUnit } }) =>
      value.momentValue ? formatUnit('date', value.momentValue, formats.dateExport) : '',
    keyCreator: ({ value }) => value.dayToString(), // for grouping by day
    ...rest,
  }),
  durationColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    aggFunc: 'avg',
    enableValue: true,
    allowedAggFuncs: ['avg', 'sum'],
    filter: 'agNumberColumnFilter',
    width: 120,
    sortable: true,
    resizable: true,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'lessThanH',
          displayName: 'Less than {hours}',
          test: (filterValue, cellValue) => cellValue < filterValue,
        },
        {
          displayKey: 'lessThanM',
          displayName: 'Less than {minutes}',
          test: (filterValue, cellValue) =>
            cellValue < nonContextConvertUnit('duration', filterValue, 'm'),
        },
        {
          displayKey: 'greaterThanH',
          displayName: 'Greater than {hours}',
          test: (filterValue, cellValue) => cellValue > filterValue,
        },
        {
          displayKey: 'greaterThanM',
          displayName: 'Greater than {minutes}',
          test: (filterValue, cellValue) =>
            cellValue > nonContextConvertUnit('duration', filterValue, 'm'),
        },
      ],
    },
    ...unitColumnMethods('duration', field, rest),
    ...rest,
  }),
  distanceColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    aggFunc: 'avg',
    enableValue: true,
    allowedAggFuncs: ['avg', 'sum'],
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    ...unitColumnMethods('distance', field, rest),
    ...rest,
  }),
  speedColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    aggFunc: 'avg',
    ...unitColumnMethods('speed', field, rest),
    ...rest,
  }),
  fuelColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    aggFunc: 'avg',
    enableValue: true,
    allowedAggFuncs: ['avg', 'sum'],
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    ...unitColumnMethods('volume', field, rest),
    ...rest,
  }),
  simpleMileageColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    aggFunc: 'avg',
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    ...unitColumnMethods('mileage', field, rest),
    ...rest,
  }),
  mileageColumn: ({ field, colId, ...rest }) => ({
    colId,
    type: 'numericColumn',
    aggFunc: 'mileage', // custom aggregation function
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    ...unitColumnMethods('mileage', field, { colId, ...rest }),
    valueGetter: ({ data }) => getValues(data),
    ...rest,
  }),
  numberColumn: ({ field, ...rest }) => ({
    field,
    type: 'numericColumn',
    width: 120,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    aggFunc: 'avg',
    enableValue: true,
    allowedAggFuncs: ['avg', 'sum'],
    ...unitColumnMethods('number', field, rest),
    ...rest,
  }),
  textColumn: ({ field, allowBlank, valueGetter, ...rest }) => {
    const wrappedMethods = {};

    if (valueGetter) {
      // guard valueGetter from grouped data
      wrappedMethods.valueGetter = (params) => {
        if (params.node.group) return undefined;
        return valueGetter(params);
      };
    }

    return {
      field,
      enableRowGroup: true,
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter', // switched from set filter for now due to complicatons: https://www.ag-grid.com/javascript-grid-filter-set/#set-filter
      valueFormatter: ({ value, node }) => value || (allowBlank || node.group ? '' : NA_VALUE),
      comparator: (valueA, valueB) =>
        (valueA || '').toLowerCase().localeCompare((valueB || '').toLowerCase()),
      ...wrappedMethods,
      ...rest,
    };
  },
  rankedTextColumn: ({ valueGetter, ...rest }) => {
    if (!valueGetter)
      throw new Error(
        'rankedTextColumn must have a valueGetter which returns at least { rank: number, label: string }.'
      );

    return {
      enableRowGroup: true,
      aggFunc: sig,
      valueGetter,
      valueFormatter: ({ value: { label } }) => label,
      comparator: (valueA, valueB) => valueA.rank - valueB.rank,
      dataExportProcessCellCallback: ({ value: { label } }) => label,
      filter: 'agTextColumnFilter', // switched from set filter for now due to complicatons: https://www.ag-grid.com/javascript-grid-filter-set/#set-filter
      ...rest,
    };
  },
  enabledDisabledColumn: ({
    field,
    trueLabelText = 'Enabled',
    falseLabelText = 'Disabled',
    nilLabelText = 'N/A',
    ...rest
  }) => ({
    field,
    enableRowGroup: true,
    sortable: true,
    valueGetter: ({ data }) => {
      const value = get(data, field);
      if (isNil(value)) return nilLabelText;
      return value ? trueLabelText : falseLabelText;
    },
    comparator: (valueA, valueB) =>
      (valueA || '').toLowerCase().localeCompare((valueB || '').toLowerCase()),
    ...rest,
  }),
  booleanColumn: ({
    field,
    trueLabelText,
    falseLabelText,
    nilLabelText = NA_VALUE,
    aggFunc = sig,
    trueRank,
    falseRank,
    nilRank = -1,
    ...rest
  }) => {
    if (!isString(trueLabelText) || !isString(falseLabelText))
      throw new Error(
        'booleanColumn must have trueLabelText, falseLabelText; nilLabelText is optional.'
      );

    if (aggFunc && (isUndefined(trueRank) || isUndefined(falseRank) || isUndefined(nilRank)))
      throw new Error(
        'booleanColumn must have trueRank, falseRank, unless aggFunc is disabled; nilRank is always optional.'
      );

    return {
      field,
      enableRowGroup: true,
      aggFunc,
      valueGetter: ({ data }) => {
        const value = get(data, field);
        switch (value) {
          case true:
            return { value, rank: trueRank, label: trueLabelText };
          case false:
            return { value, rank: falseRank, label: falseLabelText };
          default:
            return { value, rank: nilRank, label: nilLabelText };
        }
      },
      valueFormatter: ({ value: { label } }) => label,
      comparator: (valueA, valueB) => valueA.rank - valueB.rank,
      dataExportProcessCellCallback: ({ value: { label } }) => label,
      ...rest,
    };
  },
  iconColumn: ({ field, headerName, ...rest }) => ({
    field, // column must still have a field which will be used as the colId
    sortable: false,
    filter: false,
    resizable: false,
    print: false,
    export: false, // custom property; must add support
    suppressSizeToFit: true, // TODO: get this working, currently column still resizes
    width: 40,
    headerName,
    headerTooltip: headerName,
    headerClass: 'ControlledDataGrid__headerCell--icon',
    cellClass: 'ControlledDataGrid__cell--icon',
    dataExportProcessCellCallback: () => '', // no data; TODO: do not export column at all
    ...rest,
  }),
  actionButtonsColumn: ({ renderer, ...rest }) => ({
    colId: 'actionButtons',
    headerName: null,
    filter: false,
    sortable: false,
    resizable: false,
    print: false,
    suppressMenu: true,
    suppressSizeToFit: true,
    headerClass: 'ControlledDataGrid__headerCell--actionButtons',
    cellClass: 'ControlledDataGrid__cell--actionButtons',
    tableCellClassName: 'ControlledDataTable__cell--actionButtons',
    pinned: 'right',
    width: rest.width || 92, // appropriate for two buttons in Material theme; TODO: ideally can be improved with ag-Grid v23
    cellRendererFramework: renderer,
    ...rest,
  }),
  groupColumn: ({ field, ...rest }) => ({
    field,
    ...rest,
  }),
  listColumn: ({ field, renderer, transformValueElement, ...rest }) => ({
    field,
    resizable: true,
    autoHeight: true,
    sortable: false,
    enableRowGroup: false,
    suppressMenu: true,
    cellRendererFramework: renderer,
    headerClass: 'ControlledDataGrid__headerCell--list',
    cellClass: 'ControlledDataGrid__cell--list',
    valueGetter: ({ data, node }) => {
      if (node.group) return undefined;
      const value = get(data, field);
      if (!value) return undefined;
      return transformValueElement ? value.map((item) => transformValueElement(item)) : value;
    },
    ...rest,
  }),
  joinColumn: ({
    field,
    type,
    transformValueElement,
    groupFn,
    colId,
    format,
    showAggValueOnGroupExpand,
    ...rest
  }) => {
    if (!type) throw new Error('joinColumn must have type.');

    if (!field || !colId) {
      if (type === 'number' && (!transformValueElement || !groupFn || !format)) {
        throw new Error(
          'joinColumn must have field, colId, type, transformValueElement, groupFn and format.'
        );
      }
      throw new Error('joinColumn must have field, colId and type.');
    }

    return {
      field,
      enableValue: true,
      cellClass: showAggValueOnGroupExpand ? '' : 'hideAggregationSummary',
      width: 150,
      ...(type === 'number' && {
        allowedAggFuncs: ['avg'],
        filter: 'agNumberColumnFilter',
        aggFunc: 'avg',
        valueFormatter: ({ value, node, context: { formatUnit } }) => {
          if (node.group) {
            const displayValue = groupFn(
              flatten(node.childrenAfterGroup.map(({ data }) => transformValueElement(data)))
            );
            return Object.entries(displayValue)
              .map(([key, val]) => sum(val.map((row) => row[colId])) / displayValue[key].length)
              .map((item) => formatUnit(format, item))
              .join(' | ');
          }
          return value
            .map((row) => (isFinite(row[colId]) ? formatUnit(format, row[colId]) : NA_VALUE))
            .join(' | ');
        },
      }),
      ...(type === 'string' && {
        filter: 'agTextColumnFilter',
        valueFormatter: ({ value, node }) => {
          if (node.group) return undefined;
          return value.map((row) => row[colId]).join(' | ');
        },
      }),
      ...rest,
    };
  },
};
