export function bindServices() {
  this.activationCodes = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/activationCodes`,
  });

  this.allInspections = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspections/all`,
    pagedGet: true,
  });

  this.activities = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/activities` });

  this.annotations = this._standardRestEndpoint({ url: 'exceptionComments', pagedGet: true });

  this.apiKeys = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/apiKeys` });

  this.assets = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/assets` });

  this.assetTypes = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/assetTypes` });

  this.breadcrumbs = this._standardRestEndpoint({ url: 'breadcrumbs', pagedGet: true });

  this.cameraAdd = this._standardRestEndpoint({
    url: `${this._baseUrl}tenants`,
  });

  this.cameraBreadcrumbs = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/gps`,
  });

  this.cameras = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras`,
  });

  this.cameraVideoLiveStreamData = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/liveStreamingLocations`,
  });

  this.cameraSnapshotLiveData = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/liveSnapshotLocations`,
  });

  this.cameraDevices = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/devices`,
  });

  this.cameraTrips = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/trips`,
  });

  this.cameraRecordings = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/recordings`,
  });

  this.cameraRecordingsStreaming = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/recordings/streamingUrl`,
  });

  this.cameraVirtualEvents = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/virtualEvents`,
  });

  this.cameraVirtualEventsMediaDownload = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/virtualEvents/mediaDownloadUrl`,
  });

  this.cameraWakeUp = this._standardRestEndpoint({
    url: `${this._baseUrl}cameras/wakeUp`,
  });

  this.changeTenantConfiguration = this._standardRestEndpoint({
    url: `${this._originUrl}/api/support/changeTenantConfiguration`,
  });

  this.commands = this._standardRestEndpoint({ url: `${this._originUrl}/api/commands` });

  this.companionApps = this._standardRestEndpoint({
    url: `${this._originUrl}/api/appRepo/companionAppsAdmin`,
  });

  this.countries = this._standardRestEndpoint({
    url: `${this._originUrl}/api/resources/country-list`,
  });

  this.currentOrganizationalUnits = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/currentOrganizationalUnits`,
  });

  this.currentPermissions = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/currentPermissions`,
  });

  this.currentTenant = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/currentTenant`,
  });

  this.currentUser = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/currentUser`,
  });

  this.deviceDiagnosticConfigs = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/deviceDiagnosticConfigs`,
  });

  this.devices = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/devices` });

  this.dispatchAbortReasons = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/dispatchAbortReasons`,
  });

  this.driverLogDataTransfer = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driver-logs/data-transfer`,
  });

  this.driverLogs = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/summaries`,
  });

  this.driverLogDetail = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/detailed`,
  });

  this.driverLogSign = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/signLog`,
  });

  this.driverLogDutyStatus = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/dutyStatus`,
  });

  this.driverLogViolations = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverlogs/logViolations`,
  });

  this.driverLogDailyComment = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/dailyLogComments`,
  });

  this.environments = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/environments`,
  });

  this.exceptions = this._standardRestEndpoint({ url: 'exceptions', pagedGet: true });

  this.ecmReports = this._standardRestEndpoint({ url: 'latestEcmHealths', pagedGet: false });

  this.fleetTracking = this._standardRestEndpoint({ url: `${this._originUrl}/api/fleettracking` });

  this.fleetTrackingBookmarks = this._standardRestEndpoint({
    url: `${this._originUrl}/api/fleettracking/bookmarks`,
  });

  this.fleetTrackingShiftDetails = this._standardRestEndpoint({
    url: `${this._originUrl}/api/documents`,
  });

  this.fuelConsumptions = this._standardRestEndpoint({ url: 'trips', pagedGet: true });

  this.fuellings = this._standardRestEndpoint({ url: 'fuellings', pagedGet: true });

  this.fuelTaxes = this._standardRestEndpoint({ url: 'fuelTaxes', pagedGet: false });

  this.fuelTaxDetails = this._standardRestEndpoint({ url: 'fuelTaxDetails', pagedGet: false });

  this.fuelTaxMileages = this._standardRestEndpoint({ url: `${this._originUrl}/api/documents` });

  this.geozones = this._standardRestEndpoint({
    url: `${this._originUrl}/api/geozoneReports/summaries`,
  });

  this.geozoneActivities = this._standardRestEndpoint({
    url: `${this._originUrl}/api/geozoneReports/detailed`,
  });

  this.inspections = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspection/inspections`,
  });

  this.inspectorInspectionHistory = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspections/latestInspectorInspections`,
  });

  this.inspectionTasks = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspection/inspectionTasks`,
  });

  this.inspectionTemplates = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspection/inspectionTemplates`,
  });

  this.locations = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/locations` });

  this.locationTypes = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/locationTypes`,
  });

  this.messages = this._standardRestEndpoint({
    url: `${this._originUrl}/api/messaging/messages`,
  });

  this.myDriverLogs = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/mySummaries`,
  });

  this.myInspections = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspections/myInspections`,
  });

  this.myPerformances = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverPerformances/mySummaries`,
  });

  this.offDutyReasons = this._standardRestEndpoint({
    url: `${this._originUrl}/api/activities/offDutyReasons`,
  });

  this.organizationalUnits = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/organizationalUnits`,
  });

  this.organizations = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/organizationalUnits`, // TODO: shouldn't we use organizations endpoint?
  });

  this.performances = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverPerformances/summaries`,
  });

  this.performanceDetails = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverPerformances/detailed`,
  });

  this.refinedBreadcrumbs = this._standardRestEndpoint({
    url: 'performanceRuleToggled',
    pagedGet: true,
  });

  this.regulations = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverlogs/regulations`,
  });

  this.remoteActions = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/remoteActions`,
  });

  this.removeVehicleParing = this._standardRestEndpoint({
    url: `${this._originUrl}/api/admin/removeVehicleParing`,
  });

  this.reportEdits = this._standardRestEndpoint({ url: 'reportEdits' });

  this.reportOnDemand = this._standardRestEndpoint({ url: `${this._legacyBaseUrl}onDemandReport` });

  this.reportSchedules = this._standardRestEndpoint({ url: `${this._legacyBaseUrl}schedules` });

  this.reportViews = this._standardRestEndpoint({ url: 'reportViews', pagedGet: true });

  this.roles = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/roles` });

  this.rules = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverperformance/rules`,
  });

  this.rulesPredefined = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverperformance/predefined/rules`,
  });

  this.ruleSets = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverperformance/ruleSets`,
  });

  this.searchLimits = this._standardRestEndpoint({
    url: `${this._originUrl}/api/reporting/summaries/search-limits`,
  });

  this.shiftActivities = this._standardRestEndpoint({ url: 'driverShiftDetails', pagedGet: true });

  this.shifts = this._standardRestEndpoint({ url: 'driverShifts', pagedGet: true });

  this.speedTrackingConfiguration = this._standardRestEndpoint({
    url: `${this._originUrl}/api/speedtracking/configuration`,
  });

  this.speedViolations = this._standardRestEndpoint({ url: `${this._originUrl}/api/documents` });

  this.searchLimits = this._standardRestEndpoint({
    url: `${this._originUrl}/api/reporting/summaries/search-limits`,
  });

  this.signIn = this._standardRestEndpoint({
    url: `${this._originUrl}/api/system/signin?provider=navistream&uuid=${Math.random()}`,
  });

  this.tenantCompanionApps = this._standardRestEndpoint({
    url: `${this._originUrl}/api/support/companionApps`,
  });

  this.tenantsVideoConfigs = this._standardRestEndpoint({ url: `tenants/videoConfigs` });

  this.tenants = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/tenants` });

  this.trips = this._standardRestEndpoint({ url: 'trips', pagedGet: true });

  this.unidentifiedLogs = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/unidentified/summaries`,
  });

  this.unidentifiedLogDetail = this._standardRestEndpoint({
    url: `${this._originUrl}/api/driverLogs/unidentified/detailed`,
  });

  this.users = this._standardRestEndpoint({ url: `${this._originUrl}/api/admin/users` });

  this.vehicleInspectionHistory = this._standardRestEndpoint({
    url: `${this._originUrl}/api/inspections/latestVehicleInspections`,
  });

  this.videoEventBehaviors = this._standardRestEndpoint({ url: 'videoEventBehaviors' });

  this.videoEventFileLocations = this._standardRestEndpoint({ url: 'videoEventFileLocations' });

  this.videoEventTypes = this._standardRestEndpoint({ url: 'videoEventTypes' });

  this.videoConfigs = this._standardRestEndpoint({ url: `videoConfigs` });
}
