import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectInput,
  DateInput,
  Button,
  ToggleInput,
  ControlledDataGridColumnManager,
  useBooleanState,
  BasicDialog,
} from 'stti-react-common';
import moment from 'moment-timezone';
import { sortBy, isNil } from 'lodash';

import { customPropTypes } from '../../../helpers/customPropTypes';
import {
  ReportProvider,
  useReportController,
  ReportControl,
  ReportFilter,
} from '../../commons/ReportsCommon';
import { Filters } from './Filters';
import { Location } from '../../../helpers/navigation/Location';
import { ControlledDataGrid } from '../../commons/ControlledDataGrid';
import { Feature } from '../../commons/Feature';
import { validate24HrTime } from '../../../helpers/form';
import {
  filterRowsByPredicate,
  mutateRows,
  filterRowsByDateRange,
} from '../../../helpers/reports/standardFilters';

import { columnDefs } from './columnDefs';

import './SupportInvestigationReport.scss';

const { createState } = ControlledDataGrid;

const defaultState = () => ({
  controls: {
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    timeZone: 'UTC',
  },
  dataGrid: createState({ columnDefs }),
});

const savedControlNames = ['vehicleKey', 'startDay', 'startTime', 'endDay', 'endTime', 'timeZone'];
const timeZoneOptions = moment.tz
  .names()
  .filter((timeZone) => /^(Canada\/|US\/|UTC)/.test(timeZone))
  .map((timeZone) => ({
    label: timeZone.replace(/\//g, ' / ').replace(/_/g, ' '),
    value: timeZone,
  }));

export const SupportInvestigationReport = ({
  vehicles,
  fetchVehicles,
  fetchBreadcrumbs,
  fetchRefinedBreadcrumbs,
  breadcrumbsByVehicleKey,
  isFeaturePermitted,
  closeRoute,
}) => {
  // STATE

  const [isColumnDialogOpen, openColumnDialog, closeColumnDialog] = useBooleanState();
  const [isFirstRender, setIsFirstRender] = useState(true);

  // INITIALIZE REPORT

  const report = useReportController({
    defaultState,
    filtersConfig: {
      input: breadcrumbsByVehicleKey,
      chain: ['vehicle', 'mutations', 'filters', 'sort'],
    },
  });
  const { controls, debouncedControls, setControl, filteredRows, dataGridController } = report;
  const { includeInactiveVehicles } = controls;

  // LOADING AND PERMISSIONS

  useEffect(() => {
    if (isFeaturePermitted === false) closeRoute(); // will be undefined if pending
  }, [isFeaturePermitted]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender) return; // wait one cycle for all controls to be registered
    const location = new Location();
    savedControlNames.forEach((controlName) => {
      const controlValue = location.query[controlName];
      if (controlValue) setControl(controlName, controlValue);
    });
  }, [isFirstRender]);

  // HANDLERS

  const handleSetUrl = () => {
    const location = new Location();
    savedControlNames.forEach((controlName) => {
      location.query[controlName] = controls[controlName];
    });
    window.location.replace(location.toString());
  };

  const handleExport = () => {
    dataGridController.methods.exportData({ filename: 'Investigation', format: 'csv' });
  };

  const setDates = (draft) => {
    const { startDay, startTime, endDay, endTime, timeZone } = draft.controls;

    const startMoment = moment.tz(`${startDay} ${startTime}`, 'YYYY-MM-DD HH:mm:ss', timeZone);
    const endMoment = moment.tz(`${endDay} ${endTime}`, 'YYYY-MM-DD HH:mm:ss', timeZone);

    if (!startMoment.isValid() || !endMoment.isValid()) {
      draft.controls.startDate = null;
      draft.controls.endDate = null;
      return;
    }

    draft.controls.startDate = startMoment.toISOString();
    draft.controls.endDate = endMoment.toISOString();
  };

  // DATA FETCHING

  const {
    vehicleKey,
    startDate,
    endDate,
    includeSpeedSamples,
    includeOtherBreadcrumbs,
    includePerformanceRuleToggled,
  } = debouncedControls;

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    if (!vehicleKey || !startDate || !endDate) return;

    if (includeSpeedSamples || includeOtherBreadcrumbs)
      fetchBreadcrumbs({
        startDate,
        endDate,
        vehicleKey,
      });
    if (includePerformanceRuleToggled)
      fetchRefinedBreadcrumbs({
        startDate,
        endDate,
        vehicleKey,
        detailed: true,
      });
  }, [
    startDate,
    endDate,
    vehicleKey,
    includeSpeedSamples,
    includeOtherBreadcrumbs,
    includePerformanceRuleToggled,
  ]);

  // UI

  const options = useMemo(() => {
    const filteredVehicles = vehicles?.filter(
      (vehicle) => includeInactiveVehicles || vehicle.active
    );
    return {
      vehicleKeys: filteredVehicles.map(({ key }) => key).sort(),
      vehicleNames: sortBy(filteredVehicles, 'assetId').map(({ key, assetId }) => ({
        value: key,
        label: assetId,
      })),
    };
  }, [vehicles, includeInactiveVehicles]);

  // RENDER

  if (!isFeaturePermitted) return null;

  return (
    <ReportProvider value={report}>
      <Filters />
      <div className="SupportInvestigationReport">
        <Feature.Header title="Support Investigation Report" />
        <BasicDialog isOpen={isColumnDialogOpen} onClose={closeColumnDialog}>
          <ControlledDataGridColumnManager controller={dataGridController} />
        </BasicDialog>
        <div className="SupportInvestigationReport__grid">
          <ReportControl
            render={(props) => <TextInput {...props} />}
            name="vehicleKey"
            label="Vehicle Key"
            error={({ value }) => !value}
            reducerCallback={(draft, value) => {
              draft.controls.vehicleName = value;
            }}
            autoGridArea
          />
          <ReportControl
            render={(props) => <SelectInput options={options.vehicleNames} {...props} />}
            name="vehicleName"
            label="Vehicle Name"
            error={({ value }) => !value}
            disabled={vehicles.length === 0}
            reducerCallback={(draft, value) => {
              draft.controls.vehicleKey = value;
            }}
            autoGridArea
          />
          <ReportControl
            render={(props) => <ToggleInput {...props} />}
            name="includeInactiveVehicles"
            label="Include Inactive Vehicles"
            autoGridArea
          />
          <ReportControl
            render={(props) => <DateInput {...props} />}
            name="startDay"
            label="Start Date"
            error={({ value }) => !value}
            reducerCallback={setDates}
            autoGridArea
          />
          <ReportControl
            render={(props) => <TextInput {...props} />}
            name="startTime"
            label="Start Time"
            error={({ value }) => validate24HrTime(value)}
            reducerCallback={setDates}
            autoGridArea
          />
          <ReportControl
            render={(props) => <DateInput {...props} />}
            name="endDay"
            label="End Date"
            error={({ value }) => !value}
            reducerCallback={setDates}
            autoGridArea
          />
          <ReportControl
            render={(props) => <TextInput {...props} />}
            name="endTime"
            label="End Time"
            error={({ value }) => validate24HrTime(value)}
            reducerCallback={setDates}
            autoGridArea
          />
          <div className="SupportInvestigationReport__timeError">
            {controls.startDate &&
              controls.endDate &&
              controls.endDate <= controls.startDate &&
              'Start must be before End'}
          </div>
          <ReportFilter
            filterConfig={{
              group: 'filters',
              type: filterRowsByDateRange,
              getValues: ({ controls: callbackControls }) => ({
                startDate: callbackControls.startDate,
                endDate: callbackControls.endDate,
                field: 'eventAtUtc',
              }),
            }}
          />
          <ReportControl
            render={(props) => <SelectInput options={timeZoneOptions} {...props} />}
            name="timeZone"
            label="Time Zone"
            error={({ value }) => isNil(value)}
            reducerCallback={setDates}
            filterConfig={{
              group: 'mutations',
              type: mutateRows,
              debugName: 'addTimeZone',
              disabled: ({ value }) => !value,
              callback: ({ draft, value }) => {
                draft.timeZone = value;
                draft.eventAtUtc = draft.eventAt;
                draft.eventAt = moment(draft.eventAt).tz(value).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
              },
            }}
            autoGridArea
          />
          <ReportControl
            render={(props) => <ToggleInput {...props} />}
            name="includeSpeedSamples"
            label="SpeedSampled Breadcrumbs"
            filterConfig={{
              group: 'filters',
              type: filterRowsByPredicate,
              disabled: ({ value }) => value,
              test: ({ row }) => row.source === 'Breadcrumb' && row.eventType === 'SpeedSampled',
              reject: true,
            }}
            autoGridArea
          />
          <ReportControl
            render={(props) => <ToggleInput {...props} />}
            name="includeOtherBreadcrumbs"
            label="Other Breadcrumbs"
            filterConfig={{
              group: 'filters',
              type: filterRowsByPredicate,
              disabled: ({ value }) => value,
              test: ({ row }) => row.source === 'Breadcrumb' && row.eventType !== 'SpeedSampled',
              reject: true,
            }}
            autoGridArea
          />
          <ReportControl
            render={(props) => <ToggleInput {...props} />}
            name="includePerformanceRuleToggled"
            label="Performance Rule Toggled"
            filterConfig={{
              group: 'filters',
              type: filterRowsByPredicate,
              disabled: ({ value }) => value,
              test: ({ row }) => row.source === 'PerformanceRuleToggled',
              reject: true,
            }}
            autoGridArea
          />
        </div>
        <div>
          <Button label="Set URL" variant="contained" onClick={handleSetUrl} />
          <Button label="Select Columns" variant="contained" onClick={openColumnDialog} />
          <Button
            label="Export CSV"
            variant="contained"
            onClick={handleExport}
            disabled={filteredRows.output.length === 0}
          />
          <div className="SupportInvestigationReport__rowCount">
            Rows: {filteredRows.output.length}
          </div>
        </div>
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          rows={filteredRows.output}
        />
      </div>
    </ReportProvider>
  );
};

SupportInvestigationReport.propTypes = {
  vehicles: customPropTypes.vehicles.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  fetchBreadcrumbs: PropTypes.func.isRequired,
  fetchRefinedBreadcrumbs: PropTypes.func.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object.isRequired,
  isFeaturePermitted: PropTypes.bool,
  closeRoute: PropTypes.func.isRequired,
};
