import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

import { Feature } from '../../../../commons/Feature';

import './summary.scss';

export const ActivitySummary = ({ activities, route }) => (
  // RENDER
  <Feature.Wrapper className="ActivitySummary">
    <Feature.Header onClose={route.close} title="Activities" service="activities">
      <Button
        icon="add"
        variant="contained"
        label="Create New Activity"
        disabled
        onClick={() => {}}
      />
    </Feature.Header>
    <Feature.Body>
      <div className="presentation">
        <div className="activityImage" />
        <div className="infoContainer">
          <h3>Activity configuration options coming soon.</h3>
        </div>
      </div>
    </Feature.Body>
  </Feature.Wrapper>
);

ActivitySummary.propTypes = {
  activities: PropTypes.array,
  route: PropTypes.object.isRequired,
};
