import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchSpeedViolations: 'reports/speedViolations/fetchSpeedViolations',
};

const initialState = {
  speedViolations: [],
  fetches: [],
};

export const speedViolationsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchSpeedViolations])) {
      draft.speedViolations = sortBy(
        shallowMergeEntities(state.speedViolations, payload, 'key'),
        'startedAt'
      );
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchSpeedViolations])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, actionParam });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchSpeedViolations])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
