import { filter } from 'lodash';
import moment from 'moment-timezone';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../apiActionSupport';
import { ACTION_TYPE } from './geozonesReducer';
import {
  selectGeozonesFetchRequests,
  selectGeozoneActivitiesFetchRequests,
} from './geozonesSelectors';

import { getMonthInterval } from '../../helpers/moment';

const fetchGeozones = makeRestApiAction({
  service: 'geozones',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchGeozones,
  notificationsItemDescriptor: 'Geozones',
  transformOutput: (responseData, { timeZone }) =>
    responseData.map((row) => {
      // Endpoint response does not give us startedAt or endedAt,
      // so we need to build it based on props [year, month] provided.
      const referenceDate = moment([row.year, row.month - 1]).format('YYYY-MM-DD');
      const referenceMonth = moment([row.year, row.month - 1]).format('YYYY-MM');

      const newRow = { ...row };
      newRow.id = `${row.geozoneKey}-${row.key}-${referenceMonth}`;
      newRow.key = `${row.key}-${referenceMonth}`;
      newRow.ouKey = row.vehicleOuKey;
      newRow.startedAt = getMonthInterval(referenceDate).start;
      newRow.endedAt = getMonthInterval(referenceDate).end;
      newRow.monthYear = `${row.month}-${row.year}`;
      newRow.timeZone = timeZone;
      return newRow;
    }),
});

export const fetchGeozonesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectGeozonesFetchRequests,
  filterFetches: (fetches, { startDate, endDate, ouKey, timeZone, dateRangeFor }) =>
    filter(fetches, { actionParam: { startDate, endDate, ouKey, timeZone, dateRangeFor } }),
  fetchAction: fetchGeozones,
  fetchActionConfig: {
    transformInput: ({ startDate, endDate, ouKey }) => ({
      start: moment(startDate).format('YYYY-MM'),
      end: moment(endDate).format('YYYY-MM'),
      ...(ouKey && { ouKey }),
    }),
  },
  baseActionType: ACTION_TYPE.fetchGeozones,
});

const fetchGeozoneActivities = makeRestApiAction({
  service: 'geozoneActivities',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchGeozoneActivities,
  getId: ({ ouKey, startDate }) => `${ouKey}/${moment(startDate).format('YYYY-MM')}`,
  // endpoint always return an array with only one position,
  // so we will extract the first position
  transformOutput: (responseData, { timeZone }) => {
    const [first] = responseData;
    const { activity } = first;

    if (!activity) return [];
    return activity.map((row) => {
      const newRow = { ...row };
      newRow.id = `${row.driverKey}-${row.key}`;
      newRow.startedAt = getMonthInterval(row.dateEntered).start;
      newRow.endedAt = getMonthInterval(row.dateEntered).end;
      newRow.ouKey = row.vehicleOuKey;
      newRow.timeZone = timeZone;
      return newRow;
    });
  },
  notificationsItemDescriptor: 'Geozones',
});

export const fetchGeozoneActivitiesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectGeozoneActivitiesFetchRequests,
  filterFetches: (fetches, { startDate, endDate, geozoneKey, ouKey, timeZone, dateRangeFor }) =>
    filter(fetches, {
      actionParam: { startDate, endDate, geozoneKey, ouKey, timeZone, dateRangeFor },
    }),
  fetchAction: fetchGeozoneActivities,
  fetchActionConfig: {
    transformInput: ({ geozoneKey }) => ({
      geozoneKey,
    }),
  },
  baseActionType: ACTION_TYPE.fetchGeozoneActivities,
});
