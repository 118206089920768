import React from 'react';
import PropTypes from 'prop-types';

import './HidePrint.scss';

export const HidePrint = ({ hidePrint, children }) => (
  <div className={hidePrint ? 'HidePrint' : ''}> {children}</div>
);

HidePrint.propTypes = {
  hidePrint: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
