import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy, keys, values, uniqueId, flatten, sortBy } from 'lodash';
import { Card } from 'stti-react-common';
import moment from 'moment-timezone';

import { enginePowerEventsColumnDefs } from './columnDefs';
import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const EnginePowerEventsCard = ({ log, printLayoutMode }) => {
  const { eldEvents } = log || {};

  const enginePowerEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(({ eventType }) => eventType.includes('ENGINE'));
  }, [eldEvents]);

  const events = groupBy(enginePowerEvents, ({ date }) => moment(date).format('YYYY-MM-DD'));

  const arr = [];
  keys(events).forEach((value, index) => {
    arr.push({
      key: uniqueId(),
      startOfDayRecord: true,
      eldSequence: index - 1,
      date: moment(value).startOf('day').toISOString(),
    });
    arr.push(values(events)[index]);
  });

  const remappedEvents = sortBy(flatten(arr), ['date', 'eldSequence']);

  const enginePowerEventsDataGridController = useDataGridController({
    columnDefs: enginePowerEventsColumnDefs,
    gridOptions,
  });

  const enginePowerEventsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  return (
    <Card fullWidth>
      <div>
        <h4>Engine Power On/Off Events</h4>
      </div>
      {enginePowerEvents && (
        <ControlledDataTable
          controller={enginePowerEventsDataGridController}
          components={enginePowerEventsDataGridComponents}
          rows={remappedEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

EnginePowerEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  printLayoutMode: PropTypes.string,
};
