import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  simpleAdminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { Feature } from '../../../../commons/Feature';
import { columnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridController } = ControlledDataGrid;

export const RoleSummary = ({ roles = [], fetchRoles, route }) => {
  useEffect(() => {
    fetchRoles();
  }, []);

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  // RENDER
  return (
    <Feature.Wrapper className="RoleSummary">
      <Feature.Header onClose={route.close} title="Roles" service="roles" />
      <Feature.Body>
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          rows={roles}
          sizeColumnsToFit
          rowIdProperty="key"
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

RoleSummary.propTypes = {
  roles: customPropTypes.roles,
  fetchRoles: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
