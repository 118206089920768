import { sortBy, some, filter, intersection, map, find } from 'lodash';

import { PASTEL_COLORS } from './pastelColors';
import { updateThreads, groupThreadsByUser } from './threads';

export const convertToParticipants = (users, activeOu, messages) => {
  if (users.length === 0) {
    return [];
  }

  const threads = updateThreads(messages);
  const threadGroups = groupThreadsByUser(threads);

  return sortBy(
    map(users, (user) => ({
      key: user.key,
      name: user.fullName,
      reference: user.key,
      homeTerminal: user.homeTerminal || activeOu.name, // fallback to active ou
      type: 'User',
      conversations: find(threadGroups, ({ recipient }) => recipient.reference === user.key),
    })),
    ['name']
  );
};

export const getParticipants = (user, users, activeOu, messages) => {
  const isAdminAndScopeTenant = some(
    user.memberships,
    (membership) => membership.roles.includes('S-SUPER_ADMIN') && membership.scope === 'Tenant'
  );

  const allDrivers = filter(users, {
    isDriver: true,
    deleted: false,
  });

  const membershipDrivers = filter(
    allDrivers,
    (row) =>
      intersection(
        map(user.memberships, ({ ou }) => ou),
        map(row.memberships, ({ ou }) => ou)
      ).length > 0
  );

  // If user is Super Admin of Tenant org, we want all drivers
  // else if user is not Super Admin, we get all drivers for each of the user's memberships
  return isAdminAndScopeTenant
    ? convertToParticipants(allDrivers, activeOu, messages)
    : convertToParticipants(membershipDrivers, activeOu, messages);
};

export const getInitials = (participantName) => {
  if (!participantName) return '';
  const initials = participantName.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getIconColor = (firstCharacter) => {
  if (!firstCharacter) return '#FFFFFF';
  const color = find(PASTEL_COLORS, { key: firstCharacter.charAt(0).toUpperCase() });
  return color.value;
};
