import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, flatten, uniq, reject } from 'lodash';
import {
  Form,
  ProgressOverlay,
  TextInput,
  SelectInput,
  TabularInput,
  Button,
} from 'stti-react-common';

import { AdminToggleSelectInput, CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

export const InspectionTemplateDetail = ({
  inspectionTemplates,
  inspectionTemplate,
  inspectionTasks,
  vehicleTypes,
  templateId,
  ous,
  fetchInspectionTasks,
  fetchInspectionTemplate,
  fetchVehicleTypes,
  updateInspectionTemplate,
  deleteInspectionTemplate,
  createInspectionTemplate,
  openInspectionTemplateCreate,
  mode: initialMode = 'view',
  route,
  copiedControls,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const [availableVehicleTypes, setAvailableVehicleTypes] = useState([]);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    Promise.all([fetchVehicleTypes(), fetchInspectionTasks()]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      fetchInspectionTemplate(templateId)
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, templateId]);

  useEffect(() => {
    if (inspectionTemplate && vehicleTypes.length > 0) {
      const currentAssetTypes = inspectionTemplate.assetType.map((type) => type.key).valueOf();
      const usedAssetTypes = reject(
        flatten(uniq(inspectionTemplates.map((template) => template.assetType))),
        (assetType) => currentAssetTypes.includes(assetType)
      );

      setAvailableVehicleTypes(
        reject(vehicleTypes, (vehicleType) =>
          usedAssetTypes.some((usedAssetType) => usedAssetType.key === vehicleType.key)
        ).concat(inspectionTemplate.assetType)
      );
    } else {
      const usedAssetTypes = flatten(
        uniq(inspectionTemplates.map((template) => template.assetType))
      );

      setAvailableVehicleTypes(
        reject(vehicleTypes, (vehicleType) =>
          usedAssetTypes.some((usedAssetType) => usedAssetType.key === vehicleType.key)
        )
      );
    }
  }, [inspectionTemplate, vehicleTypes]);

  const { useFormController, Control } = Form;

  const form = useFormController(
    !copiedControls
      ? {
          controls: inspectionTemplate
            ? {
                ...inspectionTemplate,
                ou: inspectionTemplate.ou && inspectionTemplate.ou.key,
                assetType: inspectionTemplate.assetType.map((type) => type.key),
              }
            : {
                categories: [],
              },
        }
      : {
          controls: {
            ...copiedControls,
            name: `${copiedControls.name} (copy)`,
            assetType: [],
          },
        }
  );

  const { controls: formControls, resetControls } = form;

  if (loading)
    return (
      <div className="InspectionTemplateDetail">
        <ProgressOverlay isOpen={loading} />
      </div>
    );

  return (
    <div className="InspectionTemplateDetail">
      <CrudAdminItem
        title="Inspection Template"
        form={form}
        itemTitle={formControls.name || ''}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        mode={mode}
        onApiError={setApiError}
        setMode={setMode}
        data={formControls}
        onClose={route.close}
        createItem={createInspectionTemplate}
        updateItem={
          inspectionTemplate && !inspectionTemplate.isSystem ? updateInspectionTemplate : null
        }
        deleteItem={
          inspectionTemplate && !inspectionTemplate.isSystem ? deleteInspectionTemplate : null
        }
        copyItem={() => openInspectionTemplateCreate({ copiedControls: formControls })}
        resetControls={resetControls}
      >
        <Feature.Body>
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control
              Component={TextInput}
              name="name"
              label="Template Name"
              error={({ value }) => !value}
            />
            <Control Component={TextInput} name="description" label="Template Description" />
            <Control
              Component={AdminToggleSelectInput}
              name="active"
              label="Active"
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Inactive', value: false },
                { label: 'Active', value: true },
              ]}
            />
            <Control
              Component={SelectInput}
              name="ou"
              label="Applies To"
              options={objectsToSelectOptions(ous, 'name', 'key', true) || []}
            />
            <Control
              Component={SelectInput}
              name="odometerReadingRequired"
              label="Odometer Reading"
              options={[
                { label: 'Disabled', value: 'Disabled' },
                { label: 'Allowed', value: 'Allowed' },
                { label: 'Required', value: 'Required' },
              ]}
            />
            <Control Component={TextInput} name="complianceText" label="Compliance Text" />
            <Control
              Component={SelectInput}
              multiple
              name="assetType"
              label="Vehicle Type"
              error={({ value }) => !value || value.length === 0}
              multiline
              options={objectsToSelectOptions(availableVehicleTypes, 'name', 'key')}
            />
          </div>
          <div className="TabularInput__wrapper">
            <Control
              Component={TabularInput}
              renderHeader={({ addRow }) => (
                <div className="TabularInput__title">
                  <h2>Category</h2>
                  {mode !== 'view' && (
                    <Button variant="outlined" label="Add Category" onClick={addRow} />
                  )}
                </div>
              )}
              renderRow={({ deleteRow }) => (
                <>
                  <Control
                    Component={TextInput}
                    name="name"
                    label="Name"
                    error={({ value }) => !value}
                    readOnly={mode === 'view'}
                  />
                  <Control
                    Component={SelectInput}
                    multiple
                    name="tasks"
                    label="Tasks"
                    multiline
                    options={objectsToSelectOptions(inspectionTasks, 'name', 'key')}
                    readOnly={mode === 'view'}
                  />
                  {(mode === 'edit' || mode === 'create') && (
                    <Button
                      variant="outlined"
                      label="Delete Row"
                      icon="clear"
                      onClick={deleteRow}
                    />
                  )}
                </>
              )}
              name="categories"
              ownErrorName="categoriesTable"
            />
          </div>
        </Feature.Body>
      </CrudAdminItem>
    </div>
  );
};

InspectionTemplateDetail.propTypes = {
  inspectionTemplate: customPropTypes.inspectionTemplate,
  inspectionTemplates: customPropTypes.inspectionTemplates.isRequired,
  inspectionTasks: customPropTypes.inspectionTasks.isRequired,
  vehicleTypes: customPropTypes.vehicleTypes.isRequired,
  ous: customPropTypes.organizations.isRequired,
  copiedControls: customPropTypes.inspectionTemplate,
  templateId: PropTypes.string,
  fetchInspectionTasks: PropTypes.func.isRequired,
  fetchInspectionTemplate: PropTypes.func.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  updateInspectionTemplate: PropTypes.func.isRequired,
  deleteInspectionTemplate: PropTypes.func.isRequired,
  createInspectionTemplate: PropTypes.func.isRequired,
  openInspectionTemplateCreate: PropTypes.func,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
};
