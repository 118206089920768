import { columnDef } from '../../commons/ControlledDataGrid';

const commonColumnDefs = [
  columnDef.textColumn({
    field: 'title',
  }),
  columnDef.textColumn({
    field: 'description',
  }),
  columnDef.textColumn({
    field: 'ou.name',
    headerName: 'Shared With',
  }),
];

export const viewingColumnDefs = commonColumnDefs;

export const editingColumnDefs = [
  ...commonColumnDefs,
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
