import produce from 'immer';

import { reject, isNil } from 'lodash';
import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchFuelTaxes: 'reports/fuelTaxes/fetchFuelTaxes',
  fetchFuelTax: 'reports/fuelTaxes/fetchFuelTax',
  fetchFuelTaxExtraSegments: 'reports/fuelTaxes/fetchFuelTax',
  postFuelTaxEdit: 'reports/fuelTaxes/postFuelTaxEdit',
};

const initialState = {
  summaryData: [],
  detailData: [],
  taxRates: [],
  fetches: [],
};

export const fuelTaxesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFuelTaxes])) {
      draft.summaryData = deepMergeEntities(state.summaryData, payload.data, 'id');
      draft.taxRates = deepMergeEntities(state.taxRates, payload.taxRates, 'id');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchFuelTax,
        ACTION_TYPE.fetchFuelTaxExtraSegments,
      ])
    ) {
      draft.detailData = deepMergeEntities(state.detailData, payload.data, 'id');
    }

    // This action bellow will create a temporary rehydration for the targeted registers
    // we need this in order to update both summary and detail row without having to reload the page
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.postFuelTaxEdit])) {
      const modRow = state.detailData.find(({ id }) => id === payload.recordId);

      if (modRow) {
        const modSummaryRow = state.summaryData.find(({ id }) => id === modRow.fuelTaxId);

        const {
          json: { edits },
          ...props
        } = modRow;

        const rejectedEdits = reject(edits, ({ property }) => property === payload.propertyName);

        const updatedEdits = [
          ...rejectedEdits,
          {
            property: payload.propertyName,
            newValue: payload.newValue.value,
            userKey: payload.editorKey,
            userName: payload.editorName,
          },
        ];

        const foundAddedFuel = payload.propertyName === 'addedFuel' ? payload.newValue.value : null;

        const foundOnHwyDistance =
          payload.propertyName === 'onHighwayDistance' ? payload.newValue.value : null;

        const foundOffHwyDistance =
          payload.propertyName === 'offHighwayDistance' ? payload.newValue.value : null;

        const calcAddedFuel = isNil(foundAddedFuel) ? modRow.addedFuel : foundAddedFuel;

        const calcOnHwyDistance = isNil(foundOnHwyDistance)
          ? modRow.onHighwayDistance
          : foundOnHwyDistance;

        const calcOffHwyDistance = isNil(foundOffHwyDistance)
          ? modRow.offHighwayDistance
          : foundOffHwyDistance;

        const totalDistance = calcOnHwyDistance + calcOffHwyDistance;

        const newSummary = {
          ...modSummaryRow,
          totalDistance,
          onHighwayDistance: calcOnHwyDistance,
          offHighwayDistance: calcOffHwyDistance,
          addedFuel: calcAddedFuel,
          json: {
            edits: updatedEdits,
          },
        };

        const newDetail = {
          ...props,
          totalDistance,
          onHighwayDistance: calcOnHwyDistance,
          offHighwayDistance: calcOffHwyDistance,
          addedFuel: calcAddedFuel,
          json: {
            edits: updatedEdits,
          },
        };

        const updatedSummaryData = modSummaryRow
          ? shallowMergeEntities(state.summaryData, newSummary, 'id')
          : state.summaryData;

        const updatedDetailData = shallowMergeEntities(state.detailData, newDetail, 'id');

        return {
          ...state,
          summaryData: updatedSummaryData,
          detailData: updatedDetailData,
        };
      }
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchFuelTaxes, ACTION_TYPE.fetchFuelTax])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchFuelTaxes, ACTION_TYPE.fetchFuelTax])) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    return draft;
  });
