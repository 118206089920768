/* eslint-disable no-console */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { SelectInput, Chip } from 'stti-react-common';

import { selectSystemActiveOu, selectCurrentOus } from '../../../../data/system';
import { ReportControl } from '../ReportControl';
import { useReport } from '../useReport';
import { filterRowsByIntersection } from '../../../../helpers/reports/standardFilters';
import { debugMode } from '../../../../helpers/debug';

const OPTIONS = {
  selected: {
    label: 'Selected organization',
    value: 'default',
  },
  all: {
    label: 'All organizations',
    value: 'all',
  },
  custom: {
    label: 'Custom selection',
    value: 'custom',
  },
};

export const OusSelector = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    ous: selectCurrentOus(state),
  }),
  {} // empty to prevent dispatch as prop to component
)(
  ({
    activeOu,
    ous,
    hideAllOrganizations,
    onlyAllOrganizations,
    onlySelectedOrganization,
    disableNonAccessibleMessage,
  }) => {
    const customOuOptions = ous.map((ou) => ({ label: ou.name, value: ou.key }));

    const {
      controls: { ouMode, ousSelected },
      setControl,
      state: { lastLoadedState },
    } = useReport();

    useEffect(() => {
      setControl('ousNotFound', 0); // reset when report saved
    }, [lastLoadedState]);

    const ouModeOptions = useMemo(() => {
      if (onlyAllOrganizations) return [OPTIONS.all];
      if (hideAllOrganizations) return [OPTIONS.selected, OPTIONS.custom];
      if (onlySelectedOrganization) return [OPTIONS.selected];
      return [OPTIONS.selected, OPTIONS.all, OPTIONS.custom];
    }, [hideAllOrganizations, onlyAllOrganizations, onlySelectedOrganization]);

    useEffect(() => {
      let ouKeys;
      let ousNotFound = 0;
      switch (ouMode) {
        case 'default':
          ouKeys = [activeOu.key];
          break;
        case 'all':
          ouKeys = ous.map((ou) => ou.key);
          break;
        case 'custom': {
          ouKeys = intersection(
            ousSelected,
            ous.map(({ key }) => key)
          );
          ousNotFound = ousSelected.length - ouKeys.length;

          if (debugMode) {
            if (ousSelected) {
              const ouDisplay = ousSelected.map((ouKey, index) => ({
                ouKeys: ouKeys[index],
                ousSelected: ouKey,
                ousNotFound: !(ouKeys[index] === ouKey),
              }));

              console.groupCollapsed(
                'WEB VIEW INFO - [REPORT LOADER] - OusSelector -> CUSTOM OU SELECTOR -> ORGANIZATIONS KEYS VS SELECTED ORGANIZATIONS KEYS'
              );
              ouDisplay && ouDisplay.length > 0
                ? console.table(ouDisplay)
                : console.info('No Selected Organizations could be retrieved');
              ousNotFound > 0 && console.info('ousNotFound', ousNotFound);
              console.groupEnd();
            } else {
              console.groupCollapsed(
                'WEB VIEW INFO - [REPORT LOADER] - OusSelector -> CUSTOM OU SELECTOR'
              );
              console.info('No Selected Organizations could be retrieved');
              console.groupEnd();
            }
          }

          break;
        }
        default:
          ouKeys = [];
      }

      setControl('ouKeys', ouKeys); // control is invisible and unpersisted, set value here
      if (ousNotFound > 0) {
        setControl('ousSelected', ousSelected);
        setControl('ousNotFound', ousNotFound);
      }
    }, [activeOu, ous, ouMode, ousSelected]);

    const renderOuKeysSummary = (renderProps, { value, controls }) => {
      if (!value) return null;
      if (value.length === 0)
        return <Chip {...renderProps} variant="outlined" label="Select organization(s)" />;
      if (controls.ousNotFound > 0)
        return (
          <Chip
            {...renderProps}
            variant="outlined"
            label={
              disableNonAccessibleMessage
                ? 'Selected organizations'
                : `${value.length} organizations, 
              ${controls.ousNotFound} organizations not accessible`
            }
          />
        );
      if (value.length === 1)
        return (
          <Chip
            {...renderProps}
            color="primary"
            label={SelectInput.labelForValue(customOuOptions, value[0])}
          />
        );
      if (controls.ouMode === 'all')
        return <Chip {...renderProps} color="primary" label="All organizations" />;

      return <Chip {...renderProps} color="primary" label={`${value.length} organizations`} />;
    };

    return (
      <>
        <ReportControl
          name="ouMode"
          group="query"
          label="Organization"
          defaultValue={onlyAllOrganizations ? 'all' : 'default'}
          disabled={onlyAllOrganizations || onlySelectedOrganization}
          render={(renderProps) => <SelectInput {...renderProps} options={ouModeOptions} />}
          reducerCallback={(draft, value) => {
            if (value === 'default') draft.controls.ousSelected = [];
          }}
          reRegisterOn={[activeOu]}
          reducerCallbackOnRestore
          autoGridArea
        />
        <ReportControl
          name="ousSelected"
          group="query"
          label="Selected Organizations"
          defaultValue={[]}
          render={(renderProps, { controls }) =>
            controls.ouMode === 'custom' ? (
              <SelectInput
                {...renderProps}
                multiple
                multipleCollapseAt={2}
                multipleCollapseText="organizations"
              />
            ) : null
          }
          error={({ controls, value }) =>
            controls.ouMode === 'custom' &&
            value.length === 0 &&
            'At least one organization is required.'
          }
          options={customOuOptions}
          autoGridArea
        />
        <ReportControl
          name="ousNotFound"
          group="query"
          label="Inaccessible Organization(s)"
          unpersisted
          defaultValue={0}
          render={({ value, style, className }) =>
            value ? (
              <div style={style} className={className}>
                {value} organizations last saved with report cannot be accessed.
              </div>
            ) : null
          }
          printConfig={({ value }) =>
            value > 0 && `${value} organizations last saved with report cannot be accessed`
          }
          autoGridArea
        />
        <ReportControl
          name="ouKeys"
          label="Organization(s)"
          group="query"
          unpersisted
          defaultValue={[]}
          render={() => null}
          filterConfig={{
            type: filterRowsByIntersection,
            group: 'query',
            field: 'ouKey',
            disabled: () => onlyAllOrganizations || onlySelectedOrganization,
          }}
          reRegisterOn={[ous, onlyAllOrganizations, onlySelectedOrganization]} // printConfig and renderSummary use customOuOptions, based on ous
          renderSummary={renderOuKeysSummary}
          printConfig={({ value }) =>
            value.map((ouKey) => SelectInput.labelForValue(customOuOptions, ouKey)).join(', ')
          }
        />
      </>
    );
  }
);

OusSelector.propTypes = {
  activeOu: PropTypes.shape({ key: PropTypes.string.isRequired }),
  hideAllOrganizations: PropTypes.bool,
  onlyAllOrganizations: PropTypes.bool,
  onlySelectedOrganization: PropTypes.bool,
  ous: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ),
  disableNonAccessibleMessage: PropTypes.bool,
};
