import React from 'react';
import PropTypes from 'prop-types';
import { flatten, uniq } from 'lodash';

import { useFormats } from '../../../../../commons/Formats';
import { AggregatesCard } from '../../../../../commons/ReportsCommon';
import {
  sumOfField,
  countUniqueOfField,
  countUniqueOfArrayField,
  countOf,
  meanOfField,
} from '../../../../../../helpers/reports/aggregation';

import { getFuelName, currencyFormat } from '../../utils';

import { fuelConsumptionRates } from '../../../../../../data/reports/fuel-tax/utils';

const formatTotals = (rows, fuel, formatUnit) => {
  const { totalKilometers, totalLitres, kpl } = fuelConsumptionRates(rows)[fuel];

  return [
    {
      header: 'Total Dist',
      value: formatUnit('distance', Math.round(totalKilometers)),
    },
    {
      header: 'Total Qty Purchased',
      value: formatUnit('volume', Math.round(totalLitres)),
    },
    {
      header: 'Average Perf',
      value: formatUnit('mileage', kpl),
    },
  ];
};

export const AggregatesContent = ({ rows, reportType }) => {
  const drivers =
    reportType === 'fuelTaxSummary'
      ? flatten(rows.map((row) => (row && row.drivers ? row.drivers : [])))
      : rows.map((row) => row.driver);

  const fuelTypes = uniq(rows.map((row) => (row && row.fuelType ? row.fuelType : false))).filter(
    Boolean
  );

  const getFieldsByReportType = () => {
    if (reportType === 'fuelTaxSummary') {
      return [
        {
          header: 'Vehicles',
          value: countUniqueOfArrayField(rows, 'vehicle.name'),
        },
        {
          header: 'Drivers',
          value: countUniqueOfArrayField(drivers, 'name'),
        },
        {
          header: 'Fuel Types',
          value: countUniqueOfField(rows, 'fuelType'),
        },
      ];
    }
    return [
      {
        header: 'Segments',
        value: countOf(rows),
      },
      {
        header: 'Drivers',
        value: countUniqueOfArrayField(drivers, 'name'),
      },
      {
        header: 'Fuel Vendors',
        value: countUniqueOfArrayField(rows, 'fuelVendors'),
      },
    ];
  };

  const { formatUnit } = useFormats();

  return (
    <>
      <AggregatesCard title="Overview" name="overview" fields={getFieldsByReportType()} />

      {reportType === 'fuelTaxSummary' && (
        <AggregatesCard
          title="Summary"
          name="summary"
          fields={[
            {
              header: 'Total Calculated Tax Due',
              value: currencyFormat(sumOfField(rows, 'taxDue')),
            },
            {
              header: 'Total Net Taxable Qty',
              value: formatUnit('volume', Math.round(sumOfField(rows, 'netTaxableQty'))),
            },
            {
              header: 'Overall Average Tax Rate',
              value: (meanOfField(rows, 'taxRate') || 0).toFixed(2),
            },
          ]}
        />
      )}

      {fuelTypes.map((item) => (
        <AggregatesCard
          key={item}
          title={getFuelName(item)}
          name="totals"
          fields={formatTotals(rows, item, formatUnit)}
        />
      ))}
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
  reportType: PropTypes.string,
};

export default sumOfField;
