import BetterPropTypes from 'better-prop-types';

const unidentifiedLogShape = {
  hasActivity: BetterPropTypes.bool.isOptionalButNotNull,
  key: BetterPropTypes.string.isOptionalButNotNull,
  logDate: BetterPropTypes.string.isOptionalButNotNull,
  onDutyNotDrivingTotal: BetterPropTypes.number,
  onDutyDrivingTotal: BetterPropTypes.number,
  onDutyTotal: BetterPropTypes.number,
  offDutyTotal: BetterPropTypes.number,
  ous: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      key: BetterPropTypes.string.isOptionalButNotNull,
      name: BetterPropTypes.string.isOptionalButNotNull,
    })
  ).isOptionalButNotNull,
  timeZone: BetterPropTypes.string.isOptionalButNotNull,
  vehicle: BetterPropTypes.shape({
    key: BetterPropTypes.string.isOptionalButNotNull,
    name: BetterPropTypes.string.isOptionalButNotNull,
  }).isOptionalButNotNull,
};

export const unidentifiedLog = BetterPropTypes.shape(unidentifiedLogShape);

export const unidentifiedLogs = BetterPropTypes.arrayOf(unidentifiedLog);

export const detailedUnidentifiedLog = BetterPropTypes.shape({
  ...unidentifiedLogShape,
  adminDriverChanges: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      key: BetterPropTypes.string.isOptionalButNotNull, // dutyInterval key; will this be a unique change key?
      toDriverKey: BetterPropTypes.string.isOptionalButNotNull,
      annotation: BetterPropTypes.string.isOptionalButNotNull,
    })
  ), // TODO: this is shape coded for, presumably we'll start throwing errors once endpoint starts returning data which is cue to modify code
  driverDetails: BetterPropTypes.arrayOf(BetterPropTypes.shape({})), // TODO: what is shape?
  dutyIntervals: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      annotation: BetterPropTypes.string.isOptionalButNotNull,
      canDelete: BetterPropTypes.bool.isOptionalButNotNull,
      canEdit: BetterPropTypes.bool.isOptionalButNotNull,
      canReassign: BetterPropTypes.bool.isOptionalButNotNull,
      distance: BetterPropTypes.number.isOptionalButNotNull,
      dutyStatus: BetterPropTypes.string.isOptionalButNotNull,
      geoLocation: BetterPropTypes.string,
      endedAt: BetterPropTypes.string.isOptionalButNotNull, // added by selector
      accumulatedVehicleDistance: BetterPropTypes.number,
      elapsedEngineHours: BetterPropTypes.number,
      fromDate: BetterPropTypes.string.isOptionalButNotNull, // added by selector
      isReassigned: BetterPropTypes.bool.isOptionalButNotNull,
      isVirtual: BetterPropTypes.bool.isOptionalButNotNull,
      key: BetterPropTypes.string.isOptionalButNotNull,
      location: BetterPropTypes.shape({
        latitude: BetterPropTypes.number.isOptionalButNotNull,
        longitude: BetterPropTypes.number.isOptionalButNotNull,
      }), // added by selector
      origin: BetterPropTypes.string.isOptionalButNotNull,
      startedAt: BetterPropTypes.string.isOptionalButNotNull, // added by selector
      timeZone: BetterPropTypes.string.isOptionalButNotNull, // added by selector
    })
  ),
  dutyStatusChanges: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      key: BetterPropTypes.string.isOptionalButNotNull,
      location: BetterPropTypes.shape({
        latitude: BetterPropTypes.number.isOptionalButNotNull,
        longitude: BetterPropTypes.number.isOptionalButNotNull,
      }),
    })
  ),
});
