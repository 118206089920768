import { connect } from 'react-redux';

import { OrganizationSummary } from './summary';

import { organization } from '../../../../../data/administration/configurations';

export const OrganizationSummaryContainer = connect(
  (state) => ({
    organizationsTree: organization.selectors.selectOrganizationsTree(state),
    organizations: organization.selectors.selectOrganizations(state),
  }),
  {
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    deleteOrganization: organization.actions.deleteOrganization,
  }
)(OrganizationSummary);
