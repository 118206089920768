import moment from 'moment-timezone';
import { isEmpty, toLower } from 'lodash';
import { columnDef } from '../../../../../commons/ControlledDataGrid';

const getOriginatorUserName = (id, col) => {
  if (!col) return '';
  return (col.find(({ key }) => key === id) || { username: '' }).username;
};

export const annotationsEventsColumnDefs = (
  referencedDrivers,
  referencedSupportUsers,
  timeZone
) => [
  columnDef.textColumn({
    field: 'date',
    headerName: 'Date (MM-DD-YY)',
    width: 70,
    valueFormatter: ({ data: { date } }) => {
      if (!date) return '-';
      return moment.tz(date, timeZone).format('MM-DD-YY');
    },
  }),
  columnDef.textColumn({
    colId: 'dateHours',
    field: 'date',
    headerName: 'Time (HH:MM:SS)',
    width: 70,
    valueFormatter: ({ data: { date } }) => {
      if (!date) return '-';
      return moment.tz(date, timeZone).format('HH:mm:ss');
    },
  }),
  columnDef.textColumn({
    field: 'eldSequence',
    headerName: 'Seq. No.',
    valueFormatter: ({ value }) => `0000${Number(value).toString(16)}`.slice(-4).toUpperCase(),
  }),
  columnDef.textColumn({
    field: 'annotation',
    headerName: 'Comment or Annotation',
  }),
  columnDef.textColumn({
    field: 'modifiedAt',
    headerName: 'Edit Date (MM-DD-YY)',
    width: 70,
    valueFormatter: ({ data: { date, modifiedAt } }) => {
      if (!modifiedAt) return moment.tz(date, timeZone).format('MM-DD-YY');
      return moment.tz(modifiedAt, timeZone).format('MM-DD-YY');
    },
  }),
  columnDef.textColumn({
    colId: 'modifiedAtHours',
    field: 'modifiedAt',
    headerName: 'Edit Time (HH:MM:SS)',
    width: 70,
    valueFormatter: ({ data: { date, modifiedAt } }) => {
      if (!modifiedAt) return moment.tz(date, timeZone).format('HH:mm:ss');
      return moment.tz(modifiedAt, timeZone).format('HH:mm:ss');
    },
  }),
  columnDef.textColumn({
    field: 'originator',
    headerName: 'Edit Username (originator)',
    valueFormatter: ({ data: { driverKey, originator } }) => {
      // it is impossible to determine the username on this case
      if ((!originator || isEmpty(originator)) && !driverKey) return '-';

      // if we don't have the originator object but have the driverKey, so we search in referencedDrivers
      if ((!originator || isEmpty(originator)) && driverKey) {
        return getOriginatorUserName(driverKey, referencedDrivers);
      }

      // if we have the originator object and type property, so we search in relevant type array
      if (originator && originator.type && toLower(originator.type) === 'driver') {
        const lookUpKey = originator && originator.key ? originator.key : driverKey;
        return getOriginatorUserName(lookUpKey, referencedDrivers);
      }

      if (originator && originator.type && toLower(originator.type) === 'support') {
        if (originator.key) {
          return getOriginatorUserName(originator.key, referencedSupportUsers);
        }
      }
      return '-';
    },
  }),
];
