import React from 'react';

import { InspectionTemplateSummaryContainer } from './Summary/connect';
import { InspectionTemplateDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { inspectionTasksSummary } from '../InspectionTasks/routes';

import { organizationDetail } from '../Organizations/routes';
import { vehicleTypeDetail } from '../../resources/VehicleTypes/routes';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const inspectionTemplateSummary = configureRoute({
  name: 'Inspections',
  group: administrationGroups.configurations,
  order: 7,
  path: '/inspections',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => (
    <InspectionTemplateSummaryContainer
      {...props}
      openInspectionTemplateDetail={inspectionTemplateDetail.open}
      openInspectionTemplateCreate={inspectionTemplateCreate.open}
      openOrganizationDetail={organizationDetail.open}
      openVehicleTypeDetail={vehicleTypeDetail.open}
      openInspectionTasks={inspectionTasksSummary.open}
    />
  ),
});

export const inspectionTemplateCreate = configureRoute({
  path: '/inspection/new',
  switchKey: 'inspectionTemplate',
  RouteFrame: BasicRouteFrame,
  render: (props) => <InspectionTemplateDetailContainer {...props} mode="create" />,
});

export const inspectionTemplateDetail = configureRoute({
  path: '/inspection/:templateId',
  switchKey: 'inspectionTemplate',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <InspectionTemplateDetailContainer
      {...props}
      openInspectionTemplateCreate={inspectionTemplateCreate.open}
    />
  ),
});
