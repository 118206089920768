import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import { InfoField, HidePrint } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { NA_VALUE } from '../../../../../helpers/formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

export const VehicleCard = ({ log, hidePrint }) => {
  const { formatUnit } = useFormats();

  const { vehicleDetails } = log || {};

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__VehicleCard" fullWidth>
        <h4>Vehicles</h4>
        {vehicleDetails.map(
          ({
            startEngineTime,
            startVehicleOdometer,
            endVehicleOdometer,
            endEngineTime,
            trailers,
            jurisdictionCountry,
            jurisdictionState,
            plateNumber,
            assetId,
            currentLocation,
            vin,
            key,
          }) => (
            <div key={key} className="DriverLogDetailReport__infoFieldContainer">
              <InfoField name="assetIdAndPlate" label="Vehicle ID" value={assetId || NA_VALUE} />
              <InfoField
                name="jurisdiction"
                label="Plate / Jurisdiction / Country"
                value={
                  !plateNumber && !jurisdictionCountry && !jurisdictionState
                    ? NA_VALUE
                    : `${plateNumber || NA_VALUE} / ${jurisdictionState || NA_VALUE} / ${
                        jurisdictionCountry || NA_VALUE
                      }`
                }
              />
              <InfoField name="vin" label="Vehicle Vin" value={vin} />
              <InfoField name="location" label="Last Location" value={currentLocation} />
              <InfoField
                name="trailers"
                label="Trailer ID(s)"
                value={trailers && trailers.join(', ')}
                joinSymbol={'\n'}
              />
              <InfoField
                name="startVehicleOdometer"
                label="Start Engine Odometer *"
                value={formatUnit('distance', startVehicleOdometer)}
              />
              <InfoField
                name="startEngineHours"
                label="Start Engine Hours *"
                value={formatUnit('duration', startEngineTime)}
              />
              <InfoField
                name="endVehicleOdometer"
                label="End Engine Odometer *"
                value={formatUnit('distance', endVehicleOdometer)}
              />
              <InfoField
                name="endEngineHours"
                label="End Engine Hours *"
                value={formatUnit('duration', endEngineTime)}
              />
            </div>
          )
        )}
        <div>
          <h6>* Collected from the vehicles ECM</h6>
        </div>
      </Card>
    </HidePrint>
  );
};

VehicleCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
