/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { first, last } from 'lodash';
import PropTypes from 'prop-types';
import { ToggleInput } from 'stti-react-common';

import { useReport } from './useReport';
import { validateActivities } from '../../../data/reports/validateActivities';

export const ActivitiesDebug = ({ type, activitiesOf, activities }) => {
  const { forceRefilter, webclientViewReady } = useReport();

  const isQaToolsEnabled = !!localStorage.getItem('showQaTools');
  const isRawActivitiesModeEnabled =
    isQaToolsEnabled && !!localStorage.getItem('QaTools.rawActivitiesMode');

  useEffect(() => {
    if (!isQaToolsEnabled || !webclientViewReady || !activitiesOf || activities.length === 0)
      return;

    const errorMessages = [];

    if (first(activities).startedAt !== activitiesOf.startedAt)
      errorMessages.push(
        `Failed validation: First activity startedAt must match ${type} startedAt`
      );

    if (last(activities).endedAt !== activitiesOf.endedAt)
      errorMessages.push(`Failed validation: Last activity endedAt must match ${type} endedAt`);

    if (errorMessages.length > 0) {
      errorMessages.push('Validation halted.');
      errorMessages.forEach(console.warn);
    } else {
      validateActivities(activities, type);
    }
  }, [webclientViewReady]);

  return isQaToolsEnabled ? (
    <div>
      <ToggleInput
        label="Raw Activities Mode"
        value={isRawActivitiesModeEnabled}
        onChange={(value) => {
          localStorage.setItem('QaTools.rawActivitiesMode', value ? '1' : '');
          forceRefilter();
        }}
      />
    </div>
  ) : null;
};

ActivitiesDebug.propTypes = {
  type: PropTypes.oneOf(['shift']).isRequired,
  activitiesOf: PropTypes.shape({
    startedAt: PropTypes.string.isRequired,
    endedAt: PropTypes.string.isRequired,
  }),
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      startedAt: PropTypes.string.isRequired,
      endedAt: PropTypes.string.isRequired,
    })
  ).isRequired,
};
