/**
 * selectActivities(state) -> [activities]
 *
 * Selector for all activities
 */
export const selectActivities = (state) => state.administration.configurations.activities.data;

/**
 * selectActivitiesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on activities
 */
export const selectActivitiesFetchRequests = (state) =>
  state.administration.configurations.activities.fetches;
