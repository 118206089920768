import { columnDef } from '../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'General',
    children: [
      columnDef.textColumn({
        field: 'source',
        headerName: 'Source',
      }),
      columnDef.dateColumn({
        field: 'eventAt',
        headerName: 'Event At',
        width: 180,
      }),
      columnDef.textColumn({
        field: 'latitude',
        headerName: 'Latitude',
      }),
      columnDef.textColumn({
        field: 'longitude',
        headerName: 'Longitude',
      }),
    ],
  },
  {
    headerName: 'Breadcrumb',
    children: [
      columnDef.textColumn({
        field: 'eventType',
        headerName: 'Event Type',
      }),
      columnDef.speedColumn({
        field: 'speed',
        headerName: 'Speed',
      }),
      columnDef.textColumn({
        field: 'odometer',
        headerName: 'Odometer',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'heading',
        headerName: 'Heading',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'other',
        headerName: 'Other',
        hide: true,
      }),
    ],
  },
  {
    headerName: 'Performance Rule',
    children: [
      columnDef.textColumn({
        field: 'message',
        headerName: 'Message',
      }),
    ],
  },
];
