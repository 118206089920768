/**
 * selectInspectionTasks(state) -> [inspectionTasks]
 *
 * Selector for all inspectionTasks
 */
export const selectInspectionTasks = (state) =>
  state.administration.configurations.inspectionTasks.data;

/**
 * selectInspectionTask(state, props.taskId) -> inspectionTask
 *
 * Selector for single inspectionTask by key
 */
export const selectInspectionTask = (state, props) =>
  state.administration.configurations.inspectionTasks.data.find(
    (item) => item.key === props.taskId
  );

/**
 * selectInspectionTasksFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on inspectionTasks
 */
export const selectInspectionTasksFetchRequests = (state) =>
  state.administration.configurations.inspectionTasks.fetches;
