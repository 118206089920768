import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useReportController,
  ReportProvider,
  FetchReportDetail,
  FetchBreadcrumbs,
} from '../../../commons/ReportsCommon';
import { Feature } from '../../../commons/Feature';
import { TripRoute } from '../../../commons/MapsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { QaDataGrid } from '../../../commons/QaTools';
import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './FuelConsumptionLocationReport.scss';

const requestServices = ['breadcrumbs', 'refinedBreadcrumbs'];

export const FuelConsumptionLocationReport = ({
  fuelConsumption,
  fuelConsumptionId,
  ou,
  route,
  breadcrumbsByVehicleKey,
  fetchFuelConsumption,
}) => {
  const report = useReportController();

  const startDate = fuelConsumption && fuelConsumption.startedAt;
  const endDate = fuelConsumption && fuelConsumption.endedAt;
  const vehicleKey = fuelConsumption && fuelConsumption.vehicle.key;
  const origin = fuelConsumption && fuelConsumption.origin;
  const firstLoad = fuelConsumption && fuelConsumption.firstLoad;
  const lastUnload = fuelConsumption && fuelConsumption.lastUnload;

  const [focus, setFocus] = useState(null);

  useEffect(() => {
    setFocus(fuelConsumption && [origin, firstLoad, lastUnload]);
  }, [origin, firstLoad, lastUnload]);

  const breadcrumbs = useMemo(
    () => sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startDate, endDate),
    [breadcrumbsByVehicleKey[vehicleKey], startDate, endDate]
  );

  return (
    <ReportProvider value={report}>
      {!fuelConsumption && (
        <FetchReportDetail action={fetchFuelConsumption} args={fuelConsumptionId} />
      )}
      <div className="FuelConsumptionLocationReport">
        <Feature.Header
          title="Trip Map"
          subtitle={fuelConsumption && fuelConsumption.vehicle.name}
          onClose={route.close}
          service={requestServices}
        />
        <NaviMap focus={focus}>
          <FetchBreadcrumbs startAt={startDate} endAt={endDate} vehicleKey={vehicleKey} />
          <TripRoute trip={fuelConsumption} breadcrumbs={breadcrumbs} />
        </NaviMap>
        <QaDataGrid data={breadcrumbs} onRowClick={setFocus} />
      </div>
    </ReportProvider>
  );
};

FuelConsumptionLocationReport.propTypes = {
  fuelConsumption: customPropTypes.fuelConsumption,
  fuelConsumptionId: PropTypes.string.isRequired,
  ou: PropTypes.string.isRequired,
  breadcrumbsByVehicleKey: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchFuelConsumption: PropTypes.func.isRequired,
};
