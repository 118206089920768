import PropTypes from 'prop-types';
import { groupBy, intersection } from 'lodash';

import { EXCEPTION_TYPE, getExceptionType } from './exceptionTypes';

const testExceptionRuleOnRow = (exceptionRule, row) => {
  const evaluateRule = (ruleType) => {
    switch (ruleType) {
      case EXCEPTION_TYPE.tripCoreCycleDuration:
      case EXCEPTION_TYPE.tripUnknownStopDuration:
        if (row.duration > exceptionRule.duration) return true;
        return false;
      case EXCEPTION_TYPE.tripFuelMileage:
        if (row.mileage < exceptionRule.mileage && row.distance > (exceptionRule.distance || 0))
          // (distance is optional)
          return true;
        return false;
      case EXCEPTION_TYPE.eventSpeedViolation: {
        const {
          duration,
          other: { speedLimit, averageSpeed },
        } = row;
        const excessPercent = (averageSpeed / speedLimit - 1) * 100;
        if (excessPercent > exceptionRule.percent && duration > exceptionRule.duration) return true;
        return false;
      }
      case EXCEPTION_TYPE.eventTirePressureViolation: {
        const {
          distance,
          other: {
            tirePressureConfig: { maximumLimit },
            loaded,
            averagePressure,
          },
        } = row;
        const excessPercent = (averagePressure / maximumLimit - 1) * 100;
        const loadedStatusMatch =
          exceptionRule.loadedStatus === 'any' ||
          (exceptionRule.loadedStatus === 'loaded' && loaded) ||
          (exceptionRule.loadedStatus === 'unloaded' && !loaded);
        if (
          excessPercent > exceptionRule.percent &&
          distance > exceptionRule.distance &&
          loadedStatusMatch
        )
          return true;
        return false;
      }
      case EXCEPTION_TYPE.eventVideoEvent: {
        const {
          other: { provider, typeDescription, behaviorDescriptions },
        } = row;

        const isProvider =
          exceptionRule &&
          exceptionRule.provider &&
          exceptionRule.provider.toLowerCase() === provider.toLowerCase();

        if (exceptionRule.videoAny && isProvider) return true;
        if (exceptionRule.videoEventTypes.includes(typeDescription) && isProvider) return true;

        if (exceptionRule.provider === 'lytx') {
          if (
            intersection(exceptionRule.videoEventBehaviors, behaviorDescriptions).length > 0 &&
            isProvider
          )
            return true;
        }

        return false;
      }
      default:
        // eslint-disable-next-line no-console
        console.error(`Unknown exception rule type: ${exceptionRule.type}`);
        return false;
    }
  };

  const isException = evaluateRule(exceptionRule.type);

  if (isException)
    return {
      exceptionRule: { ...exceptionRule, title: getExceptionType(exceptionRule.type).title }, // add title
      ...row,
      id: `${row.id}-${exceptionRule.id}`, // this id is only consumed by data grid; combining guarantees uniqueness for grid row
    };

  return false;
};

export const filterRowsByExceptions = ({ rows, exceptionRules }) => {
  const exceptions = [];

  const rowsByExceptionType = groupBy(rows, 'exceptionType');

  exceptionRules.forEach((exceptionRule) => {
    if (!rowsByExceptionType[exceptionRule.type]) return;

    rowsByExceptionType[exceptionRule.type].forEach((row) => {
      const exception = testExceptionRuleOnRow(exceptionRule, row);
      if (exception) exceptions.push(exception);
    });
  });

  return exceptions;
};
filterRowsByExceptions.propTypes = {
  rows: PropTypes.array.isRequired,
  exceptionRules: PropTypes.array, // TODO: make isRequired again; (failing because is initally undefined despite control having defaultValue)
};
