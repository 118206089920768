import { connect } from 'react-redux';

import { VehicleConfiguration } from './configuration';

import {
  signalWebclientViewReady,
  selectSystemUser,
  selectSystemTenant,
  fetchTenantVehicleConfig,
  updateTenantVehicleConfig,
  selectSystemTenantOu,
  selectIsUserSuperAdmin,
  selectSystemOus,
} from '../../../../../data/system';

export const VehicleConfigurationContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    ous: selectSystemOus(state),
    tenant: selectSystemTenant(state),
    tenantOu: selectSystemTenantOu(state),
    isSuperAdmin: selectIsUserSuperAdmin(state),
  }),
  {
    fetchTenantVehicleConfig,
    updateTenantVehicleConfig,
    signalWebclientViewReady,
  }
)(VehicleConfiguration);
