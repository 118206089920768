/**
 * selectInspectionTemplates(state) -> [inspectionTemplates]
 *
 * Selector for all inspectionTemplates
 */
export const selectInspectionTemplates = (state) =>
  state.administration.configurations.inspectionTemplates.data;

/**
 * selectInspectionTemplate(state, props.templateId) -> inspectionTemplate
 *
 * Selector for single inspectionTemplate by key
 */
export const selectInspectionTemplate = (state, props) =>
  state.administration.configurations.inspectionTemplates.data.find(
    (item) => item.key === props.templateId
  );

/**
 * selectInspectionTemplatesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on inspectionTemplates
 */
export const selectInspectionTemplatesFetchRequests = (state) =>
  state.administration.configurations.inspectionTemplates.fetches;
