import { connect } from 'react-redux';

import { companionApp, tenant } from '../../../../../data/administration/configurations';

import { selectSystemTenant } from '../../../../../data/system';

import { CompanionAppSummary } from './summary';

export const CompanionAppSummaryContainer = connect(
  (state) => ({
    companionApps: companionApp.selectors.selectCompanionApps(state),
    tenants: tenant.selectors.selectTenants(state),
    isSystem: selectSystemTenant(state).isSystem,
  }),
  {
    fetchCompanionApps: companionApp.actions.fetchCompanionAppsOptimized,
    fetchTenants: tenant.actions.fetchTenantsOptimized,
    deleteCompanionApp: companionApp.actions.deleteCompanionApp,
  }
)(CompanionAppSummary);
