import { connect } from 'react-redux';

import { DeviceDetail } from './detail';
import { tablet } from '../../../../../data/administration/devices';

import { fetchEnvironmentsOptimized, selectEnvironments } from '../../../../../data/system';

export const TabletDetailContainer = connect(
  (state, props) => ({
    tablet: tablet.selectors.selectTablet(state, props),
    environments: selectEnvironments(state),
  }),
  {
    fetchTablet: tablet.actions.fetchTabletOptimized,
    fetchTablets: tablet.actions.fetchTabletOptimized,
    deleteTablet: tablet.actions.deleteTablet,
    updateTablet: tablet.actions.updateTablet,
    fetchEnvironments: fetchEnvironmentsOptimized,
  }
)(DeviceDetail);
