import { isFinite, keyBy, camelCase, startCase, isNil, includes } from 'lodash';
import moment from 'moment-timezone';

export const NA_VALUE = '—';

const METERS_PER_MILE = 1609.344;
const KM_PER_MILE = METERS_PER_MILE / 1000;
const LITERS_PER_GALLON_US = 3.785411784;
const LITERS_PER_GALLON_I = 4.54609;
const KG_PER_POUND = 0.453592;
const SECONDS_PER_HOUR = 60 * 60;

// key: internal key; note that these values are saved in reportViews, non-date keys must not be changed or removed
// description: friendly format example, use as option labels or other user-facing purposes
// suffix: appended to value when using formatUnit (includes leading spaces as appropriate)
// unit: unit shown in exported header cells or as an endAdornment in value inputs (defaults to trimmed suffix)
// precision: decimal precision after conversion
// display: if false, do not make available as display format (i.e. export-only format)
// export: if false, do not make available as export format (i.e. display-only format)
const formatsConfig = {
  date: [
    {
      key: 'MMM D, YYYY HH:mm',
      description: 'Jan 6, 2019 14:03',
      export: false,
      derived: {
        timelessDate: 'MMM D, YYYY',
        datelessTime: 'HH:mm',
      },
    },
    {
      key: 'MMM D, YYYY HH:mm:ss',
      description: 'Jan 6, 2019 14:03:05',
      export: false,
      derived: {
        timelessDate: 'MMM D, YYYY',
        datelessTime: 'HH:mm:ss',
      },
    },
    {
      key: 'MMM D, YYYY HH:mm z',
      description: 'Jan 6, 2019 14:03 PST',
      export: false,
      derived: {
        timelessDate: 'MMM D, YYYY',
        datelessTime: 'HH:mm z',
      },
    },
    {
      key: 'MMM D, YYYY h:mmA',
      description: 'Jan 6, 2019 2:03PM',
      export: false,
      derived: {
        timelessDate: 'MMM D, YYYY',
        datelessTime: 'h:mmA',
      },
    },
    {
      key: 'MMM D, YYYY h:mmA z',
      description: 'Jan 6, 2019 2:03PM PST',
      export: false,
      derived: {
        timelessDate: 'MMM D, YYYY',
        datelessTime: 'h:mmA z',
      },
    },
    {
      key: 'YYYY-MM-DD HH:mm',
      description: '2019-01-06 14:03',
      export: false,
      derived: {
        timelessDate: 'YYYY-MM-DD',
        datelessTime: 'HH:mm',
      },
    },
    {
      key: 'YYYY-MM-DD HH:mm z',
      description: '2019-01-06 14:03 PST',
      export: false,
      derived: {
        timelessDate: 'YYYY-MM-DD',
        datelessTime: 'HH:mm z',
      },
    },
    {
      key: 'YYYY-MM-DDTHH:mm:ssZZ',
      description: '2019-01-06T14:03:00-0800',
      derived: {
        timelessDate: 'YYYY-MM-DD',
        datelessTime: 'HH:mm:ssZZ',
      },
    },
  ],
  duration: [
    { key: 'h', description: 'Hours', suffix: '', unit: 'hours', display: false },
    { key: 'm', description: 'Minutes', suffix: '', unit: 'mins', display: false },
    { key: 'h:mm', description: 'H:MM', unit: 'hours', export: false },
    { key: 'h:mm:ss', description: 'H:MM:SS', unit: 'hours', export: false },
    { key: 'h.000', description: 'H.hhh', unit: 'hours', export: false },
    { key: 'h.0', description: 'H.h', unit: 'hours', export: false },
  ],
  distance: [
    { key: 'km', description: 'km, km/h', suffix: ' km', precision: 1 },
    { key: 'mi', description: 'mi, mph', suffix: ' mi', precision: 1 },
  ],
  distanceEld: [
    { key: 'km', description: 'km, km/h', suffix: ' km', precision: 0 },
    { key: 'mi', description: 'mi, mph', suffix: ' mi', precision: 0 },
  ],
  speed: [
    // speed keys match distance, i.e. there are no speedFormat or speedExportFormat controls
    { key: 'km', description: 'km/h', suffix: ' km/h', precision: 0 },
    { key: 'mi', description: 'mph', suffix: ' mph', precision: 0 },
  ],
  pressure: [
    // defaulting to psi for now until we have a more detailed unit system
    { key: 'psi', description: 'PSI', suffix: ' PSI', precision: 0 },
    { key: 'kpa', description: 'kPa', suffix: ' kPa', precision: 0 },
  ],
  volume: [
    { key: 'l', description: 'L', suffix: ' L', precision: 1 },
    { key: 'gal_i', description: 'Gal (Imperial)', suffix: ' gal', precision: 1 },
    { key: 'gal_us', description: 'Gal (US)', suffix: ' gal', precision: 1 },
  ],
  weight: [
    { key: 'kg', description: 'kg', suffix: ' kg', precision: 1 },
    { key: 'lb', description: 'lb', suffix: ' lb', precision: 1 },
    { key: 'l', description: 'L', suffix: ' L', precision: 0 },
  ],
  mileage: [
    { key: 'km/l', description: 'km/L', suffix: ' km/L', precision: 2 },
    { key: 'l/100km', description: 'L/100km', suffix: ' L/100km', precision: 0 },
    { key: 'mpg_i', description: 'MPG (Imperial)', suffix: ' MPG', precision: 0 },
    { key: 'mpg_us', description: 'MPG (US)', suffix: ' MPG', precision: 0 },
  ],
};

const formatWithDefaults = (format) => ({
  unit: (format.suffix || '').trim(),
  display: true,
  export: true,
  ...format,
});

const formatDefinitions = Object.entries(formatsConfig).reduce(
  (acc, [formatType, formats]) => ({
    ...acc,
    [formatType]: {
      keyed: keyBy(formats.map(formatWithDefaults), 'key'),
      ordered: formats.map(formatWithDefaults),
    },
  }),
  {}
);

const localeNumberFormat = (value) => {
  const outPutValue = parseFloat(value);
  const numberFormat = new Intl.NumberFormat('en-US');
  return numberFormat.format(outPutValue);
};

export const getFormatDefinitions = (formatType) => formatDefinitions[formatType].ordered;

export const getFormatDefinition = (formatType, formatKey) =>
  formatDefinitions[formatType].keyed[formatKey];

// convert raw/SI value to target units
export const convertUnit = (unitType, value, convertToUnits) => {
  if (value === null) return null;
  switch (unitType) {
    case 'date':
    case 'datelessTime':
    case 'timelessDate':
      return value; // no conversion for date
    case 'duration': // SI unit: s
      switch (convertToUnits) {
        case 'm':
          return value / 60;
        case 'h':
        case 'h.000':
        case 'h.0':
        case 'h:mm':
        case 'h:mm:ss':
          return value / 60 / 60;
        case 's':
        default:
          return value;
      }
    case 'distance':
    case 'distanceEld': // SI unit: m
      switch (convertToUnits) {
        case 'mi':
          return value / METERS_PER_MILE;
        case 'km':
        default:
          return value / 1000;
      }
    case 'speed': // SI unit: m/s
      switch (convertToUnits) {
        case 'mi':
          return (value * SECONDS_PER_HOUR) / 1000 / KM_PER_MILE;
        case 'km':
        default:
          return (value * SECONDS_PER_HOUR) / 1000;
      }
    case 'pressure': // SI unit: pa
      switch (convertToUnits) {
        case 'kpa':
          return value / 1000;
        case 'psi':
          return value / 6894.75729;
        default:
          return value;
      }
    case 'volume': // SI unit: l
      switch (convertToUnits) {
        case 'gal_i':
          return value / LITERS_PER_GALLON_I;
        case 'gal_us':
          return value / LITERS_PER_GALLON_US;
        case 'l':
        default:
          return value;
      }
    case 'weight': // SI unit: kg
      switch (convertToUnits) {
        case 'lb':
          return value / KG_PER_POUND;
        case 'l':
          return value / 0.73;
        case 'kg':
        default:
          return value;
      }
    case 'mileage': // no SI unit; using base unit of m/l
      switch (convertToUnits) {
        case 'l/100km':
          return 100 / (value / 1000);
        case 'mpg_i':
          return (value / 1000 / KM_PER_MILE) * LITERS_PER_GALLON_I;
        case 'mpg_us':
          return (value / 1000 / KM_PER_MILE) * LITERS_PER_GALLON_US;
        case 'km/l':
        default:
          return value / 1000;
      }
    case 'number':
    case 'integer':
    case 'relative':
      return value;
    default:
      throw new Error(`Cannot convert unitType "${unitType}"`);
  }
};

// "unconvert" value in converted units back to SI value
export const unconvertUnit = (unitType, value, convertFromUnits) => {
  switch (convertFromUnits) {
    case 'l/100km':
      return convertUnit(unitType, 1, convertFromUnits) / value;
    default:
      return value / convertUnit(unitType, 1, convertFromUnits);
  }
};

// format value already converted from raw/SI units into target format
export const formatConvertedUnit = (unitType, value, format) => {
  switch (unitType) {
    case 'date':
    case 'datelessTime':
    case 'timelessDate': {
      // valid value is moment object, format is moment format string
      if (!value || !value.format) return '';
      return value.format(format);
    }
    case 'integer':
      return value || 0; // always show number (never NA_VALUE)
    default:
      if (!isFinite(value)) return NA_VALUE; // otherwise okay to proceed with next switch
  }

  switch (unitType) {
    case 'duration':
      switch (format) {
        case 'h':
          return value.toFixed(4);
        case 'h.0':
          return value.toFixed(1);
        case 'h.000':
          return value.toFixed(3);
        case 'h:mm': {
          const totalMins = Math.round(value * 60);
          const displayHours = Math.floor(totalMins / 60);
          const displayMins = totalMins % 60;
          return `${displayHours}:${String(displayMins).padStart(2, '0')}`;
        }
        case 'h:mm:ss': {
          const totalSecs = Math.round(value * 60 * 60);
          const displayHours = Math.floor(totalSecs / SECONDS_PER_HOUR);
          const displayMins = Math.floor(totalSecs / 60) % 60;
          const displaySecs = totalSecs % 60;
          return `${displayHours}:${String(displayMins).padStart(2, '0')}:${String(
            displaySecs
          ).padStart(2, '0')}`;
        }
        case 'm':
          return value.toFixed(2);
        case 's':
          return `${value}s`;
        default:
          return value; // no special format for 'h', 'm'
      }
    case 'distance': {
      const formatDefinition = getFormatDefinition(unitType, format);

      return `${localeNumberFormat(value.toFixed(formatDefinition.precision))}${
        formatDefinition.suffix
      }`;
    }
    case 'distanceEld': {
      const formatDefinition = getFormatDefinition(unitType, format);
      const roundedDownValue = Math.floor(value);

      return `${localeNumberFormat(roundedDownValue.toFixed(0))}${formatDefinition.suffix}`;
    }
    case 'speed':
    case 'mileage':
    case 'volume':
    case 'weight':
    case 'pressure': {
      const formatDefinition = getFormatDefinition(unitType, format);
      return `${localeNumberFormat(value.toFixed(formatDefinition.precision))}${
        formatDefinition.suffix
      }`;
    }
    case 'number':
      return value.toFixed(2); // hardcoded precision of 2 for now
    case 'relative':
      return `${value}% of average`;
    default:
      throw new Error(`Cannot format unitType "${unitType}"`);
  }
};

// format raw/SI value
export const formatUnit = (unitType, value, format, precision) =>
  formatConvertedUnit(unitType, convertUnit(unitType, value, format), format, precision);

export const unitText = (unitType, format) => getFormatDefinition(unitType, format).unit;

/*
convertFuelToStandardEquivalent accounts for weight-based fuels like LNG (below) or CNG (pending)

0.73 kg of LNG = 1 diesel litre equivalent for mileage calculations
Source: https://www2.gov.bc.ca/assets/gov/taxes/sales-taxes/forms/fin-360guide-ifta-quarterly-tax-return-instructions.pdf (Appendix 4)
*/
export const convertFuelToStandardEquivalent = (fuelType, value) => {
  if (fuelType === 'LNG') return value / 0.73;
  return value;
};

// limit is a number representing meters
export const metersToKilometersMilesConverter = (value, limit) => {
  if (!value) return '--';
  if (!limit) {
    return `${Number((value * 0.001).toFixed(0)).toLocaleString('en-US')} km / ${Number(
      (value * 0.0006213712).toFixed(0)
    ).toLocaleString('en-US')} mi`;
  }

  const limitVal = value > limit ? limit : value;

  return `${Number((limitVal * 0.001).toFixed(0)).toLocaleString('en-US')} km / ${Number(
    (limitVal * 0.0006213712).toFixed(0)
  ).toLocaleString('en-US')} mi`;
};

export const titleCaseValueFormatter = ({ value }) =>
  (value && startCase(camelCase(value))) || NA_VALUE;

export const originStringFormatter = (value) => {
  switch (value) {
    case 'ELD': {
      return 'Eld(1)';
    }
    case 'INTERMEDIATE': {
      return 'Intermediate(1)';
    }
    case 'DRIVER': {
      return 'Driver(2)';
    }
    case 'OTHER_USER': {
      return 'Other User(3)';
    }
    case 'UNIDENTIFIED_DRIVER': {
      return 'Unidentified Driver(4)';
    }
    default:
      return value;
  }
};

export const eldLatLonCodeFormatter = (value) => {
  switch (value) {
    case 'MANUAL': {
      return 'M';
    }
    case 'ERROR': {
      return 'E';
    }
    case 'MALFUNCTION': {
      return 'E';
    }
    default:
      return 'X';
  }
};

function formatDistanceValue(value) {
  if (!value) return 0;
  if (value > 9000) return 9000;
  return value;
}

export const treatStartOfDayEvent = (data, value) => {
  const { startOfDayRecord } = data;
  if (startOfDayRecord) {
    return ' ';
  }
  // this is a string comparison
  if (value === 'undefined') {
    return '-';
  }
  return value;
};

export const formatDistanceSinceLastGps = (value) => {
  const newValue = formatDistanceValue(value);
  return `${(newValue * 0.001).toFixed(0)} km / ${(newValue * 0.0006213712).toFixed(0)} mi`;
};

export const eventToELDString = (data, value) => {
  const { zone, timeDeferred } = data;

  const formatted = moment.utc(timeDeferred * 1000).format('HH:mm');

  switch (value) {
    case 'ZONE': {
      if (zone === 'Canada60South') {
        return 'Operation Zone 1. (South of latitude 60N in Canada)';
      }
      return 'Operation Zone 2. (North of latitude 60N in Canada)';
    }

    case 'DEFERRAL_DEFERRAL_DAY_1': {
      return `Off-Duty Time Deferral Day 1 (${formatted})`;
    }

    case 'DEFERRAL_DEFERRAL_DAY_2': {
      return `Off-Duty Time Deferral Day 2 (${formatted})`;
    }

    case 'DEFERRAL_NOT_DEFERRAL': {
      return 'Off-Duty Time Deferral None';
    }

    case 'CYCLE_CAD_CYCLE_1': {
      return 'Cycle 1 (7 Days)';
    }

    case 'CYCLE_CAD_CYCLE_2': {
      return 'Cycle 2 (14 Days)';
    }

    default:
      return value;
  }
};

export const formatBooleanValue = (value) => {
  if (isNil(value)) {
    return NA_VALUE;
  }
  return value ? 'E' : '0';
};

export const lastPartAfterSign = (str, separator = '/') => {
  if (!str) return false;
  const result = str.substring(str.lastIndexOf(separator) + 1);
  return result !== str ? result.trim() : false;
};

export const getRegulationNumber = (regulationString) => {
  if (includes(regulationString, 'US Federal')) {
    return 3;
  }
  if (includes(regulationString, 'North of 60')) {
    return 2;
  }
  return 1;
};

export const getDeferralStatus = (deferralStatus) => {
  switch (deferralStatus) {
    case 'N/A':
    case 'None':
      return 0;
    case 'Day 1':
    case 'DEFERRAL_DAY_1':
      return 1;
    default:
      return 2;
  }
};

export const dutyStatusFormatter = (dutyStatus) => {
  switch (dutyStatus) {
    case 'ON_DUTY':
      return 'On Duty Not Driving';

    default:
      return titleCaseValueFormatter({ value: dutyStatus });
  }
};

export const replaceStringByRegex = (regex, original, replacer) =>
  original.replace(regex, replacer);
