import React, { useState } from 'react';
import { GoogleMap, useBooleanState } from 'stti-react-common';

import { ReportControl } from '../../ReportsCommon';
import './FleetMapTypeControl.scss';

const { ControlsButton } = GoogleMap;

export const FleetMapTypeControl = () => {
  const [isShownTerrain, setIsShownTerrain] = useState(false);
  const [isShownLabel, setIsShownLabel] = useState(false);
  const [isTerrain, checkTerrain, unCheckTerrain] = useBooleanState();
  const [isLabel, checkLabel, unCheckLabel] = useBooleanState();

  const terrainSelection = (onChange, stateValue) => {
    if (!isTerrain) {
      checkTerrain();
    } else {
      unCheckTerrain();
    }
    if (stateValue !== 'terrain') {
      onChange('terrain');
    } else {
      onChange('roadmap');
    }
  };

  const labelSelection = (onChange, stateValue) => {
    if (!isLabel) {
      checkLabel();
    } else {
      unCheckLabel();
    }
    if (stateValue !== 'hybrid') {
      onChange('hybrid');
    } else {
      onChange('satellite');
    }
  };

  return (
    <ReportControl
      name="mapType"
      group="map"
      defaultValue="roadmap"
      render={({ value, onChange }) => (
        <>
          <div className="FleetMap__MapTypeControl">
            <ControlsButton
              onMouseEnter={() => setIsShownTerrain(true)}
              onClick={() => onChange('roadmap')}
              label="Map"
              className={`FleetMap__MapTypeControl__ControlsButton ${
                ['roadmap', 'terrain'].includes(value)
                  ? 'FleetMap__MapTypeControl__ControlsButton--selected'
                  : ''
              }`}
            />
            <ControlsButton
              onMouseEnter={() => setIsShownLabel(true)}
              onClick={() => onChange('hybrid')}
              label="Satellite"
              className={`FleetMap__MapTypeControl__ControlsButton ${
                value === 'hybrid' ? 'FleetMap__MapTypeControl__ControlsButton--selected' : ''
              }`}
            />
          </div>
          <div className="FleetMap__MapTypeControl-hover">
            <ControlsButton
              icon={isTerrain ? 'check_box' : 'check_box_outline_blank'}
              label="Terrain"
              onClick={() => {
                terrainSelection(onChange, value);
              }}
              onMouseLeave={() => setIsShownTerrain(false)}
              className={`FleetMap-MapTypeControl-ControlsButton `}
              style={{
                visibility:
                  isShownTerrain && ['roadmap', 'terrain'].includes(value) ? '' : 'hidden',
              }}
            />
            <ControlsButton
              label="Label"
              icon={isLabel ? 'check_box' : 'check_box_outline_blank'}
              onClick={() => labelSelection(onChange, value)}
              onMouseLeave={() => setIsShownLabel(false)}
              className={`FleetMap-MapTypeControl-ControlsButton `}
              style={{
                visibility: isShownLabel && ['hybrid', 'satellite'].includes(value) ? '' : 'hidden',
              }}
            />
          </div>
        </>
      )}
    />
  );
};
