import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import {
  scrubIsSystemProperty,
  appendIsSystemProperty,
  removeDeleteParams,
} from '../../../../helpers/admin/adminActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectInspectionTemplatesFetchRequests } from './selectors';

const scrubCategoriesProperty = ({ categories, ...rest }) => ({
  ...rest,
  categories: categories.map((category) => ({
    name: category.name,
    tasks: category.tasks.map((task) => task.key),
  })),
});

const fetchInspectionTemplates = makeRestApiAction({
  service: 'inspectionTemplates',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspectionTemplates,
  transformInput: () => ({
    fetch: `ou,assetType`,
  }),
  transformOutput: (responseData) => responseData.map(appendIsSystemProperty),
  notificationsItemDescriptor: 'inspection templates',
});

export const fetchInspectionTemplatesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectInspectionTemplatesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchInspectionTemplates,
  baseActionType: ACTION_TYPE.fetchInspectionTemplates,
});

const fetchInspectionTemplate = makeRestApiAction({
  service: 'inspectionTemplates',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspectionTemplate,
  getId: (key) => key,
  transformOutput: (responseData) => appendIsSystemProperty(scrubCategoriesProperty(responseData)),
  notificationsItemDescriptor: 'inspection template',
});

export const fetchInspectionTemplateOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectInspectionTemplatesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchInspectionTemplate,
  baseActionType: ACTION_TYPE.fetchInspectionTemplate,
});

export const deleteInspectionTemplate = makeRestApiAction({
  service: 'inspectionTemplates',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteInspectionTemplate,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'inspection template',
});

export const createInspectionTemplate = makeRestApiAction({
  service: 'inspectionTemplates',
  method: 'post',
  baseActionType: ACTION_TYPE.createInspectionTemplate,
  transformInput: scrubIsSystemProperty,
  transformOutput: appendIsSystemProperty,
  notificationsItemDescriptor: 'inspection template',
  throwApiErrors: true,
});

export const updateInspectionTemplate = makeRestApiAction({
  service: 'inspectionTemplates',
  method: 'put',
  baseActionType: ACTION_TYPE.updateInspectionTemplate,
  getId: ({ key }) => key,
  transformInput: scrubIsSystemProperty,
  transformOutput: appendIsSystemProperty,
  notificationsItemDescriptor: 'inspection template',
  throwApiErrors: true,
});
