import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Clickable, useBooleanState } from 'stti-react-common';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { useDebounce } from '../../../../../helpers/hooks';
import { columnDefs } from './columnDefs';
import { Feature } from '../../../../commons/Feature';
import {
  AdminSearchPanel,
  AdminExportDataMenu,
  DeleteAdminItemDialog,
} from '../../../../commons/AdminCommon';
import { ToggleChip } from '../../../../commons/ToggleChip';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const VehicleTypeSummary = ({
  vehicleTypes,
  fetchVehicleTypes,
  deleteVehicleType,
  openVehicleTypeCreate,
  openVehicleTypeDetail,
  openOrganizationDetail,
  activeOu,
  tenant,
  route,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    fetchVehicleTypes();
  }, []);

  const vehicleTypesByUserMembership = React.useMemo(() => {
    if (!activeOu.name) return vehicleTypes;
    return vehicleTypes.filter(
      (membership) => !membership.ou || (membership.ou && membership.ou.name === activeOu.name)
    );
  }, [activeOu.name, vehicleTypes]);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) =>
      !data.deleted &&
      !data.isSystem && (
        <>
          <Button
            disabled={!data.ou || (data.ou && Object.keys(data.ou).length === 0) || false}
            onClick={() => {
              openVehicleTypeDetail({ assetTypeId: data.key, mode: 'edit' });
            }}
            icon="edit"
          />
          <Button
            disabled={!data.ou || (data.ou && Object.keys(data.ou).length === 0) || false}
            onClick={() => {
              setTargetRow(data);
              openDeleteDialog();
            }}
            icon="delete"
          />
        </>
      ),
    AssetType: ({ data, value }) =>
      value ? (
        <Clickable
          onClick={() => {
            openVehicleTypeDetail({ assetTypeId: data.key, mode: 'view' });
          }}
        >
          {value}
        </Clickable>
      ) : (
        '—'
      ),
    ToggleSleeperBerth: ({ value }) => (
      <ToggleChip value={value} trueLabelText="Equipped" falseLabelText="Not Equipped" />
    ),
    AppliesTo: ({ data, value }) =>
      value ? (
        <Clickable
          onClick={() => {
            openOrganizationDetail({ organizationId: data.ou.key, mode: 'view' });
          }}
        >
          {value}
        </Clickable>
      ) : (
        '—'
      ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  return (
    <Feature.Wrapper className="VehicleTypeSummary">
      <Feature.Header onClose={route.close} title="Vehicle Types" service="assetTypes">
        <AdminExportDataMenu dataGridController={dataGridController} filename="Vehicle_Types" />
        <Button
          icon="add"
          variant="contained"
          label="Create New Vehicle Type"
          onClick={() => {
            openVehicleTypeCreate();
          }}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Vehicles Types"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={vehicleTypesByUserMembership}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
          enableCellTextSelection
        />
        <DeleteAdminItemDialog
          dialogTitle="Vehicle Type"
          itemTitle={targetRow.name}
          isOpen={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          onConfirm={deleteVehicleType}
          rowData={targetRow}
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

VehicleTypeSummary.propTypes = {
  vehicleTypes: customPropTypes.vehicleTypes.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  deleteVehicleType: PropTypes.func.isRequired,
  openVehicleTypeDetail: PropTypes.func.isRequired,
  openVehicleTypeCreate: PropTypes.func.isRequired,
  openOrganizationDetail: PropTypes.func.isRequired,
  activeOu: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
  route: PropTypes.object,
};
