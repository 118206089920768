import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectDispatchAbortReasonsFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const scrubDispatchAbortReason = ({ key, text }) => ({ key, text }); // drop all other properties

const fetchDispatchAbortReasons = makeRestApiAction({
  service: 'dispatchAbortReasons',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchDispatchAbortReasons,
  transformOutput: (responseData) => responseData.map(scrubDispatchAbortReason),
  notificationsItemDescriptor: 'dispatch abort reasons',
});

export const fetchDispatchAbortReasonsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectDispatchAbortReasonsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchDispatchAbortReasons,
  baseActionType: ACTION_TYPE.fetchDispatchAbortReasons,
});

export const deleteDispatchAbortReason = makeRestApiAction({
  service: 'dispatchAbortReasons',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteDispatchAbortReason,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'dispatch abort reason',
});

export const createDispatchAbortReason = makeRestApiAction({
  service: 'dispatchAbortReasons',
  method: 'post',
  baseActionType: ACTION_TYPE.createDispatchAbortReason,
  transformOutput: scrubDispatchAbortReason,
  notificationsItemDescriptor: 'dispatch abort reason',
  throwApiErrors: true,
});

export const updateDispatchAbortReason = makeRestApiAction({
  service: 'dispatchAbortReasons',
  method: 'put',
  baseActionType: ACTION_TYPE.updateDispatchAbortReason,
  getId: ({ key }) => key,
  transformInput: scrubDispatchAbortReason,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'dispatch abort reason',
  throwApiErrors: true,
});
