import React from 'react';

import { ReportFilter } from '../../commons/ReportsCommon';
import { sortRows, mutateRows } from '../../../helpers/reports/standardFilters';

const EMPTY_ARRAY = [];

export const Filters = () => (
  <>
    <ReportFilter
      filterConfig={{
        group: 'vehicle',
        debugName: 'breadcrumbsByVehicleKey',
        type: ({ rows: breadcrumbsByVehicleKey, vehicleKey }) =>
          breadcrumbsByVehicleKey[vehicleKey] || EMPTY_ARRAY,
        getValues: ({ controls: { vehicleKey } }) => ({ vehicleKey }),
      }}
    />
    <ReportFilter
      filterConfig={{
        group: 'mutations',
        type: mutateRows,
        debugName: 'addIdAndSource',
        callback: ({ draft }) => {
          draft.source = draft.eventType ? 'Breadcrumb' : 'PerformanceRuleToggled';
          draft.id = `${draft.source}/${draft.eventAt}/${draft.sequence}`;
        },
      }}
    />
    <ReportFilter
      filterConfig={{
        group: 'mutations',
        type: mutateRows,
        debugName: 'parseOther',
        callback: ({ draft }) => {
          if (draft.other) draft.other = JSON.stringify(draft.other);
        },
      }}
    />
    <ReportFilter
      filterConfig={{
        group: 'mutations',
        type: sortRows,
        field: 'eventAt',
      }}
    />
  </>
);
