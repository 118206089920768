import { filter, find } from 'lodash';
import { v4 as uuid } from 'uuid';

import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import { selectRulesFetchRequests, selectRuleSetsFetchRequests } from './selectors';

import { ACTION_TYPE } from './reducer';

const fetchRules = makeRestApiAction({
  service: 'rules',
  method: 'get',
  getId: (key) => key,
  baseActionType: ACTION_TYPE.fetchRules,
  notificationsItemDescriptor: 'rules',
});

export const fetchRulesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRulesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchRules,
});

const fetchRule = makeRestApiAction({
  service: 'rules',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRule,
  getId: ({ ruleSetId }) => ruleSetId,
  transformOutput: (responseData, { ruleId }) => find(responseData, { key: ruleId }),
  notificationsItemDescriptor: 'rule',
});

export const fetchRuleOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRulesFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { actionParam: id }),
  fetchAction: fetchRule,
});

export const createRule = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.createRule,
  transformInput: (payload) => ({ ...payload, _type: 'driverperformance.AddPerformanceRule' }),
  transformOutput: (responseData, actionParam) => ({ ...actionParam, key: uuid() }),
  notificationsItemDescriptor: 'rule',
  throwApiErrors: true,
});

export const createRules = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.createRules,
  transformInput: (payload) => ({
    ...payload,
    _type: 'driverperformance.AddPerformanceRules',
  }),
  transformOutput: (responseData, { rules }) => rules.map((row) => ({ ...row, key: uuid() })),
  notificationsItemDescriptor: 'rules',
  throwApiErrors: true,
});

export const updateRule = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.updateRule,
  transformInput: (payload) => ({
    ...payload,
    _type: 'driverperformance.ModifyPerformanceRule',
  }),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'rule',
  throwApiErrors: true,
});

export const deleteRule = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.deleteRule,
  transformInput: (payload) => ({
    ...payload,
    _type: 'driverperformance.RemovePerformanceRule',
  }),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'rule',
});

export const updateRuleConfiguration = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.updateRuleConfiguration,
  transformInput: (payload) => ({ ...payload, _type: 'admin.ChangeShowVCPerformancePoints' }),
  notificationsItemDescriptor: 'rule configuration',
  throwApiErrors: true,
});

const fetchRuleSets = makeRestApiAction({
  service: 'ruleSets',
  method: 'get',
  transformInput: () => null, // this is made to avoid actionParams to send garbage to GET request MBL-8878
  baseActionType: ACTION_TYPE.fetchRuleSets,
  notificationsItemDescriptor: 'rule sets',
});

export const fetchRuleSetsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRuleSetsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchRuleSets,
});

const fetchRuleSet = makeRestApiAction({
  service: 'ruleSets',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRuleSet,
  getId: (key) => key,
  notificationsItemDescriptor: 'rule sets',
});

export const fetchRuleSetOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRuleSetsFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { actionParam: id }),
  fetchAction: fetchRuleSet,
});

export const createRuleSet = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.createRuleSet,
  transformInput: (payload) => ({
    ...payload,
    _type: 'driverperformance.CopyPerformanceRules',
  }),
  transformOutput: (responseData, { typeTo, name }) => ({ key: typeTo, name }),
  notificationsItemDescriptor: 'rule set',
  throwApiErrors: true,
});

export const deleteRuleSet = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.deleteRuleSet,
  transformInput: (payload) => ({
    ...payload,
    _type: 'driverperformance.RemovePerformanceRuleSet',
  }),
  transformOutput: (responseData, actionParam) => ({
    ...actionParam,
    key: actionParam.vehicleType,
  }),
  notificationsItemDescriptor: 'rule set',
});

export const fetchRulesPredefined = makeRestApiAction({
  service: 'rulesPredefined',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRulesPredefined,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'rule',
});
