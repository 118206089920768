import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, Button, Card, Form, TextInput, useBooleanState } from 'stti-react-common';

import { InfoField, HidePrint } from '../../../../commons/ReportsCommon';
import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { violationsColumnDefs as columnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

const { useDataGridController } = ControlledDataGrid;
const { useFormController, Control } = Form;

export const ViolationsCard = ({ log, user, reviewViolation, hidePrint }) => {
  const { violations, reviewDetails, canEditLogViolations, key } = log || {};
  const [isReviewViolationsDialogOpen, openReviewViolationsDialog, closeReviewViolationsDialog] =
    useBooleanState();

  const form = useFormController();

  const { resetControls, controls, hasErrors } = form;

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__ViolationsCard" fullWidth>
        <div className="DriverLogDetailReport__ViolationsCard__eventsHeader">
          <h4>Violations</h4>
          {canEditLogViolations && reviewDetails && !reviewDetails.isReviewed && (
            <Button
              icon="remove_red_eye"
              label="Mark Reviewed"
              variant="contained"
              onClick={openReviewViolationsDialog}
            />
          )}
        </div>
        {reviewDetails && reviewDetails.isReviewed && (
          <div className="ViolationsPanel__reviewDetails">
            <InfoField name="reviewerName" label="Reviewer" value={reviewDetails.reviewerName} />
            <InfoField name="comments" label="Comment" value={reviewDetails.comments} />
          </div>
        )}
        <BasicDialog
          title="Review Violations"
          className="DriverLogDetailReport__ViolationsCard__ReviewViolationsDialog"
          maxWidth="xs"
          fullWidth
          isOpen={isReviewViolationsDialogOpen}
          onOpen={resetControls}
          onClose={closeReviewViolationsDialog}
          buttons={[
            { label: 'Cancel', onClick: closeReviewViolationsDialog },
            {
              label: 'Save',
              onClick: () => {
                reviewViolation({
                  ...log.reviewDetails,
                  logKey: key,
                  reviewer: user.key,
                  ...controls,
                });
                closeReviewViolationsDialog();
              },
              disabled: hasErrors,
            },
          ]}
        >
          <Form form={form}>
            <InfoField name="reviewer" label="Reviewer" value={user && user.fullName} />
            <Control name="comments" Component={TextInput} label="Comment" multiline autoFocus />
          </Form>
        </BasicDialog>
        <ControlledDataTable
          controller={dataGridController}
          rows={violations || []}
          rowIdProperty="description"
        />
      </Card>
    </HidePrint>
  );
};

ViolationsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  user: PropTypes.object,
  reviewViolation: PropTypes.func.isRequired,
  hidePrint: PropTypes.bool,
};
