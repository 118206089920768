import React from 'react';
import PropTypes from 'prop-types';

import './Feature.scss';

export const Body = ({ children, className = '' }) => (
  <div className={`Feature__Body ${className}`}>{children}</div>
);

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
