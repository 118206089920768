import React from 'react';
import moment from 'moment-timezone';
import { DateInput } from 'stti-react-common';
import PropTypes from 'prop-types';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { ReportControl } from '../ReportControl';
import { useFormats } from '../../Formats';

const getMinAndErrorMessage = (minProp, formatUnit) => {
  if (minProp === 'last12Months')
    return {
      min: moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'),
      belowMinErrorMessage: 'Start Date must be within last complete 12 months.',
    };
  return {
    min: minProp,
    belowMinErrorMessage: `Start Date must be on or after ${formatUnit(
      'timelessDate',
      moment(minProp)
    )}`, // TODO: note that due to a bug in the branch (alpha) being merged to, this format does not follow report settings
  };
};

export const StartDateInput = ({ min: minProp, group, disableStartDate, ...restProps }) => {
  const {
    formats: { timelessDate },
    formatUnit,
  } = useFormats();

  const { min, belowMinErrorMessage } = getMinAndErrorMessage(minProp, formatUnit);
  return (
    <ReportControl
      name="startDate"
      group={group}
      label="Start Date"
      render={(renderProps) => <DateInput min={min} {...renderProps} disabled={disableStartDate} />}
      reducerCallback={(draft, value) => {
        if (value) draft.controls.dateRangeMode = 'custom';
      }}
      error={({ value }) => {
        if (!value) return 'Valid Start Date must be specified.';
        if (value < min) return belowMinErrorMessage;
        return false;
      }}
      format={timelessDate}
      autoGridArea
      reRegisterOn={[minProp, formatUnit]}
      {...restProps}
    />
  );
};

StartDateInput.propTypes = {
  min: PropTypes.oneOfType([customPropTypes.date, PropTypes.oneOf(['last12Months'])]).isRequired,
  group: PropTypes.string.isRequired,
  disableStartDate: PropTypes.bool,
};
