import React from 'react';
import PropTypes from 'prop-types';

import { dateObject } from '../../../helpers/moment';

export const CycleSummary = ({ selectedData, formatUnit }) => (
  <div className="tile">
    <h4>Cycle Summary </h4>
    <div className="tile__content">
      <div className="tile__content__item cycle">
        <strong>{formatUnit('duration', selectedData?.cycleOnDutyRemaining, 'h:mm')}</strong>
        <span>Cycle Remaining</span>
      </div>
      <div className="tile__content__item cycle">
        <strong>{formatUnit('duration', selectedData?.cycleOnDutyTime, 'h:mm')}</strong>
        <span>Cycle Total</span>
      </div>
      <div className="tile__content__item">
        <p>{selectedData?.cycleName}</p>
      </div>
    </div>
    <div className="tile__footer">
      <a
        href={`#/reports/driverLogSummary/default/driverLogs/${
          selectedData?.driver?.key
        }-${dateObject().format('YYYYMMDD')}/default`}
        target="_blank"
        rel="noreferrer"
      >
        View Details
      </a>
    </div>
  </div>
);

CycleSummary.propTypes = {
  selectedData: PropTypes.any,
  formatUnit: PropTypes.func,
};
