export const administrationGroups = {
  resources: {
    name: 'Resources',
    order: 1,
    icon: 'list_alt',
  },
  devices: {
    name: 'Devices',
    order: 2,
    icon: 'devices',
  },
  configurations: {
    name: 'Configuration',
    order: 3,
    icon: 'settings',
  },
};
