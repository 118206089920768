import { isEmpty } from 'lodash';
import { RateLimit } from 'async-sema';

import {
  getDateSequences,
  toMoment,
  createMomentRange,
  daysBetweenDates,
  getRangeStepByMode,
  setEndDateAfterStartDate,
} from '../../../../helpers/moment';

const limit = RateLimit(1);

const fetchDataOnDemand = async ({
  registerFetch,
  fetchAction,
  startDate,
  endDate,
  ouKey,
  ouName,
  timeZone,
  ous,
  dateRangeFor,
  args = {},
  noDateRange,
}) => {
  await limit();

  // this will set invalid endDates for the same as startDate
  if (!noDateRange) {
    if (endDate === 'Invalid date') {
      // eslint-disable-next-line no-param-reassign
      endDate = startDate;
    }
  }

  registerFetch(
    fetchAction({
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(ouKey && { ouKey }),
      ...(ouName && { ouName }),
      ...(ous && { ous }),
      ...(timeZone && { timeZone }),
      ...(!isEmpty(args) && { ...args }),
      ...(dateRangeFor && { dateRangeFor }),
    })
  );
};

export const fetchReportDataOnDemand = ({
  report,
  fetchAction,
  isOptimized,
  isMonthly,
  isQuarterlyOrMonthly,
  limits,
  ous,
  args,
  noDateRange,
}) => {
  const { controls, registerFetch, validateControl } = report;
  const { startDate, endDate, ouMode, ouKeys, dateRangeFor } = controls;

  const endDateFetch =
    toMoment() < toMoment(endDate)
      ? toMoment().format('YYYY-MM-DD')
      : toMoment(endDate).format('YYYY-MM-DD');

  // this means that users are trying to query invalid dates, so we will not fetch
  if (!noDateRange && toMoment(startDate) > toMoment(endDateFetch)) {
    return null;
  }

  if (!validateControl('startDate', 'endDate')) return registerFetch();

  if (isQuarterlyOrMonthly) {
    return fetchDataOnDemand({
      registerFetch,
      fetchAction,
      startDate,
      endDate,
      args,
    });
  }

  if (isOptimized) {
    // We will break dates into intervals, if ouMode equals 'all' so we will have a lesser limit
    // If we are querying custom ous or just one, the limit will be greater
    // Limits will be retrieved from an endpoint and send to this component as props
    // With these limits we will then break the range between startDate and endDate, in pairs

    const momentStart = toMoment(startDate);
    const momentEnd = toMoment(endDateFetch);

    const differenceByDays = daysBetweenDates(startDate, endDateFetch);

    const step = getRangeStepByMode(ouMode, limits, differenceByDays);

    const range = createMomentRange(momentStart, momentEnd);
    const startDates = Array.from(range.by('days', { step }));

    const fetches = noDateRange
      ? [{ start: undefined, end: undefined }]
      : startDates.map((row) => ({
          start: row.toISOString(),
          end: setEndDateAfterStartDate(row.add(step, 'days'), endDateFetch),
        }));

    if (ouMode === 'all') {
      return fetches.map(({ start, end }) =>
        fetchDataOnDemand({
          registerFetch,
          fetchAction,
          startDate: start,
          endDate: end,
          args,
          dateRangeFor,
          ous,
        })
      );
    }

    return ouKeys.map((ouKey) =>
      fetches.map(({ start, end }) =>
        fetchDataOnDemand({
          registerFetch,
          fetchAction,
          startDate: start,
          endDate: end,
          ouKey,
          dateRangeFor,
          ouName: ous.find((ou) => ou.key === ouKey)?.name,
          timeZone: ous.find((ou) => ou.key === ouKey)?.timeZone,
          args,
        })
      )
    );
  }

  if (isMonthly) {
    // We will break startDate and endDate in year-month sequences in order to call specific endpoints that accept only one
    // date parameter instead of start and end.
    return ouKeys.map((ouKey) =>
      getDateSequences(startDate, endDateFetch).map((currentDate) =>
        fetchDataOnDemand({
          registerFetch,
          fetchAction,
          startDate: currentDate,
          endDate: currentDate,
          ouKey,
          dateRangeFor,
          timeZone: ous.find((ou) => ou.key === ouKey).timeZone,
          args,
        })
      )
    );
  }

  // Finally, if the component is not optimized or monthly, it means that we need to iterate all the ous
  // and make the api calls using each OU by start an end dates intervals, this is tends to be a heavy operation.
  return ouKeys.map((ouKey) =>
    fetchDataOnDemand({
      registerFetch,
      fetchAction,
      startDate,
      endDate: endDateFetch,
      ouKey,
      dateRangeFor,
      timeZone: ous.find((ou) => ou.key === ouKey).timeZone,
      args,
    })
  );
};
