import React from 'react';

import { VehicleSummaryContainer as VehicleSummary } from './Summary/connect';
import { VehicleDetailContainer as VehicleDetail } from './Detail/connect';
import { VehicleConfigurationContainer as VehicleConfiguration } from './Configuration/connect';
import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, ADMIN_ROLE_KEY } from '../../../../config';

// EXTERNAL ROUTES
import { organizationDetail } from '../../configurations/Organizations/routes';
import { vehicleTypesSummary, vehicleTypeDetail } from '../VehicleTypes/routes';
import { cameraDetail } from '../../devices/Cameras/routes';

export const vehicleSummary = configureRoute({
  name: 'Vehicles',
  group: administrationGroups.resources,
  order: 5,
  path: '/vehicles',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <VehicleSummary
      {...props}
      openVehicleCreate={vehicleCreate.open}
      openVehicleDetail={vehicleDetail.open}
      openActiveOuVehicleConfig={vehicleConfig.open}
      openOrganizationDetail={organizationDetail.open}
      openVehicleTypeDetail={vehicleTypeDetail.open}
      openCameraDetail={cameraDetail.open}
      openVehicleTypes={vehicleTypesSummary.open}
    />
  ),
});

export const vehicleCreate = configureRoute({
  path: '/vehicle/new',
  switchKey: 'vehicle',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleDetail {...props} mode="create" />,
});

export const vehicleConfig = configureRoute({
  path: '/vehicle/config',
  switchKey: 'vehicle',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleConfiguration {...props} />,
});

export const vehicleDetail = configureRoute({
  path: '/vehicle/:assetId',
  switchKey: 'vehicle',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleDetail {...props} openCameraDetail={cameraDetail.open} />,
});
