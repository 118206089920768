import moment from 'moment-timezone';
import { filter, isEmpty } from 'lodash';

import {
  makeRestApiAction,
  makeOneTimeOptimizedAction,
  makeDateRangeOptimizedAction,
} from '../apiActionSupport';
import { ACTION_TYPE } from './unidentifiedLogsReducer';
import {
  selectUnidentifiedLogsFetchRequests,
  selectUnidentifiedLogFetchRequests,
} from './unidentifiedLogsSelectors';

const dateProperties = (logDate, timeZone) => {
  // TODO: use common util for driverLogs and unidentifiedLogs
  const startOfDayMoment = moment.tz(logDate, timeZone).startOf('day');
  const endOfDayMoment = moment.tz(logDate, timeZone).endOf('day');
  const isPartial = moment().isBefore(endOfDayMoment);

  return {
    logDate,
    timeZone,
    startedAt: startOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    endedAt: isPartial ? undefined : endOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
  };
};

const driverObject = ({ driver }) => {
  if (!driver) return { name: '', firstName: '', lastName: '' };

  if (!driver.name || isEmpty(driver.name)) {
    return { name: '', firstName: '', lastName: '', ...driver };
  }

  return {
    firstName: driver.name.split(' ')[0],
    lastName: driver.name.split(' ')[1],
    ...driver,
  };
};

const transformUnidentifiedLog = (unidentifiedLog) => {
  const {
    logDate,
    timeZone,
    ous,
    onDutyDrivingTotal,
    onDutyNotDrivingTotal,
    dutyStatusChanges = [],
  } = unidentifiedLog;

  const onDutyTotal = (onDutyDrivingTotal || 0) + (onDutyNotDrivingTotal || 0) || null;

  return {
    onDutyTotal,
    ...unidentifiedLog,
    ...dateProperties(logDate, timeZone),
    dutyStatusChanges: dutyStatusChanges.map((dutyStatusChange) => ({
      timeZone,
      ...dutyStatusChange,
    })),
    ouKey: ous.map(({ key }) => key), // for ouKeys filter (also is reason for singular "ouKey")
    hasActivity: !!onDutyTotal,
    driver: driverObject(unidentifiedLog),
  };
};

// fetchUnidentifiedLogs({ startDate, endDate, ouKey })
const fetchUnidentifiedLogs = makeRestApiAction({
  service: 'unidentifiedLogs',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    start: moment(startDate).format('YYYY-MM-DD'),
    end: moment(endDate).format('YYYY-MM-DD'),
    ouKey,
  }),
  transformOutput: (response) => response.map(transformUnidentifiedLog),
  baseActionType: ACTION_TYPE.fetchUnidentifiedLogs,
  notificationsItemDescriptor: 'unidentified driving logs',
});

// fetchUnidentifiedLogsOptimized({ startDate, endDate, ouKey })
export const fetchUnidentifiedLogsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectUnidentifiedLogsFetchRequests,
  filterFetches: (fetches, { ouKey }) =>
    filter(fetches, { ouKey }).map(({ start: startDate, end: endDate, ...rest }) => ({
      startDate,
      endDate,
      ...rest,
    })),
  baseActionType: ACTION_TYPE.fetchUnidentifiedLogs,
  fetchAction: fetchUnidentifiedLogs,
});

const fetchUnidentifiedLog = makeRestApiAction({
  service: 'unidentifiedLogDetail',
  method: 'get',
  getId: ({ vehicleKey, date }) => `${vehicleKey}/${date}`,
  transformInput: () => {},
  transformOutput: transformUnidentifiedLog,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
  notificationsItemDescriptor: 'unidentified driving log',
});

const fetchUnidentifiedLogForCanada = makeRestApiAction({
  service: 'unidentifiedLogDetail',
  method: 'get',
  getId: ({ vehicleKey, date }) => `${vehicleKey}/${date}?us=false`,
  transformInput: () => {},
  transformOutput: transformUnidentifiedLog,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
  notificationsItemDescriptor: 'unidentified driving log',
});

export const fetchUnidentifiedLogOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectUnidentifiedLogFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchUnidentifiedLog,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
});

export const fetchUnidentifiedLogOptimizedForCanada = makeOneTimeOptimizedAction({
  selectFetches: selectUnidentifiedLogFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchUnidentifiedLogForCanada,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
});

const submitAssignLogDutyStatusEvent = makeRestApiAction({
  service: 'driverLogDutyStatus',
  method: 'put',
  baseActionType: ACTION_TYPE.submitAssignLogDutyStatusEvent,
  getId: ({ key }) => `${key}/reassign`,
  transformInput: ({ toDriverKey, movementType, annotation }) => ({
    toDriverKey,
    movementType,
    annotation,
  }),
  transformOutput: (responseData, requestData) => ({
    status: 'INACTIVE_CHANGE_REQUESTED',
    ...requestData,
  }),
  notificationsItemDescriptor: 'log event driver',
});

export const submitAssignLogDutyStatusEvents =
  ({ dutyIntervalKeys, logKey, toDriverKey, movementType, annotation }) =>
  async (dispatch) => {
    dutyIntervalKeys.forEach((key) => {
      submitAssignLogDutyStatusEvent({
        key,
        logKey,
        toDriverKey,
        movementType,
        annotation,
      })(dispatch);
    });
  };
