import { connect } from 'react-redux';

import { PerformanceDetailReport } from './PerformanceDetailReport';
import { selectPerformanceDetails, fetchPerformanceDetails } from '../../../../data/reports';
import { fetchCurrentOrganizationUnits } from '../../../../data/system';

export const PerformanceDetailReportContainer = connect(
  (state) => ({
    performanceDetails: selectPerformanceDetails(state),
  }),
  {
    fetchPerformanceDetails,
    fetchOrganizations: fetchCurrentOrganizationUnits,
  }
)(PerformanceDetailReport);
