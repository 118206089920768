import { connect } from 'react-redux';

import { OrganizationDetail } from './detail';

import { user, role } from '../../../../../data/administration/resources';
import { organization } from '../../../../../data/administration/configurations';

export const OrganizationDetailContainer = connect(
  (state, { organizationId }) => ({
    organizations: organization.selectors.selectOrganizations(state),
    organization: organization.selectors.selectOrganization(state, organizationId),
    users: user.selectors.selectUsers(state),
    roles: role.selectors.selectRoles(state),
  }),
  {
    createOrganization: organization.actions.createOrganization,
    updateOrganization: organization.actions.updateOrganization,
    deleteOrganization: organization.actions.deleteOrganization,
    fetchRoles: role.actions.fetchRolesOptimized,
    fetchOrganization: organization.actions.fetchOrganization,
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    fetchUsers: user.actions.fetchUsersOptimized,
  }
)(OrganizationDetail);
