import React from 'react';
import PropTypes from 'prop-types';
import ElementQueries from 'css-element-queries/src/ElementQueries';

import { FontsLoader } from 'stti-react-common';

// enable Element Queries
ElementQueries.listen();

export const AppStyle = ({ fonts }) => <FontsLoader fonts={fonts} />;

AppStyle.propTypes = {
  fonts: PropTypes.arrayOf(PropTypes.string),
};
