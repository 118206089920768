import React from 'react';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { FEATURES } from '../../../../features';
import { ProfileContainer } from '../../../profile/connect';
import { roleSummary } from '../Roles/routes';
import { UserDetailContainer } from './Detail/connect';
import { UserSummaryContainer } from './Summary/connect';

import {
  administrationGroups,
  configureRoute,
  ADMIN_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  DRIVER_ROLE_KEY,
  MANAGER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
} from '../../../../config';

export const userSummary = configureRoute({
  name: 'Users',
  group: administrationGroups.resources,
  order: 4,
  path: '/users',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <UserSummaryContainer
      {...props}
      openUserDetail={userDetail.open}
      openUserCreateDriver={userCreateDriver.open}
      openUserCreateSupport={userCreateSupport.open}
      openRolesSummary={roleSummary.open}
    />
  ),
});

export const userCreateDriver = configureRoute({
  path: '/user/newDriver',
  switchKey: 'user',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <UserDetailContainer {...props} mode="create" isDriverCreate />,
});

export const userCreateSupport = configureRoute({
  path: '/user/newSupport',
  switchKey: 'user',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <UserDetailContainer {...props} mode="create" />,
});

export const userDetail = configureRoute({
  path: '/user/:userId',
  switchKey: 'user',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <UserDetailContainer {...props} />,
});

export const userProfile = configureRoute({
  path: '/profile',
  name: 'Profile',
  group: administrationGroups.resources,
  RouteFrame: BasicRouteFrame,
  permissions: [
    ADMIN_ROLE_KEY,
    DISPATCHER_ROLE_KEY,
    DRIVER_ROLE_KEY,
    MANAGER_ROLE_KEY,
    MECHANIC_ROLE_KEY,
  ],
  render: (props) => <ProfileContainer {...props} />,
  hidden: FEATURES.admin.hideProfileFromMainMenu,
});
