import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import { HidePrint } from '../../../../commons/ReportsCommon';
import { ControlledDataGrid, ControlledDataTable } from '../../../../commons/ControlledDataGrid';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { eldEventsColumnDefs } from './columnDefs';

const { useDataGridController } = ControlledDataGrid;

export const EldCard = ({ log, hidePrint }) => {
  const eldEventsTableController = useDataGridController({
    columnDefs: eldEventsColumnDefs,
  });

  const { eldEvents } = log || {};

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__EldCard" fullWidth>
        <div>
          <h4>ELD Events</h4>
        </div>
        {eldEvents && (
          <ControlledDataTable
            controller={eldEventsTableController}
            rows={eldEvents}
            rowIdProperty="key"
          />
        )}
      </Card>
    </HidePrint>
  );
};

EldCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
