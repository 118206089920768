import React from 'react';
import PropTypes from 'prop-types';

import './ScreenLayout.scss';

export const ScreenLayout = ({ customClass, children }) => (
  <div className={`ScreenLayout ${customClass}`}>{children}</div>
);

ScreenLayout.propTypes = {
  children: PropTypes.node,
  customClass: PropTypes.string,
};
