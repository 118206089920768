import { merge, unset, isEqual, debounce, cloneDeep, castArray, isUndefined } from 'lodash';

const getGaFields = (values) => {
  const fieldsMap = {
    // custom GA dimensions
    dateRangeMode: 'dimension1',
    dateRangeDays: 'dimension2',

    // events
    category: 'eventCategory',
    label: 'eventLabel',
    action: 'eventAction',
    value: 'eventValue',
  };

  return Object.entries(values).reduce((acc, [key, val]) => {
    if (isUndefined(val)) return acc;
    const gaKey = fieldsMap[key];
    if (!gaKey) return acc;
    return {
      ...acc,
      [gaKey]: val,
    };
  }, {});
};

class Analytics {
  state = {
    pathname: '',
    values: {},
  };

  lastState = {};

  gaQueue = [];

  ga = (...params) => {
    this.gaQueue.push(params);
    this.processGaQueue();
  };

  processGaQueue = () => {
    if (window.disableReactGa) return; // TODO: sloppy hack
    if (!window.ga) {
      // GA not ready, try again every second
      window.setTimeout(this.processGaQueue, 1000);
      return;
    }
    while (this.gaQueue.length > 0) {
      window.ga(...this.gaQueue.shift());
    }
  };

  locationChanged = debounce(() => {
    if (!isEqual(this.state, this.lastState)) {
      this.lastState = cloneDeep(this.state);

      const fieldsObject = getGaFields(this.state.values);

      this.ga('set', 'page', this.state.pathname); // set for future events
      this.ga('send', 'pageview', this.state.pathname, fieldsObject);
    }
  }, 200);

  setValues = (valuesObject) => {
    merge(this.state.values, valuesObject);

    this.locationChanged();
  };

  unsetValues = (keyOrKeys) => {
    const keys = castArray(keyOrKeys);

    keys.forEach((key) => {
      unset(this.state.values, key);
    });

    this.locationChanged();
  };

  setLocation = (pathname) => {
    let newPathname = pathname;

    // Angular OU-in-query-string removal
    newPathname = newPathname.replace(/\?ou=\d+&/, '?').replace(/[?&]ou=\d+/, '');

    // ID replacements
    newPathname = newPathname
      .replace(
        // UUID (remote ID)
        /\b[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/,
        'UUID'
      )
      .replace(
        // LOCALID (localForage ID)
        /\b[0-9a-f]{40}\b/,
        'LOCALID'
      )
      .replace(
        // BASE64 (multiple param encoding; modified base64 with dashes instead of slashes, prefixed with '='
        /\/=[0-9a-zA-Z+=-]{4,}/,
        '/BASE64'
      );

    this.state.pathname = newPathname;

    this.locationChanged();
  };

  sendEvent = (fieldsObject) => {
    this.ga('send', 'event', getGaFields({ ...this.state.values, ...fieldsObject }));
  };
}

export const analytics = new Analytics();
