import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchTaxMileages: 'reports/fuelTaxMileages/fetchTaxMileages',
};

const initialState = {
  fuelTaxMileages: [],
  fetches: [],
};

export const fuelTaxMileagesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchTaxMileages])) {
      draft.fuelTaxMileages = sortBy(
        shallowMergeEntities(state.fuelTaxMileages, payload, 'key'),
        'startedAt'
      );
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchTaxMileages])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, actionParam });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchTaxMileages])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
