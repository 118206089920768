import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, NumberInput, Form } from 'stti-react-common';

import { valueIsNull, distanceUnitOptions } from './common';
import { unconvertUnit } from '../../../../../helpers/formats';

const { Control, HiddenControl } = Form;

export const Distance = ({ preText, postText, defaultValues, error = valueIsNull }) => (
  <div className="Report__EditExceptionRuleDialog__RuleComponent">
    <HiddenControl name="distance" defaultValue={defaultValues.distance} />
    {preText}&nbsp;
    <Control
      Component={NumberInput}
      name="distanceValue"
      defaultValue={defaultValues.distanceValue}
      onSet={({ draft }) => {
        draft.distance = unconvertUnit('distance', draft.distanceValue, draft.distanceUnit);
      }}
      error={error}
    />
    &nbsp;
    <Control
      Component={SelectInput}
      name="distanceUnit"
      defaultValue={defaultValues.distanceUnit}
      onSet={({ draft }) => {
        draft.distance = unconvertUnit('distance', draft.distanceValue, draft.distanceUnit);
      }}
      options={distanceUnitOptions}
    />
    &nbsp;{postText}
  </div>
);

Distance.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  defaultValues: PropTypes.shape({
    distance: PropTypes.any,
    distanceValue: PropTypes.number,
    distanceUnit: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
