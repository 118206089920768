import { combineReducers } from 'redux';

import { configurations } from './configurations';
import { devices } from './devices';
import { resources } from './resources';

export const administration = combineReducers({
  configurations,
  devices,
  resources,
});
