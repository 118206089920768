import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Clickable } from 'stti-react-common';

import './MapCell.scss';

const { SymbolImage } = GoogleMap;

export const MapCell = ({ onClick, line, topLine, bottomLine, icon, disabled }) => {
  const iconHeight = icon && Math.min(icon.height, 15);
  const iconVariant = icon && icon.variant === 'dot' ? 'dot' : 'circular';

  return (
    <Clickable
      className={`Report__MapCell ${disabled ? 'Report__MapCell--disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="Report__MapCell__lines">
        {line || (topLine && topLine === bottomLine) ? (
          <SymbolImage symbol={line || topLine} height="28px" />
        ) : (
          <>
            {topLine ? (
              <SymbolImage symbol={topLine} height="14px" />
            ) : (
              <div style={{ height: '14px' }} /> // spacer
            )}
            {bottomLine && <SymbolImage symbol={bottomLine} height="14px" />}
          </>
        )}
      </div>
      {icon && (
        <SymbolImage
          className="Report__MapCell__icon"
          symbol={icon}
          height={`${iconHeight}px`}
          variant={iconVariant}
        />
      )}
    </Clickable>
  );
};

MapCell.propTypes = {
  onClick: PropTypes.func,
  line: PropTypes.object,
  topLine: PropTypes.object,
  bottomLine: PropTypes.object,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
};
