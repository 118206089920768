import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { Card } from 'stti-react-common';

import './AggregatesCard.scss';

export const AggregatesCard = ({ className = '', name, title, fields = [] }) => (
  <Card className={`Report__AggregatesCard Report__AggregatesCard--${name} ${className}`}>
    <div className="Report__AggregatesCard__title">{title}</div>
    <div className="Report__AggregatesCard__table">
      <table>
        <tbody>
          {fields.map((field) => (
            <tr key={field.header}>
              <th>{field.header}</th>
              <td>{isNil(field.value) ? '—' : field.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Card>
);

AggregatesCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
