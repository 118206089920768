import { connect } from 'react-redux';

import { UnidentifiedLogsSummaryReport } from './UnidentifiedLogsSummaryReport';
import { selectUnidentifiedLogs, fetchUnidentifiedLogsOptimized } from '../../../../data/reports';

export const UnidentifiedLogsSummaryReportContainer = connect(
  (state) => ({
    logs: selectUnidentifiedLogs(state),
    limits: { forAllOus: 15, forOu: 60 },
  }),
  { fetchUnidentifiedLogs: fetchUnidentifiedLogsOptimized }
)(UnidentifiedLogsSummaryReport);
