import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectDelaysFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const scrubDelay = ({ key, text }) => ({ key, text }); // drop all other properties

const fetchDelays = makeRestApiAction({
  service: 'offDutyReasons',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchDelays,
  transformOutput: (responseData) => responseData.map(scrubDelay),
  notificationsItemDescriptor: 'delays',
});

export const fetchDelaysOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectDelaysFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchDelays,
  baseActionType: ACTION_TYPE.fetchDelays,
});

export const deleteDelay = makeRestApiAction({
  service: 'offDutyReasons',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteDelay,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'delay',
});

export const createDelay = makeRestApiAction({
  service: 'offDutyReasons',
  method: 'post',
  baseActionType: ACTION_TYPE.createDelay,
  transformOutput: scrubDelay,
  notificationsItemDescriptor: 'delay',
  throwApiErrors: true,
});

export const updateDelay = makeRestApiAction({
  service: 'offDutyReasons',
  method: 'put',
  baseActionType: ACTION_TYPE.updateDelay,
  getId: ({ key }) => key,
  transformInput: scrubDelay,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'delay',
  throwApiErrors: true,
});
