import { columnDef } from '../../../commons/ControlledDataGrid';
import { getExceptionSpecificsText } from '../../../../helpers/reports/exceptionSpecifics';

export const columnDefs = [
  {
    headerName: 'Open',
    children: [
      columnDef.iconColumn({
        field: 'openExceptionDetailReport',
        headerName: 'Open Detail',
        cellRenderer: 'OpenDetail',
        essential: true,
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
    ],
  },
  {
    headerName: 'Rule',
    children: [
      columnDef.textColumn({
        field: 'exceptionRule.title',
        headerName: 'Type',
        sortable: true,
      }),
      columnDef.textColumn({
        field: 'exceptionRule.description',
        headerName: 'Description',
        cellRenderer: 'ExceptionDescription',
        sortable: true,
      }),
    ],
  },
  {
    headerName: 'Details',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Time',
        sortable: true,
        sort: 'desc',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Time',
        hide: true,
        sortable: true,
      }),
      columnDef.textColumn({
        field: 'startLocation.name',
        headerName: 'Start Location',
        sortable: true,
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        sortable: true,
      }),
      columnDef.textColumn({
        field: 'driversNames',
        headerName: 'Driver(s)',
        valueGetter: ({ data }) => data.driversNames.join(', '),
        cellRenderer: 'Drivers',
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
        sortable: true,
      }),
      columnDef.distanceColumn({
        field: 'distance',
        headerName: 'Distance',
        sortable: true,
      }),
      columnDef.textColumn({
        field: 'specifics', // no matching property, for column ID only
        headerName: 'Specifics',
        valueGetter: ({ data, context: { formatUnit } }) =>
          getExceptionSpecificsText({ exception: data, formatUnit }),
      }),
    ],
  },
  {
    headerName: 'Review',
    children: [
      columnDef.dateColumn({
        field: 'annotation.updatedAt',
        headerName: 'At',
      }),
      columnDef.textColumn({
        field: 'annotation.user.name',
        headerName: 'By',
      }),
      columnDef.textColumn({
        field: 'annotation.comment',
        headerName: 'Comment',
      }),
    ],
  },
];
