import React from 'react';

import { ApiKeySummaryContainer } from './Summary/connect';
import { ApiKeyDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const apiKeySummary = configureRoute({
  name: 'API Keys',
  group: administrationGroups.configurations,
  order: 2,
  path: '/apiKeys',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => (
    <ApiKeySummaryContainer
      {...props}
      openApiKeyDetail={apiKeyDetail.open}
      openApiKeyCreate={apiKeyCreate.open}
    />
  ),
});

export const apiKeyCreate = configureRoute({
  path: '/apiKey/new',
  switchKey: 'apiKey',
  RouteFrame: BasicRouteFrame,
  render: (props) => <ApiKeyDetailContainer {...props} mode="create" />,
});

export const apiKeyDetail = configureRoute({
  path: '/apiKey/:apiKeyId',
  switchKey: 'apiKey',
  RouteFrame: BasicRouteFrame,
  render: (props) => <ApiKeyDetailContainer {...props} />,
});
