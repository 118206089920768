import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';

import './ManageAdminDialog.scss';

export const DeleteAdminItemDialog = ({
  children,
  dialogTitle,
  dynamicElement,
  isOpen,
  itemTitle,
  onClose,
  onConfirm,
  rowData,
}) => (
  <BasicDialog
    className="DeleteAdminItemDialog"
    isOpen={isOpen}
    onClose={onClose}
    title={`Delete ${dialogTitle}`}
    buttons={[
      {
        label: 'Cancel',
        onClick: onClose,
      },
      {
        label: 'Delete',
        onClick: () => {
          onConfirm(rowData);
          onClose();
        },
      },
    ]}
  >
    {dynamicElement || (
      <p>
        Are you sure you want to delete
        <span className="DeleteAdminItemDialog__display--item"> {`${itemTitle}?`}</span>
      </p>
    )}

    {children}
  </BasicDialog>
);

DeleteAdminItemDialog.propTypes = {
  children: PropTypes.node,
  dialogTitle: PropTypes.string.isRequired,
  dynamicElement: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  itemTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};
