import { connect } from 'react-redux';
import { ShiftDetailReport } from './ShiftDetailReport';
import {
  selectShift,
  selectShiftActivities,
  selectVehiclesBreadcrumbs,
  fetchShiftsOptimized,
  fetchShift,
  fetchShiftActivities,
} from '../../../../data/reports';
import { selectSystemActiveOu } from '../../../../data/system';

export const ShiftDetailReportContainer = connect(
  (state, { shiftId }) => ({
    shift: selectShift(state, { id: shiftId }),
    activities: selectShiftActivities(state, { shiftId }),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
    ou: selectSystemActiveOu(state),
  }),
  {
    fetchShifts: fetchShiftsOptimized,
    fetchShift,
    fetchShiftActivities,
  }
)(ShiftDetailReport);
