import BetterPropTypes from 'better-prop-types';

export const speedLimit = BetterPropTypes.shape({
  appliesTo: BetterPropTypes.string.isOptionalButNotNull,
  gracePeriod: BetterPropTypes.number.isOptionalButNotNull,
  name: BetterPropTypes.string.isOptionalButNotNull,
  operationalLevel: BetterPropTypes.string.isOptionalButNotNull,
  speedLimit: BetterPropTypes.number.isOptionalButNotNull,
});

export const speedLimits = BetterPropTypes.arrayOf(speedLimit);
