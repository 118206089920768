import React from 'react';

import { SystemSettingSummaryContainer } from './Summary/connect';

import { configureRoute } from '../../../../config/configureRoute';
import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { SUPER_ADMIN_ROLE_KEY } from '../../../../data/system/constants';

export const systemSettings = configureRoute({
  path: '/systemSettings',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <SystemSettingSummaryContainer {...props} />,
});
