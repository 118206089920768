import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, ToggleInput, SelectInput } from 'stti-react-common';

import { ReportControl, useReport } from '../../../commons/ReportsCommon';

import './EventsDisplayOptions.scss';

const verticalSplitOptions = [
  { label: 'Map below Events', value: false },
  { label: 'Map beside Events', value: true },
];

export const EventsDisplayOptions = ({ isOpen, onClose }) => {
  const { controls } = useReport();
  return (
    <BasicDialog
      className="UnidentifiedLogDetailReport__EventsDisplayOptions"
      maxWidth="xs"
      fullWidth
      keepMounted // important for registerControl
      isOpen={isOpen}
      onClose={onClose}
    >
      <h4>Map</h4>
      <ReportControl
        name="mapPanel"
        label="Show Map"
        group="map"
        // render={renderProps => <ToggleInput {...renderProps} />}
        render={() => null} // make unavailable for now but still use control value
        defaultValue
      />
      <ReportControl
        name="showGeozones"
        label="Show Geozones"
        group="map"
        render={(renderProps) => <ToggleInput {...renderProps} />}
        disabled={!controls.mapPanel}
        defaultValue
      />
      {/* <ReportControl
        name="showSpeedSamples"
        label="Show Speed Samples"
        group="map"
        render={renderProps => <ToggleInput {...renderProps} />}
        disabled={!controls.mapPanel}
        defaultValue
      /> */}
      <h4>Printing</h4>
      <ReportControl
        name="printMap"
        label="Print Map"
        group="map"
        render={(renderProps) => <ToggleInput {...renderProps} />}
      />
      <h4>Layout</h4>
      <ReportControl
        name="verticalSplit"
        group="map"
        render={(renderProps) => <SelectInput {...renderProps} options={verticalSplitOptions} />}
        defaultValue
      />
    </BasicDialog>
  );
};

EventsDisplayOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
