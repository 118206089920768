import { isNil } from 'lodash';

import { columnDef } from '../../../../commons/ControlledDataGrid';
import { FEATURES } from '../../../../../features';

export const makeColumnDefs = ({
  enableCti,
  enableCumminsEngineDiagnostics,
  enabledVideoProviders,
}) => [
  columnDef.textColumn({
    field: 'assetId',
    headerName: 'Vehicle ID',
    cellRenderer: 'Asset',
    sort: 'asc',
    enableRowGroup: false,
    minWidth: 150,
  }),
  columnDef.textColumn({
    field: 'assetType.name',
    headerName: 'Type',
    cellRenderer: 'AssetType',
    minWidth: 130,
    valueGetter: ({ data }) => {
      if (isNil(data) || isNil(data.assetType) || isNil(data.assetType.name)) return '';
      return data.assetType.name;
    },
  }),
  columnDef.textColumn({
    field: 'make',
    headerName: 'Make',
    minWidth: 120,
  }),
  columnDef.textColumn({
    field: 'model',
    headerName: 'Model',
    minWidth: 120,
  }),
  columnDef.textColumn({
    field: 'fuelType',
    headerName: 'Fuel Type',
    minWidth: 150,
    valueGetter: ({ data }) => {
      if (!data.fuelType) return '';
      return data.fuelType;
    },
  }),
  columnDef.textColumn({
    field: 'engineType',
    headerName: 'Engine Type',
    minWidth: 150,
    valueGetter: ({ data }) => {
      if (!data.engineType) return '';
      return data.engineType;
    },
    hide: true,
  }),
  columnDef.enabledDisabledColumn({
    field: 'enableCumminsEngineDiagnostics',
    headerName: 'Cummins Engine Diagnostics',
    cellRenderer: 'ToggleChip',
    minWidth: 230,
    hide: !enableCumminsEngineDiagnostics,
  }),
  columnDef.numberColumn({
    field: 'year',
    headerName: 'Year',
    minWidth: 100,
  }),
  columnDef.enabledDisabledColumn({
    field: 'engineYearBefore2000',
    headerName: 'ELD Exempt (Year < 2000 or Weight < 4500kgs or Bus for < 11)?',
    cellRenderer: 'ToggleEngineYear',
    trueLabelText: 'Yes',
    falseLabelText: 'No',
    minWidth: 210,
    valueGetter: ({ data }) => {
      if (!data || !data.assetType || data.assetType.typeClass !== 'Vehicle') return null;
      return data.engineYearBefore2000 ? 'Yes' : 'No';
    },
  }),
  columnDef.textColumn({
    field: 'vin',
    headerName: 'VIN - Vehicle Identification Number',
    minWidth: 270,
    enableRowGroup: false,
    hide: true,
  }),
  columnDef.enabledDisabledColumn({
    field: 'vinAutoDetected',
    headerName: 'Vin auto detected?',
    cellRenderer: 'ToggleVinAutoDetect',
    trueLabelText: 'Yes',
    falseLabelText: 'No',
    minWidth: 180,
    hide: true,
  }),
  columnDef.textColumn({
    field: 'managedBy.name',
    headerName: 'Managed by',
    cellRenderer: 'ManagedBy',
    minWidth: 180,
  }),
  columnDef.enabledDisabledColumn({
    field: 'active',
    headerName: 'Active?',
    cellRenderer: 'ToggleActive',
    trueLabelText: 'Active',
    falseLabelText: 'Inactive',
    minWidth: 130,
  }),
  columnDef.textColumn({
    field: 'plateNumber',
    enableRowGroup: false,
    headerName: 'License Plate Number',
    minWidth: 210,
  }),
  columnDef.textColumn({
    field: 'unitSystem',
    headerName: 'Units',
    minWidth: 210,
    hide: true,
  }),
  columnDef.textColumn({
    field: 'ctiMetadata.make',
    headerName: 'CTI/TPMS Make',
    minWidth: 180,
    hide: !enableCti,
    valueGetter: ({ data }) => {
      if (isNil(data) || isNil(data.ctiMetadata) || isNil(data.ctiMetadata.make)) return '';
      return data.ctiMetadata.make;
    },
  }),
  columnDef.textColumn({
    field: 'ctiMetadata.model',
    headerName: 'CTI/TPMS Model',
    minWidth: 180,
    hide: !enableCti,
    valueGetter: ({ data }) => {
      if (isNil(data) || isNil(data.ctiMetadata) || isNil(data.ctiMetadata.model)) return '';
      return data.ctiMetadata.model;
    },
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
    width: FEATURES.admin.hideCameraMethods || enabledVideoProviders?.length === 0 ? 92 : 130,
  }),
];
