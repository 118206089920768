import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, Icon, ProgressIndicator, ScrollBar } from 'stti-react-common';
import { Hls } from 'hls.js';
import { startCase, toUpper, includes, noop, uniqueId } from 'lodash';

import { getDatelessTime, dateObject } from '../../../helpers/moment';

const defaultResourceState = {
  loading: false,
  message: null,
  name: null,
  show: false,
  type: null,
  url: null,
};

let videoInstance = null;
let hlsInstance = null;

export const CameraSummary = ({
  selectedEntity,
  selectedData,
  fetchCameras,
  fetchLiveStreamDataByCameraId,
  fetchLiveSnapshotDataByCameraId,
  fetchVideoEventFileLocation,
  setOption,
  detailMode = false,
}) => {
  const [resource, setResource] = useState(defaultResourceState);

  const playerHeight = window.innerHeight > 780 ? 360 : 260;

  const cameraEvents = selectedData?.exceptions
    ?.filter((item) => item.exceptionType === 'eventVideoEvent')
    ?.map((item) => ({
      date: dateObject(item.startedAt, selectedData?.currentSystemOu?.timezone).format(
        'MMM D, YYYY HH:mm'
      ),
      hour: dateObject(item.startedAt, selectedData?.currentSystemOu?.timezone).format('HH:mm'),
      type: startCase(item.other.typeDescription),
      fileLocations: item.other.fileLocations,
    }));

  function closeResourceWindow() {
    if (hlsInstance) {
      hlsInstance.detachMedia();
      hlsInstance.destroy();
    }

    if (videoInstance && videoInstance.removeEventListener) {
      videoInstance.removeEventListener('loadeddata', noop);
    }
    setResource(defaultResourceState);
  }

  const getVideo = (item) => {
    const vehicleKey = selectedEntity.trailerClass
      ? selectedEntity.connectedTo
      : selectedEntity.vehicle;

    setResource({
      loading: true,
      message: 'Connecting to camera...',
      name: item.name,
      show: true,
      type: 'LIVESTREAM-VIDEO',
    });

    fetchLiveStreamDataByCameraId({ provider: item.provider, vehicleKey, cameraId: item.id })
      .then((response) => {
        if (response && response.hls) {
          videoInstance = document.getElementById('videoPlayer');
          if (Hls.isSupported()) {
            hlsInstance = new Hls();

            hlsInstance.loadSource(response.hls);
            hlsInstance.attachMedia(videoInstance);

            let bufferCount = 0;
            let errorCount = 0;

            hlsInstance.on(Hls.Events.ERROR, (event, data) => {
              if (data.fatal) {
                errorCount += 1;
                bufferCount = 0;

                if (errorCount > 5) {
                  setResource({
                    loading: false,
                    message: 'This resource is not available. Please try again later...',
                    name: item.name,
                    show: true,
                    type: 'LIVESTREAM-VIDEO',
                  });
                  setTimeout(() => {
                    setResource(defaultResourceState);
                    hlsInstance.destroy();
                  }, 500);
                  return;
                }

                switch (data.type) {
                  case Hls.ErrorTypes.NETWORK_ERROR: // trying to recover from network error
                    setResource({
                      loading: false,
                      message:
                        'We are facing network issues. Please wait while we are recovering...',
                      name: item.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                    hlsInstance.loadSource(response.hls);
                    hlsInstance.attachMedia(videoInstance);
                    hlsInstance.startLoad();
                    break;
                  case Hls.ErrorTypes.MEDIA_ERROR: // try to recover from media error
                    hlsInstance.loadSource(response.hls);
                    hlsInstance.attachMedia(videoInstance);
                    hlsInstance.recoverMediaError();
                    setResource({
                      loading: false,
                      message:
                        'We are facing streaming issues. Please wait while we are recovering...',
                      name: item.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                    break;
                  default: // cannot recover
                    setResource({
                      loading: false,
                      message: 'This resource is not available. Please try again later...',
                      name: item.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                    setTimeout(() => {
                      setResource(defaultResourceState);
                      hlsInstance.destroy();
                    }, 500);
                    break;
                }
              }
            });

            hlsInstance.on(Hls.Events.BUFFER_APPENDED, () => {
              bufferCount += 1;

              if (bufferCount > 1 && bufferCount < 16) {
                setResource({
                  loading: false,
                  message: `Closing this window in ${30 - bufferCount * 2} seconds...`,
                  name: item.name,
                  show: true,
                  type: 'LIVESTREAM-VIDEO',
                });
              }

              if (hlsInstance && bufferCount >= 16) {
                videoInstance.removeEventListener('loadeddata', noop);
                bufferCount = 0;

                setTimeout(() => {
                  setResource(defaultResourceState);
                  hlsInstance.destroy();
                }, 500);
              }
            });

            if (
              videoInstance &&
              !Object.prototype.hasOwnProperty.call(videoInstance, 'loadeddata')
            ) {
              videoInstance.addEventListener('loadeddata');
            }
          }
        }
      })
      .catch(() => {
        // This call seems to fail for no apparent reason before recover and return data,
        // so we will just add this empty catch to avoid javascript unhandled exceptions
      });
  };

  const getSnapshot = (item) => {
    setResource({
      loading: true,
      message: 'Requesting snapshot...',
      name: item.name,
      show: true,
      type: 'SNAPSHOT',
    });

    const vehicleKey = selectedEntity.trailerClass
      ? selectedEntity.connectedTo
      : selectedEntity.vehicle;

    fetchLiveSnapshotDataByCameraId({
      provider: item.provider,
      vehicleKey,
      cameraId: item.id,
    })
      .then((response) => {
        setResource({
          loading: false,
          message: null,
          name: item.name,
          url: response.url,
          show: true,
          type: 'SNAPSHOT',
        });
      })
      .catch(() => {
        setResource({
          loading: false,
          message: 'An error occurred while requesting snapshot, try again later...',
          name: item.name,
          url: null,
          show: true,
          type: 'SNAPSHOT',
        });
      });
  };

  const getRecordedFiles = (fileLocation) => {
    // if we have any other video player streaming we will close it
    closeResourceWindow();

    setResource({
      loading: true,
      message: 'Please wait while we are retrieving the recorded resource...',
      name: fileLocation.name,
      show: true,
      url: null,
      type: `RECORDED-${toUpper(fileLocation.fileType)}`,
    });

    fetchVideoEventFileLocation(fileLocation.fileLocationId).then((response) => {
      setResource({
        loading: false,
        message: null,
        name: fileLocation.name,
        show: true,
        url: response.url,
        type: `RECORDED-${toUpper(fileLocation.fileType)}`,
      });
    });
  };

  const getCameraData = () => {
    const vehicleKey = selectedEntity.trailerClass
      ? selectedEntity.connectedTo
      : selectedEntity.vehicle;

    setResource({ ...resource, loading: true });

    fetchCameras({ vehicleKey }).then((result) => {
      setResource({ ...resource, loading: false });
      setOption('summary');
    });
  };

  return (
    <div className="tile" style={detailMode ? { width: '100%' } : {}}>
      {!detailMode && (
        <>
          <h4>Camera - Live </h4>
          <div className="tile__content">
            <div className="tile__content__item">
              <div className="cameraContainer">
                <Icon icon="videocam" /> <span>Video</span>
              </div>
              <div className="cameraContainer">
                <Icon icon="photo_camera" /> <span>Snapshot</span>
              </div>
            </div>
            <div className="tile__content__item">
              {selectedData?.cameras?.map((row) => (
                <div className="cameraContainer" key={row.name}>
                  <Clickable
                    disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
                    onClick={() => getVideo(row)}
                  >
                    {row.name}
                  </Clickable>
                  <Clickable
                    disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
                    onClick={() => getSnapshot(row)}
                  >
                    {row.name}
                  </Clickable>
                </div>
              ))}
            </div>
            {resource.loading && (
              <div
                className="tile__content__item"
                style={{ textAlign: 'center', overflow: 'hidden' }}
              >
                <ProgressIndicator size={35} />
              </div>
            )}
          </div>
          <div className="tile__footer">
            <Clickable
              disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
              onClick={() => setOption('camera')}
            >
              View Details
            </Clickable>
            {!selectedData?.cameras ||
              (selectedData?.cameras?.length === 0 && (
                <Clickable disabled={resource.loading} onClick={() => getCameraData()}>
                  Reload Data
                </Clickable>
              ))}
          </div>
        </>
      )}

      {detailMode && (
        <div className="cameraDetail">
          <table className="cameraDetail__table">
            <thead>
              <tr>
                <td colSpan="2">Camera - Live </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className="cameraDetail__icon">
                    <Icon icon="videocam" /> <span>Video</span>
                  </div>
                </td>
                <td className="cameraDetail__links">
                  {selectedData?.cameras?.map((row) => (
                    <Clickable
                      disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
                      onClick={() => getVideo(row)}
                      key={row.id}
                    >
                      {row.name}
                    </Clickable>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cameraDetail__icon">
                    <Icon icon="photo_camera" /> <span>Snapshot</span>
                  </div>
                </td>
                <td className="cameraDetail__links">
                  {selectedData?.cameras?.map((row) => (
                    <Clickable
                      disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
                      onClick={() => getSnapshot(row)}
                      key={row.id}
                    >
                      {row.name}
                    </Clickable>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>

          <ScrollBar style={{ height: '110px' }}>
            <table className="cameraDetail__table">
              <thead>
                <tr>
                  <td>Camera Events - Today </td>
                  <td>Time </td>
                  <td>Source </td>
                </tr>
              </thead>
              <tbody>
                {cameraEvents.map((event, index) => (
                  <tr key={index}>
                    <td>{event.type}</td>
                    <td>{event.hour}</td>
                    <td>
                      {event.fileLocations.map((fileLocation) => (
                        <div className="cameraDetail__icon" key={uniqueId()}>
                          <Icon
                            icon={
                              fileLocation.fileType === 'snapshot' ? 'photo_camera' : 'videocam'
                            }
                          />
                          <Clickable
                            disabled={resource.loading || resource.type === 'LIVESTREAM-VIDEO'}
                            onClick={() => getRecordedFiles(fileLocation)}
                            key={uniqueId()}
                          >
                            {fileLocation.cameraName}
                          </Clickable>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ScrollBar>
        </div>
      )}

      <div
        className="resourceContainer"
        style={{
          display: resource.show ? 'block' : 'none',
          width: playerHeight + 240,
          height: playerHeight + 85,
          top: -(playerHeight + 90),
        }}
      >
        <div className="resourceContainer__header">
          <h4>
            {resource.name} - {selectedEntity?.vehicleId} -{' '}
            {getDatelessTime(null, selectedData?.currentSystemOu?.timeZone, 'h:mm')} -{' '}
            {resource.type === 'LIVESTREAM-VIDEO' ? 'Live' : 'Recorded'}
          </h4>
          <Icon icon="cancel" onClick={() => closeResourceWindow()} />
        </div>

        {includes(resource.type, 'VIDEO') && (
          <video
            id="videoPlayer"
            autoPlay
            controls
            style={{ display: resource.show ? 'block' : 'none' }}
            height={playerHeight}
            {...(resource.url && { src: resource.url })}
          >
            <track kind="captions" />
          </video>
        )}

        {includes(resource.type, 'SNAPSHOT') && (
          <div
            style={{
              display: resource.show ? 'block' : 'none',
              height: playerHeight,
              background: '#efefef',
            }}
          >
            <img
              style={{
                display: resource.url ? 'block' : 'none',
                height: playerHeight,
              }}
              src={resource.url}
              alt="snapshot"
            />
          </div>
        )}

        {resource.message && <div className="resourceContainer__footer">{resource.message}</div>}
      </div>
    </div>
  );
};

CameraSummary.propTypes = {
  selectedEntity: PropTypes.any,
  selectedData: PropTypes.any,
  fetchCameras: PropTypes.func,
  fetchLiveStreamDataByCameraId: PropTypes.func,
  fetchLiveSnapshotDataByCameraId: PropTypes.func,
  fetchVideoEventFileLocation: PropTypes.func,
  setOption: PropTypes.func,
  detailMode: PropTypes.bool,
};
