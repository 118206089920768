import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';

import { GoogleMap } from 'stti-react-common';

import { symbols } from '../../../../commons/MapsCommon';
import { HeadingArrow } from '../../../../commons/MapsCommon/HeadingArrow';
import { StopIcon } from '../../../../commons/MapsCommon/StopIcon';

import { toMoment } from '../../../../../helpers/moment';
import { getCompassPoint } from '../utils';

const { Marker, Info } = GoogleMap;

export const MapBreadcrumbs = (breadcrumbs, formatUnit, timeZone) =>
  useMemo(
    () =>
      breadcrumbs.map((breadcrumb) => (
        <Marker
          key={uniqueId()}
          position={breadcrumb}
          icon={symbols.speedSampleIcon}
          rotation={breadcrumb.heading}
          selection={breadcrumb}
          order={98}
          minZoom
        >
          <Info>
            <div className="Map__ActivityInfo">
              <div className="Map__ActivityInfo__title">
                {isFinite(breadcrumb.speed) && (
                  <div className="Map__EventInfo__vector">
                    {breadcrumb.speed < 1 ? (
                      <>
                        <StopIcon color="#333" />
                        <strong>Stopped</strong>
                      </>
                    ) : (
                      <>
                        <HeadingArrow heading={breadcrumb.heading || 0} color="#333" />
                        Heading {getCompassPoint(breadcrumb.heading)} at&nbsp;
                        <strong>{formatUnit('speed', breadcrumb.speed)}</strong>
                      </>
                    )}
                  </div>
                )}
              </div>
              <table className="Map__ActivityInfo__specs">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <td>
                      {toMoment(breadcrumb.eventAt, timeZone).format('MMM DD, YYYY HH:mm:ss')}
                    </td>
                  </tr>
                  {breadcrumb.postedSpeed && (
                    <tr>
                      <th>Posted Speed</th>
                      <td>{formatUnit('speed', breadcrumb.postedSpeed)}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Altitude</th>
                    <td>{breadcrumb.altitude && breadcrumb.altitude.toFixed(0)} meters</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Info>
        </Marker>
      )),
    [breadcrumbs]
  );
