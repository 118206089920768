import { FEATURES } from '../../../../../../features';
import { columnDef } from '../../../../../commons/ControlledDataGrid';

import { currencyFormat } from '../../utils';

export const makeColumnDefs = (dataRange, useSegmentFilters) => [
  {
    headerName: 'Open',
    children: [
      columnDef.iconColumn({
        field: 'openFuelTaxDetailReport',
        headerName: '',
        cellRenderer: 'OpenDetail',
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
    ],
  },
  {
    headerName: 'Locale Info',
    children: [
      columnDef.textColumn({
        field: 'jurisdictionCountry',
        headerName: 'Country',
        width: 110,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'jurisdictionState',
        headerName: 'Jurisdiction',
        width: 110,
        enableRowGroup: true,
        rowGroupIndex: 0,
      }),
    ],
  },
  {
    headerName: 'Info',
    children: [
      columnDef.numberColumn({
        field: 'detailCount',
        headerName: 'Num of Segments',
        width: 100,
        aggFunc: 'sum',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'fuelType',
        headerName: 'Fuel Type',
        width: 100,
        enableRowGroup: true,
        cellRenderer: 'FuelType',
        rowGroupIndex: 1,
      }),
      columnDef.textColumn({
        field: 'ou.name',
        headerName: 'Organization',
        hide: true,
      }),
      columnDef.listColumn({
        field: 'drivers',
        headerName: 'Drivers',
        cellRenderer: 'Drivers',
        transformValueElement: (item) => item.name,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        width: 110,
      }),
      columnDef.textColumn({
        field: 'vehicle.typeName',
        headerName: 'Vehicle Type',
        hide: true,
      }),
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Date',
        sort: 'asc',
        hide: true,
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Date',
        hide: true,
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
        aggFunc: 'sum',
        enableRowGroup: false,
        hide: true,
      }),
      columnDef.distanceColumn({
        field: 'totalDistance',
        headerName: 'Total Dist',
        aggFunc: 'sum',
      }),
      columnDef.simpleMileageColumn({
        field: 'averagePerf',
        headerName: 'Average Perf',
        hide: true,
      }),
    ],
  },
  {
    headerName: 'Tax Values',
    children: [
      columnDef.distanceColumn({
        field: 'onHighwayDistance',
        headerName: 'Taxable Dist (On Hwy)',
        width: 180,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'offHighwayDistance',
        headerName: 'Non-Taxable Dist (Off Hwy)',
        width: 180,
        aggFunc: 'sum',
        hide: true,
      }),
      columnDef.fuelColumn({
        field: 'taxQtConsumed',
        headerName: 'Taxable Qty Consumed',
        aggFunc: 'sum',
        fuelToStandardEquivalent: true,
        forceInteger: true,
        ...(FEATURES.reports.useDynamicTaxReportCalculation &&
          useSegmentFilters && {
            valueGetter: ({ data }) => {
              if (!dataRange || dataRange.length === 0) {
                return data && data.taxQtConsumed;
              }

              const dataFound = dataRange.find((row) => row.id === data.id);
              return dataFound && dataFound.taxQtConsumed;
            },
          }),
      }),
      columnDef.fuelColumn({
        field: 'addedFuel',
        headerName: 'Taxed Qty Purchased',
        aggFunc: 'sum',
        fuelToStandardEquivalent: true,
        forceInteger: true,
      }),
      columnDef.fuelColumn({
        field: 'netTaxableQty',
        headerName: 'Net Taxable Qty',
        fuelToStandardEquivalent: true,
        aggFunc: 'sum',
        forceInteger: true,
        ...(FEATURES.reports.useDynamicTaxReportCalculation &&
          useSegmentFilters && {
            valueGetter: ({ data }) => {
              if (!dataRange || dataRange.length === 0) {
                return data && data.netTaxableQty;
              }

              const dataFound = dataRange.find((row) => row.id === data.id);
              return dataFound && dataFound.netTaxableQty;
            },
          }),
      }),
      columnDef.numberColumn({
        field: 'taxRate',
        headerName: 'Tax Rate',
        valueFormatter: ({ value }) => (value ? Number(value).toFixed(4) : 0),
        ...(FEATURES.reports.useJurisdictionTaxRates && {
          valueGetter: ({ data }) => {
            if (!dataRange || dataRange.length === 0) {
              return data && data.taxRate;
            }

            const dataFound = dataRange.find((row) => row.id === data.id);
            return dataFound && dataFound.taxRate;
          },
        }),
      }),
      columnDef.numberColumn({
        field: 'taxDue',
        headerName: 'Calculated Tax Due',
        valueFormatter: ({ value }) => currencyFormat(value),
        ...(FEATURES.reports.useJurisdictionTaxRates && {
          valueGetter: ({ data }) => {
            if (!dataRange || dataRange.length === 0) {
              return data && data.taxDue;
            }

            const dataFound = dataRange.find((row) => row.id === data.id);
            return dataFound && dataFound.taxDue;
          },
        }),
        aggFunc: 'sum',
      }),
    ],
  },
  {
    headerName: 'Edits',
    children: [
      columnDef.listColumn({
        field: 'json.edits',
        headerName: 'Modified',
        cellRenderer: 'JsonEdits',
        transformValueElement: (item) => `${item.property} = ${item.newValue} - ${item.userName}`,
        hide: true,
      }),
    ],
  },
];
