// It is module structures in case we want breakdown files by topic/domain

export const SUPER_ADMIN_ROLE_KEY = 'S-SUPER_ADMIN';
export const ADMIN_ROLE_KEY = 'S-administrator';
export const MANAGER_ROLE_KEY = 'S-manager';
export const DISPATCHER_ROLE_KEY = 'S-dispatcher';
export const MECHANIC_ROLE_KEY = 'S-mechanic';
export const DRIVER_ROLE_KEY = 'S-driver';
export const GUEST_ROLE_KEY = 'S-guest';

export const ADMIN_ROLE_KEYS = [SUPER_ADMIN_ROLE_KEY, ADMIN_ROLE_KEY];

/**
 * SUPPORT_ROLE_KEYS are all the valid types of support/admin accounts under ELD
 */
export const SUPPORT_ROLE_KEYS = [
  SUPER_ADMIN_ROLE_KEY,
  ADMIN_ROLE_KEY,
  MANAGER_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
  GUEST_ROLE_KEY,
];

export const VEHICLE_CONFIG_PROPS = [
  'filterVehiclesByOu',
  'disableAddTractorByOu',
  'disableAddTrailerByOu',
];

export const OU_EXPECTED_PAYLOAD = [
  'address',
  'carrier',
  'description',
  'disableAddTractorByOu',
  'disableAddTrailerByOu',
  'ecmState',
  'externalId',
  'filterVehiclesByOu',
  'key',
  'locale',
  'name',
  'parent',
  'principal',
  'showVCPerformancePoints',
  'timeZone',
  'unitSystem',
];

export const DEFAULT_TENANT = { key: '', name: '', tenantAddress: '', tenantAdmin: '' };
