import { makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

export const fetchEcmReports = makeRestApiAction({
  service: 'ecmReports',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchEcmReports,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'ecm reports',
});
