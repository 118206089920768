import React from 'react';

import { Chip } from 'stti-react-common';
import { toLower, capitalize } from 'lodash';

import { NA_VALUE } from '../../../../../helpers/formats';
import { pluralize } from '../../../../../helpers/pluralize/pluralizeUtils';

import { FEATURES } from '../../../../../features';

// as Stated on MBL-3385 : to have no decimal places (unless otherwise specified)
export const specialFormat = (value) => {
  if (!value) return 0;
  const parsedValue = Number(value);
  const numberFormat = new Intl.NumberFormat('en-US');

  return numberFormat.format(Math.round(parsedValue));
};

export const currencyFormat = (value) => {
  if (!value) return NA_VALUE;
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  });
  return Math.sign(value) === -1
    ? `($${formatter.format(Math.abs(value)).replace('USD', '').trim()})` // this will remove US format and add universal money symbol $
    : `$${formatter.format(value).replace('USD', '').trim()}`; // this will remove US format and add universal money symbol $
};

export const numberFormat = (value) => {
  if (!value) return NA_VALUE;
  const formatter = new Intl.NumberFormat(undefined, {
    maximumSignificantDigits: 2,
  });
  return formatter.format(value);
};

export const getFuelName = (fuel) => {
  if (!fuel) return null;
  if (toLower(fuel) === 'lng') return 'LNG  (1L = 0.73 KG)';
  return toLower(fuel) === 'gas' ? 'Gasoline' : fuel;
};

export const formatDataGridValue = (lookup, json, value, formatUnit) => {
  if (json && json.edits && Array.isArray(json.edits) && json.edits.length > 0) {
    const found = json.edits.find(({ property }) => property === lookup);
    if (found && found.newValue) {
      return formatUnit('distance', found.newValue);
    }
    return formatUnit('distance', value);
  }
  return formatUnit('distance', value);
};

export const formatFuelType = (fuelType) => {
  if (!fuelType) {
    return FEATURES.reports.hideTaxReportUnknownFuelType ? null : 'Unknown';
  }
  if (toLower(fuelType) === 'special diesel') {
    return 'Diesel';
  }
  return fuelType;
};

export const getRenderSummary = (renderProps, value, label) => {
  if (!value || value.length === 0) return null;

  const chipLabel =
    value.length > 1
      ? `${capitalize(label)}: ${value.length} ${pluralize(label)}`
      : `${capitalize(label)}: ${value[0]}`;

  return <Chip {...renderProps} label={chipLabel} />;
};

export const getMonthsAndYearFromQuarter = (quarter) => {
  const qt = quarter.substring(0, 2);
  const year = quarter.substring(2, 6);

  switch (qt) {
    case '1Q':
      return {
        months: [
          { key: 'January', value: 1 },
          { key: 'February', value: 2 },
          { key: 'March', value: 3 },
        ],
        year,
      };
    case '2Q':
      return {
        months: [
          { key: 'April', value: 4 },
          { key: 'May', value: 5 },
          { key: 'June', value: 6 },
        ],
        year,
      };
    case '3Q':
      return {
        months: [
          { key: 'July', value: 7 },
          { key: 'August', value: 8 },
          { key: 'September', value: 9 },
        ],
        year,
      };
    case '4Q':
      return {
        months: [
          { key: 'October', value: 10 },
          { key: 'November', value: 11 },
          { key: 'December', value: 12 },
        ],
        year,
      };
    default:
      return { months: [{ key: '', value: 0 }], year: '1900' };
  }
};
