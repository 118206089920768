import moment from 'moment-timezone';

export const groupPairedEvents = (events) => {
  const result = {};
  const starts = {};

  events.forEach((event, index) => {
    const { eventType } = event;

    const startEventMatch = eventType.match(/.+(?=Started$)/);
    if (startEventMatch) {
      const startEventType = startEventMatch.toString();
      starts[startEventType] = {
        event,
        index,
      };
      return;
    }

    const endEventMatch = eventType.match(/.+(?=Ended|Completed$)/);
    if (endEventMatch) {
      const endEventType = endEventMatch.toString();
      const start = starts[endEventType];
      if (!start) return;
      const end = {
        event,
        index,
      };
      const duration = moment
        .duration(moment(end.event.eventAt).diff(moment(start.event.eventAt)))
        .asSeconds();

      result[endEventType] = result[endEventType] || [];

      // mimick exceptions endpoint
      result[endEventType].push({
        sectionType: endEventType, // this is exceptionType in exceptions
        startedAt: start.event.eventAt,
        endedAt: end.event.eventAt,
        startSequence: start.event.sequence,
        endSequence: end.event.sequence,
        startLocation: {
          latitude: start.event.latitude,
          longitude: start.event.longitude,
        },
        endLocation: {
          latitude: end.event.latitude,
          longitude: end.event.longitude,
        },
        duration,
        other: end.event.other || {},
        // for rendering in NaviMap
        key: `${endEventType}/${end.event.sequence}`,
        path: events.slice(start.index, end.index + 1),
      });
    }
  });
  return result;
};
