import { connect } from 'react-redux';

import { ControlledDataTable } from './ControlledDataTable';
import { selectPrintMode } from '../../../data/system';

export const ControlledDataTableContainer = connect(
  (state) => ({
    isPrintLayout: !!selectPrintMode(state).print,
  }),
  {}
)(ControlledDataTable);
