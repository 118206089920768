import React from 'react';

import { SpeedLimitSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const speedLimitSummary = configureRoute({
  name: 'Speed Limit',
  group: administrationGroups.configurations,
  order: 10,
  path: '/speedLimitConfig',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <SpeedLimitSummaryContainer {...props} />,
});
