import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchActivationCodes: 'administration/devices/activationCodes/fetchActivationCodes',
  deleteActivationCode: 'administration/devices/activationCodes/deleteActivationCode',
  createActivationCode: 'administration/activationCodes/createActivationCode',
};

export const activationCodesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchActivationCodes)) {
      draft.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.createActivationCode)) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteActivationCode)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchActivationCodes)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchActivationCodes)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
