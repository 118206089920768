import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, castArray } from 'lodash';

import { selectActiveRequests } from '../../../data/requests';
import { RequestsProgressIndicator } from './RequestsProgressIndicator';

const mapStateToProps = (state, { service }) => ({
  requests: filter(selectActiveRequests(state), (request) =>
    castArray(service).includes(request.service)
  ),
});

export const RequestsProgressIndicatorContainer =
  connect(mapStateToProps)(RequestsProgressIndicator);

RequestsProgressIndicatorContainer.propTypes = {
  service: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
