import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import {
  useReportController,
  ReportProvider,
  ReportFragment,
  ReportLoader,
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  ControlsTablePrint,
  LaunchReport,
  DetailReportPrintingLinks,
  SectionPrint,
} from '../../../commons/ReportsCommon';

import './GeozonesSummaryReport.scss';

import { columnDefs } from './columnDefs';
import { FiltersPanel } from './FiltersPanel';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: {
    dateRangeMode: 'thisMonth',
  },
  dataGrid: createState({ columnDefs }),
});

export const GeozonesSummaryReport = ({
  fetchGeozones,
  fetchOrganizations,
  geozoneActivitiesReportRoute,
  geozones,
  reportViewId,
  route,
}) => {
  const requestServices = ['geozones'];
  useEffect(() => {
    fetchOrganizations();
  }, []);

  const report = useReportController({
    reportType: 'geozonesSummary',
    reportTypeName: 'Geozone Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: geozones,
      chain: ['query', 'filters'],
    },
  });

  const { filteredRows, dataGridController, reportType } = report;

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    OpenDetail: ({ data }) => (
      <LaunchReport
        onClick={async () => {
          geozoneActivitiesReportRoute.open({
            startDate: data.startedAt,
            endDate: data.endedAt,
            geozoneKey: data.geozoneKey,
            orgKey: data.vehicleOuKey,
            reportViewId: 'default',
          });
        }}
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
  });

  const [modal, setModal] = useState({ isOpen: false, data: {} });

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData isMonthly fetchAction={fetchGeozones} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="GeozonesSummaryReport">
        <ReportFragment.Header
          reportType={reportType}
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
        />

        <QueryPanel
          queryFor="activity"
          group="query"
          minAllowedDate="2018-01-01"
          disableFuture
          enableOnlyStartDateDays="firstDay"
          enableOnlyEndDateLastDays="lastDay"
          filterDateRange={['custom', 'thisMonth', 'lastMonth']}
        />
        <SectionPanel
          name="filtersPanel"
          title="Geozone Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <FiltersPanel group="filters" filterGroup="filters" rows={filteredRows.query} />
        </SectionPanel>
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
        <PrintLayout mode={reportType}>
          <ReportFragment.Header />
          <ControlsTablePrint />
          <SectionPrint flexRow>
            <AggregatesContent rows={filteredRows.dataGrid} />
          </SectionPrint>
          <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
        </PrintLayout>
        <DetailReportPrintingLinks
          getDetailReportPath={(data) =>
            geozoneActivitiesReportRoute.makeTargetPath({
              startDate: data.startedAt,
              endDate: data.endedAt,
              geozoneKey: data.geozone,
              reportViewId: 'default',
            })
          }
        />
      </div>
      <BasicDialog
        title="Query is too wide"
        maxWidth="lg"
        isOpen={modal.isOpen}
        onClose={() => setModal({ isOpen: false, data: {} })}
      >
        <div>
          <p>
            It appears that your query is attempting to retrieve data from {modal.data.ouCount}{' '}
            organizations over the course of {modal.data.differenceByMonths}{' '}
            {modal.data.differenceByMonths > 1 ? 'months' : 'month'}.
          </p>
          <p>
            This would make {modal.data.ouCount * modal.data.differenceByMonths} server calls and
            slow down report detail rendering.
          </p>
          <p>Please refine your queue parameters and try again</p>
        </div>
      </BasicDialog>
    </ReportProvider>
  );
};

GeozonesSummaryReport.propTypes = {
  fetchGeozones: PropTypes.func.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  geozoneActivitiesReportRoute: PropTypes.object,
  geozones: customPropTypes.geozoneSummaries.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
