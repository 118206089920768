import { connect } from 'react-redux';

import { AppConfig } from './AppConfig';
import {
  fetchCurrentOrganizationUnits,
  fetchCurrentPermissions,
  fetchCurrentTenant,
  fetchCurrentUser,
  fetchSearchLimits,
  selectAccessToken,
  setSystem,
} from '../../../data/system';
import { organization } from '../../../data/administration/configurations';

export const AppConfigContainer = connect(
  (state) => ({
    accessToken: selectAccessToken(state),
  }),
  {
    fetchCurrentOrganizationUnits,
    fetchCurrentPermissions,
    fetchCurrentTenant,
    fetchCurrentUser,
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    fetchSearchLimits,
    setSystem,
  }
)(AppConfig);
