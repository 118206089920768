import React from 'react';
import { flatten, groupBy } from 'lodash';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { AggregatesCard } from '../../../commons/ReportsCommon';
import { countUniqueOfField, sumOfField, countOf } from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();
  const registers = rows.filter(Boolean);
  const distances = flatten(registers.map((row) => row && row.distances));
  const groupedDistances = groupBy(distances.filter(Boolean), 'state');

  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Vehicles',
            value: countUniqueOfField(registers, 'vehicle'),
          },
          {
            header: 'Drivers',
            value: countUniqueOfField(registers, 'driverKey'),
          },
          {
            header: 'Sessions',
            value: countOf(registers),
          },
        ]}
      />
      {Object.entries(groupedDistances).map(([key, value]) => (
        <AggregatesCard
          title={key}
          name="distance"
          key={key}
          fields={[
            {
              header: 'Total Distance',
              value: formatUnit('distance', sumOfField(value, 'distance')),
            },
            {
              header: 'On Highway',
              value: formatUnit('distance', sumOfField(value, 'onHighway')),
            },
            {
              header: 'Off Highway',
              value: formatUnit('distance', sumOfField(value, 'offHighway')),
            },
          ]}
        />
      ))}
    </>
  );
};

AggregatesContent.propTypes = {
  rows: customPropTypes.fuelTaxMileages.isRequired,
};
