import { connect } from 'react-redux';

import { DispatchAbortReasonSummary } from './summary';

import { dispatchAbortReason } from '../../../../../data/administration/configurations';

export const DispatchAbortReasonSummaryContainer = connect(
  (state) => ({
    dispatchAbortReasons: dispatchAbortReason.selectors.selectDispatchAbortReasons(state),
  }),
  {
    fetchDispatchAbortReasons: dispatchAbortReason.actions.fetchDispatchAbortReasonsOptimized,
    deleteDispatchAbortReason: dispatchAbortReason.actions.deleteDispatchAbortReason,
    createDispatchAbortReason: dispatchAbortReason.actions.createDispatchAbortReason,
    updateDispatchAbortReason: dispatchAbortReason.actions.updateDispatchAbortReason,
  }
)(DispatchAbortReasonSummary);
