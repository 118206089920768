import { connect } from 'react-redux';

import { ActivationCodesSummary } from './summary';
import { activationCode } from '../../../../../data/administration/devices';

export const ActivationCodesSummaryContainer = connect(
  (state) => ({
    activationCodes: activationCode.selectors.selectActivationCodes(state),
  }),
  {
    fetchActivationCodes: activationCode.actions.fetchActivationCodesOptimized,
    deleteActivationCode: activationCode.actions.deleteActivationCode,
    createActivationCode: activationCode.actions.createActivationCode,
  }
)(ActivationCodesSummary);
