import { isNil, groupBy } from 'lodash';

import { formatFuelType } from '../../../components/reports/FuelTracking/FuelTax/utils';

import { FEATURES } from '../../../features';

export const sanitizeData = (data) =>
  data
    .filter((row) => {
      if (!row) return false;
      if (FEATURES.reports.hideTaxReportUnknownFuelType) {
        return (
          row.fuelType !== null &&
          row.jurisdictionCountry !== null &&
          row.jurisdictionState !== null
        );
      }
      return row.jurisdictionCountry !== null && row.jurisdictionState !== null;
    })
    .filter(Boolean);

export const getCalculatedValues = (col, item) => {
  const {
    offHighwayDistance,
    onHighwayDistance,
    addedFuel,
    fuelType,
    json: { edits },
  } = item;

  const fuelTypeName = formatFuelType(fuelType);
  const foundAddedFuel = edits.find(({ property }) => property === 'addedFuel');
  const foundOnHwyDistance = edits.find(({ property }) => property === 'onHighwayDistance');
  const foundOffHwyDistance = edits.find(({ property }) => property === 'offHighwayDistance');

  const calcAddedFuel =
    foundAddedFuel && !isNil(foundAddedFuel.newValue) ? foundAddedFuel.newValue : addedFuel;

  const calcOnHwyDist =
    foundOnHwyDistance && !isNil(foundOnHwyDistance.newValue)
      ? foundOnHwyDistance.newValue
      : onHighwayDistance;

  const calcOffHwyDist =
    foundOffHwyDistance && !isNil(foundOffHwyDistance.newValue)
      ? foundOffHwyDistance.newValue
      : offHighwayDistance;

  const recallData = col.map((row) => ({
    ...row,
    addedFuel: calcAddedFuel,
    onHighwayDistance: calcOnHwyDist,
    offHighwayDistance: calcOffHwyDist,
    fuelType: fuelTypeName,
  }));

  return { calcAddedFuel, calcOnHwyDist, calcOffHwyDist, recallData };
};

export const fuelConsumptionRates = (data) => {
  const dataByFuelType = groupBy(data, 'fuelType');

  const fuelConsumptionRatesObj = {};
  Object.entries(dataByFuelType).forEach(([key, value]) => {
    const totalKilometers = value.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue?.onHighwayDistance || 0) +
        (currentValue?.offHighwayDistance || 0),
      0
    );

    const totalLitres = value.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.addedFuel || 0),
      0
    );
    const kpl = totalKilometers / totalLitres;
    fuelConsumptionRatesObj[formatFuelType(key)] = {
      totalKilometers,
      totalLitres,
      kpl,
      rows: value.length,
    };
  });

  return fuelConsumptionRatesObj;
};
