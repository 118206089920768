import { filter } from 'lodash';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../apiActionSupport';
import { ACTION_TYPE } from './videoEventsReducer';
import {
  selectVideoEventTypesFetchRequests,
  selectVideoEventBehaviorsFetchRequests,
  selectVideoEventFileLocationFetchRequests,
} from './videoEventsSelectors';

const fetchVideoEventTypes = makeRestApiAction({
  service: 'videoEventTypes',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchVideoEventTypes,
  transformOutput: (videoEventTypesData, id) => ({
    events: videoEventTypesData,
    key: id || 'default', // add videoProvider to payload
  }),
  notificationsItemDescriptor: 'video types',
});

export const fetchVideosTypesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVideoEventTypesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVideoEventTypes,
  baseActionType: ACTION_TYPE.fetchVideoEventTypes,
});

const fetchVideoEventBehaviors = makeRestApiAction({
  service: 'videoEventBehaviors',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVideoEventBehaviors,
  notificationsItemDescriptor: 'video behaviors',
});

export const fetchVideosBehaviorsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVideoEventBehaviorsFetchRequests,
  filterFetches: (fetches) => fetches,
  fetchAction: fetchVideoEventBehaviors,
  baseActionType: ACTION_TYPE.fetchVideoEventBehaviors,
});

export const fetchVideoEventFileLocation = makeRestApiAction({
  service: 'videoEventFileLocations',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchVideoEventFileLocation,
  transformOutput: (videoEventFileLocationData, id) => ({
    ...videoEventFileLocationData,
    id, // add videoEventFileLocationId to payload
  }),
  notificationsItemDescriptor: 'video',
});

export const fetchVideoEventFileLocationOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVideoEventFileLocationFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVideoEventFileLocation,
  baseActionType: ACTION_TYPE.fetchVideoEventFileLocation,
});
