import { mean, get, filter, isFinite, uniq, flatten, sum } from 'lodash';
import { convertFuelToStandardEquivalent } from '../formats';

export const countOf = (items) => items && items.length; // return null if null, otherwise return count

export const meanOfField = (items, field) => {
  // nulls values are ignored, but if no numeric values remain will return null
  const values = filter(
    items.map((item) => get(item, field)),
    isFinite
  );
  if (values.length === 0) return null;
  return mean(values);
};

export const sumOfField = (items, field) => {
  // nulls values are ignored, but if no numeric values remain will return null
  const values = filter(
    items.map((item) => get(item, field)),
    isFinite
  );
  if (values.length === 0) return null;
  return sum(values);
};

export const uniqueOfField = (items, field) =>
  uniq(items.map((item) => get(item, field)).filter(Boolean));

export const countUniqueOfField = (items, field) => uniqueOfField(items, field).length;

export const sortedUniqueOfField = (items, field) => uniqueOfField(items, field).sort();

export const uniqueOfArrayField = (items, field) =>
  uniq(flatten(items.map((item) => get(item, field))));

export const countUniqueOfArrayField = (items, field) => uniqueOfArrayField(items, field).length;

export const sortedUniqueOfArrayField = (items, field) => uniqueOfArrayField(items, field).sort();

export const sumFields = (item, fields) => {
  // any non-numeric value causes return of null
  let total = 0;
  for (const field of fields) {
    const fieldValue = get(item, field);
    if (!isFinite(fieldValue)) return null;
    total += fieldValue;
  }
  return total;
};

export const divideFields = (item, dividendField, divisorField) => {
  // any non-numeric value causes return of null
  const dividend = get(item, dividendField);
  const divisor = get(item, divisorField);
  if (!isFinite(dividend) || !isFinite(divisor)) return null;
  return dividend / divisor;
};

export const calculateMileage = (distance, consumedFuel, fuelType) => {
  // any non-numeric value causes return of null
  if (!isFinite(distance) || !isFinite(consumedFuel)) return null;
  return distance / convertFuelToStandardEquivalent(fuelType, consumedFuel);
};

export const countTruthyOfField = (items, field) =>
  items.reduce((count, item) => count + (get(item, field) ? 1 : 0), 0);

export const countFalseyOfField = (items, field) =>
  items.reduce((count, item) => count + (!get(item, field) ? 1 : 0), 0);

export const countFalseOfField = (items, field) =>
  items.reduce((count, item) => count + (get(item, field) === false ? 1 : 0), 0);

export const countValueOfField = (items, field, value) =>
  items.reduce((count, item) => count + (get(item, field) === value ? 1 : 0), 0);
