import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import {
  CreateAdminItemDialog,
  DeleteAdminItemDialog,
  EditAdminItemDialog,
} from '../../../../commons/AdminCommon';
import { Feature } from '../../../../commons/Feature';
import { columnDefs } from './columnDefs';

import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const DispatchAbortReasonSummary = ({
  dispatchAbortReasons,
  fetchDispatchAbortReasons,
  deleteDispatchAbortReason,
  createDispatchAbortReason,
  updateDispatchAbortReason,
  route,
}) => {
  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetRow, setTargetRow] = useState({});

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          onClick={() => {
            setTargetRow({
              key: data.key,
              text: data.text,
            });
            setCurrentDialog('edit');
          }}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetRow({
              text: data.text,
              key: data.key,
            });
            setCurrentDialog('delete');
          }}
          icon="delete"
        />
      </>
    ),
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  useEffect(() => {
    fetchDispatchAbortReasons();
  }, []);

  // RENDER
  return (
    <div className="DispatchAbortReasonSummary">
      <Feature.Header
        onClose={route.close}
        title="Dispatch Abort Reasons"
        service="dispatchAbortReasons"
      >
        <Button
          icon="add"
          variant="contained"
          label="Create New Abort Reason"
          onClick={() => {
            setCurrentDialog('new');
          }}
        />
      </Feature.Header>
      <ControlledDataGrid
        theme="balham"
        controller={dataGridController}
        components={dataGridComponents}
        rows={dispatchAbortReasons}
        rowIdProperty="key"
        sizeColumnsToFit
      />
      <DeleteAdminItemDialog
        dialogTitle="Dispatch Abort Reason"
        itemTitle={targetRow.text}
        isOpen={currentDialog === 'delete'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={deleteDispatchAbortReason}
        rowData={targetRow}
      />
      <CreateAdminItemDialog
        dialogTitle="Dispatch Abort Reason"
        inputLabel="Save As: "
        isOpen={currentDialog === 'new'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={createDispatchAbortReason}
      />
      <EditAdminItemDialog
        dialogTitle="Dispatch Abort Reason"
        inputLabel="Reason: "
        isOpen={currentDialog === 'edit'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={updateDispatchAbortReason}
        rowData={targetRow}
      />
    </div>
  );
};

DispatchAbortReasonSummary.propTypes = {
  dispatchAbortReasons: customPropTypes.dispatchAbortReasons,
  deleteDispatchAbortReason: PropTypes.func.isRequired,
  createDispatchAbortReason: PropTypes.func.isRequired,
  updateDispatchAbortReason: PropTypes.func.isRequired,
  fetchDispatchAbortReasons: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
