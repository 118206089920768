import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ItemManagerTree, Clickable, Icon, Menu, ScrollBar } from 'stti-react-common';
import { groupBy, sortBy, isEmpty } from 'lodash';

import {
  SUPER_ADMIN_ROLE_KEY,
  GUEST_ROLE_KEY,
  DRIVER_ROLE_KEY,
} from '../../../data/system/constants';

import { useWindowDimensions } from '../../../helpers/hooks';
import { hasRole } from '../../../helpers/admin/adminUtils';

import './Sidebar.scss';

export const Sidebar = ({ availableRoutes, menuCategory, user, setMenu }) => {
  const exitRoute = useMemo(
    () => availableRoutes.find(({ name }) => name === 'Login'),
    [availableRoutes]
  );

  const isSystem =
    user &&
    user.memberships &&
    user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, SUPER_ADMIN_ROLE_KEY))
    );

  const isGuest =
    user &&
    user.memberships &&
    user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, GUEST_ROLE_KEY))
    );

  const routeTreeItems = useMemo(
    () =>
      Object.entries(
        groupBy(
          sortBy(
            availableRoutes.filter(({ group, hidden }) => group && !hidden),
            'group.order'
          ).map(({ group, ...rest }) => ({
            groupIcon: group.icon,
            group,
            ...rest,
          })),
          'group.name'
        )
      ).map((route) => {
        const groupName = route[0];
        const children = route[1];
        const item = {
          node: (
            <div className="Sidebar__group">
              <Icon icon={children[0].groupIcon} />
              <span>{groupName}</span>
            </div>
          ),
          keywords: groupName,
          id: groupName,
          items: sortBy(children, 'order').map(({ name, path, open, ...rest }) => ({
            node: (
              <div className="Sidebar__node">
                <Clickable
                  onClick={() => {
                    open({ reportViewId: 'default' }, { ignoreUrlByMenuCategory: menuCategory });
                  }}
                >
                  <span>{name}</span>
                </Clickable>
              </div>
            ),
            keywords: name,
            id: name,
            ...rest,
          })),
        };

        return item;
      }),
    [availableRoutes, menuCategory]
  );

  const { height } = useWindowDimensions();

  if (isEmpty(user) || menuCategory === 'fleetTracking') return null;

  return (
    <div className="Sidebar">
      <div className="Sidebar__navigationContainer">
        <ScrollBar autoHeight autoHeightMin={height - 120}>
          {!routeTreeItems ||
            (routeTreeItems.length < 1 && <div className="noItems">No Items to Show</div>)}
          {['administration', 'reports'].includes(menuCategory) && routeTreeItems.length > 0 && (
            <ItemManagerTree items={routeTreeItems} isExpanded />
          )}
        </ScrollBar>
      </div>
      <div className="Sidebar__footer">
        <Clickable
          className="Sidebar__footer--profile"
          disabled={isGuest}
          onClick={() => {
            setMenu({ category: 'administration', option: '' });
            window.location.replace('/#/profile');
          }}
        >
          <Icon icon="account_circle" />
          <span>{user && user.fullName}</span>
        </Clickable>
        <Menu
          className="Sidebar__footerMenu"
          icon="more_vert"
          items={[
            {
              label: 'Help',
              icon: 'help',
              onClick: () => {
                window.open('http://navistream.stti.ca/en/support/home');
              },
            },
            {
              label: 'Settings',
              icon: 'settings',
              disabled: !isSystem,
              onClick: () => {
                setMenu({ category: 'administration', option: '' });
                window.location.replace('/#/systemSettings');
              },
            },
            {
              label: 'Signout',
              icon: 'logout',
              onClick: () => exitRoute.logout(),
            },
          ]}
        />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    memberships: PropTypes.array,
  }),
  availableRoutes: PropTypes.array.isRequired,
  menuCategory: PropTypes.string,
  setMenu: PropTypes.func,
};
