import { columnDef } from '../../../../commons/ControlledDataGrid';

export const makeColumnDefs = (isSystem) => [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Application',
    minWidth: 100,
    enableRowGroup: false,
    cellRenderer: 'Application',
  }),
  columnDef.numberColumn({
    field: 'version',
    headerName: 'Build Number',
    enableRowGroup: false,
    minWidth: 100,
  }),
  columnDef.textColumn({
    hide: !isSystem,
    field: 'fileType',
    headerName: 'File Type',
    minWidth: 100,
  }),
  columnDef.textColumn({
    field: 'releaseChannels',
    headerName: 'Release Channels',
    autoHeight: true,
    enableRowGroup: false,
    cellRenderer: 'ReleaseChannelsList',
    sortable: false,
  }),
  columnDef.textColumn({
    hide: !isSystem,
    field: 'tenants',
    headerName: 'Tenants',
    autoHeight: true,
    enableRowGroup: false,
    cellRenderer: 'TenantsList',
    sortable: false,
  }),
  columnDef.textColumn({
    field: 'description',
    enableRowGroup: false,
    headerName: 'Description',
    minWidth: 100,
  }),
  columnDef.actionButtonsColumn({
    hide: !isSystem,
    cellRenderer: 'Actions',
  }),
];
