import { filter } from 'lodash';

import { dateWithoutTime } from '../../helpers/moment';
import { makeOneTimeOptimizedAction, makeRestApiAction } from '../apiActionSupport';
import { ACTION_TYPE } from './performancesReducer';
import {
  selectPerformancesFetchRequests,
  selectPerformanceDetailsFetchRequests,
} from './performancesSelectors';

import {
  transformPerformancesForStore,
  transformPerformanceDetailForStore,
} from './performancesSupport';

const fetchPerformances = makeRestApiAction({
  service: 'performances',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    ouKey,
    start: dateWithoutTime(startDate),
    end: dateWithoutTime(endDate),
  }),
  transformOutput: transformPerformancesForStore,
  baseActionType: ACTION_TYPE.fetchPerformances,
  notificationsItemDescriptor: 'performance',
});

export const fetchPerformancesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectPerformancesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchPerformances,
  baseActionType: ACTION_TYPE.fetchPerformances,
});

export const fetchPerformanceDetails = makeRestApiAction({
  service: 'performanceDetails',
  method: 'get',
  getId: (id) => id,
  transformInput: () => ({
    includeDeletedRules: false,
  }),
  transformOutput: transformPerformanceDetailForStore,
  baseActionType: ACTION_TYPE.fetchPerformanceDetails,
  notificationsItemDescriptor: 'performance',
});

export const fetchPerformanceDetailsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectPerformanceDetailsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchPerformanceDetails,
  baseActionType: ACTION_TYPE.fetchPerformanceDetails,
});

export const fetchMyPerformances = makeRestApiAction({
  service: 'myPerformances',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    ouKey,
    start: dateWithoutTime(startDate),
    end: dateWithoutTime(endDate),
  }),
  transformOutput: transformPerformancesForStore,
  baseActionType: ACTION_TYPE.fetchMyPerformances,
  notificationsItemDescriptor: 'performance',
});
