import React from 'react';
import PropTypes from 'prop-types';
import { ToggleInput, Chip } from 'stti-react-common';

import { OutlierFilterInput } from '../OutlierFilterInput';
import { OutlierFiltersDialogInput } from '../OutlierFiltersDialogInput';
import { ReportControl } from '../ReportControl';
import {
  filterRowsByPredicate,
  filterRowsByUnitRange,
  filterRowsByBaselineRange,
  invertFilteredRows,
} from '../../../../helpers/reports/standardFilters';

export const TripOutlierFilters = ({ baseClassName }) => (
  <OutlierFiltersDialogInput dialogClassName={`${baseClassName}__outlierFiltersDialog`}>
    <h4 style={{ gridArea: 'hideLocationsHeading' }}>Hide based on location</h4>
    <ReportControl
      name="hideWhereOriginNotEqualToLastUnload"
      label="If origin differs from last unload location"
      group={['filters', 'outliers']}
      render={(props) => <ToggleInput {...props} />}
      canDeleteSummary
      renderSummary={(props, { value }) =>
        value && <Chip {...props} label="Hide: Origin ≠ Last Unload" />
      }
      reducerCallback={(draft, value) => {
        if (value) draft.controls.hideWhereOriginEqualToLastUnload = false;
      }}
      filterConfig={{
        group: 'outlier',
        type: filterRowsByPredicate,
        test: ({ row }) => row.origin.name === row.lastUnload.name,
        disabled: ({ value }) => !value,
      }}
      printConfig={({ value }) => ({
        key: 'outliers',
        label: 'Additional Filters',
        value: value && 'Hide: Origin ≠ Last Unload',
      })}
      autoGridArea
    />
    <ReportControl
      name="hideWhereOriginEqualToLastUnload"
      label="If origin equals last unload location"
      group={['filters', 'outliers']}
      render={(props) => <ToggleInput {...props} />}
      canDeleteSummary
      renderSummary={(props, { value }) =>
        value && <Chip {...props} label="Hide: Origin = Last Unload" />
      }
      reducerCallback={(draft, value) => {
        if (value) draft.controls.hideWhereOriginNotEqualToLastUnload = false;
      }}
      filterConfig={{
        group: 'outlier',
        type: filterRowsByPredicate,
        test: ({ row }) => row.origin.name !== row.lastUnload.name,
        disabled: ({ value }) => !value,
      }}
      printConfig={({ value }) => ({
        key: 'outliers',
        label: 'Additional Filters',
        value: value && 'Hide: Origin = Last Unload',
      })}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideDurationHeading' }}>Hide duration</h4>
    <OutlierFilterInput
      name="hideDurationLessThan"
      label="less than"
      summaryPrefix="Hide: Duration < "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.duration',
        type: filterRowsByUnitRange,
        unit: 'h',
        unitType: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideDurationLessThanX,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideDurationGreaterThan"
      label="greater than"
      summaryPrefix="Hide: Duration > "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.duration',
        type: filterRowsByUnitRange,
        unit: 'h',
        unitType: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({ max: controls.hideDurationGreaterThanX }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideRelativeDurationLessThan"
      label="less than"
      summaryPrefix="Hide: Duration < "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.duration',
        type: filterRowsByBaselineRange,
        unitType: 'relative',
        disabled: ({ value }) => !value,
        getValues: ({ controls, filteredRows }) => ({
          min: controls.hideRelativeDurationLessThanX,
          baselineRows: filteredRows.resource,
        }),
      }}
      paramDefaultValue={80}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideRelativeDurationGreaterThan"
      label="greater than"
      summaryPrefix="Hide: Duration > "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.duration',
        type: filterRowsByBaselineRange,
        unitType: 'relative',
        disabled: ({ value }) => !value,
        getValues: ({ controls, filteredRows }) => ({
          max: controls.hideRelativeDurationGreaterThanX,
          baselineRows: filteredRows.resource,
        }),
      }}
      paramDefaultValue={120}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideDistanceHeading' }}>Hide distance</h4>
    <OutlierFilterInput
      name="hideDistanceLessThan"
      label="less than"
      summaryPrefix="Hide: Distance < "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.distance',
        type: filterRowsByUnitRange,
        unitType: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          min: controls.hideDistanceLessThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideDistanceGreaterThan"
      label="greater than"
      summaryPrefix="Hide: Distance > "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.distance',
        type: filterRowsByUnitRange,
        unitType: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          max: controls.hideDistanceGreaterThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideRelativeDistanceLessThan"
      label="less than"
      summaryPrefix="Hide: Distance < "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.distance',
        type: filterRowsByBaselineRange,
        unitType: 'relative',
        disabled: ({ value }) => !value,
        getValues: ({ controls, filteredRows }) => ({
          min: controls.hideRelativeDistanceLessThanX,
          baselineRows: filteredRows.resource,
        }),
      }}
      paramDefaultValue={80}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideRelativeDistanceGreaterThan"
      label="greater than"
      summaryPrefix="Hide: Distance > "
      filterConfig={{
        group: 'outlier',
        field: 'fullCycle.distance',
        type: filterRowsByBaselineRange,
        unitType: 'relative',
        disabled: ({ value }) => !value,
        getValues: ({ controls, filteredRows }) => ({
          max: controls.hideRelativeDistanceGreaterThanX,
          baselineRows: filteredRows.resource,
        }),
      }}
      paramDefaultValue={120}
      autoGridArea
    />
    <h4 style={{ gridArea: 'invertOutliersHeading' }}>Outlier detection</h4>
    <ReportControl
      name="invertOutliers"
      label="Invert results of hide filters (show the hidden rows instead)"
      group={['filters', 'outliers']}
      render={(props) => <ToggleInput {...props} />}
      canDeleteSummary
      renderSummary={(props, { value }) => value && <Chip {...props} label="Invert hide filters" />}
      filterConfig={{
        group: 'invertOutlier',
        type: invertFilteredRows,
        disabled: ({ value }) => !value,
        getValues: ({ filteredRows }) => ({
          baselineRows: filteredRows.resource,
        }),
      }}
      printConfig={({ value }) => ({
        key: 'outliers',
        label: 'Additional Filters',
        value: value && 'Invert hide filters',
      })}
      autoGridArea
    />
  </OutlierFiltersDialogInput>
);

TripOutlierFilters.propTypes = {
  baseClassName: PropTypes.string.isRequired,
};
