import React from 'react';

import { RoleSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { FEATURES } from '../../../../features';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const roleSummary = configureRoute({
  name: 'Roles',
  group: administrationGroups.resources,
  order: 3,
  path: '/roles',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <RoleSummaryContainer {...props} />,
  hidden: FEATURES.admin.hideRolesFromMainMenu,
});
