import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, useInstance } from 'stti-react-common';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  ControlsTablePrint,
  FetchBreadcrumbs,
  FetchReportDetail,
  FetchPlaceholder,
  SignalWebclientViewReady,
} from '../../../commons/ReportsCommon';

import { EditExceptionAnnotationDialog } from './EditExceptionAnnotationDialog';
import { PrintLayout } from '../../../commons/PrintLayout';
import { NaviMap } from '../../../commons/NaviMap';
import { QaDataGrid } from '../../../commons/QaTools';
import { DetailPanel } from './DetailPanel';
import { VideoPanel } from './VideoPanel';
import { AnnotationPanel } from './AnnotationPanel';
import { exceptionTypes } from '../../../../helpers/reports/exceptionTypes';
import { ExceptionMapContents } from './ExceptionMapContents';
import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';

import './ExceptionDetailReport.scss';

const requestServices = ['trips', 'exceptions', 'breadcrumbs', 'refinedBreadcrumbs', 'annotations'];

export const ExceptionDetailReport = ({
  breadcrumbsByVehicleKey,
  reportViewId,
  route,
  exceptionType,
  description,
  exception,
  tripId,
  exceptionId,
  createAnnotation,
  updateAnnotation,
  fetchTrip,
  fetchException,
  fetchAnnotations,
  fetchVideoEventFileLocation,
}) => {
  const instance = useInstance({
    isInitalFocusSet: false,
  });

  const report = useReportController({
    reportType: 'exceptionDetail',
    reportTypeName: 'Exception Detail',
    reportViewId,
    // defaultState,
    filtersConfig: {
      input: [],
      chain: [],
    },
    route,
  });

  const { reportType } = report;

  const { annotation, trip } = exception || {};

  const handleEditAnnotation = () => setIsEditingAnnotation(true);
  const [isEditingAnnotation, setIsEditingAnnotation] = useState(false);
  const [canEditAnnotation, setCanEditAnnotation] = useState(!!annotation);

  const isTripException = !!exceptionType.match(/^trip/);
  const isVideoException = exceptionType === 'eventVideoEvent';

  // Annotations

  const handleCreateAnnotation = async ({ comment }) => {
    setCanEditAnnotation(false);
    const relatingProperties = isTripException ? { tripId, exceptionType } : { exceptionId };
    try {
      await createAnnotation({ ...relatingProperties, comment });
    } finally {
      setCanEditAnnotation(true);
    }
  };

  const handleUpdateAnnotation = async ({ comment }) => {
    setCanEditAnnotation(false);
    try {
      await updateAnnotation({ id: annotation.id, comment });
    } finally {
      setCanEditAnnotation(true);
    }
  };

  const startAtUtc = exception && exception.startedAtUtc;
  const endAtUtc = exception && exception.endedAtUtc;
  const vehicleKey = exception && exception.vehicle.key;

  const breadcrumbs = useMemo(
    () => sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startAtUtc, endAtUtc),
    [breadcrumbsByVehicleKey[vehicleKey], startAtUtc, endAtUtc]
  );

  // Map

  const [focus, setFocus] = useState();

  useEffect(() => {
    // new target
    if (instance.isInitalFocusSet) {
      // focus was set, clear it
      setFocus(undefined);
      instance.isInitalFocusSet = false;
    }
  }, [exceptionType, tripId, exceptionId, description]);

  useEffect(() => {
    if (instance.isInitalFocusSet || (isTripException && !trip) || (!isTripException && !exception))
      return; // not ready

    // set initial focus to target
    if (isTripException) {
      setFocus([trip.origin, trip.firstLoad, trip.lastUnload]); //
    } else if (isVideoException) {
      setFocus(exception.startLocation);
    } else {
      setFocus([exception.startLocation, exception.endLocation]);
    }
    instance.isInitalFocusSet = true;
  }, [isTripException, trip, exception]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {tripId && !trip && <FetchReportDetail action={fetchTrip} args={tripId} />}
      {exceptionId && !exception && (
        <FetchReportDetail action={fetchException} args={exceptionId} />
      )}
      <FetchReportDetail
        action={fetchAnnotations}
        args={{ exceptionId, tripId }}
        onFetch={() => setCanEditAnnotation(false)}
        onResolve={() => setCanEditAnnotation(true)}
      />
      <SignalWebclientViewReady />
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
      </PrintLayout>
      <div className="ExceptionDetailReport">
        <ReportFragment.Header
          reportType="exceptionDetail"
          route={route}
          hideExport
          services={requestServices}
        />
        <DetailPanel
          exceptionType={exceptionType}
          description={description}
          exception={exception}
        />
        <AnnotationPanel
          exception={exception}
          canEditAnnotation={canEditAnnotation}
          onEditAnnotation={handleEditAnnotation}
        />
        {isVideoException && (
          <VideoPanel exception={exception} fetchVideoAction={fetchVideoEventFileLocation} />
        )}
        <ExpansionPanel className="ReportControl--mapPanel" title="Map" isLocked />
        {!focus && <FetchPlaceholder />}
        {focus && (
          <NaviMap focus={focus} printLayoutMode={reportType}>
            <FetchBreadcrumbs startAt={startAtUtc} endAt={endAtUtc} vehicleKey={vehicleKey} />
            <ExceptionMapContents exception={exception} breadcrumbs={breadcrumbs} />
          </NaviMap>
        )}
        <QaDataGrid data={breadcrumbs} onRowClick={setFocus} />
      </div>
      <EditExceptionAnnotationDialog
        isOpen={isEditingAnnotation}
        comment={annotation && annotation.comment}
        onClose={() => setIsEditingAnnotation(false)}
        onConfirm={annotation ? handleUpdateAnnotation : handleCreateAnnotation}
      />
    </ReportProvider>
  );
};

ExceptionDetailReport.propTypes = {
  breadcrumbsByVehicleKey: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  exceptionType: PropTypes.oneOf(exceptionTypes.map(({ type }) => type)).isRequired, // is 'tripSomething' or 'eventSomething'
  description: PropTypes.string.isRequired,
  exception: PropTypes.object,
  tripId: PropTypes.string, // UUID, or null if event-based exception
  exceptionId: PropTypes.string, // UUID, or null if trip-based exception
  createAnnotation: PropTypes.func.isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  fetchTrip: PropTypes.func.isRequired,
  fetchException: PropTypes.func.isRequired,
  fetchAnnotations: PropTypes.func.isRequired,
  fetchVideoEventFileLocation: PropTypes.func.isRequired,
};
