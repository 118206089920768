import { omit, isNil, filter } from 'lodash';
import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import {
  scrubIsSystemProperty,
  appendIsSystemProperty,
  removeDeleteParams,
} from '../../../../helpers/admin/adminActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectVehicleTypesFetchRequests } from './selectors';

const fetchVehicleTypes = makeRestApiAction({
  service: 'assetTypes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVehicleTypes,
  transformOutput: (responseData) => responseData.map(appendIsSystemProperty),
  notificationsItemDescriptor: 'vehicle types',
});

export const fetchVehicleTypesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVehicleTypesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVehicleTypes,
  baseActionType: ACTION_TYPE.fetchVehicleTypes,
});

const fetchVehicleType = makeRestApiAction({
  service: 'assetTypes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVehicleType,
  transformOutput: appendIsSystemProperty,
  getId: (key) => key,
  notificationsItemDescriptor: 'vehicle type',
});

export const fetchVehicleTypeOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVehicleTypesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVehicleType,
  baseActionType: ACTION_TYPE.fetchVehicleType,
});

export const createVehicleType = makeRestApiAction({
  service: 'assetTypes',
  method: 'post',
  baseActionType: ACTION_TYPE.createVehicleType,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => appendIsSystemProperty(responseData),
  notificationsItemDescriptor: 'vehicle type',
  throwApiErrors: true,
});

export const updateVehicleType = makeRestApiAction({
  service: 'assetTypes',
  method: 'put',
  baseActionType: ACTION_TYPE.updateVehicleType,
  getId: ({ key }) => key,
  transformInput: (data) => omit(scrubIsSystemProperty(data), isNil),
  transformOutput: appendIsSystemProperty,
  notificationsItemDescriptor: 'vehicle type',
  throwApiErrors: true,
});

export const deleteVehicleType = makeRestApiAction({
  service: 'assetTypes',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteVehicleType,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'vehicle type',
});
