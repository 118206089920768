import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isNil, startCase } from 'lodash';

import { Button, Form, ProgressOverlay, SelectInput, TextInput } from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import './config.scss';
import { AssignCameras } from '../../../../commons/AssignCameras/AssignCameras';

export const TenantConfig = ({
  companionApps,
  fetchCompanionApps,
  fetchTenant,
  fetchVideoConfigs,
  fetchVideoConfigsByTenant,
  mode: initialMode = 'view',
  postAddCameras,
  route,
  tenant,
  tenantId,
  updateTenantConfiguration,
  updateTenantVideoConfigs,
  videoConfigs,
}) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      Promise.all([
        fetchCompanionApps(tenantId),
        fetchTenant(tenantId),
        fetchVideoConfigsByTenant(tenantId),
      ]);
    } else {
      setLoading(false);
    }
  }, [initialMode, tenantId]);

  useEffect(() => {
    if (tenant && companionApps) {
      resetControls();
      setLoading(false);
    }
  }, [tenant, companionApps]);

  useEffect(() => {
    fetchVideoConfigs();
  }, []);

  const { useFormController, Control } = Form;

  const {
    ecmState,
    enableCti,
    enableCumminsEngineDiagnostics,
    enableDispatch,
    showMessagesWhileDriving,
    name,
  } = tenant || {};

  const form = useFormController({
    controls: {
      tenantKey: tenantId,
      ecmState,
      enableCti,
      enableCumminsEngineDiagnostics,
      enableDispatch,
      showMessagesWhileDriving,
      appKeys: companionApps
        .filter((companionApp) => companionApp.available)
        .map((companionApp) => companionApp.key),
      ...tenant,
    },
  });

  const { controls: formControls, resetControls } = form;

  const updateAllTenantConfigurations = useCallback(
    (data) => Promise.all([updateTenantConfiguration(data), updateTenantVideoConfigs(data)]),
    [updateTenantConfiguration, updateTenantVideoConfigs]
  );

  const getProviderDefinitions = (provider) => provider && provider.toLowerCase() === 'surfsight';
  return (
    <div className="TenantConfiguration">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Configure Features"
          form={form}
          itemTitle={name || ''}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formControls}
          onClose={route.close}
          updateItem={updateAllTenantConfigurations}
          resetControls={resetControls}
        >
          <ErrorSummary errorMsg={apiError} />
          <div className="TenantAdminDetail__controlsContainer">
            <Control
              Component={SelectInput}
              name="ecmState"
              label="ECM Feature"
              defaultValue="off"
              error={({ value }) => !value}
              options={[
                { label: 'Off', value: 'Off' },
                { label: 'Read Only', value: 'ReadOnly' },
                { label: 'Read / Write', value: 'ReadWrite' },
              ]}
            />
            <Control
              Component={SelectInput}
              name="enableDispatch"
              label="Dispatch Enabled"
              defaultValue={false}
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Enabled', value: true },
                { label: 'Disabled', value: false },
              ]}
            />
            <Control
              Component={SelectInput}
              name="enableCumminsEngineDiagnostics"
              label="Cummins Engine Diagnostics Enabled"
              defaultValue={false}
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Enabled', value: true },
                { label: 'Disabled', value: false },
              ]}
            />
            <Control
              Component={SelectInput}
              name="enableCti"
              label="CTI Feature"
              defaultValue={false}
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Enabled', value: true },
                { label: 'Disabled', value: false },
              ]}
            />
            <Control
              Component={SelectInput}
              name="showMessagesWhileDriving"
              label="Show Messages While Driving"
              defaultValue={false}
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Enabled', value: true },
                { label: 'Disabled', value: false },
              ]}
            />
            <Control
              Component={SelectInput}
              multiple
              multiline
              name="appKeys"
              label="Companion Apps"
              error={({ value }) => !value}
              options={objectsToSelectOptions(companionApps, 'name', 'key')}
            />
            <Control
              Component={SelectInput}
              name="enabledVideoProviders"
              label="Video Providers"
              multiple
              error={({ value }) => isNil(value)}
              options={videoConfigs.map(({ provider }) => provider)}
            />
          </div>
          <div className="TenantAdminDetail__videoConfigControlsContainer">
            {videoConfigs
              .filter(
                ({ provider }) =>
                  formControls.enabledVideoProviders &&
                  formControls.enabledVideoProviders.includes(provider)
              )
              .map(({ provider, params }) => (
                <div key={provider}>
                  <h2>{provider}</h2>
                  <div
                    className="TenantAdminDetail__videoConfigControls"
                    style={{
                      gridTemplateColumns: `repeat(${
                        getProviderDefinitions(provider) ? params.length + 1 : params.length
                      }, 1fr)`,
                    }}
                    key={provider}
                  >
                    {params.map(({ key }) => (
                      <Control
                        key={key}
                        Component={TextInput}
                        name={`${provider}-${key}`}
                        label={startCase(key)}
                        error={({ value }) => !value || isNil(value)}
                        readOnly={mode === 'view'}
                      />
                    ))}
                    {getProviderDefinitions(provider) && (
                      <Button
                        icon="videocam"
                        variant="outlined"
                        label="Assign Cameras"
                        disabled={mode === 'view'}
                        onClick={() => setIsOpen(true)}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
          <AssignCameras
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            postAddCameras={postAddCameras}
            tenantKey={tenantId}
          />
        </CrudAdminItem>
      )}
    </div>
  );
};

TenantConfig.propTypes = {
  tenantId: PropTypes.string,
  tenant: customPropTypes.tenant,
  companionApps: PropTypes.array,
  fetchTenant: PropTypes.func.isRequired,
  fetchCompanionApps: PropTypes.func.isRequired,
  fetchVideoConfigsByTenant: PropTypes.func.isRequired,
  updateTenantConfiguration: PropTypes.func.isRequired,
  updateTenantVideoConfigs: PropTypes.func.isRequired,
  fetchVideoConfigs: PropTypes.func.isRequired,
  videoConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.string.isRequired,
      params: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  mode: PropTypes.oneOf(['view', 'edit']),
  route: PropTypes.object.isRequired,
  postAddCameras: PropTypes.func.isRequired,
};
