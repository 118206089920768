import { combineReducers } from 'redux';

import { activationCode } from './activationCodes';
import { camera } from './cameras';
import { tablet } from './tablets';
import { ecmReport } from './ecmReports';

export const devices = combineReducers({
  activationCodes: activationCode.reducer.activationCodesReducer,
  cameras: camera.reducer.cameraReducer,
  tablets: tablet.reducer.tabletReducer,
  ecmReports: ecmReport.reducer.ecmReportReducer,
});

export { activationCode, camera, tablet, ecmReport };
