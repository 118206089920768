import React from 'react';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import { countUniqueOfField } from '../../../../helpers/reports/aggregation';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => (
      <div {...props}>
        {countUniqueOfField(rows, 'vehicle.key')} vehicles, {countUniqueOfField(rows, 'driver.key')}{' '}
        drivers, {countUniqueOfField(rows, 'id')} trips
      </div>
    )}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: customPropTypes.fuelConsumptions.isRequired,
};
