import React from 'react';

import { TabletSummaryContainer } from './Summary/connect';
import { TabletDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, ADMIN_ROLE_KEY } from '../../../../config';

// EXTERNAL ROUTES
import { activationCodesSummary } from '../ActivationCodes/routes';
import { vehicleDetail } from '../../resources/Vehicles/routes';

export const tabletSummary = configureRoute({
  name: 'Tablets',
  group: administrationGroups.devices,
  order: 3,
  path: '/tablets',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <TabletSummaryContainer
      {...props}
      openTabletDetail={tabletDetail.open}
      openAssetDetail={vehicleDetail.open}
      openActivationCodes={activationCodesSummary.open}
    />
  ),
});

export const tabletDetail = configureRoute({
  path: '/tablet/:tabletId',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <TabletDetailContainer {...props} />,
});
