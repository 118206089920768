import { columnDef } from '../../../../../commons/ControlledDataGrid';

export const commentsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date',
  }),
  columnDef.textColumn({
    field: 'originator.name',
    headerName: 'Originator',
  }),
  columnDef.textColumn({
    field: 'annotation',
    headerName: 'Comment',
  }),
  columnDef.actionButtonsColumn({
    tableCellRenderer: 'Actions',
  }),
];
