import moment from 'moment-timezone';
import { columnDef } from '../../../../../commons/ControlledDataGrid';

import { timeInMillisecondsToDecimal } from '../../../../../../helpers/moment';
import { metersToKilometersMilesConverter } from '../../../../../../helpers/formats';

const engineStateToEventName = (data, engineState) => {
  const { startOfDayRecord } = data;
  if (startOfDayRecord) {
    return '';
  }
  switch (engineState) {
    case 'ON':
      return 'Power Up';
    case 'OFF':
      return 'Shut Down';

    default:
      return engineState;
  }
};

export const enginePowerEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date & Time',
    valueGetter: ({ data: { date, startOfDayRecord } }) => {
      if (startOfDayRecord) return moment(date).format('YYYY-MM-DD');
      return moment(date).format('HH:mm:ss');
    },
    width: 70,
  }),
  columnDef.textColumn({
    field: 'engineState',
    headerName: 'Event',
    valueFormatter: ({ data, value }) => engineStateToEventName(data, value),
  }),
  columnDef.textColumn({
    field: 'geoLocation',
    headerName: 'Geo-Location',
    valueFormatter: ({ data: { geoLocation } }) => geoLocation || '',
  }),
  columnDef.textColumn({
    field: 'latitude',
    headerName: 'Latitude',
    valueFormatter: ({ data: { location } }) => {
      if (!location || !location.latitude) return '';
      return location.latitude.toFixed(2);
    },
  }),
  columnDef.textColumn({
    field: 'longitude',
    headerName: 'Longitude',
    valueFormatter: ({ data: { location } }) => {
      if (!location || !location.longitude) return '';
      return location.longitude.toFixed(2);
    },
  }),
  columnDef.distanceColumn({
    field: 'distanceSinceLastGps',
    headerName: 'Distance last val. coord.',
    valueFormatter: ({ data: { distanceSinceLastGps, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return distanceSinceLastGps
        ? metersToKilometersMilesConverter(distanceSinceLastGps)
        : '0 km / 0 mi';
    },
  }),
  columnDef.textColumn({
    field: 'asset',
    headerName: 'CMV',
    valueFormatter: ({ data: { asset, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return asset;
    },
  }),
  columnDef.distanceColumn({
    field: 'totalVehicleDistance',
    headerName: 'Distance (Total)',
    valueFormatter: ({ data: { totalVehicleDistance, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return totalVehicleDistance ? metersToKilometersMilesConverter(totalVehicleDistance) : '';
    },
  }),
  columnDef.durationColumn({
    field: 'totalEngineHours',
    headerName: 'Hours (Total)',
    valueFormatter: ({ data: { totalEngineHours, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      // totalEngineHours is given by BE in seconds, so we need to convert it to milliseconds
      return timeInMillisecondsToDecimal(totalEngineHours * 1000);
    },
  }),
  columnDef.textColumn({
    field: 'eldSequence',
    headerName: 'Seq. ID',
    valueFormatter: ({ data: { eldSequence, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return `0000${Number(eldSequence).toString(16)}`.slice(-4).toUpperCase();
    },
  }),
];
