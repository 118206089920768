import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isArray, times, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import {
  BasicDialog,
  TextInput,
  SelectInput,
  DateInput,
  ToggleInput,
  Form,
} from 'stti-react-common';

import './SendReportDialog.scss';

const { useFormController, Control } = Form;

const hoursOptions = times(24, (h) => ({
  value: h,
  label: moment().startOf('day').add(h, 'hours').format('h:mm A'),
}));

const orientationOptions = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];

export const SendReportDialog = ({
  mode,
  users,
  peerUsers,
  userEmail,
  isOpen,
  onClose,
  onConfirm,
  isReportDirty,
  isReportPersisted,
  canIncludeDetail,
  includeDetailLimitExceededNotice,
  hideExportOptions,
  fetchUsers,
  reportViews,
  onReportSave,
  EditReportMetaDialog,
  snapshotReport,
  activeOu,
  ...restProps
}) => {
  const isScheduleMode = mode === 'schedule';
  const confirmButtonVerb = isScheduleMode ? 'Save and Schedule' : 'Send';

  const userEmails = useMemo(
    () =>
      peerUsers
        .map((user) => ({
          label: `${user.firstName} ${user.lastName} - ${user.email}`,
          value: user.email,
        }))
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })),
    [users]
  );

  const reports = useMemo(() => {
    if (isArray(reportViews)) {
      const mappedArray = reportViews.map(({ title }) => ({ value: title, label: title }));
      mappedArray.unshift({ value: 'default', label: 'default' });
      return uniqBy(mappedArray, 'value');
    }
    return [{ value: 'default', label: 'default' }];
  }, [reportViews]);

  useEffect(() => {
    fetchUsers();
  }, [users]);

  const form = useFormController();

  const { resetControls, controls, hasErrors } = form;

  const attachOptions = [{ value: 'pdf', label: 'PDF' }];

  if (!hideExportOptions) attachOptions.push({ value: 'pdf,csv', label: 'PDF and CSV' }); // XLSX support deferred

  return (
    <BasicDialog
      title={isScheduleMode ? 'Create New Report Schedule' : 'Email Report'}
      className={`SendReportDialog SendReportDialog--${mode}`}
      maxWidth="sm"
      fullWidth
      isOpen={isOpen}
      onOpen={resetControls}
      onClose={onClose}
      buttons={[
        { label: 'Close', onClick: onClose },
        {
          label: confirmButtonVerb,
          onClick: () => {
            onConfirm(controls);
            onClose();
          },
          disabled: hasErrors || !isReportPersisted,
        },
      ]}
      {...restProps}
    >
      <Form form={form} disabled={!isReportPersisted}>
        <Control
          name="toEmails"
          Component={SelectInput}
          multiple
          multiline
          addNew
          label="Deliver to"
          error={({ value }) => value.length === 0}
          defaultValue={[userEmail]}
          options={userEmails}
          autoGridArea
        />
        {isScheduleMode && (
          <>
            <Control
              name="frequency"
              Component={TextInput}
              label="Every"
              value="Day"
              disabled
              autoGridArea
            />
            <Control
              name="hour"
              Component={SelectInput}
              label="At"
              options={hoursOptions}
              defaultValue={Number(moment().format('H'))}
              autoGridArea
            />
            <Control
              name="endDate"
              Component={DateInput}
              label="Until"
              min={moment().format('YYYY-MM-DD')}
              error={({ value }) => !value && 'Until date must be provided.'}
              defaultValue={moment().add(2, 'years').format('YYYY-MM-DD')}
              autoGridArea
            />
          </>
        )}
        <Control
          name="attach"
          Component={SelectInput}
          label="File attachment"
          options={attachOptions}
          defaultValue="pdf"
          disabled={attachOptions.length === 1}
          autoGridArea
        />
        <Control
          name="orientation"
          Component={SelectInput}
          label="PDF orientation"
          options={orientationOptions}
          defaultValue="landscape"
          autoGridArea
        />
        {canIncludeDetail && (
          <Control
            name="includeDetail"
            Component={ToggleInput}
            label="Include Detail Reports"
            defaultValue={false}
            autoGridArea
            disabled={!!includeDetailLimitExceededNotice}
          />
        )}
        <div className="SendReportDialog__notices" style={{ gridArea: 'notices' }}>
          {includeDetailLimitExceededNotice}
          {isReportDirty && isReportPersisted && isScheduleMode && (
            <div>
              <em>Report has been modified and will be saved before scheduling.</em>
            </div>
          )}
          {!isScheduleMode && (
            <div>
              <em>Report must be saved before it can be scheduled.</em>
            </div>
          )}
        </div>
        {reports && isScheduleMode && (
          <Control
            name="reportTypeSelect"
            Component={SelectInput}
            label="Save this report view as:"
            error={({ value }) => !value}
            options={reports}
            autoGridArea
          />
        )}
      </Form>
    </BasicDialog>
  );
};

SendReportDialog.propTypes = {
  mode: PropTypes.oneOf(['email', 'schedule']).isRequired,
  userEmail: PropTypes.string,
  users: PropTypes.array,
  peerUsers: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isReportDirty: PropTypes.bool.isRequired,
  isReportPersisted: PropTypes.bool.isRequired,
  canIncludeDetail: PropTypes.bool,
  includeDetailLimitExceededNotice: PropTypes.node,
  hideExportOptions: PropTypes.bool,
  fetchUsers: PropTypes.func,
  reportViews: PropTypes.array,
  fetchReportViewsByReportType: PropTypes.func,
  onReportSave: PropTypes.func,
  EditReportMetaDialog: PropTypes.node,
  snapshotReport: PropTypes.any,
  activeOu: PropTypes.any,
};
