/**
 * selectLocationTypes(state) -> [locationTypes]
 *
 * Selector for all locationTypes
 */
export const selectLocationTypes = (state) => state.administration.resources.locationTypes.data;

/**
 * selectLocationType(state, { locationTypeId }) -> locationType
 *
 * Selector for single locationType by key
 */
export const selectLocationType = (state, locationTypeId) =>
  state.administration.resources.locationTypes.data.find((item) => item.key === locationTypeId);

/**
 * selectLocationTypesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on locationTypes
 */
export const selectLocationTypesFetchRequests = (state) =>
  state.administration.resources.locationTypes.fetches;
