import { columnDef } from '../../../../commons/ControlledDataGrid';
import { getRuleTitle } from '../../../../../helpers/admin/performanceRules';

export const columnDefs = [
  columnDef.textColumn({
    field: 'key',
    headerName: 'Title',
    enableRowGroup: false,
    valueGetter: ({ data, context: { convertUnit } }) => getRuleTitle(data, convertUnit),
  }),
  columnDef.textColumn({
    field: 'description',
    enableRowGroup: false,
    headerName: 'Description',
  }),
  columnDef.textColumn({
    field: 'type',
    filter: 'agSetColumnFilter',
  }),
  columnDef.numberColumn({
    field: 'pointsPerSecond',
    headerName: 'PPM',
    enableRowGroup: false,
    cellRenderer: 'PointsPerMinute',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
