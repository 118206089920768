import { columnDef } from '../../../../commons/ControlledDataGrid';

export const makeColumnDefs = ({ enableCti }, canEditGeozones) => [
  columnDef.textColumn({
    field: 'name',
    width: 200,
  }),
  columnDef.textColumn({
    field: 'typeName',
    headerName: 'Type',
    width: 150,
  }),
  columnDef.speedColumn({
    field: 'speedLimit.limit',
    headerName: 'Speed Limit',
    width: 150,
    enableRowGroup: false,
  }),
  columnDef.durationColumn({
    field: 'speedLimit.gracePeriod',
    headerName: 'Grace Period',
    width: 150,
    enableRowGroup: false,
    overrideFormat: 's',
  }),
  columnDef.textColumn({
    hide: !enableCti,
    field: 'tirePressureConfig.limits',
    headerName: 'Tire Pressures',
    autoHeight: true,
    cellRenderer: 'Limits',
    width: 155,
    sortable: false,
    enableRowGroup: false,
  }),
  columnDef.distanceColumn({
    hide: !enableCti,
    field: 'tirePressureConfig.graceDistance',
    headerName: 'Grace Distance',
    enableRowGroup: false,
    width: 150,
  }),
  columnDef.textColumn({
    field: 'color',
    cellRenderer: 'Color',
    width: 200,
    enableRowGroup: false,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
    hide: !canEditGeozones,
  }),
];
