import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { castArray, isArray, noop, uniqueId } from 'lodash';
import { TextInput, ImageInput } from 'stti-react-common';

import { useReport } from '../useReport';

import './InfoField.scss';

const formatDisplayValue = (value, options, joinSymbol) => {
  const parsed = typeof value === 'number' ? value.toString() : value;
  if (options) {
    return parsed ? options[0] : options[1];
  }
  return isArray(parsed) ? parsed.join(joinSymbol) : parsed;
};

export const InfoField = ({
  name,
  label,
  value,
  controlsTableDef,
  chipLabels,
  joinSymbol = ', ',
  printOnly = false,
  isImage = false,
  conditionalCase,
  ...rest
}) => {
  const { setInfoFieldDef } = useReport();

  useEffect(
    () => {
      if (controlsTableDef === false) return;
      setInfoFieldDef(
        name,
        controlsTableDef || { name, label, value: formatDisplayValue(value, chipLabels) }
      );
    },
    controlsTableDef
      ? [controlsTableDef.name, controlsTableDef.label, controlsTableDef.value]
      : [name, label, value]
  );

  if (printOnly) {
    const values = castArray(value);

    return (
      <div className="Report__InfoField--print-only" style={{ gridArea: name }}>
        <p className="print-label">{label}</p>
        {values.map((row) => (
          <p key={uniqueId()} className="print-value">
            {row}
          </p>
        ))}
      </div>
    );
  }

  if (isImage) {
    return (
      <ImageInput
        readOnly
        label="Inspector Signature"
        name="inspectorSignature"
        value={value}
        onChange={noop}
      />
    );
  }

  if (conditionalCase) {
    return (
      <div className="Report__InfoField" style={{ gridArea: name }}>
        <p className="print-label">{label}</p>
        <p key={uniqueId()} className="print-value">
          {conditionalCase}
        </p>
      </div>
    );
  }

  return (
    <TextInput
      value={formatDisplayValue(value, chipLabels, joinSymbol)}
      onChange={noop}
      readOnly
      multiline
      className={
        chipLabels
          ? `Report__InfoField Report__InfoField--${value ? 'on' : 'off'}`
          : 'Report__InfoField'
      }
      style={{ gridArea: name }}
      label={label}
      {...rest}
    />
  );
};

InfoField.propTypes = {
  chipLabels: PropTypes.arrayOf(PropTypes.string),
  conditionalCase: PropTypes.node,
  controlsTableDef: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    PropTypes.oneOf([false]),
  ]),
  isImage: PropTypes.bool,
  joinSymbol: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  printOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
