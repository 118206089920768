import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { ExpansionPanel, Chip, SelectInput } from 'stti-react-common';

import { selectFilteredOus, selectFilteredOuMode, selectSystemOus } from '../../../../data/system';

import { OusSelector } from './OusSelector';

import './AdminFilterPanel.scss';

export const AdminFilterPanel = connect(
  (state) => ({
    ous: selectSystemOus(state),
    filteredOus: selectFilteredOus(state),
    filteredOuMode: selectFilteredOuMode(state),
  }),
  {}
)(({ children, filteredOus, filteredOuMode, ous }) => {
  const ouNames = ous
    .filter(({ key }) => includes(filteredOus, key))
    .map(({ key, name }) => ({ label: name, value: key }));

  const renderSummaries = () => {
    if (filteredOus.length === 0) return <Chip variant="outlined" label="Select organization(s)" />;
    if (filteredOuMode === 'all')
      return <Chip variant="default" color="primary" label="All organizations" />;
    if (filteredOuMode === 'default' || filteredOus.length === 1) {
      return (
        <Chip
          variant="default"
          color="primary"
          label={SelectInput.labelForValue(ouNames, filteredOus[0])}
        />
      );
    }
    return <Chip variant="default" color="primary" label={`${filteredOus.length} organizations`} />;
  };

  return (
    <div className="AdminFilterPanel">
      <ExpansionPanel name="filterPanel" title="Filter" summary={renderSummaries()}>
        <OusSelector />
        {children}
      </ExpansionPanel>
    </div>
  );
});

AdminFilterPanel.propTypes = {
  children: PropTypes.node,
};
