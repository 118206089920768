export const adminGroups = {
  inspections: {
    name: 'Inspections',
    order: 1,
    icon: 'find_in_page',
  },
  driverActivities: {
    name: 'Driver Activities',
    order: 2,
    icon: 'fact_check',
  },
  speedTracking: {
    name: 'Speed Tracking',
    order: 3,
    icon: 'speed',
  },
  administration: {
    name: 'Administration',
    order: 4,
    icon: 'admin_panel_settings',
  },
  dispatch: {
    name: 'Dispatch',
    order: 4,
    icon: 'contact_phone',
  },
};

export const reportGroups = {
  inspections: {
    name: 'Inspections',
    order: 1,
    icon: 'find_in_page',
  },
  driverLogs: {
    name: 'Driver Logs',
    order: 2,
    icon: 'assessment',
  },
  driverActivities: {
    name: 'Driver Activities',
    order: 3,
    icon: 'fact_check',
  },
  speedTracking: {
    name: 'Speed Tracking',
    order: 4,
    icon: 'speed',
  },
  exceptions: {
    name: 'Exceptions',
    order: 5,
    icon: 'privacy_tip',
  },
  fuelTracking: {
    name: 'Fuel Tracking',
    order: 6,
    icon: 'local_gas_station',
  },
  cycleTime: {
    name: 'Cycle Time',
    order: 7,
    icon: 'sync',
  },
  geozones: {
    name: 'Geo Zones',
    order: 8,
    icon: 'terrain',
  },
  scheduling: {
    name: 'Scheduling',
    order: 9,
    icon: 'schedule_send',
  },
};
