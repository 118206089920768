import produce from 'immer';

import { actionTypeProgressOrSuccess } from '../../../apiReducerSupport';

const initialState = {
  data: [],
};

export const ACTION_TYPE = {
  fetchEcmReports: 'administration/devices/ecmReports/fetchEcmReports',
};

export const ecmReportReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchEcmReports)) {
      draft.data = payload.data;
    }
  });
