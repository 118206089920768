import { cloneDeep, filter, isObject, isUndefined, merge } from 'lodash';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../helpers/moment';
import { makeRestApiAction, makeDateRangeOptimizedAction } from '../apiActionSupport';
import { ACTION_TYPE } from './shiftsReducer';
import { selectShiftsFetches } from './shiftsSelectors';
import { transformActivitiesForStore } from './activitiesSupport';

const transformShiftForStore = (shift) => {
  const transformedShift = cloneDeep(
    merge(
      { ...shift },
      {
        // ensure required properties (are null on incomplete shifts)
        trailers: [],
        vehicles: [],
        orders: [],
        fullCycle: {},
      }
    )
  );

  Object.assign(transformedShift, {
    ouKey: shift.ou.key,
    trailersNames: transformedShift.trailers.map((trailer) => trailer.name),
    vehiclesNames: transformedShift.vehicles.map((vehicle) => vehicle.name),
    distance: transformedShift.fullCycle.distance,
    duration: transformedShift.fullCycle.duration,
  });

  // add mileage in m/l
  Object.entries(transformedShift).forEach(([key, value]) => {
    if (!isObject(value)) return; // not a field group
    const { distance, consumedFuel } = value;
    if (isUndefined(distance) || isUndefined(consumedFuel)) return; // field group is not a leg
    transformedShift[key].mileage =
      distance > 0 && consumedFuel > 0 ? distance / consumedFuel : null; // mileage will be null if either field is 0 or null
  });

  return transformedShift;
};

// fetchShifts({ startDate, endDate, ouKey, timeZone })
const fetchShifts = makeRestApiAction({
  service: 'shifts',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchShifts,
  transformInput: ({ startDate, endDate, ouKey, timeZone, dateRangeFor, driverKey }) => ({
    ouKey,
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
    dateRangeFor,
    ...(driverKey && { driverKey }),
  }),
  transformOutput: (data) => data.map(transformShiftForStore),
  notificationsItemDescriptor: 'shifts',
  enableProgress: true,
});

// fetchShiftsOptimized({ startDate, endDate, ouKey, timeZone })
export const fetchShiftsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectShiftsFetches,
  filterFetches: (fetches, { ouKey, startDate, endDate, dateRangeFor }) =>
    filter(fetches, { ouKey, startDate, endDate, dateRangeFor }),
  baseActionType: ACTION_TYPE.fetchShifts,
  fetchAction: fetchShifts,
});

// fetchShift(id)
export const fetchShift = makeRestApiAction({
  service: 'shifts',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchShift,
  transformOutput: transformShiftForStore,
  notificationsItemDescriptor: 'shift',
});

// fetchShiftActivities({ shiftId })
export const fetchShiftActivities = makeRestApiAction({
  service: 'shiftActivities',
  method: 'get',
  transformInput: ({ shiftId: driverShiftId }) => ({ driverShiftId }),
  baseActionType: ACTION_TYPE.fetchShiftActivities,
  transformOutput: transformActivitiesForStore,
  notificationsItemDescriptor: 'shift details',
});
