import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Chip } from 'stti-react-common';
import { isNil, sortBy, uniqBy } from 'lodash';

import {
  ReportControl,
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  SelectFilterInput,
} from '../../../commons/ReportsCommon';
import { filterRowsByIntersection } from '../../../../helpers/reports/standardFilters';

const hasActivityOptions = [
  {
    label: 'With activity',
    value: true,
  },
  {
    label: 'Without activity',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const hasDutyStatusOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

export const FiltersPanel = ({ rows }) => {
  const vehicles = sortBy(
    uniqBy(
      rows.map((row) => row.vehicle),
      'key'
    ),
    'name'
  );
  const getOptions = useMemo(
    () => ({
      vehicleName: () => vehicles.map(({ key, name }) => ({ label: name, value: key })),
    }),
    [rows]
  );

  return (
    <SectionPanel
      name="filtersPanel"
      title="Log Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="vehicleName"
        field="vehicle.key"
        displayValue="vehicle.name"
        group="filters"
        filterGroup="filters"
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleName}
      />
      <ReportControl
        name="hasActivity"
        group="filters"
        label="Activity"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={hasActivityOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return (
            <Chip {...renderProps} label={SelectInput.labelForValue(hasActivityOptions, value)} />
          );
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(hasActivityOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'hasActivity',
        }}
      />
      <ReportControl
        name="hasDutyStatus"
        group="filters"
        label="Has Duty Status?"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={hasDutyStatusOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return <Chip {...renderProps} label={SelectInput.labelForValue(hasDutyStatusOptions, value)} />;
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(hasDutyStatusOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'hasDutyStatus',
        }}
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
