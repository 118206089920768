import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchTrips: 'reports/trips/fetchTrips',
  fetchTrip: 'reports/trips/fetchTrip',
};

const initialState = {
  trips: [],
  fetches: [],
};

export const tripsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchTrips, ACTION_TYPE.fetchTrip]))
      draft.trips = sortBy(shallowMergeEntities(state.trips, payload), 'startedAt');

    if (actionTypeRequest(type, ACTION_TYPE.fetchTrips)) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchTrips)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
