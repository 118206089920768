import { FEATURES } from '../../../../../features';
import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Tenant Name',
    minWidth: 180,
    enableRowGroup: false,
    sort: 'asc',
  }),
  columnDef.textColumn({
    field: 'tenantAdmin',
    headerName: 'Administrator Email',
    enableRowGroup: false,
    minWidth: 180,
  }),
  columnDef.textColumn({
    field: 'tenantAdminUsername',
    headerName: 'Administrator Username',
    enableRowGroup: false,
    minWidth: 180,
  }),
  columnDef.textColumn({
    field: 'ecmState',
    headerName: 'ECM feature state',
    minWidth: 120,
  }),
  columnDef.enabledDisabledColumn({
    field: 'personalUseDefault',
    headerName: 'Personal Use Default',
    cellRenderer: 'ToggleChip',
    hide: FEATURES.admin.hidePersonalUseFromTenant,
    minWidth: 180,
  }),
  columnDef.enabledDisabledColumn({
    field: 'yardMovesDefault',
    headerName: 'Yard Moves Default',
    cellRenderer: 'ToggleChip',
    hide: FEATURES.admin.hideYardMovesFromTenant,
    minWidth: 180,
  }),
  columnDef.numberColumn({
    field: 'licenseCount',
    headerName: 'License count',
    minWidth: 150,
  }),
  columnDef.numberColumn({
    field: 'deviceCount',
    headerName: 'Device count',
    enableRowGroup: false,
    minWidth: 150,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
    width: 138,
  }),
];
