import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy, keys, values, uniqueId, flatten, sortBy } from 'lodash';
import { Card } from 'stti-react-common';
import moment from 'moment-timezone';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { malfunctionAndDiagnosticEventsColumnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const MalfunctionAndDiagnosticEventsCard = ({ log, printLayoutMode }) => {
  const { eldEvents } = log || {};

  const malfunctionAndDiagnosticEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(
      ({ eventType }) =>
        eventType.includes('DIAGNOSTIC') ||
        eventType.includes('SESSION_START') ||
        eventType.includes('SESSION_END') ||
        eventType.includes('SIGNATURE')
    );
  }, [eldEvents]);

  const events = groupBy(malfunctionAndDiagnosticEvents, ({ date }) =>
    moment(date).format('YYYY-MM-DD')
  );

  const arr = [];
  keys(events).forEach((value, index) => {
    arr.push({
      key: uniqueId(),
      startOfDayRecord: true,
      eldSequence: index - 1,
      date: moment(value).startOf('day').toISOString(),
    });
    arr.push(values(events)[index]);
  });

  const remappedEvents = sortBy(flatten(arr), ['date', 'eldSequence']);

  const malfunctionAndDiagnosticEventsDataGridController = useDataGridController({
    columnDefs: malfunctionAndDiagnosticEventsColumnDefs,
    gridOptions,
  });

  const malfunctionAndDiagnosticEventsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  return (
    <Card fullWidth>
      <div>
        <h4>Login/Logout, Certification of RODS, Data Diagnostics and Malfunctions</h4>
      </div>
      {malfunctionAndDiagnosticEvents && (
        <ControlledDataTable
          controller={malfunctionAndDiagnosticEventsDataGridController}
          components={malfunctionAndDiagnosticEventsDataGridComponents}
          rows={remappedEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

MalfunctionAndDiagnosticEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  printLayoutMode: PropTypes.string,
};
