import React from 'react';
import PropTypes from 'prop-types';
import { ControlledDataGrid as CommonControlledDataGrid } from 'stti-react-common';
import { merge } from 'lodash';

import * as aggFuncs from './aggFuncs';
import { useFormats } from '../Formats';

import './ControlledDataGrid.scss';

export const ControlledDataGrid = (props) => {
  const { customContext } = props;
  const allFormatUtilities = useFormats();

  return (
    <CommonControlledDataGrid context={{ ...allFormatUtilities, ...customContext }} {...props} />
  );
};

Object.assign(ControlledDataGrid, CommonControlledDataGrid);

ControlledDataGrid.createState = (initialState = {}) => {
  const automaticState = { gridOptions: { aggFuncs } };
  const mergedState = merge({}, automaticState, initialState);
  return CommonControlledDataGrid.createState(mergedState);
};

ControlledDataGrid.propTypes = {
  customContext: PropTypes.object, // a generic object that can be injected so we have extra information needed in cellRenderer
};
