import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'stti-react-common';

import './RequestError.scss';

export const RequestError = ({
  className = '',
  onRetry,
  label = 'Error retrieving data',
  ...restProps
}) => (
  <div className={`Report__RequestError ${className}`} {...restProps}>
    <Icon icon="warning" color="error" />
    {label}
    {onRetry && <Button label="Retry" icon="refresh" tooltip="Retry" onClick={onRetry} />}
  </div>
);

RequestError.propTypes = {
  className: PropTypes.string,
  onRetry: PropTypes.func,
  label: PropTypes.string,
};
