import { isNil } from 'lodash';
import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'vehicle.name',
    headerName: 'Vehicle ID',
  }),
  columnDef.textColumn({
    field: 'ou.name',
    headerName: 'Managed By',
  }),
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date',
  }),
  columnDef.textColumn({
    field: 'gatewayDevice.type',
    headerName: 'Device Type',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'gatewayDevice.firmwareVersion',
    headerName: 'Firmware Version',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'gatewayDevice.serialNumber',
    headerName: 'Serial Number',
  }),
  columnDef.textColumn({
    field: 'uptime',
    headerName: 'Uptime',
    cellRenderer: 'Uptime',
  }),
  columnDef.textColumn({
    field: 'uptime',
    headerName: 'Working',
    cellRenderer: 'Working',
  }),

  columnDef.rankedTextColumn({
    colId: 'working',
    field: 'uptime',
    headerName: 'Working',
    cellRenderer: 'Working',
    valueGetter: ({ data }) => {
      const value = data?.uptime;
      if (isNil(value)) return { value, rank: 0, label: 'Not Measured', color: 'gray' };
      if (value >= 50) return { value, rank: 2, label: 'Good', color: 'green' };
      if (value >= 5 && value < 50) return { value, rank: 1, label: 'Partial', color: 'amber' };
      return { value, rank: 0, label: 'Not Working', color: 'red' };
    },
  }),
];
