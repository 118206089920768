import React from 'react';
import PropTypes from 'prop-types';

export const TirePressure = ({ color }) => (
  <div className="Map__TirePressure">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.75 100.749">
      <path
        d="M63.72,7.004H35.661c-15.514,0-28.135,19.289-28.135,42.998S20.147,93,35.661,93H63.72
		c15.514,0,28.136-19.289,28.136-42.998S79.233,7.004,63.72,7.004z M35.661,10.004h17.754c-4.232,2.555-7.962,6.632-10.904,11.791
		l-16.68,7.887l-8.433-7.12C21.984,14.838,28.473,10.004,35.661,10.004z M35.768,45.156L22.797,53.02l-12.121-7.348
		c0.521-7.659,2.408-14.671,5.261-20.417l8.697,7.342c0.276,0.233,0.621,0.354,0.968,0.354c0.217,0,0.436-0.047,0.641-0.144
		l14.083-6.659C37.89,31.709,36.283,38.183,35.768,45.156z M10.54,49.097l11.479,6.958c0.239,0.146,0.508,0.218,0.778,0.218
		c0.269,0,0.539-0.072,0.777-0.217l12.031-7.294c-0.008,0.413-0.021,0.823-0.021,1.239c0,7.585,1.297,14.713,3.562,20.906
		l-9.114,7.087l-15.961-7.929c-0.053-0.026-0.109-0.042-0.163-0.062c-2.147-5.889-3.383-12.719-3.383-20.003
		C10.526,49.699,10.536,49.399,10.54,49.097z M35.661,90c-8.145,0-15.395-6.202-19.991-15.789l13.872,6.891
		c0.211,0.104,0.44,0.156,0.667,0.156c0.327,0,0.652-0.107,0.921-0.315l9.174-7.134c3.167,7.251,7.736,12.946,13.11,16.191H35.661z
		 M63.72,90c-8.964,0-16.841-7.511-21.292-18.78c-0.03-0.239-0.117-0.473-0.267-0.681c-2.268-6.007-3.576-13.033-3.576-20.537
		c0-22.055,11.275-39.998,25.135-39.998c13.86,0,25.136,17.943,25.136,39.998C88.855,72.057,77.58,90,63.72,90z"
        fill={color}
      />
      <path
        d="M63.72,23.984c-7.985,0-14.24,11.429-14.24,26.018c0,14.59,6.254,26.019,14.24,26.019s14.24-11.429,14.24-26.019
		C77.96,35.413,71.705,23.984,63.72,23.984z M63.72,73.02c-6.093,0-11.24-10.541-11.24-23.019c0-12.477,5.147-23.018,11.24-23.018
		s11.24,10.541,11.24,23.018C74.96,62.479,69.812,73.02,63.72,73.02z"
        fill={color}
      />
      <path
        d="
                 M 180, 50
                 m -75, 0
                 a 15,15 0 1,0 30,0
                 a 15,15 0 1,0 -30,0
                 "
        stroke={color}
        fill="#fff"
        strokeWidth="3"
      />
      <path d="M90 49 l15 0 l0 2 l-15 0 z" stroke={color} fill="#fff" strokeWidth="3" />
      <path d="M120 49 l6 -10 l0 10 z" stroke={color} fill="#fff" strokeWidth="3" />
    </svg>
  </div>
);

TirePressure.propTypes = {
  color: PropTypes.string,
};

TirePressure.defaultProps = {
  color: 'black',
};
