import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { Form, SelectInput, TextInput, ProgressOverlay } from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

export const VehicleTypeDetail = ({
  vehicleType,
  assetTypeId,
  ous,
  fetchVehicleType,
  fetchVehicleTypes,
  deleteVehicleType,
  createVehicleType,
  updateVehicleType,
  mode: initialMode = 'view',
  route,
  activeOu,
  tenant,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      Promise.all([fetchVehicleTypes(), fetchVehicleType(assetTypeId)])
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, assetTypeId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...vehicleType,
    },
  });

  const { controls: formControls, resetControls } = form;

  const handleCreateUpdate = (formData) => {
    const payload = { ...formData };
    payload.ou = ous.find((item) => item.key === formData.appliesToKey);
    return payload;
  };

  const filteredOus = React.useMemo(() => {
    if (activeOu.name === tenant.name) return ous;
    return ous.filter((ou) => ou.name === activeOu.name);
  }, [activeOu.name, ous]);

  return (
    <div className="VehicleTypeDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Vehicle Type"
          form={form}
          itemTitle={formControls.assetTypeId || ''}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={handleCreateUpdate(formControls)}
          onClose={route.close}
          createItem={createVehicleType}
          updateItem={vehicleType && !vehicleType.isSystem ? updateVehicleType : null}
          deleteItem={vehicleType && !vehicleType.isSystem ? deleteVehicleType : null}
          resetControls={resetControls}
        >
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control
              Component={TextInput}
              name="name"
              label="Name"
              error={({ value }) => !value || !value.trim()}
            />
            <Control
              Component={SelectInput}
              name="typeClass"
              label="Type Class"
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Trailer', value: 'Trailer' },
                { label: 'Vehicle', value: 'Vehicle' },
              ]}
            />
            <Control
              Component={SelectInput}
              name="isSleeperBerth"
              label="Sleeper berth?"
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Equipped', value: true },
                { label: 'Not Equipped', value: false },
              ]}
            />
            <Control
              Component={SelectInput}
              name="appliesToKey"
              label="Applies to"
              error={({ value }) => isNil(value)}
              options={objectsToSelectOptions(filteredOus, 'name', 'key', false)}
            />
          </div>
        </CrudAdminItem>
      )}
    </div>
  );
};

VehicleTypeDetail.propTypes = {
  fetchVehicleType: PropTypes.func.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  vehicleType: customPropTypes.vehicleType,
  assetTypeId: PropTypes.string,
  ous: customPropTypes.organizations,
  deleteVehicleType: PropTypes.func.isRequired,
  createVehicleType: PropTypes.func.isRequired,
  updateVehicleType: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'update', 'create']),
  route: PropTypes.object.isRequired,
  activeOu: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
};
