import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { AggregatesCard } from '../../../commons/ReportsCommon';

import { useFormats } from '../../../commons/Formats';
import { countOf, sumOfField } from '../../../../helpers/reports/aggregation';

export const CycleDetailAggregates = ({ cycle, trips }) => {
  const { formatUnit } = useFormats();

  const tripsIncludingLegType = {
    loading: filter(trips, 'loading.include'),
    unloading: filter(trips, 'unloading.include'),
  };

  if (!cycle) return null;

  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Trips',
            value: formatUnit('integer', countOf(trips)),
          },
          {
            header: 'Vehicles',
            value: formatUnit('integer', countOf(cycle.vehiclesNames)),
          },
          {
            header: 'Trailers',
            value: formatUnit('integer', countOf(cycle.trailersNames)),
          },
        ]}
      />
      <AggregatesCard
        title="Cycle (avg)"
        name="cycle"
        fields={[
          {
            header: 'Distance',
            value: formatUnit('distance', cycle.fullCycle.distance),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', cycle.fullCycle.duration),
          },
          {
            header: 'Wait',
            value: formatUnit('duration', cycle.fullCycle.waitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Loads"
        name="loads"
        fields={[
          {
            header: 'Loads',
            value: formatUnit('integer', sumOfField(tripsIncludingLegType.loading, 'count.loads')),
          },
          {
            header: 'Unloads',
            value: formatUnit(
              'integer',
              sumOfField(tripsIncludingLegType.unloading, 'count.unloads')
            ),
          },
        ]}
      />
      <AggregatesCard
        title="Fuel (avg)"
        name="fuel"
        fields={[
          {
            header: 'Consumed',
            value: formatUnit('volume', cycle.fullCycle.consumedFuel),
          },
          {
            header: 'Idling',
            value: formatUnit('volume', cycle.fullCycle.idlingFuel),
          },
          {
            header: 'Mileage',
            value: formatUnit('mileage', cycle.fullCycle.mileage),
          },
        ]}
      />
      <AggregatesCard
        title="Empty (avg)"
        name="empty"
        fields={[
          {
            header: 'Distance',
            value: formatUnit('distance', cycle.empty.distance),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', cycle.empty.duration),
          },
          {
            header: 'Wait',
            value: formatUnit('duration', cycle.empty.waitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Loading (avg)"
        name="loading"
        fields={[
          {
            header: 'Distance',
            value: formatUnit('distance', cycle.loading.distance),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', cycle.loading.duration),
          },
          {
            header: 'Wait',
            value: formatUnit('duration', cycle.loading.waitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Loaded (avg)"
        name="loaded"
        fields={[
          {
            header: 'Distance',
            value: formatUnit('distance', cycle.loaded.distance),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', cycle.loaded.duration),
          },
          {
            header: 'Wait',
            value: formatUnit('duration', cycle.loaded.waitDuration),
          },
        ]}
      />
      <AggregatesCard
        title="Unloading (avg)"
        name="unloading"
        fields={[
          {
            header: 'Distance',
            value: formatUnit('distance', cycle.unloading.distance),
          },
          {
            header: 'Duration',
            value: formatUnit('duration', cycle.unloading.duration),
          },
          {
            header: 'Wait',
            value: formatUnit('duration', cycle.unloading.waitDuration),
          },
        ]}
      />

      {/* <AggregatesCard
        title="Performance (avg)"
        name="performance"
        fields={[
          {
            header: 'Violations',
            value: formatUnit('number', meanOfField(rows, 'count.totalViolations')),
          },
          {
            header: 'Idling',
            value: formatUnit(
              'duration',
              meanOfField(rows, 'fullCycle.idlingDuration'),
              
            ),
          },
        ]}
      /> */}
    </>
  );
};

CycleDetailAggregates.propTypes = {
  cycle: PropTypes.object,
  trips: PropTypes.array.isRequired,
};
