import { zipObject } from 'lodash';

export const base64tuple = (valueKeys) => ({
  encode: (routeParamKey, routeParams) => {
    const valuesObject = valueKeys.map((valueKey) => routeParams[valueKey]);
    const encodedValuesString = btoa(JSON.stringify(valuesObject)).replace(/\//g, '-'); // using dashes instead of slashes in base64 encoding
    return { [routeParamKey]: `=${encodedValuesString}` }; // prefix with '=' for analytics
  },
  decode: (routeParamKey, encodedValuesString) => {
    const valuesArray = JSON.parse(atob(encodedValuesString.replace(/-/g, '/').replace(/^=/, ''))); // using dashes instead of slashes in base64 encoding, remove '=' prefix
    return zipObject(valueKeys, valuesArray); // original routeParamKey is not returned from this encoder
  },
});

export const hyphenDelimitedTuple = (valueKeys, { compactDates } = {}) => ({
  encode: (routeParamKey, routeParams) => {
    let valuesObject = valueKeys.map((valueKey) => routeParams[valueKey]);
    if (compactDates) {
      // e.g. convert "2020-01-01" to "20200101"
      valuesObject = valuesObject.map((value) =>
        /^20\d{2}-\d{2}-\d{2}$/.test(value) ? value.replace(/-/g, '') : value
      );
    }
    const encodedValuesString = valuesObject.join('-');
    return { [routeParamKey]: encodedValuesString };
  },
  decode: (routeParamKey, encodedValuesString) => {
    let valuesArray = encodedValuesString.split('-');
    if (compactDates) {
      // e.g. convert "20200101" to "2020-01-01"
      valuesArray = valuesArray.map((value) =>
        /^20\d{6}$/.test(value)
          ? `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`
          : value
      );
    }
    return zipObject(valueKeys, valuesArray); // original routeParamKey is not returned from this encoder
  },
});
