import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { toLower } from 'lodash';

import { NA_VALUE } from '../formats';

const moment = extendMoment(Moment);

export const getChartEndDate = (logDate, timeZone) => {
  const now = moment.tz(moment(), timeZone);
  const logDateTime = moment.tz(logDate, timeZone);

  return now.isSame(logDateTime, 'day') ? now : logDateTime.add(1, 'day').startOf('day');
};

export const getStatusTotals = (items, status) =>
  items
    .filter((interval) => interval.dutyStatus === status)
    .map((interval) => interval.duration)
    .reduce((accumulator, value) => accumulator + value, 0);

// Method responsible to create an array of dateTimes to be displayed at the bottom
export const getEndDateQuickOptions = (startDate, { timeZone }) =>
  [
    { label: '15m', value: 900 },
    { label: '30m', value: 1800 },
    { label: '45m', value: 2700 },
    { label: '1h', value: 3600 },
    { label: '3h', value: 10800 },
    { label: '5h', value: 18000 },
    { label: '8h', value: 28800 },
    { label: '10h', value: 36000 },
    { label: '12h', value: 43200 },
    {
      label: 'Max',
      value: moment(getChartEndDate(startDate, timeZone)).diff(
        moment.tz(startDate, timeZone),
        'seconds'
      ),
    },
  ]
    .filter((duration) =>
      moment
        .tz(startDate, timeZone)
        .add(duration.value, 'seconds')
        .isSameOrBefore(getChartEndDate(startDate, timeZone))
    )
    .map((duration) => ({
      label: duration.label,
      value: moment
        .tz(startDate, timeZone)
        .add(duration.value, 'seconds')
        .format('YYYY-MM-DDTHH:mm'),
    }));

// Method responsible to update graph with new interval based on form input data.
export const updateIntervals = (controls, log, row) => {
  const { dutyStatus } = controls;
  const { movementIntervals, timeZone: logTimeZone } = log;

  // Helper function to convert dates to the given timezone
  const convertToTimezone = (date, zone) =>
    new Date(new Date(date).toLocaleString('en-US', { timeZone: zone }));

  // Convert row.start to the correct timezone
  const rowStartInZone = convertToTimezone(row?.start, logTimeZone);

  // Find the interval where the start time falls
  const intervalToUpdateIndex = movementIntervals.findIndex((interval) => {
    const intervalStart = convertToTimezone(interval.fromDate, logTimeZone);
    const intervalEnd = new Date(intervalStart.getTime() + interval.duration * 1000);
    return rowStartInZone >= intervalStart && rowStartInZone < intervalEnd;
  });

  // If no interval contains the start time, return the original list
  if (intervalToUpdateIndex === -1) {
    return movementIntervals;
  }

  // Copy the movement intervals to avoid mutating the original
  const updatedIntervals = [...movementIntervals];
  const intervalToUpdate = updatedIntervals[intervalToUpdateIndex];

  // If the interval's duty status is different from the new duty status, update it
  if (intervalToUpdate.dutyStatus !== dutyStatus) {
    intervalToUpdate.dutyStatus = dutyStatus;
  }

  // Adjust the duration if the interval's start time matches the row's start time
  if (
    convertToTimezone(intervalToUpdate.fromDate, logTimeZone).getTime() === rowStartInZone.getTime()
  ) {
    // Calculate the new duration based on the row's start time
    const intervalEnd = new Date(rowStartInZone.getTime() + intervalToUpdate.duration * 1000);
    intervalToUpdate.duration = (intervalEnd - rowStartInZone) / 1000;
  }

  return updatedIntervals;
};

export const formatDutyStatusCodeToFriendly = (status) => {
  if (!status) return NA_VALUE;
  switch (status) {
    case 'OFF_DUTY':
      return 'Off Duty';
    case 'SLEEPER_BERTH':
      return 'Sleeper Berth';
    case 'DRIVING':
      return 'Driving';
    case 'ON_DUTY':
    case 'ON_DUTY_NOT_DRIVING':
      return 'On Duty Not Driving';
    default:
      return status;
  }
};

/**
 * Sanitizes a duty status string and returns a formatted status string
 * @param {string} status - the duty status string to sanitize
 * @returns {string} - the sanitized and formatted status string
 */
export const sanitizeDutyStatus = (status) => {
  const formattedString = toLower(status);
  switch (true) {
    case formattedString.includes('on duty not driving'):
      return 'On Duty Not Driving';
    case formattedString.includes('on duty'):
      return 'On Duty';
    case formattedString.includes('off duty'):
      return 'Off Duty';
    case formattedString.includes('driving'):
      return 'Driving';
    default:
      return formatDutyStatusCodeToFriendly(status);
  }
};
