/**
 * selectRoles(state) -> [role]
 *
 * Selector for array of available roles which can be assigned to a user for an OU
 */
export const selectRoles = (state) => state.administration.resources.roles.data;

/**
 * selectRolesFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for roles
 */
export const selectRolesFetchRequests = (state) => state.administration.resources.roles.fetches;
