import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  filter,
  isNumber,
  intersection,
  get,
  castArray,
  meanBy,
  without,
  sortBy,
  isNil,
  reject,
} from 'lodash';
import produce from 'immer';

import { unconvertUnit } from '../formats';

export const filterRowsByPredicate = ({ rows, value, test, reject: rejectMode }) => {
  const itereeFn = rejectMode ? reject : filter;

  const filteredRows = itereeFn(rows, (row) => test({ row, value }));

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByPredicate.propTypes = {
  rows: PropTypes.array.isRequired,
  value: PropTypes.any,
  test: PropTypes.func.isRequired,
};

export const filterRowsByIntersection = ({ rows, field, value, reject: rejectMode }) => {
  if (isNil(value)) return rows;

  const targetValues = castArray(value);

  const itereeFn = rejectMode ? reject : filter;

  const filteredRows = itereeFn(
    rows,
    (row) => intersection(castArray(get(row, field)), targetValues).length > 0
  );

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByIntersection.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  reject: PropTypes.bool,
};

export const filterRowsByEquals = ({ rows, field, value }) => {
  if (isNil(value)) return rows;

  const filteredRows = filter(rows, (row) => get(row, field) === value);

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByEquals.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export const filterRowsByDateRange = ({ rows, field, startDate, endDate }) => {
  if (!startDate || !endDate) return [];

  let startAsString;
  let endAsString;

  const timelessDatePattern = /^\d{4}-\d{2}-\d{2}$/;
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  if (timelessDatePattern.test(startDate) && timelessDatePattern.test(endDate)) {
    // timeless dates: use whole day, without any time offset
    startAsString = moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    endAsString = moment(endDate).add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  } else if (isoDatePattern.test(startDate) && isoDatePattern.test(endDate)) {
    // ISO datetimes: use as-is time without trailing 'Z'
    startAsString = startDate.replace(/Z/, '');
    endAsString = endDate.replace(/Z/, '');
  } else {
    throw new Error('Unrecognized startDate/endDate format.');
  }

  const filteredRows = filter(rows, (row) => {
    const rowValue = get(row, field);
    return rowValue >= startAsString && rowValue < endAsString;
  });

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByDateRange.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export const filterRowsByUnitRange = ({ rows, field, unitType, max, min, unit }) => {
  const applyMin = isNumber(min);
  const applyMax = isNumber(max);

  if (!applyMin && !applyMax) return rows;

  const siMin = applyMin && unconvertUnit(unitType, min, unit);
  const siMax = applyMax && unconvertUnit(unitType, max, unit);

  const filteredRows = filter(rows, (row) => {
    const value = get(row, field);
    return (!applyMin || value >= siMin) && (!applyMax || value <= siMax);
  });

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByUnitRange.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  unitType: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  unit: PropTypes.string,
};

export const filterRowsByBaselineRange = ({ rows, field, baselineRows, max, min }) => {
  const applyMin = isNumber(min);
  const applyMax = isNumber(max);

  if (!applyMin && !applyMax) return rows;

  const average = meanBy(baselineRows, (baselineRow) => get(baselineRow, field));

  const absoluteMin = applyMin && (min / 100) * average;
  const absoluteMax = applyMax && (max / 100) * average;

  const filteredRows = filter(rows, (row) => {
    const value = get(row, field);
    return (!applyMin || value >= absoluteMin) && (!applyMax || value <= absoluteMax);
  });

  if (filteredRows.length === rows.length) return rows;
  return filteredRows;
};
filterRowsByBaselineRange.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  baselineRows: PropTypes.array.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
};

export const invertFilteredRows = ({ rows, baselineRows }) => {
  if (baselineRows.length === 0) return rows;
  if (rows.length === 0) return baselineRows;

  return without(baselineRows, ...rows);
};
invertFilteredRows.propTypes = {
  rows: PropTypes.array.isRequired,
  baselineRows: PropTypes.array.isRequired,
};

export const sortRows = ({ rows, field, reverse = false }) => {
  const sortedRows = sortBy(rows, (row) => get(row, field));
  return reverse ? sortedRows.reverse() : sortedRows;
};
sortRows.propTypes = {
  rows: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

export const mutateRows = ({ rows, value, callback }) =>
  produce(rows, (draftRows) => {
    draftRows.forEach((draftRow, index) =>
      callback({ draft: draftRow, rows: draftRows, index, value })
    );
  });
mutateRows.propTypes = {
  rows: PropTypes.array.isRequired,
  value: PropTypes.any,
  callback: PropTypes.func.isRequired,
};
