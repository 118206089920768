import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { reject, findIndex } from 'lodash';
import { v4 as uuid } from 'uuid';

import { AddExceptionRuleButton } from './AddExceptionRuleButton';
import { ExceptionRulesControl } from './ExceptionRulesControl';
import { EditExceptionRuleDialog } from './EditExceptionRuleDialog';
import { useReport } from '../useReport';

export const ExceptionRules = ({ features }) => {
  const { setState } = useReport();

  const [targetRule, setTargetRule] = useState();

  const handleSaveRule = useCallback((rule) => {
    if (rule.id) {
      // existing rule
      setState((draft) => {
        const ruleIndex = findIndex(draft.controls.exceptionRules, { id: rule.id });
        draft.controls.exceptionRules[ruleIndex] = rule;
      });
    } else {
      // new rule
      setState((draft) => {
        draft.controls.exceptionRules.push({ ...rule, id: uuid() });
      });
    }
  }, []);

  const handleDeleteRule = useCallback((rule) => {
    setState((draft) => {
      draft.controls.exceptionRules = reject(draft.controls.exceptionRules, { id: rule.id });
    });
  }, []);

  return (
    <div className="Report__ExceptionRules">
      <ExceptionRulesControl onChipClick={setTargetRule} onChipDelete={handleDeleteRule} />
      <EditExceptionRuleDialog
        isOpen={!!targetRule}
        rule={targetRule}
        onClose={() => setTargetRule(null)}
        onSaveRule={handleSaveRule}
        features={features}
      />
      <div>
        <AddExceptionRuleButton onClick={() => setTargetRule({})} />
      </div>
    </div>
  );
};

ExceptionRules.propTypes = {
  features: PropTypes.object,
};
