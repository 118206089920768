import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  SelectInput,
  TextInput,
  ProgressOverlay,
  NumberInput,
  Button,
} from 'stti-react-common';

import { isEmpty } from 'lodash';

import { FileInput } from 'stti-react-common/src/components/FileInput';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';

import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

const getAllowedFileType = (fileType) => (fileType === 'bin' ? ['binary', 'octet'] : ['android']);

export const CompanionAppDetail = ({
  companionApp,
  tenants,
  mode: initialMode = 'view',
  fetchCompanionApps,
  fetchTenants,
  updateCompanionApp,
  createCompanionApp,
  deleteCompanionApp,
  route,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    fetchCompanionApps();
    fetchTenants();
  }, []);

  useEffect(() => {
    if (!isEmpty(companionApp)) {
      setLoading(false);
      resetControls();
    }
    if (mode === 'create') {
      setLoading(false);
    }
  }, [companionApp]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: companionApp,
  });

  const { controls: formControls, resetControls, setControl } = form;

  const addAllTenants = () => {
    setControl(
      'tenantKeys',
      tenants.map((tenant) => tenant.key)
    );
  };

  const removeAllTenants = () => {
    setControl('tenantKeys', []);
  };

  return (
    <div className="CompanionAppDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Companion App"
          form={form}
          itemTitle={formControls.name || ''}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formControls}
          onClose={route.close}
          createItem={createCompanionApp}
          updateItem={updateCompanionApp}
          deleteItem={deleteCompanionApp}
          resetControls={resetControls}
        >
          <Feature.Body>
            <ErrorSummary errorMsg={apiError} />

            <div className="CrudAdminItem__controls">
              <Control
                Component={TextInput}
                name="name"
                label="Application Name"
                error={({ value }) => !value || !value.trim()}
              />
              <Control
                Component={NumberInput}
                name="version"
                label="Build Number"
                error={({ value }) => !value}
              />
              <Control
                Component={SelectInput}
                name="releaseChannels"
                label="Release Channels"
                multiple
                options={['Alpha', 'Beta', 'Prod', 'Omega', 'Delta', 'Sandbox', 'Dev', 'QA']}
                error={({ value }) => !value}
              />
              <Control
                Component={SelectInput}
                name="fileType"
                label="File Type"
                defaultValue="apk"
                error={({ value }) => !value}
                onSet={({ draft }) => {
                  draft.apk = null;
                }}
                options={[
                  { label: 'BIN (Firmware File)', value: 'bin' },
                  { label: 'APK (Android App)', value: 'apk' },
                ]}
              />
              <Control
                Component={SelectInput}
                name="tenantKeys"
                multiple
                multipleCollapseAt={4}
                multipleCollapseText="tenants"
                label="Available To Tenants"
                options={objectsToSelectOptions(tenants, 'name', 'key')}
                error={({ value }) => !value}
              />
              {(mode === 'edit' || mode === 'create') && (
                <div className="CrudAdminItem__nestedControls">
                  <Button
                    onClick={addAllTenants}
                    label="Add All Tenants"
                    variant="outlined"
                    disabled={
                      formControls.tenantKeys && formControls.tenantKeys.length === tenants.length
                    }
                  />
                  <Button
                    onClick={removeAllTenants}
                    label="Remove All Tenants"
                    variant="outlined"
                    disabled={formControls.tenantKeys && formControls.tenantKeys.length === 0}
                  />
                </div>
              )}
              <Control
                Component={TextInput}
                name="description"
                label="Description"
                multiline
                error={({ value }) => !value || !value.trim()}
              />
              {mode !== 'view' && (
                <Control
                  Component={FileInput}
                  name="apk"
                  label={`Upload ${formControls.fileType}`}
                  allowedFileTypes={getAllowedFileType(formControls.fileType)}
                  error={({ value }) => !value && mode === 'create'}
                />
              )}
            </div>
          </Feature.Body>
        </CrudAdminItem>
      )}
    </div>
  );
};

CompanionAppDetail.propTypes = {
  companionApp: customPropTypes.companionApp,
  fetchCompanionApps: PropTypes.func.isRequired,
  fetchTenants: PropTypes.func.isRequired,
  updateCompanionApp: PropTypes.func.isRequired,
  deleteCompanionApp: PropTypes.func.isRequired,
  createCompanionApp: PropTypes.func.isRequired,
  tenants: customPropTypes.tenants,
  mode: PropTypes.oneOf(['view', 'edit', 'update', 'create']),
  route: PropTypes.object.isRequired,
};
