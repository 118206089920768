/**
 * selectActivationCodes(state) -> [activationCodes]
 *
 * Selector for all activationCodes
 */
export const selectActivationCodes = (state) => state.administration.devices.activationCodes.data;

/**
 * selectActivationCodesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on activationCodes
 */
export const selectActivationCodesFetchRequests = (state) =>
  state.administration.devices.activationCodes.fetches;
