import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, ToggleInput, Button, HoverHelp } from 'stti-react-common';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { Feature } from '../../../../commons/Feature';

import { FEATURES } from '../../../../../features';

import './summary.scss';

const TOOLTIP_MESSAGES = {
  filterVehiclesByOu:
    'This setting will disable the ability for all drivers to select vehicles and trailers from organizations they are not a member of.',
  disableAddTractorByOu:
    'This setting will disable the ability for all drivers to add new vehicles from the tablet.',
  disableAddTrailerByOu:
    'This setting will disable the ability for all drivers to add new trailers from the tablet.',
  yardMovesDefault: 'Turning this setting on will enable Yard Moves for every new user created.',
  personalUseDefault:
    'Turning this setting on will enable Personal Use for every new user created.',
  showVCPerformancePoints:
    'Turning this setting on will enable performance points on the driving dashboard.',
};

export const SystemSettingSummary = ({
  tenant,
  ouTenant,
  fetchOrganizations,
  fetchCurrentTenant,
  updateOrganization,
  updateOrganizationPerformancePoints,
  updateTenant,
  isTenantSuperAdmin,
  route,
}) => {
  const { key, yardMovesDefault, personalUseDefault } = tenant;
  const {
    filterVehiclesByOu,
    disableAddTractorByOu,
    disableAddTrailerByOu,
    showVCPerformancePoints,
  } = ouTenant;

  // This need to have the inverted value from filterVehiclesByOu because having a false value actually means
  // it will allow vehicle sharing from all ous.
  const disableFilterVehiclesByOu = filterVehiclesByOu;

  // This fetchCurrentTenant is skipped since we already have the basic tenant information from redux store
  useEffect(() => {
    if (key) Promise.all([fetchOrganizations(), fetchCurrentTenant(key)]).then(resetControls);
  }, [key]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...(tenant &&
        ouTenant && {
          disableFilterVehiclesByOu,
          disableAddTractorByOu,
          disableAddTrailerByOu,
          yardMovesDefault,
          personalUseDefault,
          showVCPerformancePoints,
        }),
    },
  });

  const { controls: formControls, resetControls } = form;

  const saveSystemSettings = () => {
    updateOrganization({
      ...ouTenant,
      ...formControls,
      filterVehiclesByOu: formControls.disableFilterVehiclesByOu,
    });
    updateOrganizationPerformancePoints({
      ...ouTenant,
      showVCPerformancePoints: formControls.showVCPerformancePoints,
    });
    updateTenant({
      ...tenant,
      yardMovesDefault: formControls.yardMovesDefault,
      personalUseDefault: formControls.personalUseDefault,
    });
  };

  return (
    <div className="SystemSettingSummary">
      <Feature.Header title="System Settings" service="organizationalUnits">
        <Button icon="close" variant="contained" label="Close" onClick={() => route.close()} />
        <Button
          icon="save"
          variant="contained"
          label="Save Settings"
          onClick={saveSystemSettings}
        />
      </Feature.Header>

      <Feature.Body>
        <div className="SystemSettingSummary__formContainer">
          <Form form={form}>
            {!FEATURES.admin.hideVehicleSharingFromSettings && isTenantSuperAdmin && (
              <div className="SystemSettingSummary__formContainer__controlContainer">
                <Control
                  name="disableFilterVehiclesByOu"
                  Component={ToggleInput}
                  label="Disable Vehicle Sharing"
                />
                <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.filterVehiclesByOu} />
              </div>
            )}
            <div className="SystemSettingSummary__formContainer__controlContainer">
              <Control
                name="disableAddTractorByOu"
                Component={ToggleInput}
                label="Disable Driver Vehicle Creation"
              />
              <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.disableAddTractorByOu} />
            </div>
            <div className="SystemSettingSummary__formContainer__controlContainer">
              <Control
                name="disableAddTrailerByOu"
                Component={ToggleInput}
                label="Disable Driver Trailer Creation"
              />
              <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.disableAddTractorByOu} />
            </div>
            <div className="SystemSettingSummary__formContainer__controlContainer ng-hide">
              <Control name="yardMovesDefault" Component={ToggleInput} label="Yard Moves Default" />
              <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.yardMovesDefault} />
            </div>
            <div className="SystemSettingSummary__formContainer__controlContainer ng-hide">
              <Control
                name="personalUseDefault"
                Component={ToggleInput}
                label="Personal Use Default"
              />
              <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.personalUseDefault} />
            </div>
            <div className="SystemSettingSummary__formContainer__controlContainer">
              <Control
                name="showVCPerformancePoints"
                Component={ToggleInput}
                label="Show Performance Points on Dashboard"
              />
              <HoverHelp icon="help" tooltip={TOOLTIP_MESSAGES.showVCPerformancePoints} />
            </div>
          </Form>
        </div>
      </Feature.Body>
    </div>
  );
};

SystemSettingSummary.propTypes = {
  route: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
  ouTenant: customPropTypes.organization,
  fetchOrganizations: PropTypes.func.isRequired,
  fetchCurrentTenant: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  updateOrganizationPerformancePoints: PropTypes.func.isRequired,
  updateTenant: PropTypes.func.isRequired,
  isTenantSuperAdmin: PropTypes.bool.isRequired,
};
