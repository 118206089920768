export const findCardToPrint = (lookup, cardsToPrint) =>
  !!cardsToPrint.find((card) => card === lookup);

export const driverLogCards = [
  { key: 'DriverCard', label: 'Driver & Hours' },
  { key: 'EventsCard', label: 'Driver Record Events' },
  { key: 'RequestedChangesCard', label: 'Requested Changes' },
  { key: 'EldCard', label: 'ELD Events' },
  { key: 'CarrierCard', label: 'Carrier' },
  { key: 'VehicleCard', label: 'Vehicles' },
  { key: 'EffectiveRegulationsCard', label: 'Effective Regulations' },
  { key: 'ViolationsCard', label: 'Violations' },
  { key: 'OnDutySummaryCard', label: '14 Day Summary' },
];
