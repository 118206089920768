/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, ScrollBar, TextInput } from 'stti-react-common';
import { isEmpty } from 'lodash';

import { BookmarkList } from './BookMarkList';
import './BookMark.scss';

export const Bookmark = ({
  bookmarks = [],
  deleteFleetTrackingBookmark,
  fetchFleetTrackingBookmark,
  mapInstance,
  onSetFocus,
  postFleetTrackingBookmark,
  updateFleetTrackingBookmark,
}) => {
  const { map } = mapInstance.useMap();
  const center = map?.getCenter();
  const [bookmark, setBookmark] = useState('');

  const localStorageElement = localStorage.getItem('fleetTrackingBookmark');
  const BOOK_MARK_REGISTER = localStorageElement ? JSON.parse(localStorageElement) : {};

  const postBookmark = async (param) => {
    await postFleetTrackingBookmark({
      centre: { latitude: center.lat(), longitude: center.lng() },
      name: param,
      zoom: 4,
    });
    await fetchFleetTrackingBookmark();
    setBookmark('');
  };

  const editBookmark = async (coordinate, param, setActiveBookmark) => {
    await updateFleetTrackingBookmark({
      centre: coordinate.centre,
      isDefaultLocation: BOOK_MARK_REGISTER?.id === coordinate.id,
      key: coordinate.id,
      name: param,
      user: bookmarks.find(({ key }) => key === coordinate.id).user,
      zoom: 4,
    });
    await fetchFleetTrackingBookmark();
    setActiveBookmark();
  };

  const deleteBookmarking = async (bookMarkId, setActiveBookmark) => {
    if (BOOK_MARK_REGISTER.id === bookMarkId) {
      localStorage.removeItem('fleetTrackingBookmark');
    }
    await deleteFleetTrackingBookmark({ key: bookMarkId });
    await fetchFleetTrackingBookmark();
    setActiveBookmark();
  };

  const setBookmarkLocation = async (bookmarkCoordinates) => {
    BOOK_MARK_REGISTER.id === bookmarkCoordinates.id
      ? localStorage.removeItem('fleetTrackingBookmark')
      : localStorage.setItem('fleetTrackingBookmark', JSON.stringify(bookmarkCoordinates));

    await fetchFleetTrackingBookmark();
  };

  return (
    <div className="Bookmarks">
      <ul className="Bookmarks__list">
        <ScrollBar style={{ width: '240px', height: '30vh', overflowY: 'hidden !important' }}>
          {bookmarks.map(({ name, key, centre, zoom }) => (
            <BookmarkList
              id={key}
              key={key}
              bookMarkCoordinates={{ id: key, centre, zoom }}
              listName={name}
              editBookmark={editBookmark}
              setBookmarkLocation={setBookmarkLocation}
              deleteBookmarking={deleteBookmarking}
              onSetFocus={(result) => onSetFocus(result)}
              register={BOOK_MARK_REGISTER}
            />
          ))}
        </ScrollBar>
      </ul>

      <div className="add-bookmark-list">
        <TextInput
          placeholder="New Bookmark Name"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              postBookmark(bookmark);
            }
          }}
          value={bookmark}
          onChange={setBookmark}
          endAdornment={
            <Icon
              style={{ cursor: 'pointer' }}
              icon="add"
              onClick={() => (isEmpty(bookmark) ? null : postBookmark(bookmark))}
              disabled={isEmpty(bookmark)}
            />
          }
        />
      </div>
    </div>
  );
};

Bookmark.propTypes = {
  bookmarks: PropTypes.array,
  deleteFleetTrackingBookmark: PropTypes.func,
  fetchFleetTrackingBookmark: PropTypes.func,
  mapInstance: PropTypes.any,
  onSetFocus: PropTypes.func,
  postFleetTrackingBookmark: PropTypes.func,
  updateFleetTrackingBookmark: PropTypes.func,
};
