import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { cloneDeep, find, uniq } from 'lodash';

import { EXCEPTION_TYPE } from '../../helpers/reports/exceptionTypes';
import { selectTrips } from './tripsSelectors';
import { unitedExceptionId } from './exceptionsSupport';
import { inOuBasedTime } from '../system';

import { organization } from '../administration/configurations';

const getUsers = (state) => state.administration.resources.users.data;

const extractUnitedExceptionId = (_, { exceptionType, tripId, exceptionId }) =>
  unitedExceptionId({ exceptionType, tripId, exceptionId });

const getExceptions = (state) => state.reports.exceptions.exceptions;
const getAnnotations = (state) => state.reports.exceptions.annotations.byPseudoId;

const findAnnotation = (annotations, { exceptionType, tripId, exceptionId }) =>
  annotations[unitedExceptionId({ exceptionType, tripId, exceptionId })];

/**
 * selectUnitedExceptions(state) -> [exception]
 *
 * Selector for all *potential* exceptions available, whether trip- or event-based.
 *
 * "Potential" means that report-configuration-driven filtering will use these united exceptions to calculate
 * what is actually an exception according to the user's given criteria.
 */
export const selectUnitedExceptions = createSelector(
  [
    getExceptions,
    selectTrips,
    getAnnotations,
    organization.selectors.selectOrganizations,
    getUsers,
  ], // keep getAnnotations as dependency
  (storeExceptions, trips, annotations, ous, users) => {
    const tripExceptions = [];

    trips.forEach((trip) => {
      const { id: tripId } = trip;

      const addTripException = ({ exceptionType, ...rest }) => {
        tripExceptions.push({
          id: unitedExceptionId({ exceptionType, tripId }),
          exceptionType,
          ouKey: trip.ouKey,
          startedAt: trip.startedAt, // OU-based time (via selectTrips)
          startedAtUtc: trip.startedAtUtc, // original UTC time (via selectTrips)
          endedAt: trip.endedAt, // OU-based time (via selectTrips)
          endedAtUtc: trip.endedAtUtc, // original UTC time (via selectTrips)
          timeZone: trip.timeZone, // OU timeZone (via selectTrips)
          vehicle: trip.vehicle,
          drivers: trip.drivers,
          driversNames: trip.driversNames,
          startLocation: trip.origin,
          endLocation: trip.lastUnload,
          trip, // attach trip
          tripId,
          exceptionId: undefined,
          annotation: findAnnotation(annotations, { tripId, exceptionType }),
          ...rest,
        });
      };

      // tripCoreCycleDuration
      addTripException({
        exceptionType: EXCEPTION_TYPE.tripCoreCycleDuration,
        duration: trip.coreCycle.duration,
        distance: trip.coreCycle.distance,
      });

      // tripFuelMileage
      addTripException({
        exceptionType: EXCEPTION_TYPE.tripFuelMileage,
        duration: trip.fullCycle.duration,
        distance: trip.fullCycle.distance,
        mileage: trip.fullCycle.mileage,
      });

      // tripUnknownStopDuration
      if (trip.unknownStop.duration > 0)
        addTripException({
          exceptionType: EXCEPTION_TYPE.tripUnknownStopDuration,
          duration: trip.unknownStop.duration,
          distance: trip.unknownStop.distance,
        });
    });

    const eventExceptions = storeExceptions.map((storeException) => {
      const exceptionId = storeException.id;
      const { exceptionType, other = {} } = storeException;

      const eventException = cloneDeep({
        ...storeException,
        id: unitedExceptionId({ exceptionId, exceptionType }),
        exceptionType,
        exceptionId,
        annotation: findAnnotation(annotations, { exceptionId, exceptionType }),
        other,
      });

      let descriptions;
      if (exceptionType === 'eventVideoEvent') {
        if (other.provider === 'SURFSIGHT') {
          descriptions = uniq([other.typeDescription]);
        } else {
          descriptions = uniq([other.typeDescription, ...other.behaviorDescriptions]);
        }
      }
      eventException.other.descriptions = descriptions;

      return inOuBasedTime(eventException, ous, undefined, undefined, users);
    });

    return [...eventExceptions, ...tripExceptions];
  }
);

/**
 * selectUnitedException(state, { exceptionType: string, tripId: string?, exceptionId: string? }) -> exception
 *
 * Selector for single "united" (i.e. merged trip- and event-based) exception
 *
 * As all united exceptions are either trip- or event-based, only one of tripId or exceptionId is required.
 * Because this provided id targets a specific retrievable trip record (/trips endpoint) or retrievable exception record
 * (/exceptions endpoint),the user would have already specified some filtering criteria to end up targeting this specific
 * united exception as a "real" exception according to that user.
 */
export const selectUnitedException = createCachedSelector(
  [selectUnitedExceptions, extractUnitedExceptionId],
  (unitedExceptions, id) => find(unitedExceptions, { id })
)(extractUnitedExceptionId);
