import { connect } from 'react-redux';

import { Profile } from './profile';

import {
  selectSystemUser,
  selectSystemTenant,
  fetchCurrentUser,
  updateCurrentUser,
} from '../../data/system';

export const ProfileContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    tenant: selectSystemTenant(state),
  }),
  {
    fetchCurrentUser,
    updateCurrentUser,
  }
)(Profile);
