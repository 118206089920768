import React from 'react';
import PropTypes from 'prop-types';

import './AdminFormLabel.scss';

export const AdminFormLabel = ({ label, value }) => (
  <div className="AdminFormLabel">
    <span>{label}</span>
    <p>{value}</p>
  </div>
);

AdminFormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
