/**
 * selectRules(state) -> [rules]
 *
 * Selector for all rules
 */
export const selectRules = (state) =>
  state.administration.configurations.performanceRules.rules.data;

/**
 * selectRulesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on rules
 */
export const selectRulesFetchRequests = (state) =>
  state.administration.configurations.performanceRules.rules.fetches;

/**
 * selecteRule(state, ruleId) -> rule
 *
 * Selector for single admin rule by key
 */
export const selectRule = (state, ruleId) =>
  state.administration.configurations.performanceRules.rules.data.find(
    (item) => item.key === ruleId
  );

/**
 * selectRuleSets(state) -> [ruleSets]
 *
 * Selector for all ruleSets
 */
export const selectRuleSets = (state) =>
  state.administration.configurations.performanceRules.ruleSets.data;

/**
 * selectRuleSetsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on ruleSets
 */
export const selectRuleSetsFetchRequests = (state) =>
  state.administration.configurations.performanceRules.ruleSets.fetches;

/**
 * selectRuleSet(state, ruleSetId) -> ruleSet
 *
 * Selector for single admin ruleSet by key
 */
export const selecteRuleSet = (state, ruleSetId) =>
  state.administration.configurations.performanceRules.ruleSets.data.find(
    (item) => item.key === ruleSetId
  );

/**
 * selectRulesPredefined(state) -> rulesPredefined
 *
 * Selector for admin predefined rules
 */
export const selectRulesPredefined = (state) =>
  state.administration.configurations.performanceRules.rulesPredefined.data;
