import React from 'react';

import { VehicleTypeSummaryContainer } from './Summary/connect';
import { VehicleTypeDetailContainer } from './Detail/connect';

import { organizationDetail } from '../../configurations/Organizations/routes';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { FEATURES } from '../../../../features';

import { administrationGroups, configureRoute, ADMIN_ROLE_KEY } from '../../../../config';

export const vehicleTypesSummary = configureRoute({
  name: 'Vehicle Types',
  path: '/assetTypes',
  group: administrationGroups.resources,
  order: 6,
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <VehicleTypeSummaryContainer
      {...props}
      openVehicleTypeCreate={vehicleTypeCreate.open}
      openVehicleTypeDetail={vehicleTypeDetail.open}
      openOrganizationDetail={organizationDetail.open}
    />
  ),
  hidden: FEATURES.admin.hideVehicleTypesFromMainMenu,
});

export const vehicleTypeCreate = configureRoute({
  path: '/assetType/new',
  switchKey: 'vehicleType',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleTypeDetailContainer {...props} mode="create" />,
});

export const vehicleTypeDetail = configureRoute({
  path: '/assetType/:assetTypeId',
  switchKey: 'vehicleType',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <VehicleTypeDetailContainer {...props} />,
});
