import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';
import { groupBy, keys, values, uniqueId, flatten, sortBy } from 'lodash';
import moment from 'moment-timezone';

import { cycleEventsColumnDefs } from './columnDefs';
import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const CycleEventsCard = ({ log, printLayoutMode }) => {
  const { eldEvents } = log || {};

  const cycleEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(
      ({ eventType }) =>
        eventType.includes('CYCLE') || eventType.includes('ZONE') || eventType.includes('DEFERRAL')
    );
  }, [eldEvents]);

  const events = groupBy(cycleEvents, ({ date }) => moment(date).format('YYYY-MM-DD'));

  const arr = [];
  keys(events).forEach((value, index) => {
    arr.push({
      key: uniqueId(),
      startOfDayRecord: true,
      eldSequence: index - 1,
      date: moment(value).startOf('day').toISOString(),
    });
    arr.push(values(events)[index]);
  });

  const remappedEvents = sortBy(flatten(arr), ['date', 'eldSequence']);

  const cycleEventsDataGridController = useDataGridController({
    columnDefs: cycleEventsColumnDefs,
    gridOptions,
  });

  const cycleEventsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  return (
    <Card fullWidth>
      <div>
        <h4>Change in Driver&apos;s Cycle. Change in Operating Zone. Off-Duty Time Deferral</h4>
      </div>
      {remappedEvents && (
        <ControlledDataTable
          controller={cycleEventsDataGridController}
          components={cycleEventsDataGridComponents}
          rows={remappedEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

CycleEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  printLayoutMode: PropTypes.string,
};
