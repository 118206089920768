import React from 'react';

import { LocationTypeSummaryContainer } from './Summary/connect';
import { LocationTypeDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { FEATURES } from '../../../../features';

import { administrationGroups, configureRoute, ADMIN_ROLE_KEY } from '../../../../config';

export const locationTypeSummary = configureRoute({
  name: 'Location Types',
  group: administrationGroups.resources,
  path: '/locationTypes',
  order: 2,
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => (
    <LocationTypeSummaryContainer
      {...props}
      openLocationTypeCreate={locationTypeCreate.open}
      openLocationTypeDetail={locationTypeDetail.open}
    />
  ),
  hidden: FEATURES.admin.hideLocationTypesFromMainMenu,
});

export const locationTypeCreate = configureRoute({
  path: '/locationType/new',
  switchKey: 'locationType',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <LocationTypeDetailContainer {...props} mode="create" />,
});

export const locationTypeDetail = configureRoute({
  path: '/locationType/:locationTypeId',
  switchKey: 'locationType',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY],
  render: (props) => <LocationTypeDetailContainer {...props} />,
});
