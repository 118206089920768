import { filter } from 'lodash';

import {
  makeOneTimeOptimizedAction,
  makeRestApiAction,
  omitPrivateProperties,
} from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectLocationsFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const transformLocation = (location) => {
  const locationWithoutPrivateProperties = omitPrivateProperties(location);

  let color = location.color.toLowerCase();
  if (/^#[0-9a-f]{3}$/.test(color)) {
    const [, r, g, b] = color;
    color = `#${r}${r}${g}${g}${b}${b}`;
  }

  return {
    ...locationWithoutPrivateProperties,
    color,
  };
};

const fetchLocations = makeRestApiAction({
  service: 'locations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchLocations,
  transformOutput: (data) => data.map(transformLocation),
  notificationsItemDescriptor: 'locations',
});

export const fetchLocationsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectLocationsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchLocations,
  baseActionType: ACTION_TYPE.fetchLocations,
});

const fetchLocation = makeRestApiAction({
  service: 'locations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchLocation,
  getId: (key) => key,
  notificationsItemDescriptor: 'location',
});

export const fetchLocationOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectLocationsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchLocation,
  baseActionType: ACTION_TYPE.fetchLocation,
});

export const createLocation = makeRestApiAction({
  service: 'locations',
  method: 'post',
  baseActionType: ACTION_TYPE.createLocation,
  getId: ({ key }) => key,
  transformOutput: (response) => ({
    ...response,
    sizeOrder: 99999, // server always returns 0 regardless of new order value, use arbitrarily high number to ensure topmost until reloaded
  }),
  notificationsItemDescriptor: 'location',
  throwApiErrors: true,
});

export const updateLocation = makeRestApiAction({
  service: 'locations',
  method: 'put',
  baseActionType: ACTION_TYPE.updateLocation,
  getId: ({ key }) => key,
  transformOutput: (response, { sizeOrder }) => ({
    ...response,
    sizeOrder, // server always returns 0 regardless of new order value, keep original sizeOrder until location is refreshed
  }),
  notificationsItemDescriptor: 'location',
  throwApiErrors: true,
});

export const deleteLocation = makeRestApiAction({
  service: 'locations',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteLocation,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (response, location) => location, // "output" original location so payload is deleted location
  notificationsItemDescriptor: 'location',
});
