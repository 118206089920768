import { connect } from 'react-redux';

import { FuelTaxMileageSummaryReport } from './FuelTaxMileageSummaryReport';
import { selectFuelTaxMileages, fetchTaxMileagesOptimized } from '../../../../data/reports';

export const FuelTaxMileageSummaryReportContainer = connect(
  (state) => ({
    fuelTaxMileages: selectFuelTaxMileages(state),
  }),
  { fetchTaxMileages: fetchTaxMileagesOptimized }
)(FuelTaxMileageSummaryReport);
