import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
} from '../../../apiReducerSupport';

const initialState = {
  rules: {
    data: [],
    fetches: [],
  },
  ruleSets: {
    data: [],
    fetches: [],
  },
  rulesPredefined: {
    data: [],
    fetches: [],
  },
};

export const ACTION_TYPE = {
  createRule: 'administrator/configurations/performanceRules/rules/createRule',
  createRules: 'administrator/configurations/performanceRules/rules/createRules',
  createRuleSet: 'administrator/configurations/performanceRules/ruleSets/createRuleSet',
  deleteRule: 'administrator/configurations/performanceRules/rules/deleteRule',
  deleteRuleSet: 'administrator/configurations/performanceRules/ruleSets/deleteRuleSet',
  fetchRule: 'administrator/configurations/performanceRules/rules/fetchRule',
  fetchRules: 'administrator/configurations/performanceRules/rules/fetchRules',
  fetchRuleSet: 'administrator/configurations/performanceRules/ruleSets/fetchRuleSet',
  fetchRuleSets: 'administrator/configurations/performanceRules/ruleSets/fetchRuleSets',
  fetchRulesPredefined: 'administrator/configurations/performanceRules/rules/fetchRulesPredefined',
  updateRule: 'administrator/configurations/performanceRules/rules/updateRule',
  updateRuleConfiguration:
    'administrator/configurations/performanceRules/rules/updateRuleConfiguration',
  updateRuleSet: 'administrator/configurations/performanceRules/ruleSets/updateRuleSet',
};

export const performanceRulesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRules)) {
      draft.rules.data = sortBy(payload, ['type', (rules) => rules.description.toLowerCase()]);
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchRules)) {
      const { id, requestData } = request;
      draft.rules.fetches = shallowMergeEntities(state.rules.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchRules)) {
      draft.rules.fetches = removeEntity(state.rules.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRulesPredefined)) {
      draft.rulesPredefined.data = payload;
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchRule,
        ACTION_TYPE.createRule,
        ACTION_TYPE.createRules,
        ACTION_TYPE.updateRule,
      ])
    ) {
      draft.rules.data = shallowMergeEntities(state.rules.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteRule)) {
      draft.rules.data = removeEntity(state.rules.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRuleSets)) {
      draft.ruleSets.data = sortBy(payload, 'name');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchRuleSets)) {
      const { id, requestData } = request;
      draft.ruleSets.fetches = shallowMergeEntities(state.ruleSets.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchRuleSets)) {
      draft.ruleSets.fetches = removeEntity(state.ruleSets.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchRuleSet, ACTION_TYPE.createRuleSet])) {
      draft.ruleSets.data = shallowMergeEntities(state.ruleSets.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteRuleSet)) {
      draft.ruleSets.data = removeEntity(state.ruleSets.data, payload, 'key');
    }
  });
