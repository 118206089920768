import { connect } from 'react-redux';

import { AppRoot } from './AppRoot';
import {
  selectAccessToken,
  selectMenu,
  selectSystemActiveOu,
  selectSystemOus,
  selectSystemTenant,
  selectSystemUser,
  setMenu,
} from '../../../data/system';

export const AppRootContainer = connect(
  (state) => ({
    accessToken: selectAccessToken(state),
    activeOu: selectSystemActiveOu(state),
    menu: selectMenu(state),
    ous: selectSystemOus(state),
    tenant: selectSystemTenant(state),
    user: selectSystemUser(state),
  }),
  { setMenu }
)(AppRoot);
