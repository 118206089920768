import { connect } from 'react-redux';

import { fuelTax, selectVehiclesBreadcrumbs } from '../../../../../../data/reports';
import { FuelTaxMapReport } from './map';

export const FuelTaxMapContainer = connect(
  (state, { fuelTaxId }) => ({
    fuelTaxDetails: fuelTax.selectors.selectFuelTaxDetails(state, fuelTaxId),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
  }),
  { fetchFuelTax: fuelTax.actions.fetchFuelTaxOptimized }
)(FuelTaxMapReport);
