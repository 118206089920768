import localforage from 'localforage';

localforage.config({
  name: 'Navistream Webclient',
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
});

const localforageStore = (name, expiryDays = 30) => {
  const instance = localforage.createInstance({ name });

  const expireBefore = Date.now() - expiryDays * 24 * 60 * 60 * 1000;
  instance.iterate(({ accessedAt }, key) => {
    if (accessedAt < expireBefore) instance.removeItem(key);
  });

  return {
    get: async (id) => {
      const item = await instance.getItem(id);
      if (!item) throw new Error('Item not found.');
      instance.setItem(id, { ...item, accessedAt: Date.now() }); // no need to await
      return item.data;
    },
    create: async (entity) => {
      const now = Date.now();
      await instance.setItem(entity.id, {
        data: entity,
        createdAt: now,
        accessedAt: now,
      });
      return entity;
    },
  };
};

export const localReportViews = localforageStore('localReportViews');
