import { connect } from 'react-redux';

import { InspectionTaskDetail } from './detail';

import { selectSystemOus } from '../../../../../data/system';

import { inspectionTask } from '../../../../../data/administration/configurations';

export const InspectionTaskDetailContainer = connect(
  (state, props) => ({
    inspectionTasks: inspectionTask.selectors.selectInspectionTasks(state),
    inspectionTask: inspectionTask.selectors.selectInspectionTask(state, props),
    ous: selectSystemOus(state),
  }),
  {
    fetchInspectionTask: inspectionTask.actions.fetchInspectionTaskOptimized,
    fetchInspectionTasks: inspectionTask.actions.fetchInspectionTasksOptimized,
    createInspectionTask: inspectionTask.actions.createInspectionTask,
    updateInspectionTask: inspectionTask.actions.updateInspectionTask,
    deleteInspectionTask: inspectionTask.actions.deleteInspectionTask,
  }
)(InspectionTaskDetail);
