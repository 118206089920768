import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BasicDialog, Button, Icon } from 'stti-react-common';

export const MalfunctionAndDiagnosticInfoDialog = ({ isOpen, onClose }) => {
  const [isCanada, setIsCanada] = useState(true);

  return (
    <BasicDialog
      className="DriverLogDetailReport__MalfunctionAndDiagnosticInfoDialog"
      isOpen={isOpen}
      onClose={onClose}
      title={`Malfunction Reporting Instruction Sheet - ${isCanada ? 'Canada' : 'USA'}`}
      buttons={[
        {
          label: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <Button
        icon="flag"
        variant="contained"
        label="Canada"
        disabled={isCanada}
        onClick={() => setIsCanada(true)}
      />
      <Button
        icon="flag"
        variant="contained"
        label="USA"
        disabled={!isCanada}
        onClick={() => setIsCanada(false)}
      />

      {isCanada && (
        <div>
          <p>
            <i>
              The following document is prepared in accordance with SOR-2005-313 Section 78 (2 - 7):
            </i>
          </p>
          <p>
            If this ELD tablet is found to be in a Malfunction state as indicated in the chart
            below,then the following MUST be complied to by the driver AND the motor carrier of the
            CMV.
          </p>
          <p>
            <b>
              If an ELD malfunctions, a <u>driver</u> must:
            </b>
          </p>
          <ol>
            <li>
              Notify the motor carrier that is operating the commercial vehicle as soon as the
              vehicle is parked.
            </li>
            <li>
              The driver shall record, in the record of duty status on the day on which he or she
              noticed the malfunction or data diagnostic code, the following information:
              <ol type="a">
                <li>
                  The malfunction or data diagnostic code as set out in Table 4 of Schedule 2 of the
                  Technical Standard;
                </li>
                <li>
                  The date and time when the malfunction or data diagnostic code was noticed; and
                </li>
                <li>
                  The time when notification of the malfunction or data diagnostic code was
                  transmitted to the motor carrier.
                </li>
              </ol>
            </li>
            <li>
              Record the code referred to in the point above in each record of duty status following
              the day on which the code was noticed, until the ELD is repaired or replaced.
            </li>
          </ol>

          <p>
            <b>
              If an ELD malfunctions, a <u>motor carrier</u> must:
            </b>
          </p>
          <ol>
            <li>
              Within 14 days after the day on which it was notified of an ELD malfunction or data
              diagnostic code by the driver or otherwise became aware of it, or at the latest, upon
              return of the driver to the home terminal from a planned trip if that return exceeds
              the 14-day period, repair or replace the ELD.
            </li>
            <li>
              The motor carrier shall maintain a register of ELD malfunction or data diagnostic
              codes for ELDs installed or used in commercial vehicles that it operates for which a
              malfunction was noticed, and that register shall contain the following information:
              <ol type="a">
                <li>The name of the driver who noticed the malfunction or data diagnostic code;</li>
                <li>
                  The name of each driver that used the commercial vehicle following the discovery
                  of the malfunction or data diagnostic code until the ELD was repaired or replaced;
                </li>
                <li>The make, model and serial number of the ELD;</li>
                <li>
                  The licence plate of the commercial vehicle in which the ELD is installed or used,
                  or the Vehicle Identification Number;
                </li>
                <li>
                  The date when the malfunction or data diagnostic code was noticed and the location
                  of the commercial vehicle on that date, as well as the date when the motor carrier
                  was notified or otherwise became aware of the code;
                </li>
                <li>The date the ELD was replaced or repaired; and</li>
                <li>
                  A concise description of the actions taken by the motor carrier to repair or
                  replace the ELD.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      )}

      {!isCanada && (
        <div>
          <p>
            <i>
              The following document is prepared in accordance with 49 CFR §395.22 (h) (3) which
              states:
            </i>
          </p>
          <p>
            (h) In-vehicle information. A motor carrier must ensure that its drivers possess onboard
            a commercial motor vehicle an ELDinformation packet containing the following items:
          </p>
          <p style={{ marginLeft: 85 }}>
            (3) An instruction sheet for the driver describing ELD malfunction reporting
            requirements and record keeping procedures during ELD malfunctions
          </p>
          <p>
            If this ELD tablet is found to be in a Malfunction state as indicated in the chart
            below,then the following MUST be complied to by the driver AND the motor carrier of the
            CMV.
          </p>
          <p>
            <b>
              If an ELD malfunctions, a <u>driver</u> must:
            </b>
          </p>
          <ol>
            <li>
              Note the malfunction of the ELD and provide written notice of the malfunction to the
              motor carrier within 24 hours;
            </li>
            <li>
              Reconstruct the record of duty status (RODS) for the current 24-hour period and the
              previous 7 consecutive days, and record the RODS on graph-grid paper logs, or
              electronic logging software, that comply with 49 CFR 395.8, unless the driver already
              has the records or retrieves them from the ELD; and
            </li>
            <li>
              Continue to manually prepare RODS in accordance with 49 CFR 395.8 until the ELD is
              serviced and back in compliance. The recording of the driver&apos hours of service on
              a paper log, or electronic logging software, cannot continue for more than 8 days
              after the malfunction; a driver that continues to record his or her hours of service
              on a paper log, or electronic logging software, beyond 8 days risk being placed out of
              service.
            </li>
          </ol>
          <p>
            <b>
              If an ELD malfunctions, a <u>motor carrier</u> must:
            </b>
          </p>
          <ol>
            <li>
              Correct, repair, replace, or service the malfunctioning ELD within eight days of
              discovering the condition or a driver&apos notification to the motor carrier,
              whichever occurs first; and
            </li>
            <li>
              Require the driver to maintain a paper record of duty status (RODS) until the ELD is
              back in service.
            </li>
          </ol>
          <p>
            <u>Note about when to use paper logs:</u>
          </p>
          <p>
            &quotA driver should only use paper logs, or electronic logging software, or other
            electronic means to record their HOS if theELD malfunction hinders the accurate
            recording of the driver&apos hours-of-service data (i.e., 10/11, 14/15, 60/70 hours; or
            30 minute).&quot
          </p>
        </div>
      )}

      <div style={{ border: '1px solid #000', padding: 10 }}>
        <p>LEGEND</p>
        <p>
          <Icon
            icon="error"
            style={{ color: '#ffa500', position: 'relative', top: 3, marginRight: 3 }}
          />
          <span>
            Diagnostic - No Driver action is required. Recommended steps to resolve the issue are
            outlined in the chart below.
          </span>
        </p>
        <p>
          <Icon
            icon="warning"
            style={{ color: '#ff0000', position: 'relative', top: 3, marginRight: 3 }}
          />
          <span>
            Malfunction - Follow required steps as stated above as well as any additional steps
            outlined in the chart below.
          </span>
        </p>
      </div>
      <br />
      <table className="InfoTable">
        <tr>
          <td>Code</td>
          <td>Condition</td>
          <td>State</td>
          <td>Definition</td>
          <td>Driver recourse</td>
        </tr>
        <tr>
          <td>3</td>
          <td>MISSING_DATA</td>
          <td>
            <Icon icon="error" style={{ color: '#ffa500', position: 'relative', top: 3 }} />
          </td>
          <td>Required data is missing from the last ELD event recorded.</td>
          <td>The issue should auto-resolve. If it does not, contact your administrator.</td>
        </tr>
        <tr>
          <td>5</td>
          <td>
            <p>UNIDENTIFIED_DRIVER</p>
            <p>
              <i>
                * Unidentified driving time occurs when the vehicle is moving without a Driver
                logged in.
              </i>
            </p>
          </td>
          <td>
            <Icon icon="error" style={{ color: '#ffa500', position: 'relative', top: 3 }} />
          </td>
          <td>
            &gt;30 min of unidentified driving time has accumulated in the past 7 days. This state
            will clear when &gt;= 15 min unidentified driving time remains.
          </td>
          <td>The issue should auto-resolve. If it does not, contact your administrator.</td>
        </tr>
        <tr>
          <td>
            <table className="InnerTable">
              <tr>
                <td>1</td>
              </tr>
              <tr>
                <td>P</td>
              </tr>
            </table>
          </td>
          <td>POWER</td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>
                  <Icon icon="error" style={{ color: '#ffa500', position: 'relative', top: 3 }} />
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>The ELD failed to start-up within 1min of your engine starting.</td>
              </tr>
              <tr>
                <td>
                  &gt;30 min of power disconnect has accumulated when the last known engine state
                  was on.
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>Make sure the ELD is at the login screen before turning your vehicle on.</td>
              </tr>
              <tr>
                <td>
                  Make sure you turn off your engine before disconnecting power. Your logs may be
                  incorrect as a result.
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="InnerTable">
              <tr>
                <td>2</td>
              </tr>
              <tr>
                <td>E</td>
              </tr>
            </table>
          </td>
          <td>ENGINE_SYNC</td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>
                  <Icon icon="error" style={{ color: '#ffa500', position: 'relative', top: 3 }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>The ELD has not detected theVehicle’s ECM in the past 5 sec.</td>
              </tr>
              <tr>
                <td>
                  &gt;30 min of no data being received from the Vehicle’s ECM has accumulated.
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>Make sure the ELD is connected viaUSB or WiFi to the Vehicle’s ECM.</td>
              </tr>
              <tr>
                <td>
                  Please advise your administrator and/or installer to schedule ECM connection
                  troubleshooting.
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="InnerTable">
              <tr>
                <td>4</td>
              </tr>
              <tr>
                <td>S</td>
              </tr>
            </table>
          </td>
          <td>DATA_TRANSFER</td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>
                  <Icon icon="error" style={{ color: '#ffa500', position: 'relative', top: 3 }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>The last data transfer test has failed.</td>
              </tr>
              <tr>
                <td>Subsequent data transfer checks have failed.</td>
              </tr>
            </table>
          </td>
          <td>
            <table className="InnerTable">
              <tr>
                <td>None required. The issue should auto-resolve.</td>
              </tr>
              <tr>
                <td>
                  You may be unable to send your ELDlogs to a peace officer. Please contact your
                  administrator to perform additional troubleshooting steps.
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>T</td>
          <td>TIMING</td>
          <td>
            <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
          </td>
          <td>The time on your ELDmay be incorrect.</td>
          <td>
            <p>The issue should auto-resolve. If it does not, contact your administrator.</p>
            <p>* We recommend keeping a paper log until the issue is resolved *</p>
          </td>
        </tr>
        <tr>
          <td>L</td>
          <td>POSITIONING</td>
          <td>
            <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
          </td>
          <td>Unable to accurately determine your GPS location.</td>
          <td>
            <p>The issue should auto-resolve. If it does not, contact your administrator.</p>
            <p>* We recommend keeping a paper log until the issue is resolved *</p>
          </td>
        </tr>
        <tr>
          <td>R</td>
          <td>DATA_RECORDING</td>
          <td>
            <Icon icon="warning" style={{ color: '#ff0000', position: 'relative', top: 3 }} />
          </td>
          <td>There is an issue saving data to the ELD.</td>
          <td>
            <p>Please contact your administrator.</p>
            <p>* We recommend keeping a paper log until the issue is resolved *</p>
          </td>
        </tr>
      </table>
    </BasicDialog>
  );
};

MalfunctionAndDiagnosticInfoDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
