import React from 'react';
import { TextInput, Chip, Icon } from 'stti-react-common';

import { ReportControl } from './ReportControl';

export const SearchTextInput = (props) => (
  <ReportControl
    name="searchText"
    group="dataGrid"
    startAdornment={<Icon icon="search" />}
    canDeleteSummary
    render={(renderProps) => <TextInput {...renderProps} />}
    renderSummary={(renderProps, { value }) =>
      value && <Chip {...renderProps} label={`Contains "${value}"`} />
    }
    printConfig={({ value }) =>
      value && {
        label: 'Contains',
        value: `"${value}"`,
      }
    }
    {...props}
  />
);
