import React from 'react';

import { InspectionTaskSummaryContainer } from './Summary/connect';
import { InspectionTaskDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

import { organizationDetail } from '../Organizations/routes';

import { FEATURES } from '../../../../features';

export const inspectionTasksSummary = configureRoute({
  name: 'Tasks',
  group: administrationGroups.configurations,
  order: 6,
  path: '/tasks',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  hidden: FEATURES.admin.hideInspectionTasksFromMainMenu,
  render: (props) => (
    <InspectionTaskSummaryContainer
      {...props}
      openInspectionTaskDetail={inspectionTaskDetail.open}
      openInspectionTaskCreate={inspectionTaskCreate.open}
      openOrganizationDetail={organizationDetail.open}
    />
  ),
});

export const inspectionTaskCreate = configureRoute({
  path: '/task/new',
  switchKey: 'inspectionTask',
  RouteFrame: BasicRouteFrame,
  render: (props) => <InspectionTaskDetailContainer {...props} mode="create" />,
});

export const inspectionTaskDetail = configureRoute({
  path: '/task/:taskId',
  switchKey: 'inspectionTask',
  RouteFrame: BasicRouteFrame,
  render: (props) => <InspectionTaskDetailContainer {...props} />,
});
