import React, { useMemo } from 'react';

import { GoogleMap } from 'stti-react-common';

import { symbols } from '../../../../commons/MapsCommon';

const { Route } = GoogleMap;

export const MapHighLightSections = (highlightSections) =>
  useMemo(
    () =>
      highlightSections.length > 0 &&
      highlightSections.map((row) => (
        <Route path={row} line={symbols.speedViolationLine} disableAutoFocus />
      )),
    []
  );
