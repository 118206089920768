import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Button, BasicDialog, Form, TextInput, useBooleanState } from 'stti-react-common';

import { SectionPanel, InfoField } from '../../../commons/ReportsCommon';

const { useFormController, Control } = Form;

const renderNotesSummary = (notes) => {
  const delimiter = '- - - - - - - - - -';
  const notesArray = notes.split(delimiter);

  if (notesArray.length === 0) {
    return null;
  }

  if (notesArray.length < 4) {
    return (
      notes.length > 0 &&
      notes
        .split(delimiter)
        .map(
          (note) =>
            note.trim().length > 0 && (
              <Chip label={`${note.substr(0, 60)} ${note.length > 60 && '...'}`} />
            )
        )
    );
  }
  return <Chip label={`${notesArray.length - 1} Notes`} />;
};

export const NotesPanel = ({ inspection, user, onConfirm, ...restProps }) => {
  const form = useFormController();

  const [isNoteDialogOpen, openNoteDialog, closeNoteDialog] = useBooleanState();

  const { resetControls, controls, hasErrors } = form;
  const { notes } = inspection || {};

  return (
    <SectionPanel
      name="notesPanel"
      title="Notes"
      renderSummaries={() => inspection && renderNotesSummary(notes)}
    >
      <InfoField name="notes" label="Notes" value={notes} />
      <div className="ExceptionDetailReport__notesButtons">
        <Button
          icon="add"
          label="Add Note"
          onClick={() => {
            openNoteDialog();
          }}
        />
      </div>
      <BasicDialog
        title="Add Note"
        className="InspectionDetailReport__NotesPanelDialog"
        maxWidth="xs"
        fullWidth
        isOpen={isNoteDialogOpen}
        onOpen={resetControls}
        onClose={closeNoteDialog}
        buttons={[
          { label: 'Cancel', onClick: closeNoteDialog },
          {
            label: 'Save',
            onClick: () => {
              onConfirm({ newNote: controls.newNote, user, id: inspection.id, inspection });
              closeNoteDialog();
            },
            disabled: hasErrors,
          },
        ]}
        {...restProps}
      >
        <Form form={form}>
          <Control
            name="newNote"
            Component={TextInput}
            label="Add Note"
            defaultValue=""
            error={({ value }) => !value.trim()}
            multiline
            autoFocus
          />
        </Form>
      </BasicDialog>
    </SectionPanel>
  );
};

NotesPanel.propTypes = {
  inspection: PropTypes.object,
  user: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};
