import BetterPropTypes from 'better-prop-types';

export const cameraRecord = BetterPropTypes.shape({
  cameraId: BetterPropTypes.number.isOptionalButNotNull,
  cameraName: BetterPropTypes.string.isOptionalButNotNull,
  intervals: BetterPropTypes.array.isOptionalButNotNull,
  provider: BetterPropTypes.string.isOptionalButNotNull,
});

export const cameraRecords = BetterPropTypes.arrayOf(cameraRecord);

export const cameraEvent = BetterPropTypes.shape({
  provider: BetterPropTypes.string.isOptionalButNotNull,
  events: BetterPropTypes.array.isOptionalButNotNull,
});

export const cameraEvents = BetterPropTypes.arrayOf(cameraEvent);

export const cameraTrip = BetterPropTypes.shape({
  provider: BetterPropTypes.string.isOptionalButNotNull,
  start: BetterPropTypes.object.isOptionalButNotNull,
  end: BetterPropTypes.object.isOptionalButNotNull,
  duration: BetterPropTypes.number.isOptionalButNotNull,
  distance: BetterPropTypes.number.isOptionalButNotNull,
  maxSpeed: BetterPropTypes.number.isOptionalButNotNull,
  minSpeed: BetterPropTypes.number.isOptionalButNotNull,
  eventsCount: BetterPropTypes.number.isOptionalButNotNull,
  virtualEventsCount: BetterPropTypes.number.isOptionalButNotNull,
});

export const cameraTrips = BetterPropTypes.arrayOf(cameraTrip);

export const cameraBreadcrumb = BetterPropTypes.shape({
  accuracy: BetterPropTypes.number.isOptionalButNotNull,
  latitude: BetterPropTypes.number.isOptionalButNotNull,
  longitude: BetterPropTypes.number.isOptionalButNotNull,
  altitude: BetterPropTypes.number.isOptionalButNotNull,
  date: BetterPropTypes.string.isOptionalButNotNull,
  speed: BetterPropTypes.number.isOptionalButNotNull,
  postedSpeed: BetterPropTypes.number.isRequiredButNullable,
  postedSpeedHgv: BetterPropTypes.number.isRequiredButNullable,
  postedSpeedTrailer: BetterPropTypes.number.isRequiredButNullable,
});

export const cameraBreadcrumbs = BetterPropTypes.arrayOf(cameraBreadcrumb);
