import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  actionTypeFailure,
  eagerlyMergeEntity,
  revertEagerlyMergedEntity,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchLocations: 'administration/resources/locations/fetchLocations',
  fetchLocation: 'administration/resources/locations/fetchLocation',
  updateLocation: 'administration/resources/locations/updateLocation',
  createLocation: 'administration/resources/locations/createLocation',
  deleteLocation: 'administration/resources/locations/deleteLocation',
};

export const locationsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchLocations)) {
      draft.data = sortBy(payload, [(location) => location.name.toLowerCase()]);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchLocation,
        ACTION_TYPE.createLocation,
        ACTION_TYPE.updateLocation,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteLocation)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.updateLocation)) {
      draft.data = eagerlyMergeEntity(state.data, payload, 'key');
    }

    if (actionTypeFailure(type, ACTION_TYPE.updateLocation)) {
      draft.data = revertEagerlyMergedEntity(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [ACTION_TYPE.updateLocation, ACTION_TYPE.createLocation])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteLocation)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchLocation, ACTION_TYPE.fetchLocations])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchLocation, ACTION_TYPE.fetchLocations])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
