import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, DutyStatusChart } from 'stti-react-common';
import moment from 'moment-timezone';
import { groupBy, keys, values, uniqueId, flatten, sortBy } from 'lodash';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { useFormats } from '../../../../commons/Formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { getChartEndDate } from '../../../../../helpers/reports/driverLogsUtils';
import { driverEventsColumnDefs } from './columnDefs';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;
const { StatusLine } = DutyStatusChart;

export const DriverEventsCard = ({ log, printLayoutMode }) => {
  const { formatUnit } = useFormats();
  const { eldEvents } = log || {};

  const {
    timeZone,
    logDate,
    movementIntervals,
    dutyStatusChanges,
    offDutySleeperBerthTotal,
    offDutyNotSleeperBerthTotal,
    onDutyDrivingTotal,
    onDutyNotDrivingTotal,
    timeZoneStatus,
  } = log || {};

  const dutyIntervalsDataGridController = useDataGridController({
    columnDefs: driverEventsColumnDefs,
    gridOptions,
  });

  const dutyIntervalsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  const specialDrivingEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(
      ({ eventType }) =>
        eventType.includes('PERSONAL_USE') ||
        eventType.includes('YARD_MOVEMENT') ||
        eventType.includes('DUTY_STATUS')
    );
  }, [eldEvents]);

  const mergedEvents = [...specialDrivingEvents];
  mergedEvents.sort((a, b) => (a.date > b.date ? 1 : -1));

  const events = groupBy(mergedEvents, ({ date }) => moment(date).format('YYYY-MM-DD'));

  const arr = [];
  keys(events).forEach((value, index) => {
    arr.push({
      key: uniqueId(),
      startOfDayRecord: true,
      eldSequence: index - 1,
      date: moment(value).startOf('day').toISOString(),
    });
    arr.push(values(events)[index]);
  });

  const remappedEvents = sortBy(flatten(arr), ['date', 'eldSequence']);

  const chartTotals =
    offDutyNotSleeperBerthTotal +
    offDutySleeperBerthTotal +
    onDutyDrivingTotal +
    onDutyNotDrivingTotal;

  return (
    <Card className="CanadianEldLogDetailReport__DriverEventsCard" fullWidth>
      {movementIntervals && (
        <DutyStatusChart
          startDate={moment.tz(logDate, timeZone).toISOString()}
          endDate={getChartEndDate(logDate, timeZone).toISOString()}
          intervals={movementIntervals}
          statusTotals={{
            offDuty: formatUnit('duration', offDutyNotSleeperBerthTotal),
            sleeper: formatUnit('duration', offDutySleeperBerthTotal),
            driving: formatUnit('duration', onDutyDrivingTotal),
            onDuty: formatUnit('duration', onDutyNotDrivingTotal),
            total: formatUnit('duration', chartTotals),
          }}
        >
          <StatusLine intervals={movementIntervals} timeZoneStatus={timeZoneStatus} />
        </DutyStatusChart>
      )}
      <br />
      <br />
      <br />
      <div className="CanadianEldLogDetailReport__DriverEventsCard__eventsHeader">
        <h4>
          Changes in driver&apos;s Duty Status, Intermediate Logs and Special Driving Conditions
          (Personal Use and Yard Moves)
        </h4>
      </div>
      {dutyStatusChanges && (
        <ControlledDataTable
          controller={dutyIntervalsDataGridController}
          components={dutyIntervalsDataGridComponents}
          rows={remappedEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

DriverEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  printLayoutMode: PropTypes.string,
};
