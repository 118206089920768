import { combineReducers } from 'redux';

import { activity } from './activities';
import { apiKey } from './apiKeys';
import { companionApp } from './companionApps';
import { delay } from './delays';
import { dispatchAbortReason } from './dispatchAbortReasons';
import { inspectionTask } from './inspectionTasks';
import { inspectionTemplate } from './inspectionTemplates';
import { organization } from './organizations';
import { performanceRule } from './performanceRules';
import { speedLimit } from './speedLimits';
import { tenant } from './tenants';

export const configurations = combineReducers({
  activities: activity.reducer.activitiesReducer,
  apiKeys: apiKey.reducer.apiKeysReducer,
  companionApps: companionApp.reducer.companionAppsReducer,
  delays: delay.reducer.delaysReducer,
  dispatchAbortReasons: dispatchAbortReason.reducer.dispatchAbortReasonsReducer,
  inspectionTasks: inspectionTask.reducer.inspectionTasksReducer,
  inspectionTemplates: inspectionTemplate.reducer.inspectionTemplatesReducer,
  organizations: organization.reducer.organizationsReducer,
  performanceRules: performanceRule.reducer.performanceRulesReducer,
  speedLimits: speedLimit.reducer.speedLimitsReducer,
  tenants: tenant.reducer.tenantsReducer,
});

export {
  activity,
  apiKey,
  companionApp,
  delay,
  dispatchAbortReason,
  inspectionTask,
  inspectionTemplate,
  organization,
  performanceRule,
  speedLimit,
  tenant,
};
