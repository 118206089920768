import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressOverlay } from 'stti-react-common';

import { Location } from '../../../helpers/navigation/Location';

import './PrintSupport.scss';

export const PrintSupport = ({ printMode, setPrintMode }) => {
  const [locationQueryParams, setLocationQueryParams] = useState({});

  const updateLocationQueryParams = useCallback(() => {
    setLocationQueryParams(new Location().query);
  }, []);

  const {
    print, // may be "true"
    attach, // may be comma-delimted string of file types
    includeDetail, // may be "true"
    suppressMap, // may be "true"
    webclientViewReadyDelay, // may be string number in ms
  } = locationQueryParams;

  useEffect(() => {
    updateLocationQueryParams();
    window.addEventListener('hashchange', updateLocationQueryParams);
    return () => window.removeEventListener('hashchange', updateLocationQueryParams);
  }, []);

  useEffect(() => {
    if (!!printMode.print === !!print) return;
    setPrintMode({
      print: !!print,
      attach: attach && attach.split(','),
      includeDetail: !!includeDetail,
      suppressMap: !!suppressMap,
      webclientViewReadyDelay: Number(webclientViewReadyDelay) || 20000,
    });
  }, [print]);

  return (
    <>
      {!printMode.print && (
        <div className="PrintSupport__notice">
          <hr className="PrintSupport__notice__hr" />
          For best results, use the print button in the top right corner.
          <hr className="PrintSupport__notice__hr" />
        </div>
      )}
      <ProgressOverlay className="PrintSupport__overlay" isOpen={!!printMode.print} delay={0} dark>
        Preparing print...
      </ProgressOverlay>
    </>
  );
};

PrintSupport.propTypes = {
  printMode: PropTypes.shape({
    print: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    attach: PropTypes.array,
    includeDetail: PropTypes.bool,
  }).isRequired,
  setPrintMode: PropTypes.func.isRequired,
};
