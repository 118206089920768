import { columnDef } from '../../../../../commons/ControlledDataGrid';
import { titleCaseValueFormatter } from '../../../../../../helpers/formats';

export const driverEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Time',
    variant: 'datelessTime',
    width: 70,
  }),
  columnDef.textColumn({
    field: 'dutyStatus',
    headerName: 'Events',
    valueGetter: ({ data }) => {
      const { dutyStatus, annotation } = data;
      if (!dutyStatus) return annotation;
      return titleCaseValueFormatter({ value: dutyStatus });
    },
  }),
  columnDef.textColumn({
    field: 'recordStatus',
    headerName: 'Status',
  }),
  columnDef.textColumn({
    field: 'geoLocation',
    headerName: 'Location',
  }),
  columnDef.textColumn({
    field: 'location',
    headerName: 'Latitude/Longitude',
    valueGetter: ({ data }) => {
      const { location } = data;
      if (!location) return '—';
      const { latitude, longitude } = location;
      return `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;
    },
  }),
  columnDef.distanceColumn({
    field: 'accumulatedVehicleDistance',
    headerName: 'Distance',
  }),
  columnDef.durationColumn({
    field: 'elapsedEngineHours',
    headerName: 'Engine Hours',
    overrideFormat: 'h.0',
  }),
  columnDef.textColumn({
    field: 'origin',
    headerName: 'Origin',
  }),
  columnDef.textColumn({
    field: 'annotation',
    headerName: 'Comment',
    valueGetter: ({ data }) => {
      const { dutyStatus, annotation } = data;
      if (!dutyStatus) return '—';
      return annotation;
    },
  }),
  columnDef.actionButtonsColumn({
    tableCellRenderer: 'Actions',
  }),
];
