// EXTERNAL LIB IMPORTS
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { Form, ProgressOverlay, ToggleInput } from 'stti-react-common';

// COMMON LIB IMPORTS
import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

// HELPERS LIB IMPORTS
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { useApiError } from '../../../../../helpers/hooks';

// MISC IMPORTS
import './configuration.scss';

export const VehicleConfiguration = ({
  user,
  tenant,
  tenantOu,
  ous,
  isSuperAdmin,
  fetchTenantVehicleConfig,
  updateTenantVehicleConfig,
  mode: initialMode = 'edit',
  route,
}) => {
  const [mode, setMode] = useState(initialMode);
  const [loading, setLoading] = useState(true);
  const { apiError, setApiError } = useApiError();

  const { useFormController, Control } = Form;
  const form = useFormController({});
  const { controls: formControls, resetControls } = form;

  // This api call inside this effect needs to be called after we have the complete system tenant object
  // So to make sure we have all the required objects to safelly call the endpoint we need this control flow
  useEffect(() => {
    if (tenantOu && !isNil(tenant.enableCumminsEngineDiagnostics)) {
      fetchTenantVehicleConfig(tenantOu.key).then(() => {
        resetControls();
        setLoading(false);
      });
    }
  }, [ous, tenant.enableCumminsEngineDiagnostics]);

  if (!isEmpty(user) && !isSuperAdmin) {
    route.close();
  }

  return (
    <div className="VehicleConfiguration">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Vehicles Configuration"
          form={form}
          itemTitle={tenant.name}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={{ ...tenant, ...formControls }}
          onClose={route.close}
          updateItem={updateTenantVehicleConfig}
          resetControls={resetControls}
        >
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control
              name="filterVehiclesByOu"
              Component={ToggleInput}
              label="Disable Vehicle Sharing"
              autoFocus
              defaultValue={tenant.filterVehiclesByOu}
              disabled={!isSuperAdmin}
            />
            <small>
              (This setting will disable the ability for all drivers to select vehicles and trailers
              from organizations they are not a member of.)
            </small>
            <Control
              name="disableAddTractorByOu"
              Component={ToggleInput}
              label="Disable Driver Vehicle Creation"
              autoFocus
              defaultValue={tenant.disableAddTractorByOu}
              disabled={!isSuperAdmin}
            />
            <small>
              (This setting will disable the ability for all drivers to add new vehicles from the
              tablet.)
            </small>
            <Control
              name="disableAddTrailerByOu"
              Component={ToggleInput}
              label="Disable Driver Trailer Creation"
              autoFocus
              defaultValue={tenant.disableAddTrailerByOu}
              disabled={!isSuperAdmin}
            />
            <small>
              (This setting will disable the ability for all drivers to add new trailers from the
              tablet.)
            </small>
          </div>
        </CrudAdminItem>
      )}
    </div>
  );
};

VehicleConfiguration.propTypes = {
  user: customPropTypes.user,
  tenant: PropTypes.object.isRequired,
  tenantOu: PropTypes.object.isRequired,
  ous: PropTypes.array.isRequired,
  isSuperAdmin: PropTypes.bool,
  fetchTenantVehicleConfig: PropTypes.func.isRequired,
  updateTenantVehicleConfig: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['edit']),
  route: PropTypes.object.isRequired,
};
