import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniq, uniqBy } from 'lodash';
import { ToggleInput, Chip } from 'stti-react-common';

import {
  SelectFilterInput,
  SectionPanel,
  SectionPanelMenu,
  ControlGroupSummary,
  OutlierFiltersDialogInput,
  OutlierFilterInput,
  ReportControl,
} from '../../../commons/ReportsCommon';

import {
  filterRowsByUnitRange,
  filterRowsByBaselineRange,
  invertFilteredRows,
} from '../../../../helpers/reports/standardFilters';

export const FiltersPanel = ({ rows }) => {
  const vehicles = uniqBy(
    rows.map((row) => row?.vehicle),
    'key'
  );
  const vehicleTypes = uniq(rows.map((row) => row?.vehicle?.type));
  const drivers = uniqBy(
    rows.map((row) => row?.driver),
    'key'
  );

  const getOptions = useMemo(
    () => ({
      vehicleNames: () =>
        sortBy(
          vehicles.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      vehicleTypes: () =>
        sortBy(
          vehicleTypes.map((type) => ({ label: type, value: type })),
          'label'
        ),
      driverNames: () =>
        sortBy(
          drivers.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      jurisdictions: () =>
        sortBy(
          uniqBy(
            rows.map(({ jurisdiction }) => ({ label: jurisdiction, value: jurisdiction })),
            'value'
          ),
          'label'
        ),
      vendors: () =>
        sortBy(
          uniqBy(
            rows.map(({ vendor }) => ({ value: vendor, label: vendor })),
            'value'
          ),
          'label'
        ),
      locationNames: () =>
        sortBy(
          uniqBy(
            rows.map(({ location }) => ({ value: location?.name, label: location?.name })),
            'value'
          ),
          'label'
        ),
      fuelTypes: () =>
        sortBy(
          uniq(
            rows.map(({ fuelType }) => ({ value: fuelType, label: fuelType })),
            'value'
          ),
          'label'
        ),
    }),
    [rows]
  );
  return (
    <SectionPanel
      name="filtersPanel"
      title="Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="vehicleNames"
        field="vehicle.key"
        displayValue="vehicle.name"
        group="filters"
        filterGroup="filters"
        label="Vehicle"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
      />
      <SelectFilterInput
        name="vehicleTypes"
        field="vehicle.type"
        group="filters"
        filterGroup="filters"
        label="Vehicle Type"
        summaryPrefix="Vehicle Type: "
        itemPlural="vehicle types"
        autoGridArea
        getOptions={getOptions.vehicleTypes}
      />
      <SelectFilterInput
        name="driverNames"
        field="driver.key"
        displayValue="driver.name"
        group="filters"
        filterGroup="filters"
        label="Driver"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverNames}
      />
      <SelectFilterInput
        name="jurisdictions"
        field="jurisdiction"
        group="filters"
        filterGroup="filters"
        label="Jurisdiction"
        summaryPrefix="Jurisdiction: "
        itemPlural="jurisdictions"
        autoGridArea
        getOptions={getOptions.jurisdictions}
      />
      <SelectFilterInput
        name="vendors"
        field="vendor"
        group="filters"
        filterGroup="filters"
        label="Vendor"
        summaryPrefix="Vendor: "
        itemPlural="vendors"
        autoGridArea
        getOptions={getOptions.vendors}
      />
      <SelectFilterInput
        name="locationNames"
        field="location.name"
        group="filters"
        filterGroup="filters"
        label="Location"
        summaryPrefix="Location: "
        itemPlural="locations"
        autoGridArea
        getOptions={getOptions.locationNames}
      />
      <SelectFilterInput
        name="fuelTypes"
        field="fuelType"
        group="filters"
        filterGroup="filters"
        label="Fuel Type"
        summaryPrefix="Fuel Type: "
        itemPlural="fuel types"
        autoGridArea
        getOptions={getOptions.fuelTypes}
      />
      <OutlierFiltersDialogInput dialogClassName="Fuelling__outlierFiltersDialog">
        <h4 style={{ gridArea: 'hideDurationHeading' }}>Hide duration</h4>
        <OutlierFilterInput
          name="hideDurationLessThan"
          label="less than"
          summaryPrefix="Hide: Duration < "
          filterConfig={{
            group: 'outlier',
            field: 'duration',
            type: filterRowsByUnitRange,
            unit: 'h',
            unitType: 'duration',
            disabled: ({ value }) => !value,
            getValues: ({ controls }) => ({
              min: controls.hideDurationLessThanX,
            }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideDurationGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Duration > "
          filterConfig={{
            group: 'outlier',
            field: 'duration',
            type: filterRowsByUnitRange,
            unit: 'h',
            unitType: 'duration',
            disabled: ({ value }) => !value,
            getValues: ({ controls }) => ({ max: controls.hideDurationGreaterThanX }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeDurationLessThan"
          label="less than"
          summaryPrefix="Hide: Duration < "
          filterConfig={{
            group: 'outlier',
            field: 'duration',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              min: controls.hideRelativeDurationLessThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={80}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeDurationGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Duration > "
          filterConfig={{
            group: 'outlier',
            field: 'duration',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              max: controls.hideRelativeDurationGreaterThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={120}
          autoGridArea
        />
        <h4 style={{ gridArea: 'hideDistanceHeading' }}>Hide distance</h4>
        <OutlierFilterInput
          name="hideDistanceLessThan"
          label="less than"
          summaryPrefix="Hide: Distance < "
          filterConfig={{
            group: 'outlier',
            field: 'distance',
            type: filterRowsByUnitRange,
            unitType: 'distance',
            disabled: ({ value }) => !value,
            getValues: ({ controls, formats }) => ({
              min: controls.hideDistanceLessThanX,
              unit: formats.distance,
            }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideDistanceGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Distance > "
          filterConfig={{
            group: 'outlier',
            field: 'distance',
            type: filterRowsByUnitRange,
            unitType: 'distance',
            disabled: ({ value }) => !value,
            getValues: ({ controls, formats }) => ({
              max: controls.hideDistanceGreaterThanX,
              unit: formats.distance,
            }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeDistanceLessThan"
          label="less than"
          summaryPrefix="Hide: Distance < "
          filterConfig={{
            group: 'outlier',
            field: 'distance',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              min: controls.hideRelativeDistanceLessThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={80}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeDistanceGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Distance > "
          filterConfig={{
            group: 'outlier',
            field: 'distance',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              max: controls.hideRelativeDistanceGreaterThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={120}
          autoGridArea
        />
        <h4 style={{ gridArea: 'hideDistanceHeading' }}>Hide fuel quantity</h4>
        <OutlierFilterInput
          name="hideFuelQuantityLessThan"
          label="less than"
          summaryPrefix="Hide: Fuel Quantity < "
          filterConfig={{
            group: 'outlier',
            field: 'addedFuel',
            type: filterRowsByUnitRange,
            unitType: 'volume',
            disabled: ({ value }) => !value,
            getValues: ({ controls, formats }) => ({
              min: controls.hideFuelQuantityLessThanX,
              unit: formats.volume,
            }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideFuelQuantityGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Fuel Quantity > "
          filterConfig={{
            group: 'outlier',
            field: 'addedFuel',
            type: filterRowsByUnitRange,
            unitType: 'volume',
            disabled: ({ value }) => !value,
            getValues: ({ controls, formats }) => ({
              max: controls.hideFuelQuantityGreaterThanX,
              unit: formats.volume,
            }),
          }}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeFuelQuantityLessThan"
          label="less than"
          summaryPrefix="Hide: Fuel Quantity < "
          filterConfig={{
            group: 'outlier',
            field: 'addedFuel',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              min: controls.hideRelativeFuelQuantityLessThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={80}
          autoGridArea
        />
        <OutlierFilterInput
          name="hideRelativeFuelQuantityGreaterThan"
          label="greater than"
          summaryPrefix="Hide: Fuel Quantity > "
          filterConfig={{
            group: 'outlier',
            field: 'addedFuel',
            type: filterRowsByBaselineRange,
            unitType: 'relative',
            disabled: ({ value }) => !value,
            getValues: ({ controls, filteredRows }) => ({
              max: controls.hideRelativeFuelQuantityGreaterThanX,
              baselineRows: filteredRows.filters,
            }),
          }}
          paramDefaultValue={120}
          autoGridArea
        />
        <h4 style={{ gridArea: 'invertOutliersHeading' }}>Outlier detection</h4>
        <ReportControl
          name="invertOutliers"
          label="Invert results of hide filters (show the hidden rows instead)"
          group={['filters', 'outliers']}
          render={(props) => <ToggleInput {...props} />}
          canDeleteSummary
          renderSummary={(props, { value }) =>
            value && <Chip {...props} label="Invert hide filters" />
          }
          filterConfig={{
            group: 'invertOutlier',
            type: invertFilteredRows,
            disabled: ({ value }) => !value,
            getValues: ({ filteredRows }) => ({
              baselineRows: filteredRows.filters,
            }),
          }}
          printConfig={({ value }) => ({
            key: 'outliers',
            label: 'Additional Filters',
            value: value && 'Invert hide filters',
          })}
          autoGridArea
        />
      </OutlierFiltersDialogInput>
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
