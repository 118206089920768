import produce from 'immer';

import {
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  actionTypeFailure,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchRoles: 'administration/resources/roles/fetchRoles',
};

export const roleReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchRoles)) {
      draft.data = payload;
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchRoles])) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchRoles])) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
