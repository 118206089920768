import React from 'react';

import { configureRoute } from './configureRoute';
import { BasicRouteFrame } from './RouteFrame';

import { FleetTracking } from '../../tracking';

const fleetTracking = configureRoute({
  name: 'FleetTracking',
  path: '/fleetTracking',
  RouteFrame: BasicRouteFrame,
  render: (props) => <FleetTracking {...props} />,
});

export const fleetTrackingRoutes = [fleetTracking];
