import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { flatten, sortBy } from 'lodash';

const moment = extendMoment(Moment);

const stringToMoment = (dateString) => moment(dateString);
const stringToMomentUtc = (dateString) => moment.tz(dateString, 'Etc/UTC');

/*
  Expects full ISO date strings, i.e. dates represent an exact instant.
  e.g. 2019-01-01T00:00:00.000Z to 2019-01-02T00:00:00.000Z is exactly a 24 hour range.
*/
export const getUncoveredDateRanges = ([startDateString, endDateString], coveredDateRanges) => {
  const targetStart = stringToMoment(startDateString);
  const targetEnd = stringToMoment(endDateString);

  let uncoveredRanges = [moment.range(targetStart, targetEnd)];

  const coveredRanges = coveredDateRanges.map(([coveredStart, coveredEnd]) =>
    moment.range(stringToMoment(coveredStart), stringToMoment(coveredEnd))
  );

  coveredRanges.forEach((coveredRange) => {
    uncoveredRanges = flatten(
      uncoveredRanges.map((uncoveredRange) => uncoveredRange.subtract(coveredRange))
    );
  });

  const resultRangesAsStrings = uncoveredRanges.map((uncoveredRange) => {
    const [uncoveredStart, uncoveredEnd] = uncoveredRange.toDate();
    return [moment(uncoveredStart).toISOString(), moment(uncoveredEnd).toISOString()]; // convert to ISO strings
  });

  return sortBy(resultRangesAsStrings, ([startOfRange]) => startOfRange);
};

/*
  Expects YYYY-MM-DD strings. As dates are representative of whole days, the range is
  inclusive of the entire start and end days.
  e.g. 2019-01-01 to 2019-01-02 is a 48 hour range.
*/
export const getUncoveredTimelessDateRanges = (
  [startDateString, endDateString],
  coveredDateRanges
) => {
  const targetStart = stringToMomentUtc(startDateString);
  const targetEnd = stringToMomentUtc(endDateString).add(1, 'day').startOf('day');

  let uncoveredRanges = [moment.range(targetStart, targetEnd)];

  const coveredRanges = coveredDateRanges.map(([coveredStart, coveredEnd]) =>
    moment.range(
      stringToMomentUtc(coveredStart),
      stringToMomentUtc(coveredEnd).add(1, 'day').startOf('day')
    )
  );

  coveredRanges.forEach((coveredRange) => {
    uncoveredRanges = flatten(
      uncoveredRanges.map((uncoveredRange) => uncoveredRange.subtract(coveredRange))
    );
  });

  const resultRangesAsStrings = uncoveredRanges.map((uncoveredRange) => {
    const [uncoveredStart, uncoveredEnd] = uncoveredRange.toDate();
    return [
      moment(uncoveredStart).toISOString().substring(0, 10),
      moment(uncoveredEnd).subtract(1, 'day').toISOString().substring(0, 10),
    ]; // convert to YYYY-MM-DD strings
  });

  return sortBy(resultRangesAsStrings, ([startOfRange]) => startOfRange);
};

export const getMinimalDateRanges = (coveredDateRanges) => {
  if (coveredDateRanges.length === 0) return coveredDateRanges;

  const coveredRanges = coveredDateRanges.map(([coveredStart, coveredEnd]) =>
    moment.range(stringToMoment(coveredStart), stringToMoment(coveredEnd))
  );

  const [firstRange, ...remainingRanges] = coveredRanges;
  const minimalRanges = [firstRange];

  remainingRanges.forEach((addingRange) => {
    for (let index = 0; index < minimalRanges.length; index += 1) {
      if (addingRange.overlaps(minimalRanges[index], { adjacent: true })) {
        minimalRanges[index] = minimalRanges[index].add(addingRange, { adjacent: true });
        break;
      }

      const addingRangeLaterEnd = moment.range(
        addingRange.start,
        addingRange.end.clone().add(1, 'ms')
      );
      if (addingRangeLaterEnd.overlaps(minimalRanges[index], { adjacent: true })) {
        minimalRanges[index] = minimalRanges[index].add(addingRangeLaterEnd, { adjacent: true });
        break;
      }

      const addingRangeEarlierStart = moment.range(
        addingRange.start.clone().subtract(1, 'ms'),
        addingRange.end
      );
      if (addingRangeEarlierStart.overlaps(minimalRanges[index], { adjacent: true })) {
        minimalRanges[index] = minimalRanges[index].add(addingRangeEarlierStart, {
          adjacent: true,
        });
        break;
      }

      minimalRanges.push(addingRange);
    }
  });

  const resultRangesAsStrings = minimalRanges.map((range) => {
    const [rangeStart, rangeEnd] = range.toDate();
    return [moment(rangeStart).toISOString(), moment(rangeEnd).toISOString()]; // convert to ISO strings
  });

  return sortBy(resultRangesAsStrings, ([startOfRange]) => startOfRange);
};
