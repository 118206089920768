import { createSelector } from 'reselect';
import { organization } from '../administration/configurations';

const getGeozones = (state) => state.reports.geozones.data.summary;

/**
 * selectGeozones(state) -> [geozones]
 *
 * Selector for all geozones
 */
// export const selectGeozones = state => state.reports.geozones.data.summary;

export const selectGeozones = createSelector(
  [getGeozones, organization.selectors.selectOrganizations],
  (geozones, ous) =>
    geozones.map((geozone) => ({
      ...geozone,
      ouName: (ous.find((ou) => ou.key === geozone.ouKey) || {}).name,
    }))
);

/**
 * selectGeozonesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on geozones
 */
export const selectGeozonesFetchRequests = (state) => state.reports.geozones.fetches.summary;

/**
 * selectGeozoneActivities(state, geozoneKey) -> geozoneActivities
 *
 * Selector for all geozoneActivities from a specify geozoneKey
 */
export const selectGeozoneActivities = (state, geozoneKey) =>
  state.reports.geozones.data.activities.filter((activity) => activity.geozoneKey === geozoneKey);

/**
 * selectGeozonesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on geozones
 */
export const selectGeozoneActivitiesFetchRequests = (state) =>
  state.reports.geozones.fetches.activities;
