import { filter } from 'lodash';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectActivationCodesFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchActivationCodes = makeRestApiAction({
  service: 'activationCodes',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchActivationCodes,
  notificationsItemDescriptor: 'activation codes',
});

export const fetchActivationCodesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectActivationCodesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchActivationCodes,
  baseActionType: ACTION_TYPE.fetchActivationCodes,
});

export const deleteActivationCode = makeRestApiAction({
  service: 'activationCodes',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteActivationCode,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'activation code',
});

export const createActivationCode = makeRestApiAction({
  service: 'activationCodes',
  method: 'post',
  baseActionType: ACTION_TYPE.createActivationCode,
  notificationsItemDescriptor: 'activation code',
});
