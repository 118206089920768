import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniqBy } from 'lodash';

import {
  SelectFilterInput,
  SectionPanel,
  SectionPanelMenu,
  ControlGroupSummary,
} from '../../../commons/ReportsCommon';

export const FiltersPanel = ({ rows }) => {
  const getOptions = useMemo(
    () => ({
      organizations: () =>
        sortBy(
          uniqBy(
            rows.map((row) => ({ value: row.ouKey, label: row.ouName })),
            'value'
          ),
          'label'
        ),
      vehicles: () =>
        sortBy(
          uniqBy(
            rows.map(({ vehicle: { key, name } }) => ({ value: key, label: name })),
            'value'
          ),
          'label'
        ),
      drivers: () =>
        sortBy(
          uniqBy(
            rows.map(({ driver: { key, name } }) => ({ value: key, label: name })),
            'value'
          ),
          'label'
        ),
      origins: () =>
        sortBy(
          uniqBy(
            rows.map(({ origin: { name } }) => ({ value: name, label: name })),
            'value'
          ),
          'label'
        ),
      firstLoads: () =>
        sortBy(
          uniqBy(
            rows.map(({ firstLoad: { name } }) => ({ value: name, label: name })),
            'value'
          ),
          'label'
        ),
      lastUnloads: () =>
        sortBy(
          uniqBy(
            rows.map(({ lastUnload: { name } }) => ({ value: name, label: name })),
            'value'
          ),
          'label'
        ),
    }),
    [rows]
  );
  return (
    <SectionPanel
      name="filtersPanel"
      title="Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="organizations"
        field="ouKey"
        displayValue="ouName"
        group="filters"
        filterGroup="filters"
        label="Organization"
        summaryPrefix="Organization: "
        itemPlural="organizations"
        autoGridArea
        getOptions={getOptions.organizations}
      />
      <SelectFilterInput
        name="vehicles"
        field="vehicle.key"
        displayValue="vehicle.name"
        group="filters"
        filterGroup="filters"
        label="Vehicle"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicles}
      />
      <SelectFilterInput
        name="drivers"
        field="driver.key"
        displayValue="driver.name"
        group="filters"
        filterGroup="filters"
        label="Driver"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.drivers}
      />
      <SelectFilterInput
        name="origins"
        field="origin.name"
        group="filters"
        filterGroup="filters"
        label="Origin"
        summaryPrefix="Origin: "
        itemPlural="Origins"
        autoGridArea
        getOptions={getOptions.origins}
      />
      <SelectFilterInput
        name="firstLoads"
        field="firstLoad.name"
        group="filters"
        filterGroup="filters"
        label="Load"
        summaryPrefix="Load: "
        itemPlural="Loads"
        autoGridArea
        getOptions={getOptions.firstLoads}
      />
      <SelectFilterInput
        name="lastUnloads"
        field="lastUnload.name"
        group="filters"
        filterGroup="filters"
        label="Unload"
        summaryPrefix="Unload: "
        itemPlural="Unloads"
        autoGridArea
        getOptions={getOptions.lastUnloads}
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
