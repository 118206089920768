import { connect } from 'react-redux';

import { Geozones } from './Geozones';

import { location, locationType } from '../../../../data/administration/resources';

import { selectSystemTenant } from '../../../../data/system';

export const GeozonesContainer = connect(
  (state) => ({
    geozones: location.selectors.selectLocations(state),
    tenant: selectSystemTenant(state),
  }),
  {
    createGeozone: location.actions.createLocation,
    deleteGeozone: location.actions.deleteLocation,
    fetchGeozones: location.actions.fetchLocationsOptimized,
    fetchGeozoneTypes: locationType.actions.fetchLocationTypesOptimized,
    updateGeozone: location.actions.updateLocation,
  }
)(Geozones);
