import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'text',
    headerName: 'Reason',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
