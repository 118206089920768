import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Information',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Date',
        variant: 'timelessDate',
      }),
      columnDef.dateColumn({
        colId: 'start',
        field: 'startedAt',
        headerName: 'Start Time',
        variant: 'datelessTime',
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
        enableRowGroup: false,
        aggFunc: 'sum',
      }),
      columnDef.textColumn({
        field: 'driverName',
        headerName: 'Driver Name',
        cellRenderer: 'Driver',
        enableRowGroup: true,
        rowGroupIndex: 0,
      }),
      columnDef.textColumn({
        field: 'assetId',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
      }),
    ],
  },
  {
    headerName: 'Speed',
    children: [
      columnDef.speedColumn({
        field: 'averageSpeed',
        headerName: 'Average Speed',
      }),
      columnDef.speedColumn({
        field: 'maximumSpeed',
        headerName: 'Maximum Speed',
      }),
      columnDef.speedColumn({
        field: 'speedLimit',
        headerName: 'Speed Limit',
      }),
      columnDef.durationColumn({
        field: 'gracePeriod',
        headerName: 'Grace Period',
      }),
    ],
  },
  {
    headerName: 'Location',
    children: [
      columnDef.iconColumn({
        colId: 'routeMap',
        cellRenderer: 'SpeedViolationMap',
        headerName: 'Speed Violation Map',
        headerComponent: 'SpeedViolationHeader',
        suppressExcelExport: true,
      }),
      columnDef.textColumn({
        field: 'geozoneName',
        headerName: 'Geozone',
      }),
    ],
  },
];
