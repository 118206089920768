import { columnDef } from '../../../../../commons/ControlledDataGrid';

export const effectiveRegulationsColumnDefs = [
  columnDef.textColumn({
    field: 'regulation',
    headerName: 'Regulation',
  }),
  columnDef.textColumn({
    field: 'description',
    headerName: 'Description',
  }),
  columnDef.textColumn({
    field: 'appliesTo',
    headerName: 'Applies To',
  }),
  columnDef.textColumn({
    field: 'value',
    headerName: 'Value',
  }),
];
