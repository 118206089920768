/**
 * selectVehicleTypes(state) -> [vehicleTypes]
 *
 * Selector for all vehicleTypes
 */
export const selectVehicleTypes = (state) => state.administration.resources.vehicleTypes.data;

/**
 * selectVehicleType(state, props.assetTypeId) -> location
 *
 * Selector for single vehicleType by key
 */
export const selectVehicleType = (state, props) =>
  state.administration.resources.vehicleTypes.data.find(
    (item) => item.key === props.assetTypeId
  ) || { ou: {} };

/**
 * selectVehicleTypes(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on vehicleTypes
 */
export const selectVehicleTypesFetchRequests = (state) =>
  state.administration.resources.vehicleTypes.fetches;
