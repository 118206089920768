import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Role',
  }),
  columnDef.textColumn({
    field: 'description',
    headerName: 'Description',
  }),
];
