import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Information',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Date',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Date',
      }),
      columnDef.textColumn({
        field: 'ouName',
        headerName: 'Organization',
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        rowGroupIndex: 0,
      }),
      columnDef.textColumn({
        field: 'driver.name',
        headerName: 'Driver',
        cellRenderer: 'Driver',
      }),
      columnDef.listColumn({
        field: 'trailers',
        headerName: 'Trailers',
        cellRenderer: 'Trailers',
        hide: true, // disabled by default as required in MBL-6432
        transformValueElement: (item) => item.name,
      }),
    ],
  },
  {
    headerName: 'Locations',
    children: [
      columnDef.textColumn({
        field: 'origin.name',
        headerName: 'Origin',
        cellRenderer: 'Origin',
      }),
      columnDef.textColumn({
        field: 'firstLoad.name',
        headerName: 'Load',
        cellRenderer: 'FirstLoad',
      }),
      columnDef.textColumn({
        field: 'lastUnload.name',
        headerName: 'Unload',
        cellRenderer: 'LastUnload',
      }),
      columnDef.iconColumn({
        field: 'routeMap',
        headerName: 'Route Map',
        cellRenderer: 'RouteMap',
        headerComponent: 'RouteMapHeader',
      }),
    ],
  },
  {
    headerName: 'Empty',
    children: [
      columnDef.distanceColumn({
        field: 'empty.distance',
        headerName: 'Driving Distance',
        aggFunc: 'sum',
      }),
      columnDef.fuelColumn({
        field: 'empty.consumedFuel',
        headerName: 'Driving Fuel',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'empty.idlingDuration',
        headerName: 'Idling Duration',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'empty.idleTime',
        headerName: '% Idle Time',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'empty.idlingFuel',
        headerName: 'Idling Fuel',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'empty.idleFuel',
        headerName: '% Idle Fuel',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.durationColumn({
        field: 'empty.engineDuration',
        headerName: 'Engine Time',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'empty.usedFuel',
        headerName: 'Fuel Used',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.simpleMileageColumn({
        field: 'empty.consumption',
        headerName: 'Usage Rate',
        aggFunc: 'avg',
      }),
    ],
  },
  {
    headerName: 'Loaded',
    children: [
      columnDef.distanceColumn({
        field: 'loaded.distance',
        headerName: 'Driving Distance',
        aggFunc: 'sum',
      }),
      columnDef.fuelColumn({
        field: 'loaded.consumedFuel',
        headerName: 'Driving Fuel',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'loaded.idlingDuration',
        headerName: 'Idling Duration',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'loaded.idleTime',
        headerName: '% Idle Time',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'loaded.idlingFuel',
        headerName: 'Idling Fuel',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'loaded.idleFuel',
        headerName: '% Idle Fuel',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.durationColumn({
        field: 'loaded.engineDuration',
        headerName: 'Engine Time',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'loaded.usedFuel',
        headerName: 'Fuel Used',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.simpleMileageColumn({
        field: 'loaded.consumption',
        headerName: 'Usage Rate',
        aggFunc: 'avg',
      }),
    ],
  },
  {
    headerName: 'Overall',
    children: [
      columnDef.distanceColumn({
        field: 'overall.distance',
        headerName: 'Driving Distance',
        aggFunc: 'sum',
      }),
      columnDef.fuelColumn({
        field: 'overall.consumedFuel',
        headerName: 'Driving Fuel',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'overall.idlingDuration',
        headerName: 'Idling Duration',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'overall.idleTime',
        headerName: '% Idle Time',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'overall.idlingFuel',
        headerName: 'Idling Fuel',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'overall.idleFuel',
        headerName: '% Idle Fuel',
        aggFunc: 'avg',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.durationColumn({
        field: 'overall.engineDuration',
        headerName: 'Engine Time',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.fuelColumn({
        field: 'overall.usedFuel',
        headerName: 'Fuel Used',
        aggFunc: 'sum',
        hide: true, // disabled by default as required in MBL-6432
      }),
      columnDef.simpleMileageColumn({
        field: 'overall.consumption',
        headerName: 'Usage Rate',
        aggFunc: 'avg',
      }),
    ],
  },
];
