import React, { useEffect } from 'react';

import { ReportControl } from './ReportControl';
import { useReport } from './useReport';

export const GridAutoExpandGroups = () => {
  const { controls, webclientViewReady, dataGridController } = useReport();

  useEffect(() => {
    if (!webclientViewReady) return;
    if (controls.dataGridAutoExpandGroups) dataGridController.methods.expandGroups();
  }, [webclientViewReady]);

  return <ReportControl name="dataGridAutoExpandGroups" group="dataGrid" />;
};
