import produce from 'immer';

import { cloneDeep } from 'lodash';
import { actionTypeProgressOrSuccess, deepMergeEntities } from '../apiReducerSupport';

import { dateObject } from '../../helpers/moment';

export const ACTION_TYPE = {
  fetchMessages: 'messenger/fetchMessages',
  postMessage: 'messenger/postMessage',
  postMessageEvent: 'messenger/postMessageEvent',
  setOldestDate: 'messenger/setOldestDate',
};

const initialState = {
  messages: [],
  oldestDate: dateObject().tz().subtract(1, 'day'),
};

export const messengerReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchMessages])) {
      draft.messages = deepMergeEntities(state.messages, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.postMessageEvent])) {
      const { messageKey, ...rest } = payload;

      const merger = cloneDeep(state.messages.find((message) => message.key === messageKey));
      merger.events.push(rest);

      draft.messages = deepMergeEntities(state.messages, merger, 'key', true);
    }

    if (ACTION_TYPE.setOldestDate === type) {
      draft.oldestDate = payload;
    }

    return draft;
  });
