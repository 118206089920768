/* eslint-disable no-console */
import { useState, useRef, useReducer, useCallback, useEffect, useMemo } from 'react';
import {
  castArray,
  intersection,
  isFunction,
  isUndefined,
  isEqual,
  pick,
  pickBy,
  fromPairs,
  without,
  find,
  remove,
  uniqueId,
  clone,
  cloneDeep,
} from 'lodash';
import produce from 'immer';
import moment from 'moment-timezone';
import { useInstance, useBooleanState } from 'stti-react-common';

import { ControlledDataGrid, reportGridOptions as gridOptions } from '../ControlledDataGrid';
import { useReportFiltering } from './useReportFiltering';
import { useReportFormats } from './useReportFormats';
import { migrateReportView, LATEST_REPORT_VIEW_VERSION } from './migrateReportView';
import { debugMode } from '../../../helpers/debug';

const {
  useDataGridController,
  createState,
  utils: { flattenColumnDefs },
} = ControlledDataGrid;

const intersects = (compare1, compare2) =>
  !!intersection(castArray(compare1), castArray(compare2)).length;

const isReportDirty = (state) => {
  // compare controls
  if (!isEqual(pickBy(state.lastLoadedState.controls), pickBy(state.controls))) return true; // exclude falsey values with pickBy

  // compare dataGrid
  const pickDataGridProperties = ['columnState', 'sortModel', 'filterModel'];
  if (
    !isEqual(
      pick(state.lastLoadedState.dataGrid, pickDataGridProperties),
      pick(state.dataGrid, pickDataGridProperties)
    )
  )
    return true;

  return false;
};

const reportViewIdType = (reportViewId) => {
  if (!reportViewId) return undefined;
  if (reportViewId === 'default') return 'default';
  if (
    reportViewId.match(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    )
  )
    return 'remote';
  if (reportViewId.match(/^[0-9a-f]{40}$/)) return 'local';
  return undefined;
};

const sanitizeControls = (controls, originalControls) => {
  if (!controls) throw new Error('sanitizeControls called without controls');

  if (controls.ouMode !== 'custom') {
    return controls;
  }

  const cloneControls = cloneDeep(controls);

  if (
    controls.ousSelected &&
    controls.ousSelected.length === 0 &&
    originalControls &&
    originalControls.ouMode === 'custom' &&
    originalControls.ousSelected &&
    originalControls.ousSelected.length > 0
  ) {
    console.groupCollapsed(
      'WEB VIEW INFO - [REPORT LOADER] - sanitizeControls -> STATE ISSUE DETECTED'
    );
    console.info(
      'fixing issue with ousSelected',
      controls.ousSelected,
      originalControls.ousSelected
    );
    console.groupEnd();
    cloneControls.ousSelected = originalControls.ousSelected;
  }

  return cloneControls;
};

const fallbackDefaultState = { controls: {}, dataGrid: { columnDefs: [], columnState: [] } };

export const ACTION_TYPE = fromPairs(
  [
    'setControl',
    'setState',
    'setDataGridState',
    'restoreReport',
    'reportWasSaved',
    'revertState',
    'resetState',
  ].map((action) => [action, action])
);

export const useReportController = ({
  defaultState: providedDefaultState = fallbackDefaultState,
  reportType,
  reportTypeName,
  reportViewId,
  filtersConfig = {},
  route,
} = {}) => {
  const instance = useInstance({
    controlDefs: {},
    infoFieldDefs: {},
    instantControlChanged: false,
    defaultState: isFunction(providedDefaultState) ? providedDefaultState() : providedDefaultState,
    fetches: {},
  });
  const { controlDefs, defaultState } = instance;
  const [reportViewTooNew, setReportViewTooNew] = useState(false);

  // state
  const [state, dispatch] = useReducer(
    (currentState, { type, payload }) =>
      produce(currentState, (draft) => {
        switch (type) {
          case ACTION_TYPE.setControl: {
            const { name, value } = payload;
            draft.controls[name] = value;
            if (!controlDefs[name])
              throw new Error(`setControl called for "${name}" without control defined`);
            if (controlDefs[name].instant) instance.instantControlChanged = true;
            if (controlDefs[name].reducerCallback) controlDefs[name].reducerCallback(draft, value); // mutates draft
            break;
          }
          case ACTION_TYPE.setState:
            if (isFunction(payload)) {
              payload(draft); // mutates draft
            } else {
              throw new Error(
                'No defined behaviour for useReportController setState non-function payload'
              );
            }
            break;
          case ACTION_TYPE.setDataGridState:
            if (isFunction(payload)) {
              throw new Error(
                'No defined behaviour for useReportController setDataGridState function payload'
              );
            } else {
              Object.keys(payload).forEach((key) => {
                draft.dataGrid[key] = payload[key];
              });
            }
            break;
          case ACTION_TYPE.restoreReport: {
            const migratedPayload = migrateReportView(payload);

            if (!migratedPayload) {
              setReportViewTooNew(true);
              break;
            }

            if (debugMode) {
              console.groupCollapsed(
                'WEB VIEW INFO - [REPORT LOADER] - useReportController -> MIGRATED REPORT PAYLOAD'
              );
              console.info('payload', payload);
              migratedPayload
                ? console.info('migratedPayload', migratedPayload)
                : console.info(
                    'migratedPayload was not found we are setting reportViewTooNew as true'
                  );
              console.groupEnd();
            }

            draft.meta.id = migratedPayload.id;
            draft.meta.title = migratedPayload.title;
            draft.meta.description = migratedPayload.description;
            draft.meta.defaultView = !!migratedPayload.defaultView;
            draft.meta.canSave =
              !migratedPayload.defaultView && reportViewIdType(migratedPayload.id) !== 'local';
            draft.meta.isReady = true;
            draft.lastLoadedState = migratedPayload.state;

            draft.controls = { ...defaultState.controls, ...migratedPayload.state.controls };

            // TODO: WHILE WE ARE USING AN OLD VERSION OF PUPPETEER THIS WILL NEED TO BE HERE
            draft.controls = sanitizeControls(draft.controls, migratedPayload.state.controls);

            if (debugMode) {
              console.groupCollapsed(
                'WEB VIEW INFO - [REPORT LOADER] - useReportController -> MERGE REPORT'
              );
              console.info('defaultState.controls', defaultState.controls);
              console.info('migratedPayload.state.controls', migratedPayload.state.controls);
              console.info('currentState.controls', currentState.controls);
              console.info('merge result in draft.controls', draft.controls);
              console.groupEnd();
            }

            draft.dataGrid = {
              ...defaultState.dataGrid,
              ...currentState.dataGrid,
              ...migratedPayload.state.dataGrid,
            };

            // We need to clone this so we can manipulate the array and re-add it when necessary
            const columnState = cloneDeep(draft.dataGrid.columnState);

            if (migratedPayload.state.dataGrid && migratedPayload.state.dataGrid.columnState) {
              const flatColumnDefs = flattenColumnDefs(defaultState.dataGrid.columnDefs);

              // check if any new columns exist which are not in state being restored
              const addedColIds = without(
                defaultState.dataGrid.columnState.map((col) => col.colId),
                ...draft.dataGrid.columnState.map((col) => col.colId)
              );
              if (addedColIds.length > 0) {
                // new column(s) exist, check if they essential and must start shown
                addedColIds.forEach((colId) => {
                  const newColumnDef = find(flatColumnDefs, { colId });
                  const newColumnState = {
                    ...find(defaultState.dataGrid.columnState, { colId }),
                    hide: !newColumnDef.essential, // start hidden unless marked essential
                  };
                  const newColumnPosition = 0; // TODO: support smart detection of new column position
                  draft.dataGrid.columnState.splice(newColumnPosition, 0, newColumnState);
                });
              }

              // check if any columns that are in state being restored no longer exist
              const removedColIds = without(
                draft.dataGrid.columnState.map((col) => col.colId),
                ...defaultState.dataGrid.columnState.map((col) => col.colId)
              );
              if (removedColIds.length > 0) {
                // deprecated columns exist, remove from state being restored
                remove(columnState, ({ colId }) => removedColIds.includes(colId));
              }

              // check if any columns are using an aggFunc which is not allowed
              columnState.forEach((col) => {
                const { allowedAggFuncs } = find(flatColumnDefs, {
                  colId: col.colId,
                });
                if (col.aggFunc && allowedAggFuncs && !allowedAggFuncs.includes(col.aggFunc))
                  // column is using an aggFunc which is not in columnDef's allowed list, reset it
                  // eslint-disable-next-line no-param-reassign
                  col.aggFunc = null;
              });
              draft.dataGrid.columnState = columnState;
            }

            const controlCallbacks = [];
            Object.entries(controlDefs).forEach(([controlName, controlConfig]) => {
              if (controlConfig.reducerCallbackOnRestore) {
                const value = migratedPayload.state.controls[controlName];
                const effectFn = isFunction(controlConfig.reducerCallbackOnRestore)
                  ? controlConfig.reducerCallbackOnRestore
                  : controlConfig.reducerCallback;
                controlCallbacks.push([effectFn, value]);
              }
            });
            controlCallbacks.forEach(([effectFn, ...params]) => effectFn(draft, ...params));
            break;
          }
          case ACTION_TYPE.reportWasSaved:
            draft.lastLoadedState = payload.state;
            break;
          case ACTION_TYPE.revertState:
            Object.entries(controlDefs).forEach(([controlName, controlConfig]) => {
              if (isUndefined(payload) || intersects(payload, controlConfig.group))
                draft.controls[controlName] =
                  currentState.lastLoadedState.controls[controlName] ||
                  defaultState.controls[controlName];
              if (isUndefined(payload) || intersects(payload, 'dataGrid'))
                draft.dataGrid = {
                  ...currentState.dataGrid,
                  ...currentState.lastLoadedState.dataGrid,
                };
            });
            break;
          case ACTION_TYPE.resetState: {
            const controlCallbacks = [];
            Object.entries(controlDefs).forEach(([controlName, controlConfig]) => {
              if (isUndefined(payload) || intersects(payload, controlConfig.group)) {
                const value = defaultState.controls[controlName];
                draft.controls[controlName] = value;
                if (controlConfig.reducerCallbackOnRestore) {
                  const effectFn = isFunction(controlConfig.reducerCallbackOnRestore)
                    ? controlConfig.reducerCallbackOnRestore
                    : controlConfig.reducerCallback;
                  controlCallbacks.push([effectFn, value]);
                }
              }
            });
            if (isUndefined(payload) || intersects(payload, 'dataGrid'))
              draft.dataGrid = createState({ gridOptions, ...defaultState.dataGrid });
            controlCallbacks.forEach(([effectFn, ...params]) => effectFn(draft, ...params));
            break;
          }
          default:
            throw new Error('Unrecognized action type in useReportController reducer');
        }
        draft.meta.isDirty = isReportDirty(draft);
      }),
    {
      controls: cloneDeep(defaultState.controls),
      dataGrid: createState({ gridOptions, ...defaultState.dataGrid }),
      meta: {
        id: reportViewId,
        reportType,
        title: null,
        description: null,
        defaultView: true,
        isDirty: false,
        isReady: false,
      },
      lastLoadedState: {
        controls: {},
        dataGrid: {},
      },
    }
  );

  // state methods

  const setState = useCallback((payload) => {
    dispatch({ type: ACTION_TYPE.setState, payload });
  }, []);

  // control methods

  const setControl = useCallback((name, value) => {
    dispatch({ type: ACTION_TYPE.setControl, payload: { name, value } });
  }, []);

  const registerControl = useCallback(
    (name, config) => {
      if (isUndefined(name)) throw new Error('registerControl called without control name');
      if (isUndefined(config))
        throw new Error(`registerControl called for "${name}" without control config`);
      controlDefs[name] = config;
      const defaultValue = isUndefined(config.defaultValue) ? null : config.defaultValue;
      if (isUndefined(defaultState.controls[name])) defaultState.controls[name] = defaultValue;
      if (isUndefined(state.controls[name])) setControl(name, defaultValue);

      return () => {
        delete controlDefs[name];
      };
    },
    [controlDefs, defaultState, state.controls]
  );

  const validateControl = useCallback(
    (...controlNames) =>
      controlNames.every(
        (name) =>
          controlDefs[name] &&
          !controlDefs[name].error({
            value: state.controls[name],
            controls: state.controls,
            filteredRows,
          })
      ),
    [state.controls, controlDefs]
  );

  const resetState = useCallback((group) => {
    dispatch({ type: ACTION_TYPE.resetState, payload: group });
  }, []);

  const revertState = useCallback((group) => {
    dispatch({ type: ACTION_TYPE.revertState, payload: group });
  }, []);

  const debounceTimerRef = useRef();
  const [debouncedControls, setDebouncedControls] = useState(state.controls);
  useEffect(() => {
    const { startDate, endDate, ...rest } = state.controls;
    let validControls = clone(rest);

    // this is to avoid debounced api calls with invalid date order.
    if (moment(startDate).isAfter(moment(endDate))) {
      validControls = { ...validControls, startDate: null, endDate: null };
    } else {
      validControls = { ...validControls, startDate, endDate };
    }

    window.clearTimeout(debounceTimerRef.current);
    if (instance.instantControlChanged) {
      // do it now
      setDebouncedControls(validControls);
      instance.instantControlChanged = false;
    } else {
      // set timer
      debounceTimerRef.current = window.setTimeout(() => {
        setDebouncedControls(validControls);
      }, 500); // 500ms delay
    }
  }, [state.controls]);

  const [shouldFetch, startFetchAction, stopFetchAction] = useBooleanState();

  useEffect(() => {
    if (route && route.queryStringValue('print') === 'true') {
      startFetchAction();
    } else {
      if (validateControl('startDate', 'endDate') && !shouldFetch) {
        startFetchAction();
      }
      if (shouldFetch) {
        stopFetchAction();
      }
    }
  }, [debouncedControls]);

  // info field methods

  const setInfoFieldDef = useCallback((name, def) => {
    if (isUndefined(name)) throw new Error('setInfoFieldDef called without info field name');
    instance.infoFieldDefs[name] = def; // def contains { name, label, value }
  }, []);

  // report methods

  const restoreReport = useCallback((payload) => {
    dispatch({ type: ACTION_TYPE.restoreReport, payload });
  }, []);

  const getPersistableControls = () => {
    const persistableControls = { ...defaultState.controls, ...state.controls };
    Object.entries(controlDefs).forEach(([name, { unpersisted }]) => {
      if (unpersisted) delete persistableControls[name];
    });
    return persistableControls;
  };

  const snapshotReport = useCallback(() => ({
    id: reportViewId,
    reportType,
    title: state.meta.title,
    description: state.meta.description,
    state: {
      controls: getPersistableControls(),
      dataGrid: {
        columnState: state.dataGrid.columnState,
        filterModel: state.dataGrid.filterModel,
        sortModel: state.dataGrid.sortModel,
      },
      version: LATEST_REPORT_VIEW_VERSION,
    },
  }));

  const reportWasSaved = useCallback(() => {
    dispatch({ type: ACTION_TYPE.reportWasSaved, payload: snapshotReport() });
  }, []);

  // formats

  const { mergedFormats, overriddenFormats } = useReportFormats({ controls: state.controls });

  // filtering

  const { filteredRows, registerFilter, setDataGridFilteredRows, forceRefilter } =
    useReportFiltering({
      controls: debouncedControls,
      formats: mergedFormats,
      input: filtersConfig.input,
      chain: filtersConfig.chain,
      supplements: filtersConfig.supplements || {},
      disable: filtersConfig.disable,
    });

  // hybrid grid controller

  const dataGridMethodsController = useDataGridController.methodsOnly();
  const dataGridControllerSetState = useCallback((payload) => {
    dispatch({
      type: ACTION_TYPE.setDataGridState,
      payload,
    });
  });
  const dataGridController = useMemo(
    () => ({
      ...dataGridMethodsController,
      state: cloneDeep(state.dataGrid),
      setState: dataGridControllerSetState,
    }),
    [state.dataGrid]
  );

  const getDataGridExportFilename = useCallback(
    ({ format }) => {
      const desiredFilename = `${reportTypeName}${
        state.meta.defaultView ? '' : ` - ${state.meta.title}`
      } ${moment().format('YYYY-MM-DD')}.${format}`;
      return desiredFilename.replace(/[/\\?*:|"<>]/g, '_'); // scrub filename
    },
    [reportTypeName, state.meta.defaultView, state.meta.title]
  );

  // fetch management and webclientViewReady

  const [webclientViewReady, setWebclientViewReady] = useState();
  const [fetchError, setFetchError] = useState();
  const [fetchDependency, setFetchDependency] = useState();

  const registerFetch = useCallback((fetchPromise) => {
    const setWebclientViewReadyIfAllResolved = () => {
      if (webclientViewReady) return; // already done
      setWebclientViewReady(true);
    };

    const id = String(uniqueId());
    instance.fetches[id] = 'pending';

    if (fetchPromise)
      fetchPromise
        .then(() => {
          if (!instance.fetches[id]) return;
          instance.fetches[id] = 'resolved';
          setWebclientViewReadyIfAllResolved();
        })
        .catch((e) => {
          if (!instance.fetches[id]) return;
          instance.fetches[id] = 'error';
          setFetchError(e);
          throw e;
        });
    return () => {
      delete instance.fetches[id];
      setWebclientViewReadyIfAllResolved();
    };
  }, []);

  const fetchRetry = useCallback(() => {
    setFetchError(undefined);
    setFetchDependency({});
  }, []);

  // expose

  const report = useMemo(
    () => ({
      // state
      state,
      setState,
      resetState,
      revertState,
      defaultState, // pass-through

      // report
      meta: state.meta,
      restoreReport,
      reportWasSaved,
      snapshotReport,
      reportType, // pass-through
      reportTypeName, // pass-through
      reportViewId, // pass-through
      reportViewIdType: reportViewIdType(reportViewId),
      reportViewTooNew,

      // route
      route, // pass-through

      // controls
      controls: state.controls,
      controlDefs,
      debouncedControls,
      registerControl,
      setControl,
      validateControl,

      // info fields
      infoFieldDefs: instance.infoFieldDefs,
      setInfoFieldDef,

      // filters
      registerFilter,
      filteredRows,
      forceRefilter,

      // formats: exposed exclusively for the use of ReportProvider, which re-exposes it through a normal FormatsProvider
      formats: overriddenFormats,

      // grid
      dataGrid: state.dataGrid,
      dataGridController,
      setDataGridFilteredRows,
      getDataGridExportFilename,

      // fetch
      registerFetch,
      fetchRetry,
      fetchError,
      fetchDependency,
      webclientViewReady,

      // fetch actions: use this to avoid eager request behavior.
      shouldFetch,
      startFetchAction,
      stopFetchAction,
    }),
    [
      state,
      reportType,
      reportTypeName,
      route,
      debouncedControls,
      filteredRows,
      overriddenFormats,
      getDataGridExportFilename,
      reportViewId,
      fetchError,
      webclientViewReady,
      shouldFetch,
      startFetchAction,
      stopFetchAction,
    ]
  );

  // provide deferrable means to access report, e.g. in grid cell renderers
  const reportRef = useRef();
  reportRef.current = report;
  const getReport = useCallback(() => reportRef.current, []);

  return useMemo(
    () => ({
      ...report,
      getReport,
    }),
    [report]
  );
};
