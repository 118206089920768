import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import { GoogleMap } from 'stti-react-common';

import { useFormats } from '../Formats';

import './SectionInfo.scss';

const { Info } = GoogleMap;

export const SectionInfo = ({ section, trip }) => {
  const { startedAt, endedAt, duration } = section;
  const timeZone = section.timeZone || trip.timeZone;

  const intoTripDuration =
    trip && moment.duration(moment(startedAt).diff(moment(trip.startedAt))).asSeconds();

  const { formatUnit } = useFormats();

  const renderWhen = () => (
    <table className="Map__SectionInfo__when">
      <tbody>
        <tr>
          <th>From:</th>
          <td>
            {formatUnit('date', moment(startedAt).tz(timeZone))}
            {intoTripDuration && ` (${formatUnit('duration', intoTripDuration)} into trip)`}
          </td>
        </tr>
        <tr>
          <th>To:</th>
          <td>
            {formatUnit('date', moment(endedAt).tz(timeZone))} ({formatUnit('duration', duration)}{' '}
            duration)
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderDetail = () => {
    const { sectionType, other } = section;

    if (sectionType === 'SpeedViolation')
      return (
        <div className="Map__SectionInfo__section">
          <strong>Speed Violation</strong> for {Math.round(duration)} seconds
          <div>
            Maximum: {formatUnit('speed', other.maximumSpeed)}, Average:{' '}
            {formatUnit('speed', other.averageSpeed)}
          </div>
          {!!other.speedLimit && (
            <div>
              Limit: {formatUnit('speed', other.speedLimit)}
              {!!other.gracePeriod && `, ${other.gracePeriod} second grace period`}
            </div>
          )}
        </div>
      );

    if (sectionType === 'TirePressureViolation')
      return (
        <div className="Map__SectionInfo__section">
          <strong>Tire Pressure Violation</strong> ({other.loaded ? 'Loaded' : 'Unloaded'})
          <div>
            Maximum: {formatUnit('pressure', other.maximumPressure)}, Average:{' '}
            {formatUnit('pressure', other.averagePressure)}
          </div>
          <div>
            Limit: {formatUnit('pressure', other.tirePressureConfig.maximumLimit)},{' '}
            {formatUnit('distance', other.tirePressureConfig.graceDistance)} grace distance
          </div>
        </div>
      );

    return (
      <div className="Map__SectionInfo__section">
        <strong>{startCase(sectionType)}</strong>
      </div>
    );
  };

  return (
    <Info>
      <div className="Map__SectionInfo">
        {renderWhen()}
        {renderDetail()}
      </div>
    </Info>
  );
};

SectionInfo.propTypes = {
  section: PropTypes.shape({
    sectionType: PropTypes.string.isRequired,
    startedAt: PropTypes.string.isRequired,
    endedAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string,
    duration: PropTypes.number.isRequired,
    other: PropTypes.object, // contents depends on sectionType
  }).isRequired,
  trip: PropTypes.shape({
    startedAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string,
  }),
};
