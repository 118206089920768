import { find } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

// import { selectDriverUsers } from '../admin';

import { user } from '../administration/resources';

const getFetchRequests = (state) => state.reports.unidentifiedLogs.fetches;

/**
 * selectUnidentifiedLogs(state) -> [unidentifiedLog]
 *
 * Selector for all unidentified-driver logs
 */
export const selectUnidentifiedLogs = (state) => state.reports.unidentifiedLogs.data;

/**
 * selectUnidentifiedLog(state, { vehicleKey, date }) -> unidentifiedLog
 *
 * Selector for single detailed unidentified log by vehicle key and date
 */
const extractUnidentifiedLogParams = (_, { vehicleKey, date }) => ({ vehicleKey, date });
const extractUnidentifiedLogCacheKey = (_, { vehicleKey, date }) => `${vehicleKey}-${date}`;
export const selectUnidentifiedLog = createCachedSelector(
  [selectUnidentifiedLogs, user.selectors.selectDriverUsers, extractUnidentifiedLogParams],
  (unidentifiedLogs, driverUsers, { vehicleKey, date }) => {
    const log = find(
      unidentifiedLogs,
      ({ vehicle, logDate }) => vehicle.key === vehicleKey && logDate === date
    );
    if (!log) return undefined;

    const { timeZone, dutyStatusChanges = [], dutyIntervals = [], adminDriverChanges = [] } = log;

    return {
      ...log,
      dutyIntervals: dutyIntervals.map((interval, index) => {
        const adminDriverChange =
          find(adminDriverChanges.slice().reverse(), { key: interval.key }) || {};
        const { toDriverKey: driverKey } = adminDriverChange;
        const driverName = driverKey && (find(driverUsers, { key: driverKey }) || {}).fullName;
        const annotation = interval.annotation || adminDriverChange.annotation || '';

        return {
          ...interval,
          key: interval.key || `KEYLESS_INTERVAL_${index}`,
          startedAt: interval.start,
          endedAt: interval.end,
          fromDate: interval.start,
          timeZone,
          location: (find(dutyStatusChanges, { key: interval.key }) || {}).location, // match location from dutyStatusChanges as location is not present in dutyIntervals
          geoLocation: (find(dutyStatusChanges, { key: interval.key }) || {}).geoLocation,
          driverKey,
          driverName,
          annotation,
        };
      }),
    };
  }
)(extractUnidentifiedLogCacheKey);

/**
 * selectUnidentifiedLogsFetchRequests(state) -> [fetchRequestData]
 *
 * Selector for all fetches (active and successful), requestData only
 */
export const selectUnidentifiedLogsFetchRequests = createSelector(
  getFetchRequests,
  (fetchRequests) => fetchRequests.map((fetchRequest) => fetchRequest.requestData)
);

/**
 * selectUnidentifiedLogFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches that have occurred on detailed unidentified logs
 */
export const selectUnidentifiedLogFetchRequests = (state) =>
  state.reports.unidentifiedLogs.detailFetches;
