import BetterPropTypes from 'better-prop-types';

export const inspectionTemplate = BetterPropTypes.shape({
  ou: BetterPropTypes.oneOfType([BetterPropTypes.object, BetterPropTypes.string]),
  name: BetterPropTypes.string.isOptionalButNotNull,
  active: BetterPropTypes.bool.isOptionalButNotNull,
  description: BetterPropTypes.string,
  categories: BetterPropTypes.array,
  key: BetterPropTypes.string.isOptionalButNotNull,
  assetType: BetterPropTypes.array.isOptionalButNotNull,
  isSystem: BetterPropTypes.bool.isOptionalButNotNull,
  complianceText: BetterPropTypes.string,
  odometerReadingRequired: BetterPropTypes.string,
});

export const inspectionTemplates = BetterPropTypes.arrayOf(inspectionTemplate);
