import React from 'react';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import { useFormats } from '../../../commons/Formats';
import { sumOfField, countOf } from '../../../../helpers/reports/aggregation';

import { customPropTypes } from '../../../../helpers/customPropTypes';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();

  const [first] = rows;

  if (!first) return '';

  const { totals, aggregation } = first;

  return (
    <>
      <AggregatesCard
        title="Session Totals"
        name="totals"
        fields={[
          {
            header: 'Driving Distance',
            value: formatUnit('distance', totals.distanceDriven),
          },
          {
            header: 'Session Duration',
            value: formatUnit('duration', totals.shiftDuration),
          },
          {
            header: 'Engine On Hours',
            value: formatUnit('duration', totals.engineTime),
          },
          {
            header: 'Engine Idle Hours',
            value: formatUnit('duration', totals.engineIdleTime),
          },
          {
            header: 'Idling Count',
            value: formatUnit('integer', aggregation.Idling && aggregation.Idling.count.total),
          },
          {
            header: 'Points/Hour',
            value: formatUnit(
              'integer',
              (totals.pointsAccumulated / (totals.engineTime / 3600) || 0).toFixed(2)
            ),
          },
        ]}
      />
      <AggregatesCard
        title="Session Incidents"
        name="incidents"
        fields={[
          {
            header: 'Excessive Speed',
            value: formatUnit('integer', aggregation.Speed && aggregation.Speed.count.harsh),
          },
          {
            header: 'Excessive RPM',
            value: formatUnit('integer', aggregation.RPM && aggregation.RPM.count.harsh),
          },
          {
            header: 'Harsh Braking',
            value: formatUnit('integer', aggregation.Brake && aggregation.Brake.count.harsh),
          },
        ]}
      />
      <AggregatesCard
        title="Rule Totals"
        name="totals"
        fields={[
          {
            header: 'Count',
            value: formatUnit('integer', countOf(rows)),
          },
          {
            header: 'Sum Distance',
            value: formatUnit('distance', sumOfField(rows, 'data.distance')),
          },
          {
            header: 'Sum Duration',
            value: formatUnit('duration', sumOfField(rows, 'data.duration')),
          },
          {
            header: 'Sum Points',
            value: formatUnit('integer', sumOfField(rows, 'data.points')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: customPropTypes.performanceDetails,
};
