import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { Form, SelectInput, TextInput, useBooleanState } from 'stti-react-common';

import { CrudAdminItem, InfoAdminItemDialog } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { useApiError } from '../../../../../helpers/hooks';

export const ApiKeyDetail = ({ createApiKey, mode: initialMode = 'create', route, userName }) => {
  const [mode, setMode] = useState(initialMode);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, openApiKeyDialog] = useBooleanState();
  const { apiError, setApiError } = useApiError();

  const { useFormController, Control } = Form;
  const form = useFormController();
  const { controls: formControls, resetControls } = form;

  const createNewKey = (formValues) => {
    const payload = { ...formValues, creatorName: userName };
    return createApiKey(payload).then((response) => {
      openApiKeyDialog();
      setApiKey(response.apiKey);
    });
  };

  return (
    <div className="ApiKeyAdminDetail">
      <CrudAdminItem
        createItem={() => createNewKey(formControls)}
        data={formControls}
        form={form}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        itemTitle={formControls.name || ''}
        mode={mode}
        onApiError={setApiError}
        onClose={route.close}
        preventCreateClose
        resetControls={resetControls}
        setMode={setMode}
        title="API Key"
      >
        <>
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control Component={TextInput} name="name" label="Name" error={({ value }) => !value} />
            <Control
              Component={SelectInput}
              name="accessType"
              label="Access Type"
              error={({ value }) => isNil(value)}
              options={[{ label: 'ReadWrite', value: 'ReadWrite' }]}
              defaultValue="ReadWrite"
            />
          </div>
        </>
      </CrudAdminItem>
      <InfoAdminItemDialog
        dialogTitle="API Key"
        dialogText={
          <div>
            <p>This is the only time you will see this key.</p>
            <p>When it is lost it cannot be recovered, please keep it secure.</p>
            <h4>{apiKey}</h4>
          </div>
        }
        isOpen={isApiKeyDialogOpen}
        onClose={route.close}
      />
    </div>
  );
};

ApiKeyDetail.propTypes = {
  createApiKey: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
  userName: PropTypes.string,
};
