import { filter, map, flatten, groupBy, sumBy, uniq, uniqueId } from 'lodash';
import moment from 'moment-timezone';

import { dateWithTimeZoneToIso } from '../../helpers/moment';
import {
  makeRestApiAction,
  makeOneTimeOptimizedAction,
  omitPrivateProperties,
} from '../apiActionSupport';
import { ACTION_TYPE } from './fuelTaxMileagesReducer';
import { selectTaxMileagesFetches } from './fuelTaxMileagesSelectors';

const aggregate = (lookup, row) => {
  if (lookup === 'distance') {
    return (
      (row.endOnHighway || 0) -
      (row.startOnHighway || 0) +
      (row.endOffHighway || 0) -
      (row.startOffHighway || 0)
    );
  }
  if (lookup === 'onHighway') {
    return (row.endOnHighway || 0) - (row.startOnHighway || 0);
  }
  return (row.endOffHighway || 0) - (row.startOffHighway || 0);
};

const transformForStore = (data, { ouKey, ous, timeZone }) => {
  const trips = map(flatten(map(data, 'trips')), omitPrivateProperties).map(
    ({ key, distances, start, end, ...rest }) => ({
      key: `${ouKey}-${rest.driverKey || uniqueId('no-driver')}${moment(start).format('x')}`,
      startedAt: dateWithTimeZoneToIso(start, timeZone),
      endedAt: dateWithTimeZoneToIso(end, timeZone),
      ouKey,
      timeZone,
      jurisdictions: uniq(map(distances, 'state')),
      distance: sumBy(distances, (row) => aggregate('distance', row)),
      onHighway: sumBy(distances, (row) => aggregate('onHighway', row)),
      offHighway: sumBy(distances, (row) => aggregate('offHighway', row)),
      distances:
        distances &&
        map(groupBy(distances.map(omitPrivateProperties), 'state'), (value, state) => ({
          state,
          distance: sumBy(value, (row) => aggregate('distance', row)),
          onHighway: sumBy(value, (row) => aggregate('onHighway', row)),
          offHighway: sumBy(value, (row) => aggregate('offHighway', row)),
        })),
      ...rest,
    })
  );

  return flatten(
    trips.map((row) => {
      const arr = [];
      row.jurisdictions.forEach((item) => {
        const { key, jurisdictions, distance, distances, ...rest } = row;
        arr.push({
          key: `${key}-${item}`,
          jurisdiction: item,
          jurisdictions,
          ou: ous && ous.find(({ key: id }) => id === ouKey),
          distance: distances.find(({ state }) => state === item),
          distances,
          ...rest,
        });
      });
      return arr;
    })
  );
};

const fetchTaxMileages = makeRestApiAction({
  service: 'fuelTaxMileages',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTaxMileages,
  transformInput: ({ startDate, ouKey }) => ({
    ou: ouKey,
    date: moment(startDate).format('YYYY-MM'),
    type: 'fueltracking.DailyFuelTaxTrips',
  }),
  transformOutput: transformForStore,
  notificationsItemDescriptor: 'fuelTaxMileages',
});

// fetchTaxMileagesOptimized({ date, ouKey, timeZone })
export const fetchTaxMileagesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTaxMileagesFetches,
  filterFetches: (fetches, { date, ouKey }) => filter(fetches, { date, ouKey }),
  baseActionType: ACTION_TYPE.fetchTaxMileages,
  fetchAction: fetchTaxMileages,
});
