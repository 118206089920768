import { useMemo } from 'react';
import { flatten } from 'lodash';

import { useFormats } from '../Formats';

const customizableBaseFormats = ['date', 'distance', 'duration', 'mileage', 'volume', 'weight'];

const controlNames = flatten(
  customizableBaseFormats.map((baseFormat) => [`${baseFormat}Format`, `${baseFormat}ExportFormat`])
);

const isDefault = (controlValue) => !controlValue || controlValue === 'default';

export const useReportFormats = ({ controls }) => {
  const { formats } = useFormats();

  return useMemo(() => {
    const overriddenFormats = {};

    customizableBaseFormats.forEach((baseFormat) => {
      // display format
      const controlDisplayValue = controls[`${baseFormat}Format`];
      if (!isDefault(controlDisplayValue)) {
        overriddenFormats[baseFormat] = controlDisplayValue;
      }

      // export format
      const controlExportValue = controls[`${baseFormat}ExportFormat`];
      overriddenFormats[`${baseFormat}Export`] = !isDefault(controlExportValue)
        ? controlExportValue
        : overriddenFormats[baseFormat];
    });

    // derived formats
    if (overriddenFormats.distance) overriddenFormats.speed = overriddenFormats.distance;
    if (overriddenFormats.distanceExport)
      overriddenFormats.speedExport = overriddenFormats.distanceExport;

    const mergedFormats = { ...formats, ...overriddenFormats };

    return { mergedFormats, overriddenFormats };
  }, [formats, ...controlNames.map((controlName) => controls[controlName])]);
};
