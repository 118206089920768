import { connect } from 'react-redux';

import { GeozonesSummaryReport } from './GeozonesSummaryReport';
import { fetchGeozonesOptimized, selectGeozones } from '../../../../data/reports';

import { organization } from '../../../../data/administration/configurations';

export const GeozonesSummaryReportContainer = connect(
  (state) => ({
    geozones: selectGeozones(state),
  }),
  {
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    fetchGeozones: fetchGeozonesOptimized,
  }
)(GeozonesSummaryReport);
