import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  meanOfField,
  countOf,
  countUniqueOfField,
  countTruthyOfField,
  sumOfField,
} from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();
  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Logs',
            value: countOf(rows),
          },
          {
            header: 'With Activity',
            value: countTruthyOfField(rows, 'hasActivity'),
          },
          {
            header: 'Vehicles',
            value: countUniqueOfField(rows, 'vehicle.key'),
          },
        ]}
      />
      <AggregatesCard
        title="Duration"
        name="duration"
        fields={[
          {
            header: 'Driving',
            value: formatUnit('duration', sumOfField(rows, 'onDutyDrivingTotal')),
          },
          {
            header: 'On Duty Not Driving',
            value: formatUnit('duration', meanOfField(rows, 'onDutyNotDrivingTotal')),
          },
          {
            header: 'On Duty Total',
            value: formatUnit('duration', meanOfField(rows, 'onDutyTotal')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
