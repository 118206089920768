import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from 'stti-react-common';

import './ColorChip.scss';

export const ColorChip = ({ className = '', color, label, ...rest }) => (
  <Chip className={`ColorChip ColorChip--${color} ${className}`} label={label} {...rest} />
);

ColorChip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['green', 'red', 'amber', 'grey']).isRequired,
  label: PropTypes.string.isRequired,
};
