import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { api } from '../../../data/api';
import { store } from '../../../data/store';

export const DataProvider = ({ api: apiInstance, children }) => {
  api.useInstance(apiInstance);
  return <Provider store={store}>{children}</Provider>;
};

DataProvider.propTypes = {
  api: PropTypes.object.isRequired,
  children: PropTypes.node,
};
