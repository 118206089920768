import { isNil, toString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { BasicDialog, Form, NumberInput } from 'stti-react-common';

import { useFormats } from '../../../../commons/Formats';

export const SpeedLimitDialog = ({
  closeDialog,
  defaultGracePeriod,
  defaultSpeedLimit,
  isOpen,
  isSuperAdminFromTopOu,
  onSave,
  onSetDefaults,
  targetRow,
}) => {
  const { unitText, convertUnit, unconvertUnit } = useFormats();
  const { Control, useFormController } = Form;
  const form = useFormController({
    controls: {
      ...targetRow,
      speedLimit: Math.round(convertUnit('speed', targetRow.speedLimit)),
    },
  });

  const { controls, resetControls, hasErrors } = form;

  const onFormSave = (formControls) => {
    const { appliesTo, gracePeriod, operationalLevel, speedLimit } = formControls;
    onSave({
      appliesTo,
      gracePeriod,
      operationalLevel,
      speedLimit: unconvertUnit('speed', speedLimit),
    });
  };

  const onDefaultSave = (formControls) => {
    const { appliesTo, operationalLevel } = formControls;
    onSetDefaults({ appliesTo, operationalLevel });
  };

  return (
    <BasicDialog
      className="SpeedLimitDialog"
      title={targetRow.name}
      isOpen={isOpen}
      onOpen={resetControls}
      onClose={() => closeDialog()}
      buttons={[
        { label: 'Cancel', onClick: () => closeDialog() },
        {
          label: 'Use Defaults',
          onClick: () => onDefaultSave(controls),
          disabled: !isSuperAdminFromTopOu,
        },
        {
          label: 'Save',
          onClick: () => onFormSave(controls),
          disabled: hasErrors || !isSuperAdminFromTopOu,
        },
      ]}
    >
      <Form form={form} className="SpeedLimitDialog__Form--inline">
        <Control
          Component={NumberInput}
          name="speedLimit"
          label="Speed Limit"
          min={0}
          placeholder={Math.round(convertUnit('speed', defaultSpeedLimit)).toString()}
          endAdornment={unitText('speed')}
          error={({ value }) => {
            if (isNil(value)) return 'Speed Limit is required';
            if (value < 0) return 'Speed Limit must be positive.';
            if (Math.round(value) !== value) return 'Speed Limit must be a whole number.';
            return false;
          }}
        />
        <Control
          Component={NumberInput}
          name="gracePeriod"
          label="Grace Period"
          min={0}
          placeholder={toString(defaultGracePeriod)}
          endAdornment="seconds"
          error={({ value }) => {
            if (isNil(value)) return 'Grace Period is required';
            if (value < 0) return 'Grace Period must be positive.';
            if (Math.round(value) !== value) return 'Grace Period must be a whole number.';
            return false;
          }}
        />
      </Form>
    </BasicDialog>
  );
};

SpeedLimitDialog.propTypes = {
  targetRow: PropTypes.object,
  defaultSpeedLimit: PropTypes.number,
  defaultGracePeriod: PropTypes.number,
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  onSave: PropTypes.func,
  onSetDefaults: PropTypes.func,
};
