import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniq } from 'lodash';

import { SelectFilterInput } from '../SelectFilterInput';

export const LocationFilters = ({ rows, group, filterGroup }) => {
  const getOptions = useMemo(
    () => ({
      originName: () =>
        sortBy(uniq(rows.map((cycle) => ({ label: cycle.origin.name, value: cycle.origin.name })))),
      firstLoadName: () =>
        sortBy(
          uniq(rows.map((cycle) => ({ label: cycle.firstLoad.name, value: cycle.firstLoad.name })))
        ),
      lastUnloadName: () =>
        sortBy(
          uniq(
            rows.map((cycle) => ({
              label: cycle.lastUnload.name,
              value: cycle.lastUnload.name,
            }))
          )
        ),
    }),
    [rows]
  );
  return (
    <>
      <SelectFilterInput
        name="originName"
        group={group}
        field="origin.name"
        filterGroup={filterGroup}
        label="Origin Locations"
        summaryPrefix="Origin: "
        itemPlural="locations"
        autoGridArea
        getOptions={getOptions.originName}
      />
      <SelectFilterInput
        name="firstLoadName"
        group={group}
        field="firstLoad.name"
        filterGroup={filterGroup}
        label="Load Locations"
        summaryPrefix="Load: "
        itemPlural="locations"
        autoGridArea
        getOptions={getOptions.firstLoadName}
      />
      <SelectFilterInput
        name="lastUnloadName"
        group={group}
        field="lastUnload.name"
        filterGroup={filterGroup}
        label="Unload Locations"
        summaryPrefix="Unload: "
        itemPlural="locations"
        autoGridArea
        getOptions={getOptions.lastUnloadName}
      />
    </>
  );
};

LocationFilters.propTypes = {
  rows: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
};
