import { cloneDeep, filter, isObject, isUndefined } from 'lodash';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../helpers/moment';
import { makeRestApiAction, makeDateRangeOptimizedAction } from '../apiActionSupport';
import { ACTION_TYPE } from './tripsReducer';
import { selectTripsFetches } from './tripsSelectors';

const transformForStore = (trip) => {
  const transformedTrip = cloneDeep({
    ...trip,
    ouKey: trip.ou.key,
    trailersNames: trip.trailers.map((trailer) => trailer.name),
    driversNames: trip.drivers.map((driver) => driver.name),
  });

  // add total violation count
  transformedTrip.count.totalViolations =
    trip.count.ctiViolations + trip.count.hosViolations + trip.count.speedViolations;

  // add mileage in m/l
  Object.entries(transformedTrip).forEach(([key, value]) => {
    if (!isObject(value)) return; // not a field group
    const { distance, consumedFuel } = value;
    if (isUndefined(distance) || isUndefined(consumedFuel)) return; // field group is not a leg
    transformedTrip[key].mileage =
      distance > 0 && consumedFuel > 0 ? distance / consumedFuel : null; // mileage will be null if either field is 0 or null
  });

  return transformedTrip;
};

// fetchTrips({ startDate, endDate, ouKey, timeZone })
const fetchTrips = makeRestApiAction({
  service: 'trips',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTrips,
  transformInput: ({ startDate, endDate, ouKey: ou, timeZone }) => ({
    ou,
    tripType: 'Standard',
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
  }),
  transformOutput: (data) => data.map(transformForStore),
  notificationsItemDescriptor: 'trips',
  enableProgress: true,
});

// fetchTripsOptimized({ startDate, endDate, ouKey, timeZone })
export const fetchTripsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectTripsFetches,
  filterFetches: (fetches, { ouKey: ou }) => filter(fetches, { ou }),
  baseActionType: ACTION_TYPE.fetchTrips,
  fetchAction: fetchTrips,
  fetchActionConfig: {
    transformInput: ({ startDate, endDate, ouKey: ou }) => ({
      ou,
      tripType: 'Standard',
      startDate, // startDate is already ISO string
      endDate, // endDate is already ISO string
    }),
  },
});

// fetchTrip(id)
export const fetchTrip = makeRestApiAction({
  service: 'trips',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchTrip,
  transformOutput: transformForStore,
});
