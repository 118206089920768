import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'stti-react-common';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { FiltersPanel } from './FiltersPanel';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './FuellingsSummaryReport.scss';

const { createState, useDataGridComponents } = ControlledDataGrid;

const defaultState = () => ({
  controls: { dateRangeMode: 'last7Days' },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['fuellings'];

export const FuellingsSummaryReport = ({
  fetchFuellings,
  fuellings,
  limits,
  reportViewId,
  route,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'fuellingsSummary',
    reportTypeName: 'Fuelling Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: fuellings,
      chain: ['query', 'filters', 'outlier', 'invertOutlier'],
    },
  });
  const {
    debouncedControls,
    setControl,
    filteredRows,
    dataGridController,
    setDataGridFilteredRows,
    reportType,
  } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleNames', [data?.vehicle?.key])}>
        {value}
      </Clickable>
    ),
    VehicleType: ({ value }) => (
      <Clickable onClick={() => setControl('vehicleTypes', [value])}>{value}</Clickable>
    ),
    Driver: ({ data, value }) => (
      <Clickable onClick={() => setControl('driverNames', [data?.driver?.key])}>{value}</Clickable>
    ),
    Jurisdiction: ({ value }) => (
      <Clickable onClick={() => setControl('jurisdictions', [value])}>{value}</Clickable>
    ),
    Vendor: ({ value }) => (
      <Clickable onClick={() => setControl('vendors', [value])}>{value}</Clickable>
    ),
    Location: ({ value }) => (
      <Clickable onClick={() => setControl('locationNames', [value])}>{value}</Clickable>
    ),
    FuelType: ({ value }) => (
      <Clickable onClick={() => setControl('fuelTypes', [value])}>{value}</Clickable>
    ),
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchFuellings} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="FuellingsSummaryReport">
        <ReportFragment.Header
          reportType="fuellingSummary"
          route={route}
          services={requestServices}
        />

        <QueryPanel
          queryFor="fuelling"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2018-01-01"
          disableFuture
        />
        <FiltersPanel rows={filteredRows.query} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel
          dataGridController={dataGridController}
          dataGridComponents={dataGridComponents}
          filteredRows={filteredRows}
          setDataGridFilteredRows={setDataGridFilteredRows}
          debouncedControls={debouncedControls}
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.output} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

FuellingsSummaryReport.propTypes = {
  fetchFuellings: PropTypes.func.isRequired,
  fuellings: customPropTypes.fuellings.isRequired,
  limits: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
