import React, { useState } from 'react';
import { each, uniqueId, isArray, includes } from 'lodash';
import { Icon, ScrollBar, Clickable, BasicDialog } from 'stti-react-common';
import PropTypes from 'prop-types';

import { getDatelessTime } from '../../../helpers/moment';

function mapActivities(activities) {
  const outputActivities = [];
  each(activities, function iterateActivity(activity) {
    outputActivities.push(activity);

    if (activity.subActivities && isArray(activity.subActivities)) {
      each(activity.subActivities, function iterateSubActivity(subActivity) {
        outputActivities.push(subActivity);
      });
    }
  });
  return outputActivities;
}

const ShiftTable = ({ selectedData, formatUnit, isDetailed }) => (
  <table>
    <thead>
      <tr>
        <th>Start Time</th>
        <th>Activity Type</th>
        <th>Duration</th>
        <th>Distance</th>
        <th>Vehicle</th>
        <th>Notes</th>
        {!isDetailed && <th>Location</th>}
        {isDetailed && <th>Start Location</th>}
        {isDetailed && <th>End Location</th>}
      </tr>
    </thead>
    <tbody>
      {selectedData?.shift?.activities.map((data) => (
        <tr key={uniqueId()}>
          <td>{getDatelessTime(data.start, selectedData?.timeZone, 'HH:mm')}</td>
          <td>{data.displayName}</td>
          <td>{formatUnit('duration', data.duration, 'h:mm')}</td>
          <td>{formatUnit('distance', data.distance)}</td>
          <td>{data.assetId}</td>
          <td>{data.notes}</td>
          <td>
            <a
              href={`https://google.com/maps/?q=loc:${data.startLocation?.latitude},${data.startLocation?.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="location">
                <Icon icon="location_on" />
                {data.startLocationName}
              </div>
            </a>
          </td>

          {isDetailed && (
            <td>
              <a
                href={`https://google.com/maps/?q=loc:${data.endLocation?.latitude},${data.endLocation?.longitude}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="location">
                  <Icon icon="location_on" />
                  {data.endLocationName}
                </div>
              </a>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

ShiftTable.propTypes = {
  selectedData: PropTypes.any,
  formatUnit: PropTypes.func,
  isDetailed: PropTypes.bool,
};

export const ShiftSummary = ({ selectedData, formatUnit }) => {
  const [openShiftSummaryModal, setOpenShiftSummaryModal] = useState(false);

  if (!selectedData.shift || !selectedData.shift.activities) {
    return (
      <div className="shiftSummary">
        <h2>Current Shift Details</h2>
        <div className="no-data">
          <h3>No current shift details available yet.</h3>
        </div>
      </div>
    );
  }

  const activities = mapActivities(selectedData.shift.activities);

  const aggregation = {
    duration: activities?.reduce((total, activity) => total + activity.duration, 0) || 0,
    distance: activities?.reduce((total, activity) => total + activity.distance, 0) || 0,
    loads: activities?.filter((activity) => activity.typeClass === 'LoadActivity').length,
    unloads: activities?.filter((activity) => activity.typeClass === 'UnloadActivity').length || 0,
    driving:
      activities
        ?.filter((activity) => activity.typeClass === 'Driving')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    break:
      activities
        ?.filter((activity) => activity.typeClass === 'Break')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    others:
      activities
        ?.filter((activity) => activity.typeClass === 'Activity')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    envWait:
      activities
        ?.filter((activity) => activity.typeClass === 'EnvironmentalWait')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    loadUnloadWait: activities
      ?.filter((activity) => includes(['LoadWait', 'UnloadWait'], activity.typeClass))
      ?.reduce((total, activity) => total + activity.duration, 0),
    loadUnload: activities
      ?.filter((activity) => includes(['Load', 'Unload'], activity.typeClass))
      ?.reduce((total, activity) => total + activity.duration, 0),
    unknown:
      activities
        ?.filter((activity) => activity.displayName === 'Unknown Stop')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
  };

  return (
    <div className="shiftSummary">
      <div className="shiftSummary__title">
        <Clickable onClick={() => setOpenShiftSummaryModal(true)}>
          <Icon icon="open_in_new" />
        </Clickable>

        <h4>Current Shift Details</h4>
      </div>
      <div className="shiftSummary__tableContainer">
        <ScrollBar style={{ height: '170px' }}>
          <ShiftTable selectedData={selectedData} formatUnit={formatUnit} />
        </ScrollBar>
      </div>

      <BasicDialog
        title={`Current Shift Details for driver InTest ${selectedData?.driver?.name}`}
        isOpen={openShiftSummaryModal}
        onClose={() => setOpenShiftSummaryModal(false)}
        className="shiftSummary__modal"
        maxWidth="lg"
        fullWidth
        fullHeight
      >
        <div className="shiftSummary__modal__row">
          <h4>Shift Summary</h4>
          <div className="container">
            <div className="box">
              <h4>Start Time</h4>
              <p>{getDatelessTime(selectedData?.shift?.start, selectedData.timeZone, 'HH:mm')} </p>
            </div>
            <div className="box">
              <h4>End Time</h4>
              <p>{getDatelessTime(selectedData?.shift?.end, selectedData.timeZone, 'HH:mm')} </p>
            </div>
            <div className="box">
              <h4>Shift Length</h4>
              <p>
                {aggregation.duration > 0
                  ? formatUnit('duration', aggregation.duration, 'h:mm')
                  : '--:--'}
              </p>
            </div>
            <div className="box">
              <h4>Driving Distance</h4>
              <p>
                {aggregation.distance > 0 ? formatUnit('distance', aggregation.distance) : '--:--'}
              </p>
            </div>
            <div className="box">
              <h4>Loads/Unloads</h4>
              <p>{`${aggregation.loads} / ${aggregation.unloads}`} </p>
            </div>
          </div>
        </div>
        <div className="shiftSummary__modal__row">
          <h4>Time Summary</h4>
          <div className="container">
            <div className="box">
              <h4>Driving</h4>
              <p>{formatUnit('duration', aggregation.driving, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Break</h4>
              <p>{formatUnit('duration', aggregation.break, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Other Activities</h4>
              <p>{formatUnit('duration', aggregation.others, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Env. Wait</h4>
              <p>{formatUnit('duration', aggregation.envWait, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Waiting to Load/Unload</h4>
              <p>{formatUnit('duration', aggregation.loadUnloadWait, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Load/Unload</h4>
              <p>{formatUnit('duration', aggregation.loadUnload, 'h:mm')} </p>
            </div>
            <div className="box">
              <h4>Unknown</h4>
              <p>{formatUnit('duration', aggregation.unknown, 'h:mm')} </p>
            </div>
          </div>
        </div>
        <div className="shiftSummary__modal__row">
          <h4>Shift Timeline</h4>
          <div className="container">
            <ShiftTable selectedData={selectedData} formatUnit={formatUnit} isDetailed />
          </div>
        </div>
      </BasicDialog>
    </div>
  );
};

ShiftSummary.propTypes = {
  selectedData: PropTypes.any,
  formatUnit: PropTypes.func,
};
