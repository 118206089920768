// TODO: Deprecate moment in favor of dayjs

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

const moment = extendMoment(Moment);

// DEPRECATE ALL METHODS BELOW IN FAVOR OF DAYJS

export const isDateValid = (dateOrDateTimeString) => {
  const inputDate = moment(dateOrDateTimeString).format('YYYY-MM-DD');
  const parsedDate = moment(inputDate, 'YYYY-MM-DD', true);
  return parsedDate.isValid();
};

export const daysBetweenDates = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return moment.duration(end.diff(start)).asDays() + 1;
};

export const monthsBetweenDates = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return moment.duration(end.diff(start)).asMonths() + 1;
};

export const formatTimezone = (value) => {
  if (!value) return '';
  return value.replace(':', '');
};

export const getDateSequences = (start, end) => {
  const dateArray = [];
  let currentDate = moment(start);
  const stopDate = moment(end);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM'));
    currentDate = moment(currentDate).add(1, 'month');
  }
  return dateArray;
};

export const createMomentRange = (momentStart, momentEnd) => moment.range(momentStart, momentEnd);

export const getTimelessDate = (date) =>
  date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

// This is used to define inside an array what StartedAt and EndedAtDate are min/max
export const getMinMaxDatesFromArray = (collection) => {
  const startDates = collection.map(({ startedAt }) => (startedAt ? moment(startedAt) : moment()));
  const minStartDate = moment.min(startDates);

  const endDates = collection.map(({ endedAt }) => (endedAt ? moment(endedAt) : moment()));
  const maxEndDate = moment.max(endDates);

  return { minStartDate, maxEndDate };
};

export const timeToDecimal = (t) => {
  if (!t) return '';
  const arr = t.split(':');
  const dec = parseInt((arr[1] / 6) * 10, 10);
  if (isNaN(dec)) return null;

  return parseFloat(`${parseInt(arr[0], 10)}.${dec < 10 ? '0' : ''}${dec}`).toFixed(1);
};

export const timeInMillisecondsToDecimal = (t) => {
  if (!t) return '0.0';
  const minutes = t / (60 * 1000);
  const hours = minutes / 60;
  const remainingMinutes = minutes % 60;
  const dec = parseInt((remainingMinutes / 6) * 10, 10);

  return parseFloat(`${parseInt(hours, 10)}.${dec < 10 ? '0' : ''}${dec}`).toFixed(1);
};

// Methods using JSDoc

/**
 * Returns a numeric duration value between two intervals (date, date-time or time)
 * @param {String} start - Start Interval could be in any date-string string format
 * @param {String} end - End Interval could be in any date-string string format
 * @param {String} unit - Unit used. Defaults to 'minutes'. Options: [days, hours, minutes, seconds, milliseconds]
 * @param {String} format - Format used to parse strings into a moment object. Defaults to 'YYYY-MM-DD HH:mm:ss'
 */
export const durationBetweenIntervals = (
  start,
  end,
  unit = 'minutes',
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const startMoment = moment(start, format);
  const endMoment = moment(end, format);

  return moment.duration(endMoment.diff(startMoment)).as(unit);
};

/**
 * Returns a moment date object using a provided string
 * @param {String} value - Date, DateTime or Time input
 * @param {String} timeZone - Timezone used to override default timezone.
 */
export const toMoment = (value, timeZone) =>
  timeZone ? moment.tz(value, timeZone) : moment(value);

/**
 * Returns a boolean value when comparing a date between two dates
 * @param {String} compareDate - Date, DateTime or Time input
 * @param {String} startDate - Date, DateTime or Time input
 * @param {String} endDate - Date, DateTime or Time input
 * @param {String} forceEnd - Boolean to force or not the end of day
 */
export const compareDateWithDateInterval = (compareDate, startDate, endDate, forceEnd) => {
  if (forceEnd) {
    return (
      moment(compareDate).isSameOrAfter(moment(startDate)) &&
      moment(compareDate).isSameOrBefore(moment(endDate).endOf('day'))
    );
  }
  return (
    moment(compareDate).isSameOrAfter(moment(startDate)) &&
    moment(compareDate).isSameOrBefore(moment(endDate))
  );
};

/**
 * Returns a numeric value when comparing start of that day with a provided dateTime string
 * @param {String} dateTimeString - DateTime string
 * @param {String} timeZone - Optional set to timezone.
 */
export const getNumberFromDateTime = (dateTimeString, timeZone, scale = 'minutes') =>
  timeZone
    ? moment
        .tz(dateTimeString, timeZone)
        .diff(moment.tz(dateTimeString, timeZone).startOf('day'), scale)
    : moment(dateTimeString).diff(moment(dateTimeString).startOf('day'), scale);

/**
 * Returns a formatted time string
 * @param {String} date - DateTime string, optional, if null it will return today's date
 * @param {String} timeZone - Optional set to timezone.
 * @param {String} format - Optional format mask, default is HH:mm
 */
export const getDatelessTime = (date, timeZone, format) => {
  if (date) {
    return timeZone
      ? moment.tz(moment(date), timeZone).format(format || 'HH:mm')
      : moment(date).format(format || 'HH:mm');
  }
  return timeZone
    ? moment.tz(moment(), timeZone).format(format || 'HH:mm')
    : moment().format(format || 'HH:mm');
};

/**
 * Returns a start of day or end of day moment object based on a dateTime string provided
 * @param {String} dateString - DateTime string, optional, if null it will return today's date
 * @param {String} period - Optional period of day. Default is start.
 */
export const toMomentStartOrEnd = (dateString, period = 'start', unit = 'day') => {
  if (period === 'start') {
    return dateString ? moment(dateString).startOf(unit) : moment().startOf(unit);
  }
  return dateString ? moment(dateString).endOf(unit) : moment().endOf(unit);
};

/**
 * Returns a time formatted string based on a given numeric value
 * @param {Number} value - numeric value
 * @param {String} scale - Optional scale. Default is minute.
 * @param {String} format - Optional time format. Default is LT.
 */
export const toHourMinute = ({ value, scale = 'minute', format = 'LT' }) =>
  moment.utc(moment.duration(value, scale).asMilliseconds()).format(format);

/**
 * Returns a numeric value based on a given ouMode, limit number and a difference by days value
 * @param {String} ouMode - string value.
 * @param {Object} limits - limits object containing forAllOus and forOu properties.
 * @param {Number} differenceByDays - numeric value containing the difference between start and end dates.
 */
export const getRangeStepByMode = (ouMode, limits, differenceByDays) => {
  if (ouMode === 'all') {
    return limits.forAllOus;
  }

  const stepReturned =
    limits.forOu >= differenceByDays
      ? limits.forOu
      : limits.forOu - (differenceByDays - limits.forOu);

  if (stepReturned < 0) return limits.forOu;

  return stepReturned;
};

export const setEndDateAfterStartDate = (rowDate, endDate) => {
  if (moment(endDate).isBefore(rowDate)) {
    return moment(endDate).toISOString();
  }
  return moment(rowDate).toISOString();
};

export const getMonthInterval = (startDate) => ({
  start: moment([
    moment(startDate, 'YYYY-MM').format('YYYY'),
    moment(startDate, 'YYYY-MM').format('MM') - 1,
  ]).format('YYYY-MM-DD'),
  end: moment([
    moment(startDate, 'YYYY-MM').format('YYYY'),
    moment(startDate, 'YYYY-MM').format('MM') - 1,
  ])
    .endOf('month')
    .format('YYYY-MM-DD'),
});

export const dateWithTimeZoneToIso = (date, timeZone) =>
  moment.tz(date, timeZone).utc().toISOString();

export const startDateWithTimeZoneToIso = (startDate, timeZone) => {
  const startDateTime = moment.tz(startDate, timeZone).startOf('day').toISOString();
  const maxStartDateTime = moment.tz(moment(), timeZone).startOf('day').toISOString();
  return maxStartDateTime < startDateTime ? maxStartDateTime : startDateTime;
};

export const endDateWithTimeZoneToIso = (endDate, timeZone) => {
  if (!isDateValid(endDate)) {
    return moment.tz(moment(), timeZone).endOf('day').toISOString();
  }

  const endDateTime = moment.tz(endDate, 'YYYY-MM-DD', timeZone).endOf('day').toISOString();
  const maxEndDateTime = moment.tz(moment(), timeZone).endOf('day').toISOString();

  return maxEndDateTime < endDateTime ? maxEndDateTime : endDateTime;
};

export const dateWithoutTime = (date) => {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
};

export const getTimeZoneOffset = (timeZone) => moment.tz(timeZone).utcOffset();

export const monthNameToNumber = (monthName) => Number(moment().month(monthName).format('M'));

export const humanizeDate = (date) => moment(date).fromNow();

/** DAYJS METHODS * */

/**
 * Returns a dayjs object for the given date
 * @param {string|Date} date - Date string or Date object
 * @param {string} tz - Timezone used to override default timezone
 * @returns {Object} Dayjs object
 */
export const dateObject = (date, tz) => (tz ? dayjs(date).tz(tz) : dayjs(date));

export const getRelativeTime = (date) => {
  const durationDate = dayjs.duration(dayjs().diff(date)).$d;

  let roundedMonths = Math.floor(
    durationDate.days >= 29 ? durationDate.months + 1 : durationDate.months
  );
  roundedMonths = roundedMonths > 12 ? 0 : roundedMonths;

  return `${
    durationDate.years > 0
      ? `${durationDate.years === 1 ? '1 year' : `${durationDate.years} years`} ${
          roundedMonths > 0 ? `and ${roundedMonths} months ago` : 'ago'
        } `
      : dayjs().to(dayjs(date))
  }`;

  // return dayjs.duration(dayjs().diff(date), 'months').humanize(); // dayjs().to(dayjs(date)); // TODO: rename to humanizeDate after deprecate moment
};

export const getMinutes = (date) => dayjs().diff(dayjs(date), 'minutes');

export const fromToCalendarTime = (date, tz) =>
  tz ? dayjs(date).tz(tz).calendar(dayjs().tz(tz)) : dayjs(date).calendar(dayjs());

export const shortTimeFormatter = (date) => {
  const MINUTE_MSEC = dayjs.duration(1, 'minute').asMilliseconds();
  const HOUR_MSEC = dayjs.duration(1, 'hour').asMilliseconds();
  const DAY_MSEC = dayjs.duration(1, 'day').asMilliseconds();
  const WEEK_MSEC = dayjs.duration(1, 'week').asMilliseconds();

  const diff = dayjs().diff(date);

  if (diff < MINUTE_MSEC) {
    return 'now';
  }

  if (diff >= WEEK_MSEC) {
    return `${dayjs().diff(date, 'week')}w`;
  }

  if (diff >= DAY_MSEC) {
    return `${dayjs().diff(date, 'day')}d`;
  }

  if (diff >= HOUR_MSEC) {
    return `${dayjs().diff(date, 'hour')}h`;
  }

  if (diff >= MINUTE_MSEC) {
    return `${dayjs().diff(date, 'minute')}m`;
  }

  return dayjs(date).fromNow(true);
};

export const subtractHours = (numOfHours, date = new Date()) => {
  date.setHours(date.getHours() - numOfHours);

  return date;
};
