import { find, map } from 'lodash';

import {
  getValue,
  generateDynamicTitle,
  accelFormat,
  FUEL_FLOW,
  convertToMps,
  unconvertToMps,
} from './performanceRulesUtility';

export const RULE_TYPES = {
  Boost: {
    label: null,
    fields: ['description', 'speed', 'boost', 'pointsPerMinute', 'gracePeriod'],
    title: null,
    conditions: [
      { type: 'Speed', lowerBound: null, upperBound: null },
      { type: 'Brake', lowerBound: 1, upperBound: null },
      { type: 'Boost', lowerBound: null, upperBound: null },
    ],
    getConditionValues: ({ speedCondition, boostCondition }, convertUnit, format) => ({
      speedLowerBound: getValue('speed', speedCondition.lowerBound, convertUnit, format),
      speedUpperBound: getValue('speed', speedCondition.upperBound, convertUnit, format),
      boostLowerBound: getValue('pressure', boostCondition.lowerBound, convertUnit, format),
      boostUpperBound: getValue('pressure', boostCondition.upperBound, convertUnit, format),
    }),
    getDescriptionByCondition: ({ speedCondition }, convertUnit) =>
      generateDynamicTitle('Boost', convertUnit, { speedCondition }),
  },
  Brake: {
    label: 'Harsh Braking',
    fields: ['description', 'speed', 'accel', 'pointsPerMinute', 'gracePeriod', 'harsh'],
    title: null,
    conditions: [
      { type: 'Speed', lowerBound: null, upperBound: null },
      { type: 'Brake', lowerBound: 1, upperBound: null },
      { type: 'Acceleration', lowerBound: null, upperBound: null },
    ],
    getConditionValues: ({ speedCondition, accelCondition }, convertUnit, format) => ({
      speedLowerBound: getValue('speed', speedCondition.lowerBound, convertUnit, format),
      speedUpperBound: getValue('speed', speedCondition.upperBound, convertUnit, format),
      accelLowerBound: getValue(
        'speed',
        accelFormat(accelCondition.upperBound),
        convertUnit,
        format
      ),
      accelUpperBound: getValue(
        'speed',
        accelFormat(accelCondition.lowerBound),
        convertUnit,
        format
      ),
    }),
    getDescriptionByCondition: ({ speedCondition, accelCondition }, convertUnit, { harsh }) =>
      generateDynamicTitle('Brake', convertUnit, { speedCondition, accelCondition, harsh }),
  },
  Holdback: {
    label: 'Excessive RPM',
    fields: ['description', 'rpm', 'pointsPerMinute', 'gracePeriod', 'harsh'],
    title: 'Holdback RPM rule applies when the vehicle is moving, in gear and not consuming fuel.',
    conditions: [
      { type: 'FuelFlow', lowerBound: 0, upperBound: FUEL_FLOW },
      { type: 'Speed', lowerBound: 1, upperBound: null },
      { type: 'RPM', lowerBound: null, upperBound: null },
    ],
    getConditionValues: ({ rpmCondition }) => ({
      speedLowerBound: convertToMps(1),
      speedUpperBound: null,
      fuelLowerBound: 0,
      fuelUpperBound: FUEL_FLOW,
      rpmLowerBound: rpmCondition.lowerBound,
      rpmUpperBound: rpmCondition.upperBound,
    }),
    getDescriptionByCondition: ({ rpmCondition }, convertUnit) =>
      generateDynamicTitle('Holdback RPM', convertUnit, { rpmCondition }),
  },
  Idling: {
    label: null,
    fields: ['description', 'rpm', 'pointsPerMinute', 'gracePeriod'],
    title: 'Idling rule applies when the vehicle is stopped and the engine is on.',
    conditions: [
      { type: 'RPM', lowerBound: null, upperBound: null },
      { type: 'Speed', lowerBound: null, upperBound: convertToMps(1) },
    ],
    getConditionValues: ({ rpmCondition }) => ({
      speedLowerBound: null,
      speedUpperBound: convertToMps(1),
      rpmLowerBound: rpmCondition.lowerBound,
      rpmUpperBound: rpmCondition.upperBound,
    }),
    getDescriptionByCondition: ({ rpmCondition }, convertUnit) =>
      generateDynamicTitle('Idling', convertUnit, { rpmCondition }),
  },
  RPM: {
    label: 'Excessive RPM',
    fields: ['description', 'rpm', 'pointsPerMinute', 'gracePeriod', 'harsh'],
    title: 'Drive RPM rule applies when the vehicle is moving and consuming fuel.',
    conditions: [
      { type: 'RPM', upperBound: null, lowerBound: null },
      { type: 'Speed', lowerBound: convertToMps(1) },
      { type: 'FuelFlow', lowerBound: FUEL_FLOW },
    ],
    getConditionValues: ({ rpmCondition }) => ({
      speedLowerBound: convertToMps(1),
      speedUpperBound: null,
      fuelLowerBound: FUEL_FLOW,
      fuelUpperBound: null,
      rpmLowerBound: rpmCondition.lowerBound,
      rpmUpperBound: rpmCondition.upperBound,
    }),
    getDescriptionByCondition: ({ rpmCondition }, convertUnit) =>
      generateDynamicTitle('Drive RPM', convertUnit, { rpmCondition }),
  },
  Speed: {
    label: 'Excessive Speed',
    fields: ['description', 'speed', 'pointsPerMinute', 'gracePeriod', 'harsh'],
    title: 'Drive RPM rule applies when the vehicle is moving and consuming fuel.',
    conditions: [{ type: 'Speed', lowerBound: null, upperBound: null }],
    getConditionValues: ({ speedCondition }, convertUnit, format) => ({
      speedLowerBound: getValue('speed', speedCondition.lowerBound, convertUnit, format),
      speedUpperBound: getValue('speed', speedCondition.upperBound, convertUnit, format),
    }),
    getDescriptionByCondition: ({ speedCondition }, convertUnit) =>
      generateDynamicTitle('Speed', convertUnit, { speedCondition }),
  },
};

export const findConditionValuesByType = (conditions) => ({
  speedCondition: find(conditions, { type: 'Speed' }),
  boostCondition: find(conditions, { type: 'Boost' }),
  accelCondition: find(conditions, { type: 'Acceleration' }),
  rpmCondition: find(conditions, { type: 'RPM' }),
});

export const convertConditionValues = ({ conditions, type }, convertUnit) => {
  if (!type || !conditions) return null;

  return RULE_TYPES[type].getConditionValues(
    findConditionValuesByType(conditions),
    convertUnit,
    true
  );
};

export const getRuleTitle = (data, convertUnit) => {
  if (!data || !data.type || !data.conditions) return '';
  return RULE_TYPES[data.type].getDescriptionByCondition(
    findConditionValuesByType(data.conditions),
    convertUnit,
    data
  );
};

export const remapCondition = (values, conditions, type, unconvertUnit) => {
  const {
    speedLowerBound,
    speedUpperBound,
    accelLowerBound,
    accelUpperBound,
    boostLowerBound,
    boostUpperBound,
    rpmLowerBound,
    rpmUpperBound,
  } = values;

  return map(conditions, (condition) => {
    const newCondition = { ...condition };
    if (condition.type === 'Speed') {
      if (['Boost', 'Brake', 'Speed'].includes(type)) {
        newCondition.lowerBound = getValue('speed', speedLowerBound, unconvertUnit);
        newCondition.upperBound = getValue('speed', speedUpperBound, unconvertUnit);
      } else {
        newCondition.lowerBound = unconvertToMps(speedLowerBound);
        newCondition.upperBound = unconvertToMps(speedUpperBound);
      }
    }
    if (condition.type === 'Boost') {
      newCondition.lowerBound = getValue('pressure', boostLowerBound, unconvertUnit);
      newCondition.upperBound = getValue('pressure', boostUpperBound, unconvertUnit);
    }
    if (condition.type === 'Acceleration') {
      newCondition.lowerBound = getValue(
        'speed',
        accelFormat(accelUpperBound, true),
        unconvertUnit
      );
      newCondition.upperBound = getValue(
        'speed',
        accelFormat(accelLowerBound, true),
        unconvertUnit
      );
    }
    if (condition.type === 'RPM') {
      newCondition.lowerBound = rpmLowerBound;
      newCondition.upperBound = rpmUpperBound;
    }
    return newCondition;
  });
};

export const revertConditionValues = ({ conditions, conditionFormValues, type }, unconvertUnit) => {
  if (!conditionFormValues || !type) return null;
  return remapCondition(
    conditionFormValues,
    conditions || RULE_TYPES[type].conditions,
    type,
    unconvertUnit
  );
};
