import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';

import {
  ReportProvider,
  useReportController,
  FetchReportDetail,
} from '../../../commons/ReportsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { symbols } from '../../../commons/MapsCommon';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './InspectionLocationReport.scss';

const { Marker } = GoogleMap;

export const InspectionLocationReport = ({ fetchInspection, inspection, inspectionId }) => {
  const report = useReportController();
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    if (inspection && inspection.location) {
      setFocus(inspection.location);
    }
  }, [inspection]);

  return (
    <div className="InspectionLocationReport">
      <ReportProvider value={report}>
        <FetchReportDetail action={fetchInspection} args={inspectionId} />
        <NaviMap focus={focus}>
          {inspection && inspection.location && (
            <Marker position={inspection.location} icon={symbols.inspectionIcon} />
          )}
        </NaviMap>
      </ReportProvider>
    </div>
  );
};

InspectionLocationReport.propTypes = {
  fetchInspection: PropTypes.func.isRequired,
  inspection: customPropTypes.inspection,
  inspectionId: PropTypes.string.isRequired,
};
