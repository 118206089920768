import { connect } from 'react-redux';

import { selectTaxMileagesByKey } from '../../../../data/reports';
import { FuelTaxMileageLocationReport } from './FuelTaxMileageLocationReport';

export const FuelTaxMileageLocationReportContainer = connect(
  (state, { fuelTaxMileageKey }) => ({
    fuelTaxMileage: selectTaxMileagesByKey(state, fuelTaxMileageKey),
  }),
  {}
)(FuelTaxMileageLocationReport);
