import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';

import { symbols } from './symbols';
import { EventInfo } from './EventInfo';

const { Marker } = GoogleMap;

export const VideoMarker = ({
  eventAt,
  position,
  heading,
  speed,
  timeZone,
  descriptions,
  ...restProps
}) => (
  <Marker position={position} icon={symbols.videoIcon} {...restProps}>
    <EventInfo
      event={{
        eventType: 'VideoEvent',
        eventAt,
        heading,
        speed,
        timeZone,
        description: descriptions.join(', '),
      }}
    />
  </Marker>
);

VideoMarker.propTypes = {
  position: PropTypes.object.isRequired,
  heading: PropTypes.number,
  speed: PropTypes.number,
  eventAt: PropTypes.string,
  timeZone: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
};
