import React from 'react';
import PropTypes from 'prop-types';
import { fromToCalendarTime } from '../../../helpers/moment';

export const VehicleDefectSummary = ({ selectedData, selectedEntity }) => {
  const majorDefects = isNaN(selectedData?.inspection?.majorDefects)
    ? '-'
    : selectedData?.inspection?.majorDefects;
  const minorDefects = isNaN(selectedData?.inspection?.minorDefects)
    ? '-'
    : selectedData?.inspection?.minorDefects;

  return (
    <div className="tile">
      <h4>Vehicle Defect Summary </h4>
      <div className="tile__content">
        <div className="tile__content__item">
          <strong className="duration">{majorDefects}</strong>
          <span>Major Unrepaired</span>
        </div>
        <div className="tile__content__item">
          <strong className="duration">{minorDefects}</strong>
          <span>Minor Unrepaired</span>
        </div>
        <div className="tile__content__item">
          <p>
            {selectedEntity?.type === 'vehicle'
              ? selectedData?.inspection?.inspectorName || 'N/A'
              : selectedData?.inspection?.assetName || 'N/A'}
          </p>
          <p className="extra">
            {selectedData?.inspection?.endedAt &&
              fromToCalendarTime(selectedData?.inspection?.endedAt, selectedData.timeZone)}
          </p>
          <p className="extra">{selectedData?.inspection?.locationName}</p>
        </div>
      </div>
      <div className="tile__footer">
        {selectedData?.inspection?.id ? (
          <a
            href={`#/reports/inspectionDetail/${selectedData?.inspection?.id}/default`}
            target="_blank"
            rel="noreferrer"
          >
            View Details
          </a>
        ) : (
          <span>No Details</span>
        )}
      </div>
    </div>
  );
};

VehicleDefectSummary.propTypes = {
  selectedData: PropTypes.any,
  selectedEntity: PropTypes.any,
};
