import React, { useEffect, useState } from 'react';
import { GoogleMap } from 'stti-react-common';

import { ReportProvider, useReportController } from '../../../commons/ReportsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { symbols } from '../../../commons/MapsCommon';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './SpeedViolationLocationReport.scss';

const { Marker, Route } = GoogleMap;

export const SpeedViolationLocationReport = ({ speedViolation }) => {
  const report = useReportController();
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    if (speedViolation && speedViolation.startLocation && speedViolation.endLocation) {
      setFocus([speedViolation.startLocation, speedViolation.endLocation]);
    }
  }, [speedViolation]);

  return (
    <div className="SpeedViolationLocationReport">
      <ReportProvider value={report}>
        <NaviMap focus={focus}>
          {speedViolation && speedViolation.startLocation && (
            <Marker position={speedViolation.startLocation} icon={symbols.go} />
          )}
          {speedViolation && speedViolation.endLocation && (
            <Marker position={speedViolation.endLocation} icon={symbols.stop} />
          )}
          {speedViolation && speedViolation.startLocation && speedViolation.endLocation && (
            <Route
              path={[speedViolation.startLocation, speedViolation.endLocation]}
              line={symbols.violation}
            />
          )}
        </NaviMap>
      </ReportProvider>
    </div>
  );
};

SpeedViolationLocationReport.propTypes = {
  speedViolation: customPropTypes.speedViolation.isRequired,
};
