import React, { useMemo } from 'react';
import { sortBy, uniqBy } from 'lodash';

import {
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  SelectFilterInput,
} from '../../../commons/ReportsCommon';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const FiltersPanel = ({ rows }) => {
  const getOptions = useMemo(
    () => ({
      driverNames: () =>
        sortBy(
          uniqBy(
            rows.map(({ driver, driverName }) => ({ label: driverName, value: driver })),
            'value'
          ),
          'label'
        ),
      vehicleNames: () =>
        sortBy(
          uniqBy(
            rows.map(({ asset, assetId }) => ({ label: assetId, value: asset })),
            'value'
          ),
          'label'
        ),
      geozoneNames: () =>
        sortBy(
          uniqBy(
            rows.map(({ geozoneKey, geozoneName }) => ({ label: geozoneName, value: geozoneKey })),
            'value'
          ),
          'label'
        ),
    }),
    [rows]
  );

  return (
    <SectionPanel
      name="filtersPanel"
      title="Violation Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="driverNames"
        field="driver"
        displayValue="driverName"
        group="filters"
        filterGroup="filters"
        label="Drivers"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverNames}
      />
      <SelectFilterInput
        name="vehicleNames"
        field="asset"
        displayValue="assetId"
        group="filters"
        filterGroup="filters"
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
      />
      <SelectFilterInput
        name="geozoneNames"
        field="geozoneKey"
        displayValue="geozoneName"
        group="filters"
        filterGroup="filters"
        label="Geozone"
        summaryPrefix="Geozone: "
        itemPlural="geo zones"
        autoGridArea
        getOptions={getOptions.geozoneNames}
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: customPropTypes.speedViolations.isRequired,
};
