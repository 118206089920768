// EXTERNAL LIB IMPORTS
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, includes, filter, isEmpty, uniq, sortBy } from 'lodash';
import moment from 'moment-timezone';
import {
  BasicDialog,
  Button,
  DateInput,
  Form,
  NumberInput,
  ProgressOverlay,
  SelectInput,
  TextInput,
  ToggleInput,
  useBooleanState,
} from 'stti-react-common';

// COMMON LIB IMPORTS
import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';

// HELPERS LIB IMPORTS
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import {
  getStatesByCountry,
  objectsToSelectOptions,
} from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';
import { validateVin } from '../../../../../helpers/form';

// MISC IMPORTS
import { FEATURES } from '../../../../../features';

import './detail.scss';

export const VehicleDetail = ({
  assetId,
  cameraDevices,
  countriesList,
  createVehicle,
  deleteVehicle,
  fetchCameraDevices,
  fetchCountries,
  fetchTenantVehicleConfig,
  fetchVehicle,
  fetchVehicleRemovePairing,
  fetchVehicles,
  fetchVehicleTypes,
  isSuperAdmin,
  mode: initialMode = 'view',
  openCameraDetail,
  ous,
  route,
  tenant,
  tenantOu,
  updateVehicle,
  user,
  vehicle,
  vehicleTypes,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  const [isRemovePairingDialogOpen, openRemovePairingDialog, closeRemovePairingDialog] =
    useBooleanState();

  useEffect(() => {
    fetchCountries();
    fetchVehicleTypes();
    fetchCameraDevices({ ouKey: undefined });
  }, []);

  useEffect(() => {
    if (tenantOu && tenantOu.key) {
      fetchTenantVehicleConfig(tenantOu.key);
    }
  }, [tenantOu]);

  const cameraAllowed = useMemo(() => {
    const { enabledVideoProviders } = tenant;
    if (enabledVideoProviders && initialMode !== 'create') {
      return vehicle && vehicle.key && vehicle.cameraProvider && !FEATURES.admin.hideCameraMethods;
    }
    return false;
  }, [vehicle, tenant]);

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      Promise.all([fetchVehicles(), fetchVehicle(assetId)])
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, assetId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: vehicle
      ? {
          ...vehicle,
          managedByKey: vehicle.managedBy && vehicle.managedBy.key,
          assetTypeKey: vehicle.assetType && vehicle.assetType.key,
          ctiTpmsMake: vehicle.ctiMetadata && vehicle.ctiMetadata.make,
          ctiTpmsModel: vehicle.ctiMetadata && vehicle.ctiMetadata.model,
          ctiTpmsFirmwareVersion: vehicle.ctiMetadata && vehicle.ctiMetadata.firmwareVersion,
          ctiTpmsSoftwareVersion: vehicle.ctiMetadata && vehicle.ctiMetadata.softwareVersion,
        }
      : {},
  });

  const { controls: formControls, resetControls } = form;

  const combineFormData = (formData) => {
    if (isEmpty(formData)) {
      return formData;
    }
    const {
      active,
      inactiveDate,
      managedByKey,
      assetTypeKey,
      sharedWithKeys,
      ctiTpmsMake,
      ctiTpmsModel,
      ctiTpmsFirmwareVersion,
      ctiTpmsSoftwareVersion,
      ctiMetadata,
      managedBy,
      assetType,
      sharedWith,
      engineYearBefore2000,
      fuelType,
      ...rest
    } = formData;

    const payload = {
      ...rest,
      active,
      inactiveDate: active ? null : moment(inactiveDate).toISOString(),
      assetType: vehicleTypes.find((item) => item.key === assetTypeKey),
      managedBy: ous.find((item) => item.key === managedByKey),
      sharedWith: filter(ous, (v) => includes(sharedWithKeys, v.key)),
      externalId: (vehicle && vehicle.externalId) || '',
      engineYearBefore2000: isTractorForm ? engineYearBefore2000 : false,
      fuelType: isTrailer(assetTypeKey) ? null : fuelType,
    };

    if (tenant.enableCti) {
      payload.ctiMetadata = {
        ...ctiMetadata,
        make: ctiTpmsMake,
        model: ctiTpmsModel,
        firmwareVersion: ctiTpmsFirmwareVersion,
        softwareVersion: ctiTpmsSoftwareVersion,
      };
    }
    return payload;
  };

  const isTrailer = (value) => {
    const vehicleType = vehicleTypes.find((item) => item.key === value);
    return vehicleType && vehicleType.typeClass.toLowerCase() === 'trailer';
  };

  const getInactiveDate = (value, isActive) => {
    if (!isActive) {
      return value !== null ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    }
    return null;
  };

  const vehicleTypesOptions = useMemo(() => {
    if (!user || !user.memberships) return [];
    if (isSuperAdmin) return sortBy(vehicleTypes, ['name']);

    const topLevelOu = ous.find(({ scope }) => scope === 'Tenant') || { key: null };
    const userMemberships = user.memberships.map(({ ou }) => ou);
    const ouFilters = uniq([topLevelOu.key].concat(userMemberships));

    return sortBy(
      vehicleTypes.filter(
        ({ isSystem, ou }) => isSystem === true && (ou === null || includes(ouFilters, ou.key))
      ),
      ['name']
    );
  }, [vehicleTypes, user]);

  const isTractor = useMemo(() => {
    if (!vehicle || !vehicle.assetType || !vehicle.assetType.typeClass) return false;
    return vehicle.assetType.typeClass.toLowerCase() === 'vehicle';
  }, [vehicle]);

  const isTractorForm = useMemo(() => {
    if (!formControls || !formControls.assetTypeKey) return false;

    const filteredAsset = vehicleTypesOptions.find(({ key }) => key === formControls.assetTypeKey);
    if (!filteredAsset) return false;

    return filteredAsset.typeClass === 'Vehicle';
  }, [formControls.assetTypeKey]);

  const disableShareVehicle = useMemo(() => {
    const { assetTypeKey } = formControls;

    if (!tenant) return !isTrailer(assetTypeKey);

    return !isTrailer(assetTypeKey) && tenant.filterVehiclesByOu;
  }, [tenant, formControls]);

  const renderDynamicElement = (cameraId) => {
    if (!cameraId) return undefined;

    return (
      <>
        <p>
          This Vehicle{' '}
          <span className="DeleteAdminItemDialog__display--item">{vehicle.assetId}</span> is
          currently associated to Camera(s):
        </p>
        <ul>
          <li>{cameraDevices.find(({ id }) => id === vehicle?.cameraId)?.assetTag}</li>
        </ul>
        <p>Proceeding will remove related Camera association(s).</p>
      </>
    );
  };

  return (
    <div className="VehicleDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <>
          <CrudAdminItem
            createItem={createVehicle}
            data={combineFormData(formControls)}
            deleteItem={deleteVehicle}
            form={form}
            hasError={form.hasErrors}
            isDirty={form.isDirty}
            itemTitle={formControls.assetId || ''}
            mode={mode}
            onApiError={setApiError}
            onClose={route.close}
            resetControls={resetControls}
            setMode={setMode}
            title="Vehicle"
            updateItem={updateVehicle}
            dynamicDeletePrompt={renderDynamicElement(vehicle?.cameraId)}
          >
            <ErrorSummary errorMsg={apiError} />
            <div className="CrudAdminItem__controls">
              <Control
                Component={TextInput}
                name="assetId"
                label="Vehicle ID"
                error={({ value }) => {
                  if (!value || !value.trim()) {
                    return true;
                  }
                  if (value.length > 10) {
                    return 'Vehicle ID cannot be longer than 10 characters';
                  }
                  /*
                  if (
                    mode === 'edit' &&
                    vehicles.find(({ assetId: assetName }) => assetName === value) &&
                    value !== vehicle.assetId
                  ) {
                    return 'This Vehicle ID already exists';
                  }
                  */
                  return false;
                }}
              />
              {mode === 'view' && formControls.externalId && (
                <Control Component={TextInput} name="externalId" label="External ID" readOnly />
              )}
              <Control
                Component={SelectInput}
                name="assetTypeKey"
                label="Type"
                error={({ value }) => isEmpty(value)}
                options={objectsToSelectOptions(vehicleTypesOptions, 'name', 'key', false)}
                defaultValue={vehicle && vehicle.assetType ? vehicle.assetType.key : ''}
              />
              <div
                className={
                  !isEmpty(formControls.assetTypeKey) && !isTrailer(formControls.assetTypeKey)
                    ? 'VehicleAdminDetail__nestedControls three__inputs'
                    : 'VehicleAdminDetail__nestedControls'
                }
              >
                <Control Component={TextInput} name="make" label="Vehicle Make" />
                <Control Component={TextInput} name="model" label="Vehicle Model" />
                {!isEmpty(formControls.assetTypeKey) && !isTrailer(formControls.assetTypeKey) && (
                  <Control
                    Component={SelectInput}
                    name="fuelType"
                    label="Vehicle Fuel Type"
                    options={['Gas', 'Diesel', 'LNG']}
                    error={({ value }) => {
                      if (!value || !value.trim()) {
                        return true;
                      }
                      return false;
                    }}
                  />
                )}
              </div>
              <div className="VehicleAdminDetail__nestedControls">
                <Control Component={TextInput} name="engineType" label="Engine Type" readOnly />
                {tenant.enableCumminsEngineDiagnostics && (
                  <Control
                    Component={ToggleInput}
                    name="enableCumminsEngineDiagnostics"
                    label="Cummins Engine Diagnostics"
                    readOnly={
                      (mode !== 'edit' || mode !== 'create') && formControls.engineType !== 'CMMNS'
                    }
                  />
                )}
              </div>
              <div className="VehicleAdminDetail__nestedControls three__inputs">
                <Control Component={NumberInput} name="year" label="Vehicle Year" min={0} />
                <div style={{ width: '100%' }}>
                  <Control
                    Component={TextInput}
                    name="vin"
                    inputProps={{
                      maxLength: 17,
                    }}
                    label="Vehicle Identification Number (VIN)"
                    error={({ value }) => {
                      if (isNil(value) || isEmpty(value)) return false;
                      if (/[^a-zA-Z0-9]/.test(value)) return 'VIN cannot have special characters';
                      if (value.length !== 17) return `You typed ${value.length}/17 characters.`;
                      if (!validateVin(value)) return 'Invalid VIN.';
                      return false;
                    }}
                    onSet={({ draft }) => {
                      if (draft.vin !== vehicle.vin) {
                        draft.vinAutoDetected = false; // we will set this to manually instead of auto-detected
                      }
                    }}
                  />
                  {vehicle && vehicle.vinAutoDetected && (
                    <p style={{ fontSize: 11, color: '#00000061' }}>This VIN is auto-detected</p>
                  )}
                </div>
                <Control Component={TextInput} name="plateNumber" label="License Plate Number" />
              </div>
              {isTractorForm && (
                <Control
                  Component={SelectInput}
                  name="engineYearBefore2000"
                  label="ELD Exempt (Year < 2000 or Weight < 4500kgs or Bus for < 11)?"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              )}
              <Control
                Component={SelectInput}
                name="managedByKey"
                label="Managed by"
                error={({ value }) => isEmpty(value)}
                options={objectsToSelectOptions(ous, 'name', 'key', false)}
                defaultValue={vehicle && vehicle.managedBy ? vehicle.managedBy.key : ''}
              />
              <Control
                Component={SelectInput}
                name="unitSystem"
                label="Units"
                error={({ value }) => isEmpty(value)}
                options={[
                  { label: 'Metric', value: 'Metric' },
                  { label: 'Imperial', value: 'Imperial' },
                ]}
                defaultValue={tenantOu && tenantOu.unitSystem ? tenantOu.unitSystem : ''}
              />
              <div className="VehicleAdminDetail__nestedControls">
                <Control
                  Component={SelectInput}
                  name="jurisdictionCountry"
                  label="Country"
                  options={objectsToSelectOptions(countriesList, 'name', 'code')}
                  error={({ value }) =>
                    formControls.plateNumber && formControls.plateNumber.trim() && !value
                  }
                  onSet={({ draft }) => {
                    if (draft.jurisdictionCountry && draft.jurisdictionState) {
                      draft.jurisdictionState = null;
                    }
                  }}
                />
                <Control
                  Component={SelectInput}
                  name="jurisdictionState"
                  label="Jurisdiction"
                  error={({ value }) =>
                    formControls.plateNumber &&
                    formControls.plateNumber.trim() &&
                    !isNil(formControls.jurisdictionCountry) &&
                    !value
                  }
                  options={objectsToSelectOptions(
                    getStatesByCountry(countriesList, formControls.jurisdictionCountry),
                    'name',
                    'code',
                    false
                  )}
                  disabled={!formControls.jurisdictionCountry}
                />
              </div>
              <div className="VehicleAdminDetail__nestedControls">
                <Control
                  Component={SelectInput}
                  name="active"
                  label="Active?"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
                <div hidden={formControls.active}>
                  <Control
                    Component={DateInput}
                    name="inactiveDate"
                    label="De-activation Date"
                    error={({ value }) => !formControls.active && isNil(value)}
                    defaultValue={
                      vehicle && getInactiveDate(vehicle.inactiveDate, formControls.active)
                    }
                  />
                </div>
                <Control
                  Component={TextInput}
                  name="gatewayDeviceId"
                  label="Gateway Device ID"
                  readOnly
                />
              </div>
              {tenant.enableCti && mode !== 'create' && (
                <>
                  <div className="VehicleAdminDetail__nestedControls">
                    <Control
                      Component={TextInput}
                      name="ctiTpmsMake"
                      label="CTI/TPMS Make"
                      readOnly
                    />
                    <Control
                      Component={TextInput}
                      name="ctiTpmsModel"
                      label="CTI/TPMS Model"
                      readOnly
                    />
                  </div>
                  <div className="VehicleAdminDetail__nestedControls">
                    <Control
                      Component={TextInput}
                      name="ctiTpmsFirmwareVersion"
                      label="CTI/TPMS Firmware Version"
                      readOnly
                    />
                    <Control
                      Component={TextInput}
                      name="ctiTpmsSoftwareVersion"
                      label="CTI/TPMS Software Version"
                      readOnly
                    />
                  </div>
                </>
              )}
            </div>
            <div className="VehicleAdminDetail__nestedControls">
              <Control
                Component={SelectInput}
                name="sharedWithKeys"
                label="Shared With"
                error={({ value }) => isNil(value)}
                multiple
                options={objectsToSelectOptions(ous, 'name', 'key', false)}
                defaultValue={
                  vehicle && vehicle.sharedWith ? vehicle.sharedWith.map((item) => item.key) : []
                }
                disabled={disableShareVehicle}
              />
            </div>
            <div className="VehicleAdminDetail__nestedControls">
              <Control Component={TextInput} multiline name="notes" label="Notes" />
            </div>
          </CrudAdminItem>

          <div className="BottomContainer">
            {cameraAllowed && (
              <Button
                icon="videocam"
                variant="contained"
                label="Camera Details"
                onClick={() => openCameraDetail({ cameraId: vehicle.cameraId, mode: 'view' })}
              />
            )}
            {isSuperAdmin && mode !== 'create' && isTractor && (
              <Button
                icon="cancelPresentation"
                variant="contained"
                label="Remove Vehicle Pairing"
                onClick={() => openRemovePairingDialog()}
              />
            )}
          </div>

          <BasicDialog
            title={`Are you sure that you want to remove pairing from vehicle ${
              (vehicle && vehicle.assetId) || ''
            } ?`}
            className="VehicleAdminDetail__RemovePairingDialog"
            isOpen={isRemovePairingDialogOpen}
            onClose={closeRemovePairingDialog}
          >
            <p>Attention: This action cannot be undone.</p>
            <Button
              icon="cancelPresentation"
              variant="contained"
              label="Remove Vehicle Pairing"
              onClick={() => {
                fetchVehicleRemovePairing(vehicle.key);
                closeRemovePairingDialog();
              }}
            />
          </BasicDialog>
        </>
      )}
    </div>
  );
};

VehicleDetail.propTypes = {
  assetId: PropTypes.string,
  cameraDevices: PropTypes.array,
  countriesList: PropTypes.array,
  createVehicle: PropTypes.func.isRequired,
  deleteVehicle: PropTypes.func.isRequired,
  fetchCameraDevices: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchTenantVehicleConfig: PropTypes.func.isRequired,
  fetchVehicle: PropTypes.func.isRequired,
  fetchVehicleRemovePairing: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  openCameraDetail: PropTypes.func,
  ous: customPropTypes.organizations.isRequired,
  route: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
  tenantOu: customPropTypes.tenant,
  updateVehicle: PropTypes.func.isRequired,
  user: customPropTypes.user,
  vehicle: customPropTypes.vehicle,
  vehicleTypes: customPropTypes.vehicleTypes,
};
