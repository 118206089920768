/**
 * selectFleetTrackedData(state) -> [trackedData]
 *
 * Selector for all tracked data
 */
export const selectFleetTrackedData = (state) => state.tracking.data;

/**
 * selectFleetTrackingBookmark(state) -> {bookmark}
 *
 * Selector for current bookmark
 */
export const selectFleetTrackingBookmarks = (state) => state.tracking.bookmarks;

/**
 * selectFleetTrackingShiftDetail(state) -> [currentShiftDetails]
 *
 * Selector for current shift details
 */
export const selectFleetTrackingShiftDetail = (state) => state.tracking.currentShiftDetail.data;

/**
 * selectFleetTrackingShiftDetail(state) -> [currentShiftDetails]
 *
 * Selector for current shift details
 */
export const selectFleetTrackingShiftDetailFetches = (state) =>
  state.tracking.currentShiftDetail.fetches;
