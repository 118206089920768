import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useReport } from './useReport';

export const ReportFilter = ({ name, filterConfig, reRegisterOn = [] }) => {
  const { registerFilter } = useReport();

  useEffect(() => registerFilter({ controlName: name, ...filterConfig }), reRegisterOn);

  return null;
};
ReportFilter.propTypes = {
  name: PropTypes.string,
  filterConfig: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    group: PropTypes.string.isRequired,
  }).isRequired,
  reRegisterOn: PropTypes.array,
};
