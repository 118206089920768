/**
 * selectCameraDevices(state) -> [cameras]
 *
 * Selector for all camera devices
 */
export const selectCameraDevices = (state) => state.administration.devices.cameras.devices.data;

/**
 * selectCameraDevice(state, cameraId) -> {camera}
 *
 * Selector for a specific camera device by Id
 */
export const selectCameraDevice = (state, cameraId) =>
  state.administration.devices.cameras.devices.data.find(({ id }) => id === cameraId);

/**
 * selectCameraRecords(state) -> [cameraRecords]
 *
 * Selector for all cameraRecords
 */
export const selectCameraRecords = (state) => state.administration.devices.cameras.records;

/**
 * selectCameraVirtualEvents(state) -> [cameraVirtualEvents]
 *
 * Selector for all cameraVirtualEvents
 */
export const selectCameraVirtualEvents = (state) => state.administration.devices.cameras.events;

/**
 * selectCameraVirtualEventsMedia(state) -> {cameraVirtualEventsMedia}
 *
 * Selector for all cameraVirtualEventsMedia
 */
export const selectCameraVirtualEventsMedia = (state) =>
  state.administration.devices.cameras.mediaEvent;

/**
 * selectCameraTrips(state) -> [cameraTrips]
 *
 * Selector for all cameraTrips
 */
export const selectCameraTrips = (state) => state.administration.devices.cameras.trips;

/**
 * selectCameraBreadcrumbs(state) -> [cameraBreadcrumbs]
 *
 * Selector for all cameraBreadcrumbs
 */
export const selectCameraBreadcrumbs = (state) =>
  state.administration.devices.cameras.breadcrumbs.data;

/**
 * selectCameraDevicesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on camera devices
 */
export const selectCameraDevicesFetchRequests = (state) =>
  state.administration.devices.cameras.devices.fetches;

/**
 * selectCameraBreadcrumbsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on cameraBreadcrumbs
 */
export const selectCameraBreadcrumbsFetchRequests = (state) =>
  state.administration.devices.cameras.breadcrumbs.fetches;
