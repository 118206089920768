import { connect } from 'react-redux';

import { camera } from '../../../../../data/administration/devices';

import {
  selectSystemTenant,
  selectSystemUser,
  selectSystemOus,
} from '../../../../../data/system/systemSelectors';
import { vehicle } from '../../../../../data/administration/resources';

import { CameraDetail } from './detail';

export const CameraDetailContainer = connect(
  (state, { cameraId }) => ({
    systemTenant: selectSystemTenant(state),
    systemUser: selectSystemUser(state),
    cameraDevice: camera.selectors.selectCameraDevice(state, cameraId),
    vehicles: vehicle.selectors.selectAllVehicles(state),
    organizations: selectSystemOus(state),
  }),
  {
    fetchCameraDevice: camera.actions.fetchCameraDevice,
    putCameraDevice: camera.actions.putCameraDevice,
    postCameraDeviceReboot: camera.actions.postCameraDeviceReboot,
    fetchCameraWakeUp: camera.actions.fetchCameraWakeUp,
    fetchVehicles: vehicle.actions.fetchVehicles,
  }
)(CameraDetail);
