import { connect } from 'react-redux';

import { sortBy, filter } from 'lodash';

import { Messenger } from './messenger';
import { messenger } from '../../../data/messenger';
import { user } from '../../../data/administration/resources';
import { selectSystemActiveOu } from '../../../data/system';
import { convertToParticipants } from '../../../data/messenger/helpers';
import { store } from '../../../data/store';

export const MessengerContainer = connect(
  (state) => ({
    messages: messenger.selectors.selectMessages(state),
    users: sortBy(
      filter(user.selectors.selectUsers(state), { isDriver: true, _deleted: false }),
      (row) => row.fullName.toLowerCase()
    ),
    participants: convertToParticipants(
      user.selectors.selectUsers(state),
      selectSystemActiveOu(state),
      messenger.selectors.selectMessages(state)
    ),
    oldestDate: messenger.selectors.selectOldestDate(state),
  }),
  {
    fetchMessages: messenger.actions.fetchMessages,
    fetchUsers: user.actions.fetchUsersOptimized,
    postMessageEvent: messenger.actions.postMessageEvent,
    postMessage: messenger.actions.postMessage,
    dispatchOldestDate: (date) =>
      store.dispatch({ type: messenger.reducer.ACTION_TYPE.setOldestDate, payload: date }),
  }
)(Messenger);
