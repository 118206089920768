import React from 'react';
import PropTypes from 'prop-types';

export const StopIcon = ({ color }) => (
  <div className="Map__StopIcon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <polygon
        points="20 5.86 20 14.14 14.14 20 5.86 20 0 14.14 0 5.86 5.86 0 14.14 0 20 5.86"
        fill={color}
      />
    </svg>
  </div>
);

StopIcon.propTypes = {
  color: PropTypes.string,
};

StopIcon.defaultProps = {
  color: 'black',
};
