import PropTypes from 'prop-types';
import { isNil } from 'lodash';

const EMPTY_ARRAY = [];

export const collapseGroupedRows = ({ rows, mode, toggledIds }) => {
  // requires rows to have properties id, parentId, isParent
  // adds a property isExpanded to parents
  if (isNil(mode) || isNil(toggledIds)) return EMPTY_ARRAY;

  const filteredRows = [];

  rows.forEach((row, index) => {
    const { id, parentId, isParent } = row;
    const isToggled = toggledIds.includes(parentId || id);
    const isExpanded = mode === 'collapsed' ? isToggled : !isToggled;

    if (row.parentId) {
      // child row
      if (isExpanded) filteredRows.push(row);
    } else if (isParent && isExpanded) {
      // expanded parent row
      filteredRows.push({ ...row, isExpanded: true });
    } else {
      // solo row or collapsed parent row
      filteredRows.push(row);
    }
  });

  return filteredRows;
};
collapseGroupedRows.propTypes = {
  rows: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(['collapsed', 'expanded']).isRequired,
  toggled: PropTypes.arrayOf(PropTypes.string),
};

export const markRows = ({ rows, value, rowIdProperty = 'id', property }) => {
  if (isNil(value) || value.length === 0) return rows;

  return rows.map((row) => {
    if (value.includes(row[rowIdProperty])) return { ...row, [property]: true };
    return row;
  });
};
markRows.propTypes = {
  rows: PropTypes.array.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  property: PropTypes.string.isRequired,
};
