import { columnDef } from '../../../../commons/ControlledDataGrid';
import { getDateDuration, getSurfSightDuration } from './helpers';

export const columnDefs = [
  columnDef.textColumn({
    field: 'date',
    headerName: 'Start Time',
    valueFormatter: ({ data }) => getDateDuration(data),
    width: 110,
  }),
  columnDef.textColumn({
    field: 'vehicle.name',
    headerName: 'Vehicle',
    width: 120,
  }),
  columnDef.textColumn({
    field: 'driver.name',
    headerName: 'Driver',
    width: 120,
  }),
  columnDef.textColumn({
    field: 'duration',
    headerName: 'Duration',
    valueFormatter: ({ data }) => getSurfSightDuration(data),
    width: 100,
  }),
  columnDef.speedColumn({
    field: 'speed',
    headerName: 'Starting Speed',
    width: 140,
  }),
  columnDef.textColumn({
    field: 'files',
    headerName: 'Camera',
    cellRenderer: 'CameraView',
    width: 120,
  }),
  columnDef.textColumn({
    colId: 'mediaType',
    field: 'files',
    headerName: 'Media Type',
    cellRenderer: 'MediaType',
    width: 120,
  }),
  columnDef.textColumn({
    field: 'metadata',
    headerName: 'Metadata',
    width: 370,
  }),
  columnDef.numberColumn({
    field: 'expiresInDays',
    headerName: 'Available Days',
    valueFormatter: ({ value }) => Math.ceil(value),
    width: 130,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
