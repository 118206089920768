import React from 'react';
import PropTypes from 'prop-types';

import { OutlierFiltersDialogInput } from '../OutlierFiltersDialogInput';
import { OutlierFilterInput } from '../OutlierFilterInput';
import {
  excludeLegsFromCyclesByUnitRange,
  excludeLegsFromCyclesByRelativeRange,
} from '../../../../helpers/reports/cycleFilters';

export const LegFilters = ({ baseClassName }) => (
  <OutlierFiltersDialogInput
    className={`${baseClassName}__LegFiltersDialogInput`}
    dialogClassName={`${baseClassName}__legFiltersDialog`}
    style={{ gridArea: 'legFilters' }}
    label="Leg Filters"
    group="cycleOutlier"
  >
    <h4 style={{ gridArea: 'hideLegsRelativeDurationHeading' }}>Exclude any leg with duration</h4>
    <OutlierFilterInput
      name="hideLegsRelativeDurationLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Duration < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByRelativeRange,
        unitType: 'relative',
        legType: 'any',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideLegsRelativeDurationLessThanX,
        }),
      }}
      paramDefaultValue={80}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideLegsRelativeDurationGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Duration > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByRelativeRange,
        unitType: 'relative',
        legType: 'any',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideLegsRelativeDurationGreaterThanX,
        }),
      }}
      paramDefaultValue={120}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideEmptyLegDurationHeading' }}>Exclude empty leg with duration</h4>
    <OutlierFilterInput
      name="hideEmptyLegDurationLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Empty Leg Duration < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'empty',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideEmptyLegDurationLessThanX,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideEmptyLegDurationGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Empty Leg Duration > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'empty',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideEmptyLegDurationGreaterThanX,
        }),
      }}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideLoadingLegDurationHeading' }}>Exclude loading leg with duration</h4>
    <OutlierFilterInput
      name="hideLoadingLegDurationLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loading Leg Duration < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'loading',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideLoadingLegDurationLessThanX,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideLoadingLegDurationGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loading Leg Duration > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'loading',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideLoadingLegDurationGreaterThanX,
        }),
      }}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideLoadedLegDurationHeading' }}>Exclude loaded leg with duration</h4>
    <OutlierFilterInput
      name="hideLoadedLegDurationLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loaded Leg Duration < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'loaded',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideLoadedLegDurationLessThanX,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideLoadedLegDurationGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loaded Leg Duration > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'loaded',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideLoadedLegDurationGreaterThanX,
        }),
      }}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideUnloadingLegDurationHeading' }}>
      Exclude unloading leg with duration
    </h4>
    <OutlierFilterInput
      name="hideUnloadingLegDurationLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Unloading Leg Duration < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'unloading',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideUnloadingLegDurationLessThanX,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideUnloadingLegDurationGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Unloading Leg Duration > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'duration',
        unit: 'h',
        legType: 'unloading',
        legField: 'duration',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideUnloadingLegDurationGreaterThanX,
        }),
      }}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideLegsRelativeDistanceHeading' }}>Exclude any leg with distance</h4>
    <OutlierFilterInput
      name="hideLegsRelativeDistanceLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Distance < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByRelativeRange,
        unitType: 'relative',
        legType: 'any',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          min: controls.hideLegsRelativeDistanceLessThanX,
        }),
      }}
      paramDefaultValue={80}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideLegsRelativeDistanceGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Distance > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByRelativeRange,
        unitType: 'relative',
        legType: 'any',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls }) => ({
          max: controls.hideLegsRelativeDistanceGreaterThanX,
        }),
      }}
      paramDefaultValue={120}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideEmptyLegDistanceHeading' }}>Exclude empty leg with distance</h4>
    <OutlierFilterInput
      name="hideEmptyLegDistanceLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Empty Leg Distance < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'distance',
        legType: 'empty',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          min: controls.hideEmptyLegDistanceLessThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideEmptyLegDistanceGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Empty Leg Distance > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'distance',
        legType: 'empty',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          max: controls.hideEmptyLegDistanceGreaterThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
    <h4 style={{ gridArea: 'hideLoadedLegDistanceHeading' }}>Exclude loaded leg with distance</h4>
    <OutlierFilterInput
      name="hideLoadedLegDistanceLessThan"
      label="less than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loaded Leg Distance < "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'distance',
        legType: 'loaded',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          min: controls.hideLoadedLegDistanceLessThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
    <OutlierFilterInput
      name="hideLoadedLegDistanceGreaterThan"
      label="greater than"
      group={['cycle', 'cycleOutlier']}
      summaryPrefix="Exclude Legs: Loaded Leg Distance > "
      filterConfig={{
        group: 'leg',
        type: excludeLegsFromCyclesByUnitRange,
        unitType: 'distance',
        legType: 'loaded',
        legField: 'distance',
        disabled: ({ value }) => !value,
        getValues: ({ controls, formats }) => ({
          max: controls.hideLoadedLegDurationGreaterThanX,
          unit: formats.distance,
        }),
      }}
      autoGridArea
    />
  </OutlierFiltersDialogInput>
);

LegFilters.propTypes = {
  baseClassName: PropTypes.string.isRequired,
};
