import React, { useEffect, useState } from 'react';
import { GoogleMap } from 'stti-react-common';

import { ReportProvider, useReportController } from '../../../commons/ReportsCommon';
import { NaviMap } from '../../../commons/NaviMap';
import { symbols } from '../../../commons/MapsCommon';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './FuelTaxMileageLocationReport.scss';

const { Marker } = GoogleMap;

export const FuelTaxMileageLocationReport = ({ fuelTaxMileage }) => {
  const report = useReportController();
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    if (fuelTaxMileage && fuelTaxMileage.loadLocation && fuelTaxMileage.unloadLocation) {
      setFocus([fuelTaxMileage.loadLocation, fuelTaxMileage.unloadLocation]);
    }
  }, [fuelTaxMileage]);

  return (
    <div className="FuelTaxMileageLocationReport">
      <ReportProvider value={report}>
        <NaviMap focus={focus}>
          {fuelTaxMileage && fuelTaxMileage.loadLocation && (
            <Marker position={fuelTaxMileage.loadLocation} icon={symbols.go} />
          )}
          {fuelTaxMileage && fuelTaxMileage.unloadLocation && (
            <Marker position={fuelTaxMileage.unloadLocation} icon={symbols.stop} />
          )}
        </NaviMap>
      </ReportProvider>
    </div>
  );
};

FuelTaxMileageLocationReport.propTypes = {
  fuelTaxMileage: customPropTypes.fuelTaxMileage.isRequired,
};
