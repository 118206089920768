import moment from 'moment-timezone';
import { columnDef } from '../../../../../commons/ControlledDataGrid';

import { timeInMillisecondsToDecimal } from '../../../../../../helpers/moment';
import { metersToKilometersMilesConverter } from '../../../../../../helpers/formats';

const eventTypeToEventTitle = (compositeString, eventCode) => {
  switch (compositeString) {
    case 'SESSION_START (START)':
      return 'Login';
    case 'SESSION_END (END)':
      return 'Logout';
    case 'DIAGNOSTIC_START (START)':
      if (isNaN(+eventCode)) {
        return 'Malfunction (detected)';
      }
      return 'Data Diagnostic (detected)';

    case 'DIAGNOSTIC_END (END)':
      if (isNaN(+eventCode)) {
        return 'Malfunction (cleared)';
      }
      return 'Data Diagnostic (cleared)';

    default:
      return compositeString;
  }
};

export const malfunctionAndDiagnosticEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date & Time',
    valueGetter: ({ data: { date, startOfDayRecord } }) => {
      if (startOfDayRecord) return moment(date).format('YYYY-MM-DD');
      return moment(date).format('HH:mm:ss');
    },
    width: 70,
  }),
  columnDef.textColumn({
    field: 'eventType',
    headerName: 'Event',
    valueGetter: ({ data: { eventType, orientation, startOfDayRecord, code } }) => {
      if (startOfDayRecord || !eventType) return ' ';
      if (eventType === 'SIGNATURE') {
        return 'Certification of RODS';
      }
      if (!orientation) return '—';
      return eventTypeToEventTitle(`${eventType} (${orientation})`, code);
    },
  }),
  columnDef.textColumn({
    field: 'code',
    headerName: 'Additional Info',
    valueGetter: ({
      data: { eventType, date, timeZone, code, eventCodeDefinition, startOfDayRecord },
    }) => {
      if (eventType === 'SIGNATURE') {
        return `Time Zone: EST (UTC ${moment.tz(date, timeZone).format('Z')})`;
      }
      if (startOfDayRecord) return ' ';
      if (!code || !eventCodeDefinition) return '—';
      return `Code ${code} (${eventCodeDefinition})`;
    },
  }),
  columnDef.textColumn({
    field: 'asset',
    headerName: 'CMV',
    valueFormatter: ({ data: { asset, startOfDayRecord } }) => {
      if (startOfDayRecord || !asset) return '';
      return asset;
    },
  }),
  columnDef.textColumn({
    field: 'totalVehicleDistance',
    headerName: 'Distance (Total)',
    valueFormatter: ({ data: { totalVehicleDistance, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return metersToKilometersMilesConverter(totalVehicleDistance);
    },
  }),
  columnDef.durationColumn({
    field: 'totalEngineHours',
    headerName: 'Hours (Total)',
    valueFormatter: ({ data: { totalEngineHours, startOfDayRecord } }) => {
      if (startOfDayRecord || !totalEngineHours) return '';
      // totalEngineHours is given by BE in seconds, so we need to convert it to milliseconds
      return timeInMillisecondsToDecimal(totalEngineHours * 1000);
    },
  }),
  columnDef.textColumn({
    field: 'eldSequence',
    headerName: 'Seq. ID',
    valueFormatter: ({ data: { eldSequence, startOfDayRecord } }) => {
      if (startOfDayRecord) return '';
      return `0000${Number(eldSequence).toString(16)}`.slice(-4).toUpperCase();
    },
  }),
];
