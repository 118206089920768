import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

export const AppConfig = ({
  accessToken,
  fetchCurrentOrganizationUnits,
  fetchCurrentPermissions,
  fetchCurrentTenant,
  fetchCurrentUser,
  fetchSearchLimits,
  setSystem,
}) => {
  useEffect(() => {
    if (accessToken) {
      Promise.all([
        fetchCurrentTenant(),
        fetchCurrentOrganizationUnits(),
        fetchCurrentUser(),
        fetchCurrentPermissions(),
      ]).then((results) => {
        const [tenant, ous, user] = results;

        if (user && user.memberships && ous && ous.length > 0) {
          const [first] = user.memberships;
          const activeOu = ous.find((ou) => ou.key === first.ou);
          if (!isEmpty(activeOu) && ous.length > 0 && !isEmpty(user) && !isEmpty(tenant)) {
            fetchSearchLimits();
            setSystem({ activeOu, ous, user, tenant });
          }
        }
      });
    }
  }, [accessToken]);

  return null;
};

AppConfig.propTypes = {
  accessToken: PropTypes.string,
  fetchCurrentOrganizationUnits: PropTypes.func,
  fetchCurrentPermissions: PropTypes.func,
  fetchCurrentTenant: PropTypes.func,
  fetchCurrentUser: PropTypes.func,
  fetchSearchLimits: PropTypes.func,
  setSystem: PropTypes.func,
};
