import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchActivities: 'administration/configurations/activities/fetchActivities',
};

export const activitiesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchActivities)) {
      draft.data = payload;
    }
    if (actionTypeRequest(type, ACTION_TYPE.fetchActivities)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchActivities)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
