import React from 'react';
import PropTypes from 'prop-types';
import { Chip, SelectInput } from 'stti-react-common';

import { ReportControl } from '../../../commons/ReportsCommon';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';

const selectOptions = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 'incomplete',
    label: 'In-Progress Only',
  },
  {
    value: 'complete',
    label: 'Complete Only',
  },
];

export const CompletionFilter = ({ group, filterGroup }) => (
  <ReportControl
    name="completion"
    group={group}
    label="Completion"
    canDeleteSummary
    render={(renderProps) => <SelectInput {...renderProps} options={selectOptions} />}
    renderSummary={(renderProps, { value }) => {
      if (!value) return null;
      return <Chip {...renderProps} label={SelectInput.labelForValue(selectOptions, value)} />;
    }}
    printConfig={({ value }) => value && SelectInput.labelForValue(selectOptions, value)}
    filterConfig={{
      group: filterGroup,
      type: filterRowsByPredicate,
      test: ({ row, value }) => (value === 'complete' ? row.endedAt : !row.endedAt),
      disabled: ({ value }) => !value,
    }}
  />
);

CompletionFilter.propTypes = {
  group: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
};
