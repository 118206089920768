import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { isNil, find, map, cloneDeep, uniqBy } from 'lodash';
import {
  Button,
  Clickable,
  Form,
  SelectInput,
  TextInput,
  TabularInput,
  ProgressOverlay,
  NumberInput,
  ToggleInput,
} from 'stti-react-common';

import { AdminClickableOrSelectInput, CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

const unitSystem = ['Metric', 'Imperial'];

const languages = [
  { label: 'Canadian English', value: 'en' },
  { label: 'French Canadian', value: 'fr_CA' },
];

const timezones = map(moment.tz.names(), (timezone) => ({
  label: timezone.replace(/_/g, ' '),
  value: timezone,
}));

export const OrganizationDetail = ({
  organization,
  organizations,
  organizationId,
  fetchRoles,
  roles,
  users,
  fetchOrganizations,
  fetchOrganization,
  fetchUsers,
  deleteOrganization,
  createOrganization,
  updateOrganization,
  openUserDetail,
  openOrganizationDetail,
  mode: initialMode = 'view',
  subOrganization,
  route,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const [showOnlyDeleted, setOnlyDeleted] = useState(false);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    if (initialMode !== 'create') {
      setLoading(true);
      fetchOrganizations().then(() => {
        fetchOrganization(organizationId)
          .then(resetControls)
          .finally(() => {
            setLoading(false);
          });
      });
    } else {
      setLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    fetchRoles();
    fetchUsers();
  }, []);

  const userList = useMemo(
    () => (showOnlyDeleted ? users.filter((user) => user.deleted === true) : users),
    [showOnlyDeleted, users]
  );

  const { useFormController, Control } = Form;

  const mapOrgMembers = (members) => {
    if (!members) return [];
    return map(members, (member) => ({
      role: map(member.role, (role) => role.key),
      user: member.user.key.toString(),
    }));
  };

  const form = useFormController({
    controls: organization
      ? {
          ...organization,
          members: mapOrgMembers(organization?.members),
        }
      : {
          members: [],
          parentKey: subOrganization ? subOrganization.key : null,
          carrierKey: (subOrganization && subOrganization.key) || null,
          principalKey: (subOrganization && subOrganization.key) || null,
        },
  });

  const { controls: formControls, resetControls } = form;

  const availableOus = useMemo(() => {
    if (organizations.length > 0) {
      const filteredOus = [];
      if (mode !== 'create') {
        filteredOus.push(organization);
      }

      const getOuBranch = (parent) => {
        filteredOus.push(parent);
        if (parent?.parentKey) {
          const parentOu = find(organizations, { key: parent.parentKey });
          if (parentOu) {
            getOuBranch(parentOu);
          }
        }
      };
      if (formControls.parentKey) {
        getOuBranch(find(organizations, { key: formControls.parentKey }));
      }

      const ous = cloneDeep(filteredOus.filter((item) => item));

      if (organization) {
        ous.push({ key: organization.carrierKey, name: organization.carrierName });
        ous.push({ key: organization.parentKey, name: organization.parentName });
        ous.push({ key: organization.principalKey, name: organization.principalName });
      }

      return uniqBy(ous, 'key');
    }

    return [];
  }, [formControls.parentKey, organizations, organization]);

  const ouList = useMemo(() => {
    const tempList = cloneDeep(organizations);
    if (organization) {
      tempList.push({ key: organization.carrierKey, name: organization.carrierName });
      tempList.push({ key: organization.parentKey, name: organization.parentName });
      tempList.push({ key: organization.principalKey, name: organization.principalName });
    }

    return uniqBy(tempList, 'key');
  }, [organizations, organization]);

  function setCss(item) {
    const userFound = users?.find((user) => user.key === item);

    if (!userFound) return '';

    if (showOnlyDeleted) {
      return userFound?.deleted ? 'deleted' : 'hide';
    }
    return userFound?.deleted ? 'deleted' : '';
  }

  return (
    <Feature.Wrapper className="OrganizationDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Organization"
          form={form}
          itemTitle={formControls.name || ''}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formControls}
          onClose={route.close}
          createItem={createOrganization}
          updateItem={organization && organization.scope ? updateOrganization : null}
          deleteItem={organization && organization.scope ? deleteOrganization : null}
          resetControls={resetControls}
          reloadPageAfterCreate
          reloadUrl="#/administration/organizations"
        >
          <Feature.Body>
            <ErrorSummary errorMsg={apiError} />
            <div className="CrudAdminItem__controls">
              <Control
                Component={TextInput}
                name="name"
                label="Name"
                error={({ value }) => !value || !value.trim()}
              />
              <Control
                Component={SelectInput}
                name="timeZone"
                label="Time Zone"
                options={timezones}
                error={({ value }) => isNil(value)}
              />
              <Control
                Component={SelectInput}
                name="unitSystem"
                label="Units"
                options={unitSystem}
              />
              <Control
                Component={TextInput}
                name="address"
                label="Address"
                error={({ value }) => {
                  if (!value || !value.trim()) {
                    return true;
                  }

                  if (value.length < 30 || value.length > 60) {
                    return 'The length of address must be between 30 to 60 characters.';
                  }
                  return false;
                }}
              />
              <Control Component={TextInput} name="description" label="Description" />
              <Control
                Component={AdminClickableOrSelectInput}
                name="parentKey"
                label="Parent Organization"
                readOnly={!formControls.parentKey && mode !== 'create'}
                onClickableClick={(id) =>
                  openOrganizationDetail({ organizationId: id, mode: 'view' })
                }
                options={objectsToSelectOptions(ouList, 'name', 'key')}
                error={({ value }) => mode === 'create' && !value}
                onSet={({ draft }) => {
                  if (
                    !find(availableOus, {
                      key: draft.carrierKey,
                    })
                  ) {
                    draft.carrierKey = null;
                  }
                  if (
                    !find(availableOus, {
                      key: draft.principalKey,
                    })
                  ) {
                    draft.principalKey = null;
                  }
                }}
              />
              <Control
                Component={AdminClickableOrSelectInput}
                name="principalKey"
                label="Principal Place of Business"
                readOnly={!formControls.parentKey && mode !== 'create'}
                onClickableClick={
                  organizationId !== formControls.principalKey
                    ? (id) => openOrganizationDetail({ organizationId: id, mode: 'view' })
                    : null
                }
                options={objectsToSelectOptions(availableOus, 'name', 'key')}
                disabled={({ controls }) => !controls.parentKey}
                error={({ value, controls }) => controls.parentKey && !value}
              />
              <Control
                Component={AdminClickableOrSelectInput}
                name="carrierKey"
                label="Carrier"
                readOnly={!formControls.parentKey && mode !== 'create'}
                onClickableClick={
                  organizationId !== formControls.carrierKey
                    ? (id) => openOrganizationDetail({ organizationId: id, mode: 'view' })
                    : null
                }
                options={objectsToSelectOptions(availableOus, 'name', 'key')}
                disabled={({ controls }) => !controls.parentKey}
                error={({ value, controls }) => controls.parentKey && !value}
              />
              <Control
                Component={NumberInput}
                name="usDotNumber"
                label="US Dot Number"
                error={({ value }) =>
                  value &&
                  value.toString().length > 9 &&
                  'The length of usDotNumber must be between 1 to 9 characters.'
                }
              />
              <Control Component={SelectInput} name="locale" label="Language" options={languages} />
              <div className="sub__organization__list">
                <h2>Sub-organizations</h2>
                {(mode !== 'create' &&
                  organization &&
                  organization.children &&
                  organization.children.length > 0 && (
                    <ul>
                      {organization.children.map((child) => (
                        <li key={child.key}>
                          <Clickable
                            onClick={() =>
                              openOrganizationDetail({ organizationId: child.key, mode: 'view' })
                            }
                          >
                            {child.name}
                          </Clickable>
                        </li>
                      ))}
                    </ul>
                  )) ||
                  '—'}
              </div>
            </div>
            <div className="TabularInput__wrapper">
              <Control
                Component={TabularInput}
                renderHeader={({ addRow }) => (
                  <div className="TabularInput__title">
                    <h2>Members</h2>
                    <ToggleInput
                      label="Show Only Deleted"
                      value={showOnlyDeleted}
                      onChange={() => setOnlyDeleted(!showOnlyDeleted)}
                    />
                    {mode === 'create' && (
                      <Button variant="outlined" label="Add Members" onClick={addRow} />
                    )}
                  </div>
                )}
                renderRow={({ index, deleteRow }) => (
                  <div className={`TabularInput__row ${setCss(formControls.members[index]?.user)}`}>
                    <Control
                      Component={AdminClickableOrSelectInput}
                      name="user"
                      label="User"
                      options={objectsToSelectOptions(userList, 'fullName', 'key')}
                      error={({ value }) => !value}
                      readOnly={mode !== 'create'}
                      onClickableClick={(id) => {
                        openUserDetail({ userId: id, mode: 'view' });
                        setOnlyDeleted(false);
                      }}
                    />
                    <Control
                      Component={SelectInput}
                      multiple
                      name="role"
                      label="Role"
                      options={objectsToSelectOptions(roles, 'name', 'key')}
                      error={({ value }) => !value}
                      readOnly={mode !== 'create'}
                    />
                    {mode === 'create' && (
                      <Button
                        variant="outlined"
                        label="Delete Row"
                        icon="clear"
                        onClick={deleteRow}
                      />
                    )}
                  </div>
                )}
                name="members"
                ownErrorName="membersTable"
              />
              {formControls.members.length === 0 && mode !== 'create' && '—'}
            </div>
          </Feature.Body>
        </CrudAdminItem>
      )}
    </Feature.Wrapper>
  );
};

OrganizationDetail.propTypes = {
  organization: customPropTypes.organization,
  organizations: customPropTypes.organizations.isRequired,
  organizationId: PropTypes.string,
  fetchRoles: PropTypes.func.isRequired,
  roles: PropTypes.array,
  subOrganization: PropTypes.shape({
    key: PropTypes.string,
    members: PropTypes.array,
    parentKey: PropTypes.string,
    carrierKey: PropTypes.string,
    principalKey: PropTypes.string,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  openUserDetail: PropTypes.func,
  openOrganizationDetail: PropTypes.func,
  fetchUsers: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
};
