import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, ToggleInput, Chip } from 'stti-react-common';

import { ReportControl } from './ReportControl';
import { useFormats } from '../Formats';

export const OutlierFilterInput = ({
  name,
  group = ['filters', 'outliers'],
  paramName,
  paramDefaultValue,
  summaryPrefix,
  filterConfig,
  ...restProps
}) => {
  const { unitText } = useFormats();
  const paramControlName = paramName || `${name}X`;
  const { unitType } = filterConfig;
  const unitAdornment = unitType === 'relative' ? '% of avg' : unitText(unitType);

  return (
    <ReportControl
      name={name}
      group={group}
      render={(props) => (
        <ToggleInput {...props}>
          <ReportControl
            name={paramControlName}
            group={group}
            render={(paramControlProps) => (
              <NumberInput {...paramControlProps} endAdornment={unitAdornment} />
            )}
            reducerCallback={(draft) => {
              draft.controls[name] = true;
            }}
            defaultValue={paramDefaultValue}
          />
        </ToggleInput>
      )}
      renderSummary={(props, { value, controls, formatConvertedUnit }) =>
        value && (
          <Chip
            {...props}
            label={`${summaryPrefix} ${formatConvertedUnit(unitType, controls[paramControlName])}`}
          />
        )
      }
      canDeleteSummary
      filterConfig={filterConfig}
      printConfig={({ value, controls, formatConvertedUnit }) => ({
        key: 'outliers',
        label: 'Additional Filters',
        value:
          value && `${summaryPrefix} ${formatConvertedUnit(unitType, controls[paramControlName])}`,
      })}
      {...restProps}
    />
  );
};

OutlierFilterInput.propTypes = {
  name: PropTypes.string.isRequired,
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  paramName: PropTypes.string,
  paramDefaultValue: PropTypes.any,
  summaryPrefix: PropTypes.string,
  filterConfig: PropTypes.object,
};
