import React from 'react';
import PropTypes from 'prop-types';
import { includes, isFinite, startCase } from 'lodash';
import moment from 'moment-timezone';
import { GoogleMap } from 'stti-react-common';

import { useFormats } from '../Formats';
import { HeadingArrow } from './HeadingArrow';
import { TirePressure } from './TirePressure';
import { StopIcon } from './StopIcon';

import './EventInfo.scss';

const { Info } = GoogleMap;

const MOVING_SPEED_THRESHOLD = 1; // in m/s; 1 m/s equals 3.6 km/h

const getCompassPoint = (heading) => {
  if (!isFinite) return '';
  const compassPoints = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return compassPoints[(heading / (360 / compassPoints.length)).toFixed() % compassPoints.length];
};

export const EventInfo = ({ event, trip, visible, forceOpen, extraInfo, forceDateFormat }) => {
  const { formatUnit } = useFormats();

  const { eventType, speed, heading, eventAt, dutyStatus, other, timeZone, altitude, postedSpeed } =
    event;

  const intoTripDuration =
    trip && moment.duration(moment(eventAt).diff(moment(trip.startedAt))).asSeconds();

  const dateTimeDisplay = () =>
    includes(['VideoEvent', 'LastKnownLocation'], eventType)
      ? moment(eventAt)
          .tz(timeZone)
          ?.format(forceDateFormat || 'hh:mm:ss')
      : formatUnit('date', moment(eventAt).tz(timeZone));

  const renderWhen = () => (
    <div className="Map__EventInfo__when">
      {dateTimeDisplay()}
      {intoTripDuration > 0 && ` (${formatUnit('duration', intoTripDuration)} into trip)`}
    </div>
  );

  const renderVector = () =>
    isFinite(speed) && (
      <div className="Map__EventInfo__vector">
        {speed < MOVING_SPEED_THRESHOLD ? (
          <>
            <StopIcon color="#333" />
            <strong>Stopped</strong>
          </>
        ) : (
          <>
            <HeadingArrow heading={heading || 0} color="#333" />
            Heading {getCompassPoint(heading)} at&nbsp;
            <strong>{formatUnit('speed', speed)}</strong>
          </>
        )}
      </div>
    );

  const renderTirePressure = () =>
    other &&
    other.tirePressureGroups &&
    other.tirePressureGroups.length > 0 && (
      <div className="Map__EventInfo__vector">
        <TirePressure color="#333" />
        Tire Pressure
        <ul>
          {other.tirePressureGroups.map((tirePressureGroup) => (
            <li key={tirePressureGroup.id}>
              {`${tirePressureGroup.name}: `}
              <strong>{formatUnit('pressure', tirePressureGroup.pressure)}</strong>
            </li>
          ))}
        </ul>
      </div>
    );

  const renderDetail = () => {
    switch (eventType) {
      case 'SpeedSampled':
        return null;
      case 'DutyStatusChange':
        return (
          <div className="Map__EventInfo__detail">
            <strong>Duty Status Change</strong>: {dutyStatus || '(unknown)'}
          </div>
        );
      case 'VideoEvent':
        return (
          <div className="Map__EventInfo__detail">
            {postedSpeed && (
              <div>
                <strong>Posted Speed</strong>: {formatUnit('speed', postedSpeed)}
              </div>
            )}
            <div>
              <strong>Altitude</strong>: {altitude && altitude.toFixed(0)} meters
            </div>
          </div>
        );
      default:
        return (
          <div className="Map__EventInfo__detail">
            <strong>{startCase(eventType)}</strong>
            {extraInfo && <div className="extraInfo">{extraInfo}</div>}
          </div>
        );
    }
  };

  return (
    <Info visible={visible} forceOpen={forceOpen}>
      <div className="Map__EventInfo">
        {renderWhen()}
        {renderVector()}
        {renderTirePressure()}
        {renderDetail()}
      </div>
    </Info>
  );
};

EventInfo.propTypes = {
  event: PropTypes.shape({
    eventType: PropTypes.string.isRequired,
    eventAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    speed: PropTypes.number,
    description: PropTypes.string,
    other: PropTypes.shape({
      tirePressureGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          pressure: PropTypes.number.isRequired,
        })
      ),
      typeDescription: PropTypes.string,
      behaviorDescriptions: PropTypes.arrayOf(PropTypes.string),
    }),
    heading: PropTypes.number,
    altitude: PropTypes.number,
    postedSpeed: PropTypes.number,
    dutyStatus: PropTypes.string,
    onClick: PropTypes.any,
  }).isRequired,
  trip: PropTypes.shape({
    startedAt: PropTypes.string.isRequired,
  }),
  visible: PropTypes.bool,
  forceOpen: PropTypes.bool,
  extraInfo: PropTypes.any,
  forceDateFormat: PropTypes.string,
};
