import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, TransitionedScreen, Button } from 'stti-react-common';
import { Transition } from 'react-transition-group';

import './RouteFrame.scss';

class ErrorBoundary extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) return <RouteFrameError />;

    return children;
  }
}

const RouteFrameTransition = ({ timeout, isIn, children }) => (
  <Transition timeout={timeout} in={isIn} mountOnEnter unmountOnExit>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Transition>
);
RouteFrameTransition.propTypes = {
  timeout: PropTypes.number.isRequired,
  isIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const RouteFrameError = () => (
  <div className="RouteFrame RouteFrame--error">
    <p>We&apos;re sorry, an error has occurred.</p>
    <p>
      <Button variant="contained" onClick={() => window.history.back()} label="Go Back" />
      <span> or </span>
      <Button variant="contained" onClick={() => window.location.reload()} label="Try Reloading" />
    </p>
  </div>
);

export const BasicRouteFrame = ({ render, in: isIn, customClass }) => (
  <RouteFrameTransition timeout={0} isIn={isIn}>
    <div className={`RouteFrame RouteFrame--basic ${customClass}`}>{render()}</div>
  </RouteFrameTransition>
);

export const PopupDialogRouteFrame = ({ render, in: isIn, onClose }) => (
  <RouteFrameTransition timeout={500} isIn={isIn}>
    <div className="RouteFrame RouteFrame--popupDialog">
      <BasicDialog onClose={onClose} isOpen={isIn} maxWidth={false} fullWidth fullHeight>
        {render()}
      </BasicDialog>
    </div>
  </RouteFrameTransition>
);

export const FullOverlayRouteFrame = ({ render, in: isIn, navigate }) => (
  <RouteFrameTransition timeout={750} isIn={isIn}>
    <div className="RouteFrame RouteFrame--fullOverlay">
      <TransitionedScreen onClose={() => navigate({ close: true })} isOpen={isIn}>
        {render()}
      </TransitionedScreen>
    </div>
  </RouteFrameTransition>
);

export const FullViewRouteFrame = ({ render, in: isIn }) => (
  <RouteFrameTransition timeout={0} isIn={isIn}>
    <div className="FullViewRouteFrame RouteFrame--basic">{render()}</div>
  </RouteFrameTransition>
);

BasicRouteFrame.propTypes = {
  render: PropTypes.func.isRequired,
  // in: PropTypes.bool.isRequired, // injected by TransitionGroup
};

PopupDialogRouteFrame.propTypes = BasicRouteFrame.propTypes;
FullOverlayRouteFrame.propTypes = BasicRouteFrame.propTypes;
FullViewRouteFrame.propTypes = BasicRouteFrame.propTypes;
