import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { ReportViewTooNew } from './ReportViewTooNew';
import { FormatsProvider } from '../Formats';

export const ReportContext = createContext();

export const ReportProvider = ({ value: report, children }) => (
  <FormatsProvider formats={report.formats}>
    <ReportContext.Provider value={report}>
      {report.reportViewTooNew ? <ReportViewTooNew /> : children}
    </ReportContext.Provider>
  </FormatsProvider>
);

ReportProvider.propTypes = {
  value: PropTypes.shape({
    reportViewTooNew: PropTypes.bool,
  }),
  children: PropTypes.node,
};
