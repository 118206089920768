import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { filter } from 'lodash';

import { durationBetweenIntervals } from '../../../../helpers/moment';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import {
  selectCameraBreadcrumbsFetchRequests,
  selectCameraDevicesFetchRequests,
} from './selectors';
import { ACTION_TYPE } from './reducer';

/* PRIVATE METHODS */

const calculateExpiresInDays = (stringValue) => {
  const expiresInDays =
    60 -
    durationBetweenIntervals(
      extractDateFromString(stringValue),
      moment().format('YYYY-MM-DD'),
      'days'
    );
  if (Math.sign(expiresInDays) === -1) {
    return 0;
  }
  return expiresInDays;
};

const extractDateFromString = (string) => {
  const regex = /(\d{4})[-](\d{2})[-](\d{2})/;
  const match = string.match(regex);
  if (string.match(regex)) {
    return match[0];
  }
  return moment().format('YYYY-MM-DD');
};

export const fetchCameraDevices = makeRestApiAction({
  service: 'cameraDevices',
  method: 'get',
  transformInput: ({ ouKey }) =>
    // RequestId works to prevent FE cache to act with makeOneTimeOptimizedAction for now,
    // BE ignores this query param
    ({ ...(ouKey && { ouKey }), requestId: uuid() }),
  baseActionType: ACTION_TYPE.fetchCameraDevices,
  notificationsItemDescriptor: 'camera devices',
});

const fetchCameraBreadcrumbs = makeRestApiAction({
  service: 'cameraBreadcrumbs',
  method: 'get',
  transformInput: ({ vehicleKey, startDate, endDate, interval }) => ({
    vehicleKey,
    startDate,
    endDate,
    interval,
  }),
  transformOutput: (responseData, { timeZone }) =>
    responseData.map(({ date, ...rest }, index) => ({
      id: uuid(),
      key: `${index}-${moment.tz(date, timeZone).format()}:${rest.latitude}:${rest.longitude}`,
      eventType: 'VideoEvent',
      eventAt: moment.tz(date, timeZone).format(),
      description: `${moment.tz(date, timeZone).format('HH:mm:ss')}`,
      baseDate: moment(date).format('YYYY-MM-DD'),
      sequence: index,
      timeZone,
      ...rest,
    })),
  baseActionType: ACTION_TYPE.fetchCameraBreadcrumbs,
  notificationsItemDescriptor: 'camera breadcrumbs',
});

const lastPartAfterSign = (str, separator = '-') => {
  const result = str.substring(str.lastIndexOf(separator) + 1);
  return result !== str ? result.trim() : false;
};

/* PUBLIC METHODS */

export const fetchCameras = makeRestApiAction({
  service: 'cameras',
  method: 'get',
  transformInput: ({ vehicleKey }) => ({
    vehicleKey,
  }),
  baseActionType: ACTION_TYPE.fetchCameras,
  notificationsItemDescriptor: 'cameras',
});

export const fetchCameraDevice = makeRestApiAction({
  service: 'cameraDevices',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchCameraDevice,
  notificationsItemDescriptor: 'camera device',
});

export const fetchCameraBreadcrumbsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectCameraBreadcrumbsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchCameraBreadcrumbs,
  baseActionType: ACTION_TYPE.fetchCameraBreadcrumbs,
});

export const fetchCameraDevicesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectCameraDevicesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchCameraDevices,
  baseActionType: ACTION_TYPE.fetchCameraDevices,
});

export const fetchCameraRecords = makeRestApiAction({
  service: 'cameraRecordings',
  method: 'get',
  transformInput: ({ vehicleKey, startDate, endDate }) => ({
    vehicleKey,
    startDate,
    endDate,
  }),
  transformOutput: (responseData, { startDate, endDate, vehicleKey }) =>
    responseData.map((row) => ({ key: `${startDate}-${endDate}-${vehicleKey}`, ...row })),
  baseActionType: ACTION_TYPE.fetchCameraRecords,
  notificationsItemDescriptor: 'camera records',
});

export const fetchCameraRecordsStreaming = makeRestApiAction({
  service: 'cameraRecordingsStreaming',
  method: 'get',
  transformInput: ({ vehicleKey, startDate, cameraId, provider = 'SURFSIGHT' }) => ({
    vehicleKey,
    startDate,
    cameraId,
    provider,
  }),
  baseActionType: ACTION_TYPE.fetchCameraRecordsStreaming,
  notificationsItemDescriptor: 'camera streaming records',
});

export const fetchCameraTrips = makeRestApiAction({
  service: 'cameraTrips',
  method: 'get',
  transformInput: ({ vehicleKey, startDate, endDate, minimumDistance }) => ({
    vehicleKey,
    startDate,
    endDate,
    ...(minimumDistance && { minimumDistance }),
  }),
  transformOutput: (responseData) =>
    responseData.map(({ start, end }) => ({
      id: uuid(),
      startedAt: start.date,
      endedAt: end.date,
      origin: { latitude: start.latitude, longitude: start.longitude },
      location: { latitude: start.latitude, longitude: start.longitude },
      lastUnload: {
        latitude: end.latitude,
        longitude: end.longitude,
      },
    })),
  baseActionType: ACTION_TYPE.fetchCameraTrips,
  notificationsItemDescriptor: 'camera trips',
});

export const postCameraVirtualEvent = makeRestApiAction({
  service: 'cameraVirtualEvents',
  method: 'post',
  transformInput: ({
    vehicleKey,
    startDate,
    duration,
    quality,
    cameraId,
    provider = 'SURFSIGHT',
  }) => ({
    vehicleKey,
    startDate,
    duration,
    quality,
    cameraId,
    provider,
  }),
  baseActionType: ACTION_TYPE.postCameraVirtualEvent,
  notificationsItemDescriptor: 'camera virtual event',
});

export const fetchCameraVirtualEvents = makeRestApiAction({
  service: 'cameraVirtualEvents',
  method: 'get',
  transformInput: ({ vehicleKey, startDate, endDate }) => ({
    vehicleKey,
    startDate,
    endDate,
  }),
  transformOutput: (responseData, { startDate, endDate, vehicleKey }) =>
    responseData.map(({ events, ...restProps }) => {
      const remappedEvents = events.map(({ metadata, speed, ...rest }) => {
        const duration = lastPartAfterSign(metadata);
        const speedMeters = speed / 3.6;
        return {
          metadata,
          speed: speedMeters,
          duration,
          downloadAt: extractDateFromString(metadata),
          expiresInDays: calculateExpiresInDays(metadata),
          ...rest,
        };
      });
      return {
        key: `${startDate}-${endDate}-${vehicleKey}`,
        baseDate: moment(startDate).format('YYYY-MM-DD'),
        events: remappedEvents,
        ...restProps,
      };
    }),
  baseActionType: ACTION_TYPE.fetchCameraVirtualEvents,
  notificationsItemDescriptor: 'camera virtual events',
});

export const fetchCameraVirtualEventsMediaDownload = makeRestApiAction({
  service: 'cameraVirtualEventsMediaDownload',
  method: 'get',
  transformInput: ({
    vehicleKey,
    fileId,
    cameraId,
    provider = 'SURFSIGHT',
    fileType = 'video',
  }) => ({
    vehicleKey,
    fileId,
    cameraId,
    provider,
    fileType,
  }),
  baseActionType: ACTION_TYPE.fetchCameraVirtualEventsMediaDownload,
  notificationsItemDescriptor: 'camera virtual events',
});

export const fetchCameraWakeUp = makeRestApiAction({
  service: 'cameraWakeUp',
  method: 'get',
  transformInput: ({ vehicleKey, provider = 'SURFSIGHT' }) => ({
    vehicleKey,
    provider,
  }),
  baseActionType: ACTION_TYPE.fetchCameraWakeUp,
  notificationsItemDescriptor: 'camera wake up',
  throwApiErrors: true,
});

export const postAddCameras = makeRestApiAction({
  service: 'cameraAdd',
  method: 'post',
  getId: ({ tenantKey, provider }) => `${tenantKey}/cameras?provider=${provider}`,
  transformInput: ({ data }) => [...data],
  baseActionType: ACTION_TYPE.postAddCameras,
  notifications: {
    success: 'Your request to add devices was received successfully.',
    failure: (_, apiError) => `Error while adding devices: (${apiError.message}).`,
  },
});

export const putCameraDevice = makeRestApiAction({
  service: 'cameraDevices',
  method: 'put',
  getId: ({ id }) => id,
  transformInput: ({ assetTag, vehicleKey }) => ({
    assetTag,
    vehicleKey: vehicleKey === 'not-assigned' ? null : vehicleKey,
  }),
  baseActionType: ACTION_TYPE.putCameraDevice,
  notificationsItemDescriptor: 'camera device',
});

export const deleteCameraDevice = makeRestApiAction({
  service: 'cameraDevices',
  method: 'delete',
  getId: (id) => id,
  transformOutput: (responseData, actionParam) => actionParam,
  baseActionType: ACTION_TYPE.deleteCameraDevice,
  notifications: {
    success: 'Your request to delete this device was received successfully.',
    failure: (_, apiError) => `Error while deleting devices: (${apiError.message}).`,
  },
});

export const postCameraDeviceReboot = makeRestApiAction({
  service: 'cameraDevices',
  method: 'post',
  getId: (id) => `${id}/reboot`,
  baseActionType: ACTION_TYPE.postCameraDeviceReboot,
  notificationsItemDescriptor: 'camera device',
});

export const fetchLiveStreamDataByCameraId = makeRestApiAction({
  service: 'cameraVideoLiveStreamData',
  method: 'get',
  transformInput: ({ vehicleKey, provider = 'SURFSIGHT', cameraId }) => ({
    vehicleKey,
    provider,
    cameraId,
  }),
  baseActionType: ACTION_TYPE.fetchLiveStreamDataByCameraId,
  notificationsItemDescriptor: 'camera video stream data',
});

export const fetchLiveSnapshotDataByCameraId = makeRestApiAction({
  service: 'cameraSnapshotLiveData',
  method: 'get',
  transformInput: ({ vehicleKey, provider = 'SURFSIGHT', cameraId }) => ({
    vehicleKey,
    provider,
    cameraId,
  }),
  baseActionType: ACTION_TYPE.fetchLiveSnapshotDataByCameraId,
  notificationsItemDescriptor: 'camera snapshot data',
});
