import BetterPropTypes from 'better-prop-types';

import {
  UNREVIEWED_VIOLATION_STATUS,
  REVIEWED_VIOLATION_STATUS,
  UNKNOWN_VIOLATION_STATUS,
  NO_VIOLATION_STATUS,
} from '../../data/reports';

const driverLogShape = {
  cycleOnDutyRemaining: BetterPropTypes.number,
  cycleOnDutyTime: BetterPropTypes.number,
  cycleStart: BetterPropTypes.string,
  deferralStatus: BetterPropTypes.string,
  deferralTime: BetterPropTypes.number,
  driver: BetterPropTypes.shape({
    name: BetterPropTypes.string.isOptionalButNotNull,
    key: BetterPropTypes.string.isOptionalButNotNull,
  }).isOptionalButNotNull,
  endedAt: BetterPropTypes.string,
  hasActivity: BetterPropTypes.bool.isOptionalButNotNull,
  key: BetterPropTypes.string.isOptionalButNotNull,
  logDate: BetterPropTypes.string.isOptionalButNotNull,
  logStanding: BetterPropTypes.oneOf(['GOOD', 'IN_VIOLATION', 'NO_CYCLE_TIME']),
  onDutyDrivingTotal: BetterPropTypes.number,
  onDutyRemaining: BetterPropTypes.number,
  onDutyTotal: BetterPropTypes.number,
  ouKey: BetterPropTypes.string,
  startedAt: BetterPropTypes.string.isOptionalButNotNull,
  timeZone: BetterPropTypes.string.isOptionalButNotNull,
  violationStatus: BetterPropTypes.oneOf([
    UNREVIEWED_VIOLATION_STATUS,
    REVIEWED_VIOLATION_STATUS,
    UNKNOWN_VIOLATION_STATUS,
    NO_VIOLATION_STATUS,
  ]),
};

export const driverLog = BetterPropTypes.shape(driverLogShape);

export const driverLogs = BetterPropTypes.arrayOf(driverLog);

export const detailedDriverLog = BetterPropTypes.shape({
  ...driverLogShape,
  adminStatusChanges: BetterPropTypes.array.isOptionalButNotNull,
  carrierDetails: BetterPropTypes.shape({
    address: BetterPropTypes.string.isOptionalButNotNull,
    canDotNumber: BetterPropTypes.number,
    name: BetterPropTypes.string.isOptionalButNotNull,
    principalBusinessName: BetterPropTypes.string.isOptionalButNotNull,
    usDotNumber: BetterPropTypes.number,
  }),
  coDrivers: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      ...driverLogShape.driver,
      username: BetterPropTypes.string.isOptionalButNotNull,
    })
  ),
  comment: BetterPropTypes.string,
  cycleName: BetterPropTypes.string,
  diagnosticIndication: BetterPropTypes.bool,
  activeDiagnostics: BetterPropTypes.array,
  distanceDriven: BetterPropTypes.number,
  driver: BetterPropTypes.shape({
    ...driverLogShape.driver,
    cdnEldExempt: BetterPropTypes.bool,
    cdnEldExemptReason: BetterPropTypes.string,
    driverLicenseNumber: BetterPropTypes.string,
    firstName: BetterPropTypes.string,
    homeTerminalKey: BetterPropTypes.string,
    lastName: BetterPropTypes.string,
    licenseIssuingCountry: BetterPropTypes.string,
    licenseIssuingState: BetterPropTypes.string,
    modifiedAt: BetterPropTypes.string,
    regulations: BetterPropTypes.string,
    usEldExempt: BetterPropTypes.bool,
    usEldExemptReason: BetterPropTypes.string,
    username: BetterPropTypes.string,
  }).isOptionalButNotNull,
  driverEvents: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      annotation: BetterPropTypes.string.isOptionalButNotNull,
      date: BetterPropTypes.string.isOptionalButNotNull,
      distance: BetterPropTypes.number,
      geoLocation: BetterPropTypes.string,
      accumulatedVehicleDistance: BetterPropTypes.number,
      elapsedEngineHours: BetterPropTypes.number,
      key: BetterPropTypes.string.isOptionalButNotNull,
      origin: BetterPropTypes.string,
      status: BetterPropTypes.string,
    })
  ),
  certificationStatus: BetterPropTypes.shape({
    certified: BetterPropTypes.bool.isOptionalButNotNull,
    invalidated: BetterPropTypes.string,
    lastCertified: BetterPropTypes.string,
    signature: BetterPropTypes.string,
  }),
  dutyIntervals: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      activities: BetterPropTypes.arrayOf(
        BetterPropTypes.shape({
          annotation: BetterPropTypes.string.isOptionalButNotNull,
          date: BetterPropTypes.string.isOptionalButNotNull,
        })
      ).isOptionalButNotNull,
      annotation: BetterPropTypes.string.isOptionalButNotNull,
      canDelete: BetterPropTypes.bool.isOptionalButNotNull,
      canEdit: BetterPropTypes.bool.isOptionalButNotNull,
      canReassign: BetterPropTypes.bool.isOptionalButNotNull,
      date: BetterPropTypes.string.isOptionalButNotNull,
      distance: BetterPropTypes.number.isOptionalButNotNull,
      geoLocation: BetterPropTypes.string,
      end: BetterPropTypes.string.isOptionalButNotNull,
      accumulatedVehicleDistance: BetterPropTypes.number.isOptionalButNotNull,
      elapsedEngineHours: BetterPropTypes.number.isOptionalButNotNull,
      isReassigned: BetterPropTypes.bool.isOptionalButNotNull,
      isVirtual: BetterPropTypes.bool.isOptionalButNotNull,
      key: BetterPropTypes.string.isOptionalButNotNull,
      origin: BetterPropTypes.string.isOptionalButNotNull,
      start: BetterPropTypes.string.isOptionalButNotNull,
      status: BetterPropTypes.string.isOptionalButNotNull,
    })
  ),
  effectiveRegulations: BetterPropTypes.array.isOptionalButNotNull,
  eldEvents: BetterPropTypes.array.isOptionalButNotNull,
  endTime: BetterPropTypes.string,
  homeTerminalDetails: BetterPropTypes.object,
  hoursOnDutySummary: BetterPropTypes.array,
  malfunctionIndication: BetterPropTypes.bool,
  activeMalfunctions: BetterPropTypes.array,
  movementIntervals: BetterPropTypes.array,
  offDutyNotSleeperBerthTotal: BetterPropTypes.number,
  offDutyRemaining: BetterPropTypes.number,
  offDutySleeperBerthTotal: BetterPropTypes.number,
  offDutyTotal: BetterPropTypes.number,
  onDutyDrivingRemaining: BetterPropTypes.number,
  onDutyDrivingRemainingTomorrow: BetterPropTypes.number,
  onDutyNotDrivingTotal: BetterPropTypes.number,
  onDutyRemainingTomorrow: BetterPropTypes.number,
  personalUseRemaining: BetterPropTypes.number,
  personalUseUsed: BetterPropTypes.number,
  regulation: BetterPropTypes.string,
  reviewDetailsKey: BetterPropTypes.string,
  shippingDocuments: BetterPropTypes.array,
  vehicleDetails: BetterPropTypes.array,
  violations: BetterPropTypes.arrayOf(
    BetterPropTypes.shape({
      appliesTo: BetterPropTypes.string.isOptionalButNotNull,
      description: BetterPropTypes.string.isOptionalButNotNull,
    })
  ).isOptionalButNotNull,
});
