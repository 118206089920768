import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';

import { EventInfo } from './EventInfo';

const { Marker } = GoogleMap;

export const EventMarkers = ({ events, icon, trip, ...restProps }) =>
  !!events &&
  events.map((event) => (
    <Marker key={event.sequence} position={event} icon={icon} order={event.sequence} {...restProps}>
      <EventInfo event={{ ...event, timeZone: trip.timeZone }} trip={trip} />
    </Marker>
  ));

EventMarkers.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.number.isRequired,
    })
  ),
  icon: PropTypes.object.isRequired,
  trip: PropTypes.shape({
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
};
