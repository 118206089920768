import { connect } from 'react-redux';

import { selectPrintMode } from '../../../../data/system';

import { DetailReportPrintingLinks } from './DetailReportPrintingLinks';

export const DetailReportPrintingLinksContainer = connect(
  (state, props) => ({
    printMode: selectPrintMode(state, props),
  }),
  {}
)(DetailReportPrintingLinks);
