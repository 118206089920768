// EXTERNAL LIB IMPORTS
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { reject } from 'lodash';
import { Button, Menu, ToggleInput, useBooleanState } from 'stti-react-common';
import { useHistory } from 'react-router-dom';

// COMMON LIB IMPORTS
import {
  AdminSearchPanel,
  AdminExportDataMenu,
  DeleteAdminItemDialog,
} from '../../../../commons/AdminCommon';
import { Feature } from '../../../../commons/Feature';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';

// HELPERS LIB IMPORTS
import { useDebounce } from '../../../../../helpers/hooks';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

// MISC IMPORTS
import { columnDefs } from './columnDefs';
import { dataGridDefs } from './dataGridDefs';
import { FEATURES } from '../../../../../features';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const UserSummary = ({
  users,
  fetchUsers,
  fetchRoles,
  deleteUser,
  openUserDetail,
  openUserCreateDriver,
  openUserCreateSupport,
  openRolesSummary,
  route,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [showOnlyDeleted, setOnlyDeleted] = useState(false);
  const [showOnlyDrivers, setOnlyDrivers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const history = useHistory();

  const renderPayload = {
    openUserDetail,
    setTargetRow,
    openDeleteDialog,
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const preFilteredUsers = useMemo(
    () =>
      reject(users, (user) => user.deleted !== showOnlyDeleted).filter(
        (user) => !showOnlyDrivers || user.isDriver
      ),
    [users, showOnlyDeleted, showOnlyDrivers]
  );

  const dataGridComponents = useDataGridComponents(dataGridDefs(renderPayload));

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  return (
    <Feature.Wrapper className="UserSummary">
      <Feature.Header onClose={route.close} title="Users" service="users">
        {FEATURES.admin.hideRolesFromMainMenu && (
          <Button
            icon="groups_2"
            variant="contained"
            label="Roles"
            onClick={() =>
              openRolesSummary({ ...route.matched.values }, { history, root: 'administration' })
            }
          />
        )}
        <AdminExportDataMenu dataGridController={dataGridController} filename="Users" />
        <Menu
          icon="add"
          variant="contained"
          label="Create New User"
          items={[
            {
              label: 'New Driver',
              icon: 'local_shipping',
              variant: 'contained',
              onClick: () => openUserCreateDriver(),
            },
            {
              label: 'New Support User',
              icon: 'admin_panel_settings',
              variant: 'contained',
              onClick: () => openUserCreateSupport(),
            },
          ]}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Users"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
          renderPanelActions={
            <>
              <ToggleInput
                label="Show Only Drivers"
                value={showOnlyDrivers}
                onChange={() => setOnlyDrivers(!showOnlyDrivers)}
              />
              <ToggleInput
                label="Show Only Deleted"
                value={showOnlyDeleted}
                onChange={() => setOnlyDeleted(!showOnlyDeleted)}
              />
            </>
          }
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={preFilteredUsers}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          enableCellTextSelection
          autoHeightColumns
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="User"
        itemTitle={`${targetRow.firstName} ${targetRow.lastName}`}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteUser}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

UserSummary.propTypes = {
  users: customPropTypes.users.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  openUserDetail: PropTypes.func.isRequired,
  openUserCreateDriver: PropTypes.func.isRequired,
  openUserCreateSupport: PropTypes.func.isRequired,
  openRolesSummary: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
