import { omit, isNil, filter } from 'lodash';

import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectVehiclesFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

export const fetchVehicles = makeRestApiAction({
  service: 'assets',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVehicles,
  transformInput: () => ({
    fetch: 'assetType,managedBy,sharedWith',
    include: 'inactive',
  }),
  transformOutput: (responseData) =>
    responseData.map(({ vin, ...rest }) => {
      const sanitizedVin = vin.replace(/[^\w\s\d]+/gi, '-');
      return { vin: sanitizedVin, ...rest };
    }),
  notificationsItemDescriptor: 'vehicles',
});

export const fetchVehiclesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVehiclesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVehicles,
  baseActionType: ACTION_TYPE.fetchVehicles,
});

const fetchVehicle = makeRestApiAction({
  service: 'assets',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVehicle,
  getId: (key) => key,
  transformInput: () => ({
    fetch: 'assetType,managedBy,sharedWith',
  }),
  notificationsItemDescriptor: 'vehicle',
});

export const fetchVehicleOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVehiclesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchVehicle,
  baseActionType: ACTION_TYPE.fetchVehicle,
});

export const createVehicle = makeRestApiAction({
  service: 'assets',
  method: 'post',
  baseActionType: ACTION_TYPE.createVehicle,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => responseData,
  notificationsItemDescriptor: 'vehicle',
  throwApiErrors: true,
});

export const updateVehicle = makeRestApiAction({
  service: 'assets',
  method: 'put',
  baseActionType: ACTION_TYPE.updateVehicle,
  getId: ({ key }) => key,
  transformInput: (data) => omit(data, isNil),
  transformOutput: (responseData, actionParam) => responseData,
  notificationsItemDescriptor: 'vehicle',
  throwApiErrors: true,
});

export const deleteVehicle = makeRestApiAction({
  service: 'assets',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteVehicle,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'vehicle',
});

export const fetchVehicleRemovePairing = makeRestApiAction({
  service: 'removeVehicleParing',
  method: 'put',
  baseActionType: ACTION_TYPE.fetchVehicleRemovePairing,
  getId: (key) => key,
  notificationsItemDescriptor: 'vehicle',
});
