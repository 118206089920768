import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ToggleInput, SelectInput } from 'stti-react-common';
import { flatten, isNull, uniq } from 'lodash';

import {
  ControlGroupSummary,
  SectionPanel,
  ReportControl,
} from '../../../../../commons/ReportsCommon';
import { filterRowsByPredicate } from '../../../../../../helpers/reports/standardFilters';

import { getRenderSummary, getMonthsAndYearFromQuarter } from '../../utils';
import { toMoment } from '../../../../../../helpers/moment';
import { FEATURES } from '../../../../../../features';

export const FiltersPanelDetail = ({ rows, group, quarter, filterGroup, fuelTaxId }) => {
  const { months } = getMonthsAndYearFromQuarter(quarter);

  const jurisdictionStates = flatten(rows.map((row) => row.jurisdictionState));

  const getOptions = useMemo(
    () => ({
      jurisdictionStates: uniq(jurisdictionStates).sort(),
      months: months.map(({ key }) => key),
    }),
    [rows]
  );

  return (
    <SectionPanel
      name="detailPanel"
      title="Filters"
      renderSummaries={() => <ControlGroupSummary group={group} />}
    >
      {FEATURES.reports.useAllJuridisctionFilterOnDetailTaxReport && (
        <ReportControl
          name="showAllJurisdictions"
          group={group}
          label="Show segments for all Jurisdictions"
          canDeleteSummary
          render={(renderProps) => <ToggleInput {...renderProps} />}
          renderSummary={(renderProps, { value }) =>
            getRenderSummary(
              renderProps,
              value ? ['Show All Juridisctions'] : ['Show Primary Jurisdiction'],
              'filter'
            )
          }
          printConfig={({ value }) => value}
          filterConfig={{
            group: filterGroup,
            type: filterRowsByPredicate,
            test: ({ row, value }) => {
              if (!value) return isNull(row.parentUUID);
              return fuelTaxId === row.parentUUID || fuelTaxId === row.fuelTaxId;
            },
          }}
        />
      )}

      <ReportControl
        name="jurisdictionState"
        group={group}
        label="Jurisdiction"
        canDeleteSummary
        render={(renderProps) => (
          <SelectInput
            {...renderProps}
            multiple
            multipleCollapseAt={2}
            multipleCollapseText="jurisdictions selected"
            options={getOptions.jurisdictionStates}
          />
        )}
        renderSummary={(renderProps, { value }) =>
          getRenderSummary(renderProps, value, 'jurisdiction')
        }
        printConfig={({ value }) => value}
        filterConfig={{
          group: filterGroup,
          type: filterRowsByPredicate,
          test: ({ row, value }) => value.includes(row.jurisdictionState),
          disabled: ({ value }) => !value || value.length === 0,
        }}
      />
      <ReportControl
        name="months"
        group={group}
        label="Month(s)"
        canDeleteSummary
        render={(renderProps) => (
          <SelectInput
            {...renderProps}
            multiple
            multipleCollapseAt={2}
            multipleCollapseText="months selected"
            options={getOptions.months}
          />
        )}
        renderSummary={(renderProps, { value }) => getRenderSummary(renderProps, value, 'month')}
        printConfig={({ value }) => value}
        filterConfig={{
          group: filterGroup,
          type: filterRowsByPredicate,
          test: ({ row, value }) => {
            const monthName = toMoment(row.startedAt, row.timeZone).format('MMMM');
            return value.includes(monthName);
          },
          disabled: ({ value }) => !value || value.length === 0,
        }}
      />
    </SectionPanel>
  );
};

FiltersPanelDetail.propTypes = {
  rows: PropTypes.array,
  group: PropTypes.string.isRequired,
  quarter: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
  fuelTaxId: PropTypes.string,
};
