import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.iconColumn({
    field: 'routeMap',
    headerName: 'Open',
    cellRenderer: 'AssetTag',
    headerComponent: 'AssetHeader',
  }),
  columnDef.textColumn({
    field: 'assetTag',
    headerName: 'Asset Tag',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'assetId',
    headerName: 'Selected Vehicle',
    cellRenderer: 'Vehicle',
  }),
  columnDef.textColumn({
    field: 'ouName',
    headerName: 'Managed By',
  }),
  columnDef.textColumn({
    field: 'model',
    headerName: 'Device Model',
  }),
  columnDef.textColumn({
    field: 'imei',
    headerName: 'IMEI',
    enableRowGroup: false,
    hide: true,
  }),
  columnDef.textColumn({
    field: 'iccid',
    headerName: 'IICID',
    enableRowGroup: false,
    hide: true,
  }),
  columnDef.dateColumn({
    field: 'lastSignIn',
    headerName: 'Last Sign In',
  }),
  columnDef.textColumn({
    field: 'appVersion',
    headerName: 'Navistream Version',
  }),
  columnDef.textColumn({
    field: 'apkEnvironmentName',
    headerName: 'Release Channel',
  }),
  columnDef.textColumn({
    field: 'vgmFirmwareVersion',
    headerName: 'Firmware Version',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
    width: 138,
  }),
];
