import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, Clickable, Icon, ScrollBar } from 'stti-react-common';

import './TrackingSelectionDialogue.scss';
import { customPropTypes } from '../../../../helpers/customPropTypes';

export const TrackingSelectionDialogue = ({
  isModal,
  isOpen,
  onClose,
  selectedTrackingList,
  trackingSelectionKeys,
  trackingSelectionList,
  vehicleTypes,
}) => {
  const [trackingList, setTrackingList] = useState(selectedTrackingList);

  useEffect(() => {
    setTrackingList(selectedTrackingList);
  }, [isOpen, selectedTrackingList]);

  const addAll = () => {
    const completeList = vehicleTypes.map(({ key }) => key);
    setTrackingList(completeList);
  };

  const removeAll = () => {
    setTrackingList([]);
  };

  const addTrackingSelectionKeyList = (typeKey) => {
    const newList = trackingList?.includes(typeKey)
      ? trackingList?.filter((key) => key !== typeKey)
      : [...new Set([...trackingList, typeKey])];
    setTrackingList(newList);
  };

  const renderList = () => (
    <div className="TrackingContainer">
      <hr />
      <ScrollBar style={{ height: '60vh' }}>
        <div className="Tracking-Selection-List">
          <div>
            <h3>Motor Vehicle Types</h3>
            <ul className="list-group">
              {trackingSelectionList
                .filter((typeTracking) => typeTracking.typeClass === 'Vehicle')
                .map(({ name, key }, id) => (
                  <Clickable key={id} onClick={() => addTrackingSelectionKeyList(key)}>
                    <li>
                      {name}
                      <Icon
                        icon={trackingList?.includes(key) ? 'check_box' : 'check_box_outline_blank'}
                      />
                    </li>
                  </Clickable>
                ))}
            </ul>
          </div>
          <div>
            <h3>Trailer Types</h3>
            <ul className="list-group">
              {trackingSelectionList
                .filter((typeTracking) => typeTracking.typeClass === 'Trailer')
                .map(({ name, key }, id) => (
                  <Clickable key={id} onClick={() => addTrackingSelectionKeyList(key)}>
                    <li className="Location-list-item">
                      {name}
                      <Icon
                        icon={trackingList?.includes(key) ? 'check_box' : 'check_box_outline_blank'}
                      />
                    </li>
                  </Clickable>
                ))}
            </ul>
          </div>
        </div>
      </ScrollBar>

      <div className="TrackingContainer__footer">
        <Clickable onClick={() => addAll()}>Select all</Clickable>
        <Clickable onClick={() => removeAll()}>Remove all</Clickable>
      </div>
    </div>
  );

  if (!isModal) return <div className="TrackingSelection">{renderList()}</div>;

  return (
    <BasicDialog
      header={
        <div className="TrackingSelection__header">
          <Icon className="title-icon" icon="tune" /> <h3>Tracking Selection</h3>
        </div>
      }
      className="TrackingSelection"
      maxWidth="lg"
      fullWidth
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
          className: 'MapSetting_Cancelbtn',
        },
        {
          label: 'Track Now',
          onClick: () => trackingSelectionKeys(trackingList),
          className: 'MapSetting_Applybtn',
        },
      ]}
    >
      {renderList()}
    </BasicDialog>
  );
};

TrackingSelectionDialogue.propTypes = {
  isModal: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedTrackingList: PropTypes.array,
  trackingSelectionKeys: PropTypes.func,
  trackingSelectionList: PropTypes.arrayOf(customPropTypes.locationType).isRequired,
  vehicleTypes: customPropTypes.vehicleTypes.isRequired,
};
