import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';

import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import {
  useReportController,
  ReportProvider,
  ControlGroupSummary,
  SectionPanel,
  ReportLoader,
  SectionPanelMenu,
  MapIcon,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './TripsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: {},
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['trips'];

export const TripsSummaryReport = ({
  fetchTrips,
  limits,
  reportViewId,
  route,
  tripRouteReportRoute,
  trips,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'tripsSummary',
    reportTypeName: 'Trip Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: trips,
      chain: ['query', 'resource', 'route', 'outlier', 'invertOutlier'],
    },
  });
  const { setControl, filteredRows, dataGridController, reportType } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data?.vehicle?.key])}>{value}</Clickable>
    ),
    Trailers: ({ data: { trailers } }) =>
      trailers.map((trailer, index) => (
        <span key={trailer.name}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('trailersNames', [trailer.key])}>
            {trailer.name}
          </Clickable>
        </span>
      )),
    Drivers: ({ data: { drivers } }) =>
      drivers.map((driver, index) => (
        <span key={`${driver.name} ${driver.order}`}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('driversNames', [driver.key])}>
            {driver.name}
          </Clickable>
        </span>
      )),
    Origin: ({ value }) => (
      <Clickable onClick={() => setControl('originName', [value])}>{value}</Clickable>
    ),
    FirstLoad: ({ value }) => (
      <Clickable onClick={() => setControl('firstLoadName', [value])}>{value}</Clickable>
    ),
    LastUnload: ({ value }) => (
      <Clickable onClick={() => setControl('lastUnloadName', [value])}>{value}</Clickable>
    ),
    RouteMapHeader: asDataGridHeaderComponent(() => <MapIcon />),
    RouteMap: ({ data: { id } }) => (
      <Clickable onClick={() => tripRouteReportRoute.open({ tripId: id })}>
        <MapIcon />
      </Clickable>
    ),
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchTrips} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="TripsSummaryReport">
        <ReportFragment.Header reportType="tripsSummary" route={route} services={requestServices} />

        <QueryPanel queryFor="trip" group="query" minAllowedDate="2018-01-01" disableFuture />
        <SectionPanel
          name="filtersPanel"
          title="Trip Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <ReportFragment.TripResourceFilters
            rows={filteredRows.query}
            group="filters"
            filterGroup="resource"
          />
          <ReportFragment.LocationFilters
            rows={filteredRows.query}
            group="filters"
            filterGroup="route"
          />
          <ReportFragment.TripOutlierFilters baseClassName="CyclesSummaryReport" />
        </SectionPanel>
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
    </ReportProvider>
  );
};

TripsSummaryReport.propTypes = {
  fetchTrips: PropTypes.func.isRequired,
  limits: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  tripRouteReportRoute: PropTypes.object.isRequired,
  trips: PropTypes.array.isRequired,
};
