import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { startCase, isObject } from 'lodash';
import { DataGrid } from 'stti-react-common';

export const QaDataGrid = ({ data, onRowClick, ...props }) => {
  const isVisible = useMemo(() => !!window.localStorage.showQaTools, []);

  const columnDefs = useMemo(
    () =>
      data && data.length > 0
        ? Object.entries(data[0]).map(([key, value]) => {
            const columnDef = { field: key, headerName: startCase(key) };
            if (isObject(value))
              columnDef.valueFormatter = (params) => JSON.stringify(params.value);
            return columnDef;
          })
        : [],
    [data]
  );

  const gridOptions = {
    defaultColDef: {
      filter: true,
    },
  };

  const handleRowClicked = useCallback(
    ({ data: row }) => {
      // eslint-disable-next-line no-console
      console.info(row);
      if (onRowClick) onRowClick(row);
    },
    [onRowClick]
  );

  if (!isVisible) return null;

  return (
    <div className="QaDataGrid">
      <p>
        The below table is visible because the localStorage key <code>showQaTools</code> is
        non-empty.
      </p>
      <div>
        <DataGrid
          theme="balham"
          rowSelection="single"
          columnDefs={columnDefs}
          rowData={data}
          gridOptions={gridOptions}
          suppressCellSelection
          onRowClicked={handleRowClicked}
          {...props}
        />
      </div>
    </div>
  );
};

QaDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
};
