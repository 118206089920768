import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'username',
    headerName: 'Username',
    cellRenderer: 'Email',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'email',
    headerName: 'Email',
    cellRenderer: 'Email',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'firstName',
    headerName: 'First Name',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'lastName',
    headerName: 'Last Name',
    enableRowGroup: false,
  }),
  columnDef.enabledDisabledColumn({
    field: 'enabled',
    headerName: 'Enabled',
    cellRenderer: 'ToggleChip',
  }),
  columnDef.dateColumn({
    field: 'registrationDate',
    headerName: 'Registration Date',
    width: 200,
    enableRowGroup: false,
  }),
  columnDef.dateColumn({
    field: 'lastSignIn',
    headerName: 'Last Sign In',
    width: 200,
    enableRowGroup: false,
  }),
  columnDef.enabledDisabledColumn({
    field: 'personalUse',
    headerName: 'Personal Use',
    cellRenderer: 'ToggleChip',
  }),
  columnDef.enabledDisabledColumn({
    field: 'yardMoves',
    headerName: 'Yard Moves',
    cellRenderer: 'ToggleChip',
  }),
  columnDef.textColumn({
    field: 'driverLicenseNumber',
    headerName: 'Driver License Number',
  }),
  columnDef.textColumn({
    field: 'licenseIssuingCountry',
    headerName: 'Driver License Issuing Country',
  }),
  columnDef.textColumn({
    field: 'licenseIssuingState',
    headerName: 'Driver License Issuing State',
  }),
  columnDef.listColumn({
    field: 'memberships',
    headerName: 'Organizations',
    cellRenderer: 'Organizations',
    transformValueElement: (item) => item.name,
  }),
  columnDef.listColumn({
    colId: 'roles',
    field: 'memberships',
    headerName: 'Roles',
    cellRenderer: 'Roles',
    transformValueElement: (item) => item.roles.map((role) => role.name).join(', '),
  }),
  columnDef.textColumn({
    field: 'regulations',
    headerName: 'Regulations',
  }),
  columnDef.textColumn({
    field: 'homeTerminalName',
    headerName: 'Home Terminal',
  }),
  columnDef.textColumn({
    field: 'externalId',
    headerName: 'External ID',
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
