import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Clickable,
  TextInput,
  TextAreaInput,
  Icon,
  ScrollBar,
  Button,
  Chip,
} from 'stti-react-common';
import { filter, isEmpty, sortBy, some } from 'lodash';
import dayjs from 'dayjs';

import { getInitials, getIconColor } from '../../../data/messenger/helpers';
import { customPropTypes } from '../../../helpers/customPropTypes';
import { dateObject, getRelativeTime, getMinutes } from '../../../helpers/moment';

import './messenger.scss';

export const Messenger = ({
  dispatchOldestDate,
  fetchMessages,
  fetchUsers,
  oldestDate,
  participants,
  postMessage,
  postMessageEvent,
  targetParticipant = {},
  user,
  users,
}) => {
  useEffect(() => {
    const isGuest = some(user?.memberships, (membership) => membership?.role?.includes('S-guest'));

    if (!isGuest) {
      fetchUsers();
      fetchMessages({
        start: dayjs(dayjs().subtract(1, 'day')).toISOString(),
        end: dayjs().toISOString(),
      });
    }
  }, []);

  const messageViewRef = useRef(null);
  const [typing, setTyping] = useState('');
  const [search, setSearch] = useState('');
  const [participant, setParticipant] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [broadcastMode, setBroadcastMode] = useState(false);

  const sendBroadcast = (content, targets, sender) => {
    targets.forEach((element) => {
      sendMessage(content, element, sender);
    });
    setBroadcastMode(false);
    setTyping('');
  };

  const sendMessage = (content, recipient, sender) => {
    const messagePayload = {
      content,
      date: dateObject().toISOString(),
      recipient,
      sender,
    };

    setTyping('');
    postMessage(messagePayload).then(() =>
      fetchMessages({
        start: messagePayload.date,
        end: dateObject().toISOString(),
      }).then(() => (!broadcastMode ? setTimeout(scrollToRef, 100) : null))
    );
  };

  const loadOlderMessages = () => {
    const newOldestDate = oldestDate.subtract(1, 'day');
    fetchMessages({
      start: newOldestDate.toISOString(),
      end: oldestDate.toISOString(),
    }).then(() => dispatchOldestDate(newOldestDate));
  };

  const markMessage = (msg, eventType) => {
    const event = {
      participant: user.key, // it is always the current user
      date: dateObject().toISOString(),
      event: eventType,
      type: 'messaging.MessageEvent',
      messageKey: msg.key,
    };

    postMessageEvent(event);
    return '';
  };

  const userList = useMemo(() => {
    const compositeUsers = users.map(({ key, ...rest }) => ({
      key,
      ...rest,
      conversations: participants.find((row) => row.reference === key).conversations,
      isActive: getMinutes(rest.lastSignIn) < 1440,
    }));
    if (!search || isEmpty(search)) {
      return compositeUsers;
    }

    return filter(compositeUsers, (row) =>
      row.fullName.toLowerCase().includes(search.toLowerCase())
    );
  }, [users, search, participants]);

  const renderMessages = ({ key }) => {
    const { conversations } = participants.find(({ reference }) => reference === key);
    const sortedMessages = sortBy(conversations?.messages, ['date']);
    const renderedMessages = sortedMessages.map(
      ({ author, authorName, content, date, status }, id) =>
        author === key ? (
          <div className="messenger__container__message isMe" key={id}>
            <div className="chat__date">{getRelativeTime(date)}</div>
            <div className="chat__content">
              <div
                className="chat__content__avatar"
                style={{ backgroundColor: getIconColor(authorName) }}
              >
                {getInitials(authorName)}
              </div>
              <div className="chat__content__text">{content}</div>
            </div>
            {status && <div className="chat__status">{status}</div>}
          </div>
        ) : (
          <div className="messenger__container__message isNotMe" key={id}>
            <div className="chat__date">{getRelativeTime(date)}</div>
            <div className="chat__content">
              <div className="chat__content__text">{content}</div>
              <div
                className="chat__content__avatar"
                style={{ backgroundColor: getIconColor(authorName) }}
              >
                {getInitials(authorName)}
              </div>
            </div>
            {status && <div className="chat__status">{status}</div>}
          </div>
        )
    );

    const conditionalClassName = isEmpty(targetParticipant) ? 'messenger' : 'singleModeMessenger';

    return (
      <>
        <div className={`${conditionalClassName}__container__header`}>
          <p className={`${conditionalClassName}__container__header__name`}>
            {participant.fullName}
          </p>
          <Clickable
            className={`${conditionalClassName}__container__header__load`}
            onClick={() => loadOlderMessages()}
          >
            Load messages from {getRelativeTime(oldestDate)}
          </Clickable>
        </div>
        <ScrollBar
          className="scrollBar"
          style={{ height: isEmpty(targetParticipant) ? 'calc(100vh - 300px)' : '170px' }}
          onScrollRender={(result) => {
            if (!isEmpty(targetParticipant)) {
              result.scrollToBottom(); // this will only be handled by single mode located in fleet tracking
            }
          }}
        >
          {renderedMessages}
          <div ref={messageViewRef} />
        </ScrollBar>
        <div className={`${conditionalClassName}__container__sendMessage`}>
          <TextAreaInput
            placeholder="message"
            value={typing}
            onChange={setTyping}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                sendMessage(typing, participant.key, user.key);
              }
            }}
          />
          <div className="btn_send">
            <Button
              icon="send"
              disabled={isEmpty(typing)}
              variant="contained"
              label="Send"
              onClick={() => sendMessage(typing, participant.key, user.key)}
            />
          </div>
        </div>
      </>
    );
  };

  const scrollToRef = () => {
    messageViewRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (userList && !isEmpty(targetParticipant) && targetParticipant.type === 'driver') {
      const singleTarget = userList.find((row) => row.key === targetParticipant.key);

      if (singleTarget) {
        setParticipant(singleTarget);
      }
    }
  }, [targetParticipant, userList]);

  if (broadcastMode) {
    return (
      <div className="messenger">
        <div className="messenger__sidebar">
          <div className="messenger__sidebar__search">
            <div className="messenger__sidebar__search__header">
              <h4>Broadcast Messages</h4>
              <TextInput
                startAdornment={<Icon icon="search" />}
                className="search_field"
                label="Search recipients by driver name"
                value={search}
                onChange={(val) => setSearch(val)}
              />
            </div>
            <div className="messenger__sidebar__search__broadcast">
              <Button
                variant="outlined"
                label="Go Back"
                icon="arrow_back"
                onClick={() => {
                  setBroadcastMode(false);
                  setRecipients([]);
                }}
              />
            </div>
          </div>
          <div className="messenger__sidebar__userList">
            {userList.map(({ fullName, key }) => (
              <div className="user" key={key}>
                <div className="user__mark">
                  <Icon
                    icon={recipients.includes(key) ? 'check_box' : 'check_box_outline_blank'}
                    onClick={() =>
                      setRecipients((prev) =>
                        prev.includes(key) ? prev.filter((r) => r !== key) : [...prev, key]
                      )
                    }
                  />
                </div>
                <div className="user__name">{fullName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="messenger__container">
          <div className="messenger__container__header broadcast">
            <p className="messenger__container__header__name">New Message</p>
          </div>
          <div className="messenger__container__to">
            <div className="button_container">
              <Button
                variant="outlined"
                label="Add All"
                icon="group_add"
                onClick={() => setRecipients(userList.map(({ key }) => key))}
              />
              <Button
                variant="outlined"
                label="Add Active"
                icon="check_circle"
                onClick={() =>
                  setRecipients(userList.filter(({ isActive }) => isActive).map(({ key }) => key))
                }
              />
              <Button
                variant="outlined"
                label="Remove All"
                icon="group_remove"
                onClick={() => setRecipients([])}
              />
            </div>
            <p className="messenger__container__header">To:</p>
          </div>
          <div className="messenger__container__selectedRecipients">
            {userList
              .filter(({ key }) => recipients.includes(key))
              .map(({ fullName, key }) => (
                <Chip
                  key={key}
                  variant="default"
                  color="primary"
                  label={fullName}
                  onDelete={() => {
                    setRecipients((prev) => prev.filter((r) => r !== key));
                  }}
                />
              ))}
          </div>
          <div className="messenger__container__sendMessage">
            <TextAreaInput
              placeholder="message"
              value={typing}
              onChange={setTyping}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendBroadcast(typing, recipients, user.key);
                }
              }}
            />
            <div className="btn_send">
              <Button
                disabled={isEmpty(typing)}
                variant="contained"
                label="Enter"
                onClick={() => sendBroadcast(typing, recipients, user.key)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!isEmpty(targetParticipant)) {
    return (
      <div className="singleModeMessenger">
        <div className="singleModeMessenger__container">
          {participant && renderMessages({ key: participant?.key })}
        </div>
      </div>
    );
  }

  return (
    <div className="messenger">
      <div className="messenger__sidebar">
        <div className="messenger__sidebar__search">
          <div className="messenger__sidebar__search__header">
            <h4>Messages</h4>
            <TextInput
              startAdornment={<Icon icon="search" />}
              className="search_field"
              label="Search conversations by driver name"
              value={search}
              onChange={(val) => setSearch(val)}
            />
          </div>
          <div className="messenger__sidebar__search__broadcast">
            <Button
              variant="outlined"
              label="Broadcast"
              icon="cell_tower"
              onClick={() => setBroadcastMode(true)}
            />
          </div>
        </div>
        <div className="messenger__sidebar__userList">
          {userList.map(({ fullName, key, conversations }) => (
            <div className={`user ${key === participant?.key ? 'selected' : ''}`} key={key}>
              <div className="user__avatar" style={{ backgroundColor: getIconColor(fullName) }}>
                {getInitials(fullName)}
              </div>
              <div className="user__name">
                <Clickable
                  onClick={() => {
                    setParticipant(users.find((row) => row.key === key));
                    setTimeout(() => scrollToRef(), 10);
                    if (conversations?.messages.length > 0 && !conversations?.hasBeenRead) {
                      markMessage(conversations?.messages[0], 'Read');
                    }
                  }}
                >
                  {fullName}
                </Clickable>
                {conversations?.messages.length > 0 && (
                  <div className="user__name__conversation">{`${
                    conversations?.messages[0].author !== key ? 'You:' : ''
                  } ${
                    conversations?.messages.length > 0
                      ? `${conversations?.messages[0].content.substring(0, 15)} ${
                          conversations?.messages[0].content.length > 15 ? '...' : ''
                        }`
                      : ''
                  }`}</div>
                )}
              </div>
              <div className="user__status">
                {conversations?.hasBeenRead === false &&
                conversations?.messages[0].sender !== user.key ? (
                  <div className="new-message">new</div>
                ) : null}
                {conversations?.messages.length > 0
                  ? getRelativeTime(conversations?.messages[0].date)
                  : ''}
              </div>
            </div>
          ))}
        </div>
        <div className="messenger__sidebar__footer">
          <Clickable
            className="messenger__container__header__load"
            onClick={() => loadOlderMessages()}
          >
            Load messages from {getRelativeTime(oldestDate)}
          </Clickable>
        </div>
      </div>
      <div className="messenger__container">
        {participant && renderMessages({ key: participant?.key })}
      </div>
    </div>
  );
};

Messenger.propTypes = {
  dispatchOldestDate: PropTypes.func,
  fetchMessages: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  oldestDate: PropTypes.any,
  participants: PropTypes.array,
  postMessage: PropTypes.func,
  postMessageEvent: PropTypes.func,
  targetParticipant: PropTypes.object,
  user: customPropTypes.user,
  users: customPropTypes.users,
};
