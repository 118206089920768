/* eslint-disable no-console */
import { isFunction, now } from 'lodash';

import { ACTION_TYPE } from './systemReducer';
import { selectPrintMode } from './systemSelectors';
import { analytics } from '../analytics';
import { debugMode } from '../../helpers/debug';

export const setPrintMode = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setPrintMode, payload });
};

let isFirstPrint = true; // set false after first print

const PRINT_PREPARATION_TIME = {
  // in ms
  userMin: 1000, // must be enough time for browser to paint print layout, evidence is 1000ms is adequate
  serverMin: 110000, // must be enough time for SSR to fully render all new data (DataGrid, etc...) and paint print layout, evidence is min of 20000ms is adequate, cannot be equal or larger than 120000 since this is the max timeout in lambdas; can be overridden with webclientViewReadyDelay
  userMax: 12000, // if exceeded, proceed with user's print regardless of readiness
  pollInterval: 250, // after min time elapsed, polling interval for presence of NotReadyToPrint components blocking printing
};

const printActionSetTimeout = (handlerFn, minTime, maxTime) => {
  console.groupCollapsed('WEB VIEW INFO - PRINT ACTION SET TIMEOUT');
  console.info('minTime', minTime);
  console.info('maxTime', maxTime);
  console.groupEnd();

  const waitingSince = now();

  window.setTimeout(() => {
    const interval = setInterval(() => {
      if (
        document.querySelector('.NotReadyToPrint') && // wait for any NotReadyToPrint components to clear
        maxTime &&
        now() - waitingSince < maxTime // wait only until max time, if provided
      )
        return;
      clearInterval(interval);
      handlerFn();
    }, PRINT_PREPARATION_TIME.pollInterval);
  }, minTime);
};

export const initiatePrint =
  ({ mode }) =>
  async (dispatch) => {
    if (isFirstPrint) {
      // setup print event and media query listeners

      const handleAfterPrint = () => {
        window.setTimeout(() => dispatch(setPrintMode({ print: false })), 0); // necessary to append dispatch to event queue
      };
      window.addEventListener('afterprint', handleAfterPrint);

      const printMediaQuery = window.matchMedia('print');
      printMediaQuery.addEventListener('change', (e) => {
        if (!e.matches) handleAfterPrint();
      });

      isFirstPrint = false;
    }

    if (debugMode) {
      console.groupCollapsed('WEB VIEW INFO - INITIATE PRINT');
      console.info('initiatePrint/printMediaQuery/addEventListener', mode);
      console.groupEnd();
    }

    dispatch(setPrintMode({ print: mode }));
    analytics.sendEvent({ action: 'initiatePrint' });

    printActionSetTimeout(
      window.print,
      PRINT_PREPARATION_TIME.userMin,
      PRINT_PREPARATION_TIME.userMax
    );
  };

let isWebclientViewSignalled = false;

if (window.localStorage.showQaTools && !window.webclientViewReady) {
  // these window methods are exposed by Puppeteer during server-side rendering; exposed here for debugging and testing

  const hrefs = window.localStorage.getItem('DetailReportPrintingLinks');

  window.webclientViewReady = (...args) => {
    console.groupCollapsed('WEB VIEW INFO - VIEW READY [PRINT RESULTS - DETAILS]');
    console.info('webclientViewReady -> args', ...args);
    if (hrefs) {
      console.groupCollapsed('WEB VIEW INFO - DETAIL REPORT LINKS');
      console.table(hrefs.split(','));
      console.groupEnd();
    }
    console.groupEnd();
  };

  window.webclientViewError = (...args) => {
    console.groupCollapsed('WEB VIEW INFO - VIEW ERROR');
    console.info('webclientViewError', ...args);
    console.groupEnd();
  };
}

const getFileFormat = (attach) => {
  if (!attach) return null;
  if (attach.includes('csv')) return 'csv';
  if (attach.includes('xlsx')) return 'xlsx';
  return null;
};

export const signalWebclientViewReady = (getters) => async (dispatch, getState) => {
  if (isWebclientViewSignalled || !isFunction(window.webclientViewReady)) return;

  const { attach, webclientViewReadyDelay } = selectPrintMode(getState());

  console.groupCollapsed('WEB VIEW INFO - PRINT SETUP');
  console.info('webclientViewReadyDelay', webclientViewReadyDelay);
  console.info('PRINT_PREPARATION_TIME', PRINT_PREPARATION_TIME);
  console.groupEnd();

  printActionSetTimeout(async () => {
    const readyParam = {};

    const format = getFileFormat(attach);
    if (format) {
      readyParam.exportDataFilename = await getters.getFilename(format);
      readyParam.exportDataContent = await getters.getContent(format);
    }
    window.webclientViewReady(readyParam);
  }, Math.max(webclientViewReadyDelay || PRINT_PREPARATION_TIME.serverMin, PRINT_PREPARATION_TIME.serverMin));

  isWebclientViewSignalled = true;

  dispatch({
    type: ACTION_TYPE.signalWebclientViewReady,
  });
};

export const signalWebclientViewError = (error) => async (dispatch) => {
  if (isWebclientViewSignalled || !isFunction(window.webclientViewError)) return;

  window.webclientViewError(error);

  isWebclientViewSignalled = true;

  dispatch({
    type: ACTION_TYPE.signalWebclientViewError,
  });
};
