import { find } from 'lodash';

/**
 * selectTenants(state) -> [tenants]
 *
 * Selector for all tenants
 */
export const selectTenants = (state) => state.administration.configurations.tenants.data;

/**
 * selectTenantsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on tenants
 */
export const selectTenantsFetchRequests = (state) =>
  state.administration.configurations.tenants.fetches;

/**
 * selectTenant(state, { key }) -> tenant
 *
 * Selector for single admin tenant by key
 */
export const selectTenant = (state, key) => find(selectTenants(state), { key });
