import { connect } from 'react-redux';

import { ExceptionsSummaryReport } from './ExceptionsSummaryReport';
import {
  selectUnitedExceptions,
  createLocalReportView,
  fetchTripsOptimized,
  fetchAnnotations,
  fetchExceptions,
} from '../../../../data/reports';
import { selectSystemTenant, selectSearchLimits } from '../../../../data/system';

export const ExceptionsSummaryReportContainer = connect(
  (state) => ({
    exceptions: selectUnitedExceptions(state),
    tenant: selectSystemTenant(state),
    limits: selectSearchLimits(state, 'exceptions'),
  }),
  {
    fetchAnnotations,
    fetchExceptions,
    fetchTrips: fetchTripsOptimized,
    createLocalReportView,
  }
)(ExceptionsSummaryReport);
