import React from 'react';

import { OrganizationSummaryContainer } from './Summary/connect';
import { OrganizationDetailContainer } from './Detail/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

import { userDetail } from '../../resources/Users/routes';

export const organizationsSummary = configureRoute({
  name: 'Organizations',
  group: administrationGroups.configurations,
  order: 8,
  path: '/organizations',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => (
    <OrganizationSummaryContainer
      {...props}
      openOrganizationCreate={organizationCreate.open}
      openOrganizationDetail={organizationDetail.open}
    />
  ),
});

export const organizationCreate = configureRoute({
  path: '/organization/new',
  switchKey: 'organization',
  RouteFrame: BasicRouteFrame,
  render: (props) => <OrganizationDetailContainer {...props} mode="create" />,
});

export const organizationDetail = configureRoute({
  path: '/organization/:organizationId',
  switchKey: 'organization',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <OrganizationDetailContainer
      {...props}
      openOrganizationDetail={organizationDetail.open}
      openUserDetail={userDetail.open}
    />
  ),
});
