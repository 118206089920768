import { connect } from 'react-redux';

import { SpeedLimitSummary } from './summary';

import {
  // selectCurrentOus,
  selectSystemActiveOu,
  selectSystemTenantOu,
  selectSystemUser,
  setActiveOu,
} from '../../../../../data/system';

import { organization, speedLimit } from '../../../../../data/administration/configurations';

export const SpeedLimitSummaryContainer = connect(
  (state) => ({
    // selectSpeedLimitConfiguration returns this structure:
    // {[speedLimits], defaultValues: {gracePeriod, speedLimit}}
    ...speedLimit.selectors.selectSpeedLimitConfiguration(state),
    tenantOu: selectSystemTenantOu(state),
    activeOu: selectSystemActiveOu(state),
    systemUser: selectSystemUser(state),
    // systemOus: selectCurrentOus(state),
    ous: organization.selectors.selectOrganizations(state),
  }),
  {
    fetchSpeedLimits: speedLimit.actions.fetchSpeedLimits,
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    overrideSpeedLimit: speedLimit.actions.overrideSpeedLimit,
    clearSpeedLimit: speedLimit.actions.clearSpeedLimit,
    setActiveOu,
  }
)(SpeedLimitSummary);
