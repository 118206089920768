import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

export const PrintButton = ({ initiatePrint, mode = true, ...restProps }) => {
  const handleClick = useCallback(() => initiatePrint({ mode }), [mode]);

  return (
    <Button
      className="PrintButton"
      icon="print"
      label="Print"
      tooltip="Print"
      onClick={handleClick}
      {...restProps}
    />
  );
};

PrintButton.propTypes = {
  initiatePrint: PropTypes.func.isRequired,
  mode: PropTypes.string,
};
