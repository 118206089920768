/* eslint-disable no-console */
import { cloneDeep } from 'lodash';
import { debugMode } from '../../../helpers/debug';

export const LATEST_REPORT_VIEW_VERSION = 2;

export const migrateReportView = (reportView) => {
  const migrated = cloneDeep(reportView);
  const { controls } = migrated.state;

  if (debugMode) {
    console.groupCollapsed('WEB VIEW INFO - [REPORT LOADER] - migrateReportView');
    console.info('currentVersion', reportView.state.version);
    reportView.state.version > LATEST_REPORT_VIEW_VERSION
      ? console.info('We are retuning undefined so controller will handle it')
      : console.info('reportView', reportView);
    console.groupEnd();
  }

  // establish current version, ensure it is not "from the future" relative to this WC
  let currentVersion = reportView.state.version;
  if (currentVersion > LATEST_REPORT_VIEW_VERSION) return undefined; // report controller will handle

  // migrate 1 -> 2
  if (currentVersion === 1) {
    /*
      Version 1 has ou control with string of either 'default' or OU key.
      Version 2 has ouMode of 'default', 'all', or 'custom', and ousSelected of array of OU keys (only honoured if custom mode)
    */
    if (controls.ou === 'default') {
      controls.ouMode = 'default';
      controls.ousSelected = [];
    } else {
      controls.ouMode = 'custom';
      controls.ousSelected = [controls.ou];
    }
    delete controls.ou;
  }
  currentVersion = 2;

  // return migrated reportView
  migrated.state.version = currentVersion;
  return migrated;
};
