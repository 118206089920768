import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';

import { TripRoute, SectionInfo, VideoMarker, symbols } from '../../../commons/MapsCommon';

const { RouteSection } = GoogleMap;

export const ExceptionMapContents = ({ exception, breadcrumbs }) => {
  if (!exception) return null;

  const { exceptionType } = exception;

  const isTripException = !!exceptionType.match(/^trip/);
  const isVideoException = exceptionType === 'eventVideoEvent';

  if (isTripException) {
    // trip-based exception
    const { trip } = exception;
    return <TripRoute trip={trip} breadcrumbs={breadcrumbs} />;
  }

  if (isVideoException) {
    // video event exception
    return (
      <VideoMarker
        eventAt={exception.startedAt}
        position={exception.startLocation}
        heading={exception.other.heading}
        speed={exception.other.speed}
        timeZone={exception.timeZone}
        descriptions={exception.other.descriptions}
      />
    );
  }

  // standard event-based exception
  const sectionType = exceptionType.replace(/^event/, ''); // remove 'event' prefix, e.g. eventSpeedViolation -> SpeedViolation

  const section = {
    ...exception,
    sectionType,
  };

  return (
    <RouteSection path={breadcrumbs} line={symbols.violation}>
      <SectionInfo section={section} />
    </RouteSection>
  );
};

ExceptionMapContents.propTypes = {
  exception: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
