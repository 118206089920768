import { connect } from 'react-redux';

import { UserDetail } from './detail';
import { user, role } from '../../../../../data/administration/resources';

import {
  fetchRegulationsOptimized,
  fetchCountriesOptimized,
  selectSystemActiveOu,
  selectSystemTenant,
  selectRegulations,
  selectCountries,
  selectSystemTenantOu,
  selectIsUserDriver,
  selectSystemOus,
} from '../../../../../data/system';

export const UserDetailContainer = connect(
  (state, { userId }) => ({
    disableUsernameEdit: selectIsUserDriver(state),
    users: user.selectors.selectUsers(state),
    countriesList: selectCountries(state),
    ous: selectSystemOus(state),
    activeOu: selectSystemActiveOu(state),
    user: user.selectors.selectUserById(state, userId),
    regulations: selectRegulations(state),
    roles: role.selectors.selectRoles(state),
    tenant: selectSystemTenant(state),
    tenantOu: selectSystemTenantOu(state),
    userId,
  }),
  {
    fetchUser: user.actions.fetchUserOptimized,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchRoles: role.actions.fetchRolesOptimized,
    fetchCountries: fetchCountriesOptimized,
    fetchRegulations: fetchRegulationsOptimized,
    createUser: user.actions.createUser,
    updateUser: user.actions.updateUser,
    deleteUser: user.actions.deleteUser,
    restoreUser: user.actions.restoreUser,
  }
)(UserDetail);
