export const PASTEL_COLORS = [
  { key: 'A', value: '#5bb974' },
  { key: 'B', value: '#4ecde6' },
  { key: 'C', value: '#fcc934' },
  { key: 'D', value: '#af5cf7' },
  { key: 'E', value: '#fa903e' },
  { key: 'F', value: '#ff63b8' },
  { key: 'G', value: '#E0BBE4' },
  { key: 'H', value: '#957DAD' },
  { key: 'I', value: '#D291BC' },
  { key: 'J', value: '#FEC8D8' },
  { key: 'K', value: '#FFDFD3' },
  { key: 'L', value: '#90C978' },
  { key: 'M', value: '#AFD5AA' },
  { key: 'N', value: '#83C6DD' },
  { key: 'O', value: '#5DB1D1' },
  { key: 'P', value: '#58949C' },
  { key: 'Q', value: '#ECE6D2' },
  { key: 'R', value: '#F2D7B4' },
  { key: 'S', value: '#DF9881' },
  { key: 'T', value: '#9A91AC' },
  { key: 'U', value: '#CAA7BD' },
  { key: 'V', value: '#FFB9C4' },
  { key: 'W', value: '#FFD3D4' },
  { key: 'X', value: '#DAE3D9' },
  { key: 'Y', value: '#D4B59D' },
  { key: 'Z', value: '#CE9C6F' },
  { key: '0', value: '#572D15' },
  { key: '1', value: '#B64D3A' },
  { key: '2', value: '#B29DD9' },
  { key: '3', value: '#FE6B64' },
  { key: '4', value: '#77DD77' },
  { key: '5', value: '#779ECB' },
  { key: '6', value: '#26E3E0' },
  { key: '7', value: '#38A6D9' },
  { key: '8', value: '#4076D9' },
  { key: '9', value: '#3854BD' },
];
