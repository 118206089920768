import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressOverlay } from 'stti-react-common';
import { isEmpty } from 'lodash';

import { Feature } from '../../../../commons/Feature';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel, AdminExportDataMenu } from '../../../../commons/AdminCommon';
import { ColorChip } from '../../../../commons/ColorChip';

import { columnDefs } from './columnDefs';

import './summary.scss';

export const EcmReportSummary = ({ ecmReports, fetchEcmReports, currentUser, route }) => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    setLoading(true);
    fetchEcmReports().then(() => {
      setLoading(false);
    });
  }, []);

  const shouldShowThePage = useMemo(
    () => !loading && !isEmpty(currentUser),
    [loading, ecmReports, currentUser]
  );

  const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

  const dataGridComponents = useDataGridComponents({
    Uptime: ({ value }) => <span>{value > 100 ? 100 : value}%</span>,
    Working: {
      renderer: ({ value: { label, color } }) => <ColorChip label={label} color={color} />,
      groupRenderer: true,
    },
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  if (!shouldShowThePage) {
    return <ProgressOverlay isOpen={loading} />;
  }

  // RENDER
  return (
    <Feature.Wrapper className="EcmReportSummary">
      <Feature.Header onClose={route && route.close} title="NaviLinks" service="ecmReports" />

      <Feature.Body>
        {!currentUser?.username?.includes('streamline@') ? (
          <div className="presentation">
            <div className="cameraImage" />
            <div className="infoContainer">
              <h3>Navilink configuration page coming soon.</h3>
            </div>
          </div>
        ) : (
          <div className="EcmReportSummary__wrapper">
            <AdminSearchPanel
              searchPlaceHolder="Search By"
              searchText={searchText}
              onSearchTextChanged={setSearchText}
            >
              <AdminExportDataMenu dataGridController={dataGridController} filename="Ecm Reports" />
            </AdminSearchPanel>
            <ControlledDataGrid
              theme="balham"
              controller={dataGridController}
              components={dataGridComponents}
              rows={ecmReports}
              searchText={debouncedSearchText}
              rowIdProperty="id"
              enableCellTextSelection
              sizeColumnsToFit
            />
          </div>
        )}
      </Feature.Body>
    </Feature.Wrapper>
  );
};

EcmReportSummary.propTypes = {
  ecmReports: customPropTypes.activationCodes.isRequired,
  fetchEcmReports: PropTypes.func.isRequired,
  currentUser: customPropTypes.user,
  route: PropTypes.object,
};
