import React from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel } from 'stti-react-common';

import { ReportControl } from './ReportControl';

export const SectionPanel = ({ name, children, renderSummaries, title, ...restProps }) => (
  <ReportControl
    name={name}
    render={(renderProps) => (
      <ExpansionPanel
        {...renderProps}
        summary={renderProps.value ? null : renderSummaries()}
        title={title}
      >
        {children}
      </ExpansionPanel>
    )}
    {...restProps}
  />
);

SectionPanel.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  renderSummaries: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
