import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  formatUnit,
  convertUnit,
  unconvertUnit,
  formatConvertedUnit,
  unitText,
  getFormatDefinition,
} from '../../../helpers/formats';
import { FormatsContext } from './FormatsContext';
import { useFormats } from './useFormats';

const EMPTY_FORMATS = {};

const settableFormats = [
  'date',
  // timelessDate is always derived from date
  // datelessTime is always derived from date
  'duration',
  'distance',
  'distanceEld',
  // speed is always derived from distance
  'speed',
  'pressure',
  'volume',
  'mileage',
  'weight',
];

export const FormatsProvider = ({ systemFormats, formats = EMPTY_FORMATS, children }) => {
  const {
    formats: providedFormats = EMPTY_FORMATS,
    overriddenFormats: providedOverriddenFormats = EMPTY_FORMATS,
  } = useFormats() || {};

  const providerValue = useMemo(() => {
    const overriddenFormats = { ...providedOverriddenFormats, ...formats };
    const mergedFormats = { ...systemFormats, ...providedFormats, ...overriddenFormats };

    // set derived export formats unless overridden upstream
    settableFormats.forEach((formatType) => {
      const exportFormatType = `${formatType}Export`; // following convention, e.g. distanceExport
      if (!overriddenFormats[exportFormatType])
        mergedFormats[exportFormatType] = mergedFormats[formatType];
    });

    // always set derived speed
    mergedFormats.speed = mergedFormats.distance;
    mergedFormats.speedExport = mergedFormats.distanceExport || mergedFormats.distance;

    // always set derived date variations
    if (mergedFormats.date) {
      const derivedFormats = (getFormatDefinition('date', mergedFormats.date) || {}).derived;
      if (derivedFormats) {
        mergedFormats.timelessDate = derivedFormats.timelessDate;
        mergedFormats.timelessDateExport =
          derivedFormats.timelessDateExport || derivedFormats.dateExport;
        mergedFormats.datelessTime = derivedFormats.datelessTime;
        mergedFormats.datelessTimeExport =
          derivedFormats.datelessTimeExport || derivedFormats.dateExport;
      }
    }

    return {
      formats: mergedFormats, // complete set of formats
      overriddenFormats,
      formatUnit: (unitType, value, format = mergedFormats[unitType]) =>
        formatUnit(unitType, value, format),
      convertUnit: (unitType, value, convertToUnits = mergedFormats[unitType]) =>
        convertUnit(unitType, value, convertToUnits),
      unconvertUnit: (unitType, value, convertFromUnits = mergedFormats[unitType]) =>
        unconvertUnit(unitType, value, convertFromUnits),
      formatConvertedUnit: (unitType, value, format = mergedFormats[unitType]) =>
        formatConvertedUnit(unitType, value, format),
      unitText: (unitType, format = mergedFormats[unitType]) => unitText(unitType, format),
    };
  }, [systemFormats, providedOverriddenFormats, formats]);

  return <FormatsContext.Provider value={providerValue}>{children}</FormatsContext.Provider>;
};

FormatsProvider.propTypes = {
  children: PropTypes.node,
  systemFormats: PropTypes.object,
  formats: PropTypes.object,
};
