import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ProgressOverlay, TextInput, SelectInput } from 'stti-react-common';
import moment from 'moment-timezone';

import { AdminFormLabel, CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { useFormats } from '../../../../commons/Formats';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import { TabletDeletePrompt } from '../Modals/TabletDeletePrompt';

export const DeviceDetail = ({
  tablet,
  tabletId,
  fetchTablet,
  fetchTablets,
  updateTablet,
  deleteTablet,
  mode: initialMode = 'view',
  route,
  fetchEnvironments,
  environments,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  const { formatUnit } = useFormats();

  useEffect(() => {
    fetchEnvironments();
  }, []);

  useEffect(() => {
    // If registrationDate exists it means we have already fetched for the individual tablet and don't need to re-fetch
    Promise.all([fetchTablets(), fetchTablet(tabletId)])
      .then(resetControls)
      .finally(() => {
        setLoading(false);
      });
  }, [tabletId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: tablet,
  });

  const { controls: formControls, resetControls } = form;

  const renderForm = () => (
    <div className="CrudAdminItem__controls">
      <Control
        Component={TextInput}
        name="assetTag"
        label="Asset Tag"
        error={({ value }) => !value}
      />
      <Control Component={TextInput} name="assetId" label="Vehicle" readOnly />
      <Control Component={TextInput} name="ouName" label="Managed By" readOnly />
      <Control Component={TextInput} name="model" label="Device Model" readOnly />
      <Control Component={TextInput} name="platform" label="Platform" readOnly />
      <Control Component={TextInput} name="version" label="Platform Version" readOnly />
      <Control Component={TextInput} name="imei" label="IMEI" readOnly />
      <Control Component={TextInput} name="iccid" label="ICCID" readOnly />
      <Control Component={TextInput} name="deviceId" label="Device Identifier" readOnly />
      <div className="CrudAdminItem__nestedControls">
        <AdminFormLabel
          label="Registration Date"
          value={formatUnit('date', moment(tablet.registrationDate).tz(tablet.timeZone))}
        />
        <AdminFormLabel
          label="Last Sign In"
          value={formatUnit('date', moment(tablet.lastSignIn).tz(tablet.timeZone))}
        />
      </div>
      <Control Component={TextInput} name="appVersion" label="Navistream Version" readOnly />
      <Control Component={TextInput} name="imei" label="IMEI" readOnly />
      <Control Component={TextInput} name="vgmFirmwareVersion" label="VGM Firmware Version" />
      <Control
        Component={SelectInput}
        name="apkEnvironment"
        label="Release Channel"
        options={objectsToSelectOptions(environments, 'name', 'key', true)}
      />
    </div>
  );

  return (
    <div className="DeviceDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Device"
          form={form}
          itemTitle={formControls.assetTag}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          data={formControls}
          onClose={route.close}
          updateItem={updateTablet}
          deleteItem={deleteTablet}
          additionalDeletePrompt={
            <TabletDeletePrompt lastSignIn={formatUnit('date', moment(formControls.lastSignIn))} />
          }
          resetControls={resetControls}
        >
          <>
            <ErrorSummary errorMsg={apiError} />
            {renderForm()}
          </>
        </CrudAdminItem>
      )}
    </div>
  );
};

DeviceDetail.propTypes = {
  tablet: customPropTypes.tablet,
  tabletId: PropTypes.string.isRequired,
  fetchTablet: PropTypes.func.isRequired,
  fetchTablets: PropTypes.func.isRequired,
  updateTablet: PropTypes.func.isRequired,
  deleteTablet: PropTypes.func.isRequired,
  fetchEnvironments: PropTypes.func.isRequired,
  environments: PropTypes.array,
  mode: PropTypes.oneOf(['view', 'edit', 'update']),
  route: PropTypes.object.isRequired,
};
