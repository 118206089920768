import React from 'react';

import { DelaySummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const delaySummary = configureRoute({
  name: 'Delays',
  group: administrationGroups.configurations,
  order: 4,
  path: '/delays',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <DelaySummaryContainer {...props} />,
});
