import { intersection, castArray } from 'lodash';

import { useReport } from './useReport';
import { useFormats } from '../Formats';

const intersects = (compare1, compare2) =>
  !!intersection(castArray(compare1), castArray(compare2)).length;

export const ControlGroupSummary = ({ group, separator }) => {
  const { controlDefs, controls, setControl, filteredRows } = useReport();
  const { formats, formatUnit, formatConvertedUnit } = useFormats();
  const summaries = [];
  Object.entries(controlDefs).forEach(([name, config]) => {
    if (intersects(group, config.group) && config.renderSummary) {
      const props = {
        key: name,
      };
      if (config.canDeleteSummary)
        props.onDelete = () => {
          setControl(name, null);
        };
      // invoke renderSummary with props, value, controls
      summaries.push(
        config.renderSummary(props, {
          value: controls[name],
          controls,
          formats,
          formatUnit,
          formatConvertedUnit,
          filteredRows,
        })
      );
    }
  });
  return separator
    ? summaries.reduce((a, v, i) => a.concat(v, i !== summaries.length - 1 && separator), [])
    : summaries;
};

export const useControlGroupHasSummary = (group) => {
  const { controlDefs, controls } = useReport();
  let result = false;
  Object.entries(controlDefs).forEach(([name, config]) => {
    if (config.renderSummary && intersects(group, config.group) && controls[name]) result = true;
  });
  return result;
};
