import { columnDef } from '../../../../../commons/ControlledDataGrid';

export const violationsColumnDefs = [
  columnDef.textColumn({
    field: 'message',
    headerName: 'Description',
    minWidth: 150,
  }),
  columnDef.textColumn({
    field: 'appliesTo',
    headerName: 'Applies To',
    minWidth: 150,
  }),
];
