import { makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

export const fetchSpeedLimits = makeRestApiAction({
  service: 'speedTrackingConfiguration',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchSpeedLimits,
  notificationsItemDescriptor: 'speed limits',
});

export const overrideSpeedLimit = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.overrideSpeedLimit,
  transformInput: (payload) => ({ ...payload, _type: 'speedtracking.OverrideSpeedLimit' }),
  notificationsItemDescriptor: 'speed limits',
  throwApiErrors: true,
});

export const clearSpeedLimit = makeRestApiAction({
  service: 'commands',
  method: 'post',
  baseActionType: ACTION_TYPE.clearSpeedLimit,
  transformInput: (payload) => ({ ...payload, _type: 'speedtracking.ClearSpeedLimit' }),
  notifications: {
    success: 'Speed Limits reset to default values.',
    failure: (_, apiError) =>
      `Speed Limits could not be reset to default values (${apiError.message}).`,
  },
  throwApiErrors: true,
});
