import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'stti-react-common';

import { useReport } from './useReport';

export const SectionPanelMenu = ({ group, ...restProps }) => {
  const { resetState, revertState, meta } = useReport();
  return (
    <Menu
      icon="more_vert"
      items={[
        {
          label: `Revert Panel To ${meta.defaultView ? 'Default' : 'Saved'}`,
          onClick: () => {
            revertState(group);
          },
        },
        {
          label: 'Reset Panel',
          onClick: () => {
            resetState(group);
          },
        },
      ]}
      {...restProps}
    />
  );
};

SectionPanelMenu.propTypes = {
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
