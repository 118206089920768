import { connect } from 'react-redux';

import { selectSpeedViolationsByKey } from '../../../../data/reports';
import { SpeedViolationLocationReport } from './SpeedViolationLocationReport';

export const SpeedViolationLocationReportContainer = connect(
  (state, { speedViolationKey }) => ({
    speedViolation: selectSpeedViolationsByKey(state, speedViolationKey),
  }),
  {}
)(SpeedViolationLocationReport);
