import produce from 'immer';

import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchCompanionApps: 'administration/configurations/companionApps/fetchCompanionApps',
  updateCompanionApp: 'administration/configurations/companionApps/updateCompanionApps',
  createCompanionApp: 'administration/configurations/companionApps/createCompanionApps',
  deleteCompanionApp: 'administration/configurations/companionApps/deleteCompanionApps',
  fetchCompanionAppsByTenantId:
    'administration/configurations/companionApps/fetchCompanionAppsByTenantId',
};

export const companionAppsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCompanionApps)) {
      draft.data = payload;
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.createCompanionApp,
        ACTION_TYPE.updateCompanionApp,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteCompanionApp)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchCompanionApps)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchCompanionApps)) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchCompanionAppsByTenantId)) {
      draft.data = sortBy(payload, 'name');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchCompanionAppsByTenantId)) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchCompanionAppsByTenantId)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
