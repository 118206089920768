import { connect } from 'react-redux';
import { InspectionDetailReport } from './InspectionDetailReport';

import {
  selectInspectionById,
  fetchInspectionOptimized,
  updateInspectionDefectAndNote,
  addInspectionNote,
} from '../../../../data/reports';

import { selectSystemUser } from '../../../../data/system';

export const InspectionDetailReportContainer = connect(
  (state, { inspectionId }) => ({
    inspection: selectInspectionById(state, inspectionId),
    user: selectSystemUser(state),
  }),
  {
    fetchInspection: fetchInspectionOptimized,
    updateInspectionDefectAndNote,
    addInspectionNote,
  }
)(InspectionDetailReport);
