import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, Form, SelectInput, TextInput } from 'stti-react-common';
import { sortBy, filter, intersection } from 'lodash';

import { customPropTypes } from '../../../../helpers/customPropTypes';

const { useFormController, Control } = Form;

export const AssignLogDutyStatusEventDialog = ({
  isOpen,
  onClose,
  onConfirm,
  user,
  unidentifiedLog,
  driverUsers,
  dutyIntervalKeys,
}) => {
  // const { driverDetails } = unidentifiedLog; // TODO: prioritize these drivers as most likely for assignment

  const form = useFormController({
    controls: {},
  });

  const { resetControls } = form;

  useEffect(() => {
    resetControls();
  }, [unidentifiedLog]);
  const { controls } = form;

  const driverOptions = useMemo(() => {
    return updateDriverList(false);
  }, [unidentifiedLog, driverUsers]);

  const { hasErrors } = form;

  function updateDriverList(filterOnlyActive) {
    // filter for OU overlap
    const ouDrivers = filter(driverUsers, (driverUser) =>
      intersection(
        driverUser.memberships.map(({ ou }) => ou),
        unidentifiedLog.ous.map(({ key }) => key),
      )
    );

    if (filterOnlyActive) {
      ouDrivers = ouDrivers.filter({ active: true })
    }

    const sortedOuDrivers = sortBy(ouDrivers, 'fullName');

    const newDriverOptions = sortedOuDrivers.map(({ fullName, key }) => ({
      label: fullName,
      value: key,
    }));

    return newDriverOptions;
  }

  const handleConfirm = () => {
    const { toDriverKey, movementType, annotation } = controls;

    onConfirm({
      dutyIntervalKeys,
      logKey: unidentifiedLog.key,
      toDriverKey,
      movementType,
      annotation: `*${annotation} [${user.fullName}]`,
    });
  };

  return (
    <BasicDialog
      className="UnidentifiedLogDetailReport__AssignLogDutyStatusEventDialog"
      isOpen={isOpen}
      onClose={onClose}
      title="Assign Duty Status Change(s)"
      maxWidth="sm"
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Assign Driver',
          disabled: hasErrors,
          onClick: () => {
            handleConfirm();
            onClose();
          },
        },
      ]}
    >
      <Form form={form}>
        <span>
          Any assigned Duty Statuses will need to be approved by the specified driver and may be
          rejected.
        </span>
        <Control
          Component={SelectInput}
          name="showOnlyActiveDrivers"
          label="Filter Drivers"
          options={[{ label: 'All Drivers', value: false }, 
                    { label: 'Only Active Drivers', value: true }]}
          error={({ value }) => !value}
          defaultValue="false"
          onSet={({ label, value }) => {
            updateDriverList(value);
          }}
        />
        <Control
          Component={SelectInput}
          name="toDriverKey"
          label="Assign to"
          options={driverOptions}
          error={({ value }) => !value}
        />
        <Control
          Component={SelectInput}
          name="movementType"
          label="Driving State"
          options={[{ label: 'Driving', value: 'DRIVING' }, 
                    { label: 'Personal Conveyance', value: 'PERSONAL_USE' }, 
                    { label: 'Yard Moves', value: 'YARD_MOVEMENT' }]}
          error={({ value }) => !value}
          defaultValue="DRIVING"
        />
        <Control
          Component={TextInput}
          name="annotation"
          label="Remarks"
          error={({ value }) => {
            if (!value) return true;
            if (value.length < 4) return 'Remarks must be at least 4 characters';
            if (value.length > 60) return 'Remarks cannot be more than 60 characters';
            return false;
          }}
        />
      </Form>
    </BasicDialog>
  );
};

AssignLogDutyStatusEventDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  dutyIntervalKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  driverUsers: customPropTypes.users.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  unidentifiedLog: customPropTypes.detailedUnidentifiedLog.isRequired,
};
