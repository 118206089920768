import React, { useMemo } from 'react';
import { ToggleInput, Chip } from 'stti-react-common';
import { sortBy, uniqBy } from 'lodash';

import { ReportControl, SelectFilterInput } from '../../../commons/ReportsCommon';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';

import { customPropTypes } from '../../../../helpers/customPropTypes';

export const FiltersPanel = ({ rows }) => {
  const geozones = sortBy(
    uniqBy(
      rows.map(({ geozoneName, geozoneKey }) => ({
        key: geozoneKey,
        name: geozoneName,
      })),
      'key'
    ),
    'name'
  );
  const geozoneTypes = sortBy(
    uniqBy(
      rows.map(({ geozoneTypeName, geozoneTypeKey }) => ({
        key: geozoneTypeKey,
        name: geozoneTypeName,
      })),
      'key'
    ),
    'name'
  );

  const getOptions = useMemo(
    () => ({
      geozoneNames: () => geozones.map(({ key, name }) => ({ label: name, value: key })),
      geozoneTypes: () => geozoneTypes.map(({ key, name }) => ({ label: name, value: key })),
    }),
    [rows]
  );
  return (
    <>
      <SelectFilterInput
        name="geozoneNames"
        field="geozoneKey"
        displayValue="geozoneName"
        group="filters"
        filterGroup="filters"
        label="Geozone"
        summaryPrefix="Geozone: "
        itemPlural="geo zones"
        autoGridArea
        getOptions={getOptions.geozoneNames}
      />
      <SelectFilterInput
        name="geozoneTypes"
        field="geozoneTypeKey"
        displayValue="geozoneTypeName"
        group="filters"
        filterGroup="filters"
        label="Type"
        summaryPrefix="Type: "
        itemPlural="types"
        autoGridArea
        getOptions={getOptions.geozoneTypes}
      />
      <ReportControl
        render={(props) => <ToggleInput {...props} />}
        renderSummary={(renderProps, { value }) =>
          value && <Chip {...renderProps} label="Show system Geo Zones" />
        }
        name="showSystemZones"
        group="filters"
        label="Show System Geo Zones"
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => (!value ? !row.systemDefault : row),
          type: filterRowsByPredicate,
        }}
        autoGridArea
      />
    </>
  );
};

FiltersPanel.propTypes = {
  rows: customPropTypes.geozoneSummaries.isRequired,
};
