import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon } from 'stti-react-common';

import { Feature } from '../../../../commons/Feature';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

export const ActivationCodesSummary = ({
  activationCodes,
  fetchActivationCodes,
  deleteActivationCode,
  createActivationCode,
  route,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    fetchActivationCodes().then(() => {
      setLoading(false);
    });
  }, []);

  // RENDER
  return (
    <Feature.Wrapper className="ActivationCodesSummary">
      <Feature.Header
        onClose={route && route.close}
        title="Activation Codes"
        service="activationCodes"
      >
        <Button
          icon="add"
          variant="contained"
          label="Add Activation Code"
          onClick={() => {
            createActivationCode();
          }}
        />
      </Feature.Header>

      <div className="ActivationCodesSummary__wrapper">
        <div className="ActivationCodesSummary__grid">
          {activationCodes.map((activationCode) => (
            <Card key={activationCode.key}>
              <Icon icon="nfc" className="ActivationCodesSummary__card--mainIcon" />
              <h4>{activationCode.code}</h4>
              <Button
                icon="delete"
                className="ActivationCodesSummary__card--deleteButton"
                onClick={() => {
                  deleteActivationCode({ key: activationCode.key });
                }}
              />
            </Card>
          ))}
          {activationCodes.length === 0 && !loading && (
            <div className="ActivationCodesSummary__noItemsContainer">
              <span>No Activation Codes Found </span>
            </div>
          )}
        </div>
      </div>
    </Feature.Wrapper>
  );
};

ActivationCodesSummary.propTypes = {
  activationCodes: customPropTypes.activationCodes.isRequired,
  fetchActivationCodes: PropTypes.func.isRequired,
  deleteActivationCode: PropTypes.func.isRequired,
  createActivationCode: PropTypes.func.isRequired,
  route: PropTypes.object,
};
