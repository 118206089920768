import { useEffect } from 'react';
import { connect } from 'react-redux';

import { useReport } from '../useReport';

import { location, locationType } from '../../../../data/administration/resources';

export const FetchNaviMapData = connect(undefined, {
  fetchGeozones: location.actions.fetchLocationsOptimized,
  fetchGeozoneTypes: locationType.actions.fetchLocationTypesOptimized,
})(({ fetchGeozones, fetchGeozoneTypes }) => {
  const { registerFetch, fetchDependency } = useReport();

  useEffect(() => registerFetch(fetchGeozones()), [fetchDependency]);
  useEffect(() => registerFetch(fetchGeozoneTypes()), [fetchDependency]);

  return null;
});
