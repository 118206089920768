import { columnDef } from '../../../commons/ControlledDataGrid';
import { NA_VALUE } from '../../../../helpers/formats';
import { formatDutyStatusCodeToFriendly } from '../../../../helpers/reports/driverLogsUtils';

const formatUnidentifiedDutyStatusCodeToFriendly = ({ value }) => {
  if (value === 'OFF_DUTY') return 'Off Duty / Known Driver';
  return formatDutyStatusCodeToFriendly(value);
};

export const columnDefs = [
  {
    headerName: 'Event',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Time',
        variant: 'datelessTime',
        enableRowGroup: false,
      }),
      columnDef.iconColumn({
        field: 'map',
        headerName: 'Map',
        width: 80,
        cellRenderer: 'Mapper',
        enableRowGroup: false,
      }),
      columnDef.textColumn({
        field: 'dutyStatus',
        headerName: 'Status',
        valueFormatter: formatUnidentifiedDutyStatusCodeToFriendly,
        dataExportProcessCellCallback: formatUnidentifiedDutyStatusCodeToFriendly,
        enableRowGroup: false,
      }),
      columnDef.textColumn({
        field: 'geoLocation', // TODO: should this be 'locationName' or 'geoLocationName'?
        headerName: 'Location',
        enableRowGroup: false,
      }),
      columnDef.textColumn({
        field: 'location',
        headerName: 'Latitude/Longitude',
        valueFormatter: ({ value }) =>
          (value && `${value.latitude}, ${value.longitude}`) || NA_VALUE,
        enableRowGroup: false,
      }),
      columnDef.distanceColumn({
        field: 'accumulatedVehicleDistance',
        headerName: 'Distance',
        enableRowGroup: false,
      }),
      columnDef.durationColumn({
        field: 'elapsedEngineHours',
        headerName: 'Engine Hours',
        overrideFormat: 'h.0',
        enableRowGroup: false,
      }),
    ],
  },
  {
    headerName: 'Driver',
    children: [
      columnDef.textColumn({
        field: 'driverName',
        headerName: 'Assigned To',
        enableRowGroup: false,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'annotation',
        headerName: 'Remarks',
        enableRowGroup: false,
      }),
    ],
  },
];
