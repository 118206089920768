import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
  }),
  columnDef.textColumn({
    field: 'accessType',
    headerName: 'Access Type',
    minWidth: 150,
  }),
  columnDef.textColumn({
    field: 'creatorName',
    headerName: 'Creator',
    minWidth: 150,
  }),
  columnDef.dateColumn({
    field: 'creationDate',
    minWidth: 150,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions',
  }),
];
