import { filter } from 'lodash';
import moment from 'moment-timezone';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../helpers/moment';
import { makeRestApiAction, makeDateRangeOptimizedAction } from '../apiActionSupport';
import { ACTION_TYPE } from './speedViolationsReducer';
import { selectSpeedViolationsFetches } from './speedViolationsSelectors';

const fetchSpeedViolations = makeRestApiAction({
  service: 'speedViolations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchSpeedViolations,
  transformOutput: (responseData, { ouKey, timeZone }) => {
    const formatted = [];
    responseData.forEach(({ violations, start, end, ...rest }) => {
      violations.forEach((violation) => {
        const violationStart = violation.start;
        const violationEnd = moment(violation.start).add(violation.duration, 'seconds').format();
        formatted.push({
          ouKey,
          startedAt: violationStart,
          endedAt: violationEnd,
          timeZone,
          duration: moment.duration(moment(violationEnd).diff(moment(violationStart))).asSeconds(),
          ...rest,
          ...violation,
        });
      });
    });
    return formatted;
  },
  notificationsItemDescriptor: 'speedViolations',
});

// fetchSpeedViolationsOptimized({ startDate, endDate, ouKey, timeZone })
export const fetchSpeedViolationsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectSpeedViolationsFetches,
  filterFetches: (fetches, { ouKey }) => filter(fetches, { ouKey }),
  baseActionType: ACTION_TYPE.fetchSpeedViolations,
  fetchAction: fetchSpeedViolations,
  fetchActionConfig: {
    transformInput: ({ startDate, endDate, ouKey, timeZone }) => ({
      start: startDateWithTimeZoneToIso(startDate, timeZone),
      end: endDateWithTimeZoneToIso(endDate, timeZone),
      ou: ouKey,
      type: 'speedtracking.SpeedViolationReport',
    }),
  },
});
