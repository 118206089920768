import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { flatten, sortBy, uniq, intersection, isEmpty, uniqBy } from 'lodash';

import {
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  SelectFilterInput,
} from '../../../../../commons/ReportsCommon';

import { filterRowsByPredicate } from '../../../../../../helpers/reports/standardFilters';

export const FiltersPanel = ({ rows, group, filterGroup }) => {
  // FILTERS SETUP START -----------------------------------------------------------
  const drivers = uniqBy(
    flatten(rows.map((row) => flatten(row.drivers.map((driver) => driver)))),
    'key'
  );
  const fuelTypes = uniq(flatten(rows.map((row) => row.fuelType)));
  const fuelVendors = uniq(flatten(rows.map((row) => row.fuelVendor)));
  const organizations = uniqBy(
    flatten(rows.map((row) => row && !isEmpty(row.ou) && row.ou)),
    'key'
  );
  const vehicles = uniqBy(
    flatten(rows.map((row) => row && !isEmpty(row.vehicle) && row.vehicle)),
    'key'
  );
  const vehicleTypes = uniq(
    flatten(rows.map((row) => row && !isEmpty(row.vehicle) && row.vehicle.typeName))
  );
  const jurisdictionStates = uniq(flatten(rows.map((row) => row.jurisdictionState)));

  const getOptions = useMemo(
    () => ({
      drivers: () =>
        sortBy(
          drivers.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      fuelTypes: () =>
        sortBy(
          fuelTypes.map((item) => ({ label: item, value: item })),
          'label'
        ),
      fuelVendors: () =>
        sortBy(
          fuelVendors.map((item) => ({ label: item, value: item })),
          'label'
        ),
      organizations: () =>
        sortBy(
          organizations.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      vehicles: () =>
        sortBy(
          vehicles.map(({ key, name }) => ({ label: name, value: key })),
          'label'
        ),
      vehicleTypes: () =>
        sortBy(
          vehicleTypes.map((item) => ({ label: item, value: item })),
          'label'
        ),
      jurisdictionStates: () =>
        sortBy(
          jurisdictionStates.map((item) => ({ label: item, value: item })),
          'label'
        ),
    }),
    [rows]
  );

  return (
    <SectionPanel
      name="filtersPanel"
      title="Segment Filters"
      renderSummaries={() => <ControlGroupSummary group={group} />}
      summaryEnd={<SectionPanelMenu group={group} />}
    >
      <SelectFilterInput
        name="organizationName"
        field="ou.key"
        displayValue="ou.name"
        group={group}
        filterGroup={filterGroup}
        label="Organizations"
        summaryPrefix="Organization: "
        itemPlural="organizations"
        getOptions={getOptions.organizations}
        autoGridArea
        printConfig={({ value }) => value}
      />

      <SelectFilterInput
        name="driverName"
        field="driver.key"
        displayValue="driver.name"
        group={group}
        filterGroup={filterGroup}
        label="Drivers"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        getOptions={getOptions.drivers}
        autoGridArea
        printConfig={({ value }) => value}
        filterConfig={{
          group: filterGroup,
          type: filterRowsByPredicate,
          test: ({ row, value }) =>
            intersection(
              row.drivers.map(({ key }) => key),
              value
            ).length > 0,
          disabled: ({ value }) => !value || value.length === 0,
        }}
      />

      <SelectFilterInput
        name="vehicleName"
        field="vehicle.key"
        displayValue="vehicle.name"
        group={group}
        filterGroup={filterGroup}
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        getOptions={getOptions.vehicles}
        autoGridArea
        printConfig={({ value }) => value}
      />

      <SelectFilterInput
        name="vehicleType"
        field="vehicle.typeName"
        group={group}
        filterGroup={filterGroup}
        label="Vehicles Types"
        summaryPrefix="Vehicle Type: "
        itemPlural="vehicle types"
        getOptions={getOptions.vehicleTypes}
        autoGridArea
        printConfig={({ value }) => value}
        filterConfig={{
          group: filterGroup,
          type: filterRowsByPredicate,
          test: ({ row, value }) => value.includes(row.vehicle.typeName),
          disabled: ({ value }) => !value || value.length === 0,
        }}
      />

      <SelectFilterInput
        name="fuelType"
        field="fuelType"
        group={group}
        filterGroup={filterGroup}
        label="Fuel Types"
        summaryPrefix="Fuel Type: "
        itemPlural="fuel types"
        getOptions={getOptions.fuelTypes}
        autoGridArea
        printConfig={({ value }) => value}
      />

      <SelectFilterInput
        name="jurisdictionState"
        field="fuelType"
        group={group}
        filterGroup={filterGroup}
        label="Jurisdiction"
        summaryPrefix="Jurisdiction: "
        itemPlural="jurisdictions"
        getOptions={getOptions.jurisdictionStates}
        autoGridArea
        printConfig={({ value }) => value}
        filterConfig={{
          group: filterGroup,
          type: filterRowsByPredicate,
          test: ({ row, value }) => value.includes(row.jurisdictionState),
          disabled: ({ value }) => !value || value.length === 0,
        }}
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: PropTypes.array,
  group: PropTypes.string.isRequired,
  filterGroup: PropTypes.string.isRequired,
};
