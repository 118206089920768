// EXTERNAL LIB IMPORTS
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty, isNil } from 'lodash';
import {
  Button,
  Form,
  ImageInput,
  SelectInput,
  TextInput,
  TabularInput,
  ProgressOverlay,
  Icon,
  Clickable,
} from 'stti-react-common';
import moment from 'moment-timezone';

// COMMON LIB IMPORTS
import {
  AdminFormLabel,
  AdminToggleSelectInput,
  CrudAdminItem,
} from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';
import { useFormats } from '../../../../commons/Formats';

// HELPERS LIB IMPORTS
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import {
  objectsToSelectOptions,
  getStatesByCountry,
} from '../../../../../helpers/admin/adminUtils';
import { validateName } from '../../../../../helpers/form/validations';
import { useApiError } from '../../../../../helpers/hooks';

// MISC IMPORTS
import { DRIVER_ROLE_KEY, SUPPORT_ROLE_KEYS } from '../../../../../data/system/constants';
import './detail.scss';

const unitSystem = ['Metric', 'Imperial'];
const usELDLink = 'https://www.fmcsa.dot.gov/hours-service/elds/who-exempt-eld-rule';
const cdnELDLink = 'https://ccmta.ca/en/canadian-eld-standard';

export const UserDetail = ({
  activeOu,
  countriesList,
  createUser,
  deleteUser,
  disableUsernameEdit,
  fetchCountries,
  fetchRegulations,
  fetchRoles,
  fetchUser,
  fetchUsers,
  isDriverCreate,
  mode: initialMode = 'view',
  ous,
  regulations,
  restoreUser,
  roles,
  route,
  tenantOu,
  updateUser,
  user,
  userId,
  users,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const [restoreUserObject, setRestoreUserObject] = useState({});
  const [visibility, setVisibility] = useState(false);
  const { formatUnit } = useFormats();

  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    fetchRoles();
    fetchRegulations();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (user && user.deleted) {
      setMode('restore');
    }
  }, [user]);

  useEffect(() => {
    if (initialMode === 'create') return;

    setLoading(true);
    Promise.all([fetchUsers(), fetchUser(userId)])
      .then(resetControls)
      .finally(() => {
        setLoading(false);
      });
  }, [initialMode, userId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: user
      ? {
          ...user,
          key:
            userId !== 'newSupport' || userId !== 'newDriver' || userId !== 'new'
              ? userId
              : undefined,
          driverLicenseNumber: user.driverLicenseNumber || '',
          memberships:
            user.memberships
              .filter((membership) => find(ous, (ou) => ou.key === membership.ou && ou.scope))
              .map(({ roles: membershipRoles, ...rest }) => ({
                roles: membershipRoles.map((role) => role.key),
                ...rest,
              })) || [],
          password: undefined,
        }
      : {
          memberships: isDriverCreate
            ? [{ roles: [DRIVER_ROLE_KEY] }]
            : [{ ou: ous.length === 1 ? activeOu && activeOu.key : '' }],
        },
  });

  const { controls: formControls, resetControls, setControl } = form;

  const isDriver = formControls.isDriver || isDriverCreate;

  const hasNonDriver =
    formControls.memberships &&
    formControls.memberships.some(
      ({ roles: role }) => role && role.length !== 0 && !role.includes(DRIVER_ROLE_KEY)
    );

  const availableRoles = useMemo(() => {
    if (!roles) return [];

    return isDriver
      ? roles.filter(({ key }) => key === DRIVER_ROLE_KEY)
      : roles.filter(({ key }) => SUPPORT_ROLE_KEYS.includes(key));
  }, [isDriver, roles]);

  const formTitle = useMemo(() => {
    if (isDriver) {
      return 'Driver';
    }
    return 'Support User';
  }, [isDriver]);

  useEffect(() => {
    if (
      (formControls.memberships &&
        formControls.homeTerminal &&
        !formControls.memberships.find(
          (membership) => membership.ou === formControls.homeTerminal
        )) ||
      !isDriver
    ) {
      setControl('homeTerminal', undefined);
    }
  }, [formControls.memberships]);

  useEffect(() => {
    if (formControls.licenseIssuingCountry && formControls.licenseIssuingState && form.isDirty) {
      setControl('licenseIssuingState', undefined);
    }
  }, [formControls.licenseIssuingCountry]);

  useEffect(() => {
    setControl('password', undefined);
  }, [mode]);

  useEffect(() => {
    if (ous && ous.length > 0) {
      setLoading(false);
    }
  }, [ous]);

  // This make sure that formControls don't pass to api yardMoves and personalUse props in case
  // the current user is not driver. It seems a rare race condition here we render or not render the fields
  // accordingly with role and formData retains the default value for those two props.
  const formatData = (data) => {
    const { yardMoves, personalUse, ...rest } = data;
    return isDriver ? data : rest;
  };

  if (loading) {
    return (
      <div className="UserDetail">
        <ProgressOverlay isOpen={loading} />
      </div>
    );
  }

  return (
    <Feature.Wrapper className="UserDetail">
      <CrudAdminItem
        createItem={createUser}
        data={formatData(formControls)}
        deleteItem={deleteUser}
        form={form}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        itemTitle={`${formControls.firstName} ${formControls.lastName}`}
        mode={mode}
        onApiError={setApiError}
        onClose={route.close}
        resetControls={resetControls}
        restoreItem={restoreUser}
        setMode={setMode}
        title={formTitle}
        updateItem={updateUser}
      >
        <Feature.Body>
          <div className="UserForm__Title">
            <ErrorSummary errorMsg={apiError} />
            <h4>User Information</h4>
          </div>

          <div className="UserForm__Container">
            <Control
              Component={TextInput}
              name="email"
              label="Email"
              error={({ value }) => {
                const validEmailPattern = /\S+@\S+\.\S+/;

                if (!isEmpty(restoreUserObject)) {
                  setRestoreUserObject({});
                }

                if (!value || !value.trim()) {
                  return true;
                }

                if (!validEmailPattern.test(value.toLowerCase())) {
                  return 'Please enter a valid email';
                }
                return false;
              }}
            />

            <Control
              Component={TextInput}
              name="username"
              label="Username"
              error={({ value }) => {
                const copyOfUsers = [...users];
                if (isNil(value) || isEmpty(value)) return true;
                if (value.length < 4) return 'Username needs to have more than 4 characters';
                if (value.length > 60) return 'Username needs to have less than 60 characters';
                const duplicateUser = find(copyOfUsers, { username: value });
                if (duplicateUser) {
                  if (mode === 'edit' && duplicateUser.username !== user.username) {
                    return 'There is already a user that exists with this username';
                  }
                  if (mode === 'create') {
                    if (duplicateUser.deleted) {
                      setRestoreUserObject(duplicateUser);
                      return 'A deleted user exists with this username. Would you like to restore them?';
                    }
                    return 'There is already a user that exists with this username';
                  }
                }
                return false;
              }}
              disabled={disableUsernameEdit}
              endAdornment={
                <Button
                  onClick={() => {}}
                  tooltip="If Google Sign-in service is used, the username must match the email address on Google's linked account."
                  icon="help"
                />
              }
            />

            {!isEmpty(restoreUserObject) && (
              <Button
                className="UserSummaryDetail__restoreButton"
                label="Restore User"
                icon="restore_from_trash"
                variant="contained"
                onClick={() => {
                  route.close();
                  restoreUser(restoreUserObject);
                }}
              />
            )}
            {mode !== 'edit' && mode !== 'create' && user && (
              <AdminFormLabel label="External Id" value={user && user.externalId} />
            )}
            {(mode === 'edit' || mode === 'create') && (
              <Control
                Component={TextInput}
                name="password"
                defaultValue=""
                type={visibility ? 'text' : 'password'}
                label="Password"
                error={({ value }) => (!value || !value.trim()) && mode === 'create'}
                endAdornment={
                  <Clickable onClick={() => setVisibility(!visibility)}>
                    <Icon icon={visibility ? 'visibility' : 'visibility_off'} />
                  </Clickable>
                }
              />
            )}
            <Control
              Component={TextInput}
              name="firstName"
              label="First Name"
              error={({ value }) => validateName('First', value)}
            />
            <Control
              Component={TextInput}
              name="lastName"
              label="Last Name"
              error={({ value }) => validateName('Last', value)}
            />
            <Control
              Component={AdminToggleSelectInput}
              name="enabled"
              label="User Enabled"
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Disabled', value: false },
                { label: 'Enabled', value: true },
              ]}
            />
            <Control
              Component={SelectInput}
              name="unitSystem"
              label="Units"
              defaultValue={tenantOu.unitSystem}
              options={unitSystem}
              error={({ value }) => !value}
            />
            {mode !== 'create' && user && (
              <>
                <AdminFormLabel
                  label="Registration Date"
                  value={formatUnit(
                    'date',
                    user && moment(user.registrationDate).tz(user.timeZone)
                  )}
                />
                <AdminFormLabel
                  label="Last Sign In"
                  value={formatUnit('date', user && moment(user.lastSignIn).tz(user.timeZone))}
                />
              </>
            )}
            {/* Hidden Fields- Show only if Driver */}
            {isDriver && (
              <>
                <Control
                  Component={SelectInput}
                  name="homeTerminal"
                  label="Home Terminal"
                  options={ous
                    .filter((ou) =>
                      formControls.memberships.find(
                        (membership) =>
                          membership.ou === ou.key &&
                          membership.roles &&
                          membership.roles.includes(DRIVER_ROLE_KEY)
                      )
                    )
                    .map((ou) => ({
                      label: ou.name || '',
                      value: ou.key || '',
                    }))}
                  error={({ value }) => !value}
                  endAdornment={
                    <Button
                      onClick={() => {}}
                      tooltip="Please select a user membership in panel below in order to select a Home Terminal"
                      icon="help"
                    />
                  }
                />
                <Control
                  Component={AdminToggleSelectInput}
                  name="personalUse"
                  label="Personal Use"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Disabled', value: false },
                    { label: 'Enabled', value: true },
                  ]}
                />
                <Control
                  Component={AdminToggleSelectInput}
                  name="yardMoves"
                  label="Yard Moves"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'Disabled', value: false },
                    { label: 'Enabled', value: true },
                  ]}
                />
                <Control
                  Component={SelectInput}
                  name="regulations"
                  label="Active Regulations"
                  options={objectsToSelectOptions(regulations, 'name', 'key')}
                  error={({ value }) => !value}
                  onSet={({ draft }) => {
                    if (draft.regulations && initialMode === 'create') {
                      draft.usDriving = draft.regulations.includes('US ');
                      draft.cdnDriving = !draft.regulations.includes('US ');
                    }
                  }}
                />
                <Control
                  Component={SelectInput}
                  name="usDriving"
                  label="Driving in US?"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true },
                  ]}
                  onSet={({ draft, value }) => {
                    if (!value) {
                      draft.usEldExempt = false;
                      draft.usEldExemptReason = '';
                    }
                  }}
                />
                <Control
                  Component={SelectInput}
                  name="usEldExempt"
                  label="US ELD Exempt"
                  defaultValue={false}
                  disabled={!formControls.usDriving}
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true },
                  ]}
                  endAdornment={
                    <Clickable onClick={() => window.open(usELDLink, '_blank')}>
                      <Icon icon="help" />
                    </Clickable>
                  }
                />
                {formControls.usEldExempt && (
                  <Control
                    Component={TextInput}
                    multiline
                    name="usEldExemptReason"
                    label="US Exempt Reason"
                    disabled={!formControls.usDriving}
                    error={({ value, controls }) => !value && controls.usEldExempt}
                  />
                )}
                <Control
                  Component={SelectInput}
                  name="cdnDriving"
                  label="Driving in Canada?"
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true },
                  ]}
                  onSet={({ draft, value }) => {
                    if (!value) {
                      draft.cdnEldExempt = false;
                      draft.cdnEldExemptReason = '';
                    }
                  }}
                />
                <Control
                  Component={SelectInput}
                  name="cdnEldExempt"
                  label="CDN ELD Exempt"
                  defaultValue={false}
                  disabled={!formControls.cdnDriving}
                  error={({ value }) => isNil(value)}
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true },
                  ]}
                  endAdornment={
                    <Clickable onClick={() => window.open(cdnELDLink, '_blank')}>
                      <Icon icon="help" />
                    </Clickable>
                  }
                />
                {formControls.cdnEldExempt && (
                  <Control
                    Component={TextInput}
                    multiline
                    name="cdnEldExemptReason"
                    label="CDN Exempt Reason"
                    disabled={!formControls.cdnDriving}
                    error={({ value, controls }) => !value && controls.cdnEldExempt}
                  />
                )}
                <Control
                  Component={TextInput}
                  name="driverLicenseNumber"
                  label="Driver License Number"
                  error={({ value, controls }) => {
                    if (!value && isDriver) {
                      return true;
                    }

                    if (value && value.length > 20) {
                      return 'Cannot be more than 20 characters';
                    }

                    if (!/^[a-z0-9]+$/i.test(value)) {
                      return 'Only alphanumeric characters are allowed';
                    }

                    const duplicateUser = users.find(
                      ({ driverLicenseNumber, licenseIssuingCountry, licenseIssuingState }) =>
                        driverLicenseNumber === value &&
                        licenseIssuingCountry === controls.licenseIssuingCountry &&
                        licenseIssuingState === controls.licenseIssuingState
                    );

                    if (duplicateUser) {
                      if (
                        (mode === 'edit' &&
                          duplicateUser.driverLicenseNumber !== user.driverLicenseNumber) ||
                        mode === 'create'
                      ) {
                        if (duplicateUser.deleted) {
                          return 'A deleted user exists with this driver license number';
                        }
                        return 'There is already a user that exists with this drivers license number';
                      }
                    }
                    return false;
                  }}
                />
                <Control
                  Component={SelectInput}
                  name="licenseIssuingCountry"
                  label="Driver's License Country"
                  error={({ value }) => !value}
                  options={objectsToSelectOptions(countriesList, 'name', 'code')}
                />
                <Control
                  Component={SelectInput}
                  name="licenseIssuingState"
                  label="Driver's License State/Province"
                  options={objectsToSelectOptions(
                    getStatesByCountry(countriesList, formControls.licenseIssuingCountry),
                    'name',
                    'code',
                    false
                  )}
                  disabled={!formControls.licenseIssuingCountry}
                  error={({ value, controls }) => !value && !!controls.licenseIssuingCountry}
                />
              </>
            )}
            <Control
              Component={ImageInput}
              name="signature"
              label={
                formControls.signature && mode === 'edit'
                  ? 'Signature: Drop image below to upload'
                  : 'Signature'
              }
              dropHint="Drop image here to upload"
              maxKb={10}
            />
          </div>

          <div className="TabularInput__wrapper">
            <Control
              Component={TabularInput}
              renderHeader={({ addRow }) => (
                <div className="TabularInput__title">
                  <h4>Memberships</h4>
                  {mode !== 'view' && mode !== 'restore' && (
                    <Button variant="outlined" label="Add Memberships" onClick={addRow} />
                  )}
                </div>
              )}
              renderRow={({ deleteRow }) => (
                <>
                  <Control
                    className="TabularInput__Organization"
                    Component={SelectInput}
                    name="ou"
                    label="Organization"
                    defaultValue={ous.length === 1 ? ous[0].key : ''}
                    options={objectsToSelectOptions(ous, 'name', 'key')}
                    error={({ value }) => !value}
                    readOnly={mode === 'view' || mode === 'restore'}
                  />
                  <Control
                    Component={SelectInput}
                    multiple
                    name="roles"
                    label="Roles"
                    defaultValue={isDriver && [DRIVER_ROLE_KEY]}
                    multipleCollapseAt={3}
                    multipleCollapseText="roles"
                    options={objectsToSelectOptions(availableRoles, 'name', 'key')}
                    error={({ value }) =>
                      value &&
                      ((value.length > 1 && value.includes(DRIVER_ROLE_KEY)) ||
                        (hasNonDriver && isDriver))
                        ? 'A driver can only be a driver'
                        : !value || value.length === 0
                    }
                    readOnly={mode === 'view' || mode === 'restore' || isDriver}
                  />
                  {(mode === 'edit' || mode === 'create') && (
                    <Button
                      variant="outlined"
                      label="Delete Row"
                      icon="clear"
                      onClick={deleteRow}
                      disabled={formControls.memberships.length < 2}
                    />
                  )}
                </>
              )}
              name="memberships"
              ownErrorName="membershipTable"
            />
          </div>
        </Feature.Body>
      </CrudAdminItem>
    </Feature.Wrapper>
  );
};

UserDetail.propTypes = {
  activeOu: customPropTypes.organization,
  countriesList: PropTypes.array,
  createUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  disableUsernameEdit: PropTypes.bool.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchRegulations: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  isDriverCreate: PropTypes.bool,
  mode: PropTypes.oneOf(['view', 'edit', 'update', 'restore', 'create']),
  ous: customPropTypes.organizations.isRequired,
  regulations: PropTypes.array,
  restoreUser: PropTypes.func.isRequired,
  roles: customPropTypes.roles,
  route: PropTypes.object.isRequired,
  tenantOu: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: customPropTypes.user,
  userId: PropTypes.string,
  users: customPropTypes.users.isRequired,
};
