import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import { administration } from './administration';

import { reportsReducer as reports } from './reports';
import { systemReducer as system } from './system';
import { notificationsReducer as notifications } from './notifications';
import { requestsReducer as requests } from './requests';
import { messengerReducer as messenger } from './messenger/reducer';
import { fleetTrackReducer as tracking } from './tracking/reducer';

const ACTION_TYPE = {
  reset: 'reset',
};

const rootReducer = (state, action) =>
  combineReducers({
    administration,
    reports,
    system,
    notifications,
    requests,
    messenger,
    tracking,
  })(action.type === ACTION_TYPE.reset ? undefined : state, action);

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const resetStore = () => store.dispatch({ type: ACTION_TYPE.reset });
