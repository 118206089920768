import { connect } from 'react-redux';

import { PrintLayout } from './PrintLayout';
import { selectPrintMode } from '../../../data/system';

const mapStateToProps = (state) => ({
  printMode: selectPrintMode(state),
});

export const PrintLayoutContainer = connect(mapStateToProps)(PrintLayout);
