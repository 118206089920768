import React from 'react';
import PropTypes from 'prop-types';

import { SelectInput, Clickable } from 'stti-react-common';

export const AdminClickableOrSelectInput = ({
  value,
  label,
  readOnly,
  onClickableClick,
  options,
  ...restProps
}) =>
  !readOnly ? (
    <SelectInput
      value={value}
      label={label}
      options={options}
      className="AdminClickableOrSelectInput AdminClickableOrSelectInput--selectable"
      {...restProps}
    />
  ) : (
    <SelectInput
      value={value}
      label={label}
      options={options}
      className={`AdminClickableOrSelectInput ${
        onClickableClick
          ? 'AdminClickableOrSelectInput--clickable'
          : 'AdminClickableOrSelectInput--disabled'
      }`}
      readOnly
      renderValue={() => {
        if (!value) {
          return '—';
        }
        if (!onClickableClick) {
          return SelectInput.labelForValue(options, value);
        }
        return (
          <Clickable onClick={() => onClickableClick(value)}>
            {SelectInput.labelForValue(options, value)}
          </Clickable>
        );
      }}
      {...restProps}
    />
  );

AdminClickableOrSelectInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  onClickableClick: PropTypes.func,
  options: PropTypes.array.isRequired,
};
