import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

import { ApiError } from '../ApiError';
import { bindServices } from './services';

export class RestApi {
  constructor({ baseUrl, legacyBaseUrl, originUrl, accessToken }) {
    this._baseUrl = baseUrl;
    this._legacyBaseUrl = legacyBaseUrl;
    this._originUrl = originUrl || window.location.origin;

    this.instance = axios.create({
      baseURL: this._baseUrl,
      timeout: 60 * 1000, // 60 seconds
      headers: { authorization: `Bearer ${accessToken}` },
      paramsSerializer: qs.stringify,
    });

    bindServices.apply(this);
  }

  get originUrl() {
    return this._originUrl;
  }

  async _request(config) {
    try {
      const { data } = await this.instance.request(config);
      return data;
    } catch (err) {
      return this._handleError(err);
    }
  }

  async _pagedGetRequest({ url, params, onProgress, ...restConfig }) {
    try {
      let completeData = [];
      let nextUrl = `${url}?${qs.stringify({ ...params })}`;
      while (nextUrl) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.instance.request({
          ...restConfig,
          method: 'get',
          url: nextUrl,
        });
        const { data: pageData, paging } = data;
        const { offset, count, limit: pageLimit, nextPage } = paging;

        const nextPageUrl =
          nextPage ||
          (pageLimit === count
            ? `${url}?${qs.stringify({ ...params, offset: offset + count, limit: pageLimit })}`
            : undefined);

        completeData = completeData.concat(pageData);
        const metadata = {
          progress: offset + count,
          // 'total' to be added once supported by API
        };
        if (onProgress) onProgress(pageData, metadata);
        nextUrl = nextPageUrl;
      }
      return completeData;
    } catch (err) {
      return this._handleError(err);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _handleError(err) {
    const { response, code, request, status } = err;
    const serializableError = JSON.parse(JSON.stringify(err)); // pre-scrub to serializable object for use in webclientViewError
    if (response) {
      // HTTP error
      const message =
        get(response, 'data.message') || // old APIs
        get(response, 'data.error.message') || // new APIs
        response.statusText || // HTTP response text
        'Unknown Error';
      throw new ApiError({
        status: response.status,
        message,
        error: serializableError,
      });
    }
    if (code === 'ECONNABORTED') {
      // Axios request timeout
      throw new ApiError({
        status: ApiError.STATUS.timedOut,
        message: 'Request Timed Out',
        error: serializableError,
      });
    }
    if (request && !status) {
      // Request without status should mean network error like no internet, or CORS failure
      throw new ApiError({
        status: ApiError.STATUS.networkError,
        message: 'Network Error',
        error: serializableError,
      });
    }
    // not a known ApiError: re-throw error
    throw err;
  }

  _standardRestEndpoint = ({ url, pagedGet = false }) => ({
    get: ({ id = '', data, onProgress }) =>
      !id && pagedGet
        ? this._pagedGetRequest({ url, params: data, onProgress })
        : this._request({ method: 'get', url: `${url}/${id}`, params: data }),
    put: ({ id = '', data }) => this._request({ method: 'put', url: `${url}/${id}`, data }),
    patch: ({ id = '', data }) => this._request({ method: 'patch', url: `${url}/${id}`, data }),
    post: ({ id = '', data }) => this._request({ method: 'post', url: `${url}/${id}`, data }),
    delete: ({ id = '', data }) =>
      this._request({ method: 'delete', url: `${url}/${id}`, params: data }),
  });
}
