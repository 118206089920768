import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchApiKeys: 'admin/apiKeys/fetchApiKeys',
  deleteApiKey: 'admin/apiKeys/deleteApiKey',
  createApiKey: 'admin/apiKeys/createApiKey',
};

export const apiKeysReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchApiKeys)) {
      draft.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.createApiKey])) {
      const payloadCopy = { ...payload };
      delete payloadCopy.apiKey;
      draft.data = shallowMergeEntities(state.data, payloadCopy, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteApiKey)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchApiKeys)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchApiKeys)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
