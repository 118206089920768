/* eslint-disable react/prop-types */
// EXTERNAL LIB IMPORTS
import React from 'react';
import { Button, Clickable } from 'stti-react-common';

// COMMON LIB IMPORTS
import { ToggleChip } from '../../../../commons/ToggleChip';

// HELPERS LIB IMPORTS
import { NA_VALUE } from '../../../../../helpers/formats';

// MISC IMPORTS
import { FEATURES } from '../../../../../features';

export const dataGridDefs = ({
  openCameraDetail,
  openVehicleDetail,
  setTargetRow,
  openDeleteDialog,
  openVehicleTypeDetail,
  openOrganizationDetail,
}) => ({
  Actions: ({
    data,
    agGridReact: {
      props: { rowData },
    },
  }) =>
    !data.deleted && (
      <>
        {!FEATURES.admin.hideCameraMethods && rowData.some((row) => row.cameraProvider) && (
          <Button
            disabled={!data.cameraProvider}
            icon="videocam"
            onClick={() => openCameraDetail({ cameraId: data.cameraId, mode: 'view' })}
          />
        )}
        <Button
          onClick={() => openVehicleDetail({ assetId: data?.key, mode: 'edit' })}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetRow(data);
            openDeleteDialog();
          }}
          icon="delete"
        />
      </>
    ),
  Asset: ({ data, value }) =>
    value ? (
      <Clickable onClick={() => openVehicleDetail({ assetId: data?.key, mode: 'view' })}>
        {value}
      </Clickable>
    ) : (
      '—'
    ),
  AssetType: ({ data, value }) =>
    value ? (
      <Clickable
        onClick={() => openVehicleTypeDetail({ assetTypeId: data?.assetType?.key, mode: 'view' })}
      >
        {value}
      </Clickable>
    ) : (
      '—'
    ),
  ToggleChip: ({ value }) => <ToggleChip value={value} />,
  ToggleEngineYear: ({ data, value }) =>
    data?.assetType?.typeClass === 'Vehicle' ? (
      <ToggleChip value={value} trueLabelText="Yes" falseLabelText="No" />
    ) : (
      NA_VALUE
    ),
  ToggleActive: ({ value }) => (
    <ToggleChip value={value} trueLabelText="Active" falseLabelText="Inactive" />
  ),
  ToggleVinAutoDetect: ({ value }) => (
    <ToggleChip value={value} trueLabelText="Yes" falseLabelText="No" />
  ),
  ManagedBy: ({ data, value }) =>
    value ? (
      <Clickable
        onClick={() =>
          openOrganizationDetail({ organizationId: data?.managedBy?.key, mode: 'view' })
        }
      >
        {value}
      </Clickable>
    ) : (
      '—'
    ),
});
