import React from 'react';
import { DutyStatusChart } from 'stti-react-common';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { useFormats } from '../../../commons/Formats';

export const UnidentifiedLogDutyStatusChart = ({ unidentifiedLog, ...restProps }) => {
  const { formatUnit } = useFormats();

  const { onDutyNotDrivingTotal, onDutyDrivingTotal, offDutySleeperBerthTotal } = unidentifiedLog;

  const chartTotals = offDutySleeperBerthTotal + onDutyDrivingTotal + onDutyNotDrivingTotal;

  return (
    <DutyStatusChart
      startDate={unidentifiedLog.startedAt}
      endDate={unidentifiedLog.endedAt}
      statusTotals={{
        offDuty: formatUnit('duration', 0),
        sleeper: formatUnit('duration', offDutySleeperBerthTotal),
        driving: formatUnit('duration', onDutyDrivingTotal),
        onDuty: formatUnit('duration', onDutyNotDrivingTotal),
        total: formatUnit('duration', chartTotals),
      }}
      labels={{
        offDuty: 'OFF DUTY',
        sleeper: 'SLEEPER',
        driving: 'DRIVING',
        onDuty: 'ON DUTY',
        yardMovement: 'YM',
        personalUse: 'PC',
      }}
      {...restProps}
    />
  );
};

UnidentifiedLogDutyStatusChart.propTypes = {
  unidentifiedLog: customPropTypes.unidentifiedLog.isRequired,
};
