import { connect } from 'react-redux';

import { SystemSettingSummary } from './summary';
import {
  selectSystemTenantOu,
  selectSystemTenant,
  fetchCurrentTenant,
  selectIsUserTenantSuperAdmin,
} from '../../../../../data/system';

import { organization, tenant } from '../../../../../data/administration/configurations';

export const SystemSettingSummaryContainer = connect(
  (state) => ({
    tenant: selectSystemTenant(state),
    ouTenant: selectSystemTenantOu(state),
    isTenantSuperAdmin: selectIsUserTenantSuperAdmin(state),
  }),
  {
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    fetchCurrentTenant,
    updateOrganization: organization.actions.updateOrganization,
    updateOrganizationPerformancePoints: organization.actions.updateOrganizationPerformancePoints,
    updateTenant: tenant.actions.updateTenant,
  }
)(SystemSettingSummary);
