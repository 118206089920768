import React from 'react';
import { Button } from 'stti-react-common';

export const AddExceptionRuleButton = (props) => (
  <Button
    className="Report__AddExceptionRuleButton"
    icon="add"
    label="Add Exception Rule"
    {...props}
  />
);
