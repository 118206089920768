import React from 'react';
import PropTypes from 'prop-types';
import { DialogDrivenInput } from 'stti-react-common';

import { ControlGroupSummary, useControlGroupHasSummary } from './ControlGroupSummary';

const style = { gridArea: 'outlierFilters' };

export const OutlierFiltersDialogInput = ({
  className = '',
  group = 'outliers',
  children,
  ...restProps
}) => {
  const hasValue = useControlGroupHasSummary(group);
  return (
    <DialogDrivenInput
      className={`Report__OutlierFiltersDialogInput ${className}`}
      label="Additional Filters"
      hasValue={hasValue}
      renderValue={() => <ControlGroupSummary group={group} />}
      style={style}
      {...restProps}
    >
      {children}
    </DialogDrivenInput>
  );
};

OutlierFiltersDialogInput.propTypes = {
  className: PropTypes.string,
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node.isRequired,
};
