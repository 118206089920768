import { connect } from 'react-redux';

import { ReportSchedulesAdmin } from './ReportSchedulesAdmin';
import { selectSystemUser } from '../../../data/system';
import {
  selectReportSchedules,
  deleteReportSchedule,
  fetchReportSchedulesByUserKey,
} from '../../../data/reports';

export const ReportSchedulesAdminContainer = connect(
  (state) => ({
    reportSchedules: selectReportSchedules(state),
    user: selectSystemUser(state),
  }),
  {
    deleteReportSchedule,
    fetchReportSchedulesByUserKey,
  }
)(ReportSchedulesAdmin);
