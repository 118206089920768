import React, { useMemo } from 'react';
import { ToggleInput, Chip } from 'stti-react-common';
import { isNil, sortBy, uniq, uniqBy } from 'lodash';

import { customPropTypes } from '../../../../helpers/customPropTypes';

import {
  ReportControl,
  OutlierFilterInput,
  SelectFilterInput,
} from '../../../commons/ReportsCommon';
import {
  filterRowsByBaselineRange,
  filterRowsByPredicate,
} from '../../../../helpers/reports/standardFilters';

export const FiltersPanel = ({ rows }) => {
  const vehicles = sortBy(
    uniqBy(
      rows.map(({ vehicleKey, vehicleId, vehicleOuKey }) => ({
        key: vehicleKey,
        name: vehicleId,
        ou: vehicleOuKey,
      })),
      'key'
    ),
    'name'
  );

  const drivers = sortBy(
    uniqBy(
      rows.map(({ driverKey, driverName }) => ({
        key: driverKey,
        name: driverName,
      })),
      'key'
    ),
    'name'
  );

  const getOptions = useMemo(
    () => ({
      vehicleNames: () => vehicles.map(({ key, name }) => ({ label: name, value: key })),
      driverNames: () => drivers.map(({ key, name }) => ({ label: name, value: key })),
    }),
    [rows]
  );

  return (
    <>
      <SelectFilterInput
        name="vehicleNames"
        field="vehicleKey"
        displayValue="vehicleId"
        group="filters"
        filterGroup="filters"
        label="Vehicle"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
      />
      <SelectFilterInput
        name="driverNames"
        field="driverKey"
        displayValue="driverName"
        group="filters"
        filterGroup="filters"
        label="Driver"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverNames}
      />
      <OutlierFilterInput
        name="hideRelativeDurationGreaterThan"
        label="Hide Total Duration greater than"
        summaryPrefix="Hide: Total Duration > "
        filterConfig={{
          group: 'outlier',
          field: 'duration',
          type: filterRowsByBaselineRange,
          unitType: 'relative',
          disabled: ({ value }) => !value,
          getValues: ({ controls, filteredRows }) => ({
            max: controls.hideRelativeDurationGreaterThanX,
            baselineRows: filteredRows.query,
          }),
        }}
        autoGridArea
      />
      <OutlierFilterInput
        name="hideRelativeDistanceGreaterThan"
        label="Hide Distance Driven greater than"
        summaryPrefix="Hide: Distance Driven > "
        filterConfig={{
          group: 'outlier',
          field: 'distanceDriven',
          type: filterRowsByBaselineRange,
          unitType: 'relative',
          disabled: ({ value }) => !value,
          getValues: ({ controls, filteredRows }) => ({
            max: controls.hideRelativeDistanceGreaterThanX,
            baselineRows: filteredRows.query,
          }),
        }}
        autoGridArea
      />
      <OutlierFilterInput
        name="hideRelativeFuelConsumedGreaterThan"
        label="Hide Fuel Consumed greater than"
        summaryPrefix="Hide: Fuel Consumed > "
        filterConfig={{
          group: 'outlier',
          field: 'fuelConsumed',
          type: filterRowsByBaselineRange,
          unitType: 'relative',
          disabled: ({ value }) => !value,
          getValues: ({ controls, filteredRows }) => ({
            max: controls.hideRelativeFuelConsumedGreaterThanX,
            baselineRows: filteredRows.query,
          }),
        }}
        autoGridArea
      />
      <ReportControl
        render={(props) => <ToggleInput {...props} />}
        renderSummary={(renderProps, { value }) =>
          value && <Chip {...renderProps} label="Hide records without a Driver Name" />
        }
        name="hideNamelessDrivers"
        group="filters"
        label="Hide records without a driver"
        filterConfig={{
          group: 'filters',
          test: ({ row }) => !isNil(row.driverName),
          type: filterRowsByPredicate,
          disabled: ({ value }) => !value,
        }}
        autoGridArea
        defaultValue
      />
    </>
  );
};

FiltersPanel.propTypes = {
  rows: customPropTypes.geozoneActivities.isRequired,
};
