import { connect } from 'react-redux';

import { selectInspectionById, fetchInspectionOptimized } from '../../../../data/reports';
import { InspectionLocationReport } from './InspectionLocationReport';

export const InspectionLocationReportContainer = connect(
  (state, { inspectionId }) => ({
    inspection: selectInspectionById(state, inspectionId),
  }),
  {
    fetchInspection: fetchInspectionOptimized,
  }
)(InspectionLocationReport);
