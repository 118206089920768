import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../apiReducerSupport';

const initialState = {
  data: { summary: [], detail: [] },
  fetches: { summary: [], detail: [] },
};

export const ACTION_TYPE = {
  fetchPerformances: 'reports/performances/fetchPerformances',
  fetchPerformanceDetails: 'reports/performances/fetchPerformanceDetails',
  fetchMyPerformances: 'reports/performances/fetchMyPerformances',
};

export const performancesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchPerformances,
        ACTION_TYPE.fetchMyPerformances,
      ])
    ) {
      draft.data.summary = sortBy(shallowMergeEntities(state.data.summary, payload, 'key'), [
        'shiftNumber',
        'driverName',
      ]);
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchPerformances])) {
      const { id, actionParam } = request;
      draft.fetches.summary = shallowMergeEntities(state.fetches.summary, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchPerformances])) {
      draft.fetches.summary = removeEntity(state.fetches.summary, request);
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchPerformanceDetails)) {
      draft.data.detail = payload;
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchPerformanceDetails])) {
      const { id, actionParam } = request;
      draft.fetches.detail = shallowMergeEntities(state.fetches.detail, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchPerformanceDetails])) {
      draft.fetches.detail = removeEntity(state.fetches.detail, request);
    }

    return draft;
  });
