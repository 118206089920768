import { connect } from 'react-redux';

import { UnidentifiedLogDetailReport } from './UnidentifiedLogDetailReport';
import {
  selectUnidentifiedLog,
  selectVehiclesBreadcrumbs,
  submitAssignLogDutyStatusEvents,
  fetchUnidentifiedLogOptimized,
} from '../../../../data/reports';

import { user } from '../../../../data/administration/resources';

import { selectSystemUser } from '../../../../data/system';

export const UnidentifiedLogDetailReportContainer = connect(
  (state, { vehicleKey, date }) => ({
    unidentifiedLog: selectUnidentifiedLog(state, { vehicleKey, date }),
    driverUsers: user.selectors.selectDriverUsers(state),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
    user: selectSystemUser(state),
  }),
  { submitAssignLogDutyStatusEvents, fetchUnidentifiedLog: fetchUnidentifiedLogOptimized }
)(UnidentifiedLogDetailReport);
