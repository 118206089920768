import React from 'react';
import { Button } from 'stti-react-common';

import './ReportViewTooNew.scss';

export const ReportViewTooNew = () => (
  <div className="Report__ReportViewTooNew">
    <p>
      Report being opened was saved with a newer version of the application and cannot be loaded.
    </p>
    <Button variant="contained" onClick={() => window.history.back()} label="Go Back" />
  </div>
);
