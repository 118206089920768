import { startCase } from 'lodash';

import { NA_VALUE, replaceStringByRegex } from '../../../../../../helpers/formats';
import { columnDef } from '../../../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Open',
    children: [
      columnDef.textColumn({
        field: 'jurisdictionCountry',
        headerName: 'Country',
        width: 110,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'jurisdictionState',
        headerName: 'Jurisdiction',
        width: 110,
      }),
    ],
  },
  {
    headerName: 'Info',
    children: [
      columnDef.textColumn({
        field: 'fuelType',
        headerName: 'Fuel Type',
        width: 100,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'ou.name',
        headerName: 'Organization',
        width: 100,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'driver.name',
        headerName: 'Driver',
        cellRenderer: 'DriverNameToShiftReport',
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        width: 110,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'vehicle.typeName',
        headerName: 'Vehicle Type',
        hide: true,
      }),
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Date',
        sort: 'asc',
      }),
      columnDef.textColumn({
        field: 'startLocation.name',
        headerName: 'Start Location',
      }),
      columnDef.textColumn({
        field: 'startEventType',
        headerName: 'Start Event',
        valueFormatter: ({ value }) => {
          if (!value) return NA_VALUE;
          return startCase(replaceStringByRegex(/events\./g, value, ''));
        },
        hide: true,
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Date',
      }),
      columnDef.textColumn({
        field: 'endLocation.name',
        headerName: 'End Location',
      }),
      columnDef.textColumn({
        field: 'endEventType',
        headerName: 'End Event',
        valueFormatter: ({ value }) => {
          if (!value) return NA_VALUE;
          return startCase(replaceStringByRegex(/events\./g, value, ''));
        },
        hide: true,
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
        enableRowGroup: false,
        hide: true,
      }),
      columnDef.distanceColumn({
        field: 'startOdometer',
        headerName: 'Start Odometer',
        hide: true,
      }),
      columnDef.distanceColumn({
        field: 'endOdometer',
        headerName: 'End Odometer',
        hide: true,
      }),
      columnDef.iconColumn({
        field: 'openFuelTaxMapReport',
        headerName: 'Open Map',
        cellRenderer: 'OpenMap',
        headerComponent: 'OpenMapHeader',
        width: 65,
      }),
    ],
  },
  {
    headerName: 'Segment Values',
    children: [
      columnDef.listColumn({
        field: 'fuelVendors',
        headerName: 'Fuel Vendors',
      }),
      columnDef.fuelColumn({
        field: 'addedFuel',
        headerName: 'Taxed Qty Purchased',
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'onHighwayDistance',
        headerName: 'Taxable Dist (On Hwy)',
        width: 180,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'offHighwayDistance',
        headerName: 'Non-Taxable Dist (Off Hwy)',
        width: 180,
        aggFunc: 'sum',
        hide: true,
      }),
      columnDef.distanceColumn({
        field: 'totalDistance',
        headerName: 'Total Dist',
        aggFunc: 'sum',
      }),
    ],
  },
  {
    headerName: 'Edits',
    children: [
      columnDef.listColumn({
        field: 'json.edits',
        headerName: 'Modified',
        cellRenderer: 'JsonEdits',
        transformValueElement: (item) => `${item.property} = ${item.newValue} - ${item.userName}`,
        width: 350,
      }),
      columnDef.iconColumn({
        field: 'openOnHighwayDistanceModal',
        headerName: 'Edit',
        headerComponent: 'OpenEditHeader',
        cellRenderer: 'OpenEdit',
        width: 65,
        pinned: 'right',
      }),
    ],
  },
];
