import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Clickable } from 'stti-react-common';

import { SectionPanel } from '../../../commons/ReportsCommon';

const renderInspectionsSummary = (inspections) => {
  if (inspections.length === 0) {
    return null;
  }
  return (
    <>
      {inspections.map(({ assetId }) => (
        <Chip key={assetId} label={assetId} />
      ))}
    </>
  );
};

export const RelatedVehiclesPanel = ({ inspection, route }) => {
  const { otherInspections } = inspection || {};

  if (!otherInspections) {
    return '';
  }
  return (
    <SectionPanel
      name="relatedVehiclesPanel"
      title="Related Vehicles"
      renderSummaries={() => otherInspections && renderInspectionsSummary(otherInspections)}
    >
      <>
        {otherInspections.map(({ key, assetId }) => (
          <Clickable
            key={key}
            className="RelatedVehicles__item"
            onClick={() =>
              route.open({
                inspectionId: key,
                reportViewId: 'default',
              })
            }
          >
            {assetId}
          </Clickable>
        ))}
      </>
    </SectionPanel>
  );
};

RelatedVehiclesPanel.propTypes = {
  inspection: PropTypes.object,
  route: PropTypes.object,
};
