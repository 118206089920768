import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { RateLimit } from 'async-sema';

import {
  Form,
  DateInput,
  SelectInput,
  Button,
  ProgressOverlay,
  VideoPlayer,
  Slider,
  GoogleMap,
  SplitPane,
  TimeInput,
} from 'stti-react-common';

import { isNil, filter, flatten, uniqueId, find, capitalize, includes, uniq, map } from 'lodash';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { Feature } from '../../../../commons/Feature';
import { NaviMap } from '../../../../commons/NaviMap';
import { useReportController, ReportProvider } from '../../../../commons/ReportsCommon';
import {
  getDatelessTime,
  getTimelessDate,
  getNumberFromDateTime,
  toHourMinute,
  toMoment,
  toMomentStartOrEnd,
} from '../../../../../helpers/moment';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { useFormats } from '../../../../commons/Formats';
import { columnDefs } from './columnDefs';
import { symbols } from '../../../../commons/MapsCommon';

import {
  getSelectedDateTime,
  inRange,
  getFormDates,
  getCompareDate,
  getNumberFromHourMinute,
  getDateDuration,
  getPointerValues,
  getLocalDates,
  remapIntervals,
} from '../utils';

import { MapBreadcrumbs } from '../Maps/Breadcrumbs';
import { MapCameraTrips } from '../Maps/CameraTrips';
import { MapHighLightSections } from '../Maps/HighLightSections';
import { MapHighLightSpots } from '../Maps/HighLightSpots';

import { ConfirmDialog } from '../Dialogs/ConfirmDialog';
import { DownloadFileDialog } from '../Dialogs/DownloadFileDialog';

import './recordings.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;
const { Route } = GoogleMap;

const limit = RateLimit(1);

export const CameraRecordings = ({
  vehicle: vehicleData,
  cameraRecords,
  cameraTrips,
  cameraBreadcrumbs,
  virtualEvents,
  fetchVehicle,
  fetchCameraRecords,
  fetchCameraRecordsStreaming,
  fetchCameraVirtualEvents,
  fetchMediaEvent,
  fetchCameraTrips,
  fetchCameraBreadcrumbs,
  fetchCameraWakeUp,
  postCameraVirtualEvent,
  vehicleId,
  ouId,
  ous,
  route,
}) => {
  // STATES
  const [loading, setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [focus, setFocus] = useState([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [timeValue, setTimeValue] = useState('00:00:01');
  const [options, setOptions] = useState(['0', '5', '10', '20', '30', '45']);
  const [cameraInfo, setCameraInfo] = useState('Camera is starting');
  const [yearList, setYearList] = useState([]);

  // SPLIT PANE VALUES
  const [horizontalPaneValue, setHorizontalPaneValue] = useState(50);
  const [verticalPaneValue, setVerticalPaneValue] = useState(50);

  const { useFormController, Control } = Form;

  // INITIAL HOOKS AND MEMOS
  const { formatUnit } = useFormats();
  const timeZone = useMemo(() => (find(ous, ['key', ouId]) || {}).timeZone, [ous]);
  const report = useReportController();

  // INTERNAL METHODS
  const selectInterval = (ev, value) => {
    const found = timeline.find(({ pointers }) => inRange(value, pointers));
    if (found) {
      setCurrentVideo(null);
      setSelectedInterval({ ...found, selectedDate: getSelectedDateTime(found, value) });
    } else {
      setCurrentVideo(null);
      setSelectedInterval(null);
    }
  };

  const openVideoPlayer = (startDate, cameraId, vehicleKey) => {
    const startDateToIso = toMoment(startDate).toISOString();
    setLoadingVideo(true);
    fetchCameraRecordsStreaming({ startDate: startDateToIso, cameraId, vehicleKey }).then(
      (result) => {
        setCurrentVideo(result);
        setLoadingVideo(false);
        setHorizontalPaneValue(58);
      }
    );
  };

  const onFocusData = (compareDate) => {
    if (eventData && eventData.length > 0) {
      const focusData = eventData
        .filter(({ latitude, longitude }) => latitude !== -1 && longitude !== -1)
        .find(({ eventAt }) => eventAt.slice(0, -6) === compareDate);

      if (focusData) {
        const { id, latitude, longitude } = focusData;
        dataGridController.setState({ selectedRowIds: [id] });
        setSelectedId(id);
        setFocus([{ latitude, longitude }]);
      } else {
        dataGridController.setState({ selectedRowIds: [] });
        setSelectedId();
      }
    }
  };

  const onResetState = () => {
    setLoading(true);
    setSliderValue(0);
    setTimeValue('00:00:00');
    setSelectedInterval(null);
    setCurrentVideo(null);
    setFocus([]);
  };

  const fetchRecordingData = async (currentDate) => {
    await limit();
    fetchCameraRecords({
      vehicleKey: vehicleId,
      startDate: toMomentStartOrEnd(currentDate, 'start', 'month')
        .utc()
        .startOf('day')
        .toISOString(),
      endDate: toMomentStartOrEnd(currentDate, 'end', 'month')
        .subtract(1, 'day')
        .utc()
        .endOf('day')
        .toISOString(),
    });
  };

  const fetchBreadCrumbData = async (row, interval) => {
    await limit();
    fetchCameraBreadcrumbs({
      vehicleKey: vehicleId,
      startDate: row.startedAt,
      endDate: row.endedAt,
      timeZone,
      interval,
    });
  };

  const loadCameraRecordsByYear = async (targetYear) => {
    if (!includes(yearList, targetYear)) {
      // We will now call the year worth of data, so when we switch months,
      // we won't need to fetch cameraRecordings again until we switch years

      const promises = [];

      for (let index = 0; index < 12; index += 1) {
        const currentDate = toMoment(`${targetYear}-${index + 1}-01`).toISOString();
        promises.push(fetchRecordingData(currentDate));

        Promise.all(promises).then((results) => {
          if (results.length === 12) {
            setLoading(false);
            const yearArray = [...yearList];
            yearArray.push(targetYear);
            setYearList(uniq(yearArray));
          }
        });
      }
    } else {
      setLoading(false);
    }
  };

  const loadData = () => {
    // Lets fetch Camera Records (Responsible to draw Intervals in timeline component)
    onResetState();
    if (cameraInfo === 'Camera started, looking for video records...') {
      if (controls.startDate) {
        loadCameraRecordsByYear(controls?.startDate?.substring(0, 4));
      }
    }

    fetchCameraVirtualEvents({
      vehicleKey: vehicleId,
      startDate: toMomentStartOrEnd(controls.startDate, 'start', 'day').toISOString(),
      endDate: toMomentStartOrEnd(controls.startDate, 'end', 'day').toISOString(),
    }).then(() => {
      fetchCameraTrips({
        vehicleKey: vehicleId,
        startDate: toMomentStartOrEnd(controls.startDate, 'start', 'day').toISOString(),
        endDate: toMomentStartOrEnd(controls.startDate, 'end', 'day').toISOString(),
      }).then((rows) => {
        setLoading(false);

        if (rows && rows.length > 0) {
          rows.forEach((row) => fetchBreadCrumbData(row, 60));
        }
      });
    });
  };

  const handleVideoEventClick = (target) => {
    if (target) {
      const { date, eventAt } = target;
      const eventAtISO = toMoment(eventAt, timeZone).toISOString();
      const time = getDatelessTime(date || eventAtISO, timeZone, 'HH:mm:ss');
      const slider = getNumberFromHourMinute(controls.startDate, time, timeZone);
      onStateRefresh(slider, time);
    }
  };

  const handlePlayClick = (target) => {
    if (target && target.id && target.files) {
      const { fileId, fileType, cameraId } = target.files.find(
        (row) => row.cameraId === controls.cameraId
      );

      const date = getTimelessDate(target.date);
      const time = getDatelessTime(target.date, timeZone, 'HH:mm:ss');

      const slider = getNumberFromHourMinute(date, time, timeZone);
      onStateRefresh(slider, time);
      onFocusData(getCompareDate(controls.startDate, slider));

      if (fileId && fileType && cameraId) {
        setLoading(true);
        setLoadingVideo(true);
        fetchMediaEvent({ fileId, fileType, vehicleKey: vehicleId, cameraId }).then((result) => {
          setLoading(false);
          setCurrentVideo(result);
          setLoadingVideo(false);
        });
      }
    }
  };

  const handleLoadMorePoints = async (zoom, bounds) => {
    if (zoom < 14) return; // to avoid lots of rerendering

    cameraTrips.forEach((row) => {
      const isPointInBounds =
        bounds.sw.lat() < row.location?.latitude &&
        row.location?.latitude < bounds.ne.lat() &&
        bounds.sw.lng() < row.location?.longitude &&
        row.location?.longitude < bounds.ne.lng();

      if (isPointInBounds) {
        fetchBreadCrumbData(row, 30);
      }
    });
  };

  const onTimeChange = (time, disableSliderUpdate) => {
    const slider = getNumberFromHourMinute(controls.startDate, time, timeZone);
    if (!disableSliderUpdate) setSliderValue(slider);
    setTimeValue(time);
    const found = timeline.find(({ pointers }) => inRange(slider, pointers));
    if (found) {
      onFocusData(getCompareDate(controls.startDate, slider));
    }
    selectInterval(null, slider);
  };

  const onStateRefresh = (slider, time) => {
    setSliderValue(slider);
    setTimeValue(time);
    selectInterval(null, slider);
  };

  // FORM INITIALIZATION AND SETUP
  const form = useFormController({
    controls: {
      startDate: getTimelessDate(),
      cameraId: 1,
    },
  });

  const downloadForm = useFormController({
    controls: {
      date: getTimelessDate(),
      quality: 'high',
      duration: 5,
    },
  });

  const { controls, hasErrors } = form;
  const { controls: formControls, hasErrors: hasFormErrors, setControl } = downloadForm;

  // DATAGRID HELPERS
  const dataGridComponents = useDataGridComponents({
    CameraView: ({ data }) => {
      if (!data.files || data.files.length < 1) return '-';
      return data.files[0].cameraName;
    },
    MediaType: ({ data }) => {
      if (!data.files || data.files.length < 1) return '-';
      return capitalize(data.files[0].fileType);
    },
    Actions: ({ data }) =>
      data && data.files && data.files.length > 0
        ? data.files.map(({ fileId, fileType, cameraId }) => (
            <div key={uniqueId()}>
              <Button
                icon="location_on"
                disabled={data.latitude === -1 || data.longitude === -1}
                onClick={() => {
                  const time = getDateDuration(data);
                  const slider = getNumberFromHourMinute(controls.startDate, time, timeZone);
                  dataGridController.setState({ selectedRowIds: [data.id] });
                  setSelectedId(data.id);
                  setFocus([{ latitude: data.latitude, longitude: data.longitude }]);
                  setCurrentVideo(null);
                  setSliderValue(slider);
                  setTimeValue(time);
                }}
              />
              <Button
                icon={fileType === 'video' ? 'play_arrow' : 'photo_camera'}
                disabled={data.expiresInDays === 0}
                onClick={() => {
                  setLoading(true);
                  setLoadingVideo(true);
                  setHorizontalPaneValue(58);
                  dataGridController.setState({ selectedRowIds: [data.id] });
                  setSelectedId(data.id);
                  if (data.latitude !== -1 || data.longitude !== -1) {
                    setFocus([{ latitude: data.latitude, longitude: data.longitude }]);
                  }

                  const time = getDateDuration(data);
                  const slider = getNumberFromHourMinute(controls.startDate, time, timeZone);

                  fetchMediaEvent({ fileId, fileType, vehicleKey: vehicleId, cameraId }).then(
                    (result) => {
                      setLoading(false);
                      setCurrentVideo(result);
                      setLoadingVideo(false);
                      setSliderValue(slider);
                      setTimeValue(time);
                    }
                  );
                }}
                tooltip="Play Media"
              />
              <Button
                icon="download"
                disabled={data.expiresInDays === 0}
                onClick={() => {
                  setLoading(true);
                  const time = getDateDuration(data);
                  const slider = getNumberFromHourMinute(controls.startDate, time, timeZone);
                  fetchMediaEvent({ fileId, fileType, vehicleKey: vehicleId, cameraId })
                    .then((result) => {
                      saveAs(result.url);
                      setLoading(false);
                      dataGridController.setState({ selectedRowIds: [data.id] });
                      setSelectedId(data.id);
                      setSliderValue(slider);
                      setTimeValue(time);
                    })
                    .catch(() => setLoading(false));
                }}
                tooltip="Download Media (Less than 60 days old)"
              />
            </div>
          ))
        : '—',
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  // MEMOS

  const intervals = useMemo(
    () =>
      remapIntervals(
        flatten(map(filter(cameraRecords, ['cameraId', controls.cameraId]), 'intervals'))
      ),
    [cameraRecords, controls]
  );

  const timeline = useMemo(
    () =>
      filter(
        map(intervals, ({ startDate, endDate }, index) => ({
          index,
          utc: {
            startDate,
            endDate,
          },
          local: getLocalDates(startDate, endDate, timeZone),
          timeZone,
          pointers: getPointerValues(startDate, endDate, timeZone),
        })),
        ({ local }) => getTimelessDate(local.startDate) === controls.startDate
      ),
    [controls, timeZone, intervals]
  );

  const eventData = useMemo(() =>
    flatten(
      map(virtualEvents, ({ events, driver, vehicle }) =>
        events
          .filter(
            ({ date }) => toMoment(date, timeZone).format('YYYY-MM-DD') === controls.startDate
          )
          .map(({ date, ...rest }) => ({
            eventAt: toMoment(date, timeZone).format(),
            date: toMoment(date, timeZone).format('YYYY-MM-DD HH:mm:ss A'),
            time: toMoment(date, timeZone).format('HH:mm:ss'),
            timeZone,
            driver,
            vehicle,
            timeline,
            ...rest,
          }))
      ),
      [virtualEvents, timeZone, timeline, intervals]
    )
  );

  const datesWithTripData = useMemo(
    () =>
      uniq(
        flatten(map(filter(cameraRecords, ['cameraId', controls.cameraId]), 'intervals')).map(
          ({ startDate }) => toMoment(startDate).format('YYYY-MM-DD')
        )
      ),
    [intervals]
  );

  const breadcrumbs = useMemo(
    () =>
      cameraBreadcrumbs
        .filter(({ eventAt }) => getTimelessDate(eventAt) === controls.startDate)
        .map((breadcrumb) => ({ ...breadcrumb })),
    [cameraBreadcrumbs, controls, vehicleId]
  );

  const assetId = useMemo(() => vehicleData && vehicleData.assetId, [vehicleData]);

  const sliderDots = useMemo(() => {
    const hours = ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00', '23:59'];

    return map(hours, (hour) => {
      const dateTime = `${getTimelessDate()}T${hour}`;

      const startPosition = (getNumberFromDateTime(dateTime) * 100) / 1439;

      return { label: hour, position: startPosition };
    });
  }, []);

  const [highlightSpots, highlightSections] = useMemo(() => {
    const targetSpots = [];
    const targetSections = [];

    const virtualEvent = virtualEvents.find(({ baseDate }) => baseDate === controls.startDate);

    if (virtualEvent) {
      const { events, vehicle, driver } = virtualEvent;
      if (events) {
        events.forEach((row) => {
          const startPath = cameraBreadcrumbs.find(
            ({ latitude, longitude }) => latitude === row.latitude && longitude === row.longitude
          );
          targetSpots.push({ ...startPath, vehicle, driver, ...row });
        });
      }

      cameraBreadcrumbs &&
        cameraBreadcrumbs
          .filter(({ baseDate }) => baseDate === controls.startDate)
          .forEach((crumb, index) => {
            const { speed, postedSpeed, latitude: lat, longitude: lng } = crumb;
            const nextCrumb = cameraBreadcrumbs[index + 1] ? cameraBreadcrumbs[index + 1] : crumb;

            if (speed && postedSpeed && speed > postedSpeed) {
              targetSections.push([
                {
                  lat,
                  lng,
                },
                {
                  lat: nextCrumb.latitude,
                  lng: nextCrumb.longitude,
                },
              ]);
            }
          });
    }

    return [targetSpots, targetSections];
  }, [cameraBreadcrumbs, virtualEvents, controls.startDate]);

  // MODAL FORM SUBMIT
  const onConfirm = () => {
    const { isoStartDate, duration } = getFormDates(selectedInterval, formControls, timeZone);

    if (duration && duration >= 5) {
      setLoading(true);
      postCameraVirtualEvent({
        startDate: isoStartDate,
        cameraId: controls.cameraId,
        quality: formControls.quality,
        duration,
        vehicleKey: vehicleId,
      }).then(() => {
        setSelectedInterval(null);
        fetchCameraVirtualEvents({
          vehicleKey: vehicleId,
          startDate: toMomentStartOrEnd(controls.startDate, 'start').toISOString(),
          endDate: toMomentStartOrEnd(controls.startDate, 'end').toISOString(),
        }).then(() => {
          fetchCameraRecords({
            vehicleKey: vehicleId,
            startDate: toMomentStartOrEnd(controls.startDate, 'start').toISOString(),
            endDate: toMomentStartOrEnd(controls.startDate, 'end').toISOString(),
          }).then(() => {
            setLoading(false);
          });
        });
      });
    } else {
      setLoading(false);
    }
  };

  // EFFECTS
  useEffect(() => {
    if (vehicleId) {
      fetchVehicle(vehicleId);
      fetchCameraWakeUp({ vehicleKey: vehicleId }).then((response) => {
        if (!response) {
          setCameraInfo('Camera is offline, please contact administrator');
        } else {
          setCameraInfo('Camera started, looking for video records...');
        }
      });
    }
  }, [vehicleId]);

  useEffect(() => {
    if (!hasErrors) {
      loadData();
    }
  }, [controls.startDate, cameraInfo]);

  useEffect(() => {
    if (selectedInterval) {
      setControl('date', getTimelessDate(selectedInterval.selectedDate));
      setControl('time', getDatelessTime(selectedInterval.selectedDate, null, 'HH:mm:ss'));
      setControl('quality', 'high');
    }
  }, [selectedInterval]);

  useEffect(() => {
    if (intervals && timeline) {
      const reg = intervals.find(
        ({ startDate }) => toMoment(startDate).format('YYYY-MM-DD') === controls.startDate
      );
      if (reg) {
        const date = toMoment(reg.startDate, timeZone).format();
        const time = getDatelessTime(date, timeZone, 'HH:mm:ss');
        const slider = getNumberFromHourMinute(date, time, timeZone);
        onStateRefresh(slider, time);
      }
    }
  }, [intervals, timeline]);

  useEffect(() => {
    if (cameraTrips) {
      const focusRows = cameraTrips.map(({ origin: { latitude, longitude } }) => ({
        latitude,
        longitude,
      }));
      if (focusRows.length > 0) {
        setFocus(focusRows);
      }
    }
  }, [cameraTrips]);

  // RENDER HELPERS
  const renderTimeRange = ({ local }) => {
    const { startDate, endDate } = local;
    const startPosition = (getNumberFromDateTime(startDate, timeZone) * 100) / 1439;
    const endPosition = (getNumberFromDateTime(endDate, timeZone) * 100) / 1439;
    const startPositionPercent = `${startPosition}%`;
    const widthPercent = `${endPosition - startPosition}%`;

    return (
      <div
        key={uniqueId()}
        className="CameraRecordings__header__timeLine__bar__wrapper__item"
        style={{
          left: startPositionPercent,
          width: widthPercent,
        }}
        onClick={(ev) => {
          const rect = ev.target.getBoundingClientRect();
          const clickValue = ev.clientX - rect.left;

          const clickDateTime = toMoment(startDate, timeZone).add(clickValue, 'minutes').format();
          const date = getTimelessDate(clickDateTime);
          const time = getDatelessTime(clickDateTime, timeZone, 'HH:mm:ss');

          const slider = getNumberFromHourMinute(date, time, timeZone);
          onStateRefresh(slider, time);
          onFocusData(getCompareDate(controls.startDate, slider));
        }}
      />
    );
  };

  return (
    <div className="CameraRecordings">
      <Feature.Header onClose={route && route.close} title={assetId} />
      <ProgressOverlay isOpen={loading} />
      <div className="CameraRecordings__header">
        <div className="CameraRecordings__header__filterPanel">
          <Form form={form}>
            <Control
              Component={DateInput}
              name="startDate"
              error={({ value }) => isNil(value)}
              disablePast={loading}
              disableFuture
              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                const isSelected =
                  isInCurrentMonth && datesWithTripData.includes(day.format('YYYY-MM-DD'));
                return <div className={isSelected ? 'hasData' : ''}>{dayComponent}</div>;
              }}
              readOnly={loading}
            />
            <TimeInput
              name="time"
              type="time"
              value={timeValue}
              onChange={(value) => {
                if (value) {
                  onTimeChange(value);
                }
              }}
              disableClock
              format="HH:mm:ss"
              locale="en-CA"
              maxDetail="second"
            />
            <Control
              Component={SelectInput}
              name="cameraId"
              error={({ value }) => isNil(value)}
              options={[
                { label: 'Front Camera', value: 1 },
                { label: 'Rear Camera', value: 2 },
              ]}
            />
            <div>
              <Button
                size="small"
                label="Preview"
                variant="contained"
                onClick={() => {
                  const utcDate = toMoment(selectedInterval.selectedDate, timeZone).utc().format();
                  openVideoPlayer(utcDate, controls.cameraId, vehicleId);
                }}
                disabled={!selectedInterval || loadingVideo}
              />
              <Button
                size="small"
                label="Retrieve"
                variant="contained"
                onClick={(ev) => {
                  selectInterval(ev, sliderValue);
                  setIsOpen(true);
                }}
                disabled={!selectedInterval || loadingVideo}
              />
            </div>
          </Form>
        </div>
        <div className="CameraRecordings__header__timeLine">
          <div className="CameraRecordings__header__timeLine__bar">
            <div className="CameraRecordings__header__timeLine__bar__wrapper">
              {timeline.length === 0 && <span className="noResults">{cameraInfo} </span>}
              {timeline.length > 0 && timeline.map((row) => renderTimeRange(row))}
            </div>
            <div className="CameraRecordings__header__timeLine__bar__pointer">
              <Slider
                step={0.01666}
                min={0}
                max={1439}
                scale={(value) => toHourMinute({ value, format: 'HH:mm:ss' })}
                valueLabelDisplay="on"
                onChange={(ev, value) =>
                  setTimeout(() => {
                    setSliderValue(value);
                  }, 1)
                }
                onChangeCommitted={(ev, value) => {
                  selectInterval(ev, value);
                  const hourMinute = toHourMinute({ value, format: 'HH:mm:ss' });
                  const time = toHourMinute({
                    value: getNumberFromHourMinute(controls.starDate, hourMinute, timeZone),
                    format: 'HH:mm:ss',
                  });
                  onTimeChange(time, true);
                }}
                value={sliderValue}
              />
              <div className="CameraRecordings__header__timeLine__bar__pointer__slider">
                {sliderDots &&
                  sliderDots.map(({ label, position }) => (
                    <div key={uniqueId()} style={{ left: `${position}%` }}>
                      <div style={{ marginTop: -23, color: '#3f51b5' }}>|</div>
                      <div style={{ textIndent: -10 }}>{label}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        value={horizontalPaneValue}
        onChange={(value) => setHorizontalPaneValue(value)}
      >
        <SplitPane
          split="vertical"
          value={verticalPaneValue}
          onChange={(value) => setVerticalPaneValue(value)}
        >
          <ControlledDataGrid
            theme="balham"
            controller={dataGridController}
            components={dataGridComponents}
            rows={eventData}
            getRowStyle={(params) => {
              if (includes(params.context.selectedRowIds, params.data.id)) {
                return { background: '#ffff77' };
              }
              return true;
            }}
          />
          <div
            className={
              currentVideo
                ? 'CameraRecordings__resultsContainer__video'
                : 'CameraRecordings__resultsContainer__noVideo'
            }
          >
            {currentVideo && currentVideo.url && !loadingVideo && (
              <VideoPlayer
                id="video"
                src={currentVideo && currentVideo.url}
                maxHeight={500}
                autoPlay
              />
            )}
          </div>
        </SplitPane>
        <ReportProvider value={report}>
          <NaviMap
            focus={focus}
            hideGeozones
            onSelect={handleVideoEventClick}
            onZoomChange={(zoom, bounds) => handleLoadMorePoints(zoom, bounds)}
          >
            <Route path={breadcrumbs} line={symbols.route}>
              {MapCameraTrips(cameraTrips, controls, timeZone)}
              {MapBreadcrumbs(breadcrumbs, formatUnit, timeZone)}
              {MapHighLightSpots(highlightSpots, selectedId, timeZone, formatUnit, handlePlayClick)}
              {MapHighLightSections(highlightSections)}
            </Route>
          </NaviMap>
        </ReportProvider>
      </SplitPane>

      {DownloadFileDialog(
        selectedInterval,
        isOpen,
        setIsOpen,
        formControls,
        timeZone,
        virtualEvents,
        setIsConfirmOpen,
        onConfirm,
        hasFormErrors,
        downloadForm,
        controls,
        setOptions,
        options
      )}

      {ConfirmDialog(
        selectedInterval,
        isConfirmOpen,
        setIsConfirmOpen,
        formControls,
        timeValue,
        setLoading,
        setLoadingVideo,
        fetchMediaEvent,
        virtualEvents,
        vehicleId,
        controls,
        setIsOpen
      )}
    </div>
  );
};

CameraRecordings.propTypes = {
  ous: customPropTypes.organizations.isRequired,
  ouId: PropTypes.string,
  vehicleId: PropTypes.string.isRequired,
  cameraRecords: customPropTypes.cameraRecords.isRequired,
  virtualEvents: customPropTypes.cameraEvents.isRequired,
  cameraTrips: customPropTypes.cameraTrips,
  cameraBreadcrumbs: customPropTypes.cameraBreadcrumbs,
  vehicle: customPropTypes.vehicle,
  fetchVehicle: PropTypes.func.isRequired,
  fetchCameraTrips: PropTypes.func.isRequired,
  fetchCameraBreadcrumbs: PropTypes.func.isRequired,
  fetchCameraRecords: PropTypes.func.isRequired,
  fetchCameraRecordsStreaming: PropTypes.func.isRequired,
  fetchCameraVirtualEvents: PropTypes.func.isRequired,
  fetchMediaEvent: PropTypes.func.isRequired,
  postCameraVirtualEvent: PropTypes.func.isRequired,
  fetchCameraWakeUp: PropTypes.func.isRequired,
  route: PropTypes.object,
};
