import { connect } from 'react-redux';

import { SendReportDialog } from './SendReportDialog';
import { selectSystemUser } from '../../../data/system';
import { user } from '../../../data/administration/resources';

export const SendReportDialogContainer = connect(
  (state) => ({
    userEmail: selectSystemUser(state).email,
    users: user.selectors.selectUsers(state),
    peerUsers: user.selectors.selectPeerUsers(state),
  }),
  { fetchUsers: user.actions.fetchUsersOptimized }
)(SendReportDialog);
