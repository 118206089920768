import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

import {
  ControlledDataGrid,
  simpleAdminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import {
  CreateAdminItemDialog,
  DeleteAdminItemDialog,
  EditAdminItemDialog,
} from '../../../../commons/AdminCommon';
import { Feature } from '../../../../commons/Feature';
import { columnDefs } from './columnDefs';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

export const DelaySummary = ({
  delays,
  fetchDelays,
  deleteDelay,
  createDelay,
  updateDelay,
  route,
}) => {
  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetRow, setTargetRow] = useState({});

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          onClick={() => {
            setTargetRow({
              key: data.key,
              text: data.text,
            });
            setCurrentDialog('edit');
          }}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetRow({
              text: data.text,
              key: data.key,
            });
            setCurrentDialog('delete');
          }}
          icon="delete"
        />
      </>
    ),
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  useEffect(() => {
    fetchDelays();
  }, []);

  // RENDER
  return (
    <div className="DelaySummary">
      <Feature.Header onClose={route.close} title="Off Duty Reasons" service="offDutyReasons">
        <Button
          icon="add"
          variant="contained"
          label="Create New Off Duty Reason"
          onClick={() => {
            setCurrentDialog('new');
          }}
        />
      </Feature.Header>
      <ControlledDataGrid
        theme="balham"
        controller={dataGridController}
        components={dataGridComponents}
        rows={delays}
        rowIdProperty="key"
        sizeColumnsToFit
      />
      <DeleteAdminItemDialog
        dialogTitle="Delay Reason"
        isOpen={currentDialog === 'delete'}
        itemTitle={targetRow.text}
        onClose={() => setCurrentDialog(null)}
        onConfirm={deleteDelay}
        rowData={targetRow}
      />
      <CreateAdminItemDialog
        dialogTitle="Delay Reason"
        inputLabel="Save As: "
        isOpen={currentDialog === 'new'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={createDelay}
      />
      <EditAdminItemDialog
        dialogTitle="Delay Reason"
        inputLabel="Reason: "
        isOpen={currentDialog === 'edit'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={updateDelay}
        rowData={targetRow}
      />
    </div>
  );
};

DelaySummary.propTypes = {
  delays: customPropTypes.offDutyReasons.isRequired,
  fetchDelays: PropTypes.func.isRequired,
  deleteDelay: PropTypes.func.isRequired,
  createDelay: PropTypes.func.isRequired,
  updateDelay: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
