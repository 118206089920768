import produce from 'immer';
import { reject } from 'lodash';

export const ACTION_TYPE = {
  enqueueNotification: 'notifications/enqueue',
  dequeueNotification: 'notifications/dequeue',
};

const initialState = {
  queued: [],
};

export const notificationsReducer = (state = initialState, { type, notification, payload }) => {
  if (type === ACTION_TYPE.dequeueNotification)
    return produce(state, (draft) => {
      const { key } = payload;
      draft.queued = reject(draft.queued, { key });
    });

  if (notification)
    return produce(state, (draft) => {
      const { message } = notification;
      draft.queued.push({ key: Date.now(), message });
    });

  return state;
};
