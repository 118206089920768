export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const parseIntNumber = (item) => {
  const reg = /^\d+$/;
  if (!reg.test(item)) return false;
  return parseInt(item, 10);
};

export const parseString = (item) => {
  const reg = /^\d+$/;
  if (reg.test(item)) return false;
  return item;
};

export const mapStringAndNumbers = (rows) => {
  const numbers = rows
    .map(parseIntNumber)
    .filter(Boolean)
    .sort((a, b) => a - b);
  const strings = rows
    .map(parseString)
    .filter(Boolean)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  return numbers.concat(strings).map((item) => String(item));
};
