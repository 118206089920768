import { activationCode, activationCodes } from './activationCodes';
import { apiKey, apiKeys } from './apiKeys';
import {
  cameraEvents,
  cameraRecords,
  cameraTrips,
  cameraBreadcrumbs,
} from './cameraRecordsPropTypes';
import { companionApps, companionApp } from './companionAppsPropTypes';
import { tablet, tablets } from './tablets';
import { dispatchAbortReason, dispatchAbortReasons } from './dispatchAbortReasons';
import { driverLog, driverLogs, detailedDriverLog } from './driverLogs';
import { fuelConsumption, fuelConsumptions } from './fuelConsumptions';
import { fuelling, fuellings } from './fuellings';
import { geozoneSummary, geozoneSummaries, geozoneActivity, geozoneActivities } from './geozones';
import { inspection, inspections } from './inspections';
import { inspectionTask, inspectionTasks } from './inspectionTasks';
import { inspectionTemplate, inspectionTemplates } from './inspectionTemplatesPropTypes';
import { location } from './locationPropTypes';
import { locationType } from './locationTypePropTypes';
import { offDutyReason, offDutyReasons } from './offDutyReasons';
import { organization, organizations } from './organizationPropTypes';
import { performance, performances, performanceDetail, performanceDetails } from './performances';
import { performanceRule, performanceRules } from './performanceRules';
import { performanceRuleSet, performanceRuleSets } from './performanceRuleSets';
import { reportSchedule, reportSchedules } from './reportSchedules';
import { role, roles } from './roles';
import { speedLimit, speedLimits } from './speedLimits';
import { fuelTaxMileage, fuelTaxMileages } from './fuelTaxMileages';
import { tenant, tenants } from './tenants';
import { unidentifiedLog, unidentifiedLogs, detailedUnidentifiedLog } from './unidentifiedLogs';
import { user, users } from './users';
import { vehicle, vehicles } from './vehicles';
import { vehicleType, vehicleTypes } from './vehicleTypes';
import { speedViolation, speedViolations } from './speedViolations';

import { date } from './misc';

export const customPropTypes = {
  activationCode,
  activationCodes,
  apiKey,
  apiKeys,
  cameraBreadcrumbs,
  cameraEvents,
  cameraRecords,
  cameraTrips,
  companionApp,
  companionApps,
  date,
  tablet,
  tablets,
  dispatchAbortReason,
  dispatchAbortReasons,
  driverLog,
  driverLogs,
  detailedDriverLog,
  fuelConsumption,
  fuelConsumptions,
  fuelling,
  fuellings,
  geozoneSummary,
  geozoneSummaries,
  geozoneActivity,
  geozoneActivities,
  inspection,
  inspections,
  inspectionTask,
  inspectionTasks,
  inspectionTemplate,
  inspectionTemplates,
  location,
  locationType,
  offDutyReason,
  offDutyReasons,
  organization,
  organizations,
  performance,
  performances,
  performanceDetail,
  performanceDetails,
  performanceRule,
  performanceRules,
  performanceRuleSet,
  performanceRuleSets,
  reportSchedule,
  reportSchedules,
  role,
  roles,
  speedLimit,
  speedLimits,
  fuelTaxMileage,
  fuelTaxMileages,
  tenant,
  tenants,
  unidentifiedLog,
  unidentifiedLogs,
  detailedUnidentifiedLog,
  user,
  users,
  vehicle,
  vehicles,
  vehicleType,
  vehicleTypes,
  speedViolation,
  speedViolations,
};
