import React from 'react';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import { countUniqueOfField, countOf } from '../../../../helpers/reports/aggregation';

export const AggregatesPanel = ({ rows }) => {
  const registers = rows.filter(Boolean);
  return (
    <SectionPanel
      name="aggregatesPanel"
      title="Aggregates"
      renderSummaries={(props) => (
        <div {...props}>
          {countUniqueOfField(registers, 'vehicle')} vehicles,{' '}
          {countUniqueOfField(registers, 'driverKey')} drivers, {countOf(registers)} sessions
        </div>
      )}
    >
      <AggregatesContent rows={rows} />
    </SectionPanel>
  );
};

AggregatesPanel.propTypes = {
  rows: customPropTypes.fuelTaxMileages.isRequired,
};
