import React from 'react';
import PropTypes from 'prop-types';
import { Clickable, Icon } from 'stti-react-common';

import './ActivityCell.scss';

export const ActivityCell = ({ label, type, onToggle }) => (
  <div className="Report__ActivityCell">
    <span className="Report__ActivityCell__toggle">
      {(type === 'expanded' || type === 'collapsed') && (
        <Clickable onClick={onToggle}>
          {type === 'expanded' ? <Icon icon="expand_more" /> : <Icon icon="chevron_right" />}
        </Clickable>
      )}
    </span>
    {type === 'child' && <span className="Report__ActivityCell__indent" />}
    <span className="Report__ActivityCell__label">{label}</span>
  </div>
);

ActivityCell.propTypes = {
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  type: PropTypes.oneOf(['expanded', 'collapsed', 'child', 'solo']),
};
