import { connect } from 'react-redux';

import { DelaySummary } from './summary';
import { delay } from '../../../../../data/administration/configurations';

export const DelaySummaryContainer = connect(
  (state) => ({
    delays: delay.selectors.selectDelays(state),
  }),
  {
    fetchDelays: delay.actions.fetchDelaysOptimized,
    deleteDelay: delay.actions.deleteDelay,
    createDelay: delay.actions.createDelay,
    updateDelay: delay.actions.updateDelay,
  }
)(DelaySummary);
