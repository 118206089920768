import moment from 'moment-timezone';

import { Location } from '../../helpers/navigation/Location';
import { makeRestApiAction } from '../apiActionSupport';
import { ACTION_TYPE } from './reportSchedulesReducer';

function replaceSubstring(string, defaultSubstring, newSubstring) {
  return string.replace(defaultSubstring, newSubstring);
}

const transformForServer = (
  {
    // schedule and on-demand
    id,
    title,
    userKey,
    location: specifiedLocation,
    toEmails,
    includeDetail, // nullable
    orientation,
    attach,
    ouKey,

    // schedule only
    endDate,
    hour,
    tenantKey,
    timeZone,

    // potentially added by selector, drop
    hourAt,
  },
  { originUrl }
) => {
  let temporaryLocation = null;
  const isSchedule = !!endDate;

  const location = specifiedLocation || new Location();
  location.origin = originUrl; // overwrite with API's originUrl
  location.query = {
    ...location.query,
    ou: ouKey,
    print: 'true',
    attach,
    ...(includeDetail && { includeDetail }),
  };

  if (id) {
    temporaryLocation = replaceSubstring(location.toString(), 'default', id);
  }

  if (includeDetail) location.query.includeDetail = true;

  const remappedUrls = [temporaryLocation || location.toString()].map((url) =>
    url.replace(/\/#/, '/#/reports')
  );

  let transformed = {
    reportName: title,
    userKey,
    urls: remappedUrls,
    toEmails,
    includeDetail: !!includeDetail, // ensure falsy is explicit false
    config: {
      landscape: orientation === 'landscape',
    },
    timeZone,
  };

  if (isSchedule)
    transformed = {
      ...transformed,
      endAt: moment(endDate).tz(timeZone).add(1, 'day').endOf('day').toISOString(),
      delivery: hour, // hour is 0-23, server will use specified timeZone in scheduling
      tenantKey,
      timeZone,
      frequency: 'daily', // currently only daily is supported
      intervalStart: 0,
    };

  return transformed;
};

const transformFetchedScheduleForStore = ({
  reportName,
  config: { landscape },
  includeDetail,
  delivery,
  toEmail, // dropped legacy property (superceded by toEmails array)
  endAt,
  timeZone,
  ...rest // userKey, toEmails, urls, endAt, tenantKey, frequency, intervalStart
}) => ({
  title: reportName,
  includeDetail: !!includeDetail, // ensure falsey is explicit false
  orientation: landscape ? 'landscape' : 'portrait',
  endAt,
  endDate: moment(endAt).tz(timeZone).format('YYYY-MM-DD'),
  hour: delivery,
  timeZone,
  ...rest,
});

const transformCreatedScheduleForStore = (
  { key },
  { location, attach, ouKey, ...rest }, // drop-via-destructure the non-stored properties
  { urls, includeDetail, endAt, frequency, intervalStart }
) => ({
  // preserved from actionParam
  ...rest,

  // only property returned from server
  key,

  // from create request (not in actionParam as they are post-transform)
  urls,
  endAt,
  frequency,
  intervalStart,
  includeDetail,
});

export const fetchReportSchedulesByUserKey = makeRestApiAction({
  service: 'reportSchedules',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchReportSchedulesByUserKey,
  transformInput: (userKey) => ({ userKey }),
  transformOutput: (responseData) => responseData.map(transformFetchedScheduleForStore),
  notificationsItemDescriptor: 'report schedules',
});

/** Add fetchReportSchedulesByUserKeyOptimized back to `FetchReportSchedules.js when all reports
 * with  scheduling functionality are converted to react

export const fetchReportSchedulesByUserKeyOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectReportSchedulesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchReportSchedulesByUserKey,
  baseActionType: ACTION_TYPE.fetchReportSchedulesByUserKey,
});
*/

export const deleteReportSchedule = makeRestApiAction({
  service: 'reportSchedules',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteReportSchedule,
  transformInput: ({ key }) => ({ key }),
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'report schedule',
});

export const createReportSchedule = makeRestApiAction({
  service: 'reportSchedules',
  method: 'post',
  baseActionType: ACTION_TYPE.createReportSchedule,
  transformInput: transformForServer,
  transformOutput: transformCreatedScheduleForStore,
  notifications: {
    request: 'Scheduling report...',
    success: 'Report scheduled.',
    failure: (_, apiError) => `Report could not be scheduled (${apiError.message}).`,
  },
});

export const createReportOnDemand = makeRestApiAction({
  service: 'reportOnDemand',
  method: 'post',
  baseActionType: ACTION_TYPE.createReportOnDemand,
  transformInput: transformForServer,
  notifications: {
    request: 'Emailing report...',
    success: 'Report email requested.',
    failure: (_, apiError) => `Report could not be emailed (${apiError.message}).`,
  },
});
