import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { noop, isNil } from 'lodash';
import { Card, ImageInput } from 'stti-react-common';

import { InfoField, HidePrint } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { NA_VALUE } from '../../../../../helpers/formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

const formatBooleanValue = (value) => {
  if (isNil(value)) {
    return NA_VALUE;
  }
  return value ? 'Yes' : 'No';
};

export const DriverCard = ({ log, hidePrint }) => {
  const { formatUnit } = useFormats();

  const {
    driver,
    certificationStatus,
    onDutyTotal,
    offDutyTotal,
    deferralStatus,
    distanceDriven,
    deferralTime,
    startedAt,
    cycleStart,
    cycleOnDutyRemaining,
    cycleOnDutyTime,
    timeZone,
    coDrivers,
  } = log || {};

  const onDutyTotalFormatted = formatUnit('duration', onDutyTotal);
  const offDutyTotalFormatted = formatUnit('duration', offDutyTotal);
  const deferralTimeFormatted = formatUnit('duration', deferralTime);
  const distanceDrivenFormatted = formatUnit('distance', distanceDriven);
  const startedAtFormatted = startedAt && formatUnit('date', moment(startedAt).tz(timeZone));
  const cycleStartFormatted = cycleStart && formatUnit('date', moment(cycleStart).tz(timeZone));
  const cycleOnDutyRemainingFormatted = formatUnit('duration', cycleOnDutyRemaining);
  const cycleOnDutyTimeFormatted = formatUnit('duration', cycleOnDutyTime);

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__DriverCard" fullWidth>
        <h4>Driver &amp; Hours</h4>
        <div className="DriverLogDetailReport__infoFieldContainer">
          <InfoField name="name" label="Driver Name" value={driver && driver.name} />
          <InfoField name="username" label="Driver ID" value={driver && driver.username} />
          <InfoField
            name="driverLicenseNumber"
            label="Driver License"
            value={driver && driver.driverLicenseNumber}
          />
          <InfoField
            name="driverLicenseIssuer"
            label="License Jurisdiction"
            value={driver && driver.driverLicenseIssuer}
          />
          <InfoField
            name="cdnEldExempt"
            label="CDN ELD Exempt"
            value={formatBooleanValue(driver && driver.cdnEldExempt)}
          />
          <InfoField
            name="usEldExempt"
            label="US ELD Exempt"
            value={formatBooleanValue(driver && driver.usEldExempt)}
          />
          <InfoField
            name="coDriverName"
            label="Co-Driver Name"
            value={coDrivers && coDrivers.map(({ name }) => name)}
          />
          <InfoField
            name="coDriverId"
            label="Co-Driver ID"
            value={coDrivers && coDrivers.map(({ username }) => username)}
          />
          <InfoField
            name="distanceDriven"
            label="Total Distance Driven"
            value={distanceDrivenFormatted}
          />
          <ImageInput
            readOnly
            label="Driver Signature"
            name="driverSignature"
            value={certificationStatus && certificationStatus.signature}
            onChange={noop}
          />
          <InfoField name="onDutyTotal" label="Total On Duty" value={onDutyTotalFormatted} />
          <InfoField name="offDutyTotal" label="Total Off Duty" value={offDutyTotalFormatted} />
          <InfoField name="deferralStatus" label="Deferral" value={deferralStatus} />
          <InfoField name="deferralTime" label="Deferred Time" value={deferralTimeFormatted} />
          <InfoField name="startedAt" label="24 Hour Start" value={startedAtFormatted} />
          <InfoField name="cycleStart" label="Cycle Start" value={cycleStartFormatted} />
          <InfoField name="cycleOnDutyTime" label="Cycle Used" value={cycleOnDutyTimeFormatted} />
          <InfoField
            name="cycleOnDutyRemaining"
            label="Cycle Available"
            value={cycleOnDutyRemainingFormatted}
          />
        </div>
        <div>
          <h6>* Collected from the vehicles ECM</h6>
          <h6>** Driver manual edit value shown</h6>
        </div>
      </Card>
    </HidePrint>
  );
};

DriverCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
