import React from 'react';
import PropTypes from 'prop-types';

export const HeadingArrow = ({ heading, color }) => (
  <div className="Map__HeadingArrow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M10,0L3,17.17l0.67,0.67L10,15l6.38,2.82L17,17.17Z"
        transform={`rotate(${heading},10,10)`}
        fill={color}
      />
    </svg>
  </div>
);

HeadingArrow.propTypes = {
  heading: PropTypes.number.isRequired,
  color: PropTypes.string,
};

HeadingArrow.defaultProps = {
  color: 'black',
};
