import React from 'react';

import { TenantSummaryContainer } from './Summary/connect';
import { TenantDetailContainer } from './Detail/connect';
import { TenantConfigContainer } from './Config/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { administrationGroups, configureRoute, SUPER_ADMIN_ROLE_KEY } from '../../../../config';

export const tenantSummary = configureRoute({
  name: 'Tenant',
  group: administrationGroups.configurations,
  path: '/tenants',
  order: 11,
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => (
    <TenantSummaryContainer
      {...props}
      openTenantCreate={tenantCreate.open}
      openTenantDetail={tenantDetail.open}
      openTenantConfigDetail={tenantConfigDetail.open}
      closeRoute={tenantSummary.close}
    />
  ),
});

export const tenantCreate = configureRoute({
  path: '/tenant/new',
  switchKey: 'tenant',
  RouteFrame: BasicRouteFrame,
  render: (props) => <TenantDetailContainer {...props} mode="create" />,
});

export const tenantConfigDetail = configureRoute({
  path: '/tenant/:tenantId/config',
  switchKey: 'tenant',
  RouteFrame: BasicRouteFrame,
  render: (props) => <TenantConfigContainer {...props} />,
});

export const tenantDetail = configureRoute({
  path: '/tenant/:tenantId',
  switchKey: 'tenant',
  RouteFrame: BasicRouteFrame,
  render: (props) => <TenantDetailContainer {...props} />,
});
