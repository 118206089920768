import { connect } from 'react-redux';

import { TenantConfig } from './config';

import { companionApp, tenant } from '../../../../../data/administration/configurations';
import { camera } from '../../../../../data/administration/devices';

import { fetchVideoConfigsOptimized, selectVideoConfigs } from '../../../../../data/system';

export const TenantConfigContainer = connect(
  (state, { tenantId }) => ({
    tenant: tenant.selectors.selectTenant(state, tenantId),
    companionApps: companionApp.selectors.selectCompanionApps(state),
    videoConfigs: selectVideoConfigs(state),
  }),
  {
    fetchTenant: tenant.actions.fetchTenantOptimized,
    fetchCompanionApps: companionApp.actions.fetchCompanionAppsByTenantIdOptimized,
    updateTenantConfiguration: tenant.actions.updateTenantConfiguration,
    fetchVideoConfigs: fetchVideoConfigsOptimized,
    fetchVideoConfigsByTenant: tenant.actions.fetchVideoConfigsByTenantOptimized,
    updateTenantVideoConfigs: tenant.actions.updateTenantVideoConfigs,
    postAddCameras: camera.actions.postAddCameras,
  }
)(TenantConfig);
