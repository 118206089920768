import { connect } from 'react-redux';

import { ActivitySummary } from './summary';
import { activity } from '../../../../../data/administration/configurations';

export const ActivitySummaryContainer = connect(
  (state) => ({
    activities: activity.selectors.selectActivities(state),
  }),
  {
    fetchActivities: activity.actions.fetchApiKeysOptimized,
  }
)(ActivitySummary);
