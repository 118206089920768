import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarQueue } from 'stti-react-common';

export const Notifier = ({ queuedNotifications, dequeueNotification }) => {
  const intervalId = React.useRef(null);

  React.useEffect(() => {
    intervalId.current = setInterval(() => {
      const snackbarQueueDomElement = document.querySelector('.SnackbarQueue > div');

      if (!snackbarQueueDomElement) {
        return;
      }

      const style = window.getComputedStyle(snackbarQueueDomElement);
      if (style?.visibility === 'hidden') {
        snackbarQueueDomElement.style.display = 'none';
      } else {
        snackbarQueueDomElement.style.display = 'inherit';
      }
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return <SnackbarQueue snackbars={queuedNotifications} dequeueSnackbar={dequeueNotification} />;
};

Notifier.propTypes = {
  queuedNotifications: PropTypes.array.isRequired,
  dequeueNotification: PropTypes.func.isRequired,
};
