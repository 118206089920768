import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeRequest,
  actionTypeProgressOrSuccess,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  fetchFuellings: 'reports/fuellings/fetchFuellings',
};

const initialState = {
  fuellings: [],
  fetches: [],
};

export const fuellingsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFuellings]))
      draft.fuellings = sortBy(
        shallowMergeEntities(state.fuellings, payload),
        'startedAt'
      ).reverse();

    if (actionTypeRequest(type, ACTION_TYPE.fetchFuellings)) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchFuellings)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
