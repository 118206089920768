import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, DateInput } from 'stti-react-common';

import { useFormats } from '../Formats';

const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';

export const DateStepperInput = ({ value, onChange, min, max, error, ...restProps }) => {
  const {
    formats: { timelessDate },
  } = useFormats();

  return (
    <div className="Report__DateStepperInput" {...restProps}>
      <Button
        icon="keyboard_arrow_left"
        variant="text"
        onClick={() => {
          onChange(moment(value).subtract(1, 'days').format(NORMAL_DATE_FORMAT));
        }}
        disabled={!value || value > moment().add(1, 'day').format(NORMAL_DATE_FORMAT)}
      />
      <DateInput
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        error={error}
        format={timelessDate}
      />
      <Button
        icon="keyboard_arrow_right"
        variant="text"
        onClick={() => {
          onChange(moment(value).add(1, 'days').format(NORMAL_DATE_FORMAT));
        }}
        disabled={!value || value >= max}
      />
    </div>
  );
};

DateStepperInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
