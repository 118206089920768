import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import {
  Form,
  ProgressOverlay,
  TextInput,
  SelectInput,
  TabularInput,
  Button,
} from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';

import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { useApiError } from '../../../../../helpers/hooks';

import './detail.scss';

export const InspectionTaskDetail = ({
  fetchInspectionTask,
  fetchInspectionTasks,
  inspectionTask,
  taskId,
  ous,
  updateInspectionTask,
  deleteInspectionTask,
  createInspectionTask,
  mode: initialMode = 'view',
  route,
}) => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(initialMode);
  const { apiError, setApiError } = useApiError();

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      Promise.all([fetchInspectionTasks(), fetchInspectionTask(taskId)])
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, taskId]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: inspectionTask
      ? {
          ...inspectionTask,
          defectTypes: inspectionTask.defectTypes || [],
          ou: inspectionTask.ou && inspectionTask.ou.key,
        }
      : {
          defectTypes: [],
        },
  });

  const { controls: formControls, resetControls } = form;

  if (loading)
    return (
      <div className="InspectionTaskDetail">
        <ProgressOverlay isOpen={loading} />
      </div>
    );

  return (
    <Feature.Wrapper className="InspectionTaskDetail">
      <CrudAdminItem
        title="Inspection Task"
        form={form}
        itemTitle={formControls.name || ''}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        mode={mode}
        onApiError={setApiError}
        setMode={setMode}
        data={formControls}
        onClose={route.close}
        createItem={createInspectionTask}
        updateItem={inspectionTask && !inspectionTask.isSystem ? updateInspectionTask : null}
        deleteItem={inspectionTask && !inspectionTask.isSystem ? deleteInspectionTask : null}
        resetControls={resetControls}
      >
        <Feature.Body>
          <ErrorSummary errorMsg={apiError} />
          <div className="CrudAdminItem__controls">
            <Control
              Component={TextInput}
              name="name"
              label="Task Name"
              error={({ value }) => !value}
            />
            <Control Component={TextInput} name="description" label="Task Description" />
            <Control
              Component={SelectInput}
              name="ou"
              label="Applies To"
              options={objectsToSelectOptions(ous, 'name', 'key', true)}
            />
          </div>
          <div className="TabularInput__wrapper">
            <Control
              Component={TabularInput}
              renderHeader={({ addRow }) => (
                <div className="TabularInput__title">
                  <h2>Defect Types</h2>
                  {mode !== 'view' && (
                    <Button variant="outlined" label="Add Defect Type" onClick={addRow} />
                  )}
                </div>
              )}
              renderRow={({ deleteRow }) => (
                <>
                  <Control
                    Component={TextInput}
                    name="name"
                    label="Name"
                    error={({ value }) => !value}
                    readOnly={mode === 'view'}
                  />
                  <Control
                    Component={TextInput}
                    name="description"
                    label="Description"
                    error={({ value }) => !value}
                    readOnly={mode === 'view'}
                  />
                  <Control
                    Component={SelectInput}
                    name="isMajor"
                    label="Severity"
                    readOnly={mode === 'view'}
                    error={({ value }) => isNil(value)}
                    options={[
                      { label: 'Major', value: true },
                      { label: 'Minor', value: false },
                    ]}
                  />
                  {(mode === 'edit' || mode === 'create') && (
                    <Button
                      variant="outlined"
                      label="Delete Row"
                      icon="clear"
                      onClick={deleteRow}
                    />
                  )}
                </>
              )}
              name="defectTypes"
              ownErrorName="defectTypesTable"
            />
          </div>
        </Feature.Body>
      </CrudAdminItem>
    </Feature.Wrapper>
  );
};

InspectionTaskDetail.propTypes = {
  fetchInspectionTask: PropTypes.func.isRequired,
  fetchInspectionTasks: PropTypes.func.isRequired,
  taskId: PropTypes.string,
  inspectionTask: customPropTypes.inspectionTask,
  ous: customPropTypes.organizations.isRequired,
  updateInspectionTask: PropTypes.func.isRequired,
  deleteInspectionTask: PropTypes.func.isRequired,
  createInspectionTask: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
};
