import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, Icon, ProgressOverlay, Chip } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';
import { FiltersPanel } from './FiltersPanel';

import { customPropTypes } from '../../../../helpers/customPropTypes';

import {
  useReportController,
  ReportProvider,
  ReportFragment,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  SectionPanel,
  ControlGroupSummary,
  SectionPanelMenu,
  ControlsTablePrint,
  SectionPrint,
  FetchReportData,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './GeozoneActivitiesReport.scss';

const getDefaultState = (startDate, endDate) => ({
  controls: {
    dateRangeMode: 'custom',
    startDate,
    endDate,
  },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['geozoneActivities'];

const { createState, useDataGridComponents } = ControlledDataGrid;

export const GeozoneActivitiesReport = ({
  activities,
  startDate: startedAt,
  endDate: endedAt,
  geozoneKey,
  orgKey,
  reportViewId,
  route,
  fetchGeozoneActivities,
  fetchOrganizations,
}) => {
  const [lazyRender, setLazyRender] = useState(null);
  const report = useReportController({
    reportType: 'geozoneDetail',
    reportTypeName: 'Geozone Detail Report',
    reportViewId,
    route,
    defaultState: getDefaultState(startedAt, endedAt, orgKey),
    filtersConfig: {
      input: activities,
      chain: ['query', 'filters', 'outlier'],
    },
  });

  const { filteredRows, reportType, dataGridController, setControl } = report;

  const setShiftSummaryReportDate = ({ startedAt: dateStart, dateEntered }) => {
    if (!dateEntered || typeof dateEntered !== 'string') return dateStart;
    return dateEntered.substring(0, dateEntered.indexOf('T'));
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    setControl('ouMode', 'custom');
    setControl('ouKeys', [orgKey]);
    setControl('ousSelected', [orgKey]);
  }, [orgKey]);

  useEffect(() => {
    if (geozoneKey) {
      setTimeout(() => {
        setLazyRender(true);
      }, 2000);
    }
  }, [geozoneKey]);

  useEffect(() => {
    dataGridController.methods.autoSizeColumns();
  }, [filteredRows]);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    // TODO: it is pending a better way to handle this with route.
    // Ty is working on it and I will revisit this once it is ready
    OpenDriverShift: ({ data, value }) => (
      <div className="ControlledDataGrid__DriverContainer">
        <Clickable
          onClick={() =>
            window.location.replace(
              `#/driverShiftSummary/default?driverName=${
                data.driverName
              }&date=${setShiftSummaryReportDate(data)}`
            )
          }
        >
          <Icon icon="person_pin" />
        </Clickable>
        {value}
      </div>
    ),
  });

  const [first] = activities;

  return (
    <ReportProvider value={report}>
      <FetchReportData fetchAction={fetchGeozoneActivities} args={{ geozoneKey }} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="GeozoneActivitiesReport">
        <ReportFragment.Header reportType={reportType} route={route} services={requestServices} />

        <QueryPanel queryFor="activity" group="query" disableQueryPanel />
        {lazyRender ? (
          <>
            <SectionPanel
              name="detailsPanel"
              title="Details"
              renderSummaries={() => (
                <Chip
                  variant="default"
                  color="primary"
                  label={`${first?.geozoneName} - ${first?.geozoneTypeName}`}
                />
              )}
            >
              <div>
                <p>
                  <b>Geozone Name:</b> <span>{first?.geozoneName}</span>
                </p>
                <p>
                  <b>Geozone Type:</b> <span>{first?.geozoneTypeName}</span>
                </p>
              </div>
            </SectionPanel>
            <SectionPanel
              name="filtersPanel"
              title="Filters"
              renderSummaries={() => <ControlGroupSummary group="filters" />}
              summaryEnd={<SectionPanelMenu group="filters" />}
            >
              <FiltersPanel group="filters" filterGroup="filters" rows={filteredRows.query} />
            </SectionPanel>
            <AggregatesPanel rows={filteredRows.dataGrid} />
            <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
          </>
        ) : (
          <ProgressOverlay isOpen />
        )}
        <PrintLayout mode={reportType}>
          <ReportFragment.Header />
          <ControlsTablePrint />
          <SectionPrint flexRow>
            <AggregatesContent rows={filteredRows.dataGrid} />
          </SectionPrint>
          <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
        </PrintLayout>
      </div>
    </ReportProvider>
  );
};

GeozoneActivitiesReport.propTypes = {
  activities: customPropTypes.geozoneActivities,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  geozoneKey: PropTypes.string.isRequired,
  orgKey: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  fetchGeozoneActivities: PropTypes.func.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
};
