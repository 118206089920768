import { connect } from 'react-redux';

import { VehicleTypeSummary } from './summary';

import { selectSystemActiveOu, selectSystemTenant } from '../../../../../data/system';
import { vehicleType } from '../../../../../data/administration/resources';

export const VehicleTypeSummaryContainer = connect(
  (state) => ({
    vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
    activeOu: selectSystemActiveOu(state),
    tenant: selectSystemTenant(state),
  }),
  {
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
    deleteVehicleType: vehicleType.actions.deleteVehicleType,
  }
)(VehicleTypeSummary);
