import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'stti-react-common';

import { useReport } from './useReport';
import { ReportControl } from './ReportControl';
import { collapseGroupedRows } from '../../../helpers/reports/displayFilters';

export const GridGroupingControl = ({ filterGroup }) => {
  const { setControl } = useReport();

  return (
    <>
      <ReportControl
        name="dataGridGroupingMode"
        group="dataGrid"
        defaultValue="collapsed"
        instant
        autoGridArea
        render={({ style }) => (
          <div style={style}>
            <Button
              onClick={() => {
                setControl('dataGridGroupingToggledRows', []);
                setControl('dataGridGroupingMode', 'expanded');
              }}
              icon="expand_more"
              label="Expand All"
            />
            <Button
              onClick={() => {
                setControl('dataGridGroupingToggledRows', []);
                setControl('dataGridGroupingMode', 'collapsed');
              }}
              icon="chevron_right"
              label="Collapse All"
            />
          </div>
        )}
      />
      <ReportControl
        name="dataGridGroupingToggledRows"
        group="dataGrid"
        defaultValue={[]}
        instant
        unpersisted
        render={() => null}
        filterConfig={{
          type: collapseGroupedRows,
          group: filterGroup,
          getValues: ({ controls, value }) => ({
            mode: controls.dataGridGroupingMode || 'collapsed',
            toggledIds: value,
          }),
        }}
      />
    </>
  );
};

GridGroupingControl.propTypes = {
  filterGroup: PropTypes.string.isRequired,
};
