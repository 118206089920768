import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  meanOfField,
  countOf,
  countUniqueOfField,
  sumOfField,
} from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

import { formatUnit as nonContextFormatUnit } from '../../../../helpers/formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit, formats } = useFormats();
  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Traversals',
            value: countOf(rows),
          },
          {
            header: 'Drivers',
            value: countUniqueOfField(rows, 'driverName'),
          },
          {
            header: 'Vehicles',
            value: countUniqueOfField(rows, 'vehicleId'),
          },
        ]}
      />
      <AggregatesCard
        title="Totals"
        name="totals"
        fields={[
          {
            header: 'Driving Time',
            value: formatUnit('duration', sumOfField(rows, 'driveDuration')),
          },
          {
            header: 'Driving Distance',
            value: nonContextFormatUnit(
              'distance',
              sumOfField(rows, 'distanceDriven'),
              formats.distance,
              1
            ),
          },
          {
            header: 'Fuel Consumed',
            value: formatUnit('volume', sumOfField(rows, 'fuelConsumed')),
          },
          {
            header: 'Stopped Time',
            value: formatUnit('duration', sumOfField(rows, 'stopDuration')),
          },
          {
            header: 'Idling Time',
            value: formatUnit('duration', sumOfField(rows, 'idleDuration')),
          },
          {
            header: 'Total Time',
            value: formatUnit('duration', sumOfField(rows, 'duration')),
          },
        ]}
      />
      <AggregatesCard
        title="Averages"
        name="averages"
        fields={[
          {
            header: 'Driving Time',
            value: formatUnit('duration', meanOfField(rows, 'driveDuration')),
          },
          {
            header: 'Driving Distance',
            value: nonContextFormatUnit(
              'distance',
              meanOfField(rows, 'distanceDriven'),
              formats.distance,
              1
            ),
          },
          {
            header: 'Fuel Consumed',
            value: formatUnit('volume', meanOfField(rows, 'fuelConsumed')),
          },
          {
            header: 'Stopped Time',
            value: formatUnit('duration', meanOfField(rows, 'stopDuration')),
          },
          {
            header: 'Idling Time',
            value: formatUnit('duration', meanOfField(rows, 'idleDuration')),
          },
          {
            header: 'Total Time',
            value: formatUnit('duration', meanOfField(rows, 'duration')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
