import React from 'react';

import { EcmReportSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import {
  ADMIN_ROLE_KEY,
  administrationGroups,
  configureRoute,
  SUPER_ADMIN_ROLE_KEY,
} from '../../../../config';

export const ecmReportSummary = configureRoute({
  name: 'NaviLinks',
  path: '/naviLinks',
  group: administrationGroups.devices,
  order: 4,
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY, ADMIN_ROLE_KEY],
  render: (props) => <EcmReportSummaryContainer {...props} />,
});
