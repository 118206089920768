import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'stti-react-common';

import { annotationsEventsColumnDefs } from './columnDefs';
import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController, createState } = ControlledDataGrid;

export const AnnotationsEventsCard = ({ log, printLayoutMode }) => {
  const { eldEvents, timeZone, referencedDrivers = [], referencedSupportUsers = [] } = log || {};

  const annotationsEvents = useMemo(() => {
    if (!eldEvents) return [];

    return eldEvents.filter(
      ({ eventType, annotation }) =>
        (eventType.includes('CYCLE') ||
          eventType.includes('ZONE') ||
          eventType.includes('DEFERRAL') ||
          eventType.includes('PERSONAL_USE') ||
          eventType.includes('YARD_MOVEMENT') ||
          eventType.includes('DIAGNOSTIC') ||
          eventType.includes('MALFUNCTION') ||
          eventType.includes('SIGNATURE') ||
          eventType.includes('SESSION') ||
          eventType.includes('DUTY_STATUS') ||
          eventType.includes('ENGINE')) &&
        annotation &&
        annotation.length !== 0
    );
  }, [eldEvents]);

  const annotationsEventsDataGridController = useDataGridController({
    columnDefs: annotationsEventsColumnDefs(referencedDrivers, referencedSupportUsers, timeZone),
    gridOptions,
  });

  const annotationsEventsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
  });

  useEffect(() => {
    annotationsEventsDataGridController.setState(
      createState({
        columnDefs: annotationsEventsColumnDefs(
          referencedDrivers,
          referencedSupportUsers,
          timeZone
        ),
      })
    );
  }, [timeZone]);

  return (
    <Card fullWidth>
      <div>
        <h4>Comments, Remarks and Annotations</h4>
      </div>
      {annotationsEvents && (
        <ControlledDataTable
          controller={annotationsEventsDataGridController}
          components={annotationsEventsDataGridComponents}
          rows={annotationsEvents}
          rowIdProperty="key"
        />
      )}
      <PrintLayout mode={printLayoutMode} inline />
    </Card>
  );
};

AnnotationsEventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  printLayoutMode: PropTypes.string,
};
