import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, Icon, TextInput, useBooleanState } from 'stti-react-common';

export const BookmarkList = ({
  bookMarkCoordinates,
  deleteBookmarking,
  editBookmark,
  id,
  listName,
  onSetFocus,
  setBookmarkLocation,
  register,
}) => {
  const [typing, SetTyping] = useState(listName);
  const [isActivateBookmark, editActivateBookmark, closeActivateBookmark] = useBooleanState();

  return (
    <li className={`list-group-box ${isActivateBookmark ? 'list-group-edit-box' : ''}`}>
      {isActivateBookmark ? (
        <>
          <TextInput value={typing} onChange={SetTyping} />
          <div className="bookmark-action-container">
            <Icon
              className="check-bookmark-bdn"
              icon="check"
              onClick={() => editBookmark(bookMarkCoordinates, typing, closeActivateBookmark)}
            />
            <Icon icon="clear" onClick={() => closeActivateBookmark()} />
            <Icon
              className="delete-bookmark-bdn"
              icon="delete"
              onClick={() => deleteBookmarking(id, closeActivateBookmark)}
            />
          </div>
        </>
      ) : (
        <>
          <Clickable
            onClick={() =>
              onSetFocus({
                lat: bookMarkCoordinates.centre.latitude,
                lng: bookMarkCoordinates.centre.longitude,
              })
            }
          >
            {listName}
          </Clickable>
          <Icon icon="edit" onClick={() => editActivateBookmark()} />
          <Icon
            icon={`${register?.id === id ? 'star' : 'star_border'}`}
            onClick={() => setBookmarkLocation(bookMarkCoordinates)}
          />
        </>
      )}
    </li>
  );
};

BookmarkList.propTypes = {
  bookMarkCoordinates: PropTypes.object,
  deleteBookmarking: PropTypes.func,
  editBookmark: PropTypes.func,
  id: PropTypes.string,
  listName: PropTypes.string,
  onSetFocus: PropTypes.func,
  register: PropTypes.object,
  setBookmarkLocation: PropTypes.func,
};
