import React from 'react';

import { ActivitySummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { configureRoute, SUPER_ADMIN_ROLE_KEY, administrationGroups } from '../../../../config';

export const activitySummary = configureRoute({
  name: 'Activities',
  group: administrationGroups.configurations,
  order: 1,
  path: '/activities',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY],
  render: (props) => <ActivitySummaryContainer {...props} />,
});
