import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  countOf,
  countUniqueOfField,
  sumOfField,
  meanOfField,
} from '../../../../helpers/reports/aggregation';
import { useFormats } from '../../../commons/Formats';

export const AggregatesContent = ({ rows }) => {
  const { formatUnit } = useFormats();

  return (
    <>
      <AggregatesCard
        title="Overview"
        name="overview"
        fields={[
          {
            header: 'Vehicles',
            value: countUniqueOfField(rows, 'vehicle.key'),
          },
          {
            header: 'Drivers',
            value: countUniqueOfField(rows, 'driver.key'),
          },
          {
            header: 'Trips',
            value: countOf(rows),
          },
        ]}
      />
      <AggregatesCard
        title="Distance"
        name="distance"
        fields={[
          {
            header: 'Empty',
            value: formatUnit('distance', sumOfField(rows, 'empty.distance')),
          },
          {
            header: 'Loaded',
            value: formatUnit('distance', sumOfField(rows, 'loaded.distance')),
          },
          {
            header: 'Overall',
            value: formatUnit('distance', sumOfField(rows, 'overall.distance')),
          },
        ]}
      />
      <AggregatesCard
        title="Fuel"
        name="fuel"
        fields={[
          {
            header: 'Empty',
            value: formatUnit('volume', sumOfField(rows, 'empty.consumedFuel')),
          },
          {
            header: 'Loaded',
            value: formatUnit('volume', sumOfField(rows, 'loaded.consumedFuel')),
          },
          {
            header: 'Overall',
            value: formatUnit('volume', sumOfField(rows, 'overall.consumedFuel')),
          },
        ]}
      />
      <AggregatesCard
        title="Idling"
        name="idling"
        fields={[
          {
            header: 'Empty',
            value: formatUnit('duration', sumOfField(rows, 'empty.idlingDuration')),
          },
          {
            header: 'Loaded',
            value: formatUnit('duration', sumOfField(rows, 'loaded.idlingDuration')),
          },
          {
            header: 'Overall',
            value: formatUnit('duration', sumOfField(rows, 'overall.idlingDuration')),
          },
        ]}
      />
      <AggregatesCard
        title="Idle Time"
        name="idle time"
        fields={[
          {
            header: 'Empty',
            value: `${formatUnit('number', meanOfField(rows, 'empty.idleTime'))} %`,
          },
          {
            header: 'Loaded',
            value: `${formatUnit('number', meanOfField(rows, 'loaded.idleTime'))} %`,
          },
          {
            header: 'Overall',
            value: `${formatUnit('number', meanOfField(rows, 'overall.idleTime'))} %`,
          },
        ]}
      />
      <AggregatesCard
        title="Idle Fuel"
        name="idle fuel"
        fields={[
          {
            header: 'Empty',
            value: `${formatUnit('number', meanOfField(rows, 'empty.idleFuel'))}%`,
          },
          {
            header: 'Loaded',
            value: `${formatUnit('number', meanOfField(rows, 'loaded.idleFuel'))}%`,
          },
          {
            header: 'Overall',
            value: `${formatUnit('number', meanOfField(rows, 'overall.idleFuel'))}%`,
          },
        ]}
      />
      <AggregatesCard
        title="Rate"
        name="rate"
        fields={[
          {
            header: 'Empty',
            value: formatUnit('mileage', meanOfField(rows, 'empty.consumption')),
          },
          {
            header: 'Loaded',
            value: formatUnit('mileage', meanOfField(rows, 'loaded.consumption')),
          },
          {
            header: 'Overall',
            value: formatUnit('mileage', meanOfField(rows, 'overall.consumption')),
          },
        ]}
      />
    </>
  );
};

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
