import { connect } from 'react-redux';
import { VideoOptions } from './VideoOptions';
import {
  selectVideoEventTypes,
  selectVideoEventBehaviors,
  fetchVideosTypesOptimized,
  fetchVideosBehaviorsOptimized,
} from '../../../../../data/reports';

export const VideoOptionsContainer = connect(
  (state) => ({
    videoEventTypes: selectVideoEventTypes(state),
    videoEventBehaviors: selectVideoEventBehaviors(state),
  }),
  {
    fetchVideoEventTypes: fetchVideosTypesOptimized,
    fetchVideoEventBehaviors: fetchVideosBehaviorsOptimized,
  }
)(VideoOptions);
