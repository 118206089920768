/**
 * selectDispatchAbortReasons(state) -> [dispatchAbortReasons]
 *
 * Selector for all dispatchAbortReasons
 */
export const selectDispatchAbortReasons = (state) =>
  state.administration.configurations.dispatchAbortReasons.data;

/**
 * selectDispatchAbortReasonsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on dispatchAbortReasons
 */
export const selectDispatchAbortReasonsFetchRequests = (state) =>
  state.administration.configurations.dispatchAbortReasons.fetches;
