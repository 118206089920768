import { ACTION_TYPE } from './notificationsReducer';

export const enqueueNotification = (notification) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.enqueueNotification,
    notification: {
      notification,
    },
  });
};

export const dequeueNotification = (notification) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.dequeueNotification, payload: notification });
};
