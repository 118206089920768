import React from 'react';
import PropTypes from 'prop-types';

import { ExpansionPanel, Icon, TextInput } from 'stti-react-common';
import './AdminSearchPanel.scss';

export const AdminSearchPanel = ({
  searchPlaceHolder,
  searchText,
  onSearchTextChanged,
  renderPanelActions,
}) => (
  <div className="AdminSearchPanel">
    <ExpansionPanel
      summary={
        <TextInput
          startAdornment={<Icon icon="search" />}
          placeholder={searchPlaceHolder}
          value={searchText}
          onChange={onSearchTextChanged}
        />
      }
      summaryEnd={renderPanelActions || null}
      isLocked
    />
  </div>
);

AdminSearchPanel.propTypes = {
  searchPlaceHolder: PropTypes.string,
  searchText: PropTypes.string,
  onSearchTextChanged: PropTypes.func,
  renderPanelActions: PropTypes.node,
};
