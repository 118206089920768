import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isArray, isEmpty, isNil, isObject } from 'lodash';
import { Card, Clickable, useBooleanState } from 'stti-react-common';

import { InfoField, HidePrint } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { NA_VALUE } from '../../../../../helpers/formats';
import { formatTimezone } from '../../../../../helpers/moment';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import { MalfunctionAndDiagnosticInfoDialog } from '../Dialogs/MalfunctionAndDiagnosticInfoDialog';

const formatBooleanValue = (value) => {
  if (!isArray(value)) return 'No';
  return isEmpty(value) ? 'No' : 'Yes';
};

const formatHealthCodes = (activeMalfunctions, activeDiagnostics) => {
  if (!isArray(activeMalfunctions) && !isArray(activeDiagnostics)) return '-';
  const sanitizedMalfunctions = activeMalfunctions.map((item) => (isObject(item) ? item.code : item));
  const sanitizedDiagnostics = activeDiagnostics.map((item) => (isObject(item) ? item.code : item));
  const sanitizedHealthCodes = [ ...sanitizedMalfunctions, ...sanitizedDiagnostics];
  return sanitizedHealthCodes.join(', ');
};

export const CarrierCard = ({ log, hidePrint }) => {
  const { formatUnit } = useFormats();

  const {
    carrierDetails,
    homeTerminalDetails,
    startedAt,
    timeZone,
    regulation,
    cycleName,
    shippingDocuments,
    malfunctionIndication,
    diagnosticIndication,
    unidentifiedDriving,
    health,
    activeMalfunctions,
    activeDiagnostics,
    providerInfo,
  } = log || {};

  const [isInfoDialogOpen, openInfoDialog, closeInfoDialog] = useBooleanState();

  const startedAtMoment = moment(startedAt).tz(timeZone);

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__CarrierCard" fullWidth>
        <h4>Carrier</h4>
        <div className="DriverLogDetailReport__infoFieldContainer">
          <InfoField
            name="recordDate"
            label="Record date"
            value={formatUnit('date', startedAtMoment)}
          />
          <InfoField
            name="timeZone"
            label="Timezone"
            value={formatTimezone(formatUnit('date', startedAtMoment, 'Z'))}
          />
          <InfoField
            name="regulation"
            label="Regulations"
            value={regulation && cycleName ? `${regulation} - ${cycleName}` : NA_VALUE}
          />
          <InfoField
            name="eldId"
            label="ELD ID - Provider"
            value={`${providerInfo && providerInfo.identifier} - ${
              providerInfo && providerInfo.certificationId
            }`}
          />
          <InfoField
            name="carrierName"
            label="Carrier Name"
            value={carrierDetails && carrierDetails.name}
          />
          <InfoField
            name="usDot"
            label="US DOT #"
            value={carrierDetails && carrierDetails.usDotNumber}
          />
          <InfoField
            name="homeTerminalName"
            label="Home Terminal Name"
            value={homeTerminalDetails && homeTerminalDetails.name}
          />
          <InfoField
            name="carrierAddress"
            label="Carrier Address"
            value={carrierDetails && carrierDetails.address}
          />
          <InfoField
            name="canDot"
            label="Can DOT #"
            value={carrierDetails && carrierDetails.canDotNumber}
          />
          <InfoField
            name="homeTerminalAddress"
            label="Home Terminal Address"
            value={homeTerminalDetails && homeTerminalDetails.address}
          />
          <InfoField
            name="principalPlaceOfBusiness"
            label="Principal Place Of Business"
            value={carrierDetails && carrierDetails.principalBusinessName}
          />
          <InfoField name="shippingIds" label="Shipping Ids" value={shippingDocuments} />
          <InfoField
            name="malfunctionIndication"
            label="Malfunction Indication"
            value={formatBooleanValue(activeMalfunctions)}
          />
          <InfoField
            name="diagnosticIndication"
            label="Diagnostic Indication"
            value={formatBooleanValue(activeDiagnostics)}
          />
          <InfoField
            name="healthCodes"
            label="Health Codes"
            value={formatHealthCodes(activeMalfunctions, activeDiagnostics)}
          />
          <InfoField
            name="unidentifiedDriving"
            label="Unidentified Driving"
            value={unidentifiedDriving ? 'Yes' : 'No'}
          />
        </div>
        <div>
          <h6>
            <Clickable onClick={() => openInfoDialog()}>
              Malfunctions and diagnostics Info
            </Clickable>
          </h6>
        </div>
      </Card>
      <MalfunctionAndDiagnosticInfoDialog isOpen={isInfoDialogOpen} onClose={closeInfoDialog} />
    </HidePrint>
  );
};

CarrierCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  hidePrint: PropTypes.bool,
};
