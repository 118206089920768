import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';
import { noop, isFunction } from 'lodash';

import { useReport, FetchNaviMapData } from '../ReportsCommon';
import { Geozones, MapTypeControl } from '../MapsCommon';
import { PrintLayout } from '../PrintLayout';
import { NotReadyToPrint } from '../PrintSupport';

import './NaviMap.scss';

const { Controls, Placeholder, useMap } = GoogleMap;

const NotReadyToPrintUntilTilesLoaded = () => {
  const { map } = useMap() || {};

  const [isTilesLoaded, setIsTilesLoaded] = useState();

  useEffect(() => {
    if (!map) return noop;
    const listener = map.addListener('tilesloaded', () => {
      window.setTimeout(() => {
        setIsTilesLoaded(true);
      }, 500); // add another 500ms for map to render
    });
    return () => listener.remove();
  }, [map]);

  return isTilesLoaded ? null : <NotReadyToPrint />;
};

const BoundsListener = ({ onBoundsChange, onZoomChange }) => {
  const { map, zoom } = useMap() || {};

  const bounds = map.getBounds();

  useEffect(() => {
    if (!bounds?.getSouthWest().lat() && bounds?.getNorthEast().lng()) return;

    if (onZoomChange && isFunction(onZoomChange)) {
      onZoomChange(zoom, { sw: bounds?.getSouthWest(), ne: bounds?.getNorthEast() });
    }

    onBoundsChange([bounds?.getSouthWest().lat(), bounds?.getSouthWest().lng()]);
  }, [bounds?.getSouthWest().lat(), bounds?.getNorthEast().lng()]);
  return null;
};

BoundsListener.propTypes = {
  onBoundsChange: PropTypes.func,
  onZoomChange: PropTypes.func,
};

export const NaviMap = ({
  canEditGeozones,
  children,
  disableNaviMapTypeButton,
  focus,
  focusZoom,
  hideGeozoneInfo = false,
  hideGeozones,
  hideGeozoneTypes = [],
  onReset,
  onSelect,
  onZoomChange,
  printLayoutMode,
  printLayoutSuppressMapMode,
  printLayoutSuppressMapProp,
  selectedGeozone,
}) => {
  const [trackedBounds, setTrackedBounds] = useState();

  const report = useReport();
  const { controls } = report;

  if (!report) throw new Error('NaviMap must be used within a ReportProvider');

  if (printLayoutSuppressMapMode) return null;

  if (!focus) return <Placeholder />;

  return (
    <>
      <FetchNaviMapData />
      <div className="NaviMap">
        <GoogleMap
          focus={focus}
          onSelect={onSelect}
          mapType={controls.mapType}
          focusZoom={focusZoom}
        >
          <BoundsListener onBoundsChange={setTrackedBounds} onZoomChange={onZoomChange} />
          {!disableNaviMapTypeButton && (
            <Controls controlPosition="leftBottom">
              <MapTypeControl />
            </Controls>
          )}
          {!hideGeozones && (
            <Geozones
              selectedGeozone={selectedGeozone}
              onReset={onReset}
              canEdit={canEditGeozones}
              hideInfo={hideGeozoneInfo}
              hideGeozoneTypes={hideGeozoneTypes}
            />
          )}
          {children}
        </GoogleMap>
      </div>
      {printLayoutMode && !printLayoutSuppressMapProp && (
        <PrintLayout mode={printLayoutMode}>
          <div className="NaviMap--print">
            <GoogleMap focus={trackedBounds || focus} mapType={controls.mapType} disableAnimation>
              <NotReadyToPrintUntilTilesLoaded />
              {!hideGeozones && <Geozones />}
              {children}
            </GoogleMap>
          </div>
        </PrintLayout>
      )}
    </>
  );
};

NaviMap.propTypes = {
  canEditGeozones: PropTypes.bool,
  children: PropTypes.node,
  disableNaviMapTypeButton: PropTypes.bool,
  focus: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  focusZoom: PropTypes.number,
  hideGeozoneInfo: PropTypes.bool,
  hideGeozones: PropTypes.bool,
  hideGeozoneTypes: PropTypes.arrayOf(PropTypes.string),
  onReset: PropTypes.func,
  onSelect: PropTypes.func,
  onZoomChange: PropTypes.func,
  printLayoutMode: PropTypes.string,
  printLayoutSuppressMapMode: PropTypes.bool,
  printLayoutSuppressMapProp: PropTypes.bool,
  selectedGeozone: PropTypes.object,
};
