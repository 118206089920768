import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';

import './ManageAdminDialog.scss';

export const InfoAdminItemDialog = ({ dialogTitle, dialogText, isOpen, onClose }) => (
  <BasicDialog
    title={dialogTitle}
    className="InfoAdminItemDialog"
    maxWidth="lg"
    isOpen={isOpen}
    onClose={onClose}
  >
    {dialogText}
  </BasicDialog>
);

InfoAdminItemDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  dialogText: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
