import { connect } from 'react-redux';

import { FuelConsumptionsSummaryReport } from './FuelConsumptionsSummaryReport';
import { selectFuelConsumptions, fetchFuelConsumptionsOptimized } from '../../../../data/reports';
import { selectSearchLimits, setPrintMode } from '../../../../data/system';

export const FuelConsumptionsSummaryReportContainer = connect(
  (state) => ({
    fuelConsumptions: selectFuelConsumptions(state),
    limits: selectSearchLimits(state, 'fuelConsumptions'),
  }),
  { fetchFuelConsumptions: fetchFuelConsumptionsOptimized, setPrintMode }
)(FuelConsumptionsSummaryReport);
