import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import moment from 'moment-timezone';

import { BasicDialog, Button, Form, TabularInput, TextInput, SelectInput } from 'stti-react-common';

const { useFormController, Control } = Form;

const validFormat = 'YYYY-MM-DDTHH:mm';

const setParamsByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return [
        { key: 'startAt', value: moment().format(validFormat) },
        { key: 'endAt', value: moment().format(validFormat) },
      ];
    case 'activateDevice':
      return [
        { key: 'activationCode', value: null },
        { key: 'assetTag', value: null },
      ];
    case 'reActivateDevice':
      return [
        { key: 'activationCode', value: null },
        { key: 'assetTag', value: null },
      ];
    default:
      return [];
  }
};

const setTypeByTemplate = (template) => {
  switch (template) {
    case 'noTemplate':
      return '';
    case 'dataDiagnosticRequest':
      return 'logAppender';
    default:
      return template;
  }
};

const setInputTypeByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return 'datetime-local';

    default:
      return 'text';
  }
};

export const TabletDebugDialog = ({ isOpen, onClose, onConfirm, rowData }) => {
  const [currentTemplate, setCurrentTemplate] = useState('noTemplate');

  const form = useFormController({
    controls: {
      key: rowData.key,
      params: [],
    },
  });

  const { resetControls, controls, hasErrors, isDirty, setControl } = form;

  const readOnlyType = [
    'logAppender',
    'deactivateDevice',
    'activateDevice',
    'reActivateDevice',
  ].includes(controls.type);

  useEffect(() => {
    resetControls();
  }, [rowData]);

  useEffect(() => {
    setCurrentTemplate('noTemplate');
  }, [isOpen]);

  useEffect(() => {
    setControl('params', setParamsByTemplate(currentTemplate));
    setControl('type', setTypeByTemplate(currentTemplate));
  }, [currentTemplate]);

  const setErrorByTemplate = (template, value) => {
    switch (template) {
      case 'dataDiagnosticRequest': {
        if (isEmpty(value)) {
          return true;
        }

        if (!moment(value).isValid() || value.length !== validFormat.length) {
          return `Invalid date/time entered, time must be formatted as ${validFormat}`;
        }
        return false;
      }
      default:
        return !value;
    }
  };

  return (
    <BasicDialog
      className="TabletSummary__TabletDebugDialog"
      isOpen={isOpen}
      onClose={onClose}
      title={`Set Remote Action for: ${rowData.assetTag}`}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Add New Config',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            const { params, ...rest } = controls;
            const payloadParams = map(params, ({ key, value }) => {
              if (key === 'startAt' || key === 'endAt') {
                return { key, value: moment(value).toISOString() };
              }
              return { key, value };
            });
            onConfirm({ ...rest, params: payloadParams });
            onClose();
          },
        },
      ]}
    >
      <SelectInput
        className="AdminSearchPanel__SelectInput--aligned"
        value={currentTemplate}
        options={[
          { label: 'No Template', value: 'noTemplate' },
          { label: 'Data Diagnostic Request', value: 'dataDiagnosticRequest' },
          //{ label: 'Deactivate Device', value: 'deactivateDevice' },
          //{ label: 'Activate Device', value: 'activateDevice' },
          //{ label: 'Re-activate Device', value: 'reActivateDevice' },
        ]}
        onChange={(value) => setCurrentTemplate(value)}
      />

      <Form form={form}>
        <div className="TabletDebugDialog__controls">
          <Control
            name="type"
            Component={TextInput}
            label="Type"
            error={({ value }) => !value}
            readOnly={readOnlyType}
          />
        </div>
        <div className="TabletDebugDialog__diagnosticKeyPairs">
          <Control
            Component={TabularInput}
            renderHeader={({ addRow }) => (
              <div className="TabularInput__title">
                <h4>Remote Action Params</h4>
                <Button
                  variant="outlined"
                  label="Add Key/Value"
                  onClick={addRow}
                  disabled={currentTemplate !== 'noTemplate'}
                />
              </div>
            )}
            renderRow={({ deleteRow }) => (
              <>
                <Control
                  Component={TextInput}
                  name="key"
                  label="Key"
                  error={({ value }) => !value}
                  disabled={currentTemplate !== 'noTemplate'}
                />
                <Control
                  Component={TextInput}
                  name="value"
                  label="Value"
                  type={setInputTypeByTemplate(currentTemplate)}
                  error={({ value }) => setErrorByTemplate(currentTemplate, value)}
                />
                <Button
                  variant="outlined"
                  label="Delete Row"
                  icon="clear"
                  onClick={deleteRow}
                  disabled={currentTemplate !== 'noTemplate'}
                />
              </>
            )}
            name="params"
            ownErrorName="paramsTableError"
          />
        </div>
      </Form>
    </BasicDialog>
  );
};

TabletDebugDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};
