export const scrubIsSystemProperty = ({ isSystem, ...rest }) => ({ ...rest }); // drop isSystem Property

export const appendIsSystemProperty = ({ _ownBy, ...rest }) => ({
  ...rest,
  isSystem: _ownBy === 'System',
});

/**
 * This function is used to remove params from delete operations that use the structure /api/{deleteKey}
 * Purpose for adding this is some deleteRequests pass the key in the params and not the api url (ex: /reportSchedules)
 */
export const removeDeleteParams = () => ({});
