import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Information',
    children: [
      columnDef.textColumn({
        field: 'ou.name',
        headerName: 'Organization',
      }),
      columnDef.textColumn({
        field: 'vehicleName',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        enableRowGroup: true,
        rowGroupIndex: 0,
      }),

      columnDef.textColumn({
        field: 'driverName',
        headerName: 'Driver',
        cellRenderer: 'Driver',
      }),
    ],
  },
  {
    headerName: 'Locations',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Date',
      }),
      columnDef.iconColumn({
        colId: 'routeMapStart',
        cellRenderer: 'TaxFuelMileageMap',
        headerName: 'Tax Fuel Mileage Map',
        headerComponent: 'TaxFuelMileageHeader',
        suppressExcelExport: true,
      }),
      columnDef.textColumn({
        field: 'unloadedLeg.locationName',
        headerName: 'Start Location',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Date',
      }),
      columnDef.iconColumn({
        colId: 'routeMapEnd',
        cellRenderer: 'TaxFuelMileageMap',
        headerName: 'Tax Fuel Mileage Map',
        headerComponent: 'TaxFuelMileageHeader',
        suppressExcelExport: true,
      }),
      columnDef.textColumn({
        field: 'loadedLeg.locationName',
        headerName: 'End Location',
      }),
    ],
  },
  {
    headerName: 'Distances',
    children: [
      columnDef.textColumn({
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
      }),
      columnDef.distanceColumn({
        field: 'distance.distance',
        headerName: 'Distance',
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'distance.onHighway',
        headerName: 'On Highway',
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'distance.offHighway',
        headerName: 'Off Highway',
        aggFunc: 'sum',
      }),
    ],
  },
];
