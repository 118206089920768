import { useRef, useEffect, useState } from 'react';

export const useDebounce = (input, delay, initial) => {
  const debounceTimerRef = useRef();
  const [debounced, setDebounced] = useState(initial);
  useEffect(() => {
    window.clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = window.setTimeout(() => {
      setDebounced(input);
    }, delay);
  }, [input]);

  return debounced;
};
