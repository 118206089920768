import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import { isString, isEmpty, reject, uniqBy, sortBy } from 'lodash';
import ReactGA from 'react-ga';

import { analytics } from '../../../data/analytics';
import { BasicRouteFrame } from './RouteFrame';

import {
  ADMIN_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  DRIVER_ROLE_KEY,
  GUEST_ROLE_KEY,
  MANAGER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
  SUPER_ADMIN_ROLE_KEY,
} from '../../../data/system/constants';

import { version } from '../../../helpers/debug';

version();

const RESTRICTED_ROUTES = ['NaviLinks'];

const hasRole = (role, type) => {
  const roleToString = isString(role) ? role : role.key;
  return roleToString.includes(type);
};

const removeStreamLineRestrictedRoutes = (appRoutes, user) => {
  let filteredRoutes = appRoutes;

  if (!user?.username?.includes('streamline@')) {
    filteredRoutes = reject(appRoutes, (route) => RESTRICTED_ROUTES.includes(route.name));
  }

  return filteredRoutes;
};

export const filterRoutesByPermissions = (user, tenant, ous, activeOu, appRoutes) => {
  if (isEmpty(user) || isEmpty(tenant)) {
    return [];
  }

  const filteredAppRoutes = removeStreamLineRestrictedRoutes(appRoutes, user);

  const userRoles = {
    isDriver: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, DRIVER_ROLE_KEY))
    ),
    isSystem: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, SUPER_ADMIN_ROLE_KEY))
    ),
    isAdmin: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ADMIN_ROLE_KEY))
    ),
    isManager: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, MANAGER_ROLE_KEY))
    ),
    isMechanic: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, MECHANIC_ROLE_KEY))
    ),
    isDispatcher: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, DISPATCHER_ROLE_KEY))
    ),
    isGuest: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, GUEST_ROLE_KEY))
    ),
  };

  const totalAllowedRoutes = [];

  const filteredRoutesByTenantAndDriver = reject(
    filteredAppRoutes,
    ({ tenantEnabledKey, driverOnly }) =>
      driverOnly || (tenantEnabledKey && !tenant[tenantEnabledKey])
  );

  // means it is super-admin and need to return all routes
  if (userRoles.isSystem) {
    return filteredRoutesByTenantAndDriver;
  }

  if (userRoles.isAdmin) {
    totalAllowedRoutes.push(
      ...filteredRoutesByTenantAndDriver.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(ADMIN_ROLE_KEY))
      )
    );
  }

  if (userRoles.isManager) {
    totalAllowedRoutes.push(
      ...filteredRoutesByTenantAndDriver.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(MANAGER_ROLE_KEY))
      )
    );
  }

  if (userRoles.isDriver) {
    const filteredRoutesByTenant = reject(
      filteredAppRoutes,
      ({ tenantEnabledKey }) => tenantEnabledKey && !tenant[tenantEnabledKey]
    );

    totalAllowedRoutes.push(
      ...filteredRoutesByTenant.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(DRIVER_ROLE_KEY))
      )
    );
  }

  if (userRoles.isMechanic) {
    totalAllowedRoutes.push(
      ...filteredRoutesByTenantAndDriver.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(MECHANIC_ROLE_KEY))
      )
    );
  }

  if (userRoles.isDispatcher) {
    totalAllowedRoutes.push(
      ...filteredRoutesByTenantAndDriver.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(DISPATCHER_ROLE_KEY))
      )
    );
  }

  if (userRoles.isGuest) {
    totalAllowedRoutes.push(
      ...filteredRoutesByTenantAndDriver.filter(
        ({ permissions, redirectTo }) =>
          redirectTo || (permissions && permissions.includes(GUEST_ROLE_KEY))
      )
    );
  }

  // Now get routes that does not have permissions attached and add it
  totalAllowedRoutes.push(
    ...filteredRoutesByTenantAndDriver.filter(
      ({ permissions, redirectTo }) => redirectTo || !permissions
    )
  );

  // lets make sure we don't have any route with same path
  const routesWithPath = uniqBy(
    totalAllowedRoutes.filter(({ path }) => path),
    'path'
  );
  const routesWithoutPath = totalAllowedRoutes.filter(({ path }) => !path);

  return routesWithPath.concat(routesWithoutPath);
};

const RouteMatcher = withRouter(({ location, Index, Fallback, availableRoutes, menuCategory }) => {
  const routeFrames = [];
  const matchedSwitchKeys = {};
  let mustRedirectTo = null;
  let deferToAngularRouter = false;

  if (location.pathname === '/') mustRedirectTo = 'login';

  ReactGA.pageview(window.location.pathname + window.location.search);

  // match views
  availableRoutes.forEach((route) => {
    const {
      name,
      path,
      match,
      switchKey,
      render,
      redirectTo,
      RouteFrame,
      close,
      getState,
      isAngular,
    } = route;
    const viewKey = name || path || redirectTo;

    if (matchedSwitchKeys[switchKey]) return; // switchKey already matched, skip

    const matched = match(location.pathname.replace(/\/$|\/\?/, '')); // strip trailing slash; returns object populated with matched params/values, or false if unmatched

    if (matched) {
      if (isAngular) {
        deferToAngularRouter = true;
      } else if (redirectTo) {
        mustRedirectTo = mustRedirectTo || redirectTo; // keep first redirect if applicable
      } else {
        if (switchKey) matchedSwitchKeys[switchKey] = true;
        routeFrames.push(
          <RouteFrame
            key={viewKey}
            render={() =>
              render({
                route: { availableRoutes, ...route, matched },
                ...matched.values,
                ...getState(),
              })
            }
            availableRoutes={availableRoutes}
            onClose={close}
            customClass={menuCategory}
          />
        );
      }
    }
  });

  // leave to Angular router if applicable
  if (deferToAngularRouter) return null;

  // redirect if applicable
  if (mustRedirectTo) return <Redirect to={mustRedirectTo} />;

  if (!routeFrames.length && location.pathname !== '/') {
    routeFrames.push(
      <BasicRouteFrame key="Fallback" render={() => <Fallback />} customClass={menuCategory} />
    );
  }

  // insert index view as bottommost layer, if applicable
  if (Index) {
    routeFrames.unshift(
      <BasicRouteFrame key="Index" render={() => <Index />} customClass={menuCategory} />
    );
  }

  // analytics
  analytics.setLocation(`${location.pathname}${location.search}`);

  // return views, for some reason administration is returned correctly by super admin roles but
  // not by the other roles, this is made to normalize the behavior between multiple roles
  const sortedRoutes = sortBy(routeFrames, ({ key }) => (key === '/administration' ? 0 : 1));
  return <TransitionGroup component={null}>{sortedRoutes}</TransitionGroup>;
});

export const AppRouter = ({ Index, Fallback, availableRoutes, menuCategory }) => (
  <Router>
    <RouteMatcher
      Index={Index}
      Fallback={Fallback}
      availableRoutes={availableRoutes}
      menuCategory={menuCategory}
    />
  </Router>
);

AppRouter.propTypes = {
  Index: PropTypes.elementType,
  Fallback: PropTypes.elementType.isRequired,
  availableRoutes: PropTypes.array.isRequired,
  menuCategory: PropTypes.string,
};
