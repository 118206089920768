import { filter } from 'lodash';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import {
  scrubIsSystemProperty,
  appendIsSystemProperty,
  removeDeleteParams,
} from '../../../../helpers/admin/adminActionSupport';
import { selectInspectionTasksFetchRequests } from './selectors';

const fetchInspectionTasks = makeRestApiAction({
  service: 'inspectionTasks',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspectionTasks,
  transformInput: () => ({
    fetch: `ou`,
  }),
  transformOutput: (responseData) => responseData.map(appendIsSystemProperty),
  notificationsItemDescriptor: 'inspection tasks',
});

export const fetchInspectionTasksOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectInspectionTasksFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchInspectionTasks,
  baseActionType: ACTION_TYPE.fetchInspectionTasks,
});

const fetchInspectionTask = makeRestApiAction({
  service: 'inspectionTasks',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspectionTask,
  getId: ({ key }) => key,
  transformInput: () => ({
    fetch: 'ou',
  }),
  transformOutput: (responseData) => responseData.map(appendIsSystemProperty),
  notificationsItemDescriptor: 'inspection task',
});

export const fetchInspectionTaskOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectInspectionTasksFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { actionParam: id }),
  fetchAction: fetchInspectionTask,
  baseActionType: ACTION_TYPE.fetchInspectionTask,
});

export const deleteInspectionTask = makeRestApiAction({
  service: 'inspectionTasks',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteInspectionTask,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'inspection task',
});

export const createInspectionTask = makeRestApiAction({
  service: 'inspectionTasks',
  method: 'post',
  baseActionType: ACTION_TYPE.createInspectionTask,
  transformInput: scrubIsSystemProperty,
  transformOutput: appendIsSystemProperty,
  notificationsItemDescriptor: 'inspection task',
  throwApiErrors: true,
});

export const updateInspectionTask = makeRestApiAction({
  service: 'inspectionTasks',
  method: 'put',
  baseActionType: ACTION_TYPE.updateInspectionTask,
  getId: ({ key }) => key,
  transformInput: scrubIsSystemProperty,
  transformOutput: appendIsSystemProperty,
  notificationsItemDescriptor: 'inspection task',
  throwApiErrors: true,
});
