import { combineReducers } from 'redux';

import { location } from './locations';
import { locationType } from './locationTypes';
import { role } from './roles';
import { user } from './users';
import { vehicle } from './vehicles';
import { vehicleType } from './vehicleTypes';

export const resources = combineReducers({
  locations: location.reducer.locationsReducer,
  locationTypes: locationType.reducer.locationTypesReducer,
  roles: role.reducer.roleReducer,
  users: user.reducer.usersReducer,
  vehicles: vehicle.reducer.vehiclesReducer,
  vehicleTypes: vehicleType.reducer.vehicleTypesReducer,
});

export { location, locationType, role, user, vehicle, vehicleType };
