import { connect } from 'react-redux';

import { Sidebar } from './Sidebar';
import { selectSystemTenant, selectSystemUser, setMenu } from '../../../data/system';

export const SidebarContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    tenant: selectSystemTenant(state),
  }),
  { setMenu }
)(Sidebar);
