import { useEffect } from 'react';

import { useReport } from '../useReport';

export const FetchPlaceholder = () => {
  const { registerFetch } = useReport();

  useEffect(registerFetch, []);

  return null;
};
