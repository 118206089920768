import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Chip } from 'stti-react-common';

import { SectionPanel, InfoField } from '../../../commons/ReportsCommon';
import { useFormats } from '../../../commons/Formats';

export const DetailPanel = ({ shift }) => {
  const { formatUnit } = useFormats();

  const { startedAt, endedAt, timeZone, duration, driver, vehiclesNames, trailersNames } =
    shift || {};

  const formattedStartedAt = startedAt && formatUnit('date', moment(startedAt).tz(timeZone));

  const formattedEndedAt = endedAt && formatUnit('date', moment(endedAt).tz(timeZone));

  const formattedDuration = duration && formatUnit('duration', duration);

  return (
    <SectionPanel
      name="detailPanel"
      title="Detail"
      renderSummaries={() =>
        shift && (
          <>
            <Chip label={`Driver: ${driver && driver.name}`} />
            <Chip label={`Vehicles: ${vehiclesNames && vehiclesNames.join(', ')}`} />
            <Chip label={`Trailers: ${trailersNames && trailersNames.join(', ')}`} />
            <Chip label={`${formattedStartedAt} to ${formattedEndedAt} (${formattedDuration})`} />
          </>
        )
      }
    >
      <InfoField name="driver" label="Driver" value={driver && driver.name} />
      <InfoField name="vehicles" label="Vehicle(s)" value={vehiclesNames} />
      <InfoField name="trailers" label="Trailer(s)" value={trailersNames} />
      <InfoField name="startedAt" label="Started At" value={formattedStartedAt} />
      <InfoField name="endedAt" label="Ended At" value={formattedEndedAt} />
      <InfoField name="duration" label="Duration" value={formattedDuration} />
    </SectionPanel>
  );
};

DetailPanel.propTypes = {
  shift: PropTypes.shape({
    startedAt: PropTypes.string.isRequired,
    endedAt: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    driver: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    vehiclesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    trailersNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};
