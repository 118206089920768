// EXTERNAL LIB IMPORTS
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { Button, ToggleInput, useBooleanState } from 'stti-react-common';
import { useHistory } from 'react-router-dom';

// COMMON LIB IMPORTS
import {
  AdminSearchPanel,
  AdminExportDataMenu,
  DeleteAdminItemDialog,
} from '../../../../commons/AdminCommon';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { Feature } from '../../../../commons/Feature';

// HELPERS LIB IMPORTS
import { useDebounce } from '../../../../../helpers/hooks';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

// MISC IMPORTS
import { dataGridDefs } from './dataGridDefs';
import { FEATURES } from '../../../../../features';
import { makeColumnDefs } from './columnDefs';

import './summary.scss';

const { useDataGridComponents, useDataGridController, createState } = ControlledDataGrid;

export const VehicleSummary = ({
  cameraDevices,
  deleteVehicle,
  fetchCameraDevices,
  fetchVehicles,
  fetchVehicleTypes,
  isTenantSuperAdmin,
  openActiveOuVehicleConfig,
  openCameraDetail,
  openOrganizationDetail,
  openVehicleCreate,
  openVehicleDetail,
  openVehicleTypeDetail,
  openVehicleTypes,
  route,
  tenant,
  vehicles,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const [showOnlyInactive, setOnlyInactive] = useState(false);
  const [showOnlyWithCameras, setOnlyWithCameras] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const history = useHistory();

  const cameraAllowed = useMemo(() => {
    const { enabledVideoProviders } = tenant;

    return !!enabledVideoProviders;
  }, [tenant]);

  useEffect(() => {
    fetchVehicles();
    fetchVehicleTypes();
    fetchCameraDevices({ ouKey: undefined });
  }, []);

  const renderPayload = {
    openCameraDetail,
    openVehicleDetail,
    setTargetRow,
    openDeleteDialog,
    openVehicleTypeDetail,
    openOrganizationDetail,
    cameraAllowed,
  };

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs: makeColumnDefs(tenant),
  });

  const dataGridComponents = useDataGridComponents(dataGridDefs(renderPayload));

  useEffect(() => {
    dataGridController.setState(createState({ columnDefs: makeColumnDefs(tenant) }));
  }, [tenant]);

  const dataRows = useMemo(() => {
    const filteredVehicles = vehicles?.filter((vehicle) => {
      if (!showOnlyInactive && !showOnlyWithCameras) {
        return vehicle.active === true;
      }
      return (
        (showOnlyInactive ? true : vehicle.active === true) &&
        (showOnlyWithCameras ? !isNil(vehicle.cameraProvider) : true)
      );
    });
    return filteredVehicles;
  }, [vehicles, showOnlyInactive, showOnlyWithCameras]);

  const renderDynamicElement = (cameraId) => {
    if (!cameraId) return undefined;

    return (
      <>
        <p>
          This Vehicle{' '}
          <span className="DeleteAdminItemDialog__display--item">{targetRow.assetId}</span> is
          currently associated to Camera(s):
        </p>
        <ul>
          <li>{cameraDevices.find(({ id }) => id === targetRow?.cameraId)?.assetTag}</li>
        </ul>
        <p>Proceeding will remove related Camera association(s).</p>
      </>
    );
  };

  if (isEmpty(tenant)) return <div />;

  return (
    <Feature.Wrapper className="VehicleSummary">
      <Feature.Header onClose={route.close} title="Vehicles" service="assets">
        {FEATURES.admin.hideVehicleTypesFromMainMenu && (
          <Button
            icon="commute"
            variant="contained"
            label="Vehicle Types"
            onClick={() =>
              openVehicleTypes({ ...route.matched.values }, { history, root: 'administration' })
            }
          />
        )}
        <AdminExportDataMenu dataGridController={dataGridController} filename="Vehicles" />
        {isTenantSuperAdmin && (
          <Button
            icon="share"
            variant="contained"
            label="Vehicle Configuration"
            onClick={() => openActiveOuVehicleConfig({ mode: 'edit' })}
          />
        )}
        <Button
          icon="add"
          variant="contained"
          label="Create New Vehicle"
          onClick={() => openVehicleCreate()}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Vehicles"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
          renderPanelActions={
            <>
              <ToggleInput
                label="Show Inactive"
                value={showOnlyInactive}
                onChange={() => setOnlyInactive(!showOnlyInactive)}
              />
              {!FEATURES.admin.hideCameraMethods && (
                <ToggleInput
                  label="Show With Cameras"
                  value={showOnlyWithCameras}
                  onChange={() => setOnlyWithCameras(!showOnlyWithCameras)}
                />
              )}
            </>
          }
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={dataRows}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          sizeColumnsToFit
          enableCellTextSelection
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Vehicle"
        dynamicElement={renderDynamicElement(targetRow?.cameraId)}
        isOpen={isDeleteDialogOpen}
        itemTitle={targetRow.assetId}
        onClose={closeDeleteDialog}
        onConfirm={deleteVehicle}
        rowData={targetRow}
      />
    </Feature.Wrapper>
  );
};

VehicleSummary.propTypes = {
  cameraDevices: PropTypes.array,
  deleteVehicle: PropTypes.func.isRequired,
  fetchCameraDevices: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  isTenantSuperAdmin: PropTypes.bool,
  openActiveOuVehicleConfig: PropTypes.func.isRequired,
  openCameraDetail: PropTypes.func.isRequired,
  openOrganizationDetail: PropTypes.func.isRequired,
  openVehicleCreate: PropTypes.func.isRequired,
  openVehicleDetail: PropTypes.func.isRequired,
  openVehicleTypeDetail: PropTypes.func.isRequired,
  openVehicleTypes: PropTypes.func.isRequired,
  route: PropTypes.object,
  tenant: customPropTypes.tenant,
  vehicles: customPropTypes.vehicles.isRequired,
};
