import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { uniqBy, last, sortBy, find, uniqueId } from 'lodash';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  SignalWebclientViewReady,
  FetchReportDetail,
} from '../../../commons/ReportsCommon';

// import Cards
import { AnnotationsEventsCard } from './Cards/AnnotationsEventsCard';
import { CycleEventsCard } from './Cards/CycleEventsCard';
import { DriverCard } from './Cards/DriverCard';
import { DriverEventsCard } from './Cards/DriverEventsCard';
import { EnginePowerEventsCard } from './Cards/EnginePowerEventsCard';
import { MalfunctionAndDiagnosticEventsCard } from './Cards/MalfunctionAndDiagnosticEventsCard';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { debugMode } from '../../../../helpers/debug';
import { PrintLayout } from '../../../commons/PrintLayout';

import './UnidentifiedEldLogDetailReport.scss';

const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';

const FEATURE_SHOW_DETAILED_HEADER = true;

const recordOriginCodeAppend = (origin) => {
  if (!origin) return '';
  switch (origin) {
    case 'ELD':
      return 'Eld(1)';
    case 'DRIVER':
      return 'Driver(2)';
    case 'OTHER_USER':
      return 'Other User(3)';
    case 'UNIDENTIFIED_DRIVER':
      return 'Unidentified Driver(4)';
    case 'INTERMEDIATE':
      return 'Intermediate(1)';
    default:
      return origin.toLower();
  }
};

const recordStatusCodeAppend = (status) => {
  if (!status) return '';
  switch (status) {
    case 'ACTIVE':
      return 'Active(1)';
    case 'INACTIVE_CHANGED':
      return 'Inactive Changed(2)';
    case 'INACTIVE_CHANGE_REQUESTED':
      return 'Inactive Change Requested(3)';
    case 'INACTIVE_CHANGE_REJECTED':
      return 'Inactive Change Rejected(4)';
    default:
      return 'Inactive Changed(2)';
  }
};

const findReferencedVehicleByKey = (referencedVehicles, key) => {
  if (!referencedVehicles) return null;
  return (find(referencedVehicles, { key }) || {}).assetId;
};

const cycleCodeByName = (cycle) => {
  if (!cycle) return '';
  switch (cycle) {
    case 'CAD_CYCLE_1':
      return '7';
    case 'CAD_CYCLE_2':
      return '14';
    case 'US_7_DAYS_CYCLE':
      return '7';
    case 'US_8_DAYS_CYCLE':
      return '8';
    default:
      return 'N/A';
  }
};

const eventCodeFormatter = (code) => {
  switch (code) {
    case '1':
    case 'P':
      return 'Power';
    case '2':
    case 'E':
      return 'Engine Sync';
    case 'T':
      return 'Timing';
    case 'L':
      return 'Positioning';
    case 'R':
      return 'Data Recording';
    case '3':
      return 'Missing Data';
    case '4':
    case 'S':
      return 'Data Transfer';
    case '5':
      return 'Unid. Driver';
    case '6':
    default:
      return 'Other';
  }
};

const makeDetailedLog = (unidentifiedLog) => {
  if (!unidentifiedLog) {
    return {};
  }
  const {
    dutyIntervals = [],
    annotations = [],
    effectiveRegulations = [],
    adminStatusChanges = [],
    start,
    violations = [],
    certifications = [], // re-composed into eldEvents
    health = [], // re-composed into eldEvents
    enginePower = [], // re-composed into eldEvents
    sessions = [], // re-composed into eldEvents
    specialMovements = [], // re-composed into eldEvents
    operatingZones = [], // re-composed into eldEvents
    timeDeferralEvents = [], // re-composed into eldEvents
    driverCycleEvents = [], // re-composed into eldEvents
    vehicleDetails = [],
    ...restLog
  } = unidentifiedLog;

  const { timeZone } = unidentifiedLog;
  const { dutyStatusChanges = [] } = restLog;

  return {
    ...restLog,
    vehicleDetails: uniqBy(
      vehicleDetails.map((vehicleDetail) => ({
        currentLocation: (
          last(dutyStatusChanges.filter(({ vehicleKey }) => vehicleKey === vehicleDetail.key)) || {}
        ).geoLocation,
        location: (
          last(dutyStatusChanges.filter(({ vehicleKey }) => vehicleKey === vehicleDetail.key)) || {}
        ).location,
        ...vehicleDetail,
      })),
      'assetId'
    ),
    isDetailed: true,
    canEditLogViolations: false,
    canManageLogDutyStatuses: false,
    driverEvents: sortBy(
      [
        ...dutyIntervals.map((dutyInterval, index) => ({
          date: dutyInterval.start, // has "start" and "end"; add "date" for table
          timeZone,
          ...dutyInterval,
          key: dutyInterval.key || (index === 0 ? 'gap-interval-key' : undefined), // first record may be keyless to due being a mock record to fill midnight-until-event gap
          location: (dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {}).location,
          origin: recordOriginCodeAppend(dutyInterval.origin),
          recordStatus: recordStatusCodeAppend(
            (
              dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
                recordStatus: '',
              }
            ).recordStatus
          ),
          eldSequence: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              eldSequence: '',
            }
          ).eldSequence,
          accumulatedVehicleDistance: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              accumulatedVehicleDistance: 0,
            }
          ).accumulatedVehicleDistance,
          totalVehicleDistance: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              totalVehicleDistance: 0,
            }
          ).totalVehicleDistance,
          elapsedEngineHours: (
            dutyStatusChanges.find(({ key }) => key === dutyInterval.key) || {
              elapsedEngineHours: 0,
            }
          ).elapsedEngineHours,
          asset: findReferencedVehicleByKey(restLog.referencedVehicles, dutyInterval.vehicleKey),
        })),
        ...annotations
          .filter(({ type }) => type !== 'LogComment')
          .map(({ date, text: annotation, type }, index) => ({
            key: `annotation-${index}`,
            date,
            timeZone,
            annotation,
            type,
          })),
      ],
      'date'
    ),
    effectiveRegulations: sortBy(
      effectiveRegulations.map(({ value, description, regulation, appliesTo, ...rest }) => ({
        key: `${value}${description}${regulation}${appliesTo}${uniqueId()}`,
        value,
        description,
        regulation,
        appliesTo,
        ...rest,
      })),
      'regulation'
    ),
    adminStatusChanges: sortBy(
      adminStatusChanges.map((statusChange) => ({
        timeZone,
        origin: recordOriginCodeAppend(
          (dutyStatusChanges.find(({ key }) => key === statusChange.key) || {}).origin
        ),
        ...statusChange,
        status: recordStatusCodeAppend(statusChange.status),
      })),
      'date'
    ),
    eldEvents: sortBy(
      [
        ...certifications.map((event) => ({ ...event, eventType: `SIGNATURE` })),
        ...health.map(({ code, ...event }) => ({
          ...event,
          code,
          eventCodeDefinition: eventCodeFormatter(code),
          eventType: `DIAGNOSTIC_${event.orientation}`,
        })),
        ...enginePower.map((event) => ({ ...event, eventType: `ENGINE_${event.engineState}` })),
        ...sessions.map((event) => ({ ...event, eventType: `SESSION_${event.orientation}` })),
        ...specialMovements.map((event) => ({
          ...event,
          eventType: `${event.movementType}_${event.orientation}`,
        })),
        ...operatingZones.map((event) => ({ ...event, eventType: `ZONE` })),
        ...timeDeferralEvents.map((event) => ({
          ...event,
          key: event.key || uniqueId(),
          eventType: `DEFERRAL_${event.deferralStatus}`,
        })),
        ...driverCycleEvents.map((event) => ({
          ...event,
          code: cycleCodeByName(event.cycle),
          eventType: `CYCLE_${event.cycle.displayName}`,
        })),
        ...dutyStatusChanges.map((event) => ({
          ...event,
          key: event.key || uniqueId(),
          eventType: `DUTY_STATUS_${event.dutyStatus}`,
        })),
      ].map((event) => ({
        timeZone,
        ...event,
        recordOrigin: recordOriginCodeAppend(event.recordOrigin),
        recordStatus: recordStatusCodeAppend(event.recordStatus),
        asset: findReferencedVehicleByKey(restLog.referencedVehicles, event.vehicleKey),
      })),
      'date'
    ),
    violations,
    logAnnotations: sortBy(
      [
        ...annotations
          .filter(({ type }) => type === 'LogComment')
          .map(({ text: annotation, ...rest }) => ({
            annotation,
            timeZone,
            ...rest,
          })),
      ],
      'date'
    ),
  };
};

export const UnidentifiedEldLogDetailReport = ({
  users,
  unidentifiedLog,
  vehicleKey,
  date,
  reportViewId,
  setPrintMode,
  route,
  fetchUsers,
  fetchUnidentifiedLog,
}) => {
  const todayDate = moment().format(NORMAL_DATE_FORMAT);
  const isDateValid = moment(date, NORMAL_DATE_FORMAT).format(NORMAL_DATE_FORMAT) === date;
  const isDateInFuture = isDateValid && date > todayDate;
  const isDateAcceptable = isDateValid && !isDateInFuture;

  const report = useReportController({
    reportType: 'unidentifiedLogDetail',
    reportTypeName: `Events for the Unidentified Driver`,
    reportViewId,
    route,
  });

  const { reportType } = report;

  const userList = useMemo(() => users, [users]);

  const detailedLog = useMemo(() => makeDetailedLog(unidentifiedLog), [unidentifiedLog]);

  const [dateInputValue, setDateInputValue] = useState(null);

  useEffect(() => {
    if (!isDateValid) return;
    setDateInputValue(date);
  }, [isDateValid, date]);

  useEffect(() => {
    if (!dateInputValue || dateInputValue === date) return;
    route.open({
      vehicleKey,
      date: dateInputValue,
      reportViewId,
    });
  }, [dateInputValue]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {isDateAcceptable && (
        <FetchReportDetail action={fetchUnidentifiedLog} args={{ vehicleKey, date, eld: 'cdn' }} />
      )}
      <SignalWebclientViewReady />
      <div className="UnidentifiedEldLogDetailReport">
        <PrintLayout mode={reportType} inline debug={debugMode}>
          <ReportFragment.Header reportType="driverLog" route={route} hideExport />
          {FEATURE_SHOW_DETAILED_HEADER && (
            <DriverCard log={detailedLog} params={route.getAllParams()} />
          )}
          <DriverEventsCard log={detailedLog} printLayoutMode={reportType} />
          <br />
          <MalfunctionAndDiagnosticEventsCard log={detailedLog} printLayoutMode={reportType} />
          <br />
          <CycleEventsCard log={detailedLog} printLayoutMode={reportType} />
          <br />
          <AnnotationsEventsCard log={detailedLog} printLayoutMode={reportType} users={userList} />
          <br />
          <EnginePowerEventsCard log={detailedLog} printLayoutMode={reportType} />
          <br />
        </PrintLayout>
      </div>
    </ReportProvider>
  );
};

UnidentifiedEldLogDetailReport.propTypes = {
  users: customPropTypes.users,
  unidentifiedLog: customPropTypes.detailedDriverLog,
  setPrintMode: PropTypes.func.isRequired,
  vehicleKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchUnidentifiedLog: PropTypes.func.isRequired,
};
