import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  fetchReportViewsByReportType,
  createReportView,
  updateReportView,
  createOrUpdateDefaultReportView,
  selectVisibleReportViews,
  createReportSchedule,
  createReportOnDemand,
} from '../../../../data/reports';

import {
  selectSystemActiveOu,
  selectSystemTenant,
  selectSystemUser,
} from '../../../../data/system';

import { ReportMenu } from './ReportMenu';

export const ReportMenuContainer = connect(
  (state, props) => ({
    reportViews: selectVisibleReportViews(state, props),
    activeOu: selectSystemActiveOu(state),
    tenantKey: selectSystemTenant(state).key,
    userKey: selectSystemUser(state).key,
  }),
  {
    fetchReportViewsByReportType,
    createReportView,
    updateReportView,
    createOrUpdateDefaultReportView,
    createReportSchedule,
    createReportOnDemand,
  }
)(ReportMenu);

ReportMenuContainer.propTypes = {
  reportType: PropTypes.string.isRequired,
};
