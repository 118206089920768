import BetterPropTypes from 'better-prop-types';

export const vehicleType = BetterPropTypes.shape({
  ou: BetterPropTypes.oneOfType([BetterPropTypes.object, BetterPropTypes.string]),
  name: BetterPropTypes.string,
  typeClass: BetterPropTypes.string,
  isSleeperBerth: BetterPropTypes.bool,
  description: BetterPropTypes.string,
  _ownBy: BetterPropTypes.string,
  key: BetterPropTypes.string.isOptionalButNotNull,
});

export const vehicleTypes = BetterPropTypes.arrayOf(vehicleType);
