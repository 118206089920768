import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card } from 'stti-react-common';

import { useFormats } from '../Formats';
import { customPropTypes } from '../../../helpers/customPropTypes';

import './DriverLogPrintLayoutHeader.scss';

export const DriverLogPrintLayoutHeader = ({
  driverLog,
  date,
  logSummaryPrintDetails,
  comment,
}) => {
  const { formatUnit } = useFormats();

  return (
    <Card className="DriverLogPrintLayoutHeader__Card" fullWidth>
      <h1>Driver&apos;s Daily Log</h1>
      <table>
        <thead>
          <tr>
            <th>Date of RODS</th>
            <td>{formatUnit('timelessDate', moment(date))}</td>
          </tr>
        </thead>
        <tbody>
          {driverLog && driverLog.driver && (
            <tr>
              <th>Driver</th>
              <td>{driverLog.driver.name}</td>
            </tr>
          )}
          {logSummaryPrintDetails.length > 0 && (
            <tr>
              <th>Summary</th>
              <td>{logSummaryPrintDetails.join(', ')}</td>
            </tr>
          )}
          <tr>
            <th>Comment</th>
            <td>{comment}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

DriverLogPrintLayoutHeader.propTypes = {
  date: PropTypes.string.isRequired,
  logSummaryPrintDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
  driverLog: customPropTypes.detailedDriverLog,
  comment: PropTypes.string,
};
