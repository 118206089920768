import { columnDef } from '../../../commons/ControlledDataGrid';

const categoryChildren = (category, hide) => [
  columnDef.distanceColumn({
    field: `${category}.distance`,
    headerName: 'Distance',
    hide,
    width: 120,
  }),
  columnDef.durationColumn({
    field: `${category}.duration`,
    headerName: 'Duration',
    hide,
    width: 120,
  }),
  columnDef.durationColumn({
    field: `${category}.waitDuration`,
    headerName: 'Waiting',
    hide,
    width: 120,
  }),
  columnDef.fuelColumn({
    field: `${category}.addedFuel`,
    headerName: 'Added Fuel',
    hide,
    width: 120,
  }),
  columnDef.fuelColumn({
    field: `${category}.consumedFuel`,
    headerName: 'Consumed Fuel',
    hide,
    width: 120,
  }),
  columnDef.fuelColumn({
    field: `${category}.idlingFuel`,
    headerName: 'Idle Fuel',
    hide,
    width: 120,
  }),
  columnDef.simpleMileageColumn({
    field: `${category}.mileage`,
    headerName: 'Mileage',
    hide,
    width: 120,
  }),
  columnDef.durationColumn({
    field: `${category}.engineDuration`,
    headerName: 'Engine Time',
    hide,
    width: 120,
  }),
  columnDef.durationColumn({
    field: `${category}.idlingDuration`,
    headerName: 'Idle Time',
    hide,
    width: 120,
  }),
  columnDef.speedColumn({
    field: `${category}.averageSpeed`,
    headerName: 'Avg Speed',
    hide,
    width: 120,
  }),
];

export const columnDefs = [
  {
    headerName: 'Trip Resources',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Time',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Time',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        width: 150,
      }),
      columnDef.textColumn({
        colId: 'trailersNames',
        headerName: 'Trailers(s)',
        valueGetter: ({ data }) => data.trailersNames.join(', '),
        cellRenderer: 'Trailers',
      }),
      columnDef.textColumn({
        field: 'ou.name',
        headerName: 'Organization',
      }),
      columnDef.textColumn({
        colId: 'driversNames',
        headerName: 'Driver(s)',
        valueGetter: ({ data }) => data.driversNames.join(', '),
        cellRenderer: 'Drivers',
      }),
    ],
  },
  {
    headerName: 'Route',
    children: [
      columnDef.textColumn({
        field: 'origin.name',
        headerName: 'Origin',
        cellRenderer: 'Origin',
        width: 250,
      }),
      columnDef.textColumn({
        field: 'firstLoad.name',
        headerName: 'First Load',
        cellRenderer: 'FirstLoad',
        width: 250,
      }),
      columnDef.textColumn({
        field: 'lastUnload.name',
        headerName: 'Last Unload',
        cellRenderer: 'LastUnload',
        width: 250,
      }),
      columnDef.iconColumn({
        field: 'routeMap',
        headerName: 'Route Map',
        cellRenderer: 'RouteMap',
        headerComponent: 'RouteMapHeader',
      }),
    ],
  },
  {
    headerName: 'Order',
    children: [
      columnDef.numberColumn({
        field: 'count.loads',
        headerName: 'Loads',
      }),
    ],
  },
  {
    headerName: 'Core Cycle',
    children: categoryChildren('coreCycle', true),
  },
  {
    headerName: 'Full Cycle',
    children: categoryChildren('fullCycle'),
  },
  {
    headerName: 'Empty',
    children: categoryChildren('empty', true),
  },
  {
    headerName: 'Loading',
    children: categoryChildren('loading', true),
  },
  {
    headerName: 'Loaded',
    children: categoryChildren('loaded', true),
  },
  {
    headerName: 'Unloading',
    children: categoryChildren('unloading', true),
  },
  {
    headerName: 'On Highway',
    children: categoryChildren('onHighway', true),
  },
  {
    headerName: 'Off Highway',
    children: categoryChildren('offHighway', true),
  },
  {
    headerName: 'Driver Activity',
    children: categoryChildren('driverActivity', true),
  },
  {
    headerName: 'Driving',
    children: categoryChildren('driving', true),
  },
  {
    headerName: 'Delay',
    children: categoryChildren('delay', true),
  },
  {
    headerName: 'Break',
    children: categoryChildren('break', true),
  },
  {
    headerName: 'Personal Use',
    children: categoryChildren('personalUse', true),
  },
  {
    headerName: 'Unknown Stop',
    children: categoryChildren('unknownStop', true),
  },
  {
    headerName: 'Unidentified Driving',
    children: categoryChildren('unidentifiedDriving', true),
  },
];
