import React from 'react';
import PropTypes from 'prop-types';
import { BasicDialog, Clickable, Icon, ScrollBar } from 'stti-react-common';
import { includes, union } from 'lodash';

import { MAP_SETTING_OPTION } from '../../../../data/system/constants';

import './MapSetting.scss';

const filterCriteria = [
  'showVehicleLabels',
  'showLocationLabels',
  'showTrafficOverlay',
  'showSearchBar',
  'automaticallyUpdate',
  'clusterNearbyVehicles',
];

export const MapSetting = ({
  isModal,
  onClose,
  isOpen,
  locationTypes,
  checkedLocationSettingList,
  setCheckedLocationSettingList,
  appSetting,
  addMapSettingOption,
}) => {
  const locationSettings = checkedLocationSettingList.filter(
    (item) => !includes(filterCriteria, item)
  );
  const LOCATION_LABELS = 'showLocationLabels';

  const toggleAll = () => {
    const clearList = checkedLocationSettingList.length === locationTypes.length;
    if (clearList) {
      const updatedList = checkedLocationSettingList
        .filter((element) => !locationTypes.map(({ key }) => key).includes(element))
        .filter((element) => !element.includes(LOCATION_LABELS));
      setCheckedLocationSettingList(updatedList);

      return;
    }
    const allLocations = locationTypes.map(({ key }) => key);
    const updatedArr = union(checkedLocationSettingList, allLocations);
    setCheckedLocationSettingList(updatedArr);
    addMapSettingOption(LOCATION_LABELS);
  };

  function getIconName(value) {
    if (checkedLocationSettingList.includes(value)) {
      return 'check_box';
    }
    return 'check_box_outline_blank';
  }

  const renderList = () => (
    <div className="MapSetting__container">
      <hr />
      <br />
      <ScrollBar style={{ height: '60vh' }}>
        <div className="MapSetting__container__list">
          <div className="MapSetting__container__list__settings">
            <ul className="list-group">
              {MAP_SETTING_OPTION.map(({ label, value }, id) => (
                <Clickable onClick={() => addMapSettingOption(value)} key={id}>
                  <li>
                    {label}{' '}
                    <Icon
                      icon={
                        checkedLocationSettingList.includes(value)
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }
                    />
                  </li>
                </Clickable>
              ))}
            </ul>
          </div>
          <div className="MapSetting__container__list__locations">
            <ul className="list-group">
              <Clickable onClick={() => toggleAll()}>
                <li>
                  Show All Location Types <Icon icon={getIconName(LOCATION_LABELS)} />
                </li>
              </Clickable>
              {locationTypes.map(({ name, key }, id) => (
                <Clickable key={id} onClick={() => addMapSettingOption(key)}>
                  {checkedLocationSettingList.includes(LOCATION_LABELS) && (
                    <li className="Location-list-item">
                      {name}
                      <Icon
                        icon={
                          checkedLocationSettingList.includes(key)
                            ? 'check_box'
                            : 'check_box_outline_blank'
                        }
                      />
                    </li>
                  )}
                </Clickable>
              ))}
            </ul>
          </div>
        </div>
      </ScrollBar>
    </div>
  );

  if (!isModal) return <div className="MapSetting">{renderList()}</div>;

  return (
    <BasicDialog
      header={
        <div className="MapSetting__header">
          <Icon className="MapSetting__header__icon" icon="settings" /> <h3>Map Options</h3>
        </div>
      }
      className="MapSetting"
      maxWidth="lg"
      fullWidth
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
          className: 'MapSetting_CancelBtn',
        },
        {
          label: 'Apply',
          onClick: appSetting,
          className: 'MapSetting_ApplyBtn',
        },
      ]}
    >
      {renderList()}
    </BasicDialog>
  );
};

MapSetting.propTypes = {
  locationTypes: PropTypes.any,
  isModal: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  checkedLocationSettingList: PropTypes.array,
  setCheckedLocationSettingList: PropTypes.func,
  appSetting: PropTypes.func,
  addMapSettingOption: PropTypes.func,
};
