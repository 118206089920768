import { round } from 'lodash';

// conversion to metre per second
export const convertToMps = (value) => (value ? value / 3.6 : null);
export const unconvertToMps = (value) => (value ? value * 3.6 : null);

export const FUEL_FLOW = 2 / 3600; // Assuming 2L/h as minimum  (L/s)

export const accelFormat = (value, inverted) => {
  if (!isFinite(value) || value === 0) return null;
  return Math.abs(value) * (inverted ? -1 : 1);
};

export const getValue = (unit, value, converter, format) => {
  if (!isFinite(value) || !value) return null;
  return format ? round(converter(unit, value)) : converter(unit, value);
};

const pristineCondition = {
  lowerBound: null,
  upperBound: null,
};

export const generateDynamicTitle = (type, convertUnit, rest) => {
  switch (type) {
    case 'Boost': {
      let { speedCondition } = rest;
      speedCondition = speedCondition || pristineCondition;

      if (speedCondition.lowerBound && speedCondition.upperBound) {
        return `Boost ${round(convertUnit('speed', speedCondition.lowerBound))}-${round(
          convertUnit('speed', speedCondition.upperBound)
        )}`;
      }
      if (speedCondition.lowerBound && !speedCondition.upperBound) {
        return `Boost ${round(convertUnit('speed', speedCondition.lowerBound))}+`;
      }
      return 'Boost';
    }
    case 'Brake': {
      const { harsh } = rest;
      let { speedCondition, accelCondition } = rest;
      speedCondition = speedCondition || pristineCondition;
      accelCondition = accelCondition || pristineCondition;

      if (speedCondition.lowerBound && speedCondition.upperBound && !accelCondition.upperBound) {
        return `Brake ${round(convertUnit('speed', speedCondition.lowerBound))}-${round(
          convertUnit('speed', speedCondition.upperBound)
        )}, decel <= ${round(Math.abs(convertUnit('speed', accelCondition.lowerBound)))} ${
          harsh ? '(harsh)' : ''
        }`;
      }
      if (speedCondition.lowerBound && speedCondition.upperBound && !accelCondition.lowerBound) {
        return `Brake ${round(convertUnit('speed', speedCondition.lowerBound))}-${round(
          convertUnit('speed', speedCondition.upperBound)
        )}, decel >= ${round(Math.abs(convertUnit('speed', accelCondition.upperBound)))} ${
          harsh ? '(harsh)' : ''
        }`;
      }
      if (speedCondition.lowerBound && !speedCondition.upperBound && !accelCondition.lowerBound) {
        return `Brake ${round(convertUnit('speed', speedCondition.lowerBound))}+, decel >= ${round(
          Math.abs(convertUnit('speed', accelCondition.upperBound))
        )} ${harsh ? '(harsh)' : ''}`;
      }
      if (speedCondition.lowerBound && !speedCondition.upperBound && !accelCondition.upperBound) {
        return `Brake ${round(convertUnit('speed', speedCondition.lowerBound))}+, decel <= ${round(
          Math.abs(convertUnit('speed', accelCondition.lowerBound))
        )} ${harsh ? '(harsh)' : ''}`;
      }
      return 'Brake';
    }
    case 'Idling': {
      let { rpmCondition } = rest;
      rpmCondition = rpmCondition || pristineCondition;

      if (rpmCondition.lowerBound === 1 && !rpmCondition.upperBound) {
        return 'Excessive Idling';
      }
      if (rpmCondition.lowerBound && rpmCondition.upperBound) {
        return `Low Idling RPM < ${rpmCondition.upperBound}`;
      }
      if (rpmCondition.lowerBound > 1 && !rpmCondition.upperBound) {
        return `High Idling RPM > ${rpmCondition.lowerBound}`;
      }
      return 'Idling RPM';
    }
    case 'Speed': {
      let { speedCondition } = rest;
      speedCondition = speedCondition || pristineCondition;

      if (speedCondition.lowerBound && speedCondition.upperBound) {
        return `Speed ${round(convertUnit('speed', speedCondition.lowerBound))}-${round(
          convertUnit('speed', speedCondition.upperBound)
        )}`;
      }
      if (speedCondition.lowerBound && !speedCondition.upperBound) {
        return `Speed ${round(convertUnit('speed', speedCondition.lowerBound))}+`;
      }
      return 'Speed';
    }
    default: {
      let { rpmCondition } = rest;
      rpmCondition = rpmCondition || pristineCondition;

      if (rpmCondition.lowerBound && rpmCondition.upperBound) {
        return `${type} ${rpmCondition.lowerBound}-${rpmCondition.upperBound}`;
      }
      if (rpmCondition.lowerBound && !rpmCondition.upperBound) {
        return `${type} ${rpmCondition.lowerBound}+`;
      }
      return type;
    }
  }
};
