import React from 'react';
import PropTypes from 'prop-types';

import { SectionPanel } from '../../../commons/ReportsCommon';
import { AggregatesContent } from './AggregatesContent';
import { countUniqueOfField } from '../../../../helpers/reports/aggregation';

export const AggregatesPanel = ({ rows }) => (
  <SectionPanel
    name="aggregatesPanel"
    title="Aggregates"
    renderSummaries={(props) => (
      <div {...props}>
        {rows.length} traversals, {countUniqueOfField(rows, 'driverName')} drivers,{' '}
        {countUniqueOfField(rows, 'vehicleId')} vehicles
      </div>
    )}
  >
    <AggregatesContent rows={rows} />
  </SectionPanel>
);

AggregatesPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
