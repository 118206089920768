import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../helpers/moment';
import { makeRestApiAction } from '../apiActionSupport';
import { ACTION_TYPE } from './exceptionsReducer';

const transformExceptionForStore = (exception) => ({
  ...exception,
  exceptionType: `event${exception.exceptionType}`,
  ouKey: exception.ou.key,
  drivers: [
    {
      ...exception.driver, // { key, name }
      order: 1,
    },
  ],
  driversNames: [exception.driver.name],
});

// fetchExceptions({ startDate, endDate, ouKey, timeZone, exceptionType })
export const fetchExceptions = makeRestApiAction({
  service: 'exceptions',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchExceptions,
  transformInput: ({ startDate, endDate, ouKey, timeZone, exceptionType }) => ({
    ouKey,
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
    exceptionType,
  }),
  transformOutput: (data) => data.map(transformExceptionForStore),
  notificationsItemDescriptor: 'exceptions',
  enableProgress: true,
});

// fetchException(id)
export const fetchException = makeRestApiAction({
  service: 'exceptions',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchException,
  transformOutput: transformExceptionForStore,
});

// #1: fetchAnnotations({ tripId })
// #2: fetchAnnotations({ exceptionId })
// #3: fetchAnnotations({ startDate, endDate, ouKey, timeZone })
export const fetchAnnotations = makeRestApiAction({
  service: 'annotations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchAnnotations,
  transformInput: ({ tripId, exceptionId, startDate, endDate, ouKey, timeZone }) => {
    if (tripId) return { tripId };
    if (exceptionId) return { exceptionId };
    return {
      ouKey,
      startDate: startDateWithTimeZoneToIso(startDate, timeZone),
      endDate: endDateWithTimeZoneToIso(endDate, timeZone),
    };
  },
  notificationsItemDescriptor: 'reviews',
  enableProgress: true,
});

// #1: createAnnotation({ tripId, exceptionType, comment })
// #2: createAnnotation({ exceptionId, comment })
export const createAnnotation = makeRestApiAction({
  service: 'annotations',
  method: 'post',
  baseActionType: ACTION_TYPE.createAnnotation,
  notificationsItemDescriptor: 'review',
});

// updateAnnotation({ id, comment })
export const updateAnnotation = makeRestApiAction({
  service: 'annotations',
  method: 'patch',
  baseActionType: ACTION_TYPE.updateAnnotation,
  getId: ({ id }) => id,
  notificationsItemDescriptor: 'review',
});
