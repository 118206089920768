/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, BasicDialog } from 'stti-react-common';
import { groupBy, map, find } from 'lodash';

import { useFormats } from '../../Formats';
import { EditGeozoneDialog } from '../EditGeozoneDialog';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import './Geozones.scss';

const {
  Area,
  Info,
  ContextMenu,
  EditArea,
  DrawArea,
  utils: { computeArea },
} = GoogleMap;

export const Geozones = ({
  geozones,
  hideGeozoneTypes = [],
  createGeozone,
  updateGeozone,
  deleteGeozone,
  selectedGeozone,
  tenant,
  onReset,
  canEdit,
  hideInfo = false,
}) => {
  const [target, setTarget] = useState(null);
  const [mode, setMode] = useState(null); // drawShape, newProperties, editShape, editProperties, delete

  const { enableCti } = tenant;
  const { formatUnit } = useFormats();

  const reset = () => {
    // reset only mode and not target, so text prompts don't disappear during dialog close animation
    setMode(null);
    onReset && onReset();
  };

  useEffect(() => {
    if (!selectedGeozone) return;
    setMode(selectedGeozone.mode);
    setTarget(selectedGeozone.geozone);
  }, [selectedGeozone && selectedGeozone.mode, selectedGeozone && selectedGeozone.geozone]);

  const saveGeozone = (properties) => {
    const geozone = {
      ...target,
      ...properties,
    };
    geozone.areaSize = computeArea(geozone.locations);
    const actionFn = mode === 'newProperties' ? createGeozone : updateGeozone;
    actionFn(geozone);
  };

  const renderedGeozones = geozones.map((geozone) => {
    const { key, name, speedLimit, tirePressureConfig, typeName, locations, sizeOrder, color } =
      geozone;

    const renderTirePressureLimits = (limits) => {
      if (!limits) return '';
      const getValue = (findResult) => {
        if (!findResult) return null;
        return findResult.maximumLimit;
      };
      const limitsByName = map(groupBy(limits, 'groupId'), (collection, lookup) => ({
        groupId: lookup,
        unloaded: getValue(find(collection, { loaded: false })),
        loaded: getValue(find(collection, { loaded: true })),
      }));
      return (
        <div className="Geozones__tirePressures">
          <span>Max Tire Pressures</span>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>(U)</th>
                <th>(L)</th>
              </tr>
            </thead>
            <tbody>
              {limitsByName.map((row) => (
                <tr key={row.groupId}>
                  <td>{row.groupId}</td>
                  <td>{formatUnit('pressure', row.unloaded)}</td>
                  <td>{formatUnit('pressure', row.loaded)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      !hideGeozoneTypes.includes(geozone.type) &&
      (mode === 'editShape' && key === target.key ? (
        <EditArea
          key={key}
          path={locations}
          color={color}
          controlPosition="topCenter"
          onCancel={reset}
          onSave={(newLocations) => {
            saveGeozone({ locations: newLocations });
            reset();
          }}
        />
      ) : (
        <Area key={key} path={locations} color={color} order={sizeOrder} selection={geozone}>
          {!hideInfo && (
            <Info>
              <strong>{name}</strong>
              <div>Type: {typeName}</div>
              {speedLimit && <div>Speed Limit: {formatUnit('speed', speedLimit.limit)}</div>}
              {tirePressureConfig &&
                tirePressureConfig.limits &&
                enableCti &&
                renderTirePressureLimits(tirePressureConfig.limits)}
            </Info>
          )}
          {canEdit && (
            <ContextMenu
              items={[
                {
                  label: 'Edit Geozone Shape',
                  onClick: () => {
                    setTarget(geozone);
                    setMode('editShape');
                  },
                  key: 'editShape',
                },
                {
                  label: 'Edit Geozone Properties',
                  onClick: () => {
                    setTarget(geozone);
                    setMode('editProperties');
                  },
                  key: 'editProperties',
                },
                {
                  label: 'Delete Geozone',
                  onClick: () => {
                    setTarget(geozone);
                    setMode('delete');
                  },
                  key: 'delete',
                },
                <hr />,
                {
                  label: 'Draw New Geozone',
                  onClick: () => {
                    setMode('drawShape');
                  },
                  key: 'drawShape',
                },
              ]}
            />
          )}
        </Area>
      ))
    );
  });

  if (!canEdit) return renderedGeozones;

  return (
    <>
      <ContextMenu
        items={[
          {
            label: 'Draw New Geozone',
            onClick: () => {
              setMode('drawShape');
            },
            key: 'drawShape',
          },
        ]}
      />
      {mode === 'drawShape' && (
        <DrawArea
          controlPosition="topCenter"
          onCancel={reset}
          onSave={(newLocations) => {
            setTarget({ locations: newLocations });
            setMode('newProperties');
          }}
        />
      )}
      {mode === 'newProperties' && <Area path={target.locations} color="#000000" />}
      {renderedGeozones}
      <EditGeozoneDialog
        isOpen={mode === 'editProperties' || mode === 'newProperties'}
        geozone={target}
        onClose={reset}
        onSave={saveGeozone}
      />
      <BasicDialog
        isOpen={mode === 'delete'}
        onClose={reset}
        buttons={[
          {
            label: 'Cancel',
            onClick: reset,
          },
          {
            label: 'Delete',
            onClick: () => {
              deleteGeozone(target);
              reset();
            },
          },
        ]}
      >
        <span>Are you sure you want to delete {target && target.name}?</span>
      </BasicDialog>
    </>
  );
};

Geozones.propTypes = {
  geozones: PropTypes.arrayOf(customPropTypes.location),
  hideGeozoneTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(customPropTypes.locationType),
  ]),
  createGeozone: PropTypes.func.isRequired,
  updateGeozone: PropTypes.func.isRequired,
  deleteGeozone: PropTypes.func.isRequired,
  selectedGeozone: PropTypes.shape({
    geozone: customPropTypes.location,
    mode: PropTypes.string,
  }),
  tenant: PropTypes.object,
  onReset: PropTypes.func,
  canEdit: PropTypes.bool,
  hideInfo: PropTypes.bool,
};
