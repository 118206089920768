import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { DateInput } from 'stti-react-common';

import { ReportControl } from '../ReportControl';
import { useFormats } from '../../Formats';

export const EndDateInput = ({
  disableOneYearMaxRange,
  maxRangeInDays,
  disableEndDate,
  group,
  ...props
}) => {
  const {
    formats: { timelessDate },
  } = useFormats();

  return (
    <ReportControl
      name="endDate"
      group={group}
      label="End Date"
      render={(renderProps) => <DateInput {...renderProps} disabled={disableEndDate} />}
      reducerCallback={(draft, value) => {
        if (value) draft.controls.dateRangeMode = 'custom';
      }}
      error={({ value, controls }) => {
        const { startDate } = controls;
        if (startDate && !value) return 'Valid End Date must be specified.';
        if (startDate > value) return 'End Date must be on or after Start Date.';
        if (!disableOneYearMaxRange && moment(value) > moment(startDate).add(1, 'year'))
          return 'End Date must be within a year of Start Date.';
        if (
          maxRangeInDays &&
          startDate &&
          value &&
          moment(value).diff(moment(startDate), 'days') > maxRangeInDays
        )
          return `Diference between Start and End Dates cannot be greater than ${maxRangeInDays} days`;
        return false;
      }}
      format={timelessDate}
      autoGridArea
      {...props}
    />
  );
};

EndDateInput.propTypes = {
  disableOneYearMaxRange: PropTypes.bool,
  maxRangeInDays: PropTypes.number,
  group: PropTypes.string.isRequired,
  disableEndDate: PropTypes.bool,
};
