import React from 'react';
import PropTypes from 'prop-types';
import { Clickable, Icon, useBooleanState } from 'stti-react-common';

import './ToggleTransition.scss';

const ChildElement = ({ children, isMapPanelOpen }) => (
  <div className="container-panel-content">
    <div className={isMapPanelOpen ? '' : 'hide-panel-children'}>{children}</div>
  </div>
);

ChildElement.propTypes = {
  children: PropTypes.node,
  isMapPanelOpen: PropTypes.bool,
};

export const ToggleTransition = ({
  arrowIcon,
  arrowPosition,
  ButtonContent,
  children,
  Header,
  mapPanelOpen = false,
  className = '',
}) => {
  const [isMapPanelOpen, openMapPanelDialog, closeMapPanelDialog] = useBooleanState(mapPanelOpen);

  return (
    <div className={`ToggleTransition ${className}`}>
      {!isMapPanelOpen ? (
        <div className={`container-panel-btn ${arrowPosition}`}>
          <Clickable className="clickable_action" onClick={openMapPanelDialog}>
            {ButtonContent}
          </Clickable>
          {ChildElement({ children, isMapPanelOpen })}
        </div>
      ) : (
        <div className="container-panel">
          <Clickable className={`_${arrowPosition} clickable_action`} onClick={closeMapPanelDialog}>
            <Icon icon={arrowIcon || 'keyboard_arrow_left'} />
            {Header}
          </Clickable>
          {ChildElement({ children, isMapPanelOpen })}
        </div>
      )}
    </div>
  );
};

ToggleTransition.propTypes = {
  arrowIcon: PropTypes.string,
  arrowPosition: PropTypes.oneOf(['left', 'right']),
  ButtonContent: PropTypes.any,
  children: PropTypes.node,
  Header: PropTypes.any,
  mapPanelOpen: PropTypes.bool,
  className: PropTypes.string,
};
