import { connect } from 'react-redux';

import { VehicleTypeDetail } from './detail';

import {
  selectSystemActiveOu,
  selectSystemTenant,
  selectSystemOus,
} from '../../../../../data/system';
import { vehicleType } from '../../../../../data/administration/resources';

export const VehicleTypeDetailContainer = connect(
  (state, prop) => ({
    vehicleType: vehicleType.selectors.selectVehicleType(state, prop),
    ous: selectSystemOus(state),
    activeOu: selectSystemActiveOu(state),
    tenant: selectSystemTenant(state),
  }),
  {
    fetchVehicleType: vehicleType.actions.fetchVehicleTypeOptimized,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
    createVehicleType: vehicleType.actions.createVehicleType,
    updateVehicleType: vehicleType.actions.updateVehicleType,
    deleteVehicleType: vehicleType.actions.deleteVehicleType,
  }
)(VehicleTypeDetail);
