import React from 'react';
import PropTypes from 'prop-types';
import { omit, omitBy, isUndefined } from 'lodash';
import hash from 'object-hash';
import { Clickable } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { countOf } from '../../../../helpers/reports/aggregation';
import { sortRows } from '../../../../helpers/reports/standardFilters';
import {
  transformTripRowsToCycleRows,
  addAggregatesToCycleRows,
} from '../../../../helpers/reports/cycleFilters';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  SectionPanel,
  ControlGroupSummary,
  SectionPanelMenu,
  AggregatesCard,
  ReportControl,
  ReportFilter,
  ControlsTablePrint,
  SectionPrint,
  LaunchReport,
  DetailReportPrintingLinks,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
} from '../../../commons/ReportsCommon';

import './CyclesSummaryReport.scss';

import { columnDefs } from './columnDefs';

const { createState, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: {},
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['trips'];

export const CyclesSummaryReport = ({
  createLocalReportView,
  cycleDetailReportRoute,
  fetchTrips,
  limits,
  reportViewId,
  route,
  trips,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'cyclesSummary',
    reportTypeName: 'Cycle Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: trips,
      chain: [
        'query',
        'resource',
        'outlier',
        'invertOutlier',
        'trips',
        'makeCycles',
        'leg',
        'finishCycles',
        'route',
      ],
    },
  });

  const { setControl, filteredRows, dataGridController, getReport, reportType } = report;

  // LOCAL REPORT VIEWS

  const makeLocalReportViewData = (cycle) => {
    const retrievedReport = getReport();
    let inheritedControls = { ...retrievedReport.controls }; // must use getReport() as event is inside grid cell renderer
    inheritedControls = omitBy(inheritedControls, isUndefined); // drop undefined values
    inheritedControls = omit(inheritedControls, 'searchText'); // drop searchText
    inheritedControls = omitBy(inheritedControls, (controlValue, controlName) =>
      controlName.match(/Panel$/)
    ); // drop all panels

    const cycleDetailReportView = {
      reportType: 'cycleDetail',
      state: {
        controls: {
          ...inheritedControls,
          originName: cycle.origin.name,
          firstLoadName: cycle.firstLoad.name,
          lastUnloadName: cycle.lastUnload.name,
        },
        version: retrievedReport.state.version,
      },
    };
    const id = hash(cycleDetailReportView);

    return { id, ...cycleDetailReportView };
  };

  // GRID CELL COMPONENTS

  const dataGridComponents = ControlledDataGrid.useDataGridComponents({
    /* eslint-disable react/prop-types */
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data?.vehicle?.key])}>{value}</Clickable>
    ),
    Trailers: ({ data: { trailers } }) =>
      trailers.map((trailer, index) => (
        <span key={trailer?.name}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('trailersNames', [trailer?.name])}>
            {trailer?.name}
          </Clickable>
        </span>
      )),
    Drivers: ({ data: { drivers } }) =>
      drivers.map((driver, index) => (
        <span key={`${driver?.name} ${driver?.order}`}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('driversNames', [driver?.key])}>
            {driver?.name}
          </Clickable>
        </span>
      )),
    Origin: ({ value }) => (
      <Clickable onClick={() => setControl('originName', [value])}>{value}</Clickable>
    ),
    FirstLoad: ({ value }) => (
      <Clickable onClick={() => setControl('firstLoadName', [value])}>{value}</Clickable>
    ),
    LastUnload: ({ value }) => (
      <Clickable onClick={() => setControl('lastUnloadName', [value])}>{value}</Clickable>
    ),
    OpenDetail: ({ data }) => (
      <LaunchReport
        onClick={async () => {
          const cycleDetailReportView = makeLocalReportViewData(data);
          await createLocalReportView(cycleDetailReportView);
          cycleDetailReportRoute.open({ reportViewId: cycleDetailReportView.id });
        }}
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchTrips} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="CyclesSummaryReport">
        <ReportFragment.Header
          reportType="cyclesSummary"
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
        />

        <QueryPanel queryFor="trip" group="query" minAllowedDate="2018-01-01" />
        <SectionPanel
          name="filtersPanel"
          title="Trip Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <ReportFragment.TripResourceFilters
            rows={filteredRows.query}
            group="filters"
            filterGroup="resource"
          />
          <ReportFragment.TripOutlierFilters baseClassName="CyclesSummaryReport" />
        </SectionPanel>
        <SectionPanel
          name="cyclesPanel"
          title="Cycle Filters"
          renderSummaries={() => <ControlGroupSummary group="cycle" />}
          summaryEnd={<SectionPanelMenu group="cycle" />}
        >
          <ReportFilter
            filterConfig={{
              group: 'makeCycles',
              type: transformTripRowsToCycleRows,
            }}
          />
          <ReportFilter
            filterConfig={{
              group: 'makeCycles',
              type: sortRows,
              field: 'count.contributingTrips',
              reverse: true,
            }}
          />
          <ReportFilter
            filterConfig={{
              group: 'finishCycles',
              type: addAggregatesToCycleRows,
            }}
          />
          <ReportFragment.LocationFilters
            rows={filteredRows.finishCycles}
            group="cycle"
            filterGroup="route"
          />
          <ReportFragment.LegFilters baseClassName="CyclesSummaryReport" />
        </SectionPanel>
        <SectionPanel
          name="aggregatesPanel"
          title="Aggregates"
          renderSummaries={() => <ControlGroupSummary group="aggregates" />}
        >
          <ReportControl
            name="aggregatesCollapsedSummary"
            group="aggregates"
            renderSummary={(renderProps, { filteredRows: fr }) => (
              <div {...renderProps}>
                {countOf(fr.trips)} trips, {countOf(fr.dataGrid)} cycles
              </div>
            )}
          />
          <AggregatesCard
            name="Overview"
            title="Overview"
            fields={[
              {
                header: 'Trips',
                value: countOf(filteredRows.trips),
              },
              {
                header: 'Cycles',
                value: countOf(filteredRows.dataGrid),
              },
            ]}
          />
        </SectionPanel>
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesCard
            name="Overview"
            title="Overview"
            fields={[
              {
                header: 'Trips',
                value: countOf(filteredRows.trips),
              },
              {
                header: 'Cycles',
                value: countOf(filteredRows.dataGrid),
              },
            ]}
          />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
      <DetailReportPrintingLinks
        getDetailReportPath={async (cycle) => {
          const cycleDetailReportView = makeLocalReportViewData(cycle);
          await createLocalReportView(cycleDetailReportView);
          return cycleDetailReportRoute.makeTargetPath({
            reportViewId: cycleDetailReportView.id,
          });
        }}
      />
    </ReportProvider>
  );
};

CyclesSummaryReport.propTypes = {
  createLocalReportView: PropTypes.func.isRequired,
  cycleDetailReportRoute: PropTypes.object.isRequired,
  fetchTrips: PropTypes.func.isRequired,
  limits: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  trips: PropTypes.array.isRequired,
};
