import { columnDef } from '../../../../../commons/ControlledDataGrid';
import { titleCaseValueFormatter } from '../../../../../../helpers/formats';

export const adminStatusChangesColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Time',
  }),
  columnDef.textColumn({
    field: 'dutyStatus',
    headerName: 'Events',
    minWidth: 150,
    valueFormatter: titleCaseValueFormatter,
  }),
  columnDef.textColumn({
    field: 'status',
    headerName: 'Status',
  }),
  columnDef.textColumn({
    field: 'annotation',
    headerName: 'Comment',
  }),
  columnDef.textColumn({
    field: 'origin',
    headerName: 'Origin',
  }),
];
