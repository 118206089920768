import { EXCEPTION_TYPE } from './exceptionTypes';

export const getExceptionSpecificsText = ({ exception, formatUnit }) => {
  if (!exception) return null;
  const { exceptionType, other, mileage } = exception;
  switch (exceptionType) {
    case EXCEPTION_TYPE.eventSpeedViolation:
      return `Limit: ${formatUnit('speed', other.speedLimit)}, Max: ${formatUnit(
        'speed',
        other.maximumSpeed
      )}, Avg: ${formatUnit('speed', other.averageSpeed)}, Grace Period: ${
        other.gracePeriod
      } seconds`;
    case EXCEPTION_TYPE.eventTirePressureViolation:
      return `Axle Group: ${other.groupId}, Limit: ${formatUnit(
        'pressure',
        other.tirePressureConfig.maximumLimit
      )}, Max: ${formatUnit('pressure', other.maximumPressure)}, Avg: ${formatUnit(
        'pressure',
        other.averagePressure
      )}, Grace Distance: ${formatUnit('distance', other.tirePressureConfig.graceDistance)}, ${
        other.loaded ? 'Loaded' : 'Unloaded'
      }`;
    case EXCEPTION_TYPE.tripFuelMileage:
      return `Mileage: ${formatUnit('mileage', mileage)}`;
    case EXCEPTION_TYPE.eventVideoEvent: {
      return other.descriptions.join(', ');
    }
    case EXCEPTION_TYPE.tripCoreCycleDuration:
    case EXCEPTION_TYPE.tripUnknownStopDuration:
    default:
      return null;
  }
};
