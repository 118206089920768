import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchDispatchAbortReasons: 'administration/configurations/dispatch/fetchDispatchAbortReasons',
  deleteDispatchAbortReason: 'administration/configurations/deleteDispatchAbortReason',
  createDispatchAbortReason: 'administration/configurations/createDispatchAbortReason',
  updateDispatchAbortReason: 'administration/configurations/updateDispatchAbortReason',
};

export const dispatchAbortReasonsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchDispatchAbortReasons)) {
      draft.data = payload;
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteDispatchAbortReason)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.createDispatchAbortReason,
        ACTION_TYPE.updateDispatchAbortReason,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchDispatchAbortReasons)) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchDispatchAbortReasons)) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
