import { connect } from 'react-redux';
import {
  selectUnidentifiedLog,
  fetchUnidentifiedLogOptimizedForCanada,
  fetchDriverLogViolationOptimized,
} from '../../../../data/reports';
import { UnidentifiedEldLogDetailReport } from './UnidentifiedEldLogDetailReport';
import { setPrintMode, selectSystemUser, selectIsUserDriver } from '../../../../data/system';

import { user } from '../../../../data/administration/resources';

export const UnidentifiedEldLogDetailReportContainer = connect(
  (state, { vehicleKey, date }) => ({
    unidentifiedLog: selectUnidentifiedLog(state, { vehicleKey, date }),
    user: selectSystemUser(state),
    users: user.selectors.selectUsers(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    setPrintMode,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchUnidentifiedLog: fetchUnidentifiedLogOptimizedForCanada,
    fetchDriverLogViolation: fetchDriverLogViolationOptimized,
  }
)(UnidentifiedEldLogDetailReport);
