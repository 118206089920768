import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../../../apiReducerSupport';

const initialState = {
  data: [],
  fetches: [],
};

export const ACTION_TYPE = {
  fetchInspectionTasks: 'administration/configurations/inspections/fetchInspectionTasks',
  fetchInspectionTask: 'administration/configurations/inspections/fetchInspectionTask',
  deleteInspectionTask: 'administration/configurations/inspections/deleteInspectionTask',
  updateInspectionTask: 'administration/configurations/inspections/updateInspectionTask',
  createInspectionTask: 'administration/configurations/inspections/createInspectionTask',
};

export const inspectionTasksReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchInspectionTasks)) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteInspectionTask)) {
      draft.data = removeEntity(state.data, payload, 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchInspectionTask,
        ACTION_TYPE.createInspectionTask,
        ACTION_TYPE.updateInspectionTask,
      ])
    ) {
      draft.data = shallowMergeEntities(state.data, payload, 'key');
    }

    if (
      actionTypeRequest(type, [ACTION_TYPE.fetchInspectionTasks, ACTION_TYPE.fetchInspectionTask])
    ) {
      const { id, actionParam } = request;
      draft.fetches = shallowMergeEntities(state.fetches, {
        id,
        actionParam,
      });
    }

    if (
      actionTypeFailure(type, [ACTION_TYPE.fetchInspectionTasks, ACTION_TYPE.fetchInspectionTask])
    ) {
      draft.fetches = removeEntity(state.fetches, request);
    }
  });
