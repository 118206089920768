import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Rules',
    children: [
      columnDef.textColumn({
        field: 'description',
        headerName: 'Description',
        width: 200,
      }),
      columnDef.numberColumn({
        field: 'data.count',
        headerName: 'Count',
        width: 200,
      }),
      columnDef.distanceColumn({
        field: 'data.distance',
        headerName: 'Distance',
        width: 200,
      }),
      columnDef.durationColumn({
        field: 'data.duration',
        headerName: 'Duration',
        width: 200,
      }),
      columnDef.numberColumn({
        field: 'data.points',
        headerName: 'Points',
        width: 200,
      }),
    ],
  },
];
