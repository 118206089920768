import { zipObject } from 'lodash';

let memoryUrl = null;

const makeMatch = (regex, matchNames, encoding) => (path) => {
  const match = path.match(regex);
  if (!match) return false;

  const [matchedPath, ...matchedValuesArray] = match;

  let matchedValues = zipObject(matchNames, matchedValuesArray);

  Object.entries(encoding).forEach(([routeParamKey, encoder]) => {
    matchedValues = {
      ...matchedValues, // begin with copy
      [routeParamKey]: undefined, // remove key being decoded from matched values, encoder may or may not replace
      ...encoder.decode(routeParamKey, matchedValues[routeParamKey]), // use encoder's decode method and spread result
    };
  });

  return { path: matchedPath, values: matchedValues };
};

export const configureRoute = ({ name, path, encoding = {}, isAngular, RouteFrame, ...rest }) => {
  const routeVariableNames = (path.match(/:[A-Za-z]+/g) || []).map((v) => v.replace(':', '')); // implemented this way because lookbehind lacks browser support

  const match = makeMatch(
    new RegExp(path.replace(/:[A-Za-z]+/g, '([^/?]+)')),
    routeVariableNames,
    encoding
  );

  let state = {};
  const getState = () => state;

  const makeTargetPath = (routeParams, { affectState } = {}) => {
    let insertRoutePath = path;

    let routeParamsAfterEncoding = routeParams;
    Object.entries(encoding).forEach(([routeParamKey, encoder]) => {
      routeParamsAfterEncoding = {
        ...routeParamsAfterEncoding, // begin with copy
        ...encoder.encode(routeParamKey, routeParams), // use encoder's encode method and spread result
      };
    });

    Object.entries(routeParamsAfterEncoding).forEach(([routeParamKey, routeParamValue]) => {
      if (insertRoutePath.includes(`:${routeParamKey}`)) {
        insertRoutePath = insertRoutePath.replace(`:${routeParamKey}`, routeParamValue);
      } else if (affectState) {
        state[routeParamKey] = routeParamValue;
      }
    });

    return insertRoutePath;
  };

  const open = (routeParams = {}, { replace, ignoreUrlByMenuCategory, from } = {}) => {
    state = {}; // reset state

    const insertRoutePath = makeTargetPath(routeParams, { affectState: true });

    const currentLocation = window.location.href;

    const existingRoutePathMatch = currentLocation.match(
      new RegExp(path.replace(/:[A-Za-z]+/g, '([^/?]+)'))
    );

    let newLocation;
    if (isAngular || ignoreUrlByMenuCategory) {
      newLocation = ignoreUrlByMenuCategory
        ? `#${ignoreUrlByMenuCategory}${insertRoutePath}`
        : `#${insertRoutePath}`; // ignore current path
    } else if (existingRoutePathMatch) {
      newLocation = currentLocation.replace(existingRoutePathMatch[0], insertRoutePath); // replace old match
    } else {
      newLocation = currentLocation.replace(/^([^?]*)/, `$1${insertRoutePath}`); // append to path
    }

    const locationMethod = replace ? 'replace' : 'assign';
    window.location[locationMethod](newLocation);

    if (ignoreUrlByMenuCategory && from) {
      memoryUrl = `#/${ignoreUrlByMenuCategory}/${from}`;
    }
  };

  const close = () => {
    const currentLocation = window.location.href;
    const existingRoutePathMatch = currentLocation.match(
      new RegExp(path.replace(/:[A-Za-z]+/g, '([^/?]+)'))
    );
    if (!existingRoutePathMatch) return;

    if (memoryUrl) {
      window.location.href = memoryUrl;
      memoryUrl = null;
      return;
    }
    window.location.replace(currentLocation.replace(existingRoutePathMatch[0], ''));
  };

  const refer = (routeParams = {}, { history, root }) => {
    if (!history) throw new Error('You need to history from useHistory using react-router-dom');
    const insertRoutePath = `/${root}${makeTargetPath(routeParams, { affectState: true })}`;

    history.push(insertRoutePath);
  };

  const queryStringValue = (value) => {
    if (!value) throw new Error('You need to pass a value to queryStringValue function');
    const querySearch = window.location.href.slice(window.location.href.indexOf('?') + 1);
    const params = new URLSearchParams(querySearch);
    const paramValue = params.get(value);
    return paramValue;
  };

  const getAllParams = () => {
    const querySearch = window.location.href.slice(window.location.href.indexOf('?') + 1);
    const params = new URLSearchParams(querySearch);
    return Object.fromEntries(params);
  };

  return {
    name,
    path,
    match,
    RouteFrame,
    open,
    close,
    refer,
    makeTargetPath,
    getState,
    isAngular,
    queryStringValue,
    getAllParams,
    ...rest,
  };
};
