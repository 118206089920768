import { isNil, noop } from 'lodash';

// important thing about args: if you want to call directly as string use fetchReportDetailOnDemand(args) />
// otherwise you will be passing a object if you call like this fetchReportDetailOnDemand(args: {{var, anotherVar}})  />

export const fetchReportDetailOnDemand = ({
  report,
  fetchAction,
  args,
  onFetch = noop,
  onResolve = noop,
}) => {
  const { registerFetch } = report;
  const isEmptyArgs = Object.values(args).every((x) => isNil(x));
  if (isEmptyArgs) return registerFetch();
  onFetch();

  return registerFetch(fetchAction(typeof args === 'string' ? args : { ...args }).then(onResolve));
};
