import React, { useState, useCallback } from 'react';
import { Button } from 'stti-react-common';

import { SettingsDialog } from './SettingsDialog';

export const SettingsButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = useCallback(() => setIsOpen(true), []);
  const closeDialog = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Button
        className="Report__SettingsButton"
        label="Settings"
        icon="settings"
        tooltip="Report Settings"
        onClick={openDialog}
        {...props}
      />
      <SettingsDialog isOpen={isOpen} onClose={closeDialog} />
    </>
  );
};
