import React from 'react';

import { BasicDialog } from 'stti-react-common';
import { flatten } from 'lodash';

import { getFormDates } from '../utils';

export const ConfirmDialog = (
  selectedInterval,
  isConfirmOpen,
  setIsConfirmOpen,
  formControls,
  timeValue,
  setLoading,
  setLoadingVideo,
  fetchMediaEvent,
  virtualEvents,
  vehicleId,
  controls,
  setIsOpen,
  setCurrentVideo
) => {
  if (!selectedInterval && !isConfirmOpen) return '';
  return (
    <BasicDialog
      title="A video with the same interval already exists"
      className="ConfirmDialog"
      maxWidth="lg"
      isOpen={isConfirmOpen}
      onClose={() => setIsConfirmOpen(false)}
      buttons={[
        { label: 'Close', onClick: () => setIsConfirmOpen(false) },
        {
          label: 'Play Video',
          onClick: () => {
            const { isoStartDate, duration } = getFormDates(
              selectedInterval,
              formControls,
              timeValue
            );

            const mappedEvents = flatten(virtualEvents.map(({ events }) => events));
            const register = mappedEvents.find(
              ({ date, duration: durationString }) =>
                date === isoStartDate && duration === parseInt(durationString, 10)
            );

            const { files } = register || { files: null };
            const file = files && files.find(({ cameraId }) => cameraId === controls.cameraId);
            if (file) {
              setLoading(true);
              setLoadingVideo(true);
              fetchMediaEvent({
                fileId: file.fileId,
                fileType: file.fileType,
                vehicleKey: vehicleId,
                cameraId: controls.cameraId,
              }).then((result) => {
                setIsConfirmOpen(false);
                setIsOpen(false);
                setLoading(false);
                setCurrentVideo(result);
                setLoadingVideo(false);
              });
            } else {
              setIsConfirmOpen(false);
            }
          },
        },
      ]}
    />
  );
};
