import { connect } from 'react-redux';

import { TripsSummaryReport } from './TripsSummaryReport';
import { selectTrips, fetchTripsOptimized } from '../../../../data/reports';
import { selectSearchLimits } from '../../../../data/system';

export const TripsSummaryReportContainer = connect(
  (state) => ({
    trips: selectTrips(state),
    limits: selectSearchLimits(state, 'trips'),
  }),
  { fetchTrips: fetchTripsOptimized }
)(TripsSummaryReport);
