import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Clickable, SelectInput } from 'stti-react-common';
import { startCase, includes } from 'lodash';

import moment from 'moment-timezone';
import {
  ControlGroupSummary,
  ControlsTablePrint,
  DetailReportPrintingLinks,
  FetchReportData,
  fetchReportDataOnDemand,
  LaunchReport,
  MapIcon,
  EngineIcon,
  QueryPanel,
  ReportControl,
  ReportFragment,
  ReportLoader,
  ReportProvider,
  SectionPanel,
  SectionPanelMenu,
  SectionPrint,
  SignalWebclientViewReady,
  useReportController,
} from '../../../commons/ReportsCommon';

import { ToggleChip } from '../../../commons/ToggleChip';
import { InspectionResourceFilters } from './InspectionResourceFilters';
import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { filterRowsByPredicate } from '../../../../helpers/reports/standardFilters';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { getMinMaxDatesFromArray } from '../../../../helpers/moment';
import { columnDefs } from './columnDefs';
import { PrintLayout } from '../../../commons/PrintLayout';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import './InspectionsSummaryReport.scss';

const { createState, asDataGridHeaderComponent, useDataGridComponents } = ControlledDataGrid;

const defaultState = ({ myInspectionsOnly }) => ({
  controls: myInspectionsOnly ? { dateRangeMode: 'thisMonth' } : {},
  dataGrid: createState({ columnDefs }),
});

const requestServices = [
  'myInspections',
  'allInspections',
  'inspectorInspectionHistory',
  'vehicleInspectionHistory',
  'inspections',
];

export const InspectionsSummaryReport = ({
  fetchInspections,
  fetchMyInspections,
  fetchInspectorsLatestInspections,
  fetchVehiclesLatestInspections,
  inspections,
  myInspectionsOnly,
  user,
  reportViewId,
  openInspectionLocationReportRoute,
  inspectionDetailReportRoute,
  route,
  limits,
  ous,
}) => {
  const [myInspectionsFetched, setMyInspectionsFetched] = useState(false);

  const isPrint = route.queryStringValue('print') === 'true';

  const report = useReportController({
    reportType: 'inspectionsSummary',
    reportTypeName: myInspectionsOnly
      ? 'My Inspections Summary Report'
      : 'Inspections Summary Report',
    reportViewId,
    route,
    defaultState: defaultState({ myInspectionsOnly }),
    filtersConfig: {
      input: inspections,
      chain: ['query', 'filters'],
    },
  });

  const { dataGridController, setControl, controls, debouncedControls, filteredRows, reportType } =
    report;

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    InspectionMapHeader: () => <MapIcon />,
    InspectionMap: ({ data: { id } }) => (
      <Clickable onClick={() => openInspectionLocationReportRoute({ inspectionId: id })}>
        <MapIcon />
      </Clickable>
    ),
    OpenVehicleDetail: ({ data }) => (
      <LaunchReport
        onClick={() => {
          inspectionDetailReportRoute.open({
            inspectionId: data.id,
            reportViewId: 'default',
          });
        }}
      />
    ),
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('assetNames', [data.assetKey])}>{value}</Clickable>
    ),
    VehicleType: ({ data, value }) => (
      <Clickable onClick={() => setControl('assetTypes', [data.assetType])}>{value}</Clickable>
    ),
    Inspector: ({ data, value }) => (
      <Clickable onClick={() => setControl('inspectorNames', [data.inspectorKey])}>
        {value}
      </Clickable>
    ),
    ToggleChip: ({ value }) => <ToggleChip value={value} trueLabelText="Yes" falseLabelText="No" />,
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    DTCStatus: ({ value }) => (value === 'Yes' ? <EngineIcon /> : '-'),
  });

  const setAction = (myInspection, mode) => {
    if (!mode) return () => {};
    if (mode === 'byDateRange') return myInspection ? fetchMyInspections : fetchInspections;
    if (mode === 'latestInspectionsByVehicle') return fetchVehiclesLatestInspections;
    return fetchInspectorsLatestInspections;
  };

  // this effect will determine the date range to be used for retrieving latest by queries
  useEffect(() => {
    const { modeSelector, ouMode } = controls;
    const { modeSelector: debouncedModeSelector, ousSelected } = debouncedControls;
    const hasLatest = includes(
      ['latestInspectionsByVehicle', 'latestInspectionsByInspector'],
      modeSelector
    );

    if (isPrint && !hasLatest) return;

    const shouldSetDate =
      modeSelector &&
      debouncedModeSelector &&
      debouncedModeSelector !== 'byDateRange' &&
      modeSelector === debouncedModeSelector;

    if (hasLatest && shouldSetDate) {
      // if ouMode is default, means we will only make one call and set dates based on inspections returned
      if (ouMode === 'default') {
        const { minStartDate, maxEndDate } = getMinMaxDatesFromArray(inspections);
        setControl('startDate', minStartDate.format('YYYY-MM-DD'));
        setControl('endDate', maxEndDate.format('YYYY-MM-DD'));
      }

      if (ouMode === 'custom' && ousSelected.length > 0) {
        setControl('startDate', moment().subtract(2, 'year').format('YYYY-MM-DD'));
        setControl('endDate', moment().format('YYYY-MM-DD'));
      }
    }

    if (modeSelector === 'byDateRange' && debouncedModeSelector !== 'byDateRange') {
      setControl('startDate', moment().format('YYYY-MM-DD'));
      setControl('endDate', moment().format('YYYY-MM-DD'));
    }
  }, [
    inspections,
    controls.modeSelector,
    controls.ouMode,
    debouncedControls.modeSelector,
    debouncedControls.ousSelected,
  ]);

  useEffect(() => {
    if (isPrint) return;
    const { modeSelector } = debouncedControls;

    if (modeSelector) {
      fetchReportDataOnDemand({
        fetchAction: setAction(myInspectionsOnly, modeSelector),
        report,
        isOptimized: true,
        limits,
        ous,
        noDateRange: includes(
          ['latestInspectionsByVehicle', 'latestInspectionsByInspector'],
          modeSelector
        ),
      });
    }
  }, [debouncedControls]);

  useEffect(() => {
    if (!myInspectionsOnly || !user || isPrint || (inspections && inspections.length > 0)) return;

    fetchReportDataOnDemand({
      fetchAction: fetchMyInspections,
      report,
      isOptimized: true,
      limits,
      ous,
    });
    setMyInspectionsFetched(true);
  }, [debouncedControls]);

  useEffect(() => {
    if (myInspectionsOnly && myInspectionsFetched) {
      const { minStartDate, maxEndDate } = getMinMaxDatesFromArray(inspections);
      setControl('startDate', minStartDate.format('YYYY-MM-DD'));
      setControl('endDate', maxEndDate.format('YYYY-MM-DD'));
    }
  }, [myInspectionsFetched, myInspectionsOnly, inspections]);

  if (myInspectionsOnly && !user && !user.key) return null; // may be initial render where key not yet available

  const shouldDisableThisFeature =
    controls && controls.modeSelector !== 'byDateRange' && !myInspectionsOnly;

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      {isPrint && !!debouncedControls.modeSelector && (
        <FetchReportData
          fetchAction={setAction(myInspectionsOnly, debouncedControls.modeSelector)}
        />
      )}
      <SignalWebclientViewReady />
      <div className="InspectionsSummaryReport">
        <ReportFragment.Header
          reportType="inspections"
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
        />
        <QueryPanel
          queryFor="inspection"
          group="query"
          onlySelectedOrganization={myInspectionsOnly}
          disableDateRange={shouldDisableThisFeature}
          minAllowedDate="2015-01-01"
          hideAllOrganizations
          disableOneYearMaxRange
          disableFuture
          disableStartDate={shouldDisableThisFeature}
          disableEndDate={shouldDisableThisFeature}
          filterDateRange={[
            'custom',
            'today',
            'yesterday',
            'thisWeek',
            'lastWeek',
            'last7Days',
            'thisMonth',
            'lastMonth',
            '30Days',
          ]}
        >
          {!myInspectionsOnly && (
            <ReportControl
              render={(props) => (
                <SelectInput
                  options={[
                    { value: 'byDateRange', label: 'By Date Range' },
                    { value: 'latestInspectionsByVehicle', label: 'Latest By Vehicle' },
                    {
                      value: 'latestInspectionsByInspector',
                      label: 'Latest By Inspector',
                    },
                  ]}
                  {...props}
                />
              )}
              name="modeSelector"
              label="Mode"
              defaultValue="byDateRange"
              group="query"
              printConfig={({ value }) =>
                value && {
                  label: 'Mode',
                  value: startCase(value),
                }
              }
              reducerCallback={(draft, value) => {
                // null out filters whenever mode changes
                draft.controls.assetNames = [];
                draft.controls.inspectorNames = [];
              }}
              renderSummary={(renderProps, { controls: reportControls }) => {
                if (reportControls.modeSelector === 'latestInspectionsByVehicle') {
                  return (
                    <Chip
                      {...renderProps}
                      color="primary"
                      label="Mode: Latest inspections by vehicle"
                    />
                  );
                }
                if (reportControls.modeSelector === 'latestInspectionsByInspector') {
                  return (
                    <Chip
                      {...renderProps}
                      color="primary"
                      label="Mode: Latest inspections by inspector"
                    />
                  );
                }

                return (
                  reportControls.modeSelector !== 'byDateRange' && (
                    <Chip {...renderProps} variant="outlined" label="Select mode" />
                  )
                );
              }}
              filterConfig={{
                group: 'query',
                test: ({ row, value }) => {
                  if (value === 'latestInspectionsByVehicle') return row.isLatestVehicleInspection;
                  if (value === 'latestInspectionsByInspector')
                    return row.isLatestInspectorInspection;
                  return true;
                },
                type: filterRowsByPredicate,
                disabled: ({ value }) => !value,
              }}
              autoGridArea
            />
          )}
        </QueryPanel>

        <SectionPanel
          name={myInspectionsOnly ? 'myInspectionsFiltersPanel' : 'filtersPanel'}
          title="Inspection Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <InspectionResourceFilters
            rows={filteredRows.query}
            group="filters"
            filterGroup="filters"
            myInspectionsOnly={myInspectionsOnly}
            queryMode={controls.modeSelector}
            queryFor="inspection"
          />
        </SectionPanel>
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />

        <DetailReportPrintingLinks
          getDetailReportPath={async ({ id }) =>
            inspectionDetailReportRoute.makeTargetPath({
              inspectionId: id,
              reportViewId: 'default',
            })
          }
        />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
    </ReportProvider>
  );
};

InspectionsSummaryReport.propTypes = {
  fetchInspections: PropTypes.func.isRequired,
  fetchMyInspections: PropTypes.func.isRequired,
  fetchInspectorsLatestInspections: PropTypes.func.isRequired,
  fetchVehiclesLatestInspections: PropTypes.func.isRequired,
  inspections: customPropTypes.inspections.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  user: PropTypes.object,
  openInspectionLocationReportRoute: PropTypes.func.isRequired,
  inspectionDetailReportRoute: PropTypes.object.isRequired,
  myInspectionsOnly: PropTypes.bool,
  ous: customPropTypes.organizations,
  limits: PropTypes.object,
  setPrintMode: PropTypes.func.isRequired,
};
