import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, useBooleanState } from 'stti-react-common';

import { customPropTypes } from '../../../helpers/customPropTypes';
import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../commons/ControlledDataGrid';
import { columnDefs } from './columnDefs';
import { Feature } from '../../commons/Feature';
import { DeleteAdminItemDialog } from '../../commons/AdminCommon';

import './ReportSchedulesAdmin.scss';

const { useDataGridController, useDataGridComponents } = ControlledDataGrid;

export const ReportSchedulesAdmin = ({
  fetchReportSchedulesByUserKey,
  user,
  reportSchedules,
  deleteReportSchedule,
  route,
}) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState({});
  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <Button
        onClick={() => {
          setTargetRow(data);
          openDeleteDialog();
        }}
        icon="delete"
      />
    ),
  });

  useEffect(() => {
    if (user.key) {
      fetchReportSchedulesByUserKey(user.key);
    }
  }, [user]);

  return (
    <div className="ReportSchedulesAdmin">
      <Feature.Header onClose={route.close} title="Report Schedules" service="reportSchedules" />
      <ControlledDataGrid
        components={dataGridComponents}
        controller={dataGridController}
        rows={reportSchedules}
        rowIdProperty="key"
        sizeColumnsToFit
        enableCellTextSelection
      />
      <DeleteAdminItemDialog
        dialogTitle="Report Schedule"
        itemTitle={targetRow && targetRow.title}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={deleteReportSchedule}
        rowData={targetRow}
      />
    </div>
  );
};

ReportSchedulesAdmin.propTypes = {
  fetchReportSchedulesByUserKey: PropTypes.func.isRequired,
  reportSchedules: customPropTypes.reportSchedules.isRequired,
  deleteReportSchedule: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  user: customPropTypes.user,
};
