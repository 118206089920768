import { camelCase, startCase } from 'lodash';
import moment from 'moment-timezone';

import { columnDef } from '../../../../../commons/ControlledDataGrid';

const eventTypeToEventTitle = (eventType, eventCode, month, day) => {
  switch (eventType) {
    case 'DIAGNOSTIC_START':
      if (isNaN(+eventCode)) {
        return 'Malfunction Start';
      }
      return 'Data Diagnostic Start';

    case 'DIAGNOSTIC_END':
      if (isNaN(+eventCode)) {
        return 'Malfunction End';
      }
      return 'Data Diagnostic End';

    case 'SIGNATURE':
      return `Signature, Log Date ${moment(month, 'M').format('MMM')} ${day}`;

    default:
      return startCase(camelCase(eventType));
  }
};

export const eldEventsColumnDefs = [
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Time',
    minWidth: 150,
  }),
  columnDef.textColumn({
    field: 'eventType',
    headerName: 'Event',
    valueGetter: ({ data: { eventType, code, month, day } }) => {
      if (!eventType) return '-';
      return eventTypeToEventTitle(eventType, code, month, day);
    },
  }),
  columnDef.textColumn({
    field: 'geoLocation',
    headerName: 'Location',
  }),
  columnDef.distanceColumn({
    field: 'totalVehicleDistance',
    headerName: 'Odometer',
    aggFunc: null,
  }),
  columnDef.durationColumn({
    field: 'totalEngineHours',
    headerName: 'Engine Hours',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'annotation',
    headerName: 'Comment',
    valueGetter: ({ data }) => {
      const { eventCodeDefinition, annotation } = data;
      if (!eventCodeDefinition) return annotation;
      return `${eventCodeDefinition} ${annotation ? `-${annotation}` : ''}`;
    },
  }),
];
