import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, NumberInput, Form } from 'stti-react-common';

import { valueIsNull } from './common';
import { unconvertUnit, getFormatDefinitions } from '../../../../../helpers/formats';

const { Control, HiddenControl } = Form;

const mileageUnitOptions = getFormatDefinitions('mileage').map(({ key, description }) => ({
  value: key,
  label: description,
}));

export const Mileage = ({ preText, postText, defaultValues, error = valueIsNull }) => (
  <div className="Report__EditExceptionRuleDialog__RuleComponent">
    <HiddenControl name="mileage" defaultValue={defaultValues.mileage} />
    {preText}&nbsp;
    <Control
      Component={NumberInput}
      name="mileageValue"
      defaultValue={defaultValues.mileageValue}
      onSet={({ draft }) => {
        draft.mileage = unconvertUnit('mileage', draft.mileageValue, draft.mileageUnit);
      }}
      error={error}
    />
    &nbsp;
    <Control
      Component={SelectInput}
      name="mileageUnit"
      defaultValue={defaultValues.mileageUnit}
      onSet={({ draft }) => {
        draft.mileage = unconvertUnit('mileage', draft.mileageValue, draft.mileageUnit);
      }}
      options={mileageUnitOptions}
    />
    &nbsp;{postText}
  </div>
);

Mileage.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  defaultValues: PropTypes.shape({
    mileage: PropTypes.any,
    mileageValue: PropTypes.number,
    mileageUnit: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
