export const validateName = (target, value) => {
  if (!value || !value.trim()) {
    return true;
  }
  if (value.length < 2) {
    return `${target} name must have at least 2 characters`;
  }
  if (/\s/.test(value)) {
    return `${target} name cannot contain spaces`;
  }
  if (/[^a-zA-Z0-9]/.test(value)) {
    return `${target} name cannot contain special characters`;
  }
  if (value && value.length > 30) {
    return `${target} name cannot be over 30 characters`;
  }
  return false;
};

export const validateEmail = (value) => {
  const validEmailPattern = /\S+@\S+\.\S+/;
  if (!value || !value.trim()) {
    return true;
  }
  if (!validEmailPattern.test(value.toLowerCase())) {
    return 'Please enter a valid email';
  }
  return false;
};

export const validate24HrTime = (value) =>
  !/^(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):[0-5][0-9]:[0-5][0-9]$/.test(
    value
  ) && 'HH:mm:ss';
