import { connect } from 'react-redux';

import {
  selectFuelConsumptionsById,
  selectVehiclesBreadcrumbs,
  fetchFuelConsumption,
} from '../../../../data/reports';
import { FuelConsumptionLocationReport } from './FuelConsumptionLocationReport';

export const FuelConsumptionLocationReportContainer = connect(
  (state, { fuelConsumptionId }) => ({
    fuelConsumption: selectFuelConsumptionsById(state, fuelConsumptionId),
    breadcrumbsByVehicleKey: selectVehiclesBreadcrumbs(state),
  }),
  { fetchFuelConsumption }
)(FuelConsumptionLocationReport);
