import { find, intersection, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { selectSystemActiveOu, selectSystemUser } from '../../../system';
import { ADMIN_ROLE_KEYS } from '../../../system/constants';

const getTablets = (state) => state.administration.devices.tablets.data;

/**
 * selectTablets(state) -> [tablet]
 *
 * Selector for all tablets based on currentUsers permissions
 * Super Admin of tenant org returns all tablets
 */
export const selectTablets = createSelector(
  [getTablets, selectSystemActiveOu, selectSystemUser],
  (tablets, activeOu, currentUser) => {
    if (isEmpty(currentUser)) {
      return [];
    }
    if (
      currentUser.memberships.some(
        ({ role, scope }) => scope === 'Tenant' && intersection(ADMIN_ROLE_KEYS, role).length > 0
      )
    ) {
      return tablets.map((tablet) => ({
        ...tablet,
        timeZone: activeOu.timeZone,
      }));
    }

    const availableOrganizationKeys = currentUser.memberships
      .filter(
        (membership) => intersection(ADMIN_ROLE_KEYS, membership.role).length > 0 && membership.ou
      )
      .map((filteredMemberships) => filteredMemberships.ou);

    return tablets
      .filter((tablet) => availableOrganizationKeys.includes(tablet.ou))
      .map((tablet) => ({
        ...tablet,
        timeZone: activeOu.timeZone,
      }));
  }
);

/**
 * selectTablet(state, { tabletId }) -> tablet
 *
 * Selector for single tablet by key
 */
export const selectTablet = (state, { tabletId }) => find(selectTablets(state), { key: tabletId });

/**
 * selectTabletsFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on tablets
 */
export const selectTabletsFetchRequests = (state) => state.administration.devices.tablets.fetches;
