import React from 'react';

import { configureRoute } from '../../config/configureRoute';
import { BasicRouteFrame } from '../commons/AppRouter/RouteFrame';
import { Navigation } from '../commons/Navigation';

export const administration = configureRoute({
  path: '/administration',
  RouteFrame: BasicRouteFrame,
  render: (props) => <Navigation menuCategory="administration" {...props} />,
});
