/* eslint-disable no-console */
import { filter, find, last, some, isNull, reject } from 'lodash';
import moment from 'moment-timezone';

const rules = {
  shift: [],
};

export const validateActivities = (activities, activitiesType) => {
  if (!rules[activitiesType])
    throw new Error('Unrecognized activitiesType argument in validateActivities.');

  let errorCount = 0;
  rules[activitiesType].forEach(([ruleText, callback]) => {
    const addError = (offendingActivity, ...extra) => {
      if (errorCount === 0) console.groupCollapsed();
      errorCount += 1;
      console.warn(`Failed validation: ${ruleText}, row ${offendingActivity.row}`, ...extra);
    };
    callback(activities, addError);
  });
  if (errorCount > 0) {
    console.groupEnd();
    console.warn('Validation complete with', errorCount, `errors.`);
  } else {
    console.info('Validation complete without errors.');
  }
};

const addUniversalRule = (ruleText, callback) => {
  rules.shift.push([ruleText, callback]);
};

const addShiftRule = (ruleText, callback) => {
  rules.shift.push([ruleText, callback]);
};

const STANDARD_ACTIVITIES = [
  'BrakeCheck',
  'Break',
  'Driving',
  'FerryDelay',
  'Fuelling',
  'HookingTrailers',
  'Inspection',
  'LoadInspection',
  'OtherActivity',
  'OtherDelay',
  'PersonalUse',
  'ScaleIn',
  'ScaleOut',
  'UnhookingTrailers',
];

const STOPPED_ACTIVITY = 'Stopped';

const LOADING_ACTIVITIES = ['Loading', 'Unloading'];

const MINOR_PIT_ACTIVITIES = [
  'GpsAcquired',
  'GpsLost',
  'BrakeCheckExited',
  'BrakeCheckEntered',
  'CustomGeozoneEntered',
  'CustomGeozoneExited',
  'FuellingStationEntered',
  'FuellingStationExited',
  'LoadLocationEntered',
  'LoadLocationExited',
  'OffHighwayEntered',
  'OffHighwayExited',
  'OfficeEntered',
  'OfficeExited',
  'RestaurantEntered',
  'RestaurantExited',
  'ScaleEntered',
  'ScaleExited',
  'ShopEntered',
  'ShopExited',
  'UnloadLocationEntered',
  'UnloadLocationExited',
  'NetworkConnectionAcquired',
  'NetworkConnectionLost',
  'StateEntered',
];

// shift only
const SHIFT_STARTED_ACTIVITY = 'ShiftStarted';
const SHIFT_ENDED_ACTIVITY = 'ShiftEnded';
const USER_LOGGED_IN_ACTIVITY = 'UserLoggedIn';
const USER_LOGGED_OUT_ACTIVITY = 'UserLoggedOut';
const SHIFT_PIT_ACTIVITIES = [
  SHIFT_STARTED_ACTIVITY,
  SHIFT_ENDED_ACTIVITY,
  USER_LOGGED_IN_ACTIVITY,
  USER_LOGGED_OUT_ACTIVITY,
];

const ALL_PIT_ACTIVITIES = [...MINOR_PIT_ACTIVITIES, ...SHIFT_PIT_ACTIVITIES];

const NUMBER_PROPERTIES = ['distance', 'duration', 'addedFuel', 'consumedFuel', 'idlingFuel'];

addUniversalRule(
  'No parent will have a single child (it will become a solo row)',
  (activities, addError) => {
    activities.forEach((activity) => {
      const { childCount } = activity;
      if (childCount === 1) addError(activity);
    });
  }
);

addUniversalRule(
  "Only one level of depth supported (i.e. no child's parent is also a child)",
  (activities, addError) => {
    activities.forEach((activity) => {
      const { originalParent } = activity;
      if (originalParent && originalParent.originalParent) addError(activity);
    });
  }
);

addUniversalRule(
  'Activities are delivered in startedAt chronological order (but may have the same startedAt as their preceding row)',
  (activities, addError) => {
    activities.forEach((activity, index) => {
      const { startedAtUtc } = activity;
      const lastRow = activities[index - 1];
      if (!lastRow) return;
      if (startedAtUtc < lastRow.startedAtUtc) addError(activity);
    });
  }
);

addUniversalRule(
  'Activities start with row of 1 and ascend by exactly 1',
  (activities, addError) => {
    activities.forEach((activity, index) => {
      const { row } = activity;
      const lastRow = activities[index - 1];
      if (!lastRow && row !== 1) addError(activity, `at index ${index}`);
      if (lastRow && row !== lastRow.row + 1) addError(activity, `at index ${index}`);
    });
  }
);

addUniversalRule('Parent rows must have a non-zero childCount', (activities, addError) => {
  activities.forEach((activity) => {
    const { id, childCount } = activity;
    if (find(activities, { parentId: id }) && childCount === 0) addError(activity);
  });
});

addUniversalRule('child and solo rows must have a childCount of zero', (activities, addError) => {
  activities.forEach((activity) => {
    const { id, childCount } = activity;
    if (!find(activities, { parentId: id }) && childCount !== 0) addError(activity);
  });
});

addUniversalRule('PIT rows may not be parents', (activities, addError) => {
  activities.forEach((activity) => {
    const { activityType, isParent } = activity;
    if (ALL_PIT_ACTIVITIES.includes(activityType) && isParent) addError(activity);
  });
});

addUniversalRule('Standard activities allowed children', (activities, addError) => {
  activities.forEach((activity) => {
    const { activityType, originalParent } = activity;
    // check children backwards
    if (originalParent && STANDARD_ACTIVITIES.includes(originalParent.activityType)) {
      switch (true) {
        case activityType === originalParent.activityType:
        case activityType === 'Driving':
        case activityType === 'SpeedViolation':
        case MINOR_PIT_ACTIVITIES.includes(activityType):
          return;
        default:
          addError(
            activity,
            `(${activityType} not allowed for parent of ${originalParent.activityType})`
          );
      }
    }
  });
});

addUniversalRule('Stopped activity allowed children', (activities, addError) => {
  activities.forEach((activity) => {
    const { activityType, originalParent } = activity;
    // check children backwards
    if (originalParent && originalParent.activityType === STOPPED_ACTIVITY) {
      switch (true) {
        case activityType === originalParent.activityType:
        case activityType === 'SpeedViolation':
        case MINOR_PIT_ACTIVITIES.includes(activityType):
          return;
        default:
          addError(
            activity,
            `(${activityType} not allowed for parent of ${originalParent.activityType})`
          );
      }
    }
  });
});

addUniversalRule('Loading activities allowed children', (activities, addError) => {
  activities.forEach((activity) => {
    const { activityType, originalParent } = activity;
    // check children backwards
    if (originalParent && LOADING_ACTIVITIES.includes(originalParent.activityType)) {
      switch (true) {
        case activityType === originalParent.activityType:
        case activityType === 'Driving':
        case activityType === 'SpeedViolation':
        case MINOR_PIT_ACTIVITIES.includes(activityType):
        case activityType === 'Waiting':
        case activityType === 'Break':
          return;
        default:
          addError(
            activity,
            `(${activityType} not allowed for parent of ${originalParent.activityType})`
          );
      }
    }
  });
});

addUniversalRule('First child of Loading activity must be Waiting', (activities, addError) => {
  activities.forEach((activity, index) => {
    const lastRow = activities[index - 1];
    if (!lastRow) return;
    const { activityType } = activity;
    if (
      lastRow.isParent &&
      LOADING_ACTIVITIES.includes(lastRow.activityType) &&
      activityType !== 'Waiting'
    )
      addError(activity);
  });
});

addUniversalRule(
  'PIT activities must have null duration/distance/fuel properties',
  (activities, addError) => {
    activities.forEach((activity) => {
      const { activityType } = activity;
      if (
        ALL_PIT_ACTIVITIES.includes(activityType) &&
        some(NUMBER_PROPERTIES, (property) => !isNull(activity[property]))
      )
        addError(activity);
    });
  }
);

addUniversalRule(
  'Duration property must be difference between startedAt and endedAt (or null if duration is 0)',
  (activities, addError) => {
    activities.forEach((activity) => {
      const { startedAtUtc, endedAtUtc, duration } = activity;
      const calculatedDuration =
        moment.duration(moment(endedAtUtc).diff(moment(startedAtUtc))).asSeconds() || null;
      if (duration !== calculatedDuration) addError(activity);
    });
  }
);

addUniversalRule('The structure of all rows must follow pattern', (activities, addError) => {
  let currentParentId = null;
  activities.forEach((activity) => {
    const { id, parentId, childCount } = activity;
    if (childCount > 0) {
      // new parent
      currentParentId = id;
      return;
    }
    if (parentId && parentId !== currentParentId) {
      // child out of place
      addError(activity);
    }
  });
});

addUniversalRule(
  'Row time does not overlap and is without gaps (ignoring children)',
  (activities, addError) => {
    const activitiesExcludingChildren = reject(activities, 'parentId');
    activitiesExcludingChildren.forEach((activity, index) => {
      const { startedAtUtc, activityType } = activity;
      if (index === 0 || activityType === USER_LOGGED_IN_ACTIVITY) return;
      const lastRow = activitiesExcludingChildren[index - 1];
      if (startedAtUtc !== lastRow.endedAtUtc) addError(activity);
    });
  }
);

addUniversalRule(
  'Row time does not overlap and is without gaps (ignoring parents)',
  (activities, addError) => {
    const activitiesExcludingParents = reject(activities, 'childCount');
    activitiesExcludingParents.forEach((activity, index) => {
      const { startedAtUtc, activityType } = activity;
      if (index === 0 || activityType === USER_LOGGED_IN_ACTIVITY) return;
      const lastRow = activitiesExcludingParents[index - 1];
      if (startedAtUtc !== lastRow.endedAtUtc) addError(activity);
    });
  }
);

addShiftRule('ShiftStarted must be first row of shift', (activities, addError) => {
  const firstActivity = activities[0];
  if (firstActivity.activityType !== SHIFT_STARTED_ACTIVITY) addError(firstActivity);
});

addShiftRule(
  'Non-first-row ShiftStarted must be preceded by UserLoggedIn + Stopped',
  (activities, addError) => {
    activities.forEach((activity, index) => {
      if (index === 0) return;
      if (activity.activityType === SHIFT_STARTED_ACTIVITY) {
        const lastRow = activities[index - 1];
        const skipExtraRows = (lastRow.originalParent && lastRow.originalParent.childCount) || 0;
        const lastLastRow = activities[index - 1 - skipExtraRows - 1];
        if (
          !lastLastRow ||
          lastLastRow.activityType !== USER_LOGGED_IN_ACTIVITY ||
          lastRow.activityType !== STOPPED_ACTIVITY
        )
          addError(activity);
      }
    });
  }
);

addShiftRule('ShiftStarted must contain vehicleName property', (activities, addError) => {
  activities.forEach((activity) => {
    if (activity.activityType === SHIFT_STARTED_ACTIVITY && !activity.vehicleName)
      addError(activity);
  });
});

addShiftRule(
  'ShiftEnded (must appear exactly once, as last row of shift)',
  (activities, addError) => {
    const lastActivity = last(activities);
    if (lastActivity.activityType !== SHIFT_ENDED_ACTIVITY) addError(lastActivity);
    const allShiftEnded = filter(activities, { activityType: SHIFT_ENDED_ACTIVITY });
    allShiftEnded.forEach((shiftEnded) => {
      if (shiftEnded !== lastActivity) addError(shiftEnded);
    });
  }
);

addShiftRule('UserLoggedOut must be followed by UserLoggedIn', (activities, addError) => {
  activities.forEach((activity, index) => {
    if (activity.activityType === USER_LOGGED_OUT_ACTIVITY) {
      const nextRow = activities[index + 1] || {};
      if (nextRow.activityType !== USER_LOGGED_IN_ACTIVITY) addError(activity);
    }
  });
});

addShiftRule('UserLoggedIn must be preceded by UserLoggedOut', (activities, addError) => {
  activities.forEach((activity, index) => {
    if (activity.activityType === USER_LOGGED_IN_ACTIVITY) {
      const lastRow = activities[index - 1] || {};
      if (lastRow.activityType !== USER_LOGGED_OUT_ACTIVITY) addError(activity);
    }
  });
});

addShiftRule('UserLoggedIn must be followed by Stopped + ShiftStarted', (activities, addError) => {
  activities.forEach((activity, index) => {
    if (activity.activityType === USER_LOGGED_IN_ACTIVITY) {
      const nextRow = activities[index + 1] || {};
      const skipExtraRows = nextRow.childCount || 0;
      const nextNextRow = activities[index + 1 + skipExtraRows + 1];
      if (
        nextRow.activityType !== STOPPED_ACTIVITY ||
        nextNextRow.activityType !== SHIFT_STARTED_ACTIVITY
      )
        addError(activity);
    }
  });
});

addShiftRule('All root activities will fall into one of the categories', (activities, addError) => {
  const validNonChildrenActivities = [
    ...STANDARD_ACTIVITIES,
    STOPPED_ACTIVITY,
    ...LOADING_ACTIVITIES,
    ...SHIFT_PIT_ACTIVITIES,
  ];
  activities.forEach((activity) => {
    if (!activity.originalParent && !validNonChildrenActivities.includes(activity.activityType))
      addError(activity);
  });
});

// addUniversalRule('Number properties may not be negative', (activities, addError) => {
//   activities.forEach(activity => {
//     if (some(NUMBER_PROPERTIES, property => isFinite(activity[property]) && activity[property] < 0))
//       addError(activity);
//   });
// });
