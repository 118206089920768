import { filter } from 'lodash';
// import moment from 'moment-timezone';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectActivitiesFetchRequests } from './selectors';
// import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchApiKeys = makeRestApiAction({
  service: 'activities',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchActivities,
  notificationsItemDescriptor: 'Activities',
});

export const fetchApiKeysOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectActivitiesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchApiKeys,
  baseActionType: ACTION_TYPE.fetchActivities,
});
