import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput, Form, Icon, Clickable, ProgressOverlay } from 'stti-react-common';
import { isEmpty } from 'lodash';

import './AppLogin.scss';

function sanitizeInputs(input) {
  if (typeof input === 'object') {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, sanitizeInputs(value)])
    );
  }

  return input ? input.replace(/<script.*>/g, '') : '';
}

export const AppLogin = ({ signIn, user, route, setMenu, ous }) => {
  const [visibility, setVisibility] = useState(false);
  const { Control, useFormController } = Form;
  const form = useFormController({
    controls: { username: '', password: '' },
  });

  const { controls: formControls, isDirty, hasErrors } = form;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (route) {
      route.authenticate(user, setMenu, ous);
    } else {
      let timerId;
      if (isEmpty(user)) {
        timerId = setTimeout(() => {
          window.location.href = '#/login';
        }, 5000);
      }
      return () => clearTimeout(timerId);
    }
  }, [user, setMenu, ous, route]);

  return isEmpty(route) ? (
    <>
      <h3>Checking user authentication and loading page, please wait...</h3>
      <ProgressOverlay isOpen />
    </>
  ) : (
    <div className="AppLogin">
      <header>
        <a className="navbar-brand" href="/#">
          Navistream
        </a>
      </header>
      <div className="AppLogin_FormContainer">
        <Form form={form}>
          <div className="AppLogin_FormContainer__controls">
            <Control
              Component={TextInput}
              name="username"
              label="Username"
              error={({ value }) => isDirty && !value}
            />
            <Control
              Component={TextInput}
              name="password"
              defaultValue=""
              type={visibility ? 'text' : 'password'}
              label="Password"
              error={({ value }) => (isDirty && !value) || (isDirty && !value.trim())}
              endAdornment={
                <Clickable onClick={() => setVisibility(!visibility)}>
                  <Icon icon={visibility ? 'visibility' : 'visibility_off'} />
                </Clickable>
              }
              onKeyPress={(e) =>
                e.key === 'Enter' &&
                !hasErrors &&
                isDirty &&
                signIn({ ...sanitizeInputs(formControls) })
              }
            />
            <div className="ButtonContainer">
              <Button
                icon="cloud"
                variant="contained"
                label="Sign in with Google"
                color="primary"
                onClick={() => {}}
              />
              <Button
                icon="login"
                variant="contained"
                label="Sign in"
                color="secondary"
                disabled={!isDirty || hasErrors}
                onClick={() => signIn({ ...sanitizeInputs(formControls) })}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

AppLogin.propTypes = {
  signIn: PropTypes.func.isRequired,
  user: PropTypes.object,
  route: PropTypes.object,
  setMenu: PropTypes.func,
  ous: PropTypes.array,
};
