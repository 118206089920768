import { connect } from 'react-redux';

import { FormatsProvider } from './FormatsProvider';
import { selectFormats } from '../../../data/system';

export const FormatsProviderContainer = connect(
  (state) => ({
    systemFormats: selectFormats(state),
  }),
  {}
)(FormatsProvider);
