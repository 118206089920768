import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import { FiltersPanel } from './FiltersPanel';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import {
  useReportController,
  ReportProvider,
  LaunchReport,
  ReportLoader,
  ControlsTablePrint,
  SectionPrint,
  ReportFragment,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
  DetailReportPrintingLinks,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './UnidentifiedLogsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: {
    dateRangeMode: 'last14Days',
    hasActivity: true,
  },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['unidentifiedLogs'];

export const UnidentifiedLogsSummaryReport = ({
  fetchUnidentifiedLogs,
  limits,
  logs,
  reportViewId,
  route,
  unidentifiedLogDetailReportRoute,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'unidentifiedLogsSummary',
    reportTypeName: 'Unidentified Driver Log Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: logs,
      chain: ['query', 'filters'],
    },
  });
  const { setControl, filteredRows, dataGridController, reportType } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    OpenDetail: ({
      data: {
        vehicle: { key: vehicleKey },
        logDate: date,
      },
    }) => (
      <LaunchReport
        onClick={() =>
          unidentifiedLogDetailReportRoute.open({ vehicleKey, date, reportViewId: 'default' })
        }
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data.vehicle.key])}>{value}</Clickable>
    ),
    Activity: {
      renderer: ({ value: { value, label } }) => (
        <Clickable onClick={() => setControl('hasActivity', value)}>{label}</Clickable>
      ),
      groupRenderer: true,
    },
  });

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData fetchAction={fetchUnidentifiedLogs} isOptimized limits={limits} />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="UnidentifiedLogsSummaryReport">
        <ReportFragment.Header route={route} services={requestServices} />
        <QueryPanel
          queryFor="log"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2018-01-01"
          disableFuture
        />
        <FiltersPanel rows={filteredRows.query} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} rowIdProperty="key" />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable
          controller={dataGridController}
          rows={filteredRows.dataGrid}
          rowIdProperty="key"
        />
      </PrintLayout>
      <DetailReportPrintingLinks
        getDetailReportPath={({ vehicle: { key: vehicleKey }, logDate: date }) =>
          unidentifiedLogDetailReportRoute.makeTargetPath({
            vehicleKey,
            date,
            reportViewId: 'default',
          })
        }
      />
    </ReportProvider>
  );
};

UnidentifiedLogsSummaryReport.propTypes = {
  logs: customPropTypes.unidentifiedLogs.isRequired,
  reportViewId: PropTypes.string.isRequired,
  unidentifiedLogDetailReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchUnidentifiedLogs: PropTypes.func.isRequired,
  limits: PropTypes.object,
};
