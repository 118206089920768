import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find, orderBy } from 'lodash';
import moment from 'moment-timezone';

const extractKey = (_, key) => key;

const getFetchRequests = (state) => state.reports.speedViolations.fetches;
const getOrganizations = (state) => state.system.ous.data;
const getSpeedViolations = (state) => state.reports.speedViolations.speedViolations;

/**
 * selectSpeedViolations(state) -> [speedViolations]
 *
 * Selector for all speed violation events
 */

export const selectSpeedViolations = createSelector(
  [getSpeedViolations, getOrganizations],
  (violations, ous) => {
    const speedViolations = violations.map((violation) => {
      const organization = ous.find((ou) => ou.key === violation.ouKeys[0]);
      return {
        ...violation,
        ouKey: violation.ouKey || (organization && organization.key),
        timeZone: organization ? organization.timeZone : moment.tz.guess(),
      };
    });

    return orderBy(speedViolations, ['driverName'], ['asc']);
  }
);

/**
 * selectSpeedViolationsByKey(state, { key }) -> violation
 *
 * Selector for single violation by key
 */
export const selectSpeedViolationsByKey = createCachedSelector(
  [selectSpeedViolations, extractKey],
  (violations, key) => find(violations, { key })
)(extractKey);

/**
 * selectSpeedViolationsFetches(state) -> [fetchRequestData]
 *
 * Selector for all fetches (active and successful), requestData only
 */
export const selectSpeedViolationsFetches = createSelector(getFetchRequests, (fetchRequests) =>
  fetchRequests.map((fetchRequest) => fetchRequest.actionParam)
);
