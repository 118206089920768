import { connect } from 'react-redux';

import { TenantSummary } from './summary';

import { selectSystemTenant, selectSystemUser } from '../../../../../data/system';
import { tenant } from '../../../../../data/administration/configurations/tenants';

export const TenantSummaryContainer = connect(
  (state) => ({
    tenant: selectSystemTenant(state),
    tenants: tenant.selectors.selectTenants(state),
    isSystem: selectSystemTenant(state).isSystem,
    systemUser: selectSystemUser(state),
  }),
  {
    fetchTenants: tenant.actions.fetchTenantsOptimized,
    createTenantSupportUser: tenant.actions.createTenantSupportUser,
  }
)(TenantSummary);
