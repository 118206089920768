import { connect } from 'react-redux';

import { ShiftsSummaryReport } from './ShiftsSummaryReport';
import { selectShifts, fetchShiftsOptimized } from '../../../../data/reports';
import { selectSystemUser, setPrintMode, selectSearchLimits } from '../../../../data/system';

export const ShiftsSummaryReportContainer = connect(
  (state) => ({
    shifts: selectShifts(state),
    userKey: selectSystemUser(state).key,
    limits: selectSearchLimits(state, 'shifts'),
  }),
  { fetchShifts: fetchShiftsOptimized, setPrintMode }
)(ShiftsSummaryReport);
