import React from 'react';
import PropTypes from 'prop-types';

export const TabletDeletePrompt = ({ lastSignIn }) => (
  <div>
    <p>
      Deleting this tablet can cause <b>data loss</b> since the last use.
    </p>
    <p>Last sign in: {lastSignIn}</p>
    <p>You will need to re-activate this tablet for it to be functional again.</p>
  </div>
);

TabletDeletePrompt.propTypes = {
  lastSignIn: PropTypes.string,
};
